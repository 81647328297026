import { FC, memo } from 'react'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { PresenceStatusListToolbar } from './components/presenceStatusListToolbar/PresenceStatusListToolbar'
import { PresenceStatusListGrid } from './components/presenceStatusListGrid/PresenceStatusListGrid'

export const PresenceStatusList: FC = memo(() => {
  return (
    <div className="flex flex-col gap-2">
      <CrewErrorBoundary>
        <PresenceStatusListToolbar />
      </CrewErrorBoundary>

      <CrewErrorBoundary>
        <PresenceStatusListGrid />
      </CrewErrorBoundary>
    </div>
  )
})
