import classNames from 'classnames'
import { FC, memo, useMemo } from 'react'

import { LIMIT_FOR_UNREAD_DISPLAY_COUNT } from 'configs/constants'
import {
  useMessageSelector,
  useSelectUnreadCountInChatRoomWithDescendants,
} from '@crew/states'
import { CrewCountBadge } from 'components/crewCountBadge/crewCountBadge'

export type CrewChatRoomUnreadBadgeProps = {
  /**
   * 未読件数を表示する対象チャットルームID
   */
  chatRoomId: string
}

// 未読がある場合に適用するクラス
const hasUnreadClassNames = 'has-unread'

// 空要素の場合に適用するクラス
const emptyClassNames = 'w-0 h-0'

export const CrewChatRoomUnreadBadge: FC<CrewChatRoomUnreadBadgeProps> = memo(
  (props) => {
    const selectUnreadCountTotal =
      useSelectUnreadCountInChatRoomWithDescendants()

    const { attention, total, mentionToMe, mentionToGroup } =
      useMessageSelector((state) =>
        selectUnreadCountTotal(state, props.chatRoomId)
      )

    // 上限（現状は99）を超える場合は、その数字に+を付けて表示する。 例）99+
    const counterText = useMemo(
      () =>
        attention > LIMIT_FOR_UNREAD_DISPLAY_COUNT
          ? `${LIMIT_FOR_UNREAD_DISPLAY_COUNT}+`
          : `${attention}`,
      [attention]
    )

    const targetBackgroundClassNames = useMemo(
      () =>
        mentionToMe > 0
          ? 'crew-badge-red-inverted' // 赤：ユーザー個人宛のメンションがある場合
          : mentionToGroup > 0
          ? 'crew-badge-orange-inverted' // オレンジ：ユーザが属するグループ宛のメンションがある場合。@allや@hereなど
          : 'crew-badge-blue-inverted', // 青：上記以外
      [mentionToMe, mentionToGroup]
    )

    if (attention <= 0) {
      if (total <= 0) {
        // 未読が完全に0なら何も表示しない
        return null
      } else {
        // 未読があるがreplyAndMentionが0なら、hasUnreadClassNamesクラスを持った空のspan返す。バッジなしで太字になる
        return (
          <span className={classNames(hasUnreadClassNames, emptyClassNames)} />
        )
      }
    }

    return (
      <CrewCountBadge displayColor={targetBackgroundClassNames}>
        {counterText}
      </CrewCountBadge>
    )
  }
)
