import { FC, memo } from 'react'
import { EventDetailAttendanceAndCommentForm } from '../eventDetailAttendanceAndCommentForm/eventDetailAttendanceAndCommentForm'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useEventDetailAttendeeListToolbar } from './useEventDetailAttendeeListToolbar'
import { EventDetailAttendeesEntryDialog } from '../eventDetailAttendeeEntryDialog/eventDetailAttendeesEntryDialog'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { CrewErrorDialog } from 'components/elements/crewErrorDialog/crewErrorDialog'
import { useTranslation } from '@crew/modules/i18n'
import { useModal } from 'components/layouts/modal/useModal'
import { useEventAttendeesContext } from '../../eventDetailAttendeeList'
import { useCallback, useState } from 'react'
import { useToast } from 'hooks/useToast'
import { useParams } from 'react-router-dom'
import { useEventDetailPageContext } from 'features/project/components/eventDetailPage/useEventDetailPage'
import { useProjectPermissions } from '@crew/hooks'
import { EntityType } from '@crew/enums/domain'
import { CrewCheckBox } from 'components/devextreme/crewCheckBox'

type PostToChatCheckboxProps = {
  onNeedNotificationChange: (value: boolean) => void
  value: boolean
}

// チャットに投稿する
const PostToChatCheckbox: FC<PostToChatCheckboxProps> = (props) => {
  const { t } = useTranslation()

  // チェックボックスの値が変更されたときの処理
  const handleNeedNotificationChange = useCallback(
    (value: boolean | null) => {
      props.onNeedNotificationChange(value ?? false)
    },
    [props]
  )

  return (
    <CrewCheckBox
      text={t('label.needNotification')}
      onValueChange={handleNeedNotificationChange}
      value={props.value}
    />
  )
}

export const EventDetailAttendeeListToolbar = memo(() => {
  const { deleteEventAttendees, isLoadingDeleteEventAttendees } =
    useEventDetailAttendeeListToolbar()

  const { t } = useTranslation()

  const { confirmAttendance } = useEventDetailPageContext()

  const { eventId } = useParams()

  const { hasPrjEventEditPermission } = useProjectPermissions(
    EntityType.Event,
    eventId
  )

  const { currentAttendee, selectedAttendeeIds, setSelectedAttendeeIds } =
    useEventAttendeesContext()

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  const [isErrorDialogOpen, openErrorDialog, closeErrorDialog] = useModal()

  const toast = useToast()

  const [
    isAttendeesEntryDialogOpen,
    openAttendeesEntryDialog,
    closeAttendeesEntryDialog,
  ] = useModal()

  const [needNotification, setNeedNotification] = useState<boolean>(false)

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')

  // エラーダイアログメッセージ
  const [errorMessage, setErrorMessage] = useState('')

  //open confirm dialog when click remove attendee button
  const handleRemoveAttendeeButtonClick = useCallback(() => {
    setConfirmMessage(t('message.general.confirmMessage.delete'))
    // ダイアログを開くときに通知チェックボックスを初期化
    setNeedNotification(false)
    openConfirmDialog()
  }, [openConfirmDialog, t])

  // 削除確認ダイアログ OKボタン
  const handleDeletePermitButtonClick = useCallback(async () => {
    if (eventId && selectedAttendeeIds.length > 0) {
      try {
        await deleteEventAttendees(
          eventId,
          selectedAttendeeIds,
          needNotification
        )

        // Update selected attendee to blank after deleted it
        setSelectedAttendeeIds([])

        toast.success(t('message.meeting.removeAttendeesSuccess'))
      } catch (err: any) {
        setErrorMessage(t('message.general.errorMessage.delete'))
        openErrorDialog()
      }
    }

    closeConfirmDialog()
  }, [
    closeConfirmDialog,
    eventId,
    selectedAttendeeIds,
    deleteEventAttendees,
    needNotification,
    setSelectedAttendeeIds,
    toast,
    t,
    openErrorDialog,
  ])

  // チャットに投稿するチェックボックスの値変更時
  const handleNeedNotificationChange = useCallback((value: boolean | null) => {
    setNeedNotification(value ?? false)
  }, [])

  return (
    <div>
      <div className="flex items-center justify-between p-2 mt-3">
        {/* Check is logged in user has in attendees and event confirm attendance */}
        {currentAttendee && confirmAttendance && (
          <EventDetailAttendanceAndCommentForm />
        )}

        {/* Action add attendee and remove attendee */}
        {hasPrjEventEditPermission && (
          <div className="flex gap-x-3 items-center ml-auto">
            <CrewButton
              type="primary"
              text={t('action.addAttendee')}
              onClick={openAttendeesEntryDialog}
            />
            <CrewButton
              type="primary"
              text={t('action.removeAttendee')}
              onClick={handleRemoveAttendeeButtonClick}
              disabled={selectedAttendeeIds.length === 0}
            />
          </div>
        )}
      </div>

      <EventDetailAttendeesEntryDialog
        isOpen={isAttendeesEntryDialogOpen}
        title={t('label.addAttendeeDialogTitle')}
        onClose={closeAttendeesEntryDialog}
      />

      {/* 削除確認ダイアログ */}
      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleDeletePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
        renderItem={
          <PostToChatCheckbox
            onNeedNotificationChange={handleNeedNotificationChange}
            value={needNotification}
          />
        }
        permitButtonDisabled={isLoadingDeleteEventAttendees}
      />

      <CrewErrorDialog
        isOpen={isErrorDialogOpen}
        message={errorMessage}
        onCloseButtonClick={closeErrorDialog}
      />
    </div>
  )
})
