import { memo } from 'react'
import { TenantUserTable } from './components/tenantUserTable/tenantUserTable'

export const UserListGrid = memo(() => {
  return (
    <>
      {/* Tenant user table */}
      <div className="flex overflow-y-hidden">
        <TenantUserTable />
      </div>
    </>
  )
})
