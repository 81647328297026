import { useTranslation } from '@crew/modules/i18n'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { ValidateRules } from '@crew/utils/form'
import { useLoginMutation } from '@crew/apis/app/appApis'
import { useWebsocketForceDisconnect } from '@crew/providers/websocket'
export type LoginFormValues = {
  loginId: string
  password: string
}

export const useAppLoginPage = () => {
  const { t } = useTranslation()
  const websocketForceDisconnect = useWebsocketForceDisconnect()

  // react-hook-formの各種データを取得
  const { control, handleSubmit } = useForm<LoginFormValues>({
    defaultValues: {
      loginId: '',
      password: '',
    },
    criteriaMode: 'all',
  })

  // Get login function
  const [loginMutation, { isLoading: isLoadingLogin }] = useLoginMutation()

  // ログイン
  const login = useCallback(
    async (data: LoginFormValues) => {
      // 念のためログイン前にwebsocketを強制切断しておく
      websocketForceDisconnect()

      // call login api
      const result = await loginMutation({
        ...data,
      }).unwrap()

      return result
    },
    [loginMutation, websocketForceDisconnect]
  )

  // 以下、入力コンポーネントのルールとレンダリングを定義
  const validateRules: ValidateRules<LoginFormValues> = useMemo(
    () => ({
      loginId: {
        required: t('message.general.isRequired', {
          name: t('label.username'),
        }),
        maxLength: {
          value: 500,
          message: t('message.general.maxLength', {
            name: t('label.username'),
            value: 500,
          }),
        },
      },
      password: {
        required: t('message.general.isRequired', {
          name: t('label.password'),
        }),
        maxLength: {
          value: 500,
          message: t('message.general.maxLength', {
            name: t('label.password'),
            value: 500,
          }),
        },
      },
    }),
    [t]
  )

  return {
    // react-hook-form関連
    control,
    handleSubmit,
    validateRules,

    // Login process
    login,
    isLoadingLogin,
  }
}
