import { FC, memo, useEffect } from 'react'
import { CrewNavTabs } from 'components/elements/crewNavTabs/crewNavTabs'
import { useMemo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { DisplayAnonymousFile, TaskDetailListTabs } from 'enums/app'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { CrewNavTabProps } from 'components/elements/crewNavTabs/components/crewNavTab'
import { DEFAULT_PAGING_PARAMS } from 'configs/constants'
import { useUserSetting } from '@crew/states'
import { SettingKeyType } from '@crew/enums/app'
import { buildObjUrlParamsFromFilterDefinition } from '@crew/utils'
import { useAppSelector } from 'states/hooks'
import { useGetLookupFiltersQuery } from '@crew/apis/lookup/lookupApis'
import { EntityType } from '@crew/enums/domain'

export const TaskDetailTab: FC = memo(() => {
  const { t } = useTranslation()
  const { navigate } = useCrewNavigate()

  const filterEventMessage = useAppSelector(
    (state) => state.app.filterEventMessage
  )

  const defaultPageSize = useUserSetting(
    SettingKeyType.ListDisplayNumber,
    DEFAULT_PAGING_PARAMS.pageSize
  )

  // Declare default pagination information
  const defaultPagination = useMemo(() => {
    return {
      pageIndex: DEFAULT_PAGING_PARAMS.pageIndex,
      pageSize: defaultPageSize,
    }
  }, [defaultPageSize])

  // ====================================================
  // Build url parameter for the link of File tab
  // ====================================================
  // Get file filter
  const { data: fileFilters, refetch: refetchFileFilter } =
    useGetLookupFiltersQuery({
      entityType: EntityType.File,
    })

  // When an event related to Filter arises, perform get task filters again
  useEffect(() => {
    refetchFileFilter()
  }, [filterEventMessage, refetchFileFilter])

  // get task default filter
  const fileDefaultFilter = useMemo(
    () => fileFilters?.filters.find((filter) => filter.default),
    [fileFilters?.filters]
  )

  // file params
  const fileFilterCondition =
    buildObjUrlParamsFromFilterDefinition(fileDefaultFilter)
  const fileParams = useMemo(() => {
    return {
      ...defaultPagination,
      ...fileFilterCondition,
      // デフォルトは匿名ファイルを表示しない
      ...{ anonymousFile: DisplayAnonymousFile.DoNotDisplay.value },
    }
  }, [defaultPagination, fileFilterCondition])

  // タブアイテム
  const displayTabItems: CrewNavTabProps[] = useMemo(() => {
    const items = Object.values(TaskDetailListTabs).map((item) => {
      let param: any = {}
      if (item.value === TaskDetailListTabs.File.value) {
        param = fileParams
      }

      return {
        onClick: () => {
          // タブに対応するURLへ遷移（相対パス指定）
          navigate(item.value, {
            ...param,
          })
        },
        to: item.value,
        text: t(item.text),
        icon: item.icon,
      }
    })

    return items
  }, [fileParams, navigate, t])

  return <CrewNavTabs tabItems={displayTabItems} />
})
