import { Menu, Transition } from '@headlessui/react'
import {
  forwardRef,
  RefAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { genericMemo } from 'utils'
import { DropDownButton } from './components/dropDownbutton/dropDownButton'
import { DropDownSplitButton } from './components/dropDownSplitButton/dropDownSplitButton'
import { DropDownItem } from './components/dropDownItem/dropDownItem'
import { createPortal } from 'react-dom'
import { debounce } from 'lodash'
import { useValueChangeEffect } from '@crew/hooks'
import classNames from 'classnames'

const MIN_SPACE_FOR_DOWNWARD = 200 // Minimum space needed below for downward opening
const MIN_SPACE_FOR_RIGHTWARD = 100 // Minimum space needed on the right for rightward opening

export const DropDownButtonSize = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DropDownButtonSize =
  (typeof DropDownButtonSize)[keyof typeof DropDownButtonSize]

export const DropDownButtonType = {
  primary: 'primary',
  success: 'success',
  danger: 'danger',
  normal: 'normal',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DropDownButtonType =
  (typeof DropDownButtonType)[keyof typeof DropDownButtonType]

export const StylingMode = {
  text: 'text',
  outlined: 'outlined',
  contained: 'contained',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type StylingMode = (typeof StylingMode)[keyof typeof StylingMode]

type CrewDropDownButtonProps<TItem> = {
  type?: DropDownButtonType
  stylingMode?: StylingMode
  className?: string
  items: TItem[]
  onButtonClick?: (selectedItem?: TItem) => void
  onItemClick?: (itemData: TItem) => void
  splitButton?: boolean
  keyExpr: keyof TItem
  displayExpr: keyof TItem
  render?: (itemData?: TItem) => React.ReactElement
  itemRender?: (itemData: TItem) => React.ReactElement
  disabled?: boolean
  buttonSize?: DropDownButtonSize
  itemSize?: DropDownButtonSize
  selectedItemKey?: string | number
  selectedItem?: TItem
  text?: string
  icon?: React.ReactNode
  visible?: boolean
  container?: React.RefObject<HTMLDivElement>
  showArrowIcon?: boolean
  showDivider?: boolean
  highlightSelected?: boolean
  itemStylingMode?: StylingMode
  onShowing?: () => void
  onHiding?: () => void
}

const CrewDropDownButtonComponent = <TItem,>(
  {
    items,
    type = 'normal',
    stylingMode = 'contained',
    onButtonClick,
    onItemClick,
    buttonSize = 'md',
    itemSize = 'md',
    disabled,
    keyExpr,
    displayExpr,
    render,
    itemRender,
    splitButton,
    className,
    selectedItemKey,
    text,
    icon,
    visible,
    container,
    showArrowIcon = true,
    showDivider = true,
    highlightSelected = true,
    itemStylingMode,
    onShowing,
    onHiding,
    ...rest
  }: CrewDropDownButtonProps<TItem>,
  ref: React.Ref<HTMLButtonElement>
) => {
  const [selectedKey, setSelectedKey] = useState<string | number>('')
  const [selectedItem, setSelectedItem] = useState<TItem>()
  const [dropDownOpen, setDropDownOpen] = useState(false)
  const [dropDownStyle, setDropDownStyle] =
    useState<React.CSSProperties | null>(null)

  const buttonRef = useRef<HTMLDivElement>(null)

  // Position calculation logic
  const updatePosition = useCallback(() => {
    if (!buttonRef.current) return

    const buttonRect = buttonRef.current.getBoundingClientRect()
    const screenHeight = window.innerHeight
    const screenWidth = window.innerWidth

    // Calculate available vertical space
    const spaceAbove = buttonRect.top
    const spaceBelow = screenHeight - buttonRect.bottom

    // Decide whether to open upward or downward
    const shouldOpenUpward =
      spaceBelow < MIN_SPACE_FOR_DOWNWARD && spaceAbove > spaceBelow

    // Calculate vertical position
    let top = shouldOpenUpward ? buttonRect.y : buttonRect.y + buttonRect.height

    // Calculate available horizontal space
    const spaceLeft = buttonRect.left
    const spaceRight = screenWidth - buttonRect.right

    // Decide whether to open leftward or rightward
    const shouldOpenLeftward =
      spaceRight < MIN_SPACE_FOR_RIGHTWARD && spaceLeft > spaceRight

    // Calculate horizontal position and ensure it stays within screen bounds
    let left = buttonRect.x
    const rightEdge = left + buttonRect.width

    if (rightEdge > screenWidth) {
      left = screenWidth - buttonRect.width
    }

    const dropDownBaseStyle = {
      minWidth: buttonRect.width,
      top,
      transform: shouldOpenUpward ? 'translateY(-100%)' : 'translateY(0)',
    }

    setDropDownStyle(() => {
      if (shouldOpenLeftward) {
        return {
          ...dropDownBaseStyle,
          right: spaceRight,
        }
      } else {
        return {
          ...dropDownBaseStyle,
          left,
        }
      }
    })
  }, [])

  // Effect for position updates
  useEffect(() => {
    // Debounce the position update function to prevent excessive calls
    const debouncedUpdate = debounce(updatePosition, 100)

    // Attach event listeners for resize events
    // Use the debounced version to handle rapid event firing efficiently
    window.addEventListener('resize', debouncedUpdate)

    // Perform an initial position update when the effect is first run
    updatePosition()

    // Cleanup function to remove event listeners and cancel debounce when the component unmounts
    return () => {
      window.removeEventListener('resize', debouncedUpdate)
      debouncedUpdate.cancel() // Cancel any pending debounced function calls
    }
  }, [updatePosition])

  // Effect to trigger position update when `dropDownOpen` changes
  useValueChangeEffect(
    () => {
      // Ensure the position is updated immediately
      updatePosition()
    },
    [dropDownOpen, updatePosition],
    dropDownOpen
  )

  useEffect(() => {
    if (
      typeof selectedItemKey === 'number' ||
      typeof selectedItemKey === 'string'
    ) {
      setSelectedKey(selectedItemKey)
      const item = items.find((item) => item[keyExpr] === selectedItemKey)
      setSelectedItem(item)
    } else if (rest.selectedItem) {
      setSelectedKey(rest.selectedItem[keyExpr] as string | number)
      setSelectedItem(rest.selectedItem)
    }
  }, [items, keyExpr, rest.selectedItem, selectedItem, selectedItemKey])

  // Handle item button click
  const handleDropDownButtonItemClick = useCallback(
    (itemData: TItem) => {
      if (onItemClick) {
        onItemClick(itemData)
      }
      setSelectedKey(itemData[keyExpr] as string | number)
      setSelectedItem(itemData)
    },
    [keyExpr, onItemClick]
  )

  // Render drop down button item
  const renderItem = useCallback(
    (item: TItem) => {
      return (
        <DropDownItem
          itemData={item}
          displayExpr={displayExpr}
          itemRender={itemRender}
          onItemClick={handleDropDownButtonItemClick}
          isSelected={selectedKey === item[keyExpr]}
          type={type}
          stylingMode={itemStylingMode || stylingMode}
          size={itemSize}
          highlightSelected={highlightSelected}
        />
      )
    },
    [
      displayExpr,
      itemRender,
      handleDropDownButtonItemClick,
      selectedKey,
      keyExpr,
      type,
      itemStylingMode,
      stylingMode,
      itemSize,
      highlightSelected,
    ]
  )

  return (
    <Menu as="div" className="relative inline-block">
      {({ open }) => {
        setDropDownOpen(open)
        // Call onShowing or onHiding callback
        if (open && onShowing) {
          onShowing()
        } else if (!open && onHiding) {
          onHiding()
        }
        return (
          <>
            <div ref={buttonRef}>
              {splitButton ? (
                <DropDownSplitButton
                  disabled={disabled}
                  icon={icon}
                  onButtonClick={onButtonClick}
                  render={render}
                  selectedItem={selectedItem}
                  size={buttonSize}
                  stylingMode={stylingMode}
                  text={text}
                  type={type}
                  showArrowIcon={showArrowIcon}
                />
              ) : (
                <DropDownButton
                  disabled={disabled}
                  icon={icon}
                  onButtonClick={onButtonClick}
                  render={render}
                  selectedItem={selectedItem}
                  size={buttonSize}
                  stylingMode={stylingMode}
                  text={text}
                  type={type}
                  showArrowIcon={showArrowIcon}
                />
              )}
            </div>
            {dropDownStyle && (
              <Transition
                as="div"
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                {createPortal(
                  <Menu.Items
                    static
                    className={classNames(
                      'fixed z-50 w-auto py-1 origin-top-right divide-gray-100 rounded-md crew-bg-default shadow-lg ring-1 ring-black/5 focus:outline-none',
                      showDivider && 'divide-y'
                    )}
                    style={dropDownStyle}
                  >
                    {/* Render drop down button item */}
                    {items.map((item) => renderItem(item))}
                  </Menu.Items>,
                  container?.current || document.body
                )}
              </Transition>
            )}
          </>
        )
      }}
    </Menu>
  )
}

export const CrewDropDownButton = genericMemo(
  forwardRef(CrewDropDownButtonComponent) as unknown as <TItem>(
    props: CrewDropDownButtonProps<TItem> & RefAttributes<HTMLButtonElement>
  ) => ReturnType<typeof CrewDropDownButtonComponent>
)

// Define a mapping object for button size
export const DropDownButtonSizeClassNamesMap = {
  xs: 'py-0.5 px-0.5 text-xs',
  sm: 'py-1 px-1 text-sm',
  md: 'py-1.5 px-3 text-base',
  lg: 'py-2 px-3 text-lg',
  xl: 'py-2.5 px-3 text-xl',
} as const satisfies { [key in DropDownButtonSize]: string }

// Define a mapping object for button types and styling modes
export const DropDownButtonClassNamesMap = {
  primary: {
    contained: 'crew-action-default',
    outlined: 'crew-action-default-outlined',
    text: 'crew-action-default-text',
  },
  success: {
    contained: 'crew-action-success',
    outlined: 'crew-action-success-outlined',
    text: 'crew-action-success-text',
  },
  danger: {
    contained: 'crew-action-danger',
    outlined: 'crew-action-danger-outlined',
    text: 'crew-action-danger-text',
  },
  normal: {
    contained: 'crew-action-normal',
    outlined: 'crew-action-normal-outlined',
    text: 'crew-action-normal-text',
  },
} as const satisfies {
  [key in DropDownButtonType]: {
    [key in StylingMode]: string
  }
}

export const SplitLineClassNamesMap = {
  primary: {
    contained: 'border-crew-blue-50 dark:border-crew-blue-50',
    outlined: 'border-crew-blue-500 dark:border-crew-blue-500',
    text: 'border-crew-blue-500 dark:border-crew-blue-500',
  },
  success: {
    contained: 'border-crew-green-50 dark:border-crew-green-50',
    outlined: 'border-crew-green-500 dark:border-crew-green-500',
    text: 'border-crew-green-500 dark:border-crew-green-500',
  },
  danger: {
    contained: 'border-crew-red-50 dark:border-crew-red-50',
    outlined: 'border-crew-red-500 dark:border-crew-red-500',
    text: 'border-crew-red-500 dark:border-crew-red-500',
  },
  normal: {
    contained: 'border-crew-gray-50 dark:border-crew-gray-50',
    outlined: 'border-crew-gray-900 dark:border-crew-gray-50',
    text: 'border-crew-gray-900 dark:border-crew-gray-50',
  },
} as const satisfies {
  [key in DropDownButtonType]: {
    [key in StylingMode]: string
  }
}
