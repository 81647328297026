import { FC, Fragment, memo, useCallback, useState } from 'react'

import { ChatView } from '@crew/enums/app'

import { CrewUnreadLine } from 'components/elements/crewUnreadLine/crewUnreadLine'

import { ChatThreadMessageListItem } from './components/chatThreadMessageListItem/chatThreadMessageListItem'
import { useChatThreadMessageList } from './useChatThreadMessageList'
import { getChatMessageListItemDomId } from 'utils/chat'
import { useAppSelector } from 'states/hooks'
import { ChatReadStatusDialog } from 'features/chat/components/chatReadStatusDialog/chatReadStatusDialog'
import { useTranslation } from '@crew/modules/i18n'
import { useModal } from 'components/layouts/modal/useModal'

type ChatThreadMessageListProps = {
  selectedItemId?: string | undefined // 選択中のメッセージ。ハイライト用に使用する
}

export const ChatThreadMessageList: FC<ChatThreadMessageListProps> = memo(
  (props) => {
    const {
      displayItems,
      itemsScrollableDivRef,
      messageInView,
      messageOutOfView,
    } = useChatThreadMessageList()

    const { t } = useTranslation()
    const [
      isOpenedReadStatusDialog,
      openReadStatusDialog,
      closeReadStatusDialog,
    ] = useModal()

    const [selectedReadStatusMessageId, setSelectedReadStatusMessageId] =
      useState<string>('')

    // 処理対象のチャットスレッドをViewModelから取得
    const currentChatThread = useAppSelector(
      (state) => state.message.chat.current.chatThread
    )

    // メッセージ全体表示イベントハンドラ
    const handleMessageInView = useCallback(
      (inView: boolean, id: string, messageId: string) => {
        if (inView) {
          messageInView(id, messageId)
        } else {
          messageOutOfView(messageId)
        }
      },
      [messageInView, messageOutOfView]
    )

    // Open ReadStatusDialog
    const handleClickChatReadStatus = useCallback(
      (chatMessageId: string) => {
        setSelectedReadStatusMessageId(chatMessageId)
        openReadStatusDialog()
      },
      [openReadStatusDialog]
    )

    // 表示に必要なデータが指定されていない場合、表示することができないので、エラーを表示する
    if (!currentChatThread) {
      return null
    }

    return (
      <div
        className="grow flex overflow-y-scroll flex-col-reverse"
        ref={itemsScrollableDivRef}
      >
        <div className="grow flex flex-col">
          {/* スレッド表示を行う対象のトピック */}
          <ChatThreadMessageListItem
            id="fixed_header" // 用途は特にないがListItem側でidを必須にする兼ね合いで設定
            itemId="fixed_header_item" // 同上
            chatMessageId={currentChatThread.topicId}
            selectedMessageId={props.selectedItemId}
            onClickChatReadStatus={handleClickChatReadStatus}
          />
          {/*
          トピックと返信リストの間の区切り線
          CrewChatDividerを使用していないのは区切り線の高さを持たせないため
        */}
          <div className="px-2">
            <hr className="border-t" />
          </div>
          {/* 返信リスト */}
          {displayItems.map((item) => (
            <Fragment key={item.id}>
              <CrewUnreadLine show={item.isFirstUnreadMessage} />
              <ChatThreadMessageListItem
                key={item.id}
                // スクロール位置制御のためChatViewを含めたidとして指定する
                id={getChatMessageListItemDomId(
                  item.messageId,
                  ChatView.Thread
                )}
                itemId={item.id}
                chatMessageId={item.messageId}
                container={itemsScrollableDivRef}
                onMessageInView={handleMessageInView}
                onAdditionalLoading={item.handleAdditionalLoading}
                onFirstUnreadMessageViewed={item.handleFirstUnreadMessageViewed}
                isFirstUnreadMessage={item.isFirstUnreadMessage}
                selectedMessageId={props.selectedItemId}
                onClickChatReadStatus={handleClickChatReadStatus}
              />
            </Fragment>
          ))}
        </div>

        {/* 既読状況 */}
        {selectedReadStatusMessageId && (
          <ChatReadStatusDialog
            isOpen={isOpenedReadStatusDialog}
            title={t('label.readStatus')}
            messageId={selectedReadStatusMessageId}
            onClose={closeReadStatusDialog}
          />
        )}
      </div>
    )
  }
)
