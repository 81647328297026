import { memo, FC } from 'react'
import { Modal } from 'components/layouts/modal/modal'
import { TaskCategoryEntryForm } from './components/taskCategoryEntryForm/taskCategoryEntryForm'

type PropsMode = {
  isEditMode: boolean
  title: string
  isOpen: boolean
  onClose: () => void
  taskCategoryId: string | null
  version: number | null
}

export const TaskCategoryEntryDialog: FC<PropsMode> = memo((props) => {
  return (
    <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
      <TaskCategoryEntryForm
        taskCategoryId={props.taskCategoryId}
        isEditMode={props.isEditMode}
        onSubmit={props.onClose}
        onCancel={props.onClose}
      />
    </Modal>
  )
})
