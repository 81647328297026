import { MessageType } from '@crew/enums/domain'
import { useProjectPermissions, useValueChangeEffect } from '@crew/hooks'
import classNames from 'classnames'
import { CrewChatMessageActionMenu } from 'components/elements/crewChatMessageItem/components/crewChatMessageActionMenu/crewChatMessageActionMenu'
import { AvatarPosition } from 'components/elements/crewChatMessageItem/components/crewChatMessageItemAvatar/crewChatMessageItemAvatar'
import { CrewEditMessageItem } from 'components/elements/crewMessageItem/components/crewEditMessageItem/crewEditMessageItem'
import { CrewNormalMessageItem } from 'components/elements/crewMessageItem/components/crewNormalMessageItem/crewNormalMessageItem'
import { CrewNotificationMessageItem } from 'components/elements/crewMessageItem/components/crewNotificationMessageItem/crewNotificationMessageItem'
import { ShowReplyButtonType, ShowReactionType } from '@crew/utils/chat'
import { ADDITIONAL_LOADING_TRIGGER_INTERSECTION_ROOT_MARGIN } from 'configs/constants'
import { FC, memo, RefObject, useCallback, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useAppSelector } from 'states/hooks'
import { MessageItemType } from '../../useChatTimelineMessageList'
import { TopicSummaryMessageItem } from './components/topicSummaryMessageItem/topicSummaryMessageItem'
import { useChatTimelineMessageListItem } from './useChatTimelineMessageListItem'

export type ChatTimelineMessageListItemProps = {
  id: string

  // アイテムのタイプ。描画の切り替えに使用する
  type: MessageItemType

  // アイテムID
  itemId: string

  // チャットメッセージID
  chatMessageId: string

  // トピックID。返信ボタンをクリックした際にスレッドに表示するために使用する
  topicId: string

  // 再掲トピックの場合は指定不要
  container?: RefObject<HTMLDivElement>

  // 再掲トピックの場合は指定不要
  onMessageInView?: (inView: boolean, id: string, messageId: string) => void

  // 再掲トピックの場合は指定不要。再掲トピックでなく且つ追加読込が無い場合は「undefinedを格納した変数」が渡されるため、undefinedも受け取れる型として明示しておく
  onAdditionalLoading?: (() => void) | undefined

  // 再掲トピックの場合は指定不要。画面内に最終既読メッセージが表示されているときに発火する
  onFirstUnreadMessageViewed?: (() => void) | undefined

  // 再掲トピックの場合は指定不要。選択されたメッセージのハイライト用に使用する
  selectedItemId?: string

  // 再掲トピックの場合は指定不要。返信ボタンが押された際に選択されたメッセージを更新するために使用する
  setSelectedItemId?: (messageId: string) => void

  // 未読ラインが表示されているメッセージかどうか。再掲トピックの場合は指定不要。
  isFirstUnreadMessage?: boolean

  onClickChatReadStatus?: (chatMessageId: string) => void // A function to call when viewing the chat message read status
}

/**
 * Timeline形式のメッセージアイテム
 * messageTypeに応じて表示コンポーネントを出し分ける
 */
export const ChatTimelineMessageListItem: FC<ChatTimelineMessageListItemProps> =
  memo((props) => {
    const {
      message,
      isError,
      visibleActionMenu,
      showActionMenu,
      hideActionMenu,
      toggleEditMode,
      cancelEditMode,
      openTargetThread,
      updateChatMessage,
      isMyMessage,
      isEditMode,
    } = useChatTimelineMessageListItem(props.chatMessageId, props.topicId)

    // 上端、下端、メッセージ本体の要素を表示したかどうかのフラグ
    const [isShownTopDiv, setIsShowTopDiv] = useState(false)
    const [isShownBottomDiv, setIsShownBottomDiv] = useState(false)
    const [isShownMessageDiv, setIsShownMessageDiv] = useState(false)

    // ------------------------------ イベントハンドラ ------------------------------
    // propsの関数は代入して使用（useEffect内で使用時、依存配列にpropsが入ってしまうため）
    const handleMessageInView = props.onMessageInView
    const handleFirstUnreadMessageViewed = props.onFirstUnreadMessageViewed

    // 返信ボタンクリックイベントハンドラ
    const handleReplyButtonClick = useCallback(() => {
      // 返信ボタンをクリックされた投稿に紐づくスレッドを表示する
      openTargetThread()

      // ハイライト表示するため、選択されたメッセージを更新する
      props.setSelectedItemId && message && props.setSelectedItemId(message?.id)
    }, [message, openTargetThread, props])

    // マウスホバー時にアクションメニューを表示する
    const handleMouseEnter = useCallback(() => {
      showActionMenu()
    }, [showActionMenu])

    // マウスが離れたらアクションメニューを非表示にする
    const handleMouseLeave = useCallback(() => {
      hideActionMenu()
    }, [hideActionMenu])

    // 「編集」押下時に編集モードを切り替える
    const handleEditButtonClick = useCallback(() => {
      toggleEditMode()
    }, [toggleEditMode])

    // 編集モードの登録 / キャンセル時に編集モードをoffにする
    const handleEditModeCancel = useCallback(() => {
      cancelEditMode()
    }, [cancelEditMode])

    // 添付ファイル削除後にメッセージ更新APIを実行してwebsocketによる表示の更新を行う
    const handleFileDeleted = useCallback(async () => {
      updateChatMessage()
    }, [updateChatMessage])

    // メッセージの要素が一部でも表示されたらメッセージの表示フラグを更新する
    const { ref: messageRef } = useInView({
      threshold: 0, // 一部でも表示したときに発火する
      root: props.container?.current,
      onChange: (inView) => {
        setIsShownMessageDiv(inView)
        // メッセージの要素が非表示になった場合は、上端・下端の要素も非表示になったとみなす
        if (!inView) {
          setIsShowTopDiv(false)
          setIsShownBottomDiv(false)
        }
      },
    })

    // 上端の要素が表示されたら表示フラグを更新する
    const { ref: topRef } = useInView({
      root: props.container?.current,
      onChange: (inView) => {
        // 上端の要素が非表示になった場合でも、メッセージの要素が表示されている場合は表示したことがあるとみなす
        if (!inView && isShownMessageDiv) {
          return
        }

        setIsShowTopDiv(inView)
      },
    })

    // 下端の要素が表示されたら表示フラグを更新する
    const { ref: bottomRef } = useInView({
      root: props.container?.current,
      onChange: (inView) => {
        // 下端の要素が非表示になった場合でも、メッセージの要素が表示されている場合は表示したことがあるとみなす
        if (!inView && isShownMessageDiv) {
          return
        }
        setIsShownBottomDiv(inView)
      },
    })

    const isFirstUnreadMessage = props.isFirstUnreadMessage
    // 表示状態が更新されたら各イベントハンドラを実行する
    useValueChangeEffect(
      () => {
        if (isShownTopDiv && isShownBottomDiv) {
          /* メッセージ表示（上端、下端どちらも表示したことがある） */

          // 既読管理やスクロール位置の保持を行うイベントハンドラ実行
          handleMessageInView?.(true, props.itemId, props.chatMessageId)

          // 画面内に最終既読メッセージが表示された際のイベントハンドラ実行
          // （最終既読メッセージでない場合はpropsにイベントハンドラが指定されないため、何も動作しない）
          handleFirstUnreadMessageViewed?.()
        } else if (!isShownTopDiv && !isShownBottomDiv) {
          /* メッセージ非表示（上端、下端どちらも表示したことがない） */

          // 既読管理やスクロール位置の保持を行うイベントハンドラ実行
          handleMessageInView?.(false, props.itemId, props.chatMessageId)
        }
      },
      [
        handleMessageInView,
        handleFirstUnreadMessageViewed,
        isShownBottomDiv,
        isShownTopDiv,
        props.chatMessageId,
        props.itemId,
      ],
      { isShownTopDiv, isShownBottomDiv, isFirstUnreadMessage },
      false,
      (prev, next) =>
        prev.isShownTopDiv === next.isShownTopDiv &&
        prev.isShownBottomDiv === next.isShownBottomDiv &&
        prev.isFirstUnreadMessage === next.isFirstUnreadMessage
    )

    // アイテムが表示領域に近づいたら追加ロードイベントハンドラを呼ぶ
    const { ref: loadingTriggerRef } = useInView({
      rootMargin: ADDITIONAL_LOADING_TRIGGER_INTERSECTION_ROOT_MARGIN, // 表示領域に「近づいた」をトリガーにするため、領域の外側にマージンを付与する
      root: props.container?.current,
      onChange: (inView) => {
        // このイベントは、アイテムと判定領域の重なり方の割合がthreshold(デフォルト: 0)を越えた場合に発火する。
        // 近づいた場合と離れた場合のどちらも発火するが、inViewの値で方向を判定することができる。
        //   近づいた場合: true 離れた場合: false
        if (inView) {
          // アイテムが近づいてきた場合、追加読込を行う
          props.onAdditionalLoading?.()
        }
      },
    })

    // 処理対象のチャットルームをViewModelから取得
    const currentChatRoom = useAppSelector(
      (state) => state.message.chat.current.chatRoom
    )

    const {
      hasPrjFileCreatePermission,
      hasPrjFileDownloadPermission,
      hasPrjFileDeletePermission,
    } = useProjectPermissions(
      currentChatRoom?.rootEntityType,
      currentChatRoom?.rootEntityRecordId
    )

    // 選択中メッセージが自メッセージかどうかを判定
    const isHighlighted =
      props.selectedItemId && message?.id === props.selectedItemId

    // メッセージの表示コンポーネントを取得
    const renderChatMessageItem = useCallback(() => {
      // メッセージが取得できない場合は何も表示しない
      if (!message) return null

      // 編集モードの場合は編集用コンポーネントを表示
      if (isEditMode) {
        return (
          <CrewEditMessageItem
            message={message}
            onEditModeCancel={handleEditModeCancel}
            canUploadFile={hasPrjFileCreatePermission}
            // 返信メッセージの場合はアバターサイズを小さくする
            isLargeAvatar={
              props.type === MessageItemType.ReplyMessage ? false : true
            }
            // 返信メッセージの場合はアバターを右寄せする
            avatarPosition={
              props.type === MessageItemType.ReplyMessage
                ? AvatarPosition.Right
                : AvatarPosition.Center
            }
          />
        )
      }

      // messageTypeに応じて表示コンポーネントを出し分ける
      switch (message.messageType) {
        // 通常投稿メッセージ
        case MessageType.MessageNormal:
          switch (props.type) {
            // 通常投稿メッセージと返信メッセージ
            case MessageItemType.NormalMessage:
            case MessageItemType.ReplyMessage:
              return (
                <CrewNormalMessageItem
                  message={message}
                  showRelatedLink={true} // 関連先リンクを表示する
                  // 自分の投稿でありファイル削除権限がある場合は削除ボタンを表示する
                  showDeleteAttachmentButton={
                    isMyMessage && hasPrjFileDeletePermission
                  }
                  onAttachmentFileDeleted={handleFileDeleted}
                  // ファイルダウンロード権限がある場合は添付ファイルをダウンロードできるようにする
                  canDownloadAttachment={hasPrjFileDownloadPermission}
                  highlightKeyword=""
                  onReplyClick={handleReplyButtonClick}
                  // 返信メッセージの場合はアバターサイズを小さくする
                  isLargeAvatar={
                    props.type === MessageItemType.ReplyMessage ? false : true
                  }
                  // 返信メッセージの場合はアバターを右寄せする
                  avatarPosition={
                    props.type === MessageItemType.ReplyMessage
                      ? AvatarPosition.Right
                      : AvatarPosition.Center
                  }
                  showReplyButtonType={ShowReplyButtonType.ShowWithoutCount} // 再掲トピックでない通常投稿は「返信」ボタンを表示する
                  showReactionsAndReactionButton={
                    ShowReactionType.ShowAndButton
                  } // 再掲トピックでない通常投稿はリアクションボタンを表示する
                  replyCount={undefined} // タイムライン形式では「返信」という表示になるのでundefinedを指定する
                  onClick={undefined}
                />
              )
            // 再掲されるトピック
            case MessageItemType.TopicSummary:
              return (
                <TopicSummaryMessageItem
                  message={message}
                  highlightKeyword=""
                />
              )
            default:
              return null
          }

        // 自動投稿メッセージ
        case MessageType.TaskAdded:
        case MessageType.TaskUpdated:
        case MessageType.TaskDeleted:
        case MessageType.TaskCommentUpdated:
        case MessageType.FileAdded:
        case MessageType.FileUpdated:
        case MessageType.ProjectMemberJoined:
        case MessageType.ProjectMemberLeft:
        case MessageType.ProjectMemberRequested:
        case MessageType.EventAdded:
        case MessageType.EventUpdated:
        case MessageType.EventDeleted:
        case MessageType.EventJoined:
          return (
            <CrewNotificationMessageItem
              message={message}
              showRelatedLink={true} // 関連先リンクを表示する
              // ファイルダウンロード権限がある場合は添付ファイルをダウンロードできるようにする
              canDownloadAttachment={hasPrjFileDownloadPermission}
              onReplyClick={handleReplyButtonClick}
              // 再掲トピックの場合はアイコン表示するためユーザーアバターを省略する
              omitUserAvatar={
                props.type === MessageItemType.TopicSummary ? true : false
              }
              // 再掲トピックの場合は返信ボタンとリアクションボタンを表示しない
              showReplyButtonType={
                props.type === MessageItemType.TopicSummary
                  ? ShowReplyButtonType.None
                  : ShowReplyButtonType.ShowWithoutCount
              }
              showReactionsAndReactionButton={
                props.type === MessageItemType.TopicSummary
                  ? ShowReactionType.None
                  : ShowReactionType.ShowAndButton
              }
              truncateMessage={
                props.type === MessageItemType.TopicSummary ? true : false
              }
              replyCount={undefined} // タイムライン形式では「返信」という表示になるのでundefinedを指定する
              onClick={undefined}
            />
          )
        default:
          return null
      }
    }, [
      handleEditModeCancel,
      handleFileDeleted,
      handleReplyButtonClick,
      hasPrjFileCreatePermission,
      hasPrjFileDeletePermission,
      hasPrjFileDownloadPermission,
      isEditMode,
      isMyMessage,
      message,
      props.type,
    ])

    // 表示に必要なデータが指定されていない場合、表示することができないので、エラーを表示する
    if (!currentChatRoom) {
      return null
    }

    // エラーが発生している場合はエラーが発生した旨を表示する
    if (isError) {
      return null
    }

    // 表示に必要なデータがない場合は何も表示しない
    // 当初「読み込み中」を表示しようとしていたが、メッセージごとにその表示が出てしまうと見栄えが悪かったので表示しないようにした
    if (!message) {
      return null
    }

    return (
      <div
        id={props.id}
        className={classNames(
          'flex flex-col crew-border-gray relative',
          isHighlighted && 'bg-crew-blue-1-light dark:bg-crew-blue-3-dark' // 対象メッセージが選択されている場合ハイライトする
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* メッセージ上端の要素。既読処理に使用するのみで画面には何も表示しない */}
        <div ref={topRef} className="-z-10"></div>

        {props.onAdditionalLoading && (
          <div
            ref={loadingTriggerRef}
            className="absolute left-0 top-0 right-0 bottom-0 -z-10"
          />
        )}

        {/* メッセージ */}
        <div ref={messageRef}>{renderChatMessageItem()}</div>

        {props.type !== MessageItemType.TopicSummary && (
          // ホバーメニュー
          // メッセージ枠右上にホバーメニューを表示するためabsoluteを使用
          <div className="absolute top-0 right-0">
            <CrewChatMessageActionMenu
              visible={visibleActionMenu}
              isBookmarkedMessage={
                message.bookmarks ? message.bookmarks.length > 0 : false
              }
              disabledChatReadStatus={!isMyMessage}
              bookmarkMessages={message.bookmarks}
              chatMessageId={props.chatMessageId}
              chatMessageVersion={message.version}
              // 自分が投稿したメッセージでない場合や通常メッセージでない(現状だと自動投稿メッセージである)場合はコンテキストメニューを使用できなくする
              disabledContextMenu={
                !isMyMessage ||
                message.messageType !== MessageType.MessageNormal
              }
              onEditButtonClick={handleEditButtonClick}
              onClickChatReadStatus={props.onClickChatReadStatus}
            />
          </div>
        )}

        {/* メッセージ下端の要素。既読処理に使用するのみで画面には何も表示しない */}
        <div ref={bottomRef} className="-z-10"></div>
      </div>
    )
  })
