import { CrewButton } from 'components/elements/crewButton/crewButton'
import { FC, memo, useState } from 'react'
import { useInviteNewUsersToolbar } from './useInviteNewUsersToolbar'
import { CrewDatePickerField } from 'components/forms/crewDatePickerField'
import { DatePickerDateFormat } from 'enums/system'
import { useTranslation } from '@crew/modules/i18n'
import { useCallback, useMemo } from 'react'
import dayjs from '@crew/modules'
import { useTenantUserEntryContext } from '../../useTenantUserEntryDialog'
import { ComponentCallbackHandler, uniqueString } from '@crew/utils'
import { CrewTagBoxField } from 'components/forms/crewTagBoxField'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import {
  InvitationRoleType,
  SettingKeyType,
  ContractPlan,
} from '@crew/enums/app'
import { GetTenantSettingsRequest } from '@crew/apis/setting/models/getTenantSettings/request'
import { useGetTenantSettingsQuery } from '@crew/apis/setting/settingApis'
import {
  ReduceReturnType,
  FormValues,
  UserTagBoxType,
} from './useInviteNewUsersToolbar'
import { CrewPopover } from 'components/devextreme/crewPopover'
import HelpOutline from '~icons/material-symbols/help-outline'
import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import { CrewSelectBox } from 'components/devextreme/crewSelectBox'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { useModal } from 'components/layouts/modal/useModal'
import { useAppSelector } from 'states/hooks'

// Horizontal initialization value of popover
const MAX_WITH_POPOVER = 300

type HelpIconProps = {
  target: string
  content: string
}

const HelpIcon: FC<HelpIconProps> = memo((props) => {
  // ユニークキー生成
  const popoverKey = useMemo(() => uniqueString(), [])
  return (
    <>
      <span id={`${props.target}-${popoverKey}`}>
        <HelpOutline width={20} height={20} />
      </span>
      <CrewPopover
        target={`#${props.target}-${popoverKey}`}
        showEvent="mouseenter"
        hideEvent="mouseleave"
        position="top"
        maxWidth={MAX_WITH_POPOVER}
      >
        <span className="text-sm">{props.content}</span>
      </CrewPopover>
    </>
  )
})

export const InviteNewUsersToolbar = memo(() => {
  const {
    control,
    handleSubmit,
    reset,
    formState,

    setError,
    setValue,
    getValues,

    validateRules,

    newUserDataSource,
    isDisableDates,
    tenantRoleDataSource,
    addUsers,
    verifyUserPendings,
    isLoadingVerifyUserPendings,
  } = useInviteNewUsersToolbar()

  const { t } = useTranslation()

  const currentPlan = useAppSelector((state) => state.app.currentPlan)

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  const { setSelectedNewUsers, selectedNewUsers } = useTenantUserEntryContext()

  const [selectedTenantRoleType, setSelectedTenantRoleType] = useState<string>(
    InvitationRoleType.Internal.key
  )

  // 組織設定の「外部ユーザーの有効期限(初期値)」を取得
  const getTenantSettingsRequestParams: GetTenantSettingsRequest = {
    keys: [SettingKeyType.OrganizationExternalUserExpirationDate],
  }
  const { data: getTenantSettings } = useGetTenantSettingsQuery(
    getTenantSettingsRequestParams
  )

  // 有効期限の初期値
  const defaultExpiredDate = useMemo(() => {
    //convert tenant settings to object
    const tenantSettings =
      getTenantSettings?.tenantSettings.reduce<ReduceReturnType>(
        (tenantSetting, currentValue) => ({
          ...tenantSetting,
          [currentValue.key]: currentValue.value,
        }),
        {}
      )

    const expirationDate =
      tenantSettings?.[SettingKeyType.OrganizationExternalUserExpirationDate]

    // 有効期限には「当日」+「組織設定の外部ユーザーの有効期限(初期値)」を初期表示する
    return dayjs(Date.now())
      .add(expirationDate ? Number(expirationDate) : 0, 'day')
      .toDate()
  }, [getTenantSettings])

  const canSend = useMemo(
    () =>
      Object.keys(formState.errors).length === 0 &&
      !formState.isSubmitting &&
      !isLoadingVerifyUserPendings,
    [formState, isLoadingVerifyUserPendings]
  )

  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  // すでに存在するタグを入力しEnterを押した場合もこのイベントが発生する
  const handleAddCustomUserValue = useCallback<
    ComponentCallbackHandler<typeof CrewTagBoxField, 'onCustomItemCreating'>
  >(
    (args) => {
      if (!args.text) {
        return
      }

      let newUser: UserTagBoxType = {
        value: args.text,
      }

      // カスタム値の登録（{ id: tagId | undefined, name: 入力値 }）
      // カスタムデータソースのinsert内でAPIによるtagIdの取得を試みる
      args.customItem = args.component
        .getDataSource()
        .store()
        .insert(newUser)
        .then((savedUser: UserTagBoxType) => {
          newUser = savedUser //レスポンスで差し替え
          return newUserDataSource.load()
        })
        .then(() => newUser)
    },
    [newUserDataSource]
  )

  // Add new users and reset form
  const addSelectedNewUsersAndResetForm = useCallback(
    (data: FormValues) => {
      const newUsers = addUsers(data, selectedNewUsers)

      setSelectedNewUsers(newUsers)
      reset()
    },
    [addUsers, reset, selectedNewUsers, setSelectedNewUsers]
  )

  //action when add button is clicked
  const handleAddButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        // Verify user list
        const emailOrLoginIds = data.newUsers.map((user) => user)

        const result = await verifyUserPendings(
          data.invitationRoleType,
          emailOrLoginIds
        )

        if (result.hasExternalUser) {
          openConfirmDialog()
          return
        }

        // Add new users and reset form
        addSelectedNewUsersAndResetForm(data)
      } catch (err) {
        showApiErrors(err)
      }
    }
    handleSubmit(onSubmit)()
  }, [
    handleSubmit,
    verifyUserPendings,
    addSelectedNewUsersAndResetForm,
    openConfirmDialog,
    showApiErrors,
  ])

  // Event handle when confirm dialog OK button is clicked
  const handlePermitButtonClick = useCallback(async () => {
    // get form data
    const data = getValues()

    // Add new users and reset form
    addSelectedNewUsersAndResetForm(data)

    closeConfirmDialog()
  }, [addSelectedNewUsersAndResetForm, closeConfirmDialog, getValues])

  // Event when tenant role type is changed
  const handleTenantRoleTypeChange = useCallback<
    ComponentCallbackHandler<typeof CrewSelectBox, 'onValueChanged'>
  >(
    (event) => {
      setSelectedTenantRoleType(event.value)
      if (event.value === InvitationRoleType.External.key) {
        setValue(
          'expirationDatetime',
          getValues('expirationDatetime') ?? defaultExpiredDate
        )
      }
    },
    [defaultExpiredDate, getValues, setValue]
  )

  return (
    <form className="flex flex-col gap-y-2.5">
      {/* tenant role */}
      <div className="flex flex-col gap-y-1">
        <div className="flex flex-row items-center">
          <p>{t('label.tenantInviteUserRoleLabel')}</p>

          {currentPlan !== ContractPlan.Free && (
            <HelpIcon
              target="tenant-role"
              content={t('label.organizationRoleHelpText')}
            />
          )}
        </div>
        <CrewSelectBoxField
          id="invitationRoleType"
          control={control}
          name="invitationRoleType"
          dataSource={tenantRoleDataSource}
          valueExpr="id"
          displayExpr="name"
          searchEnabled={false}
          searchExpr="name"
          minSearchLength={0}
          required={true}
          rules={validateRules.invitationRoleType}
          className="w-1/4"
          showClearButton={false}
          onValueChanged={handleTenantRoleTypeChange}
        />
      </div>

      {/* expiration date */}
      <div className="flex flex-col gap-y-1">
        <div className="flex flex-row items-center">
          <p>{t('label.expirationDateLabel')}</p>
          <HelpIcon
            target="expiration-datetime"
            content={t('label.expirationDateHelpText')}
          />
        </div>
        <CrewDatePickerField
          id="expirationDatetime"
          name="expirationDatetime"
          control={control}
          labelMode="hidden"
          pickerType="calendar"
          showLabel={false}
          displayFormat={DatePickerDateFormat.YYYYMMDD}
          rules={validateRules.expirationDatetime}
          disabledDates={isDisableDates}
          className="w-1/4"
          // Expired date is required when the tenant role type is external
          showClearButton={
            selectedTenantRoleType !== InvitationRoleType.External.key
          }
          // If tenant role type is external we cant using blackspace to clear date
          acceptCustomValue={
            selectedTenantRoleType !== InvitationRoleType.External.key
          }
        />
      </div>

      <div className="grow flex flex-col gap-y-1">
        <p>{t('label.newUsersLabel')}</p>
        <div className="flex flex-row items-start gap-x-2.5">
          {/* new users */}
          <div className="flex-1">
            <CrewTagBoxField
              id="newUsers"
              name="newUsers"
              displayExpr="value"
              valueExpr="value"
              dataSource={newUserDataSource}
              control={control}
              searchEnabled={false}
              showLabel={false}
              rules={validateRules.newUsers}
              acceptCustomValue={true}
              onCustomItemCreating={handleAddCustomUserValue}
              openOnFieldClick={false}
              deferRendering={false}
              popupSearchEnabled={false}
            />
          </div>

          {/* add button */}
          <CrewButton
            type="primary"
            text={t('label.add')}
            onClick={handleAddButtonClick}
            disabled={!canSend}
          />
        </div>
      </div>

      {/* invite confirm dialog */}
      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={t('message.tenant.inviteUserConfirmMessage')}
        onPermitButtonClick={handlePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
      />
    </form>
  )
})
