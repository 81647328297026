var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { apis } from '../apiBase/apiBase';
const meetingApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        endMeeting: builder.mutation({
            query: (params) => ({
                url: 'meetings/end',
                method: 'POST',
                body: params,
            }),
        }),
        joinMeeting: builder.mutation({
            query: (params) => ({
                url: 'meetings/join',
                method: 'POST',
                body: params,
            }),
        }),
        startRecord: builder.mutation({
            query: (params) => ({
                url: 'meetings/start-record',
                method: 'POST',
                body: params,
            }),
        }),
        endRecord: builder.mutation({
            query: (params) => ({
                url: 'meetings/end-record',
                method: 'POST',
                body: params,
            }),
        }),
        uploadMeetingBackground: builder.mutation({
            query: (params) => {
                // リクエストのファイルをformDataに格納して送信
                const formData = new FormData();
                formData.append('file', params.file);
                return {
                    url: `meetings/background/upload`,
                    method: `POST`,
                    body: formData,
                };
            },
        }),
        downloadMeetingBackgroundImage: builder.query({
            query: (params) => {
                return {
                    url: `meetings/background/download`,
                    method: 'GET',
                    responseType: 'blob',
                    responseHandler: (response) => __awaiter(void 0, void 0, void 0, function* () { return yield response.blob(); }),
                };
            },
        }),
        appointAsMeetingHost: builder.mutation({
            query: (params) => ({
                url: 'meetings/host/appoint',
                method: 'POST',
                body: params,
            }),
        }),
        dismissAsMeetingHost: builder.mutation({
            query: (params) => ({
                url: 'meetings/host/dismiss',
                method: 'POST',
                body: params,
            }),
        }),
        deleteMeetingAttendee: builder.mutation({
            query: (params) => ({
                url: 'meetings/attendee/delete',
                method: 'POST',
                body: params,
            }),
        }),
        getMeetingAttendees: builder.query({
            query: (params) => ({
                url: 'meetings/attendees',
                method: 'GET',
                params: params,
            }),
        }),
        joinMeetingFromWaiting: builder.mutation({
            query: (params) => ({
                url: 'meetings/attendee/accept',
                method: 'POST',
                body: params,
            }),
        }),
    }),
    overrideExisting: false,
});
export const { useEndMeetingMutation, useJoinMeetingMutation, useStartRecordMutation, useEndRecordMutation, useUploadMeetingBackgroundMutation, useLazyDownloadMeetingBackgroundImageQuery, useDismissAsMeetingHostMutation, useAppointAsMeetingHostMutation, useDeleteMeetingAttendeeMutation, useLazyGetMeetingAttendeesQuery, useGetMeetingAttendeesQuery, useJoinMeetingFromWaitingMutation, } = meetingApis;
