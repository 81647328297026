import { useTranslation } from '@crew/modules/i18n'
import { formatByteSize } from '@crew/utils/number'
import { ColumnDef, TableOptions, getCoreRowModel } from '@tanstack/react-table'
import { CrewFileChangerItem } from 'components/elements/crewFileChangerItem/crewFileChangerItem'
import { CrewTable } from 'components/elements/crewTable/crewTable'
import { FC, memo, useMemo } from 'react'
import { FileHistory } from '@crew/apis/file/models/getFileHistories/response'
import BaselineSaveAlt from '~icons/ic/baseline-save-alt'
import { useCallback, useEffect } from 'react'
import { downloadFileFromUrl, getParamAsArray } from 'utils'
import { OnChangeFn, SortingState } from '@tanstack/react-table'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import qs from 'qs'

import { useAppSelector } from 'states/hooks'
import { useGetFileHistoriesQuery } from '@crew/apis/file/fileApis'
import { GetFileHistoriesRequest } from '@crew/apis/file/models/getFileHistories/request'

import { skipToken } from '@reduxjs/toolkit/query'
import { EntityType } from '@crew/enums/domain'
import { FILE_HISTORIES_DEFAULT_SORT_COLUMN } from 'configs/constants'
import { useProjectPermissions } from '@crew/hooks'

import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { useFileSignedUrl } from 'hooks/useFileSignedUrl'

// renderとして使うのでmemo不可
const SizeText: FC<{ size: number }> = (props) => (
  <>{formatByteSize(props.size)}</>
)

// renderとして使うのでmemo不可
const UpdateAt: FC<{ date: string }> = (props) => {
  const [t] = useTranslation()

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  return (
    <>
      {props
        ? t('format.timestamp', {
            value: props.date,
            timeZone: defaultUserProfileRegion,
          })
        : undefined}
    </>
  )
}

export const FileHistoryTable = memo(() => {
  const { t } = useTranslation()

  const { fileId } = useParams()

  const { hasPrjFileDownloadPermission } = useProjectPermissions(
    EntityType.File,
    fileId
  )

  const { getFileHistorySignedUrl } = useFileSignedUrl()

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const params = qs.parse(searchParams.toString())

  // ソート順。指定されていない場合はバージョンの降順
  const sortParams =
    searchParams.getAll('sort').length > 0
      ? searchParams.getAll('sort')
      : FILE_HISTORIES_DEFAULT_SORT_COLUMN

  // The request params to get file list
  const requestParams: GetFileHistoriesRequest | undefined = fileId
    ? {
        fileId: fileId,
        sort: sortParams,
      }
    : undefined

  // Get file list
  const { data: getFilesResult, refetch: getFileRefetch } =
    useGetFileHistoriesQuery(requestParams ?? skipToken)

  // Build the sort condition
  const sorting: SortingState = useMemo(() => {
    const sortArray =
      getParamAsArray('sort', params) || FILE_HISTORIES_DEFAULT_SORT_COLUMN
    return sortArray.map((sort) => {
      const [id, direction] = sort.split('.')
      return {
        id,
        desc: direction === 'desc',
      }
    })
  }, [params])

  // Func handle change sorting
  const handleSortingChange: OnChangeFn<SortingState> = useCallback(
    (updaterOrValue) => {
      let values: SortingState
      if (updaterOrValue instanceof Function) {
        values = updaterOrValue(sorting)
      } else {
        values = updaterOrValue
      }

      const sortList = values.map((sort) => {
        return `${sort.id}.${sort.desc ? 'desc' : 'asc'}`
      })

      const newParams = {
        ...params,
        sort: sortList,
      }

      navigate(`?${qs.stringify(newParams, { arrayFormat: 'repeat' })}`)
    },
    [navigate, params, sorting]
  )

  const fileEventMessage = useAppSelector((state) => state.app.fileEventMessage)

  // refresh file list
  useEffect(() => {
    getFileRefetch()
  }, [getFileRefetch, fileEventMessage])

  const handleDownloadButtonClick = useCallback(
    async (fileHistoryId: string, fileName: string) => {
      const url = await getFileHistorySignedUrl(fileHistoryId)

      // download file from signed url
      downloadFileFromUrl(url, fileName)
    },
    [getFileHistorySignedUrl]
  )

  const columns = useMemo<ColumnDef<FileHistory>[]>(
    () => [
      {
        id: 'revision',
        accessorKey: 'revision',
        header: () => t('label.revision'),
        cell: ({ row }) => (
          <div className="w-full text-right">{row.original.revision}</div>
        ),
        size: 120,
        minSize: 50,
      },
      {
        id: 'description',
        accessorKey: 'description',
        header: () => t('label.contentChanged'),
        cell: ({ row }) => (
          <div className="truncate">{row.original.description}</div>
        ),
        size: 500,
        minSize: 50,
      },
      {
        id: 'size',
        accessorKey: 'size',
        header: () => t('label.size'),
        cell: ({ row }) => (
          <div className="w-full text-right truncate">
            <SizeText size={row.original.size} />
          </div>
        ),
        size: 80,
        minSize: 50,
      },
      {
        id: 'updatedBy',
        accessorKey: 'updatedBy',
        header: () => t('label.updatedBy'),
        cell: ({ row }) => (
          <div className="truncate">
            <CrewFileChangerItem
              displayName={row.original.createdBy.displayName}
              userId={row.original.createdBy.id}
              version={row.original.createdBy.version}
            />
          </div>
        ),
        size: 160,
        minSize: 50,
      },
      {
        id: 'createdAt',
        accessorKey: 'createdAt',
        header: () => t('label.updateDatetime'),
        cell: ({ row }) => (
          <div className="truncate">
            <UpdateAt date={row.original.createdAt} />
          </div>
        ),
        size: 160,
        minSize: 50,
      },
      {
        id: 'action',
        accessorKey: 'action',
        header: '',
        cell: ({ row }) => (
          <div className="flex gap-2 pr-2 justify-end items-center">
            {hasPrjFileDownloadPermission && (
              <span
                className="cursor-pointer"
                onClick={() =>
                  handleDownloadButtonClick(row.original.id, row.original.name)
                }
              >
                <BaselineSaveAlt width={24} height={24} />
              </span>
            )}
          </div>
        ),
        size: 50,
        minSize: 50,
        enableSorting: false,
      },
    ],
    [t, hasPrjFileDownloadPermission, handleDownloadButtonClick]
  )

  const tableOptions: TableOptions<FileHistory> = {
    data: getFilesResult?.fileHistories || [],
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: handleSortingChange,
    manualPagination: false,
    manualSorting: true,
    enableMultiSort: true,
    enableRowSelection: true,
    maxMultiSortColCount: 2,
    meta: {
      headerRowHeight: 40,
      dataRowHeight: 50,
    },
  }
  return (
    <div className="px-2">
      <CrewTable
        tableOptions={tableOptions}
        showPager={false}
        showColumnSelector={false}
      />
    </div>
  )
})
