import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useUpdateContactMutation } from '@crew/apis/contract/contractApis'
import { useTranslation } from '@crew/modules/i18n'
import { ValidateRules } from '@crew/utils/form'

export type FormValues = {
  companyName: string
  contactName: string
  departmentName: string
  officialPosition: string
  tel: string
}
const formInitialValues: FormValues = {
  companyName: '',
  contactName: '',
  departmentName: '',
  officialPosition: '',
  tel: '',
}
export const useContractChangeContactInformationPage = () => {
  const { control, handleSubmit, setError, reset } = useForm<FormValues>({
    criteriaMode: 'all',
    defaultValues: formInitialValues,
  })

  const { t } = useTranslation()

  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      companyName: {
        required: t('message.general.required'),
      },
      contactName: {
        required: t('message.general.required'),
      },
      departmentName: {},
      officialPosition: {},
      tel: {
        required: t('message.general.required'),
      },
    }),
    [t]
  )

  const [updateContactMutation, { isLoading: isLoadingUpdateContact }] =
    useUpdateContactMutation()

  // 連絡先情報の更新
  const updateContact = useCallback(
    async (data: FormValues) => {
      await updateContactMutation({
        contactName: data.contactName,
        companyName: data.companyName,
        departmentName: data.departmentName,
        officialPosition: data.officialPosition,
        tel: data.tel,
      }).unwrap()
    },
    [updateContactMutation]
  )

  return {
    control,
    validateRules,
    handleSubmit,
    setError,
    reset,
    updateContact,
    isLoadingUpdateContact,
  }
}
