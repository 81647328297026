import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { memo } from 'react'
import { useTwoFactorSMS } from './useTwoFactorSMS'
import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import { TwoFactorVerifyCode } from '../twoFactorVerifyCode/twoFactorVerifyCode'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { NationPhoneCode, TwoFactorAuthenticationType } from 'enums/app'
import { FormValues } from './useTwoFactorSMS'

export type TwoFactorSMSProps = {
  securityTwoFactorAuthDestination: string | undefined
  securityTwoFactorAuthCountryCode: string | undefined
  onSubmit: () => void
}

type TwoFactorSMSStep = 'input' | 'verify'

export const TwoFactorSMS = memo((props: TwoFactorSMSProps) => {
  const {
    control,
    reset,
    setError,
    handleSubmit,
    sendAuthCode,
    isLoadingSendAuthenticationCode,
  } = useTwoFactorSMS()

  const { t } = useTranslation()
  const toast = useToast()
  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  const [twoFactorSMSStep, setTwoFactorSMSStep] =
    useState<TwoFactorSMSStep>('input')

  const [destination, setDestination] = useState('')
  const [countryCode, setCountryCode] = useState('')

  // Get the list of nation
  const nationPhoneCodes = useMemo(() => {
    return Object.values(NationPhoneCode).map((item) => {
      return {
        value: item.value,
        key: t(item.text),
      }
    })
  }, [t])

  // Event handle when the Send button is clicked
  const handleSendVerifyCodeButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        // send verification code to email address
        await sendAuthCode(data)

        // set destination
        setDestination(data.phoneNumber)

        // set country code
        setCountryCode(data.nation)

        toast.success(t('message.personalSetting.sendVerifyCodeSuccess'))

        setTwoFactorSMSStep('verify')
        //reset form values
        reset()
      } catch (error) {
        showApiErrors(error)
      }
    }

    handleSubmit(onSubmit)()
  }, [handleSubmit, reset, sendAuthCode, showApiErrors, t, toast])

  return (
    <div className="mt-2.5">
      <div className="flex flex-col gap-y-2.5">
        <span className="crew-text-gray-4">
          {t('message.personalSetting.twoFactorSmsNote')}
        </span>
        <div className="flex gap-x-2.5 items-end">
          {twoFactorSMSStep === 'input' ? (
            <>
              {/* 国 */}
              <CrewSelectBoxField
                control={control}
                id="nation"
                name="nation"
                label={t('label.nation')}
                className="w-36"
                items={nationPhoneCodes}
                minSearchLength={0}
                showClearButton={false}
                displayExpr="key"
                valueExpr="value"
              />

              {/* 電話番号 */}
              <CrewTextBoxField
                control={control}
                id="phoneNumber"
                name="phoneNumber"
                label={t('label.phoneNumber')}
                className="w-52"
              />

              {/* 送信する */}
              <CrewButton
                text={t('action.sendVerifyCode')}
                type="primary"
                onClick={handleSendVerifyCodeButtonClick}
                disabled={isLoadingSendAuthenticationCode}
              />
            </>
          ) : (
            <TwoFactorVerifyCode
              onSubmit={props.onSubmit}
              twoFactorAuthenticationType={
                TwoFactorAuthenticationType.Sms.value
              }
              destination={destination}
              countryCode={countryCode}
            />
          )}
        </div>
      </div>
    </div>
  )
})
