import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import { CrewTagBoxField } from 'components/forms/crewTagBoxField'
import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useAddExistingUserToolbar } from './useAddExistingUserToolbar'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { SEARCH_TIMEOUT_MSEC } from '@crew/configs/constants'
import { useFocusInput } from 'hooks/useFocusInput'
import { useTranslation } from '@crew/modules/i18n'
import {
  ExistingUserType,
  RoleSelectType,
  useProjectMemberEntryContext,
} from 'features/project/components/projectMemberEntryDialog/useProjectMemberEntryDialog'
import { FormValues } from './useAddExistingUserToolbar'
import { RoleRef } from '@crew/models/refs'
import { ContractPlan, Roles } from '@crew/enums/app'
import { uniqueString } from '@crew/utils'
import HelpOutline from '~icons/material-symbols/help-outline'
import { CrewPopover } from 'components/devextreme/crewPopover'
import { UserType } from '@crew/enums/domain'
import { useAppSelector } from 'states/hooks'
import { ICrewTagBox } from 'components/devextreme/crewTagBox'

// Horizontal initialization value of popover
const MAX_WITH_POPOVER = 300

type HelpIconProps = {
  target: string
  content: string
}

const HelpIcon: FC<HelpIconProps> = memo((props) => {
  // ユニークキー生成
  const popoverKey = useMemo(() => uniqueString(), [])
  return (
    <>
      <span id={`${props.target}-${popoverKey}`}>
        <HelpOutline width={20} height={20} />
      </span>
      <CrewPopover
        target={`#${props.target}-${popoverKey}`}
        showEvent="mouseenter"
        hideEvent="mouseleave"
        position="top"
        maxWidth={MAX_WITH_POPOVER}
      >
        <span className="text-sm">{props.content}</span>
      </CrewPopover>
    </>
  )
})

export const AddExistingUserToolbar = memo(() => {
  const {
    control,
    handleSubmit,
    reset,
    formState,

    roleDataSource,
    noneProjectMembersDataSource,

    validateRules,
  } = useAddExistingUserToolbar()

  const { t } = useTranslation()
  const currentPlan = useAppSelector((state) => state.app.currentPlan)
  const { setSelectedExistingUsers, selectedExistingUsers } =
    useProjectMemberEntryContext()

  const tagBoxRef = useRef<ICrewTagBox>(null)

  // Declare the default rule
  const [ruleInitialize, setRuleInitialize] = useState<RoleRef>()

  const canSend = useMemo(
    () => Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
    [formState]
  )

  // フォーム初期化処理関数
  const initializeForm = useCallback(async () => {
    //Need get roleItem from roleDataSource by name ('メンバー')
    const roleItems = await roleDataSource.store().load()
    if (Array.isArray(roleItems)) {
      const roleItem = roleItems.find(
        (role) => role.roleCode === Roles.PrjMember.value
      ) as RoleRef

      setRuleInitialize(roleItem)
      reset({
        roleId: roleItem.id,
      })
    } else {
      console.error('Unexpected type of roleItems', roleItems)
    }
  }, [roleDataSource, reset])

  useEffect(() => {
    // フォーム初期化を実行
    initializeForm()
  }, [initializeForm])

  //action when add button is clicked
  const handleAddButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      const selectedItems =
        tagBoxRef.current?.component()?.instance.option('selectedItems') ||
        data.users

      //get role info from role data source
      const roleItems = await roleDataSource.load()
      const role = roleItems.find(
        (role: RoleSelectType) => role.id === data.roleId
      ) as RoleSelectType

      const selectedRole = role ?? ruleInitialize

      const prjGuest = roleItems.find(
        (role: RoleSelectType) => role.roleCode === Roles.PrjGuest.value
      ) as RoleSelectType

      //map form values to existing user type
      const payload: ExistingUserType[] = selectedItems.map((user) => {
        return {
          ...user,
          // In case role does not exist, the default value will be taken
          role: user.userType === UserType.External ? prjGuest : selectedRole, // Set guest role for external user
        }
      })

      //replace same users in selected existing users
      const filteredSelectedExistingUsers = selectedExistingUsers.filter(
        (selectedExistingUser) => {
          return !payload.some((user) => user.id === selectedExistingUser.id)
        }
      )

      setSelectedExistingUsers([...filteredSelectedExistingUsers, ...payload])

      reset()
    }
    handleSubmit(onSubmit)()
  }, [
    handleSubmit,
    reset,
    roleDataSource,
    ruleInitialize,
    selectedExistingUsers,
    setSelectedExistingUsers,
  ])

  useFocusInput('users')

  return (
    <div>
      <p className="text-md my-3">{t('label.addExistingUserDescription')}</p>
      <form className="flex flex-col gap-y-2.5">
        <div className="flex flex-col gap-y-1">
          <div className="flex flex-row items-center">
            <p>{t('label.projectRoleLabel')}</p>

            {currentPlan !== ContractPlan.Free && (
              <HelpIcon
                target="project-role"
                content={t('label.projectRoleHelpText')}
              />
            )}
          </div>

          {/* role */}
          <CrewSelectBoxField
            id="roleId"
            control={control}
            name="roleId"
            dataSource={roleDataSource}
            valueExpr="id"
            displayExpr="name"
            searchEnabled={false}
            searchExpr="name"
            minSearchLength={0}
            required={true}
            rules={validateRules.roleId}
            className="w-1/4"
            showClearButton={false}
          />
        </div>

        <div className="flex flex-col gap-y-1 grow">
          <p>{t('label.projectAddExistingUserLabel')}</p>

          <div className="flex flex-row items-start gap-x-2.5">
            <div className="grow">
              {/* users list */}
              <CrewTagBoxField
                id="users"
                name="users"
                control={control}
                displayExpr="displayName"
                valueExpr="id"
                dataSource={noneProjectMembersDataSource}
                searchEnabled={true}
                searchMode="contains"
                searchExpr="displayName"
                searchTimeout={SEARCH_TIMEOUT_MSEC}
                minSearchLength={0}
                showLabel={false}
                rules={validateRules.users}
                customRef={tagBoxRef}
              />
            </div>

            {/* add button */}
            <CrewButton
              type="primary"
              text={t('label.add')}
              onClick={handleAddButtonClick}
              disabled={!canSend}
            />
          </div>
        </div>
      </form>
    </div>
  )
})
