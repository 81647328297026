import { memo, useCallback, useMemo, useState } from 'react'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import OutlineVideocam from '~icons/ic/outline-videocam'
import BaselineDelete from '~icons/ic/baseline-delete'
import BaselineSaveAlt from '~icons/ic/baseline-save-alt'
import { useCrewMeetingArchiveItem } from './useCrewMeetingArchiveItem'
import { CrewLink } from 'components/elements/crewLink/crewLink'
import { useModal } from 'components/layouts/modal/useModal'
import { useToast } from 'hooks/useToast'
import { useTranslation } from '@crew/modules/i18n'

import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'

export type CrewMeetingArchiveItemProps = {
  eventRecordingId: string
  eventId: string
  meetingId: string
  startDatetime: string
  endDatetime: string | null
  showDeleteButton: boolean
  showDownloadButton: boolean
  onDeleteArchiveFileItem: (meetingId: string) => void // CrewArchiveFiles側のファイル表記削除イベント
  onLinkClick: () => void // リンククリック時のイベント、動画を再生する
}

export const CrewMeetingArchiveItem = memo(
  (props: CrewMeetingArchiveItemProps) => {
    const {
      getEventRecordingUrl,
      deleteEventRecording,
      isLoadingDeleteEventRecording,
    } = useCrewMeetingArchiveItem()

    const { t } = useTranslation()

    // ユーザー設定からデフォルトのユーザープロファイル地域を取得
    const defaultUserProfileRegion = useUserSetting(
      SettingKeyType.UserProfileRegion,
      Region.Japan.value
    )

    // 開始日フォーマット
    const formatedStartDate = useMemo(() => {
      return {
        datetime: t('format.datetime', {
          value: props.startDatetime,
          timeZone: defaultUserProfileRegion,
        }),
        date: t('format.date', {
          value: props.startDatetime,
          timeZone: defaultUserProfileRegion,
        }),
      }
    }, [defaultUserProfileRegion, props.startDatetime, t])

    // 終了日フォーマット
    const formatedEndDate = useMemo(() => {
      return {
        datetime: t('format.datetime', {
          value: props.endDatetime,
          timeZone: defaultUserProfileRegion,
        }),
        date: t('format.date', {
          value: props.endDatetime,
          timeZone: defaultUserProfileRegion,
        }),
        shorttime: t('format.shorttime', {
          value: props.endDatetime,
          timeZone: defaultUserProfileRegion,
        }),
      }
    }, [defaultUserProfileRegion, props.endDatetime, t])

    // ファイル名
    const fileName = useMemo(() => {
      // two same dates
      if (formatedStartDate.date === formatedEndDate.date) {
        return `${formatedStartDate.datetime} - ${formatedEndDate.shorttime}`
      }
      // two different dates
      return `${formatedStartDate.datetime} - ${formatedEndDate.datetime}`
    }, [
      formatedEndDate.date,
      formatedEndDate.datetime,
      formatedEndDate.shorttime,
      formatedStartDate.date,
      formatedStartDate.datetime,
    ])

    const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
      useModal()
    const { success, error } = useToast()

    // 確認ダイアログメッセージ
    const [confirmMessage, setConfirmMessage] = useState('')

    // ダウンロードボタンクリック時
    const handleDownloadButtonClick = useCallback(async () => {
      // 録画ファイルの署名付きURLを取得
      const url = await getEventRecordingUrl(props.eventRecordingId)

      // 署名付きURLから録画ファイルをダウンロード
      const link = document.createElement('a')
      link.href = url
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }, [fileName, getEventRecordingUrl, props.eventRecordingId])

    // Action when delete button is clicked
    const handleDeleteButtonClick = useCallback(() => {
      // 確認ダイアログの表示（処理は確認ダイアログのOKボタン押下時に行う）
      setConfirmMessage(t('message.general.confirmMessage.delete'))
      openConfirmDialog()
    }, [openConfirmDialog, t])

    // 削除確認ダイアログのOKボタン押下
    const handleDeletePermitButtonClick = useCallback(async () => {
      try {
        await deleteEventRecording(props.eventId, props.meetingId)
        success(t('message.meetingRecording.meetingRecordingDeleted'))
        // 削除したファイルの表記を消す
        props.onDeleteArchiveFileItem(props.meetingId)
      } catch (err) {
        error(t('message.general.failedToDelete'))
        return
      }
      closeConfirmDialog()
    }, [closeConfirmDialog, deleteEventRecording, props, success, t, error])

    return (
      <>
        <div className="flex gap-2 items-center">
          <CrewLink
            // 動画再生
            onClick={props.onLinkClick}
            title={fileName}
            className="flex items-center gap-x-2"
          >
            <OutlineVideocam width={24} height={24} />
            <span>{fileName}</span>
          </CrewLink>
          {/* ダウンロードアイコン（ボタン） */}
          {props.showDownloadButton && (
            <div
              className="cursor-pointer hover:opacity-70"
              onClick={handleDownloadButtonClick}
            >
              <BaselineSaveAlt width={24} height={24} />
            </div>
          )}
          {/* 削除アイコン（ボタン） */}
          {props.showDeleteButton && (
            <div
              className="cursor-pointer hover:opacity-70"
              onClick={handleDeleteButtonClick}
            >
              <BaselineDelete width={24} height={24} />
            </div>
          )}
        </div>

        {/* 削除確認ダイアログ */}
        <CrewConfirmDialog
          isOpen={isConfirmDialogOpen}
          message={confirmMessage}
          onPermitButtonClick={handleDeletePermitButtonClick}
          onCancelButtonClick={closeConfirmDialog}
          permitButtonDisabled={isLoadingDeleteEventRecording}
        />
      </>
    )
  }
)
