import { useUpdateTwoFactorAuthMutation } from '@crew/apis/setting/settingApis'
import { useTranslation } from '@crew/modules/i18n'
import { ValidateRules } from '@crew/utils/form'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'

export type FormValues = {
  verificationCode: string
}
const formInitialValues: FormValues = {
  verificationCode: '',
}

export const useTwoFactorVerifyCode = (
  twoFactorAuthenticationType: string,
  destination: string | undefined,
  countryCode: string | undefined
) => {
  const { t } = useTranslation()

  // react-hook-formの各種データを取得
  const { control, handleSubmit, reset, setError } = useForm<FormValues>({
    criteriaMode: 'all',
    defaultValues: formInitialValues,
  })

  // Get function for verify authentication code
  const [
    verifyAuthenticationCodeMutation,
    { isLoading: isLoadingVerifyAuthenticationCode },
  ] = useUpdateTwoFactorAuthMutation()

  // Verify authentication code process
  const verifyAuthCode = useCallback(
    async (verificationCode: string) => {
      // send verification code to verify
      await verifyAuthenticationCodeMutation({
        authenticationCode: verificationCode,
        twoFactorAuthenticationType: twoFactorAuthenticationType,
        destination: destination ?? null,
        countryCode: countryCode ?? null,
      }).unwrap()
    },
    [
      countryCode,
      destination,
      twoFactorAuthenticationType,
      verifyAuthenticationCodeMutation,
    ]
  )

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      verificationCode: {
        required: t('message.general.isRequired', {
          name: t('label.verificationCode'),
        }),
      },
    }),
    [t]
  )

  return {
    control,
    handleSubmit,
    reset,
    setError,
    validateRules,
    verifyAuthCode,
    isLoadingVerifyAuthenticationCode,
  }
}
