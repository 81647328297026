import { useLazyGetChatThreadUnreadCountQuery } from '@crew/apis/chatRead/chatReadApis'
import { useSelectUnreadCountInThread, useUnreadService } from '@crew/states'
import { useCallback } from 'react'
import { useEffectOnce } from '@dx-system/react-use'
import { useAppSelector } from 'states/hooks'

export const useCrewMessageReplyButton = (topicId: string) => {
  const unreadService = useUnreadService()

  // 未読件数をReduxから取得して返す
  const selectUnreadCount = useSelectUnreadCountInThread()
  const unreadCount = useAppSelector((state) =>
    selectUnreadCount(state, topicId)
  )

  // チャットスレッド既読件数取得用
  const [lazyGetChatThreadUnreadCountQuery] =
    useLazyGetChatThreadUnreadCountQuery()

  // チャットスレッドに紐づく未読件数を取得してReduxに格納する関数
  const getChatThreadUnreadAndSetToRedux = useCallback(async () => {
    if (unreadCount.hasUnreadInfo) {
      // 既に未読件数がReduxに格納されている場合はReduxの値を使用するため、未読件数をフェッチしない
      return
    }

    // チャットスレッド既読件数取得
    const result = await lazyGetChatThreadUnreadCountQuery({
      threadRootMessageId: topicId,
    }).unwrap()

    // 取得した未読件数を未読管理ストアに反映する
    unreadService.setChatThreadUnreadCount({
      topicId,
      count: {
        total: result.total,
        normal: result.normal,
        reply: result.reply,
        mentionToGroup: result.mentionToGroup,
        mentionToMe: result.mentionToMe,
      },
      lastReadMessageId: result.lastReadMessageId ?? undefined,
      updateSerial: result.updateSerial,
    })
  }, [
    lazyGetChatThreadUnreadCountQuery,
    topicId,
    unreadCount.hasUnreadInfo,
    unreadService,
  ])

  // 初期表示時、チャットスレッドに紐づく未読件数を取得してReduxに格納する
  useEffectOnce(() => {
    getChatThreadUnreadAndSetToRedux()
  })

  return {
    total: unreadCount.total,
    toMe: unreadCount.mentionToMe,
    toGroup: unreadCount.mentionToGroup,
  }
}
