import _ from 'lodash'
import {
  ParamValue,
  SearchFilter,
  dateFilterValueToString,
  stringToDateFilterValue,
} from 'utils/filter'
import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { useCallback, useMemo } from 'react'
import { ValueChangedEvent as TextBoxValueChangedEvent } from 'devextreme/ui/text_box'
import { ValueChangedEvent as SelectBoxValueChangedEvent } from 'devextreme/ui/select_box'
import { ValueChangedEvent as TagBoxValueChangedEvent } from 'devextreme/ui/tag_box'
import { assertNever } from '@crew/utils'
import {
  ArchivedStatus,
  JoiningProjectStatus,
  ProjectSearchOptions,
} from 'enums/app'
import { CrewSelectBox } from 'components/devextreme/crewSelectBox'
import { useProjectGroupDataSource } from 'hooks/dataSource/useProjectGroupDataSource'
import { useUserDataSource } from 'hooks/dataSource/useUserDataSource'
import { CrewTagBox } from 'components/devextreme/crewTagBox'
import { useTranslation } from '@crew/modules/i18n'
import {
  CrewDateFilterBox,
  CrewDateFilterBoxValueChangedEvent,
} from 'components/devextreme/crewDateFilterBox'

type CrewProjectSearchInputProps = {
  filter: SearchFilter
  updateSearchValue: (filter: SearchFilter, value: ParamValue) => void
}

export const CrewProjectSearchInput: React.FC<CrewProjectSearchInputProps> = ({
  filter,
  updateSearchValue,
}) => {
  const { t } = useTranslation()

  // プロジェクトグループ取得用DataSource（インクリメンタルサーチ）
  const projectGroupDataSource = useProjectGroupDataSource()
  // custom data source for owner user
  const ownerUserDataSource = useUserDataSource(true)
  // custom data source for joining project status
  const joiningProjectStatusDataSource = useMemo(() => {
    return Object.values(JoiningProjectStatus).map((item) => {
      return {
        id: item.value,
        name: t(item.text),
      }
    })
  }, [t])
  // custom data source for archived status
  const archivedStatusDataSource = useMemo(() => {
    return Object.values(ArchivedStatus).map((item) => {
      return {
        id: item.value,
        name: t(item.text),
      }
    })
  }, [t])

  // キーワードの変更
  const handleKeywordChanged = useCallback(
    (e: TextBoxValueChangedEvent) => {
      if (_.isEqual(e.value, e.previousValue)) return
      if (e.value === '') {
        updateSearchValue(filter, undefined)
        return
      }
      updateSearchValue(filter, e.value)
    },
    [filter, updateSearchValue]
  )

  // 参加状態の変更
  const handleParticipationStatusChanged = useCallback(
    (e: TagBoxValueChangedEvent) => {
      if (_.isEqual(e.value, e.previousValue)) return
      updateSearchValue(filter, e.value)
    },
    [filter, updateSearchValue]
  )

  // アーカイブ状態の変更
  const handleArchivedStatusChanged = useCallback(
    (e: TagBoxValueChangedEvent) => {
      if (_.isEqual(e.value, e.previousValue)) return
      updateSearchValue(filter, e.value)
    },
    [filter, updateSearchValue]
  )

  // プロジェクトグループの変更
  const handleProjectGroupChanged = useCallback(
    (e: TagBoxValueChangedEvent) => {
      if (_.isEqual(e.value, e.previousValue)) return
      updateSearchValue(filter, e.value)
    },
    [filter, updateSearchValue]
  )

  // 所有者の変更
  const handleOwnerUserChanged = useCallback(
    (e: SelectBoxValueChangedEvent) => {
      if (_.isEqual(e.value, e.previousValue)) return
      updateSearchValue(filter, e.value)
    },
    [filter, updateSearchValue]
  )

  // 作成日の変更
  const handleCreatedAtChanged = useCallback(
    (e: CrewDateFilterBoxValueChangedEvent) => {
      if (_.isEqual(e.value, e.previousValue)) return
      if (e.value) {
        updateSearchValue(filter, dateFilterValueToString(e.value))
      }
    },
    [filter, updateSearchValue]
  )

  // 更新日の変更
  const handleUpdatedAtChanged = useCallback(
    (e: CrewDateFilterBoxValueChangedEvent) => {
      if (_.isEqual(e.value, e.previousValue)) return
      if (e.value) {
        updateSearchValue(filter, dateFilterValueToString(e.value))
      }
    },
    [filter, updateSearchValue]
  )

  switch (filter.field) {
    case ProjectSearchOptions.Keyword.id:
      return (
        <CrewTextBox
          id={ProjectSearchOptions.Keyword.id}
          name={ProjectSearchOptions.Keyword.name}
          value={filter.value as string}
          showClearButton={true}
          onValueChanged={handleKeywordChanged}
          mode="search"
        />
      )
    case ProjectSearchOptions.JoiningProjectStatus.id:
      return (
        <CrewTagBox
          id={ProjectSearchOptions.JoiningProjectStatus.id}
          name={ProjectSearchOptions.JoiningProjectStatus.name}
          dataSource={joiningProjectStatusDataSource}
          valueExpr="id"
          displayExpr="name"
          searchEnabled={true}
          popupSearchEnabled={true}
          searchMode="contains"
          searchExpr="name"
          minSearchLength={0}
          maxDisplayedTags={2}
          value={filter.value as string[]}
          onValueChanged={handleParticipationStatusChanged}
        />
      )
    case ProjectSearchOptions.ArchivedStatus.id:
      return (
        <CrewTagBox
          id={ProjectSearchOptions.ArchivedStatus.id}
          name={ProjectSearchOptions.ArchivedStatus.name}
          dataSource={archivedStatusDataSource}
          valueExpr="id"
          displayExpr="name"
          searchEnabled={true}
          popupSearchEnabled={true}
          searchMode="contains"
          searchExpr="name"
          minSearchLength={0}
          maxDisplayedTags={2}
          value={filter.value as string[]}
          onValueChanged={handleArchivedStatusChanged}
        />
      )
    case ProjectSearchOptions.ProjectGroupId.id:
      return (
        <CrewTagBox
          id={ProjectSearchOptions.ProjectGroupId.id}
          name={ProjectSearchOptions.ProjectGroupId.name}
          dataSource={projectGroupDataSource}
          valueExpr="id"
          displayExpr="name"
          searchEnabled={true}
          popupSearchEnabled={true}
          searchMode="contains"
          searchExpr="name"
          minSearchLength={0}
          maxDisplayedTags={2}
          value={filter.value as string[]}
          onValueChanged={handleProjectGroupChanged}
        />
      )
    case ProjectSearchOptions.OwnerUser.id:
      return (
        <CrewSelectBox
          id={ProjectSearchOptions.OwnerUser.id}
          name={ProjectSearchOptions.OwnerUser.name}
          displayExpr="displayName"
          valueExpr="id"
          dataSource={ownerUserDataSource}
          minSearchLength={0}
          showClearButton={true}
          value={filter.value}
          onValueChanged={handleOwnerUserChanged}
          searchEnabled={true}
          searchExpr="displayName"
        />
      )
    case ProjectSearchOptions.CreatedAt.id:
      return (
        <CrewDateFilterBox
          value={stringToDateFilterValue(filter.value as string)}
          onValueChange={handleCreatedAtChanged}
        />
      )
    case ProjectSearchOptions.UpdatedAt.id:
      return (
        <CrewDateFilterBox
          value={stringToDateFilterValue(filter.value as string)}
          onValueChange={handleUpdatedAtChanged}
        />
      )
    default:
      // assertNever has value type never, so we need to cast filter.field to never
      assertNever(filter.field as never, 'Invalid filter field')
  }
}
