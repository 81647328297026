import { memo, useCallback } from 'react'
import { useTwoFactorVerifyCode } from './useTwoFactorVerifyCode'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { useLoadUserSettings } from '@crew/states'
import { useTranslation } from '@crew/modules/i18n'
import { FormValues } from './useTwoFactorVerifyCode'

export type TwoFactorVerifyCodeProps = {
  onSubmit: () => void
  twoFactorAuthenticationType: string
  destination?: string
  countryCode?: string
}

export const TwoFactorVerifyCode = memo((props: TwoFactorVerifyCodeProps) => {
  const {
    control,
    handleSubmit,
    reset,
    setError,
    validateRules,
    verifyAuthCode,
    isLoadingVerifyAuthenticationCode,
  } = useTwoFactorVerifyCode(
    props.twoFactorAuthenticationType,
    props.destination,
    props.countryCode
  )

  const { t } = useTranslation()
  const toast = useToast()
  const [loadUserSettings] = useLoadUserSettings()
  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  // Event handle when the Active button is clicked
  const handleActivateButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        // send verification code to verify
        await verifyAuthCode(data.verificationCode)

        // Reload user setting info
        loadUserSettings()

        // Display a toast indicating that the setting save was successful
        toast.success(t('message.personalSetting.twoFactorSettingSaved'))

        // call back onSubmit function from parent component to close form and refresh data
        props.onSubmit()

        // reset form values
        reset()
      } catch (error) {
        showApiErrors(error)
      }
    }

    handleSubmit(onSubmit)()
  }, [
    handleSubmit,
    loadUserSettings,
    props,
    reset,
    showApiErrors,
    t,
    toast,
    verifyAuthCode,
  ])

  return (
    <div className="flex gap-x-2.5 items-end">
      {/* 認証コード */}
      <CrewTextBoxField
        control={control}
        id="verificationCode"
        name="verificationCode"
        label={t('label.verificationCode')}
        mode="password"
        rules={validateRules.verificationCode}
      />
      {/* 確認する */}
      <CrewButton
        text={t('action.activate')}
        type="primary"
        onClick={handleActivateButtonClick}
        disabled={isLoadingVerifyAuthenticationCode}
      />
    </div>
  )
})
