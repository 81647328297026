import { UserRef } from '@crew/models/refs'
import { FC, memo } from 'react'
import { ChatReadStatusUserListItem } from './components/chatReadStatusUserListItem/chatReadStatusUserListItem'

type User = UserRef & {
  isRead: boolean
}

type ChatReadStatusUserListProps = {
  users: User[]
}

export const ChatReadStatusUserList: FC<ChatReadStatusUserListProps> = memo(
  (props) => {
    return (
      <div className="flex flex-col">
        {props.users.map((user) => (
          <ChatReadStatusUserListItem key={user.id} user={user} />
        ))}
      </div>
    )
  }
)
