import { FC, memo, useMemo } from 'react'

import { CrewNavTabs } from 'components/elements/crewNavTabs/crewNavTabs'

import { useTranslation } from '@crew/modules/i18n'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { CrewNavTabProps } from 'components/elements/crewNavTabs/components/crewNavTab'
import { DirectMessageTabs, DisplayAnonymousFile } from 'enums/app'
import { useUserSetting } from '@crew/states'
import { SettingKeyType } from '@crew/enums/app'
import { DEFAULT_PAGING_PARAMS } from 'configs/constants'

export const DirectMessageTab: FC = memo(() => {
  const { t } = useTranslation()
  const { navigate } = useCrewNavigate()

  const defaultPageSize = useUserSetting(
    SettingKeyType.ListDisplayNumber,
    DEFAULT_PAGING_PARAMS.pageSize
  )

  // Declare default pagination information
  const defaultPagination = useMemo(() => {
    return {
      pageIndex: DEFAULT_PAGING_PARAMS.pageIndex,
      pageSize: defaultPageSize,
    }
  }, [defaultPageSize])

  const fileParams = useMemo(() => {
    return {
      ...defaultPagination,
      // デフォルトは匿名ファイルを表示しない
      ...{ anonymousFile: DisplayAnonymousFile.DoNotDisplay.value },
    }
  }, [defaultPagination])

  // タブアイテム
  const displayTabItems: CrewNavTabProps[] = useMemo(() => {
    const items = Object.values(DirectMessageTabs).map((item) => {
      let param: any = {}
      if (item.value === DirectMessageTabs.File.value) {
        param = fileParams
      }

      return {
        onClick: () => {
          // タブに対応するURLへ遷移（相対パス指定）
          navigate(item.value, { ...param })
        },
        to: item.value,
        text: t(item.text),
        icon: item.icon,
      }
    })

    return items
  }, [fileParams, navigate, t])

  return <CrewNavTabs tabItems={displayTabItems} />
})
