import { Modal } from 'components/layouts/modal/modal'
import { FC, memo } from 'react'
import { PresenceSettingForm } from './components/presenceSettingForm/presenceSettingForm'

type PresenceSettingDialogProps = {
  isOpen: boolean
  title: string
  onClose: () => void
}

export const PresenceSettingDialog: FC<PresenceSettingDialogProps> = memo(
  (props) => {
    return (
      <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
        {/* set width and height of the list to ensure that each item field on the screen is displayed correctly. */}
        <div className="flex flex-col h-80 min-w-[400px]">
          <PresenceSettingForm onClose={props.onClose} />
        </div>
      </Modal>
    )
  }
)
