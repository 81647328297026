import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { UserRef } from '@crew/models/refs'
import { memo, useMemo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import BaselinePersonAdd from '~icons/ic/baseline-person-add'
import classNames from 'classnames'
import { CrewUserAvatar } from '../crewUserAvatar/crewUserAvatar'

// 最大表示件数
export const DISPLAY_LIMIT = 5

type CrewGroupAvatarProps = {
  groupAvatar?: UserRef[]
  limit?: number
  showAddAvatar?: boolean
  onAddAvatarClick?: () => void
  size?: CrewAvatarSize
  // disable click event to avatar
  disableClick?: boolean
}
export const CrewGroupAvatar = memo((props: CrewGroupAvatarProps) => {
  const { t } = useTranslation()
  const limitAvatar = props.limit ?? DISPLAY_LIMIT
  const groupAvatarObj = props.groupAvatar?.slice(0, limitAvatar)
  const addAccountClassNames = useMemo(() => {
    return 'flex items-center justify-center relative cursor-pointer crew-avatar-sm rounded-full crew-bg-gray-2'
  }, [])

  return (
    <div className="flex items-end">
      <div className="flex items-center pl-2">
        {/* アバター表示 */}
        {groupAvatarObj?.map((item) => (
          <div key={item.id} className="-ml-2">
            <CrewUserAvatar
              userId={item.id}
              displayName={item?.displayName}
              // 'sm' is the default size of Avatar
              size={props.size ?? CrewAvatarSize.sm}
              disableClick={props.disableClick}
              cacheValue={item.id + item.version}
            />
          </div>
        ))}
        {/* 追加アイコン表示 */}
        {props.showAddAvatar && (
          <div
            className={classNames(addAccountClassNames, '-ml-2')}
            onClick={props.onAddAvatarClick}
          >
            <BaselinePersonAdd
              className="crew-text-default"
              width={24}
              height={24}
            />
          </div>
        )}
      </div>
      {/* 人数表示 */}
      {props.groupAvatar && props.groupAvatar?.length > limitAvatar && (
        <span className="ml-2 whitespace-nowrap">
          {props.groupAvatar?.length}
          {t('label.people')}
        </span>
      )}
    </div>
  )
})
