import { useCallback } from 'react'
import { useAppDispatch } from 'states/hooks'
import { NotifyEventType } from 'enums/app'
import {
  notifyEventRecordingEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { EventRecording } from '@crew/models/domain'
import {
  useDeleteEventRecordingMutation,
  useLazyGetEventRecordingUrlQuery,
} from '@crew/apis/project/projectApis'

export const useCrewMeetingArchiveItem = () => {
  const [
    deleteEventRecordingMutation,
    { isLoading: isLoadingDeleteEventRecording },
  ] = useDeleteEventRecordingMutation()

  const [lazyGetEventRecordingUrl] = useLazyGetEventRecordingUrlQuery()

  const dispatch = useAppDispatch()

  // get event recording url
  const getEventRecordingUrl = useCallback(
    async (eventRecordingId: string) => {
      const response = await lazyGetEventRecordingUrl({
        eventRecordingId,
      }).unwrap()
      return response.url
    },
    [lazyGetEventRecordingUrl]
  )

  // delete event recording
  const deleteEventRecording = useCallback(
    async (eventId: string, meetingId: string) => {
      await deleteEventRecordingMutation({
        eventId,
        meetingId,
      }).unwrap()

      // mutation操作の後は、レスポンスを通知する
      const objectEventMessage: ObjectEventMessage<EventRecording> = {
        eventType: NotifyEventType.Deleted,
        id: meetingId,
        object: undefined,
      }
      dispatch(notifyEventRecordingEvent(objectEventMessage))
    },
    [deleteEventRecordingMutation, dispatch]
  )

  return {
    getEventRecordingUrl,
    deleteEventRecording,
    isLoadingDeleteEventRecording,
  }
}
