import { useCallback } from 'react'
import {
  useDeleteFileMutation,
  useDeleteFileHistoryMutation,
} from '@crew/apis/file/fileApis'
import { useAppDispatch } from 'states/hooks'
import { NotifyEventType } from 'enums/app'
import {
  notifyFileEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { File, FileHistory } from '@crew/models/domain'
import { isFileHistory } from '@crew/utils'

export const useCrewAttachmentThumbnailItem = (
  attachment: File | FileHistory
) => {
  const [deleteFileMutation] = useDeleteFileMutation()
  const [deleteFileHistoryMutation, { isLoading: isLoadingDeleteFileHistory }] =
    useDeleteFileHistoryMutation()

  const dispatch = useAppDispatch()

  // 削除確認ダイアログのOKボタン押下
  const deleteAttachment = useCallback(async () => {
    if (isFileHistory(attachment)) {
      // FileHistoryの場合
      await deleteFileHistoryMutation({
        fileHistoryId: attachment.id,
      }).unwrap()
    } else {
      // Fileの場合
      await deleteFileMutation({
        fileId: attachment.id,
        version: attachment.version,
      }).unwrap()
    }

    // mutation操作の後は、レスポンスを通知する
    const objectEventMessage: ObjectEventMessage<File> = {
      eventType: NotifyEventType.Deleted,
      id: attachment.id,
      object: undefined,
    }
    dispatch(notifyFileEvent(objectEventMessage))
  }, [attachment, deleteFileHistoryMutation, deleteFileMutation, dispatch])
  return {
    deleteAttachment,
    isLoadingDeleteFileHistory,
  }
}
