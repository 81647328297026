import { FC, memo, useCallback } from 'react'

import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'

import { SideMenuList } from './components/sideMenuList/sideMenuList'
import { FavoriteProjectPanel } from './components/favoriteProjectPanel/favoriteProjectPanel'
import { DirectMessageUserPanel } from './components/directMessageUserPanel/directMessageUserPanel'
import { UnreadProjectPanel } from './components/unreadProjectPanel/unreadProjectPanel'
import { useValueChangeEffect } from '@crew/hooks'
import { useAppSelector } from 'states/hooks'
import { useLazyGetActiveChatRoomsQuery } from '@crew/apis/chat/chatApis'
import {
  selectUnreadActiveChatRooms,
  useActiveChatRoomService,
  useUnreadService,
  useUserSetting,
} from '@crew/states'
import type {
  SetActiveChatRoomsWithUnreadCountParams,
  SetChatThreadUnreadCountsParams,
} from '@crew/states'
import { SettingKeyType } from '@crew/enums/app'
import { UserChatSettingDisplayFormat } from 'enums/app'
import { useLazyGetChatThreadUnreadCountsQuery } from '@crew/apis/chatRead/chatReadApis'
import { useEffectOnce } from '@dx-system/react-use'

export const LeftSidePanel: FC = memo(() => {
  // アクティブチャットルーム操作用のサービスをフックから取得
  const activeChatRoomService = useActiveChatRoomService()
  // 未読情報操作用のサービスをフックから取得
  const unreadService = useUnreadService()

  // お気に入り状態変更のイベントメッセージ
  const favoriteEventMessage = useAppSelector(
    (state) => state.app.favoriteEventMessage
  )

  // アクティブチャットルーム取得API
  const [lazyGetActiveChatRoomsQuery] = useLazyGetActiveChatRoomsQuery()

  // スレッドの未読情報一覧取得API
  const [lazyGetChatThreadUnreadCountsQuery] =
    useLazyGetChatThreadUnreadCountsQuery()

  // ログインユーザーのチャット表示形式
  const displayFormat = useUserSetting(SettingKeyType.ChatDisplayFormat)

  const getActiveChatRooms = useCallback(async () => {
    // アクティブチャットルーム取得を取得
    const activeChatRooms = await lazyGetActiveChatRoomsQuery().unwrap()

    const params: SetActiveChatRoomsWithUnreadCountParams = {
      activeChatRooms: activeChatRooms.chatRooms.map((chatRoom) => {
        return {
          // 基本情報
          chatRoomId: chatRoom.chatRoomId,
          parentChatRoomId: chatRoom.parentChatRoomId,
          referenceEntityType: chatRoom.referenceEntityType,
          referenceEntityRecordId: chatRoom.referenceEntityRecordId,
          // projectの場合の追加情報
          isFavorite: chatRoom.isFavorite,
          projectName: chatRoom.projectName,
          projectScope: chatRoom.projectScope,
          projectType: chatRoom.projectType,
          projectVersion: chatRoom.projectVersion,
          // 未読情報
          unreadCount: {
            total: chatRoom.totalUnreadCountTotal,
            normal: chatRoom.totalUnreadCountNormal,
            reply: chatRoom.totalUnreadCountReply,
            mentionToGroup: chatRoom.totalUnreadCountMentionToGroup,
            mentionToMe: chatRoom.totalUnreadCountMentionToMe,
          },
          updateSerial: chatRoom.updateSerial,
          lastReadMessageId:
            chatRoom.lastReadMessageId !== null
              ? chatRoom.lastReadMessageId
              : undefined,
        }
      }),
    }

    // 取得したアクティブチャットルームのデータをReduxへ格納する
    activeChatRoomService.setActiveChatRoomsWithUnreadCount(params)
  }, [activeChatRoomService, lazyGetActiveChatRoomsQuery])

  // 初期表示時にアクティブチャットルームを取得する
  useEffectOnce(() => {
    getActiveChatRooms()
  })

  // お気に入り状態が変更されたときに左メニューの表示を更新するため再フェッチする
  useValueChangeEffect(
    () => {
      getActiveChatRooms()
    },
    [getActiveChatRooms],
    favoriteEventMessage
  )

  // スレッドの未読情報一覧を取得し、Reduxへ格納する
  const getThreadUnreadCounts = useCallback(async () => {
    // スレッドの未読情報一覧を取得
    const threadUnreadCounts =
      await lazyGetChatThreadUnreadCountsQuery().unwrap()

    // 取得した未読情報をReduxへ格納する
    const params: SetChatThreadUnreadCountsParams = {
      threadUnreadCounts: threadUnreadCounts.chatThreadUnreadCounts.map(
        (threadUnreadCount) => {
          return {
            topicId: threadUnreadCount.threadMessageId,
            lastReadMessageId: threadUnreadCount.lastReadMessageId,
            count: {
              total: threadUnreadCount.total,
              normal: threadUnreadCount.normal,
              reply: threadUnreadCount.reply,
              mentionToGroup: threadUnreadCount.mentionToGroup,
              mentionToMe: threadUnreadCount.mentionToMe,
            },
            updateSerial: threadUnreadCount.updateSerial,
          }
        }
      ),
    }

    unreadService.setChatThreadUnreadCounts(params)
  }, [lazyGetChatThreadUnreadCountsQuery, unreadService])

  // 個人設定のチャット表示形式を変更時、スレッドの未読情報を取得する
  useValueChangeEffect(
    () => {
      // チャット表示形式が「スレッドリスト表示」の場合のみ未読情報を取得する
      if (displayFormat === UserChatSettingDisplayFormat.Thread.value) {
        getThreadUnreadCounts()
      }
    },
    [displayFormat, getThreadUnreadCounts],
    displayFormat
  )

  // 未読があるアクティブチャットルーム
  const unreadChatRooms = useAppSelector(selectUnreadActiveChatRooms)

  return (
    <CrewErrorBoundary>
      <div className="flex flex-col flex-1 overflow-y-auto">
        {/* 新着～マイタスク */}
        <SideMenuList />
        {/* 未読 */}
        {unreadChatRooms.length > 0 && <UnreadProjectPanel />}
        {/* お気に入りプロジェクト */}
        <FavoriteProjectPanel />
        {/* ダイレクトメッセージ */}
        <DirectMessageUserPanel />
      </div>
    </CrewErrorBoundary>
  )
})
