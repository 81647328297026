import { Language } from '@crew/enums/i18n';
export const resources = {
    [Language.En]: {
        translation: {
            general: {
                loadMore: 'Load more',
                fold: 'Fold',
                entityType: {
                    customers: 'Customers',
                    chatMessages: 'Chat Messages',
                    opportunities: 'Opportunities',
                    opportunityActivities: 'Opportunity Activities',
                    projects: 'Projects',
                    events: 'Events',
                    tasks: 'Tasks',
                    taskActivities: 'Task Activities',
                    files: 'Files',
                    users: 'Users',
                    departments: 'Departments',
                },
            },
            action: {
                inviteUser: 'Invite User',
                approve: 'Approve',
                reject: 'Reject',
                new: 'New',
                edit: 'Edit',
                delete: 'Delete',
                cancel: 'Cancel',
                register: 'Register',
                register2: 'Register',
                close: 'Close',
                login: 'Log in',
                logout: 'Log out',
                tryFreePlan: 'Try Free Plan',
                startFreeTrial: 'Start Free Trial',
                tryAgain: 'Try again',
                send: 'Send',
                projectNew: 'New project',
                following: 'Following',
                followUs: 'Follow us',
                addTask: 'Add new task',
                confirmPermit: 'Permit',
                confirmCancel: 'Cancel',
                advancedCondition: 'Advanced Condition',
                retry: 'Retry',
                showMore: 'Show More',
                abbreviate: 'Abbreviate',
                newTask: 'New Task',
                searchByThisCondition: 'Search by this condition',
                clearSearchCondition: 'Clear search condition',
                saveSearchCondition: 'Save search condition',
                addMember: 'Add member',
                removeMember: 'Remove member',
                addNew: 'Add new',
                newFile: 'New File',
                save: 'Save',
                reply: 'Reply',
                detail: 'Detail',
                startMeeting: 'Start Meeting',
                joinMeeting: 'Join the meeting',
                leaveMeeting: 'Leave meeting',
                endMeeting: 'End meeting',
                lexical: {
                    insertRowAbove: 'Insert row above',
                    insertRowsAbove: 'Insert {{number}} rows above',
                    insertRowBelow: 'Insert row below',
                    insertRowsBelow: 'Insert {{number}} rows below',
                    insertColumnLeft: 'Insert column left',
                    insertColumnsLeft: 'Insert {{number}} columns left',
                    insertColumnRight: 'Insert column right',
                    insertColumnsRight: 'Insert {{number}} columns right',
                    deleteRow: 'Delete row',
                    deleteColumn: 'Delete column',
                    deleteTable: 'Delete table',
                    addRowHeader: 'Add row header',
                    removeRowHeader: 'Remove row header',
                    addColumnHeader: 'Add column header',
                    removeColumnHeader: 'Remove column header',
                    rows: 'Rows',
                    columns: 'Columns',
                    cellBackgroundColor: 'Cell background color',
                    paragraph: 'Normal',
                    h1: 'Heading 1',
                    h2: 'Heading 2',
                    h3: 'Heading 3',
                    bulletList: 'Bullet List',
                    numberList: 'Numbered List',
                    checkList: 'Check List',
                    quote: 'Quote',
                    codeBlock: 'Code Block',
                    align: 'Align',
                    insert: 'Insert',
                    table: 'Table',
                    confirm: 'Confirm',
                    insertTable: 'Insert Table',
                    backgroundColor: 'Background color',
                    leftAlign: 'Left Align',
                    centerAlign: 'Center Align',
                    rightAlign: 'Right Align',
                    justifyAlign: 'Justify Align',
                    outdent: 'Outdent',
                    indent: 'Indent',
                },
                addAttendee: 'Add attendee',
                removeAttendee: 'Remove attendee',
                addToProject: 'Add to project',
                addToAttendees: 'Add to attendees',
                inviteToProject: 'Invite to project',
                registerAndJoin: 'Register and join',
                loginToJoin: 'Login to join',
                newProjectGroup: 'New Project Group',
                newMeetingKind: 'New Meeting Kind',
                newEventKind: 'New Event Kind',
                newTaskKind: 'New Task Type',
                newTaskState: 'New Task Status',
                search: 'Search',
                mobileApprove: 'Approve',
                mobileReject: 'Reject',
                yes: 'Yes',
                no: 'No',
                change: 'Change',
                confirm: 'Confirm',
                sendVerifyCode: 'Send',
                toSave: 'Save',
                newEventCategory: 'New Event Category',
                newTaskCategory: 'New Task Category',
                cancelInvitation: 'Cancel invitation',
                activate: 'Activate',
                sendEmail: 'Send email',
                upgradePlan: 'Upgrade',
                resetPassword: 'Reset',
                approveRequest: 'Approve request',
                rejectRequest: 'Reject request',
                disableTwoFactor: 'Disable',
                markAllRead: 'Mark all read',
                apply: 'Apply',
                addCondition: 'Add condition',
                copyLink: 'Copy link',
                bulkInvitation: 'Bulk invitation',
                configureNotification: 'Configure notification',
                editWBS: 'Edit WBS',
                renumberWBSNumber: 'Renumber WBS number',
                finishEditingWBS: 'Finish editing WBS',
                createSubFolder: 'Create subfolder',
                editFolder: 'Edit folder',
                deleteFolder: 'Delete folder',
                copy: 'Copy',
                newFolder: 'New folder',
                move: 'Move',
                addSubtask: 'Add subtask',
                deleteTask: 'Delete task',
                toDay: 'Today',
                displayDetail: 'Display detail',
                add: 'Add',
                addUser: 'Add user',
                expandAll: 'Expand all',
                closeAll: 'Close all',
                goToFolder: 'Go to folder',
                newMeeting: 'New Meeting',
                deleteFile: 'Delete file',
                addWorkTime: 'Add work time',
                switchToAnotherOrganization: 'Switch to another organization',
                turnOffAudio: 'Turn off audio',
                turnOffVideo: 'Turn off video',
                appointAsMeetingHost: 'Appoint as host',
                dismissAsMeetingHost: 'Dismiss as host',
                removeFromMeeting: 'Remove from meeting',
                bulkAdd: 'Bulk add',
                editMeetingMinutes: 'Edit',
                mention: 'Mention',
                mentionAndReply: 'Mention And Reply',
                allMessage: 'All message',
                off: 'Off',
                newPresenceStatus: 'New Presence Status',
                leaveTheMeeting: 'Leave the meeting',
                endTheMeeting: 'End the meeting',
                newSchedule: 'New schedule',
            },
            label: {
                all: 'All',
                allState: 'All State',
                organization: 'Organization',
                authority: 'Permission',
                view: 'View',
                detail: 'Detail',
                start: 'Start',
                end: 'End',
                project: 'Project',
                schedule: 'Schedule',
                changeLanguage: 'Change language',
                username: 'User id or email address',
                password: 'Password',
                tenantId: 'Tenant ID',
                tenantIdSample: 'e.g. aoba-tech',
                tenantSettings: 'Tenant Settings',
                unEntered: 'Unentered',
                assignedUser: 'Assigned user',
                dueDate: 'Due date',
                feed: 'Feed',
                bookmark: 'Bookmark',
                chat: 'Chat',
                message: 'Message',
                mentionAndReply: 'Mention And Reply',
                opportunity: 'Opportunity',
                allOpportunities: 'All opportunities',
                myOpportunities: 'My opportunities',
                opportunityName: 'Opportunity name',
                subject: 'Subject',
                description: 'Description',
                content: 'Content',
                completed: 'Completed',
                stage: 'Stage',
                probability: 'Probability',
                closeDate: 'Close date',
                amount: 'Amount',
                opportunityEvent: 'Opportunity event',
                customer: 'Customer',
                customerName: 'Customer name',
                lead: 'Lead',
                support: 'Support',
                task: 'Task',
                classification: 'Classification',
                state: 'State',
                status: 'Status',
                priority: 'Priority',
                file: 'File',
                projectName: 'Project name',
                key: 'Key',
                owner: 'Owner',
                progress: 'Progress',
                scope: 'Project scope',
                projectGroup: 'Project group',
                member: 'Member',
                change: 'Change',
                projectAddTitle: 'Add project',
                noOptions: 'No options',
                projectEditTitle: 'Edit project',
                addTaskTitle: 'Add new task',
                editTaskTitle: 'Edit task',
                addTaskCommentTitle: 'Add task comment',
                editTaskCommentTitle: 'Edit task comment',
                addTaskWorkTitle: 'Add task work',
                editTaskWorkTitle: 'Edit task work',
                startDate: 'Start date',
                deadline: 'Deadline',
                category: 'Category',
                scheduledTime: 'Scheduled time',
                allDay: 'All day',
                attendee: 'Attendee',
                notificationRecipient: 'Notification recipient',
                // Backendの定義と一致させるためにkeyを `Events` としている
                allEvents: 'All meeting',
                meetingsToAttend: 'Meetings to attend',
                myTasks: 'My task',
                uncompletedTasks: 'Uncompleted Task',
                allTasks: 'All Task',
                allTypes: 'All types',
                allBookmark: 'All Bookmark',
                unarchive: 'Unarchive',
                archived: 'Archived',
                incomplete: 'In progress',
                overdue: 'Overdue',
                participatingProjects: 'Participating projects',
                myProjects: 'My projects',
                includeArchivedProjects: 'Include archived projects',
                activeProjects: 'Active projects',
                archivedProjects: 'Archived projects',
                meetingKind: 'Meeting Kind',
                eventKind: 'Event Kind',
                taskKind: 'Task Type',
                taskState: 'Task Status',
                memberState: 'State',
                keyword: 'Keyword',
                registrationDate: 'Created date',
                rolloverDate: 'Updated date',
                order: 'Order{{number}}',
                searchCondition: 'Search condition',
                myPage: 'My page',
                newArrival: 'New Arrival',
                taskCategory: 'Task category',
                projectSettingTitle: 'Project setting',
                standardSearch: 'Standard search',
                advancedSearch: 'Advanced search',
                endDate: 'End date',
                user: 'User',
                role: 'Role',
                registeredDate: 'Registered date',
                name: 'Name',
                explanation: 'Explanation',
                addMember: 'Add member',
                editMember: 'Edit member',
                type: 'Type',
                notStarted: 'Not started yet',
                inProgress: 'Working',
                inReview: 'Under review',
                taskKindAdd: 'Add task type',
                taskKindEdit: 'Edit task type',
                color: 'Color',
                id: 'ID',
                ascending: 'ASC',
                descending: 'DESC',
                taskStateAdd: 'Add task status',
                taskStateEdit: 'Edit task status',
                meetingKindAdd: 'Add a meeting kind',
                meetingKindEdit: 'Edit meeting kind',
                eventKindAdd: 'Add a event kind',
                eventKindEdit: 'Edit event kind',
                projectGroupAdd: 'Add a project group',
                projectGroupEdit: 'Edit project group',
                displayColor: 'Display color',
                addEventCategory: 'Add event category',
                editEventCategory: 'Edit event category',
                addTaskCategory: 'Add task category',
                editTaskCategory: 'Edit task category',
                emoji: 'Emoji',
                saveCondition: 'Save Condition',
                filterName: 'Filter Name',
                public: 'Public',
                private: 'Private',
                actualTime: 'Actual time',
                highest: 'Highest',
                high: 'High',
                normal: 'Normal',
                low: 'Low',
                lowest: 'Lowest',
                comment: 'Comment',
                commentAndHistory: 'Comment and history',
                history: 'History',
                time: 'Time',
                weeks: 'W',
                days: 'D',
                hours: 'H',
                minutes: 'M',
                relatedItem: 'Related item',
                entityType: 'Entity Type',
                entityRecord: 'Entity Record',
                remainingWorkTimes: 'Time remaining',
                startDatetime: 'Start date and time',
                unassigned: 'Unassigned',
                taskAdded: 'Added a task',
                allFiles: 'All Files',
                myFiles: 'My Files',
                dropFileHere: 'Drop file here',
                dragDropOrClickFile: 'Drop or click here to select a file',
                registerNewFile: 'Register new file',
                tag: 'Tag',
                updatedHistory: 'Updated history',
                preview: 'Preview',
                version: 'Version',
                fileName: 'File Name',
                changer: 'Changer',
                createdBy: 'Created by',
                registrationDatetime: 'Registration time',
                updatedBy: 'Updated by',
                updateDatetime: 'Update time',
                size: 'Size',
                selectFile: 'Select File',
                selectImage: 'Select Image',
                or: 'Or',
                uploaded: 'Uploaded',
                uploadFailed: 'Upload Failed',
                editFile: 'Edit file',
                contentChanged: 'Content changed',
                saveTags: 'Save tags',
                changes: 'Changes',
                fileUpload: 'File upload',
                allMessage: 'All message',
                onlyThisRoom: 'Only this room',
                sortOrder: 'Sort order',
                searchTag: 'Search Tag',
                attachments: 'Attachments',
                downloadFile: 'Download file',
                deleteFile: 'Delete file',
                dashboard: 'dashboard',
                settings: 'Settings',
                logout: 'Logout',
                addUser: 'Add User',
                directMessage: 'Direct Message',
                shareScreens: 'Share Screens',
                blur: 'Blur',
                dateTime: 'Date time',
                microphone: 'Microphone',
                speaker: 'Speaker',
                test: 'Test',
                noiseReduction: 'Noise Removal',
                startWebMeeting: 'Start a web conference',
                webMeetingSetting: 'Web conferencing settings',
                displayName: 'Name',
                displayNameSample: 'e.g. Yamada Taiki',
                automaticRecording: 'Automatic Recording',
                camera: 'Camera',
                background: 'Background',
                unreadFromHere: 'Unread from here',
                fullScreen: 'Full Screen',
                exitFullScreen: 'Exit Full Screen',
                recording: 'Recording',
                add: 'Add',
                updated: 'Updated',
                pleaseSelectFile: 'Please select a file',
                image: 'Image',
                taskId: 'Task ID',
                related: 'Related',
                favorite: 'Favorite',
                enterNumberOfRows: 'Enter the number of rows:',
                enterNumberOfColumns: 'Enter the number of columns:',
                userIdOrEmailAddress: 'Email address or login ID',
                forgotPassword: 'Forgot your password?',
                other: 'Other',
                leaveRoom: 'Leave',
                startRecording: 'Start recording',
                endRecording: 'End recording',
                recordingInprogress: 'Recording',
                horizontalScreen: 'Horizontal screen',
                updateFile: 'Update file',
                mailAddress: 'Mail address',
                requiredApprovalToJoin: 'Required approval to join',
                attendance: 'Attendance',
                absence: 'Absence',
                attending: 'Attending',
                existingUsers: 'Add an existing users',
                newUsers: 'Invite new users',
                addOrInviteMembers: 'Add/Invite members',
                addOrInviteMemberDescription: 'Add or invite members to your project.',
                unanswered: 'Unanswered',
                addAttendeeDialogTitle: 'Add attendee',
                addAttendeeDescription: 'Add participants to the meeting.',
                addExistingUserDescription: 'You can add users that already exist in your organization.',
                people: 'people',
                inviteNewUsersDescription: 'You can invite new users.',
                specifyExpirationTitle: 'Specify an expiration date for users outside your organization',
                confirmAttendance: 'Confirm Attendance',
                organizationSettings: 'Organization Settings',
                initialValue: 'Initial Value',
                comfirmLeaveMetting: 'You will be forced out of the meeting, are you sure you want to move to another page?',
                userType: 'User type',
                expirationDate: 'Expiration date',
                internalUser: 'Internal user',
                externalUser: 'External user',
                invitingUsers: 'Inviting users',
                userOutsideOrganization: 'User outside organization',
                assignToMe: 'Assign to me',
                joinProject: 'Join project',
                clickToJoinProject: 'Click "Join project" to become a member of the project',
                approvalPending: 'Approval pending',
                projectImage: 'Project image',
                signupErrorInvalid: 'This invite link is invalid.',
                signupErrorExpired: 'Either the expiration date has expired or the invitation has been canceled.',
                signupExistingUserMessage: 'You are invited to CrewWorks.',
                signupExistingUserMessage2: 'Please log in to accept the invitation sent to {{email}}.',
                joined: 'Joined',
                inviting: 'Inviting',
                organizationName: 'Organization name',
                organizationNameSample: 'e.g. AOBA Technologies',
                loginId: 'Login ID',
                loginIdSample: 'e.g. yamada-aoba-tech',
                confirmPassword: 'Confirm password',
                clickHereToRedirectToTenantPage: 'Click here to redirect to login tenant page',
                registerAnotherEmailAddress: 'Register another email address',
                signupNewUserMessage: 'You are invited to CrewWorks.',
                signupNewUserMessage2: 'To accept the invitation sent to {{email}}, please proceed with the account registration.',
                inviteUser: 'Invite user',
                loginIdOrEmail: 'Email address/login ID',
                estimatedWorkTimes: 'Please input in the format of 3d30m or 150m.',
                list: 'List',
                noDataText: 'No data to display',
                reactOtherMembersMessage: ' and {{otherMembers}} others',
                personalSetting: 'Personal Setting',
                personalProfile: 'Profile',
                personalDisplay: 'Display',
                security: 'Security',
                changeAvatar: 'Change avatar',
                avatarNote: 'JPG, PNG are available for use',
                avatarSize: 'The size is up to {{avatarSize}}MB',
                userInformation: 'User information',
                userAlias: 'Alias (Furigana)',
                userComment: 'Comment',
                language: 'Language',
                ja: 'Japanese',
                en: 'English',
                region: 'Region',
                languageAndRegion: 'Language and region',
                sendDirectMessage: 'Send direct message',
                allUsers: 'All users',
                internalUsers: 'Internal users',
                externalUsers: 'External users',
                alias: 'Alias',
                searchKey: 'Search key',
                internal: 'Internal',
                external: 'External',
                twoFactorAuthentication: 'Two-factor authentication',
                newEmailAddress: 'New email address',
                verificationCode: 'Verification code',
                currentPassword: 'Current password',
                newPassword: 'New password',
                newPasswordConfirm: 'New password (confirm)',
                appearance: 'Appearance',
                theme: 'Theme',
                light: 'Light',
                dark: 'Dark',
                displayNumber: 'Display number',
                displayFormat: 'Display format',
                displayRange: 'Display range',
                historyDisplayOrder: 'History display order',
                initialDisplay: 'Initial display',
                itemPerPage: '{{ displayNumber }} items',
                timelineDisplay: 'Timeline display',
                threadDisplay: 'Thread display',
                displayAll: 'Display all',
                thisLayerOnly: 'This layer only',
                newsMentionAndReply: 'Mention And Reply',
                notification: 'Notification',
                whatToNotify: 'What to notify',
                notificationMethod: 'Notification method',
                notificationMentionAndReply: 'Mention And Reply',
                allMessages: 'All Message',
                newTopicsOnly: 'New Topics Only',
                allAttentions: 'All (Mention and Reply and Reaction)',
                mention: 'Mention',
                banner: 'Banner',
                email: 'Email',
                bannerAndEmail: 'Banner And Email',
                none: 'None',
                eventCategory: 'Event category',
                editUser: 'Edit user',
                bulkEditUsers: 'Bulk edit users',
                enabled: 'Enabled',
                disabled: 'Disabled',
                archiveProject: 'Archive your project',
                archiveProjectDescription: 'Once archived, it will no longer appear in the project list.',
                isArchived: 'Archived',
                unarchived: 'Unarchived',
                myTask: 'My Task',
                addRole: 'Add role',
                roleName: 'Role name',
                original: 'Original',
                organizationDomain: 'Organization domain',
                addExternalUser: 'Add external user',
                externalUserExpirationDate: 'External user expiration date (initial value)',
                numberOfCharactersPassword: 'Number of characters in password',
                lettersOrMore: 'letters or more',
                enforceComplexPassword: 'Enforce complex password',
                restrictDomains: 'Restrict domains',
                enforeTwoFactorAuthentication: 'Enforce two-factor authentication',
                maximumSize: 'Maximum size is {{ maximumSize }}MB',
                allow: 'Allow',
                disallow: 'Disallow',
                app: 'App',
                sms: 'SMS',
                securityEnabled: 'Enabled ({{ securityAuthenticationType }})',
                japanRegion: 'Tokyo (UTC+09:00)',
                vietnamRegion: 'Hanoi (UTC+07:00)',
                systemRole: 'System role',
                projectRole: 'Project role',
                tenantSetting: 'Tenant Setting',
                changeContractPlan: 'Change contract plan',
                upgradeContractPlan: 'Upgrade',
                contractInformation: 'Contract Information',
                waitingApproval: 'Waiting Approval',
                advancedSetting: 'Advanced settings',
                returnToProject: 'Return to the project',
                phoneNumber: 'Phone number',
                nation: 'Nation',
                japanPhoneCode: 'Japan +81',
                vietnamPhoneCode: 'Vietnam +84',
                roles: {
                    sysTenantAdmin: 'System tenant admin',
                    sysAdmin: 'System admin',
                    sysUser: 'System user',
                    sysExternalUser: 'System external user',
                    prjAdmin: 'Project admin',
                    prjMember: 'Project member',
                    prjGuest: 'Project guest',
                },
                permissions: {
                    sysContractViewEdit: 'View/Edit contract information',
                    sysTenantSettingViewEdit: 'View/Edit organization settings',
                    sysProjectCreateDelete: 'Create/Delete Project',
                    sysPublicProjectViewJoin: 'View/Join Public Projects',
                    sysInternalUserInvite: 'Invite internal users',
                    sysExternalUserInvite: 'Invite external users',
                    sysUserListView: 'View user list',
                    prjProjectEdit: 'Edit Project',
                    prjProjectSettingEdit: 'Edit project settings',
                    prjMemberAdd: 'Add project member',
                    prjMemberEdit: 'Edit project members',
                    prjMemberDelete: 'Delete project members',
                    // Backendの定義と一致させるためにkeyを `prjEvent*` としている
                    prjEventCreate: 'Create meeting',
                    prjEventEdit: 'Edit meeting',
                    prjEventDelete: 'Delete meeting',
                    prjEventMeetingManage: 'Meeting management',
                    prjTaskCreate: 'Create task',
                    prjTaskEdit: 'Edit Task',
                    prjTaskDelete: 'Delete task',
                    prjFileCreate: 'Create file',
                    prjFileEdit: 'Edit file',
                    prjFileDownload: 'Download file',
                    prjFileDelete: 'Delete file',
                    prjFolderCreate: 'Create folder',
                    prjFolderEdit: 'Edit folder',
                    prjFolderDelete: 'Delete folder',
                    prjMemberRoleEdit: 'Change project member role',
                },
                leaveProject: 'Leave project',
                contract: 'Contract Information',
                contractPlan: 'Contract Plan',
                contractPlanChange: 'Contract plan change',
                contractNow: 'Now',
                contractAfterChange: 'After change',
                priceAnnual: 'Yen(excluding tax)/user/year',
                priceMonth: 'Yen(excluding tax)/user/month',
                resetPasswordHint: 'Please enter a new password',
                signUpHint: 'Register your CrewWorks organization and system administrator account.',
                expirationDatetimeHint: 'Please complete registration by {{ expirationDatetime }}',
                organizationInformation: 'Organization Information',
                systemAdministratorInformation: 'System Administrator Information',
                currentPlan: 'Current plan',
                basicPlan: 'Basic plan',
                perUser: '{{userUnit}} user {{price}} Yen/month',
                storageCapacity: 'Storage capacity',
                changeCapacity: 'Change capacity',
                externUserLimitTitle: 'External user limit',
                externUserLimitValue: '{{ baseExternalUsers }} Users + {{ addingExternalUsers }} Adding slots',
                changeExternlUserCount: 'Update to invitable external users',
                contractCycle: 'Contract cycle',
                year: 'year',
                month: 'month',
                changeBillingCycle: 'Change billing cycle',
                usageStatus: 'Usage Status',
                numberOfUser: 'Number of users',
                numberOfExternalUser: 'Number of external users',
                storage: 'Storage',
                contactName: 'Account Manager',
                changeContactInformation: 'Change contact information',
                requestInformation: 'Request information',
                paymentMethod: 'Payment method',
                creditCard: 'Credit card',
                bankTransfer: 'Bank transfer',
                registerPaymentMethod: 'Payment setup',
                changePaymentMethod: 'Change payment methods',
                billingAddress: 'Billing address',
                registerBillingAddress: 'Register billing address',
                changeBillingAddress: 'Change billing address',
                requestDescription: 'For billing information, including payment history and receipt downloads, visit our billing portal.',
                billingPortal: 'Billing portal',
                notificationMeOnlyWhenOffline: 'Notify me only when offline',
                choosePlan: 'Choose a plan',
                choosePlanHint: 'You can try all features of the selected plan for free for 1 month',
                tryForFree: 'Try for free',
                compareMorePlan: 'Compare more',
                plan: 'Plan',
                planType: {
                    free: 'Free',
                    standard: 'Standard',
                    professional: 'Professional',
                },
                planDescription: {
                    starter: {
                        line1: 'Better collaboration for smaller organizations and teams',
                    },
                    standard: {
                        line1: 'All the tools you need for the job',
                        line2: 'Recommended for all teams who want to accelerate their business',
                    },
                },
                contractDetails: 'Contract details',
                planDescriptionForUpdate: {
                    standard: 'Chat, task management, web conferencing, file sharing',
                    professional: 'Standard Features + Advanced Project Management',
                },
                planFeature: {
                    businessChat: 'Business chat',
                    taskManagement: 'Task management',
                    projectManagement: 'Project management',
                    fileSharing: 'File sharing',
                    webMeeting: 'Web conferencing',
                    numberOfStorage: '{{numberOfStorage}}Gb of storage',
                },
                planBillingCycle: {
                    year: 'Annual contract',
                    month: 'Monthly contract',
                },
                yen: 'Yen',
                planPricePerUser: 'Per user',
                planPriceAnnual: '/year (excluding tax)',
                planPriceMonthly: '/month (excluding tax)',
                planPricePerMonth: '/month(monthly equivalent)',
                changeStorage: 'Change storage capacity',
                acrossTheOrganization: 'Across the organization',
                canBeUse: 'can be used up to',
                free: 'Free',
                contractCycleChange: 'Contract cycle change',
                cycleBilling: {
                    month: {
                        name: 'Month',
                        description: 'Monthly payment',
                        price: '{{price}}yen(excluding tax)/user/month',
                    },
                    year: {
                        name: 'Year',
                        description: 'Annual payment',
                        price: '{{price}}yen(excluding tax)/user/year',
                        bonus: "You can save 2 months' worth",
                    },
                },
                isTrialing: 'In trial (Until {{dueDate, date}})',
                reply: 'Reply',
                reaction: 'Reaction',
                chatThread: 'thread',
                tenantPolicy: 'Use lowercase , numbers, and hyphens.  (Between 3 and 20 characters)',
                tenantPolicy2: 'First character must be a lowercase letter, and last character must be either a lowercase letter or a number.',
                tenantPolicyNotice: 'Please set an ID considering actual operation, as you cannot change the URL after signing up.',
                loginIdPolicy: 'Use uppercase, lowercase, numbers, and hyphens (min. 4 characters).',
                passwordComplexityPolicy: 'Require at least one uppercase, one lowercase, one number, and one symbol ({{symbol}}) (min. {{minLength}} characters)',
                companyNameSample: 'e.g. AOBA Technologies Co. Ltd.',
                companyNameNotice: 'Please enter your official company name.',
                displayName2: 'Display name',
                displayName2Notice: 'Please enter the name to be used for display.',
                loginIdNotice: 'You cannot register a login ID that is already in use.',
                contactInformation: 'Contact information',
                contactInformationHint: 'We will use your information to provide you with service information and to contact you via support.',
                fullName: 'Full name',
                contactNameSample: 'e.g. Yamada Taiki',
                contactNameNotice: 'Please enter the full name of the person in charge.',
                departmentName: 'Department name',
                departmentNameSample: 'e.g. First Business Division',
                officialPosition: 'Official position',
                officialPositionSample: 'e.g. Manager',
                tel: 'Tel',
                telSample: 'e.g. 022-123-4567',
                domainHint: 'If you specify more than one, separate them with semicolons.',
                forceComplexPasswordHint: 'Must contain at least one uppercase letter, one lowercase letter, one number, and one symbol',
                restrictDomainsHint: 'Restrict the email addresses that can be invited as users in your organization',
                tenantSignUpDescription: 'Register with CrewWorks. \nPlease enter the email address you will be using.',
                authenticationCode: 'Authentication code',
                authenticationCodeHint: {
                    line1: 'Require two-factor authentication: for added security、',
                    line2: 'Please enter your verification code.',
                },
                makeToLeave: 'Make to leave',
                addFavorite: 'Add favorite',
                removeFavorite: 'Remove favorite',
                department: 'Department',
                crewTable: {
                    selectColumn: 'Select Column',
                    numberItemOfPage: '{{page}} items',
                    selectAll: 'Select All',
                },
                select: 'Select',
                action: 'Action',
                revision: 'Version',
                lastUpdatedAttachmentAt: 'Update time',
                ownerUserName: 'Owner',
                referenceNo: 'ID',
                taskKindName: 'Type',
                taskPriority: 'Priority',
                taskStateDisplayOrder: 'State',
                assignToUserName: 'Assigned user',
                taskCategoryName: 'Task category',
                unspecified: 'Unspecified',
                unknownCondition: 'Unknown condition',
                yesterday: 'Yesterday',
                today: 'Today',
                tomorrow: 'Tomorrow',
                lastMonth: 'Last month',
                thisMonth: 'This month',
                nextMonth: 'Next month',
                thatDay: 'That day',
                beforeAt: 'Before',
                afterAt: 'After',
                pastDays: 'Past days',
                nextDays: 'Next days',
                between: 'Between',
                myself: 'Myself',
                joiningProjectStatus: {
                    joiningStatus: 'Joining status',
                    joined: 'Joined',
                    notJoin: 'Not join',
                },
                archivedProjectStatus: {
                    archivedStatus: 'Archived state',
                    archived: 'Archived',
                    unarchive: 'Unarchive',
                },
                numberItemsSelected: '{{number}} items selected',
                selectAll: 'Select all',
                notSet: '(Not set)',
                link: 'Link',
                card: 'Card',
                displayOnTheBottom: 'Display on the bottom',
                displayOnTheRight: 'Display on the right',
                doNotDisplay: 'Do not display',
                TermsOfServiceAndPrivacyPolicy: {
                    prefixMessage: 'I agree to the ',
                    termOfService: 'terms of service',
                    and: 'and',
                    privacyPolicy: 'privacy policy',
                    suffixMessage: '.',
                },
                organizationRoleLabel: 'Please select your role in the organization.',
                projectRoleLabel: 'Please select your role in the project.',
                expirationDateLabel: 'Specify the expiration date for the user as needed.',
                newUsersLabel: 'Enter the email address or login ID of the user you want to invite and add them to the list.',
                roleInTheOrganization: 'Roles in the organization',
                roleInTheProject: 'Roles in the project',
                organizationRoleHelpText: 'External users have limited permissions.',
                projectRoleHelpText: 'External users are automatically designated as guests.',
                expirationDateHelpText: 'Once the expiration date has passed, the user will no longer be able to log in.',
                invitationRoleType: {
                    admin: 'Admin',
                    internal: 'Internal user',
                    external: 'External user',
                },
                tenantInviteUserRoleLabel: 'Please select a role.',
                bulkInviteUsers: 'Bulk invite users',
                bulkInviteUsersDescription: 'You can invite users in bulk by uploading a CSV file.',
                downloadCsvTemplate: 'Download CSV template',
                dragAndDropCsvFile: 'Drag and drop a CSV file',
                csvTemplateNote1: '*Please specify admin, internal, or external for the role value. Administrator=admin, general user=internal, external user=external.',
                csvTemplateNote2: '*For external users, an expiration date is required.',
                aboutThisApp: 'About this app',
                libraryUsed: 'Library Used',
                bankTransferDescription: 'Bank transfer is a payment method in which payment is made to the bank account indicated on the invoice.',
                projectAddExistingUserLabel: 'Select the users you want to invite and add them to the list.',
                userExpirationDate: 'User expiration date',
                returnToContractInformation: 'Return to contract information',
                allowBannerNotification: 'Allow banner notification',
                pushPermissions: {
                    default: 'Permission denied.', // デフォルトの場合も受信はできない
                    granted: 'Permission granted.',
                    denied: 'Permission denied.',
                },
                canChangeInBrowserSetting: 'Can be changed in browser settings.',
                hintForAllowInviteableExternalUsers: 'Invitable external users: {{remaining}} remaining ({{total}} total)',
                filterSettings: 'Filter settings',
                filterNameLabel: 'Filter name',
                publicScopeLabel: 'Publication range',
                defaultLabel: 'Default',
                builtIn: 'Built-in',
                companyName: 'Company name',
                postalCode: 'Postal code',
                stateProvince: 'State/Province',
                city: 'City/Town/Village',
                line1: 'Line1',
                line2: 'Line2',
                stateList: {
                    hokkaido: 'Hokkaido',
                    aomori: 'Aomori',
                    iwate: 'Iwate',
                    miyagi: 'Miyagi',
                    akita: 'Akita',
                    yamagata: 'Yamagata',
                    fukushima: 'Fukushima',
                    ibaraki: 'Ibaraki',
                    tochigi: 'Tochigi',
                    gunma: 'Gunma',
                    saitama: 'Saitama',
                    chiba: 'Chiba',
                    tokyo: 'Tokyo',
                    kanagawa: 'Kanagawa',
                    niigata: 'Niigata',
                    toyama: 'Toyama',
                    ishikawa: 'Ishikawa',
                    fukui: 'Fukui',
                    yamanashi: 'Yamanashi',
                    nagano: 'Nagano',
                    gifu: 'Gifu',
                    shizuoka: 'Shizuoka',
                    aichi: 'Aichi',
                    mie: 'Mie',
                    shiga: 'Shiga',
                    kyoto: 'Kyoto',
                    osaka: 'Osaka',
                    hyogo: 'Hyogo',
                    nara: 'Nara',
                    wakayama: 'Wakayama',
                    tottori: 'Tottori',
                    shimane: 'Shimane',
                    okayama: 'Okayama',
                    hiroshima: 'Hiroshima',
                    yamaguchi: 'Yamaguchi',
                    tokushima: 'Tokushima',
                    kagawa: 'Kagawa',
                    ehime: 'Ehime',
                    kochi: 'Kochi',
                    fukuoka: 'Fukuoka',
                    saga: 'Saga',
                    nagasaki: 'Nagasaki',
                    kumamoto: 'Kumamoto',
                    oita: 'Oita',
                    miyazaki: 'Miyazaki',
                    kagoshima: 'Kagoshima',
                    okinawa: 'Okinawa',
                },
                makeDefaultFilter: 'Make default filter',
                registerFilter: 'Register filter',
                search: 'Search',
                emojis: {
                    recentlyUsedItems: 'Recently used items',
                    smileysAndEmotion: 'Smileys & Emotion',
                    peopleAndBody: 'People & Body',
                    animalsAndNature: 'Animals & Nature',
                    foodAndDrink: 'Food & Drink',
                    travelAndPlaces: 'Travel & Places',
                    activities: 'Activities',
                    objects: 'Objects',
                    symbols: 'Symbols',
                    flags: 'Flags',
                },
                filterEditTitle: 'Edit filter',
                needNotification: 'Post to chat',
                addInvitableExternalUser: 'Change invitable users',
                invitableExternalUserForFree: 'You can invite external users for free up to twice the number of subscribed users.',
                invitableExternalUser: 'Number of external users that can be invited: {{total}} (remaining {{remaining}} users can be invited)',
                expandInviteeLimit: 'The maximum number of external user invitations can be expanded with additional purchases.',
                canBeInvite: 'The entire organization can invite up to {{people}} people + {{additionalPeople}} additional people',
                canBeInvite2: {
                    form1: 'The entire organization can invite up to {{people}} people + ',
                    form2: ' additional people',
                },
                numberOfPeople: '{{people}} people',
                priceAnnualText: '{{price}} yen(excluding tax)/year',
                priceMonthlyText: '{{price}} yen(excluding tax)/month',
                costEffectiveMonthly: 'Monthly equivalent: {{price}} yen, {{month}} months savings',
                dependencies: 'Dependencies',
                parentTask: 'Parent task',
                childTask: 'Child task',
                predecessorTask: 'Predecessor task',
                successorTask: 'Successor task',
                finishToStart: 'Finish>Start',
                finishToFinish: 'Finish>Finish',
                startToStart: 'Start>Start',
                startToFinish: 'Start>Finish',
                finishToStartDescription: 'Finish>Start : Successor task cannot start until predecessor task is completed',
                finishToFinishDescription: 'Finish>Finish : The successor task will not be completed unless the predecessor task is completed',
                startToStartDescription: 'Start>Start : Successor task cannot start before predecessor task starts',
                startToFinishDescription: 'Start>Finish : The successor task must be started in order for the predecessor task to complete',
                clear: 'Clear',
                statusType: 'Status type',
                selectTasks: 'Select tasks',
                forAllProject: 'For all project',
                menu: 'Menu',
                show: 'Show',
                scheduleTime: 'Scheduled time',
                excludedFromProgressManagement: 'Excluded from progress management',
                addEditProjectCost: 'Add/edit project costs',
                feature: 'Features',
                projectManagement: 'Project management',
                projectManagementNote: 'WBS・Gantt chart・EVM analysis',
                costPerHour: 'Cost(/h)',
                effectiveStartDate: 'Effective start date',
                pricePerHour: 'Price(/h)',
                projectCost: 'Project cost',
                progressExcluded: 'Progress excluded',
                showToday: 'Show today',
                showDetailColumn: 'Show detail column',
                wbsNumber: 'WBS number',
                scheduledStartDate: 'Scheduled start date',
                scheduledEndDate: 'Scheduled end date',
                scheduledWorkingTime: 'Scheduled working time',
                actualStartDate: 'Actual start date',
                actualEndDate: 'Actual end date',
                actualWorkingTime: 'Actual working time',
                blanks: '(Blanks)',
                progressRate: 'Progress rate',
                unread: 'Unread',
                evm: {
                    evmIndex: 'EVM index',
                    esmIndex: 'ES index',
                    referenceDate: 'Reference date',
                    expectedCost: 'Expected cost',
                    costVariance: 'Cost variance',
                    plannedCompletionDate: 'Planned completion date',
                    expectedCompletionDate: 'Expected completion date',
                    scheduleVariance: 'Schedule Variance',
                    plannedValue: 'Planned Value',
                    earnedValue: 'Earned Value',
                    actualCost: 'Actual Cost',
                    schedulePerformanceIndex: 'Schedule Performance Index',
                    costPerformanceIndex: 'Cost Performance Index',
                    budgetAtCompletion: 'Budget At Completion',
                    estimateAtCompletion: 'Estimate At Completion',
                    varianceAtCompletion: 'Variance At Completion',
                    earnedSchedule: 'Earned Schedule',
                    actualSchedule: 'Actual Schedule',
                    completionDateVariance: 'Completion Date Variance ',
                    costWithYen: 'Cost(¥)',
                    pv: 'PV',
                    ev: 'EV',
                    ac: 'AC',
                    sv: 'SV',
                    spi: 'SPI',
                    cv: 'CV',
                    cpi: 'CPI',
                    bac: 'BAC',
                    eac: 'EAC',
                    vac: 'VAC',
                    es: 'ES',
                    at: 'AT',
                    svt: 'SV(t)',
                    spit: 'SPI(t)',
                    pd: 'PD',
                    eact: 'EAC(t)',
                    plannedCost: 'Planned cost',
                },
                taskSubject: 'Task subject',
                workDate: 'Work date',
                startTime: 'Start time',
                endTime: 'End time',
                displayWorkInputForm: 'Enter working time',
                meetingHasEnded: 'Meeting has ended',
                eventManagement: 'Event management',
                eventManagementNote: 'Meeting・Event・Calendar',
                taskManagement: 'Task management',
                taskManagementNote: 'Task・Kanban',
                folder: 'Folder',
                unsorted: 'unsorted',
                workingTime: 'Working time',
                folderName: 'Folder name',
                registerWorkingTime: 'Register working time',
                registerConsecutively: 'Register consecutively',
                showOnlyMyTasks: 'Show only my tasks',
                showCompletedTasks: 'Show completed tasks',
                weekly: 'Weekly',
                monthly: 'Monthly',
                total: 'Total',
                movingFiles: 'Moving files',
                selectTheDestinationFolder: 'Select the destination folder',
                anonymousFile: 'Anonymous file',
                on: 'On',
                off: 'Off',
                meetingMinutes: 'Meeting minutes',
                groupMonthly: 'Group monthly',
                taskDetail: 'Task detail',
                groupWeekly: 'Group weekly',
                custom: 'Custom',
                planed: '(Planed)',
                zoomIn: 'Zoom in',
                zoomOut: 'Zoom out',
                selectFolder: 'Please select a folder.',
                previewUnavailable: 'Preview unavailable',
                meeting: 'Meeting',
                registerNewMeeting: 'Register new meeting',
                editMeeting: 'Edit meeting',
                allowNonAttendees: 'Allow non-participants to enter the room',
                approvalRequired: 'Permission to enter is required.',
                percentSign: '%',
                editWorktime: 'Edit work time',
                registerFolder: 'Register new folder',
                editFolder: 'Edit folder',
                actual: 'Actual',
                planned: 'Planned',
                otherWorkTimes: 'Other {{count}} items',
                root: '(Root)',
                topic: 'Topic',
                privateToProjectMembers: 'Do not make public to non-project members',
                groupDaily: 'Group daily',
                organizationSwitch: 'Organization switch',
                csvTemplateNote: '*Please specify admin or internal for the role value. Administrator=admin, general user=internal.',
                hintForAllowInviteableUsers: 'Invitable users: {{remaining}} remaining ({{total}} total)',
                bulkAddUsers: 'Bulk add users',
                notes: 'Notes',
                permit: 'Permit',
                reject: 'Reject',
                askToJoinMeeting: 'Requesting permission to join meeting',
                waitingForApproval: 'Waiting for approval to enter the room',
                read: 'Read',
                readStatus: 'Read status',
                afterDaysAndIncludeOverdue: 'After days and include overdue tasks',
                otherState: 'Other state',
                presenceStatus: 'Presence state',
                presenceStatusAdd: 'Register presence state',
                presenceStatusEdit: 'Edit presence state',
                takePhoto: 'Take photo',
                outSide: 'Outside',
                inside: 'Inside',
                zoom: 'Zoom',
                capture: 'Capture',
                photo: 'Photo',
                video: 'Video',
                cameraFlashOn: 'ON',
                cameraFlashOff: 'OFF',
                cameraFlashAuto: 'AUTO',
                unknown: 'unknown',
                meetingJoinRejected: 'Meeting join rejected',
                presenceStatusSetting: 'Presence status setting',
                permissionRequired: 'Permission required',
                microphonePermissionRequiredMessage: 'Microphone access is needed for this feature',
                recurrence: {
                    none: 'Do not repeat',
                    daily: 'Daily',
                    weekdays: 'Daily (weekdays)',
                    weekly: 'Every {{weekday}}',
                    monthlyDate: 'The {{day}} of every month',
                    monthlyDayOfWeek: 'The {{ordinal}} {{weekday}} of every month',
                    yearly: '{{month}} {{day}} every year',
                },
                recurrenceRule: 'Recurrence',
                recurrenceEndDate: 'Recurrence end date',
                target: 'Target',
                personal: 'Personal',
                registerNewSchedule: 'Register new schedule',
                editSchedule: 'Edit schedule',
                presenceType: {
                    present: 'Present',
                    busy: 'Busy',
                    away: 'Away',
                    absent: 'Absent',
                },
                changeEventScopeType: {
                    thisEvent: 'This event',
                    futureEvents: 'Future events',
                    allEvents: 'All events',
                },
                changeEventScope: 'Please select the range for changing the event.',
                deleteEventScope: 'Please select the range for deleting the schedule.',
                changeRecurrenceEvent: 'Change recurrence event',
                deleteRecurrenceEvent: 'Delete recurrence event',
                changeEventScopeDescription: 'All events within the specified range will be deleted, and new event will be created.',
                changeEventScopeDescription2: 'All events within the specified range will be deleted.',
            },
            format: {
                timestamp: '{{value, timestamp}}',
                datetime: '{{value, datetime}}',
                date: '{{value, date}}',
                shortdate: '{{value, shortdate}}',
                time: '{{value, time}}',
                shorttime: '{{value, shorttime}}',
                relativetime: '{{value, relativetime}}',
                daterange: '{{value, daterange}}',
                number: '{{value, number}}',
                currency: '{{value, currency}}',
                dateWithText: '{{value, dateWithText}}',
                dayOfWeek: '{{value, dayOfWeek}}',
                dayOfWeekShort: '{{value, dayOfWeekShort}}',
                shortDateWithText: '{{value, shortDateWithText}}',
                yearWithText: '{{value, yearWithText}}',
                dateWithDayOfWeek: '{{value, dateWithDayOfWeek}}',
                yearMonthWithText: '{{value, yearMonthWithText}}',
                weekday: '{{value, weekday}}',
                shortWeekday: '{{value, weekday}}',
                longWeekday: '{{value, longWeekday}}',
                numberOrdinal: '{{value, numberOrdinal}}',
                longMonth: '{{value, longMonth}}',
            },
            message: {
                apiError: {
                    validationFailed: 'Some input values are invalid',
                    detectedUpdateByOthers: 'Someone updated earlier. Please reload before reenter',
                    fileNameDuplication: 'Duplicate file name "{{fileName}}"',
                    unknown: 'Unknown error occurred on the server. "{{error}}"', // バックエンドが返すapiErrorUnknown型のerror要素
                    unknownResult: 'Unknown format error returned from server. {{error}}', // バックエンドが返したエラーの値そのものが不正だった場合
                    projectMemberPendingDuplication: '{{destination}} has been invited',
                    tenantUserExisted: '{{destination}} is a registered user',
                    loginIdDoesNotExist: '{{destination}} does not exist',
                    emailDoesNotExist: 'This email address cannot be changed',
                    userPendingDuplication: '{{destination}} has been invited',
                    taskCreationIsNotAllowed: 'You cannot create tasks without joining the project',
                    jwtInvalidError: 'Your login has expired. Please login again',
                    unauthorizedError: 'You do not have permission',
                    verificationCodeInvalid: 'The verification code is invalid',
                    emailAddressInvalid: 'The email address is invalid',
                    passwordInvalidFormat: 'The password is incorrect format',
                    passwordInvalidSimpleMaxLength: 'The password can only have a maximum of {{maxLength}} characters.',
                    passwordInvalidSimpleMinLength: 'The password must be at least {{minLength}} characters.',
                    passwordInvalidComplex: 'Passwords must be at least {{minLength}} characters and include at least one uppercase letter, one lowercase letter, one number, and one symbol ( {{symbols}} ).',
                    incorrectCurrentPassword: 'Current password is incorrect',
                    deleteBuiltinRoleIsNotAllowed: 'This role is not user-defined (built-in role) so it cannot be deleted',
                    invitationExpired: 'Your invitation has expired',
                    startRecordMeetingIsNotAllowed: 'You are not allowed to start record meeting',
                    endRecordMeetingIsNotAllowed: 'You are not allowed to stop recording meeting',
                    downloadFileNotAllowed: "Couldn't download because you don't have permission to download",
                    notAllowedToInviteInternalUser: 'Inviting internal users is not allowed',
                    notAllowedToInviteExternalUser: 'Inviting external users is not allowed',
                    roleNameDuplication: 'Duplicate role name "{{roleName}}"',
                    invalidPasswordLength: 'Invalid Password Length',
                    invalidComplexPasswordLength: 'If you want to force a complex password, you must set the password length to 8 or more characters',
                    invalidValueOfLengthError: 'Please enter a value below {{maxLength}} characters',
                    updateEmailInvalidDomain: 'Your email address could not be updated because it does not match your organization domain.',
                    deleteUsedRoleIsNotAllowed: 'Deletion is not allowed because this role is in use',
                    changeMeetingHostNotAllowed: 'You are not allowed to change meeting host',
                    deleteMeetingAttendeeNotAllowed: 'You are not allowed to delete meeting attendee',
                    authenticationCodeIncorrect: 'Your authentication code is incorrect',
                    authenticationCodeHasExpired: 'Your authentication code has expired',
                    couldNotVerifyAuthenticationCode: 'Could not verify your authentication code',
                    canNotSendAuthenticationCode: 'Could not send verification code',
                    invalidPhoneNumber: 'The format of the phone number you entered is incorrect',
                    notAllowedToDeleteLastProjectAdmin: 'It is not allowed to delete the last project administrator',
                    notAllowedToChangeTheRoleOfLastProjectAdmin: 'It is not allowed to change the role of last project administrator',
                    assignNonPrjGuestForExternalUser: 'It is not allowed to assign permissions that are not project guests to external users. User: {{destination}}',
                    organizationDomainRequired: 'Organization domain is required',
                    notAllowedToAddExternalUserAsProjectOwner: 'Yout cannot add external user as project owner',
                    existedDomainEmailInvalid: 'There is an email address whose domain has restricted invitations. User: {{destination}}',
                    inviteExternalUserLimitExceeded: 'The number of external users has exceeded the allowed threshold.\nNumber of people you can invite: {{inviteableExternalUserCount}}\nCurrent number of people: {{currentExternalUserCount}}\nNumber of people exceeding: {{exceededExternalUserCount}}',
                    deleteFileHistoryNotTheLatestVersion: 'The file could not be deleted because it is not the latest file.',
                    commonHttpError: 'An unexpected error occurred, please contact support.',
                    expirationDatetimeRequired: 'Expiration date is required',
                    duplicateEffectiveStartDateError: 'Duplicate effective start date',
                    EmailAddressIsAlreadyInUsed: 'This email address is already registered.',
                    deleteErrorInUse: 'It cannot be deleted because it is already in use.',
                    notAllowMoveParentFolderIntoChild: 'It is not allowed to move parent folder into a child',
                    outScopeGetMyActualTimesError: 'Please specify a working time range within 6 weeks (42 days).',
                    taskChildrenExistError: 'Task with linked subtasks cannot have changes in scheduled dates or estimated work times',
                    dataDateOutsideOfProjectDuration: 'Please set the Base Date within project period（{{startDate}}～{{endDate}}）',
                    notAllowedToChangeProjectMemberRole: 'You do not have permission to change project member roles',
                    notAllowedToRegisterTask: 'Unable to register because task management function is disabled',
                    notAllowedToRegisterEvent: 'Unable to register because event management function is disabled',
                    tenantUsersExceedLimit: 'The number of users has exceeded the limit. Only {{remainingUser}} users are left to invite.',
                    exceededStorageLimit: 'Upload cannot be performed because storage usage has been exceeded.',
                    notAllowedToCreateExternalUser: 'Create external users is not allowed',
                    notAllowedToCreateInternalUser: 'Create internal users is not allowed',
                    createTenantUsersExceedLimit: 'The number of users has exceeded the limit. Only {{remainingUser}} users are left to create.',
                    urlHasExpired: 'Registration is not possible because the URL has expired. Please apply again.',
                    notAllowedToDeleteBuiltInPresenceState: 'It is not allowed to delete built in presence state',
                    notAllowedToChangePresenceStateType: 'It is not allowed to change the presence state type of built in presence state',
                    projectMemberDuplication: 'You have already joined this project',
                    meetingIsOver: 'The meeting is over',
                    notAllowedToChangeFixedPermission: 'It is not allowed to change the fixed permission of the built-in role.',
                    disabledUserChangePassword: 'Cannot change password because account is disabled',
                    twoFactorAuthenticationMethodForFreePlanInvalid: 'Two-factor authentication via SMS is not available on the free plan',
                },
                general: {
                    error: 'Error',
                    failedToRetrieveData: 'Failed to retrieve data',
                    failedToCreate: 'Failed to create',
                    failedToUpdate: 'Failed to update',
                    failedToDelete: 'Failed to delete',
                    failedToReject: 'Failed to reject',
                    failedToCancel: 'Failed to cancel',
                    failedToDownload: 'Failed to download',
                    ANnewHasBeenRegistered: 'A new {{name}} has been registered.',
                    searchByInput: 'Search by input',
                    required: 'Required',
                    isRequired: '{{name}} is required',
                    minLength: '{{name}} must be at least {{value}} characters',
                    maxLength: '{{name}} must be at most {{value}} characters',
                    dateFormat: 'The date format is incorrect.',
                    minHour: '{{name}} must be at least {{value}} hours.',
                    maxHour: '{{name}} must be at most {{value}} hours.',
                    noDisplayData: 'No display data',
                    confirmMessage: {
                        register: 'Are you sure you want to register?',
                        edit: 'Are you sure you want to edit it?',
                        delete: 'Are you sure you want to delete?',
                        reject: 'Are you sure you want to reject?',
                        cancel: 'Are you sure you want to cancel?',
                        leave: 'Are you sure you want to be asked to leave?',
                    },
                    errorMessage: {
                        register: 'Signup failed.',
                        edit: 'Editing failed.',
                        delete: 'Failed to delete.',
                        sort: 'Sort failed.',
                        reorder: 'Reorder failed.',
                        approve: 'Approve failed',
                        cancel: 'Cancel failed.',
                        reject: 'Reject failed.',
                    },
                    loadingError: 'Loading Error',
                    invalidDate: 'Invalid date',
                    invalidEmail: 'Invalid email address',
                    invalidTel: 'The phone number format is incorrect.',
                    selectProject: 'Please select a project',
                    invalidPositiveInteger: 'Invalid positive integer',
                    duplicateEmail: 'Duplicate email',
                    pastDatesInvalid: 'Past dates are invalid',
                    domainInvalid: 'The email address domain is invalid.',
                    existedFilterName: 'The name is {{name}} has been used by another filter.',
                    onlyOneParentTask: 'Only one parent task can be set',
                    oneParentTaskMustBeSelected: 'One parent task must be selected for the child task',
                    invalidActualProgress: 'The actual progress must be a integer between 0 and 100',
                    invalidFileUploading: 'Cannot send while the file is being uploaded',
                },
                auth: {
                    failedToLogin: 'Failed to login',
                    failedToVerifyEmail: 'Failed to verify email',
                    failedToSignup: 'Failed to signup',
                    pageDoesNotExist: 'This page does not exist',
                    enterYourLoginIdOrEmail: 'Please enter your login ID or email address.',
                    sendEmailToResetPassword: 'We will send you an email to reset your password.',
                    lockedUser: 'Due to consecutive login failures, your account has been temporarily locked. Please wait for a while and try again later.',
                },
                projectMemberPending: {
                    failedToApprove: 'Failed to Approve',
                    projectMemberApproved: 'Project  member approved successfully',
                    projectMemberDelete: 'Project member deleted successfully',
                },
                project: {
                    failedToFollow: 'Failed to follow',
                    failedToUnfollow: 'Failed to unfollow',
                    failedToSubscribe: 'Failed to subscribe',
                    failedToUnsubscribe: 'Failed to unsubscribe',
                    failedToJoin: 'Failed to join',
                    projectRegistered: 'Project  created successfully',
                    projectUpdated: 'Project  updated successfully',
                    projectDeleted: 'Project  deleted successfully',
                    projectMemberRegistered: 'Add new project member successfully',
                    invalidCreatedAtFrom: 'The created date from must be less than the end created date to.',
                    invalidCreatedAtTo: 'The created date to must be be greater than the created date from.',
                    invalidUpdatedAtFrom: 'The updated date from must be less than the updated date to.',
                    invalidUpdatedAtTo: 'The updated date to must be be greater than the updated date from.',
                    projectMemberUpdated: 'Update project member successfully',
                    projectMemberDeleted: 'Remove member from project successfully',
                    eventCategoryDeleted: 'Event category deleted successfully',
                    invalidStartDate: 'The start date must be less than the end date.',
                    invalidEndDate: 'The end date must be greater than the start date.',
                    eventCategoryAdded: 'A event category has been created.',
                    eventCategoryUpdated: 'The event category has been updated.',
                    taskCategoryDeleted: 'Task category deleted successfully',
                    conflictToDeleteProject: "Can't delete because there are events or project tasks",
                    expirationDateMustBeCorrect: 'Please enter a date before today',
                    confirmLeaveProject: 'Are you sure you want to leave this project?',
                    leaveProjectSuccess: 'Leave project successfully',
                    noInviteUserPermission: 'You do not have permission to invite users',
                    approveSuccess: 'Approve request successfully',
                    rejectSuccess: 'Reject request successfully',
                    cancelSuccess: 'Cancel invitation request successfully',
                    doNotHavePermissionToInviteUser: 'You do not have permission to invite this user',
                    projectMemberUnitPriceRegisteredSuccessfully: 'Project costs have been successfully registered.',
                    projectMemberUnitPriceUpdatedSuccessfully: 'Project costs have been updated successfully.',
                    projectMemberUnitPriceDeletedSuccessfully: 'Project costs were successfully deleted.',
                    saveProjectSettingSuccess: 'Project settings have been saved',
                    confirmDeleteProjectGroup: 'There is a project associated with this group. You will be automatically removed from the group. Do you want to delete it?',
                },
                meeting: {
                    meetingRegistered: 'Meeting created successfully',
                    meetingUpdated: 'Meeting updated successfully',
                    meetingUpdateFailed: 'Meeting update failed',
                    meetingDeleted: 'Meeting deleted successfully',
                    invalidStartDate: 'The start date must be less than the end date.',
                    invalidEndDate: 'The end date must be greater than the start date.',
                    invalidStartTime: 'The start time must be less than the end time.',
                    invalidEndTime: 'The end time must be greater than the start time.',
                    addAttendeesSuccess: 'Add meeting attendees successfully',
                    attendanceAndCommentUpdated: 'Attendance and comments updated',
                    removeAttendeesSuccess: 'Remove attendees from meeting successfully',
                    meetingMinutesUpdated: 'The meeting minutes have been updated.',
                    failedToStart: 'Web meeting failed to start',
                    failedToLoadBackgroundImage: 'Failed to load background image',
                    webMeetingInProgress: 'Web meeting in progress',
                    isNotMeetingAttendee: 'Only participants can join the meeting',
                    meetingAttendeesLimitReached: 'You cannot join the meeting because the number of attendees has reached the limit.',
                    meetingHostChanged: 'Meeting host changed',
                    hostMutedYou: 'The host has muted you',
                    hostStoppedYourVideo: 'The host has stopped your video',
                    meetingAttendeesWaitingForApproval: 'There are participants waiting for admission approval',
                },
                task: {
                    taskRegistered: 'task created successfully',
                    taskUpdated: 'task updated successfully',
                    taskUpdateFailed: 'Task update failed',
                    taskDeleted: 'task deleted successfully',
                    invalidFormatTime: 'Check your entry matches the time format。',
                    taskCommentRegistered: 'Task comment created successfully',
                    taskCommentUpdated: 'Task comment updated successfully',
                    taskCommentDeleted: 'Task comment deleted successfully',
                    taskHistoryDeleted: 'Task history deleted successfully',
                    invalidTaskStartDate: 'The start date must be less than the due date.',
                    invalidTaskDueDate: 'The due date must be greater than the start date.',
                    wbsNumberReorderConfirm: 'The order will be discarded because the WBS number has not been reassigned. Do you want to end the edit?',
                    workTimesRegistered: 'Work time registered successfully',
                    confirmUnregisteredAction: 'Any information you have entered will be discarded. Are you sure?',
                    confirmDeleteLinkedSubtasks: 'Linked subtasks will also be deleted. Are you sure?',
                    workTimeUpdated: 'Work time updated successfully',
                    workTimeDeleted: 'Work time deleted successfully',
                    invalidWbsNumber: 'The WBS number is invalid',
                },
                chat: {
                    messageSurvey: 'Survey',
                    taskAdded: 'Registered a new task.',
                    taskUpdated: 'Updated a task.',
                    taskDeleted: 'Deleted a task.',
                    taskCommentUpdated: 'Updated a task.',
                    // Backendの定義と一致させるためにkeyを `event_*` としている
                    eventAdded: 'Registered a new meeting.',
                    eventUpdated: 'Updated a meeting.',
                    eventDeleted: 'Deleted a meeting.',
                    eventJoined: 'Meeting attendees changed',
                    enterYourMessage: 'Enter your message',
                    failedTPostMessage: 'Failed to post message',
                    failedToReaction: 'Failed to reaction',
                    displayAllMessages: 'All messages',
                    displayThread: 'Threads',
                    replyCount: '{{count}} replies',
                    messageDeleted: 'Deleted a message',
                    messageEdited: 'Edited a message',
                    mentionAll: 'Notify everyone',
                    mentionHere: 'Notify active users',
                    fileAdded: 'Registered a new file.',
                    fileUpdated: 'Updated a file',
                    mentioned: '{{name}} mentioned you.',
                    replied: '{{name}} replied you.',
                    reacted: '{{name}} reacted to you.',
                    failedToGetChatRoom: 'Failed to get chatroom',
                    userName: '{{name}}',
                    projectMemberJoined: 'Member to joined.',
                    memberToJoined: '{{names}} to joined',
                    projectMemberRequested: 'Requested to join the project.',
                    memberToRequested: '{{names}} to requested',
                    projectMemberLeft: 'Left the project.',
                    memberToLeft: '{{names}} to left',
                    mobileReplyCount: 'Replies({{count}})',
                },
                taskList: {
                    invalidStartDateFrom: 'The start date from must be less than the start date to.',
                    invalidStartDateTo: 'The start date to must be be greater than the start date from.',
                    invalidDueDateFrom: 'The due date must be less than the start date.',
                    invalidDueDateTo: 'The due date to must be be greater than the end date from.',
                    invalidCreatedDateFrom: 'The created date from must be less than the end created date to.',
                    invalidCreatedDateTo: 'The created date to must be be greater than the created date from.',
                    invalidUpdatedDateFrom: 'The updated date from must be less than the updated date to.',
                    invalidUpdatedDateTo: 'The updated date to must be be greater than the updated date from.',
                    invalidSortBy: 'The same value cannot be selected for Sort order 1 and Sort order 2.',
                },
                taskKind: {
                    taskKindRegistered: 'Task type created successfully',
                    taskKindUpdated: 'Task type updated successfully',
                    taskKindDeleted: 'Task type deleted successfully',
                },
                meetingKind: {
                    meetingKindRegistered: 'Meeting type created successfully',
                    meetingKindUpdated: 'Meeting type updated successfully',
                    meetingKindDeleted: 'Meeting type deleted successfully',
                },
                eventKind: {
                    eventKindRegistered: 'Event kind created successfully',
                    eventKindUpdated: 'Event kind updated successfully',
                    eventKindDeleted: 'Event kind deleted successfully',
                },
                projectGroup: {
                    projectGroupRegistered: 'Project group created successfully',
                    projectGroupUpdated: 'Project group updated successfully',
                    projectGroupDeleted: 'Project group deleted successfully',
                },
                meetingRecording: {
                    meetingRecordingDeleted: 'Event recording deleted successfully',
                    meetingRecordingStarted: '{{userName}} has started recording meetings',
                    meetingRecordingStopped: '{{userName}} has finished recording the meeting',
                    storageCapacityExceededError: 'Recording cannot be started because storage usage has been exceeded',
                },
                taskCategory: {
                    taskCategoryUpdated: 'Task category updated successfully',
                    taskCategoryAdded: 'Task category created successfully',
                },
                taskState: {
                    taskStateRegistered: 'Task state successfully created',
                    taskStateUpdated: 'Task state updated successfully',
                    taskStateDeleted: 'Task state deleted successfully',
                },
                bookmark: {
                    failedToRegisteredBookmark: 'Failed to bookmark create',
                    failedToDeleteBookmark: 'Failed to bookmark delete',
                    failedToUpdateBookMarkArchive: 'Archive failed.',
                },
                filter: {
                    filterRegistered: 'Filter created successfully',
                    filterUpdated: 'Filter updated successfully',
                    filterExistConfirm: 'Filter is exist. Are you sure you want to update?',
                    filterCreatedByAnotherUser: 'Cannot be registered because it duplicates a filter name registered by another user.',
                    filterDeleted: 'Filter deleted successfully',
                },
                taskComment: {
                    commentRegistered: 'Registered comment',
                    commentUpdated: 'Comment updated',
                    failedToRegisteredComment: 'Failed to register comment',
                    failedToUpdateComment: 'Failed to update comment',
                    invalidTimeRange: 'The end time must be greater than the start time.',
                    invalidActualWorkTimesDuration: 'Actual time that is greater than the difference between Start date and time and End date and time cannot be registered.',
                },
                home: {
                    failedToChatMessage: 'Failed to get message',
                },
                file: {
                    invalidMinFileSize: 'File is too small',
                    invalidMaxFileSize: 'File is too large',
                    invalidFileExtension: 'File type is not allowed',
                    uploadAborted: 'Upload cancelled',
                    copySuccess: 'Copy to clipboard successfully',
                    uploadFailed: 'Upload failed',
                    tagUpdated: 'Update tags successfully',
                    fileUpdated: 'Update file successfully',
                    deleteSuccess: 'Delete file successfully',
                    bulkDeleteConfirm: 'Are you sure you want to delete selected files?',
                    uploadSuccess: 'Upload file successfully',
                    fileCreated: 'Created file successfully',
                    couldNotPreview: 'Could not preview',
                    canNotDownloadFile: 'Can not download file.',
                    moveFilesSuccess: 'Move files successfully.',
                    moveDuplicatedFiles: 'Can not move the followings files, due to duplicate file names in the destination folder:\n{{duplicateFileNames}}',
                    unsupportedFileFormat: 'The file format is not supported. Please upload a file in a supported format.',
                },
                fileList: {
                    invalidCreatedAtFrom: 'The created at from must be less than the end created at to.',
                    invalidCreatedAtTo: 'The created at to must be be greater than the created at from.',
                    invalidUpdatedAtFrom: 'The updated at from must be less than the end updated at to.',
                    invalidUpdatedAtTo: 'The updated at to must be be greater than the updated at from.',
                    invalidSortBy: 'The same value cannot be selected for Sort order 1 and Sort order 2.',
                },
                follow: {
                    failedToFollow: 'Failed to follow',
                    failedToUnfollow: 'Failed to unfollow',
                    failedToSubscribe: 'Failed to subscribe',
                    failedToUnsubscribe: 'Failed to unsubscribe',
                    cannotFollowChildEntity: "Can't follow child entity",
                    cannotSubscribeChildEntity: "Can't subscribe child entity",
                },
                favorite: {
                    failedToFavorite: 'Failed to favorite',
                    failedToUnFavorite: 'Failed to unfavorite',
                },
                signup: {
                    verifyYourEmailAddress: 'Verify your email address',
                    messageCheckMail: 'We have sent the account registration URL to the email address you provided.',
                    emailIsAlreadyRegistered: 'This email address is already registered.',
                    messageEmailIsAlreadyRegistered: 'Email address has been entered before and cannot continue',
                    register: {
                        signupSuccess: 'You have signed up! Welcome to CrewWorks!',
                        invalidFormatTenantAlias: 'Does not meet requirements.',
                        invalidFormatPassword: 'Does not meet requirements.',
                        invalidFormatConfirmPassword: 'Password and confirm password is not same',
                        tenantIdAlreadyExists: 'Tenant ID already exists',
                        loginIdAlreadyExists: 'Login ID is duplicated',
                        invalidLoginId: 'Does not meet requirements.',
                    },
                    invalidPlan: 'Invalid plan information.',
                    invalidBillingCycle: 'Invalid billing cycle.',
                    singupLinkHasExpired: 'This link has expired and is no longer valid. \n Please try again.',
                },
                tenant: {
                    inviteUserSuccess: 'Invite user successfully',
                    bulkAddUsersSuccess: 'Bulk add users successfully',
                    changeSelectedUsers: 'Change selected users',
                    roleUpdated: 'role updated successfully',
                    saveTenantSettingSuccess: 'Your organization settings have been saved',
                    roleNameDuplicated: 'Role name is duplicated',
                    roleDeleted: 'role deleted successfully',
                    userRoleAndStatusUpdated: 'User roles and states have been updated',
                    cancelInvitationSuccess: 'Invitation successfully canceled',
                    canNotEditUserTogether: 'External users cannot be edited together',
                    invalidDomain: 'Invalid domain',
                    inviteUserConfirmMessage: 'Contains users from different domains. Is it OK?',
                    batchInviteError: 'There are {{count}} errors. Please correct and upload again.',
                    required_item: 'This item is required.',
                    invalidFormatEmail: 'Email address is incorrect.',
                    invalidFormatRoleCode: 'Please set one of the following roles. (admin, internal, external)',
                    invalidFormatExpirationDatetime: 'Please enter the date in [YYYY/MM/DD] format.',
                    external_user: "A user who is different from the organization's domain will be added as an administrator/general user.",
                    existed_user: 'Registered',
                    existRegisteredUser: 'There are already users registered in your organization. The user will not be invited.',
                    invalidFormatPassword: 'Password is incorrect format',
                },
                user: {
                    userIsInvalid: 'This user is invalid',
                },
                personalSetting: {
                    saveUserSettingSuccess: 'Personal settings have been saved.',
                    notBeenSet: 'Not been set',
                    sendVerificationCode: 'We will send you a verification code to verify your new email address.',
                    enterVerificationCode: 'Please enter the verification code provided in the email.',
                    howToGetAuthorizationCode: 'How will I receive my authorization code?',
                    twoFactorSmsNote: 'Please enter your phone number to receive the verification code.',
                    twoFactorMailNote: 'Please enter your email address to receive the verification code.',
                    twoFactorAppNote: 'Please install the Google Authenticator or Microsoft Authenticator app on your smartphone and scan this barcode.',
                    updateEmailSuccess: 'Your email address has been updated.',
                    passwordUpdated: 'Password has been updated.',
                    sendVerifyCodeSuccess: 'We have sent you a verification code.',
                    twoFactorSettingSaved: 'Two-factor authentication settings saved.',
                    twoFactorSettingDeleted: 'Two-factor authentication settings deleted',
                },
                resetPassword: {
                    sendMailSuccess: 'Sent password reset email successfully',
                    resetPasswordSuccess: 'Password reset is successfully.',
                },
                contract: {
                    upgradePlanSuccess: 'Upgrade plan success',
                    changeCyclePlanSuccess: 'Change cycle plan success',
                    changeStoragePlanSuccess: 'Change storage plan success',
                    registerBillingInformationSuccess: 'Register billing information success',
                    registerPaymentMethodSuccess: 'Register payment method success',
                    purchaseExternalUserSuccess: 'Successfully purchased external user quota',
                    updateContactSuccess: 'Updated contact information',
                    registerCreditCardGeneralError: 'Credit card registration failed',
                    registerCreditCardErrorWithDedicatedMessage: {
                        card_declined: 'Rejected by credit card company. Please enter other card information',
                        expired_card: 'This is an expired card. Please enter other card information',
                        incorrect_cvc: 'Incorrect security code',
                        incorrect_number: 'Incorrect card number',
                    },
                    enablingCardPayments: 'Enabling card payments...',
                    enablingCardPaymentsTimeout: 'Enabling credit card payment has timed out. Please try again.',
                    enablingCardPaymentsError: 'Enabling card payment failed.',
                    upgradePlanInfo: 'Upgrade your plan. When you upgrade, you will be billed immediately for the difference.',
                    downgradePlanInfo: 'Downgrade your plan. If you downgrade, the difference will be processed as a deposit and applied to future payments. Please note that no refunds will be made.',
                },
                notification: {
                    requestPermission: 'By allowing notifications, you can receive the latest information in real time.',
                },
                wbs: {
                    wbsNumberHasBeenAssigned: 'The WBS number has been assigned.',
                },
                folder: {
                    folderRegistered: 'Folder created successfully',
                    folderUpdated: 'Folder updated successfully',
                    folderDeleted: 'Folder deleted successfully',
                    folderDeleteConfirm: 'All files in the folder will be deleted. Are you sure?',
                    moveFolderSuccess: 'Move folder successfully',
                },
                startFreeTrialNotes: {
                    availableFeatures: '・You can try all features of the Professional Plan for free for 30 days.',
                    afterTheTrialEnds: '・After the free trial ends, there will be no automatic charges.\nIf you choose to subscribe, any data you registered during the trial period will remain available for continued use.',
                    needHelp: {
                        prefix: '・If you have any questions, please check our ',
                        suffix: ' for assistance.',
                        link: 'FAQ',
                    },
                },
                presenceStatus: {
                    presenceDeleted: 'Presence state deleted successfully',
                    presenceRegistered: 'Presence state created successfully',
                    presenceUpdated: 'Presence state updated successfully',
                },
                event: {
                    eventUpdated: 'Event updated successfully',
                    eventDeleted: 'Event deleted successfully',
                },
            },
            // TODO: mobile用の定義はCREW-6115で削除予定。そのため、ここに定義は追加しないこと。
            //https://break-tmc.atlassian.net/browse/CREW-6115
            mobile: {
                label: {
                    timeline: 'Timeline',
                    menu: 'Menu',
                    news: 'News',
                    mentionAndReply: 'Mention And Reply',
                    bookmark: 'Bookmark',
                    theme: 'Theme',
                    language: 'Language',
                    light: 'Light',
                    dark: 'Dark',
                    ja: 'Japanese',
                    en: 'English',
                    selectDate: 'Select date',
                    search: 'Search',
                    keyword: 'Keyword',
                    assignedUser: 'Assigned user',
                    eventKind: 'Event type',
                    eventCategory: 'Event category',
                    startDate: 'Start date',
                    endDate: 'End date',
                    createdDate: 'Created date',
                    updatedDate: 'Updated date',
                    moveToState: 'Move to {{stateName}}',
                    myTask: 'My task',
                    updated: 'rollover',
                    detail: 'Detail',
                    selectingTags: 'Selecting tags',
                    schedule: 'Schedule',
                    deleteBookmark: 'Delete bookmark',
                    toBookmark: 'Bookmark',
                    toComment: 'Comment',
                    registerWorkTime: 'Register work time',
                    year: 'Year',
                    monthOfYear: {
                        january: 'January',
                        february: 'February',
                        march: 'March',
                        april: 'April',
                        may: 'May',
                        june: 'June',
                        july: 'July',
                        august: 'August',
                        september: 'September',
                        october: 'October',
                        november: 'November',
                        december: 'December',
                    },
                    favorite: 'Favorite',
                    directMessage: 'Direct message',
                },
                action: {
                    cancel: 'Cancel',
                    confirm: 'Confirm',
                    reaction: 'Reaction',
                    download: 'Download',
                    share: 'Share',
                },
                header: {
                    newsFeedPage: 'News Feed',
                    chatThreadPage: 'Chat Thread',
                    projectDetailPage: 'Project Detail',
                    settingsPage: 'Settings',
                    projectListAdvancedSearchPage: 'Advanced Search',
                    menuPage: 'Menu',
                    projectDetailMeetingAdvancedSearchPage: 'Advanced Search',
                    taskDetailPage: 'Task Detail',
                    taskDetailFileAdvancedSearchPage: 'Advanced Search',
                    taskListAdvancedSearchPage: 'Advanced Search',
                    projectDetailTaskAdvancedSearchPage: 'Advanced Search',
                    projectDetailFileAdvancedSearchPage: 'Advanced Search',
                    meetingDetailTaskListAdvancedSearchPage: 'Advanced Search',
                    projectSelectMemberEntryModal: 'Member Selection',
                    filePickerEntryModal: 'Choose File',
                    fileDetailPage: 'File Detail',
                    crewSaveFilterModal: 'Save Condition',
                    fileListAdvancedSearchPage: 'Advanced Search',
                    meetingDetailFileListAdvancedSearchPage: 'Advanced Search',
                    meetingDetailVideoViewerPage: 'Video Playback',
                    searchPage: 'Search',
                    homePage: 'Chat', //Home画面としてチャット画面を表示する
                    meetingDetailPage: 'Meeting detail',
                },
                message: {
                    noMembers: 'No members',
                    cannotPreview: 'This file cannot be preview',
                    noTags: 'No tags',
                },
            },
        },
    },
    [Language.Ja]: {
        translation: {
            general: {
                loadMore: 'さらに表示',
                fold: '折りたたむ',
                entityType: {
                    customers: '顧客',
                    chatMessages: 'チャットメッセージ',
                    opportunities: '案件',
                    opportunityActivities: '案件活動',
                    projects: 'プロジェクト',
                    events: 'イベント',
                    tasks: 'タスク',
                    taskActivities: 'タスク活動',
                    files: 'ファイル',
                    users: 'ユーザー',
                    departments: '部門',
                },
            },
            action: {
                inviteUser: 'ユーザーを招待',
                approve: '承認する',
                reject: '却下する',
                new: '新規',
                edit: '編集',
                delete: '削除',
                cancel: 'キャンセル',
                register: '登録',
                register2: '登録する', // 契約関係のみで使用。他画面は基本的にregisterを使用する。
                close: '閉じる',
                login: 'ログイン',
                logout: 'ログアウト',
                tryFreePlan: '無料で使ってみる',
                startFreeTrial: '無料トライアルをはじめる',
                tryAgain: '再試行',
                send: '送信',
                projectNew: '新規プロジェクト',
                following: 'フォロー中',
                followUs: 'フォローする',
                addTask: 'タスクを追加',
                confirmPermit: 'OK',
                confirmCancel: 'キャンセル',
                advancedCondition: '詳細条件',
                retry: '再試行',
                showMore: '続きを表示',
                abbreviate: '省略して表示',
                newTask: '新規タスク',
                searchByThisCondition: 'この条件で検索',
                clearSearchCondition: '検索条件をクリア',
                saveSearchCondition: '検索条件を保存',
                addMember: 'メンバーを追加',
                removeMember: 'メンバーを削除',
                addNew: '新規追加',
                newFile: '新規ファイル',
                save: '保存',
                reply: '返信',
                detail: '詳細',
                startMeeting: '会議を開始',
                joinMeeting: '会議に参加',
                leaveMeeting: '会議を退室',
                endMeeting: '会議を終了',
                lexical: {
                    insertRowAbove: '上に行を挿入',
                    insertRowsAbove: '上に{{number}}行を挿入',
                    insertRowBelow: '下に行を挿入',
                    insertRowsBelow: '下に{{number}}行を挿入',
                    insertColumnLeft: '左に列を挿入',
                    insertColumnsLeft: '左に{{number}}列を挿入',
                    insertColumnRight: '右に列を挿入',
                    insertColumnsRight: '右に{{number}}列を挿入',
                    deleteRow: '行を削除',
                    deleteColumn: '列を削除',
                    deleteTable: 'テーブルを削除',
                    addRowHeader: 'ヘッダ行を追加',
                    removeRowHeader: 'ヘッダ行を削除',
                    addColumnHeader: 'ヘッダ列を追加',
                    removeColumnHeader: 'ヘッダ列を削除',
                    rows: '行',
                    columns: '列',
                    cellBackgroundColor: 'セルの背景色',
                    paragraph: 'ノーマルテキスト',
                    h1: '見出し１',
                    h2: '見出し２',
                    h3: '見出し３',
                    bulletList: '箇条書きリスト',
                    numberList: '番号付きリスト',
                    checkList: 'チェックリスト',
                    quote: '引用',
                    codeBlock: 'コードスニペット',
                    align: '揃え',
                    insert: '挿入',
                    table: 'テーブル',
                    confirm: '確認',
                    insertTable: 'テーブルを挿入',
                    backgroundColor: '背景色',
                    leftAlign: '左揃え',
                    centerAlign: '中央揃え',
                    rightAlign: '右揃え',
                    justifyAlign: '両端揃え',
                    outdent: 'インデント解除',
                    indent: 'インデント',
                },
                addAttendee: '参加者を追加',
                removeAttendee: '参加者を削除',
                addToProject: 'プロジェクトに追加',
                addToAttendees: '参加者に追加',
                inviteToProject: 'プロジェクトに招待',
                registerAndJoin: '登録して参加する',
                loginToJoin: 'ログインして参加する',
                newProjectGroup: '新規プロジェクトグループ',
                newMeetingKind: '新規会議種別',
                newEventKind: '新規イベント種別',
                newTaskKind: '新規タスク種別',
                newTaskState: '新規タスクステータス',
                search: '検索',
                mobileApprove: '承認',
                mobileReject: '却下',
                yes: 'はい',
                no: 'いいえ',
                change: '変更する',
                confirm: '確認する',
                sendVerifyCode: '送信する',
                toSave: '保存する',
                newEventCategory: '新規イベント分類',
                newTaskCategory: '新規タスク分類',
                cancelInvitation: '招待をキャンセル',
                activate: '有効化する',
                sendEmail: 'メールを送信',
                upgradePlan: 'アップグレードする',
                resetPassword: '再設定',
                approveRequest: '申請を承認',
                rejectRequest: '申請を却下',
                disableTwoFactor: '無効化する',
                markAllRead: 'すべて既読にする',
                apply: '適用',
                addCondition: '条件追加',
                copyLink: 'リンクをコピー',
                bulkInvitation: '一括招待',
                configureNotification: '通知の設定',
                editWBS: 'WBSを編集',
                renumberWBSNumber: 'WBS番号を再付番する',
                finishEditingWBS: 'WBSの編集終了',
                createSubFolder: 'サブフォルダを作成',
                editFolder: 'フォルダを編集',
                deleteFolder: 'フォルダを削除',
                copy: 'コピー',
                newFolder: '新規フォルダ',
                move: '移動',
                addSubtask: 'サブタスクを追加',
                deleteTask: 'タスクを削除',
                toDay: '今日',
                displayDetail: '詳細表示',
                add: '追加',
                addUser: 'ユーザーを追加',
                expandAll: 'すべて展開',
                closeAll: 'すべて閉じる',
                goToFolder: 'フォルダへ移動',
                newMeeting: '新規会議',
                deleteFile: 'ファイルを削除',
                addWorkTime: '作業時間を追加',
                switchToAnotherOrganization: '別の組織に切り替え',
                turnOffAudio: '音声をオフにする',
                turnOffVideo: 'ビデオをオフにする',
                appointAsMeetingHost: '主催者に任命する',
                dismissAsMeetingHost: '主催者を解任する',
                removeFromMeeting: '退室させる',
                bulkAdd: '一括追加',
                editMeetingMinutes: '編集する',
                mention: 'メンション',
                mentionAndReply: 'メンションと返信',
                allMessage: 'すべてのメッセージ',
                off: 'オフ',
                newPresenceStatus: '新規在席状態',
                leaveTheMeeting: '会議から退室する',
                endTheMeeting: '会議を終了する',
                newSchedule: '新規予定',
            },
            label: {
                all: 'すべて',
                allState: 'すべての状態',
                organization: '組織',
                authority: '権限',
                view: 'ビュー',
                detail: '詳細',
                start: '開始',
                end: '終了',
                project: 'プロジェクト',
                schedule: 'スケジュール',
                changeLanguage: '言語切替',
                username: 'ユーザーIDまたはメールアドレス',
                password: 'パスワード',
                tenantId: '組織ID',
                tenantIdSample: '例: aoba-tech',
                tenantSettings: 'テナント設定',
                unEntered: '（未入力）',
                assignedUser: '担当者',
                dueDate: '期限',
                feed: 'フィード',
                bookmark: 'ブックマーク',
                chat: 'チャット',
                message: 'メッセージ',
                mentionAndReply: 'メンションと返信',
                opportunity: '案件',
                allOpportunities: '全案件',
                myOpportunities: '私の案件',
                opportunityName: '案件名',
                subject: '件名',
                description: '説明',
                content: '内容',
                completed: '完了',
                stage: 'ステージ',
                probability: '確度',
                closeDate: '受注予定日',
                amount: '金額',
                opportunityEvent: '案件イベント',
                customer: '顧客',
                customerName: '得意先名',
                lead: 'リード',
                support: 'サポートポータル',
                task: 'タスク',
                classification: '種別',
                state: 'ステータス',
                status: 'ステータス',
                priority: '優先度',
                file: 'ファイル',
                projectName: 'プロジェクト名',
                key: 'キー',
                owner: '所有者',
                progress: '進捗',
                scope: '公開範囲',
                projectGroup: 'プロジェクトグループ',
                member: 'メンバー',
                change: '変更',
                projectAddTitle: '新規プロジェクトの登録',
                noOptions: 'オプションなし',
                projectEditTitle: 'プロジェクトの編集',
                addTaskTitle: 'タスクの追加',
                editTaskTitle: 'タスクの編集',
                addTaskCommentTitle: 'タスクコメントの追加',
                editTaskCommentTitle: 'タスクコメントの編集',
                addTaskWorkTitle: 'タスク作業時間の追加',
                editTaskWorkTitle: 'タスク作業時間の編集',
                startDate: '開始日',
                deadline: '期限',
                category: '分類',
                scheduledTime: '予定時間',
                allDay: '終日',
                attendee: '参加者',
                notificationRecipient: '通知先',
                // Backendの定義と一致させるためにkeyを `Events` としている
                allEvents: 'すべての会議',
                meetingsToAttend: '参加対象の会議',
                myTasks: '私のタスク',
                uncompletedTasks: '未完了のタスク',
                allTasks: 'すべてのタスク',
                allTypes: 'すべての種別',
                allBookmark: 'すべて',
                unarchive: '未処理のみ',
                archived: '処理済のみ',
                incomplete: '未完了',
                overdue: '期限オーバー',
                participatingProjects: '参加しているプロジェクト',
                myProjects: '私のプロジェクト',
                includeArchivedProjects: 'アーカイブしたプロジェクトを含む',
                activeProjects: '有効なプロジェクト',
                archivedProjects: 'アーカイブ済みプロジェクト',
                meetingKind: '会議種別',
                eventKind: 'イベント種別',
                taskKind: 'タスク種別',
                taskState: 'タスクステータス',
                memberState: '状態',
                keyword: 'キーワード',
                registrationDate: '登録日',
                rolloverDate: '更新日',
                order: '並び順{{number}}',
                searchCondition: '検索条件',
                myPage: 'マイページ',
                newArrival: '新着',
                taskCategory: 'タスク分類',
                projectSettingTitle: 'プロジェクト設定',
                standardSearch: '標準検索',
                advancedSearch: '詳細検索',
                endDate: '終了日',
                user: 'ユーザー',
                role: '役割',
                registeredDate: '登録日時',
                name: '名称',
                explanation: '説明',
                addMember: 'メンバーの追加',
                editMember: 'メンバーの編集',
                type: 'タイプ',
                notStarted: '未着手',
                inProgress: '作業中',
                inReview: 'レビュー中',
                taskKindAdd: 'タスク種別の追加',
                taskKindEdit: 'タスク種別の編集',
                color: 'カラー',
                id: 'ID',
                ascending: '昇順',
                descending: '降順',
                taskStateAdd: 'タスクステータスの追加',
                taskStateEdit: 'タスクステータスの編集',
                meetingKindAdd: 'プロジェクト会議種別の追加',
                meetingKindEdit: 'プロジェクト会議種別の編集',
                eventKindAdd: 'イベント種別の追加',
                eventKindEdit: 'イベント種別の編集',
                projectGroupAdd: 'プロジェクトグループの追加',
                projectGroupEdit: 'プロジェクトグループの編集',
                displayColor: 'カラー',
                addEventCategory: 'イベント分類の追加',
                editEventCategory: 'イベント分類の編集',
                addTaskCategory: 'タスク分類の追加',
                editTaskCategory: 'タスク分類の編集',
                emoji: '絵文字',
                saveCondition: '条件を保存',
                filterName: 'フィルター名',
                public: '公開',
                private: '非公開',
                actualTime: '実績時間',
                highest: '最高',
                high: '高め',
                normal: '普通',
                low: '低め',
                lowest: '最低',
                comment: 'コメント',
                commentAndHistory: 'コメントと履歴',
                history: '履歴',
                time: '時間',
                weeks: '週',
                days: '日',
                hours: '時間',
                minutes: '分',
                relatedItem: '関連先',
                entityType: '関連先タイプ',
                entityRecord: '関連先',
                remainingWorkTimes: '残時間',
                startDatetime: '開始日時',
                unassigned: '未割り当て',
                taskAdded: 'タスクを追加しました',
                allFiles: 'すべてのファイル',
                myFiles: '私のファイル',
                dropFileHere: 'ファイルをここにドロップしてください',
                dragDropOrClickFile: 'ファイルをここにドロップ、またはここをクリックしてファイルを選択して下さい',
                registerNewFile: 'ファイルの追加',
                tag: 'タグ',
                updatedHistory: '更新履歴',
                preview: 'プレビュー',
                version: 'バージョン',
                fileName: 'ファイル名',
                changer: '更新者',
                createdBy: '登録者',
                registrationDatetime: '登録日時',
                updatedBy: '更新者',
                updateDatetime: '更新日時',
                size: 'サイズ',
                selectFile: 'ファイルを選択',
                selectImage: '写真を選択',
                or: 'または',
                uploaded: 'アップロード完了',
                uploadFailed: 'アップロード失敗',
                editFile: 'ファイルの編集',
                contentChanged: '変更内容',
                saveTags: 'タグの追加',
                changes: '変更内容',
                fileUpload: 'ファイルのアップロード',
                allMessage: 'すべて',
                onlyThisRoom: 'このルームのみ',
                sortOrder: '並び順',
                searchTag: 'タグを選択',
                attachments: '添付ファイル',
                downloadFile: 'ファイルのダウンロード',
                deleteFile: 'ファイル削除',
                dashboard: 'ダッシュボード',
                settings: '設定',
                logout: 'ログアウト',
                addUser: 'ユーザーの追加',
                directMessage: 'ダイレクトメッセージ',
                shareScreens: '画面共有',
                blur: 'ぼかし',
                dateTime: '日時',
                microphone: 'マイク',
                speaker: 'スピーカー',
                test: 'テスト',
                noiseReduction: 'ノイズ除去',
                startWebMeeting: 'Web会議の開始',
                webMeetingSetting: 'Web会議の設定',
                displayName: '名前',
                displayNameSample: '例: 山田 大樹',
                automaticRecording: '自動録画',
                camera: 'カメラ',
                background: '背景',
                unreadFromHere: 'ここから未読',
                fullScreen: '全画面表示',
                exitFullScreen: '全画面表示の終了',
                recording: '録画',
                add: '追加',
                updated: '更新',
                pleaseSelectFile: 'ファイルを選択してください',
                image: '画像',
                taskId: 'タスクID',
                related: '関連',
                favorite: 'お気に入り',
                enterNumberOfRows: '行数を入力してください',
                enterNumberOfColumns: '列数を入力してください',
                userIdOrEmailAddress: 'メールアドレスまたはログインID',
                forgotPassword: 'パスワードをお忘れですか？',
                other: 'その他',
                leaveRoom: '退室',
                startRecording: '録画開始',
                endRecording: '録画終了',
                recordingInprogress: '録画中',
                horizontalScreen: '横画面',
                updateFile: 'ファイルの編集',
                mailAddress: 'メールアドレス',
                requiredApprovalToJoin: '参加には承認が必要',
                attendance: '出席',
                absence: '欠席',
                attending: '出欠',
                existingUsers: '既存ユーザーの追加',
                newUsers: '新規ユーザーの招待',
                addOrInviteMembers: 'メンバーを追加/招待',
                addOrInviteMemberDescription: 'プロジェクトにメンバーを追加または招待します。',
                unanswered: '未回答',
                addAttendeeDialogTitle: '参加者を追加',
                addAttendeeDescription: '会議に参加者を追加します。',
                addExistingUserDescription: '組織内に既に存在するユーザーを追加することができます。',
                people: '人',
                inviteNewUsersDescription: '新しいユーザーを招待することができます。メールアドレスかログインIDを入力してください。',
                specifyExpirationTitle: '組織外のユーザーの有効期限を指定してください',
                confirmAttendance: '出欠を確認する',
                organizationSettings: '組織設定',
                initialValue: '初期値',
                comfirmLeaveMetting: '会議から強制退室となりますが、ページを移動してもよろしいですか ?',
                userType: 'ユーザー種別',
                expirationDate: '有効期限',
                internalUser: '一般ユーザー',
                externalUser: '外部ユーザー',
                invitingUsers: 'ユーザーの招待',
                userOutsideOrganization: '組織外ユーザー',
                assignToMe: '私が担当',
                joinProject: 'プロジェクトに参加',
                clickToJoinProject: 'プロジェクトのメンバーになるには「プロジェクトに参加」をクリックしてください',
                approvalPending: '承認待ち',
                projectImage: 'プロジェクトイメージ',
                signupErrorInvalid: 'この招待リンクは無効です。',
                signupErrorExpired: '有効期限が切れたか招待がキャンセルされました。',
                signupExistingUserMessage: 'CrewWorksに招待されています。',
                signupExistingUserMessage2: '{{email}}に送信された招待を承諾するには、ログインしてください。',
                joined: '参加済み',
                inviting: '招待中',
                organizationName: '組織名',
                organizationNameSample: '例: 青葉テクノロジーズ',
                loginId: 'ログインID',
                loginIdSample: '例: yamada-aoba-tech',
                confirmPassword: 'パスワード確認',
                clickHereToRedirectToTenantPage: 'ここをクリックしてテナントのページへ遷移する',
                registerAnotherEmailAddress: '他のアドレスで登録する',
                signupNewUserMessage: 'CrewWorksに招待されています。',
                signupNewUserMessage2: '{{email}}に送信された招待を承諾するには、アカウント登録を行ってください。',
                inviteUser: 'ユーザーを招待',
                loginIdOrEmail: 'メールアドレス/ログインID',
                estimatedWorkTimes: '3d30m, 150mの書式で入力。',
                list: 'リスト',
                noDataText: '表示するデータがありません。',
                reactOtherMembersMessage: ' と他{{otherMembers}}名',
                personalSetting: '個人設定',
                personalProfile: 'プロフィール',
                personalDisplay: '表示',
                security: 'セキュリティ',
                changeAvatar: 'アバターを変更する',
                avatarNote: 'JPG, PNG が使用可能',
                avatarSize: 'サイズは{{avatarSize}}MBまで',
                userInformation: 'ユーザー情報',
                userAlias: '別名(ふりがな)',
                userComment: 'コメント',
                language: '言語',
                ja: '日本語',
                en: '英語',
                region: '地域',
                languageAndRegion: '言語と地域',
                sendDirectMessage: 'ダイレクトメッセージを送信',
                allUsers: 'すべてのユーザー',
                internalUsers: '組織内ユーザー',
                externalUsers: '組織外ユーザー',
                alias: '別名',
                searchKey: '検索キー',
                internal: '組織内',
                external: '組織外',
                twoFactorAuthentication: '2要素認証',
                newEmailAddress: '新しいメールアドレス',
                verificationCode: '認証コード',
                currentPassword: '現在のパスワード',
                newPassword: '新しいパスワード',
                newPasswordConfirm: '新しいパスワード(確認)',
                appearance: '外観',
                theme: 'テーマ',
                light: 'ライトモード',
                dark: 'ダークモード',
                displayNumber: '表示件数',
                displayFormat: '表示形式',
                displayRange: '表示範囲',
                historyDisplayOrder: '履歴の表示順',
                initialDisplay: '初期表示',
                itemPerPage: '{{ displayNumber }}件',
                timelineDisplay: 'タイムライン表示',
                threadDisplay: 'スレッド表示',
                displayAll: 'すべて表示',
                thisLayerOnly: 'この階層のみ',
                newsMentionAndReply: 'メンションと返信',
                notification: '通知',
                whatToNotify: '通知する内容',
                notificationMethod: '通知の方法',
                notificationMentionAndReply: 'メンションと返信',
                allMessages: 'すべてのメッセージ',
                newTopicsOnly: '新着トピックのみ',
                allAttentions: 'すべて(メンションと返信とリアクション)',
                mention: 'メンション',
                banner: 'バナー',
                email: 'メール',
                bannerAndEmail: 'バナーとメール',
                none: 'なし',
                eventCategory: 'イベント分類',
                editUser: 'ユーザーの編集',
                bulkEditUsers: 'ユーザーの一括編集',
                enabled: '有効',
                disabled: '無効',
                archiveProject: 'プロジェクトをアーカイブする',
                archiveProjectDescription: 'アーカイブするとプロジェクト一覧に表示されなくなります。',
                isArchived: 'アーカイブ済み',
                unarchived: '未アーカイブ',
                myTask: 'マイタスク',
                addRole: '役割を追加',
                roleName: '名称 ',
                original: 'コピー元',
                organizationDomain: '組織のドメイン',
                addExternalUser: '外部ユーザーの追加',
                externalUserExpirationDate: '外部ユーザーの有効期限(初期値)',
                numberOfCharactersPassword: 'パスワードの文字数',
                lettersOrMore: '文字以上',
                enforceComplexPassword: '複雑なパスワードを強制する',
                restrictDomains: 'ドメインを制限する',
                enforeTwoFactorAuthentication: '2要素認証を強制する',
                maximumSize: '最大サイズは{{ maximumSize }}MB',
                allow: '許可する',
                disallow: '許可しない',
                app: 'アプリ',
                sms: 'SMS',
                securityEnabled: '有効({{ securityAuthenticationType }})',
                japanRegion: '(UTC+09:00)  東京',
                vietnamRegion: '(UTC+07:00)  ハノイ',
                systemRole: 'システム権限',
                projectRole: 'プロジェクト権限',
                tenantSetting: '組織設定',
                changeContractPlan: '契約プランの変更',
                upgradeContractPlan: 'アップグレード',
                contractInformation: '契約情報',
                waitingApproval: '承認待ち',
                advancedSetting: '詳細設定',
                returnToProject: 'プロジェクトに戻る',
                phoneNumber: '電話番号',
                nation: '国',
                japanPhoneCode: '日本 +81',
                vietnamPhoneCode: 'ベトナム +84',
                roles: {
                    sysTenantAdmin: '組織管理者',
                    sysAdmin: '管理者',
                    sysUser: '一般ユーザー',
                    sysExternalUser: '外部ユーザー',
                    prjAdmin: '管理者',
                    prjMember: 'メンバー',
                    prjGuest: 'ゲスト',
                },
                permissions: {
                    sysContractViewEdit: '契約情報の表示/変更',
                    sysTenantSettingViewEdit: '組織設定の表示/変更',
                    sysProjectCreateDelete: 'プロジェクトの作成/削除',
                    sysPublicProjectViewJoin: 'パブリックプロジェクトの表示 /参加',
                    sysInternalUserInvite: '組織内ユーザーの招待',
                    sysExternalUserInvite: '組織外ユーザーの招待',
                    sysUserListView: 'ユーザーリストの表示',
                    prjProjectEdit: 'プロジェクトの編集',
                    prjProjectSettingEdit: 'プロジェクト設定の変更',
                    prjMemberAdd: 'プロジェクトメンバーの追加',
                    prjMemberEdit: 'プロジェクトメンバーの編集',
                    prjMemberDelete: 'プロジェクトメンバーの削除',
                    // Backendの定義と一致させるためにkeyを `prjEvent*` としている
                    prjEventCreate: '会議の作成',
                    prjEventEdit: '会議の編集',
                    prjEventDelete: '会議の削除',
                    prjEventMeetingManage: '会議の管理',
                    prjTaskCreate: 'タスクの作成',
                    prjTaskEdit: 'タスクの編集',
                    prjTaskDelete: 'タスクの削除',
                    prjFileCreate: 'ファイルの作成',
                    prjFileEdit: 'ファイルの編集',
                    prjFileDownload: 'ファイルのダウンロード',
                    prjFileDelete: 'ファイルの削除',
                    prjFolderCreate: 'フォルダの作成',
                    prjFolderEdit: 'フォルダの編集',
                    prjFolderDelete: 'フォルダの削除',
                    prjMemberRoleEdit: 'プロジェクトメンバーの役割変更',
                },
                leaveProject: 'プロジェクトから退出',
                contract: '契約情報',
                contractPlan: '契約プラン',
                contractPlanChange: 'ご契約プランの変更',
                contractNow: '現在',
                contractAfterChange: '変更後',
                priceAnnual: '円(税抜)/ユーザー/年',
                priceMonth: '円(税抜)/ユーザー/月',
                resetPasswordHint: '新しいパスワードを入力してください。',
                signUpHint: 'CrewWorksの組織と組織管理者を登録します。',
                expirationDatetimeHint: '{{ expirationDatetime }} までに登録を完了してください。',
                organizationInformation: '組織情報',
                systemAdministratorInformation: '組織管理者情報',
                currentPlan: '契約内容',
                basicPlan: '基本プラン',
                perUser: '{{userUnit}}ユーザー当たり{{price}}円/月',
                storageCapacity: 'ストレージ容量',
                externUserLimitTitle: '外部ユーザー上限数',
                externUserLimitValue: '{{ baseExternalUsers }}人 + 追加枠{{ addingExternalUsers }}人',
                changeExternlUserCount: '招待可能数の変更',
                changeCapacity: '容量の変更',
                contractCycle: '契約サイクル',
                year: '年間',
                month: '月間',
                changeBillingCycle: '請求サイクルの変更',
                usageStatus: '使用状況',
                numberOfUser: 'ユーザー数',
                numberOfExternalUser: '外部ユーザー数',
                storage: 'ストレージ',
                contactName: '担当者名',
                changeContactInformation: '連絡先情報の変更',
                requestInformation: '請求情報',
                paymentMethod: '支払方法',
                creditCard: 'クレジットカード',
                bankTransfer: '銀行振込',
                registerPaymentMethod: '支払方法の登録',
                changePaymentMethod: '支払方法の変更',
                billingAddress: '請求先情報',
                registerBillingAddress: '請求先情報の登録',
                changeBillingAddress: '請求先情報の変更',
                requestDescription: 'お支払いの履歴や領収書のダウンロードなど、ご請求に関する情報は請求ポータルでご確認ください。',
                billingPortal: '請求ポータル',
                notificationMeOnlyWhenOffline: 'オフライン時のみ通知する',
                choosePlan: 'プランの選択',
                choosePlanHint: '選択したプランのすべての機能を1か月間無料でお試しいただくことができます。',
                tryForFree: '無料で試してみる',
                compareMorePlan: 'もっと詳しく比較する',
                plan: 'プラン',
                planType: {
                    free: 'フリー',
                    standard: 'スタンダード',
                    professional: 'プロフェッショナル',
                },
                planDescription: {
                    starter: { line1: '小規模の組織やチームの連携を強化' },
                    standard: {
                        line1: '仕事に必要なツールが全部入り',
                        line2: 'ビジネスを加速したい全てのチームにおすすめ',
                    },
                },
                contractDetails: 'ご契約内容',
                planDescriptionForUpdate: {
                    standard: 'チャット・タスク管理・Web会議・ファイル共有',
                    professional: 'スタンダートの機能+高度なプロジェクト管理',
                },
                planFeature: {
                    businessChat: 'ビジネスチャット',
                    taskManagement: 'タスク管理',
                    projectManagement: 'プロジェクト管理',
                    fileSharing: 'ファイル共有',
                    webMeeting: 'Web会議',
                    numberOfStorage: '{{numberOfStorage}}GBのストレージ',
                },
                planBillingCycle: {
                    year: '年間契約',
                    month: '月間契約',
                },
                yen: '円',
                planPricePerUser: '1ユーザーあたり',
                planPriceAnnual: '/年(税抜)',
                planPriceMonthly: '/月(税抜)',
                planPricePerMonth: '/月(月額換算)',
                changeStorage: 'ストレージ容量の変更',
                acrossTheOrganization: '組織全体で',
                canBeUse: 'まで使用可能',
                free: '無料',
                contractCycleChange: '契約サイクルの変更',
                cycleBilling: {
                    month: {
                        name: '月間',
                        description: '月額でのお支払い',
                        price: '{{price}}円(税抜)/ユーザー/月',
                    },
                    year: {
                        name: '年間',
                        description: '年額でのお支払い',
                        price: '{{price}}円(税抜)/ユーザー/年',
                        bonus: '月額換算で2か月分お得',
                    },
                },
                isTrialing: 'トライアル中 ({{dueDate, date}}まで)',
                reply: '返信',
                reaction: 'リアクション',
                chatThread: 'スレッド',
                tenantPolicy: '英小文字・数字・ハイフンを使用できます。(3文字以上20文字以下)',
                tenantPolicy2: '先頭は英小文字、末尾は英小文字か数字でなければなりません。',
                tenantPolicyNotice: 'サインアップ後にURLを変更することはできませんので、本番運用を想定したIDを設定してください。',
                loginIdPolicy: '英大文字・英小文字・数字・ハイフンを使用できます。(4文字以上)',
                passwordComplexityPolicy: `英大文字・英小文字・数字・記号({{symbol}})をそれぞれ最低1文字ずつ含む必要があります。({{minLength}}文字以上)`,
                companyNameSample: '例: 株式会社 青葉テクノロジーズ',
                companyNameNotice: '正式な会社名を入力してください。',
                displayName2: '表示名',
                displayName2Notice: '表示に使用する名称を入力してください。',
                loginIdNotice: '既に利用中のログインIDは登録できません',
                contactInformation: '連絡先情報',
                contactInformationHint: 'サービス情報のご案内やサポートからのご連絡に利用いたします。',
                fullName: '氏名',
                contactNameSample: '例: 山田 太樹',
                contactNameNotice: 'ご担当者様の氏名をフルネームでご入力ください。',
                departmentName: '部門名',
                departmentNameSample: '例: 第一事業部',
                officialPosition: '役職',
                officialPositionSample: '例: 部長',
                tel: '電話番号',
                telSample: '例: 022-123-4567',
                domainHint: '複数指定する場合はセミコロンで区切ってください',
                forceComplexPasswordHint: '英大文字・英小文字・数字・記号をそれぞれ最低1文字ずつ含む必要があります',
                restrictDomainsHint: '組織内のユーザーとして招待できるメールアドレスを制限します',
                tenantSignUpDescription: 'CrewWorksに登録します。\n利用するメールアドレスを入力してください。',
                authenticationCode: '認証コード',
                authenticationCodeHint: {
                    line1: '2要素認証の要求: セキュリティを強化するために、',
                    line2: '認証コードを入力してください。',
                },
                makeToLeave: '退出させる',
                addFavorite: 'お気に入り追加',
                removeFavorite: 'お気に入り解除',
                department: '所属部門',
                crewTable: {
                    selectColumn: '列選択',
                    numberItemOfPage: '{{page}}件表示',
                    selectAll: '全選択',
                },
                select: '選択',
                action: '操作',
                revision: 'バージョン',
                lastUpdatedAttachmentAt: '更新日時',
                ownerUserName: '所有者',
                referenceNo: 'ID',
                taskKindName: '種別',
                taskPriority: '優先度',
                taskStateDisplayOrder: 'ステータス',
                assignToUserName: '担当者',
                taskCategoryName: '分類',
                unspecified: '未指定',
                unknownCondition: '不明な条件',
                yesterday: '昨日',
                today: '今日',
                tomorrow: '明日',
                lastMonth: '先月',
                thisMonth: '今月',
                nextMonth: '来月',
                thatDay: '指定日',
                beforeAt: '指定日以前',
                afterAt: '指定日以後',
                pastDays: '過去N日',
                nextDays: '今後N日',
                between: '範囲指定',
                myself: '自分',
                joiningProjectStatus: {
                    joiningStatus: '参加状態',
                    joined: '参加済み',
                    notJoin: '未参加',
                },
                archivedProjectStatus: {
                    archivedStatus: 'アーカイブ状態',
                    archived: 'アーカイブ済み',
                    unarchive: '未アーカイブ',
                },
                numberItemsSelected: '{{number}}件選択中',
                selectAll: '全選択',
                notSet: '(未設定)',
                link: 'リンク',
                card: 'カード',
                displayOnTheBottom: '下に表示',
                displayOnTheRight: '右に表示',
                doNotDisplay: '表示しない',
                TermsOfServiceAndPrivacyPolicy: {
                    prefixMessage: '',
                    termOfService: '利用規約',
                    and: 'と',
                    privacyPolicy: 'プライバシーポリシー',
                    suffixMessage: 'に同意します',
                },
                organizationRoleLabel: '組織での役割を選択してください。',
                projectRoleLabel: 'プロジェクトでの役割を選択してください。',
                expirationDateLabel: '必要に応じてユーザーの有効期限を指定してください。',
                newUsersLabel: '招待するユーザーのメールアドレスかログインIDを入力し、リストに追加してください。',
                roleInTheOrganization: '組織での役割',
                roleInTheProject: 'プロジェクトでの役割',
                organizationRoleHelpText: '外部ユーザーは権限が制限されます。',
                projectRoleHelpText: '外部ユーザーは自動的にゲストになります。',
                expirationDateHelpText: '有効期限を過ぎると、そのユーザーはログインすることができなくなります。',
                invitationRoleType: {
                    admin: '管理者',
                    internal: '一般ユーザー',
                    external: '外部ユーザー',
                },
                tenantInviteUserRoleLabel: '役割を選択してください。',
                bulkInviteUsers: 'ユーザーを一括招待',
                bulkInviteUsersDescription: 'CSVファイルをアップロードして、ユーザーを一括で招待することができます。',
                downloadCsvTemplate: 'CSVファイルのテンプレートをダウンロード',
                dragAndDropCsvFile: 'CSVファイルをここにドラッグ&ドロップしてください',
                csvTemplateNote1: '※役割の値は、admin、internal、externalを指定してください。管理者=admin、一般ユーザー=internal、外部ユーザー=external。',
                csvTemplateNote2: '※外部ユーザーの場合は、有効期限が必須です。',
                aboutThisApp: 'このアプリについて',
                libraryUsed: '使用しているライブラリー',
                bankTransferDescription: '銀行振込は、請求書に記載された銀行口座にお振込みいただくお支払方法です。',
                projectAddExistingUserLabel: '招待するユーザーを選択し、リストに追加してください。',
                userExpirationDate: 'ユーザーの有効期限',
                returnToContractInformation: '契約情報に戻る',
                allowBannerNotification: 'バナーの許可',
                pushPermissions: {
                    default: '許可されていません', // デフォルトの場合も受信はできない
                    granted: '許可されています',
                    denied: '許可されていません',
                },
                canChangeInBrowserSetting: 'ブラウザの設定で変更することができます。',
                hintForAllowInviteableExternalUsers: '招待可能な外部ユーザー数: 残り{{remaining}}人 ({{total}}人中)',
                filterSettings: 'フィルターの設定',
                filterNameLabel: 'フィルタ名',
                publicScopeLabel: '公開範囲',
                defaultLabel: '既定',
                builtIn: 'ビルトイン',
                companyName: '会社名',
                postalCode: '郵便番号',
                stateProvince: '都道府県',
                city: '市区町村',
                line1: '住所1',
                line2: '住所2',
                stateList: {
                    hokkaido: '北海道',
                    aomori: '青森県',
                    iwate: '岩手県',
                    miyagi: '宮城県',
                    akita: '秋田県',
                    yamagata: '山形県',
                    fukushima: '福島県',
                    ibaraki: '茨城県',
                    tochigi: '栃木県',
                    gunma: '群馬県',
                    saitama: '埼玉県',
                    chiba: '千葉県',
                    tokyo: '東京都',
                    kanagawa: '神奈川県',
                    niigata: '新潟県',
                    toyama: '富山県',
                    ishikawa: '石川県',
                    fukui: '福井県',
                    yamanashi: '山梨県',
                    nagano: '長野県',
                    gifu: '岐阜県',
                    shizuoka: '静岡県',
                    aichi: '愛知県',
                    mie: '三重県',
                    shiga: '滋賀県',
                    kyoto: '京都府',
                    osaka: '大阪府',
                    hyogo: '兵庫県',
                    nara: '奈良県',
                    wakayama: '和歌山県',
                    tottori: '鳥取県',
                    shimane: '島根県',
                    okayama: '岡山県',
                    hiroshima: '広島県',
                    yamaguchi: '山口県',
                    tokushima: '徳島県',
                    kagawa: '香川県',
                    ehime: '愛媛県',
                    kochi: '高知県',
                    fukuoka: '福岡県',
                    saga: '佐賀県',
                    nagasaki: '長崎県',
                    kumamoto: '熊本県',
                    oita: '大分県',
                    miyazaki: '宮崎県',
                    kagoshima: '鹿児島県',
                    okinawa: '沖縄県',
                },
                makeDefaultFilter: '既定のフィルターとする',
                registerFilter: 'フィルターの登録',
                search: '検索',
                emojis: {
                    recentlyUsedItems: 'よく使うもの',
                    smileysAndEmotion: '顔文字と感情',
                    peopleAndBody: '人物と身体',
                    animalsAndNature: '動物と自然',
                    foodAndDrink: '飲食物',
                    travelAndPlaces: '旅行と名所',
                    activities: 'アクティビティ',
                    objects: 'オブジェクト',
                    symbols: '記号',
                    flags: '国旗',
                },
                filterEditTitle: 'フィルターの編集',
                needNotification: 'チャットに投稿する',
                addInvitableExternalUser: '招待可能ユーザーを変更',
                invitableExternalUserForFree: 'ご契約ユーザー数の2倍までは無料で外部ユーザーを招待できます。',
                invitableExternalUser: '招待可能な外部ユーザー数: {{total}}人(残り{{remaining}}人招待可能)',
                expandInviteeLimit: '追加でご購入いただくことで、外部ユーザーの招待上限数を拡大することができます。',
                numberOfPeople: '{{people}}人',
                canBeInvite: '組織全体で {{people}}人 + {{additionalPeople}}人 まで追加で招待可能',
                canBeInvite2: {
                    form1: '組織全体で {{people}}人 + ',
                    form2: '人 まで追加で招待可能',
                },
                priceAnnualText: '{{price}}円(税抜)/年',
                priceMonthlyText: '{{price}}円(税抜)/月',
                costEffectiveMonthly: '月額換算{{price}}円で{{month}}か月分お得',
                dependencies: '依存関係',
                parentTask: '親タスク',
                childTask: '子タスク',
                predecessorTask: '先行タスク',
                successorTask: '後続タスク',
                finishToStart: '完了>開始',
                finishToFinish: '完了>完了',
                startToStart: '開始>開始',
                startToFinish: '開始>完了',
                finishToStartDescription: '完了>開始 : 先行タスクが完了するまで後続タスクは開始できません',
                finishToFinishDescription: '完了>完了 : 先行タスクが完了しないと後続タスクも完了しません',
                startToStartDescription: '開始>開始 : 先行タスクが開始する前に後続タスクを開始できません',
                startToFinishDescription: '開始>完了 : 先行タスクを完了するためには、後続タスクを開始する必要があります',
                clear: 'クリア',
                statusType: 'ステータス種別',
                selectTasks: 'タスクの選択',
                forAllProject: '全プロジェクト向け',
                menu: 'メニュー',
                show: '表示する',
                scheduleTime: '予定時間',
                excludedFromProgressManagement: '進捗管理から除外',
                addEditProjectCost: 'プロジェクト原価の追加/編集',
                feature: '機能',
                projectManagement: 'プロジェクト管理',
                projectManagementNote: 'WBS・ガントチャート・EVM分析',
                costPerHour: '原価(/時)',
                effectiveStartDate: '適用開始日',
                pricePerHour: '単価(/時)',
                projectCost: 'プロジェクト原価',
                progressExcluded: '進捗除外',
                showToday: '今日を表示',
                showDetailColumn: '詳細列を表示',
                wbsNumber: 'WBS番号',
                scheduledStartDate: '予定開始日',
                scheduledEndDate: '予定終了日',
                scheduledWorkingTime: '予定作業時間',
                actualStartDate: '実績開始日',
                actualEndDate: '実績終了日',
                actualWorkingTime: '実績作業時間',
                blanks: '(空白)',
                progressRate: '進捗率',
                unread: '未読',
                evm: {
                    evmIndex: 'EVM指標',
                    esmIndex: 'ESM指標',
                    referenceDate: '基準日',
                    expectedCost: '予想コスト',
                    costVariance: 'コスト差異',
                    plannedCompletionDate: '計画完了日',
                    expectedCompletionDate: '予想完了日',
                    scheduleVariance: 'スケジュール差異',
                    plannedValue: '計画値',
                    earnedValue: '出来高',
                    actualCost: '実コスト',
                    schedulePerformanceIndex: 'スケジュール効率指標',
                    costPerformanceIndex: 'コスト効率指標',
                    budgetAtCompletion: '完了時総予算',
                    estimateAtCompletion: '完了時コスト予測',
                    varianceAtCompletion: '完了時コスト差異',
                    earnedSchedule: '出来高スケジュール',
                    actualSchedule: '実スケジュール',
                    completionDateVariance: '完了日差異',
                    costWithYen: 'コスト(¥)',
                    pv: 'PV',
                    ev: 'EV',
                    ac: 'AC',
                    sv: 'SV',
                    spi: 'SPI',
                    cv: 'CV',
                    cpi: 'CPI',
                    bac: 'BAC',
                    eac: 'EAC',
                    vac: 'VAC',
                    es: 'ES',
                    at: 'AT',
                    svt: 'SV(t)',
                    spit: 'SPI(t)',
                    pd: 'PD',
                    eact: 'EAC(t)',
                    plannedCost: '計画コスト',
                },
                taskSubject: 'タスク件名',
                workDate: '作業日',
                startTime: '開始時刻',
                endTime: '終了時刻',
                displayWorkInputForm: '作業時間を入力',
                meetingHasEnded: '会議は終了しました',
                eventManagement: 'イベント管理',
                eventManagementNote: '会議・イベント・カレンダー',
                taskManagement: 'タスク管理',
                taskManagementNote: 'タスク・カンバン',
                folder: 'フォルダ ',
                unsorted: '未整理',
                workingTime: '作業時間',
                folderName: 'フォルダ名',
                registerWorkingTime: '作業時間の登録',
                registerConsecutively: '連続して登録する',
                showOnlyMyTasks: '自分が担当のタスクのみ表示',
                showCompletedTasks: '完了したタスクも表示',
                weekly: '週間',
                monthly: '月間',
                total: '合計',
                movingFiles: 'ファイルの移動',
                selectTheDestinationFolder: '移動先のフォルダを選択してください。',
                anonymousFile: '無名ファイル',
                on: 'する',
                off: 'しない',
                meetingMinutes: '議事録',
                groupMonthly: 'グループ月間',
                taskDetail: 'タスクの詳細',
                groupWeekly: 'グループ週間',
                custom: 'カスタム',
                planed: '(予定あり)',
                zoomIn: '拡大',
                zoomOut: '縮小',
                selectFolder: 'フォルダを選択してください。',
                previewUnavailable: 'プレビューができません',
                meeting: '会議',
                registerNewMeeting: '新規会議の追加',
                editMeeting: '会議の編集',
                allowNonAttendees: '参加者以外の入室を許可する',
                approvalRequired: '入室許可が必要',
                percentSign: '%',
                editWorktime: '作業時間の編集',
                registerFolder: '新規フォルダの登録',
                editFolder: 'フォルダの編集',
                actual: '実績',
                planned: '予定',
                otherWorkTimes: 'その他{{count}}件',
                root: '(ルート)',
                topic: '議題',
                privateToProjectMembers: 'プロジェクトメンバー以外には公開しない',
                groupDaily: 'グループ日間',
                organizationSwitch: '組織切替',
                csvTemplateNote: '※役割の値は、admin、internalを指定してください。管理者=admin、一般ユーザー=internal。',
                hintForAllowInviteableUsers: '招待可能なユーザー数: 残り{{remaining}}人 ({{total}}人中)',
                bulkAddUsers: 'ユーザーを一括追加',
                notes: '注意事項',
                permit: '許可',
                reject: '拒否',
                askToJoinMeeting: '入室の許可を要求しています',
                waitingForApproval: '主催者による入室許可を待機しています',
                read: '既読',
                readStatus: '既読状況',
                afterDaysAndIncludeOverdue: 'および、期限を超過したタスクを含める',
                otherState: 'その他の状態',
                presenceStatus: '在席状態',
                presenceStatusAdd: '在席状態の登録',
                presenceStatusEdit: '在席状態の編集',
                takePhoto: '写真を撮る',
                outSide: '外側',
                inside: '内側',
                zoom: 'ズーム',
                capture: '撮影',
                photo: '写真',
                video: '動画',
                cameraFlashOn: 'ON',
                cameraFlashOff: 'OFF',
                cameraFlashAuto: 'AUTO',
                unknown: '不明',
                meetingJoinRejected: '会議の入室が拒否されました',
                presenceStatusSetting: '在席状態の設定',
                permissionRequired: '許可が必要です',
                microphonePermissionRequiredMessage: 'マイクへのアクセス許可が必要です',
                recurrence: {
                    none: '繰り返さない',
                    daily: '毎日',
                    weekdays: '毎日(平日)',
                    weekly: '毎週{{weekday}}',
                    monthlyDate: '毎月{{day}}日',
                    monthlyDayOfWeek: '毎月第{{ordinal}}{{weekday}}',
                    yearly: '毎年{{month}}月{{day}}日',
                },
                recurrenceRule: '繰り返し',
                recurrenceEndDate: '繰り返し終了日',
                target: '対象 ',
                personal: '個人',
                registerNewSchedule: '新規予定の登録',
                editSchedule: '予定の編集',
                presenceType: {
                    present: '在席中',
                    busy: '取り込み中',
                    away: '離席中',
                    absent: '不在',
                },
                changeEventScopeType: {
                    thisEvent: 'この予定',
                    futureEvents: 'これ以降のすべての予定',
                    allEvents: 'すべての予定',
                },
                changeEventScope: '予定を変更する範囲を選択してください。',
                deleteEventScope: '予定を削除する範囲を選択してください。',
                changeRecurrenceEvent: '繰り返し予定の変更',
                deleteRecurrenceEvent: '繰り返し予定の削除',
                changeEventScopeDescription: '指定範囲内の予定はすべて削除され、新しく予定が作成されます。',
                changeEventScopeDescription2: '指定範囲内の予定はすべて削除されます。',
            },
            format: {
                timestamp: '{{value, timestamp}}',
                datetime: '{{value, datetime}}',
                date: '{{value, date}}',
                shortdate: '{{value, shortdate}}',
                time: '{{value, time}}',
                shorttime: '{{value, shorttime}}',
                relativetime: '{{value, relativetime}}',
                daterange: '{{value, daterange}}',
                number: '{{value, number}}',
                currency: '{{value, currency}}',
                dateWithText: '{{value, dateWithText}}',
                dayOfWeek: '{{value, dayOfWeek}}',
                dayOfWeekShort: '{{value, dayOfWeekShort}}',
                shortDateWithText: '{{value, shortDateWithText}}',
                yearWithText: '{{value, yearWithText}}',
                dateWithDayOfWeek: '{{value, dateWithDayOfWeek}}',
                yearMonthWithText: '{{value, yearMonthWithText}}',
                weekday: '{{value, weekday}}曜',
                shortWeekday: '{{value, weekday}}',
                longWeekday: '{{value, longWeekday}}',
                numberOrdinal: '{{value, numberOrdinal}}',
                longMonth: '{{value, longMonth}}',
            },
            message: {
                apiError: {
                    validationFailed: '入力値に誤りがあります',
                    detectedUpdateByOthers: '誰かが先に更新しました。再読み込みして再入力してください',
                    fileNameDuplication: 'ファイル名 "{{fileName}}" が重複しています',
                    unknown: 'サーバで不明なエラーが発生しました。 "{{error}}"', // バックエンドが返すapiErrorUnknown型のerror要素
                    unknownResult: 'サーバーから不明な形式のエラーが返されました。 "{{error}}"', // バックエンドが返したエラーの値そのものが不正だった場合
                    projectMemberPendingDuplication: '{{destination}}は招待されています',
                    tenantUserExisted: '{{destination}}は登録されています',
                    loginIdDoesNotExist: '{{destination}}は存在しません',
                    emailDoesNotExist: 'このメールアドレスに変更できません',
                    userPendingDuplication: '{{destination}}は招待されています',
                    taskCreationIsNotAllowed: 'プロジェクトに参加していないため、タスクを作成できません。',
                    jwtInvalidError: 'ログインの有効期限が切れました。再ログインしてください',
                    unauthorizedError: '権限がありません',
                    verificationCodeInvalid: '無効な認証コードです',
                    emailAddressInvalid: '無効なメールアドレスです',
                    passwordInvalidFormat: 'パスワードの形式が正しくありません。',
                    passwordInvalidSimpleMaxLength: 'パスワードは最大{{maxLength}}文字までしか入力できません。',
                    passwordInvalidSimpleMinLength: 'パスワードは少なくとも {{minLength}} 文字でなければなりません。',
                    passwordInvalidComplex: 'パスワードは最低 {{minLength}} 文字で、英大文字・英小文字・数字・記号( {{symbols}} )をそれぞれ最低1文字ずつ含む必要があります。',
                    incorrectCurrentPassword: '現在のパスワードが正しくありません。',
                    deleteBuiltinRoleIsNotAllowed: 'この役割はユーザーにより定義されたものではないため、削除できません。',
                    invitationExpired: '招待の有効期限が切れました。',
                    startRecordMeetingIsNotAllowed: '会議の録画を開始する権限がありません。',
                    endRecordMeetingIsNotAllowed: '会議の録画を終了する権限がありません。',
                    downloadFileNotAllowed: 'ダウンロードが許可されていないため、ダウンロードできませんでした',
                    notAllowedToInviteInternalUser: '組織内ユーザーの招待は許可されていません',
                    notAllowedToInviteExternalUser: '組織外ユーザーの招待は許可されていません',
                    roleNameDuplication: '役割名 "{{roleName}}" が重複しています',
                    invalidPasswordLength: 'パスワードの長さが正しくありません。',
                    invalidComplexPasswordLength: '複雑なパスワードを強制する場合はパスワードの文字数を8文字以上に設定する必要があります',
                    invalidValueOfLengthError: '{{maxLength}}文字以下で入力してください',
                    updateEmailInvalidDomain: '組織ドメインと一致しないため、メールアドレスを更新できませんでした。',
                    deleteUsedRoleIsNotAllowed: 'この役割が使用されているため、削除は許可されていません',
                    changeMeetingHostNotAllowed: '会議の主催者を変更できません',
                    deleteMeetingAttendeeNotAllowed: '会議の参加者を削除できません。',
                    authenticationCodeIncorrect: '認証コードが正しくありません',
                    authenticationCodeHasExpired: '認証コードの有効期限が切れました',
                    couldNotVerifyAuthenticationCode: '認証コードを確認できませんでした',
                    canNotSendAuthenticationCode: '認証コードを送信できませんでした',
                    invalidPhoneNumber: '入力された電話番号の形式が正しくありません',
                    notAllowedToDeleteLastProjectAdmin: 'プロジェクトの最終管理者を削除することができません。',
                    notAllowedToChangeTheRoleOfLastProjectAdmin: 'プロジェクトの最終管理者の役割を変更することができません。',
                    assignNonPrjGuestForExternalUser: '外部ユーザーに「ゲスト」以外の役割を付与することができません。ユーザー：{{destination}}',
                    organizationDomainRequired: '組織のドメインは必須です',
                    notAllowedToAddExternalUserAsProjectOwner: '外部ユーザーをプロジェクト所有者に指定することはできません。',
                    existedDomainEmailInvalid: '招待が制限されているドメインのメールアドレスが存在します。ユーザー：{{destination}}',
                    inviteExternalUserLimitExceeded: '招待可能な外部ユーザー数を超えるため招待できません。\n招待可能な人数: {{inviteableExternalUserCount}}\n現在の人数: {{currentExternalUserCount}}\n超過人数:  {{exceededExternalUserCount}}',
                    deleteFileHistoryNotTheLatestVersion: '最新のファイルではないため削除できませんでした。',
                    commonHttpError: '想定外のエラーが発生しました。サポートに連絡してください',
                    expirationDatetimeRequired: '有効期限は必須です',
                    duplicateEffectiveStartDateError: '適用開始日が重複しています',
                    EmailAddressIsAlreadyInUsed: '登録済のメールアドレスです。',
                    deleteErrorInUse: '既に使用されているため削除できません',
                    notAllowMoveParentFolderIntoChild: '親フォルダを子フォルダに移動することができません',
                    outScopeGetMyActualTimesError: '作業時間の取得範囲は6週間(42日)以内で指定してください。',
                    taskChildrenExistError: '子タスクが紐づいているタスクでは、予定開始日、予定終了日、または予定作業時間を変更することはできません',
                    dataDateOutsideOfProjectDuration: '基準日はプロジェクト期間内の日付を指定してください（{{startDate}}～{{endDate}}）',
                    notAllowedToChangeProjectMemberRole: 'プロジェクトメンバーの役割変更の権限がありません',
                    notAllowedToRegisterTask: 'タスク管理機能が無効になっているため、登録できません',
                    notAllowedToRegisterEvent: 'イベント管理機能が無効になっているため、登録できません',
                    tenantUsersExceedLimit: 'ユーザー数が制限を超えました。残りの招待可能なユーザー数は{{remainingUser}}人のみです。',
                    exceededStorageLimit: 'ストレージ使用量が超過しているため、アップロードできません',
                    notAllowedToCreateExternalUser: '組織外ユーザーの作成は許可されていません',
                    notAllowedToCreateInternalUser: '組織内ユーザーの作成は許可されていません',
                    createTenantUsersExceedLimit: 'ユーザー数が制限を超えました。残りの作成可能なユーザー数は{{remainingUser}}人のみです。',
                    urlHasExpired: 'URLの有効期限切れのため登録できません。再度お申し込みください',
                    notAllowedToDeleteBuiltInPresenceState: '組み込みの在席状態を削除できません',
                    notAllowedToChangePresenceStateType: '組み込みの在席状態の在席状態タイプは変更できません',
                    projectMemberDuplication: 'このプロジェクトに既に参加済です。',
                    meetingIsOver: '会議は終了しました。',
                    notAllowedToChangeFixedPermission: '組み込みロールの固定権限は変更できません',
                    disabledUserChangePassword: '無効なアカウントのため、パスワードを変更できませんでした',
                    twoFactorAuthenticationMethodForFreePlanInvalid: 'フリープランではSMSによる二要素認証は利用できません',
                },
                general: {
                    error: 'エラー',
                    failedToRetrieveData: 'データの取得に失敗しました',
                    failedToCreate: '追加に失敗しました',
                    failedToUpdate: '更新に失敗しました',
                    failedToDelete: '削除に失敗しました',
                    failedToReject: '却下できませんでした',
                    failedToCancel: 'キャンセルできませんでした',
                    failedToDownload: 'ダウンロードに失敗しました',
                    ANnewHasBeenRegistered: '新しい{{name}}を登録しました',
                    searchByInput: '入力して検索',
                    required: '必須です',
                    isRequired: '{{name}} は必須です',
                    minLength: '{{name}} は {{value}} 文字以上で入力してください',
                    maxLength: '{{name}} は {{value}} 文字以下で入力してください',
                    dateFormat: '日付の形式が正しくありません',
                    minHour: '{{name}} は {{value}} 時間以上を入力してください',
                    maxHour: '{{name}} は {{value}} 時間以内を入力してください',
                    noDisplayData: '表示データなし',
                    confirmMessage: {
                        register: '登録してよろしいですか？',
                        edit: '編集してよろしいですか？',
                        delete: '削除してよろしいですか？',
                        reject: '却下してよろしいですか？',
                        cancel: 'キャンセルしてよろしいですか？',
                        leave: '退室させてもよろしいですか？',
                    },
                    errorMessage: {
                        register: '登録に失敗しました。',
                        edit: '編集に失敗しました。',
                        delete: '削除に失敗しました。',
                        sort: '並べ替えに失敗しました。',
                        reorder: '並べ替えに失敗しました。',
                        approve: '申請の承認ができませんでした。',
                        cancel: '申請の却下ができませんでした。',
                        reject: '招待のキャンセルができませんでした。',
                    },
                    loadingError: '読み込めませんでした。',
                    invalidDate: '日付の形式が正しくありません。',
                    invalidEmail: 'メールアドレスが正しくありません。',
                    invalidTel: '電話番号の形式が正しくありません。',
                    selectProject: 'プロジェクトを選択してください',
                    invalidPositiveInteger: '正の整数を入力してください',
                    duplicateEmail: 'このメールアドレスが重複しています。',
                    pastDatesInvalid: '過去の日付を指定できません。',
                    domainInvalid: 'メールアドレスのドメインが無効です。',
                    existedFilterName: '{{name}}の名前は別のフィルタによって使用されています。',
                    onlyOneParentTask: '親タスクは１つしか設定できません。',
                    oneParentTaskMustBeSelected: '親タスクは 1つのみ選択する必要があります',
                    invalidActualProgress: '進捗率は0以上100以下の整数でなければなりません。',
                    invalidFileUploading: 'ファイルをアップロード中は送信できません',
                },
                auth: {
                    failedToLogin: 'ログインできませんでした',
                    failedToVerifyEmail: 'メールアドレスを確認できませんでした。',
                    failedToSignup: 'サインアップできませんでした',
                    pageDoesNotExist: 'このページは存在しません',
                    enterYourLoginIdOrEmail: 'ログインIDまたはメールアドレスを入力してください。',
                    sendEmailToResetPassword: 'パスワードをリセットするためのメールを送信します。',
                    lockedUser: 'ログインに連続して失敗したため、一時的にロックされています。しばらくお待ちいただき、再度お試しください。',
                },
                projectMemberPending: {
                    failedToApprove: '承認に失敗しました',
                    projectMemberApproved: 'メンバーが承認されました。',
                    projectMemberDelete: 'メンバーが削除されました',
                },
                project: {
                    failedToFollow: 'フォローに失敗しました',
                    failedToUnfollow: 'フォロー解除に失敗しました',
                    failedToSubscribe: '通知の有効化に失敗しました',
                    failedToUnsubscribe: '通知の無効化に失敗しました',
                    failedToJoin: '参加に失敗しました',
                    projectRegistered: 'プロジェクトが作成されました',
                    projectUpdated: 'プロジェクトが更新されました',
                    projectDeleted: 'プロジェクトが削除されました',
                    projectMemberRegistered: 'プロジェクトにメンバーが正常に追加されました。',
                    invalidCreatedAtFrom: '登録日の範囲の前後が逆転しています',
                    invalidCreatedAtTo: '登録日の範囲の前後が逆転しています',
                    invalidUpdatedAtFrom: '更新日の範囲の前後が逆転しています',
                    invalidUpdatedAtTo: '更新日の範囲の前後が逆転しています',
                    projectMemberUpdated: 'プロジェクトメンバーが更新されました',
                    projectMemberDeleted: 'メンバーがプロジェクトから削除されました。',
                    eventCategoryDeleted: 'プロジェクトイベント分類が正常に削除されました。',
                    invalidStartDate: '開始日は終了日よりも前に設定してください。',
                    invalidEndDate: '終了日は開始日よりも後に設定してください。',
                    eventCategoryAdded: 'プロジェクトイベント分類が作成されました。',
                    eventCategoryUpdated: 'プロジェクトイベント分類が更新されました。',
                    taskCategoryDeleted: 'タスク分類が正常に削除されました。',
                    conflictToDeleteProject: 'プロジェクトイベントまたはタスクがあるため削除できません。',
                    expirationDateMustBeCorrect: '本日より先の日付を入力してください',
                    confirmLeaveProject: 'このプロジェクトから退出してもよろしいですか。',
                    leaveProjectSuccess: 'プロジェクトから退出しました。',
                    noInviteUserPermission: 'ユーザーを招待する権限がありません。',
                    approveSuccess: '申請の承認ができました。',
                    rejectSuccess: '申請を却下しました',
                    cancelSuccess: '招待のキャンセルができました。',
                    doNotHavePermissionToInviteUser: 'このユーザーを招待する権限がありません。',
                    projectMemberUnitPriceRegisteredSuccessfully: 'プロジェクト原価を登録しました',
                    projectMemberUnitPriceUpdatedSuccessfully: 'プロジェクト原価を更新しました',
                    projectMemberUnitPriceDeletedSuccessfully: 'プロジェクト原価を削除しました',
                    saveProjectSettingSuccess: 'プロジェクト設定が保存されました。',
                    confirmDeleteProjectGroup: '関連付けしているプロジェクトが存在します。グループから自動的に外れますが削除してもよろしいですか？',
                },
                meeting: {
                    meetingRegistered: 'プロジェクト会議が作成されました。',
                    meetingUpdated: 'プロジェクト会議が更新されました。',
                    meetingUpdateFailed: 'プロジェクト会議を更新できませんでした。',
                    meetingDeleted: 'プロジェクト会議が削除されました。',
                    invalidStartDate: '開始日は終了日よりも前に設定してください。',
                    invalidEndDate: '終了日は開始日よりも後に設定してください。',
                    invalidStartTime: '開始時刻は終了時刻よりも前に設定してください。',
                    invalidEndTime: '終了時刻は開始時刻よりも後に設定してください。',
                    addAttendeesSuccess: '参加者が会議に追加されました',
                    attendanceAndCommentUpdated: '参加者の出欠が登録されました',
                    removeAttendeesSuccess: '参加者が会議から削除されました',
                    meetingMinutesUpdated: '議事録が更新されました。',
                    failedToStart: 'Web会議が開始できませんでした。',
                    failedToLoadBackgroundImage: '背景画像の読み込みに失敗しました。',
                    webMeetingInProgress: 'Web会議開催中',
                    isNotMeetingAttendee: '参加者以外の入室が許可されていません',
                    meetingAttendeesLimitReached: '会議の参加人数が上限に達しているため、会議に参加できません。',
                    meetingHostChanged: '主催者が変更されました',
                    hostMutedYou: 'ホストがあなたをミュートにしました',
                    hostStoppedYourVideo: 'ホストがあなたのビデオを停止しました',
                    meetingAttendeesWaitingForApproval: '入室許可待ちの参加者がいます',
                },
                task: {
                    taskRegistered: 'タスクが作成されました。',
                    taskUpdated: 'タスクが更新されました。',
                    taskUpdateFailed: 'タスクを更新できませんでした。',
                    taskDeleted: 'タスクが削除されました。',
                    invalidFormatTime: '入力が時刻の形式と一致していることをご確認ください。',
                    taskCommentRegistered: 'タスクのコメントが作成されました。',
                    taskCommentUpdated: 'タスクのコメントが更新されました。',
                    taskCommentDeleted: 'タスクのコメントが削除されました',
                    taskHistoryDeleted: 'タスクの履歴が削除されました。',
                    invalidTaskStartDate: '開始日は期限よりも前に設定してください。',
                    invalidTaskDueDate: '期限は開始日よりも後に設定してください。',
                    wbsNumberReorderConfirm: 'WBS番号を再付番処理が実行されていないため、並び順は破棄されます。編集を終了しますか？',
                    workTimesRegistered: '作業時間が登録されました',
                    confirmUnregisteredAction: '入力した内容は破棄されます。よろしいですか？',
                    confirmDeleteLinkedSubtasks: '紐づいている子タスクも削除されます。よろしいですか？',
                    workTimeUpdated: '作業時間が更新されました',
                    workTimeDeleted: '作業時間が削除されました',
                    invalidWbsNumber: 'WBS番号の形式が正しくありません',
                },
                chat: {
                    messageSurvey: 'アンケート',
                    taskAdded: '新しいタスクを登録しました',
                    taskUpdated: 'タスクを更新しました。',
                    taskDeleted: 'タスクを削除しました。',
                    taskCommentUpdated: 'タスクを更新しました。',
                    // Backendの定義と一致させるためにkeyを `event_*` としている
                    eventAdded: '新しい会議を登録しました',
                    eventUpdated: '会議を更新しました。',
                    eventDeleted: '会議を削除しました。',
                    eventJoined: '会議の参加者が変更されました',
                    enterYourMessage: 'メッセージを入力してください',
                    failedTPostMessage: 'チャットメッセージ投稿に失敗しました',
                    failedToReaction: 'リアクションに失敗しました',
                    displayAllMessages: '全件表示',
                    displayThread: 'スレッド表示',
                    replyCount: '{{count}} 件の返信',
                    messageDeleted: 'メッセージを削除しました。',
                    messageEdited: 'メッセージを編集しました。',
                    mentionAll: '全員に通知',
                    mentionHere: 'アクティブユーザーに通知',
                    fileAdded: '新しいファイルを追加しました',
                    fileUpdated: 'ファイルを更新しました。',
                    mentioned: '{{name}} さんがあなたをメンションしました。',
                    replied: '{{name}} さんがあなたに返信しました。',
                    reacted: '{{name}} さんがあなたにリアクションしました。',
                    failedToGetChatRoom: 'チャットルームの取得に失敗しました',
                    userName: '{{name}} さん',
                    projectMemberJoined: 'メンバーが参加しました',
                    memberToJoined: '{{names}} が参加しました',
                    projectMemberRequested: 'プロジェクトの参加がリクエストされました',
                    memberToRequested: '{{names}} が参加をリクエストしました',
                    projectMemberLeft: 'メンバーがプロジェクトから退出しました',
                    memberToLeft: '{{names}} がプロジェクトから退出しました',
                    mobileReplyCount: '返信({{count}})',
                },
                taskList: {
                    invalidStartDateFrom: '開始日の範囲の前後が逆転しています',
                    invalidStartDateTo: '開始日の範囲の前後が逆転しています',
                    invalidDueDateFrom: '期日の範囲の前後が逆転しています',
                    invalidDueDateTo: '期日の範囲の前後が逆転しています',
                    invalidCreatedDateFrom: '登録日の範囲の前後が逆転しています',
                    invalidCreatedDateTo: '登録日の範囲の前後が逆転しています',
                    invalidUpdatedDateFrom: '更新日の範囲の前後が逆転しています',
                    invalidUpdatedDateTo: '更新日の範囲の前後が逆転しています',
                    invalidSortBy: '並び順1と並び順2で同じ値を選択できません。',
                },
                taskKind: {
                    taskKindRegistered: 'タスク種別が作成されました。',
                    taskKindUpdated: 'タスク種別が更新されました。',
                    taskKindDeleted: 'タスク種別が削除されました。',
                },
                meetingKind: {
                    meetingKindRegistered: 'プロジェクト会議種別が作成されました。',
                    meetingKindUpdated: 'プロジェクト会議種別が更新されました。',
                    meetingKindDeleted: 'プロジェクト会議種別が削除されました。',
                },
                eventKind: {
                    eventKindRegistered: 'イベント種別が作成されました。',
                    eventKindUpdated: 'イベント種別が更新されました。',
                    eventKindDeleted: 'イベント種別が削除されました。',
                },
                projectGroup: {
                    projectGroupRegistered: 'プロジェクトグループが作成されました。',
                    projectGroupUpdated: 'プロジェクトグループが更新されました。',
                    projectGroupDeleted: 'プロジェクトグループが削除されました。',
                },
                meetingRecording: {
                    meetingRecordingDeleted: 'アーカイブ情報が削除されました。',
                    meetingRecordingStarted: '{{userName}}が会議の録画を開始しました',
                    meetingRecordingStopped: '{{userName}}が会議の録画を終了しました',
                    storageCapacityExceededError: 'ストレージ使用量が超過しているため、録画を開始できません',
                },
                taskCategory: {
                    taskCategoryUpdated: 'タスク分類が更新されました。',
                    taskCategoryAdded: 'タスク分類が作成されました。',
                },
                taskState: {
                    taskStateRegistered: 'タスク状態が作成されました。',
                    taskStateUpdated: 'タスク状態が更新されました。',
                    taskStateDeleted: 'タスク状態が削除されました。',
                },
                bookmark: {
                    failedToRegisteredBookmark: 'ブックマークの登録に失敗しました。',
                    failedToDeleteBookmark: 'ブックマークの解除に失敗しました。',
                    failedToUpdateBookMarkArchive: 'アーカイブに失敗しました。',
                },
                filter: {
                    filterRegistered: 'フィルタが作成されました。',
                    filterUpdated: 'フィルタが更新されました。',
                    filterExistConfirm: 'フィルター名が既に存在しています。上書きしてもよろしいですか？',
                    filterCreatedByAnotherUser: '他ユーザーが登録したフィルタ名と重複しているため登録できません',
                    filterDeleted: 'フィルタが削除されました。',
                },
                taskComment: {
                    commentRegistered: 'コメントを登録しました。',
                    commentUpdated: 'コメントが更新されました。',
                    failedToRegisteredComment: 'コメントの登録に失敗しました。',
                    failedToUpdateComment: 'コメントの更新に失敗しました。',
                    invalidTimeRange: '開始時刻は終了時刻よりも前に設定してください。',
                    invalidActualWorkTimesDuration: '「開始日時」と「終了日時」の差分より大きい「実績時間」は登録できません。',
                },
                home: {
                    failedToChatMessage: 'メッセージの取得に失敗しました。',
                },
                file: {
                    invalidMinFileSize: 'ファイルが小さすぎます',
                    invalidMaxFileSize: 'ファイルが大きすぎます',
                    invalidFileExtension: '許可されていない拡張子です',
                    uploadAborted: 'アップロードはキャンセルされました',
                    copySuccess: 'クリップボードにコピーしました。',
                    uploadFailed: 'アップロードに失敗しました',
                    tagUpdated: 'タグが編集されました。',
                    fileUpdated: 'ファイルが更新されました。',
                    deleteSuccess: 'ファイルが削除されました。',
                    bulkDeleteConfirm: '選択したファイルを削除してもよろしいですか？',
                    uploadSuccess: 'ファイルがアップロードされました。',
                    fileCreated: 'ファイルが作成されました。',
                    couldNotPreview: 'プレビューできませんでした',
                    canNotDownloadFile: 'ファイルをダウンロードできません',
                    moveFilesSuccess: 'ファイルが移動されました。',
                    moveDuplicatedFiles: '移動先フォルダに同じ名前のファイルがあるため、以下のファイルを移動できませんでした。\n{{duplicateFileNames}}',
                    unsupportedFileFormat: 'サポート外のファイル形式のためアップロードできません',
                },
                fileList: {
                    invalidCreatedAtFrom: '登録日の範囲の前後が逆転しています',
                    invalidCreatedAtTo: '登録日の範囲の前後が逆転しています',
                    invalidUpdatedAtFrom: '更新日の範囲の前後が逆転しています',
                    invalidUpdatedAtTo: '更新日の範囲の前後が逆転しています',
                    invalidSortBy: '並び順1と並び順2で同じ値を選択できません。',
                },
                follow: {
                    failedToFollow: 'フォローに失敗しました',
                    failedToUnfollow: 'フォロー解除に失敗しました',
                    failedToSubscribe: '通知の有効化に失敗しました',
                    failedToUnsubscribe: '通知の無効化に失敗しました',
                    cannotFollowChildEntity: '上位層の関連先がフォローされているため、下位層の対象をフォロー解除することができません。',
                    cannotSubscribeChildEntity: '上位層の関連先が通知されているため、下位層の対象を通知解除することができません。',
                },
                favorite: {
                    failedToFavorite: 'お気に入りへの追加に失敗しました',
                    failedToUnFavorite: 'お気に入りの解除に失敗しました',
                },
                signup: {
                    verifyYourEmailAddress: 'メールアドレスを確認してください',
                    messageCheckMail: 'ご入力頂いたメールアドレスにアカウント登録URLをお送りしました。',
                    emailIsAlreadyRegistered: '登録済のメールアドレスです。',
                    messageEmailIsAlreadyRegistered: 'メールアドレスの入力は以前に行われており、処理を続行できないため',
                    register: {
                        signupSuccess: 'サインアップしました！ようこそCrewWorksへ！',
                        invalidFormatTenantAlias: '要件を満たしていません。',
                        invalidFormatPassword: '要件を満たしていません。',
                        invalidFormatConfirmPassword: 'パスワードとパスワード確認は同じパスワードを入力してください。',
                        tenantIdAlreadyExists: 'テナントIDが既に存在しています',
                        loginIdAlreadyExists: 'ログインIDが重複しています。',
                        invalidLoginId: '要件を満たしていません。',
                    },
                    invalidPlan: 'プラン情報が不正です。',
                    invalidBillingCycle: '請求サイクルが不正です。',
                    singupLinkHasExpired: 'このリンクは有効期限切れのため無効です。\n恐れ入りますが、再度お申込みください。',
                },
                tenant: {
                    inviteUserSuccess: 'ユーザーの招待が完了しました',
                    bulkAddUsersSuccess: 'ユーザーの一括追加が完了しました',
                    changeSelectedUsers: '選択したユーザーを変更します。',
                    roleUpdated: '役割が更新されました。',
                    saveTenantSettingSuccess: '組織設定が保存されました',
                    roleNameDuplicated: '役割名が重複しています。',
                    roleDeleted: '役割が削除されました。',
                    userRoleAndStatusUpdated: 'ユーザーの役割と状態が更新されました。',
                    cancelInvitationSuccess: '招待が正常にキャンセルされました',
                    canNotEditUserTogether: '外部ユーザーを一緒に編集することはできません',
                    invalidDomain: 'ドメインが正しくありません',
                    inviteUserConfirmMessage: 'ドメインの異なるユーザーが含まれています。よろしいですか？',
                    batchInviteError: 'エラーが{{count}}件あります。訂正して再度アップロードしてください。',
                    required_item: 'この項目は入力必須です。',
                    invalidFormatEmail: 'メールアドレスが正しくありません。',
                    invalidFormatRoleCode: '役割は次のいずれかを設定してください。(admin,internal,external)',
                    invalidFormatExpirationDatetime: '「YYYY/MM/DD」形式で日付を入力してください。',
                    external_user: '組織のドメインと異なるユーザーが管理者・一般ユーザーとして追加されます。',
                    existed_user: '登録済み',
                    existRegisteredUser: '既に組織に登録済みのユーザーがいます。そのユーザーへの招待は行われません。',
                    invalidFormatPassword: '要件を満たしていません。',
                },
                user: {
                    userIsInvalid: 'このユーザーは無効です',
                },
                personalSetting: {
                    saveUserSettingSuccess: '個人設定が正常に保存されました',
                    notBeenSet: '設定されていません',
                    sendVerificationCode: '新しいメールアドレスを確認するため、認証コードを送信します。',
                    enterVerificationCode: 'メールに記載された認証コードを入力してください。',
                    howToGetAuthorizationCode: 'どの方法で認証コードを受け取りますか？',
                    twoFactorSmsNote: '認証コードを受け取る電話番号を入力してください。',
                    twoFactorMailNote: '認証コードを受け取るメールアドレスを入力してください。',
                    twoFactorAppNote: 'Google Authenticator または Microsoft Authenticator アプリをスマートフォンにインストールして、このバーコードをスキャンしてください。',
                    updateEmailSuccess: 'メールアドレスが更新されました。',
                    passwordUpdated: 'パスワードが更新されました',
                    sendVerifyCodeSuccess: '認証コードを送信しました。',
                    twoFactorSettingSaved: '2要素認証の設定が保存されました。',
                    twoFactorSettingDeleted: '二要素認証の設定が削除されました',
                },
                resetPassword: {
                    sendMailSuccess: 'パスワードの再設定メールが送信されました。',
                    resetPasswordSuccess: 'パスワードの再設定が完了しました。',
                },
                contract: {
                    upgradePlanSuccess: '契約プランを更新しました',
                    changeCyclePlanSuccess: '契約サイクルを更新しました',
                    changeStoragePlanSuccess: 'ストレージ容量を変更しました',
                    registerBillingInformationSuccess: '請求先情報を更新しました',
                    registerPaymentMethodSuccess: '支払い方法を登録しました',
                    purchaseExternalUserSuccess: '外部ユーザー招待枠を変更しました',
                    updateContactSuccess: '連絡先情報を更新しました',
                    registerCreditCardGeneralError: 'クレジットカードの登録に失敗しました',
                    registerCreditCardErrorWithDedicatedMessage: {
                        card_declined: 'クレジット会社により利用を拒否されました。他のカード情報を入力してください',
                        expired_card: '期限切れのカードです。他のカード情報を入力してください',
                        incorrect_cvc: 'セキュリティコードが一致しません',
                        incorrect_number: 'カード番号が誤っています',
                    },
                    enablingCardPayments: 'クレジットカード決済を有効化しています...',
                    enablingCardPaymentsTimeout: 'クレジットカード決済の有効化がタイムアウトしました。再度お試し下さい。',
                    enablingCardPaymentsError: 'クレジットカード決済の有効化に失敗しました。',
                    upgradePlanInfo: 'プランをアップグレードします。アップグレードすると、差額分は即時ご請求されます。',
                    downgradePlanInfo: 'プランをダウングレードします。ダウングレードすると、差額分は預かり金として処理され、今後のお支払いに充当されます。返金はされませんのでご注意ください。',
                },
                notification: {
                    requestPermission: '通知を許可すると、最新の情報をリアルタイムで受け取ることができます。',
                },
                wbs: {
                    wbsNumberHasBeenAssigned: 'WBS番号が付番されました。',
                },
                folder: {
                    folderRegistered: 'フォルダが作成されました。',
                    folderUpdated: 'フォルダを更新しました。',
                    folderDeleted: 'フォルダを削除しました。',
                    folderDeleteConfirm: 'フォルダ内のすべてのファイルが削除されます。よろしいですか？',
                    moveFolderSuccess: 'フォルダが移動されました。',
                },
                startFreeTrialNotes: {
                    availableFeatures: '・プロフェッショナルプランのすべての機能を30日間無料でお試しいただけます。',
                    afterTheTrialEnds: '・無料トライアル終了後、自動的に課金されることはありません。\n　本契約いただいた場合、無料トライアル中に登録したデータは継続利用できます。',
                    needHelp: {
                        prefix: '・お困りごとがございましたら',
                        suffix: 'をご確認ください。',
                        link: 'よくある質問',
                    },
                },
                presenceStatus: {
                    presenceDeleted: '在席状態が削除されました',
                    presenceRegistered: '在席状態が作成されました',
                    presenceUpdated: '在席状態が更新されました',
                },
                event: {
                    eventUpdated: '予定が更新されました',
                    eventDeleted: '予定が削除されました',
                },
            },
            // TODO: mobile用の定義はCREW-6115で削除予定。そのため、ここに定義は追加しないこと。
            //https://break-tmc.atlassian.net/browse/CREW-6115
            mobile: {
                label: {
                    timeline: 'タイムライン',
                    menu: 'メニュー',
                    news: '新着',
                    mentionAndReply: 'メンションと返信',
                    bookmark: 'ブックマーク',
                    theme: 'テーマ',
                    language: '言語',
                    light: 'ライトモード',
                    dark: 'ダークモード',
                    ja: '日本語',
                    en: '英語',
                    selectDate: '日付の選択',
                    search: '検索',
                    keyword: 'キーワード',
                    assignedUser: '担当者',
                    eventKind: '種別',
                    eventCategory: 'イベント分類',
                    startDate: '開始日',
                    endDate: '終了日',
                    createdDate: '登録日',
                    updatedDate: '更新日',
                    moveToState: '{{stateName}}に移動',
                    myTask: 'マイタスク',
                    updated: '更新',
                    detail: '詳細',
                    selectingTags: 'タグの選択',
                    schedule: 'スケジュール',
                    deleteBookmark: 'ブックマークを解除',
                    toBookmark: 'ブックマークする',
                    toComment: 'コメントする',
                    registerWorkTime: '作業時間を登録する',
                    year: '年',
                    monthOfYear: {
                        january: '1月',
                        february: '2月',
                        march: '3月',
                        april: '4月',
                        may: '5月',
                        june: '6月',
                        july: '7月',
                        august: '8月',
                        september: '9月',
                        october: '10月',
                        november: '11月',
                        december: '12月',
                    },
                    favorite: 'お気に入り',
                    directMessage: 'ダイレクトメッセージ',
                },
                action: {
                    cancel: 'キャンセル',
                    confirm: '確認',
                    reaction: 'リアクション',
                    download: 'ダウンロード',
                    share: '共有',
                },
                header: {
                    newsFeedPage: '新着',
                    chatThreadPage: 'スレッド',
                    projectDetailPage: 'プロジェクト詳細',
                    settingsPage: '設定',
                    projectListAdvancedSearchPage: '詳細検索',
                    menuPage: 'メニュー',
                    projectDetailMeetingAdvancedSearchPage: '詳細検索',
                    taskDetailPage: 'タスク詳細',
                    taskDetailFileAdvancedSearchPage: '詳細検索',
                    taskListAdvancedSearchPage: '詳細検索',
                    projectDetailTaskAdvancedSearchPage: '詳細検索',
                    projectDetailFileAdvancedSearchPage: '詳細検索',
                    meetingDetailTaskListAdvancedSearchPage: '詳細検索',
                    projectSelectMemberEntryModal: 'メンバーの選択',
                    filePickerEntryModal: 'ファイルの選択',
                    fileDetailPage: 'ファイル詳細',
                    crewSaveFilterModal: 'フィルタの保存',
                    fileListAdvancedSearchPage: '詳細検索',
                    meetingDetailFileListAdvancedSearchPage: '詳細検索',
                    meetingDetailVideoViewerPage: '動画再生',
                    searchPage: '検索',
                    homePage: 'チャット', //Home画面としてチャット画面を表示する
                    meetingDetailPage: '会議詳細',
                },
                message: {
                    noMembers: 'メンバーなし',
                    cannotPreview: 'このファイルはプレビューを表示できません',
                    noTags: 'タグなし',
                },
            },
        },
    },
};
