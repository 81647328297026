import { useCallback } from 'react'
import {
  useBulkApproveProjectMemberPendingMutation,
  useBulkCancelInvitationMutation,
  useBulkRejectRequestMutation,
  useDeleteProjectMembersMutation,
} from '@crew/apis/project/projectApis'
import { useAppDispatch } from 'states/hooks'
import {
  ObjectEventMessage,
  notifyProjectEvent,
  notifyProjectSettingMemberEvent,
} from 'features/app/states/appSlice'
import { NotifyEventType } from 'enums/app'
import { Project, ProjectMember } from '@crew/models/domain'
import { setSelectedUserIds } from '../../states/projectDetailSlice'

export const useProjectDetailMemberList = () => {
  const dispatch = useAppDispatch()

  const [
    deleteProjectMembersMutation,
    { isLoading: isLoadingDeleteProjectMembers },
  ] = useDeleteProjectMembersMutation()
  const [bulkApproveProjectMemberPendingMutation] =
    useBulkApproveProjectMemberPendingMutation()
  const [bulkCancelInvitationMutation] = useBulkCancelInvitationMutation()
  const [bulkRejectRequestMutation] = useBulkRejectRequestMutation()

  // Delete project members process
  const deleteProjectMembers = useCallback(
    async (projectId: string, userIds: string[]) => {
      // call API remove list project member
      await deleteProjectMembersMutation({
        projectId,
        userIds,
      }).unwrap()

      // reset selected project members
      // TODO: 選択中ユーザーの保持はreduxではなくcontextで行う。リファクタリング対象。
      // https://break-tmc.atlassian.net/browse/CREW-10096
      dispatch(setSelectedUserIds([]))

      //trigger event refresh project setting members list
      const objectEventMessageProjectMember: ObjectEventMessage<ProjectMember> =
        {
          eventType: NotifyEventType.Deleted,
          id: userIds[0],
          object: undefined,
        }
      dispatch(notifyProjectSettingMemberEvent(objectEventMessageProjectMember))

      //trigger project detail refresh members list
      const objectEventMessageProject: ObjectEventMessage<Project> = {
        eventType: NotifyEventType.Updated,
        id: projectId,
        object: {
          id: projectId,
        },
      }
      dispatch(notifyProjectEvent(objectEventMessageProject))
    },
    [deleteProjectMembersMutation, dispatch]
  )

  // Approve project member pending in bulk process
  const bulkApproveProjectMemberPending = useCallback(
    async (projectId: string, userIds: string[]) => {
      // call api bulk approve request
      await bulkApproveProjectMemberPendingMutation({
        projectMemberPendingIds: userIds,
      }).unwrap()

      // reset selected project members
      dispatch(setSelectedUserIds([]))

      //trigger event refresh project setting members list
      const objectEventMessageProjectMember: ObjectEventMessage<ProjectMember> =
        {
          eventType: NotifyEventType.Updated,
          id: userIds[0],
          object: undefined,
        }
      dispatch(notifyProjectSettingMemberEvent(objectEventMessageProjectMember))

      //trigger project detail refresh members list
      const objectEventMessageProject: ObjectEventMessage<Project> = {
        eventType: NotifyEventType.Updated,
        id: projectId,
        object: {
          id: projectId,
        },
      }
      dispatch(notifyProjectEvent(objectEventMessageProject))
    },
    [bulkApproveProjectMemberPendingMutation, dispatch]
  )

  // Reject project member pending in bulk process
  const bulkRejectProjectMemberPending = useCallback(
    async (userIds: string[]) => {
      // call api to reject request
      await bulkRejectRequestMutation({
        projectMemberPendingIds: userIds,
      }).unwrap()

      // reset selected project members
      dispatch(setSelectedUserIds([]))

      //trigger event refresh project setting members list
      const objectEventMessageProjectMember: ObjectEventMessage<ProjectMember> =
        {
          eventType: NotifyEventType.Deleted,
          id: userIds[0],
          object: undefined,
        }
      dispatch(notifyProjectSettingMemberEvent(objectEventMessageProjectMember))
    },
    [bulkRejectRequestMutation, dispatch]
  )

  // Cancel invitation project member in bulk process
  const bulkCancelInvitationProjectMembers = useCallback(
    async (userIds: string[]) => {
      // call api to cancel invitation
      await bulkCancelInvitationMutation({
        projectMemberPendingIds: userIds,
      }).unwrap()

      // reset selected project members
      dispatch(setSelectedUserIds([]))

      //trigger event refresh project setting members list
      const objectEventMessageProjectMember: ObjectEventMessage<ProjectMember> =
        {
          eventType: NotifyEventType.Deleted,
          id: userIds[0],
          object: undefined,
        }
      dispatch(notifyProjectSettingMemberEvent(objectEventMessageProjectMember))
    },
    [bulkCancelInvitationMutation, dispatch]
  )

  return {
    deleteProjectMembers,
    bulkApproveProjectMemberPending,
    bulkRejectProjectMemberPending,
    bulkCancelInvitationProjectMembers,
    isLoadingDeleteProjectMembers,
  }
}
