import classNames from 'classnames'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { useCrewChatMessageActionMenu } from 'components/elements/crewChatMessageItem/components/crewChatMessageActionMenu/useCrewChatMessageActionMenu'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { CrewCheckBox } from 'components/devextreme/crewCheckBox'
import { Bookmark as BookmarkModel } from '@crew/models/domain'
import { useToast } from 'hooks/useToast'
import { useTranslation } from '@crew/modules/i18n'
import { useModal } from 'components/layouts/modal/useModal'
import { ContextMenuItems } from 'enums/app'
import { useMessageUrl } from 'hooks/useMessageUrl'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import Bookmark from '~icons/material-symbols/bookmark'
import BookmarkOutline from '~icons/material-symbols/bookmark-outline'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewDropDownButton } from 'components/elements/crewDropDownButton/crewDropDownButton'
import MoreHoriz from '~icons/material-symbols/more-horiz'

// Dropdown item render
const renderDropDownItem = (itemData: { name: string }) => (
  <span className="px-1.5 text-base whitespace-nowrap">{itemData.name}</span>
)

export type CrewChatMessageActionMenuProps = {
  visible: boolean
  chatMessageId: string
  isBookmarkedMessage: boolean
  bookmarkMessages: BookmarkModel[]
  chatMessageVersion: number
  disabledContextMenu: boolean
  onEditButtonClick?: () => void
  showArchiveCheckbox?: boolean
  disabledChatReadStatus?: boolean
  onClickChatReadStatus?: (chatMessageId: string) => void
}

export const CrewChatMessageActionMenu: FC<CrewChatMessageActionMenuProps> =
  memo((props: CrewChatMessageActionMenuProps) => {
    const {
      contextMenuData,
      deleteChatMessage,
      bookmarkArchiveSelectionChange,
      deleteBookmark,
      insertBookmark,
      isLoadingDeleteChatMessage,
    } = useCrewChatMessageActionMenu(
      props.disabledContextMenu,
      props.disabledChatReadStatus ?? true
    )

    const { t } = useTranslation()
    const { error, success } = useToast()
    const [showApiErrors] = useShowApiErrors()
    const messageUrl = useMessageUrl(props.chatMessageId)

    const [visibleActionMenu, setVisibleActionMenu] = useState<boolean>(false)
    const [showContextMenu, setShowContextMenu] = useState<boolean>(false)
    const [confirmMessage, setConfirmMessage] = useState('')

    const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
      useModal()

    // TODO: コンテキストメニューを表示した状態での、ホバーメニューの表示・非表示に問題あり。
    // https://break-tmc.atlassian.net/browse/CREW-4027
    // TODO: ブックマークの登録・削除でホバーメニューを非表示にする場合、メニュー再表示に問題が残るのでひとまず非表示にはしない。
    // https://break-tmc.atlassian.net/browse/CREW-4029

    // メニューの表示・非表示切り替え
    useEffect(() => {
      if (!showContextMenu) {
        // コンテキストメニューが非表示の時のみ、切り替えを受け付ける
        setVisibleActionMenu(props.visible)
      }
      // contextMenuの外側をクリックした際にprops.visibleを受け取りたいので全て依存に入れる
    }, [props.visible, showContextMenu])

    const isArchived = useMemo(() => {
      return (
        props.bookmarkMessages.length > 0 && props.bookmarkMessages[0].archived
      )
    }, [props.bookmarkMessages])

    const bookmarkSelectedClass =
      'text-crew-red-3-light dark:text-crew-red-3-dark'
    const iconDefaultClass =
      'hover:bg-crew-slate-1-light hover:shadow dark:hover:bg-crew-slate-4-dark'

    // ドロップダウンの「編集」押下時の
    const handleEditButtonClick = useCallback(() => {
      props.onEditButtonClick?.()
    }, [props])

    // ContextMenu内のitemクリック時のイベントハンドラ
    const handleClickItem = useCallback(
      (itemData: { id: string }) => {
        if (itemData.id === ContextMenuItems.Edit.action) {
          handleEditButtonClick()
        } else if (itemData.id === ContextMenuItems.Delete.action) {
          setConfirmMessage(t('message.general.confirmMessage.delete'))
          openConfirmDialog()
        } else if (itemData.id === ContextMenuItems.Copy.action) {
          navigator.clipboard.writeText(messageUrl)
          success(t('message.file.copySuccess'))
        } else if (itemData.id === ContextMenuItems.ReadStatus.action) {
          props.onClickChatReadStatus?.(props.chatMessageId)
        }
      },
      [handleEditButtonClick, messageUrl, openConfirmDialog, props, success, t]
    )

    // ContextMenuが表示された時のイベントハンドラ
    const handleShowingItem = useCallback(() => {
      setShowContextMenu(true)
    }, [])

    // ContextMenuが非表示になった時のイベントハンドラ
    const handleHidingItem = useCallback(() => {
      setShowContextMenu(false)
    }, [])

    const handleBookmarkClick = useCallback(async () => {
      if (props.isBookmarkedMessage) {
        // ブックマーク解除
        try {
          await deleteBookmark(props.chatMessageId)
        } catch (err) {
          error(t('message.bookmark.failedToDeleteBookmark'))
        }
      } else {
        // ブックマーク登録
        try {
          await insertBookmark(props.chatMessageId)
        } catch (err) {
          error(t('message.bookmark.failedToRegisteredBookmark'))
        }
      }
    }, [
      deleteBookmark,
      error,
      insertBookmark,
      props.chatMessageId,
      props.isBookmarkedMessage,
      t,
    ])

    // 削除確認ダイアログの許可ボタンクリック時のイベントハンドラ
    const handleDeletePermitButtonClick = useCallback(async () => {
      try {
        await deleteChatMessage(props.chatMessageId, props.chatMessageVersion)
        // チャットメッセージ削除成功時に画面上のItemを外す
        // websocket経由で削除する
        success(t('message.chat.messageDeleted'))
      } catch (err) {
        console.error(
          '[useCrewChatMessageActionMenu] chat message delete error.',
          JSON.stringify(err)
        )
        showApiErrors(err)
      }
      closeConfirmDialog()
    }, [
      closeConfirmDialog,
      deleteChatMessage,
      props.chatMessageId,
      props.chatMessageVersion,
      showApiErrors,
      success,
      t,
    ])

    // ブックマークアーカイブチェックボックスの変更時のイベントハンドラ
    const handleBookmarkArchiveSelectionChange = useCallback(async () => {
      try {
        await bookmarkArchiveSelectionChange(
          props.chatMessageId,
          isArchived,
          props.bookmarkMessages[0].version
        )
      } catch (err) {
        error(t('message.bookmark.failedToUpdateBookMarkArchive'))
      }
    }, [
      bookmarkArchiveSelectionChange,
      error,
      isArchived,
      props.bookmarkMessages,
      props.chatMessageId,
      t,
    ])

    return (
      <>
        {visibleActionMenu ? (
          // メニュー表示時
          <div
            className="crew-bg-default flex flex-row items-center border gap-x-1"
            id="dropDownMenu"
          >
            {/* show archive checkbox in bookmark list only */}
            {props.showArchiveCheckbox && (
              <>
                {/* archived bookmark message */}
                <CrewCheckBox
                  onValueChanged={handleBookmarkArchiveSelectionChange}
                  defaultValue={isArchived}
                  className="pl-1"
                />
              </>
            )}

            {/* ブックマーク */}
            {props.isBookmarkedMessage ? (
              <CrewButton
                onClick={handleBookmarkClick}
                type="normal"
                stylingMode="text"
                icon={
                  <Bookmark
                    className={classNames(
                      props.isBookmarkedMessage ? bookmarkSelectedClass : null
                    )}
                  />
                }
                size="lg"
              />
            ) : (
              <CrewButton
                onClick={handleBookmarkClick}
                type="normal"
                stylingMode="text"
                icon={
                  <BookmarkOutline
                    className={classNames(
                      props.isBookmarkedMessage ? bookmarkSelectedClass : null
                    )}
                  />
                }
                size="lg"
              />
            )}

            {/* 編集・削除 */}
            <CrewDropDownButton
              icon={<MoreHoriz width={24} height={24} />}
              items={contextMenuData}
              keyExpr="id"
              displayExpr="name"
              onItemClick={handleClickItem}
              showArrowIcon={false}
              showDivider={false}
              highlightSelected={false}
              stylingMode="text"
              buttonSize="sm"
              itemRender={renderDropDownItem}
              onShowing={handleShowingItem}
              onHiding={handleHidingItem}
            />
          </div>
        ) : (
          // メニュー非表示時
          <div className="flex gap-x-1 items-center">
            {/* show archive checkbox in bookmark list only */}
            {props.showArchiveCheckbox && (
              <>
                {/* アーカイブボタン */}
                <CrewCheckBox
                  defaultValue={isArchived}
                  onValueChanged={handleBookmarkArchiveSelectionChange}
                />
              </>
            )}

            {/* ブックマーク */}
            {props.isBookmarkedMessage ? (
              <>
                {/* ブックマークアイコン */}
                <Bookmark
                  width={20}
                  height={20}
                  className={classNames(
                    iconDefaultClass,
                    bookmarkSelectedClass
                  )}
                  onClick={handleBookmarkClick}
                />
              </>
            ) : null}
          </div>
        )}
        {/* 削除確認ダイアログ */}
        <CrewConfirmDialog
          isOpen={isConfirmDialogOpen}
          message={confirmMessage}
          onPermitButtonClick={handleDeletePermitButtonClick}
          onCancelButtonClick={closeConfirmDialog}
          permitButtonDisabled={isLoadingDeleteChatMessage}
        />
      </>
    )
  })
