import { TenantSettingMember } from '@crew/apis/tenantSetting/models/getTenantSettingMembers/response'
import { BulkInsertUsersRequest } from '@crew/apis/user/models/bulkInsertUsers/request'
import {
  useBulkInsertUsersMutation,
  useVerifyBulkAddUsersMutation,
} from '@crew/apis/user/userApis'
import { NotifyEventType } from 'enums/app'
import {
  ObjectEventMessage,
  notifyTenantSettingUserEventMessageEvent,
} from 'features/app/states/appSlice'
import { useCallback } from 'react'
import { useAppDispatch } from 'states/hooks'

export const useTenantUserBulkAddDialog = () => {
  const dispatch = useAppDispatch()

  const [verifyBulkAddUsersMutation] = useVerifyBulkAddUsersMutation()
  const [bulkInsertUsersMutation, { isLoading: isLoadingBulkAddUsers }] =
    useBulkInsertUsersMutation()

  // Verify bulk add users
  const verifyBulkAddUsers = useCallback(
    async (file: File) => {
      const result = await verifyBulkAddUsersMutation({ file: file }).unwrap()

      return result
    },
    [verifyBulkAddUsersMutation]
  )

  // Bulk add users
  const bulkAddUsers = useCallback(
    async (data: BulkInsertUsersRequest) => {
      await bulkInsertUsersMutation(data).unwrap()
      // mutation操作の後は、レスポンスを通知する
      const objectEventMessage: ObjectEventMessage<TenantSettingMember> = {
        eventType: NotifyEventType.Inserted,
        id: '',
        object: undefined,
      }
      //trigger event refresh user list
      dispatch(notifyTenantSettingUserEventMessageEvent(objectEventMessage))
    },
    [bulkInsertUsersMutation, dispatch]
  )

  return { verifyBulkAddUsers, bulkAddUsers, isLoadingBulkAddUsers }
}
