import { useTranslation } from '@crew/modules/i18n'
import { ValidateRules } from '@crew/utils/form'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useNewUserJoinProjectMutation } from '@crew/apis/project/projectApis'

export type FormValues = {
  loginId: string
  fullName: string
  password: string
  agree: boolean
}
const formInitialValues: FormValues = {
  loginId: '',
  fullName: '',
  password: '',
  agree: false,
}

export const useSignupExistingAccount = () => {
  const { t } = useTranslation()

  // react-hook-formの各種データを取得
  const { control, handleSubmit, formState, watch } = useForm<FormValues>({
    defaultValues: formInitialValues,
    criteriaMode: 'all',
  })

  // Get function for user join project
  const [
    newUserJoinProjectMutation,
    { isLoading: isLoadingNewUserJoinProject },
  ] = useNewUserJoinProjectMutation()

  // User join project process
  const joinProject = useCallback(
    async (data: FormValues, invitationToken: string) => {
      const payload = {
        ...data,
        displayName: data.fullName,
        invitationToken,
        passwordConfirm: undefined,
      }

      await newUserJoinProjectMutation({
        joinProject: payload,
      }).unwrap()
    },
    [newUserJoinProjectMutation]
  )

  // 以下、入力コンポーネントのルールとレンダリングを定義
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      loginId: {
        required: t('message.general.isRequired', {
          name: t('label.userIdOrEmailAddress'),
        }),
        maxLength: {
          value: 500,
          message: t('message.general.maxLength', {
            name: t('label.userIdOrEmailAddress'),
            value: 500,
          }),
        },
      },
      fullName: {
        required: t('message.general.isRequired', {
          name: t('label.displayName'),
        }),
      },
      password: {
        required: t('message.general.isRequired', {
          name: t('label.password'),
        }),
        maxLength: {
          value: 500,
          message: t('message.general.maxLength', {
            name: t('label.password'),
            value: 500,
          }),
        },
      },
      agree: {},
    }),
    [t]
  )

  return {
    control,
    handleSubmit,
    formState,
    validateRules,
    watch,
    joinProject,
    isLoadingNewUserJoinProject,
  }
}
