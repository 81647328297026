import { CrewTaskPriorityIcon } from 'components/elements/crewTaskPriorityIcon/crewTaskPriorityIcon'
import { memo, useMemo } from 'react'
import classNames from 'classnames'
import { TaskPriorities } from 'enums/app'

type RenderPriorityProps = {
  taskPriority: number
  className?: string
}

// TODO: コンポーネント化されているが、useTaskPriorityとの兼ね合いや定義先について議論が必要なため、以下タスクで方針決めを行う
// https://break-tmc.atlassian.net/browse/CREW-5329
export const CrewTaskPriority = memo((props: RenderPriorityProps) => {
  // find priorities by task priority value
  const taskPriority = useMemo(() => {
    return Object.values(TaskPriorities).find(
      (item) => item.value === props.taskPriority
    )
  }, [props.taskPriority])

  return taskPriority ? (
    <div className={classNames('flex items-center gap-x-2', props.className)}>
      <CrewTaskPriorityIcon taskPriority={taskPriority} />
    </div>
  ) : null
})
