import { useTranslation } from '@crew/modules/i18n'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  FormValues,
  Task,
  useTaskDetailDependencyParentTask,
} from './useTaskDetailDependencyParentTask'
import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { useProjectPermissions } from '@crew/hooks'
import { EntityType } from '@crew/enums/domain'
import AddCircleOutline from '~icons/material-symbols/add-circle-outline'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { useModal } from 'components/layouts/modal/useModal'
import { TaskDetailChildParentItem } from '../taskDetailChildParentItem/taskDetailChildParentItem'

type TaskDetailDependencyParentTaskProps = {
  task: Task | null | undefined
}

export const TaskDetailDependencyParentTask: FC<TaskDetailDependencyParentTaskProps> =
  memo((props) => {
    const {
      control,
      formState,
      validateRules,
      reset,
      setError,
      handleSubmit,
      updateParentTask,
      parentTaskDataSource,
      isLoadingUpdateTask,
    } = useTaskDetailDependencyParentTask(props.task?.id)

    const { t } = useTranslation()
    const { taskId } = useParams()
    const [showApiErrors] = useShowApiErrorsWithForm(setError)
    const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
      useModal()
    const [showAddParentTask, setShowAddParentTask] = useState(false)

    const canSend = useMemo(
      // fromState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
      // check permission create task
      () =>
        Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
      // formStateはproxyなのでformState自体をlistenする必要がある
      // https://react-hook-form.com/api/useform/formstate
      [formState]
    )

    // Click the show add parent task form button
    const handleShowAddParentTaskFormButtonClick = useCallback(() => {
      setShowAddParentTask(true)
    }, [])

    // キャンセルボタンクリック
    const handleCancelButtonClick = useCallback(() => {
      // reset form
      reset()

      setShowAddParentTask(false)
    }, [reset])

    // Click the add parent task button
    const handleAddParentTaskButtonClick = useCallback(async () => {
      const onSubmit = async (data: FormValues) => {
        try {
          if (!props.task) return

          await updateParentTask(props.task, data.parentTaskId)

          // reset form
          reset()
          setShowAddParentTask(false)
        } catch (err) {
          showApiErrors(err)
        }
      }
      handleSubmit(onSubmit)()
    }, [handleSubmit, props.task, reset, showApiErrors, updateParentTask])

    // Click the delete button
    const handleDeleteButtonClick = useCallback(() => {
      openConfirmDialog()
    }, [openConfirmDialog])

    // 削除確認ダイアログ OKボタン
    const handleDeletePermitButtonClick = useCallback(async () => {
      if (props.task) {
        try {
          await updateParentTask(props.task, undefined)
        } catch (err) {
          showApiErrors(err)
        }
      }

      closeConfirmDialog()
    }, [closeConfirmDialog, props.task, showApiErrors, updateParentTask])

    const { hasPrjTaskEditPermission } = useProjectPermissions(
      EntityType.Task,
      taskId
    )

    return (
      <>
        <p className="flex items-center">{t('label.parentTask')}</p>

        <div className="flex items-center">
          {hasPrjTaskEditPermission && (
            <CrewButton
              stylingMode="text"
              onClick={handleShowAddParentTaskFormButtonClick}
              icon={<AddCircleOutline width={20} height={20} />}
              size="sm"
            />
          )}
        </div>

        <div className="col-span-2 flex flex-col gap-2.5">
          {showAddParentTask && (
            <form>
              <div className="flex flex-row items-center gap-2.5">
                {/* Search */}
                <div className="flex-1">
                  <CrewSelectBoxField
                    control={control}
                    id="parentTaskId"
                    name="parentTaskId"
                    dataSource={parentTaskDataSource}
                    valueExpr="id"
                    displayExpr="subject"
                    searchEnabled={true}
                    mode="search"
                    showDropDownButton={false}
                    placeholder=""
                    rules={validateRules.parentTaskId}
                  />
                </div>

                {/* Add button */}
                <CrewButton
                  text={t('label.add')}
                  type="primary"
                  disabled={!canSend || isLoadingUpdateTask}
                  onClick={handleAddParentTaskButtonClick}
                />
                {/* Cancel button */}
                <CrewButton
                  text={t('action.cancel')}
                  type="normal"
                  stylingMode="outlined"
                  onClick={handleCancelButtonClick}
                />
              </div>
            </form>
          )}

          {/* parent task */}
          {props.task?.parentTask && (
            <TaskDetailChildParentItem
              task={props.task.parentTask}
              onDelete={handleDeleteButtonClick}
            />
          )}

          {/* 削除確認ダイアログ */}
          <CrewConfirmDialog
            isOpen={isConfirmDialogOpen}
            message={t('message.general.confirmMessage.delete')}
            onPermitButtonClick={handleDeletePermitButtonClick}
            onCancelButtonClick={closeConfirmDialog}
            permitButtonDisabled={isLoadingUpdateTask}
          />
        </div>
      </>
    )
  })
