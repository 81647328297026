import { EntityType } from '@crew/enums/domain'
import { useProjectPermissions } from '@crew/hooks'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { TaskEntryDialog } from 'features/task/components/taskEntryDialog/taskEntryDialog'
import { memo, useCallback } from 'react'
import { EventDetailTaskSearch } from '../eventDetailTaskSearch/eventDetailTaskSearch'
import { useModal } from 'components/layouts/modal/useModal'
import { useParams } from 'react-router-dom'
import { useTranslation } from '@crew/modules/i18n'
import { getDefaultTabValue } from '@crew/utils/enum'
import { TaskDetailListTabs } from 'enums/app'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { GetEventRequest } from '@crew/apis/project/models/getEvent/request'
import { useGetEventQuery } from '@crew/apis/project/projectApis'
import { skipToken } from '@reduxjs/toolkit/query'

export const EventDetailTaskListToolbar = memo(() => {
  const { t } = useTranslation()
  const { eventId } = useParams()
  const { navigate } = useCrewNavigate()

  // イベント詳細の取得
  // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
  const useGetEventParam: GetEventRequest | undefined = eventId
    ? {
        eventId,
      }
    : undefined

  const { data: getEventQueryResult } = useGetEventQuery(
    useGetEventParam ?? skipToken
  )

  /** ダイアログ */
  // タスク登録ダイアログ
  const [
    isProjectDetailTaskEntryDialogOpen,
    openProjectDetailTaskEntryDialog,
    closeProjectDetailTaskEntryDialog,
  ] = useModal()

  const { hasPrjTaskCreatePermission } = useProjectPermissions(
    EntityType.Project,
    getEventQueryResult?.event?.entityRecordId ?? ''
  )

  // タスク登録完了
  const handleTaskRegistered = useCallback(
    (taskId: string) => {
      // タスク詳細画面に遷移
      navigate(`/tasks/${taskId}/${getDefaultTabValue(TaskDetailListTabs)}`)

      //close project detail task entry dialog
      closeProjectDetailTaskEntryDialog()
    },
    [navigate, closeProjectDetailTaskEntryDialog]
  )

  return (
    <div className="p-2.5">
      {hasPrjTaskCreatePermission && (
        <div className="text-right pb-2.5">
          {/* 新規タスクボタン */}
          <CrewButton
            type="primary"
            className="ml-auto"
            onClick={openProjectDetailTaskEntryDialog}
            text={t('action.newTask')}
          />
        </div>
      )}

      {/* 検索条件 */}
      <EventDetailTaskSearch />

      {/* タスク登録ダイアログ */}
      <TaskEntryDialog
        isEditMode={false}
        title={t('label.addTaskTitle')}
        onSubmit={handleTaskRegistered}
        isOpen={isProjectDetailTaskEntryDialogOpen}
        onClose={closeProjectDetailTaskEntryDialog}
        projectId={getEventQueryResult?.event?.entityRecordId as string}
      />
    </div>
  )
})
