import { FC, memo, useCallback } from 'react'
import {
  ParamValue,
  SearchFilter,
  dateFilterValueToString,
  stringToDateFilterValue,
} from 'utils/filter'
import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { EventSearchOptions } from 'enums/app'
import { assertNever } from '@crew/utils'
import { ValueChangedEvent as TextBoxValueChangedEvent } from 'devextreme/ui/text_box'
import { ValueChangedEvent as SelectBoxValueChangedEvent } from 'devextreme/ui/select_box'
import { ValueChangedEvent as TagBoxValueChangedEvent } from 'devextreme/ui/tag_box'
import _ from 'lodash'
import {
  CrewDateFilterBox,
  CrewDateFilterBoxValueChangedEvent,
} from 'components/devextreme/crewDateFilterBox'
import { CrewSelectBox } from 'components/devextreme/crewSelectBox'
import { CrewTagBox } from 'components/devextreme/crewTagBox'
import { useEventKindDataSource } from 'hooks/dataSource/useEventKindDataSource'
import { EntityType } from '@crew/enums/domain'
import { useUserDataSource } from 'hooks/dataSource/useUserDataSource'
import { EventKindRef } from '@crew/models/refs'

type GroupComponentType<T> = {
  data: {
    items: T[]
    key: string
  }
}

// Render event kind group name
const renderEventKindGroupName = ({
  data,
}: GroupComponentType<EventKindRef>) => {
  // プロジェクトに紐づかないデータは仕様上ありえないが、型制約の関係上チェック処理を追加
  const item = data.items.find((item) => item.entityRecordId)

  if (!item) {
    return null
  }

  return <span>{item.projectName}</span>
}

export type EventSearchInputProps = {
  filter: SearchFilter
  updateSearchValue: (filter: SearchFilter, value: ParamValue) => void
}

export const CrewEventSearchInput: FC<EventSearchInputProps> = memo(
  ({ filter, updateSearchValue }) => {
    // Attendee data source
    const attendeeDataSource = useUserDataSource(true)

    // custom data source for created by user
    const createdByUserDataSource = useUserDataSource(true)
    // custom data source for updated by user
    const updatedByUserDataSource = useUserDataSource(true)

    // event kind data source
    const eventKind = useEventKindDataSource(
      EntityType.Project,
      undefined, // projectId
      true, // multiSelect
      true // isGrouped
    )

    // event cat3gory data source
    // TODO: Web: イベント分類の非表示対応
    // https://break-tmc.atlassian.net/browse/CREW-15049
    // const projectEventCategoryDataSource = useEventCategoryDataSource(projectId)

    // キーワードの変更
    const handleKeywordChanged = useCallback(
      (e: TextBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        if (e.value === '') {
          updateSearchValue(filter, undefined)
          return
        }
        updateSearchValue(filter, e.value)
      },
      [filter, updateSearchValue]
    )

    // 参加者の変更
    const handleAttendeeIdChanged = useCallback(
      (e: SelectBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        updateSearchValue(filter, e.value)
      },
      [filter, updateSearchValue]
    )

    // change start date
    const handleStartDateChanged = useCallback(
      (e: CrewDateFilterBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        if (e.value) {
          updateSearchValue(filter, dateFilterValueToString(e.value))
        }
      },
      [filter, updateSearchValue]
    )

    // change end date
    const handleEndDateChanged = useCallback(
      (e: CrewDateFilterBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        if (e.value) {
          updateSearchValue(filter, dateFilterValueToString(e.value))
        }
      },
      [filter, updateSearchValue]
    )

    // 作成者の変更
    const handleCreatedByChanged = useCallback(
      (e: SelectBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        updateSearchValue(filter, e.value)
      },
      [filter, updateSearchValue]
    )

    // 更新者の変更
    const handleUpdatedByChanged = useCallback(
      (e: SelectBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        updateSearchValue(filter, e.value)
      },
      [filter, updateSearchValue]
    )

    // 作成日の変更
    const handleCreatedAtChanged = useCallback(
      (e: CrewDateFilterBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        if (e.value) {
          updateSearchValue(filter, dateFilterValueToString(e.value))
        }
      },
      [filter, updateSearchValue]
    )

    // 更新日の変更
    const handleUpdatedAtChanged = useCallback(
      (e: CrewDateFilterBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        if (e.value) {
          updateSearchValue(filter, dateFilterValueToString(e.value))
        }
      },
      [filter, updateSearchValue]
    )

    // change event kind
    const handleTypeChanged = useCallback(
      (e: TagBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        updateSearchValue(filter, e.value)
      },
      [filter, updateSearchValue]
    )

    // カテゴリの変更
    // TODO: Web: イベント分類の非表示対応
    // https://break-tmc.atlassian.net/browse/CREW-15049
    // const handleCategoryChanged = useCallback(
    //   (e: TagBoxValueChangedEvent) => {
    //     if (_.isEqual(e.value, e.previousValue)) return
    //     updateSearchValue(filter, e.value)
    //   },
    //   [filter, updateSearchValue]
    // )

    switch (filter.field) {
      case EventSearchOptions.Keyword.id:
        return (
          <CrewTextBox
            id={EventSearchOptions.Keyword.id}
            name={EventSearchOptions.Keyword.name}
            value={filter.value as string}
            showClearButton={true}
            onValueChanged={handleKeywordChanged}
            mode="search"
          />
        )

      case EventSearchOptions.AttendeeId.id:
        return (
          <CrewSelectBox
            id={EventSearchOptions.AttendeeId.id}
            name={EventSearchOptions.AttendeeId.name}
            displayExpr="displayName"
            valueExpr="id"
            dataSource={attendeeDataSource}
            minSearchLength={0}
            showClearButton={true}
            value={filter.value}
            onValueChanged={handleAttendeeIdChanged}
            searchEnabled={true}
            searchExpr="displayName"
          />
        )

      case EventSearchOptions.EventKindId.id:
        return (
          <CrewTagBox
            id={EventSearchOptions.EventKindId.id}
            name={EventSearchOptions.EventKindId.name}
            displayExpr="name"
            valueExpr="id"
            dataSource={eventKind}
            searchEnabled={true}
            popupSearchEnabled={true}
            searchMode="contains"
            searchExpr="name"
            minSearchLength={0}
            maxDisplayedTags={2}
            value={filter.value as string[]}
            onValueChanged={handleTypeChanged}
            grouped
            groupComponent={renderEventKindGroupName}
          />
        )

      // TODO: Web: イベント分類の非表示対応
      // https://break-tmc.atlassian.net/browse/CREW-15049
      // case EventSearchOptions.EventCategoryId.id:
      //   return (
      //     <CrewTagBox
      //       id={EventSearchOptions.EventCategoryId.id}
      //       name={EventSearchOptions.EventCategoryId.name}
      //       displayExpr="name"
      //       valueExpr="id"
      //       dataSource={projectEventCategoryDataSource}
      //       searchEnabled={true}
      //       popupSearchEnabled={true}
      //       searchMode="contains"
      //       searchExpr="name"
      //       minSearchLength={0}
      //       maxDisplayedTags={2}
      //       value={filter.value as string[]}
      //       onValueChanged={handleCategoryChanged}
      //     />
      //   )
      case EventSearchOptions.StartDate.id:
        return (
          <CrewDateFilterBox
            value={stringToDateFilterValue(filter.value as string)}
            onValueChange={handleStartDateChanged}
          />
        )
      case EventSearchOptions.EndDate.id:
        return (
          <CrewDateFilterBox
            value={stringToDateFilterValue(filter.value as string)}
            onValueChange={handleEndDateChanged}
          />
        )
      case EventSearchOptions.CreatedById.id:
        return (
          <CrewSelectBox
            id={EventSearchOptions.CreatedById.id}
            name={EventSearchOptions.CreatedById.name}
            displayExpr="displayName"
            valueExpr="id"
            dataSource={createdByUserDataSource}
            minSearchLength={0}
            showClearButton={true}
            value={filter.value}
            onValueChanged={handleCreatedByChanged}
            searchEnabled={true}
            searchExpr="displayName"
          />
        )
      case EventSearchOptions.UpdatedById.id:
        return (
          <CrewSelectBox
            id={EventSearchOptions.UpdatedById.id}
            name={EventSearchOptions.UpdatedById.name}
            displayExpr="displayName"
            valueExpr="id"
            dataSource={updatedByUserDataSource}
            minSearchLength={0}
            showClearButton={true}
            value={filter.value}
            onValueChanged={handleUpdatedByChanged}
            searchEnabled={true}
            searchExpr="displayName"
          />
        )
      case EventSearchOptions.CreatedAt.id:
        return (
          <CrewDateFilterBox
            value={stringToDateFilterValue(filter.value as string)}
            onValueChange={handleCreatedAtChanged}
          />
        )
      case EventSearchOptions.UpdatedAt.id:
        return (
          <CrewDateFilterBox
            value={stringToDateFilterValue(filter.value as string)}
            onValueChange={handleUpdatedAtChanged}
          />
        )
      default:
        // assertNever has value type never, so we need to cast filter.field to never
        assertNever(filter.field as never, 'Invalid filter field')
    }
  }
)
