import {
  ComponentProps,
  FC,
  PropsWithChildren,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { CrewAvatar } from '../crewAvatar/crewAvatar'
import { CrewLink } from '../crewLink/crewLink'
import { EntityType } from '@crew/enums/domain'
import { getDefaultTabValue } from '@crew/utils/enum'
import { DirectMessageTabs } from 'enums/app'
import { generateImageAvatarUrl } from '@crew/utils/avatar'
import classNames from 'classnames'
import { CrewPresenceStateIconSize } from '../crewPresenceStateIcon/crewPresenceStateIcon'
import {
  CrewUserPresence,
  UserPresenceState,
} from '../crewUserPresence/crewUserPresence'
import { useTranslation } from '@crew/modules/i18n'

type Props = PropsWithChildren<ComponentProps<typeof CrewAvatar>> & {
  userId: string
  // ダイレクトチャンネルのID
  directChannelId?: string
  displayName: string
  showLabel?: boolean
  labelColor?: string
  // disable click event to avatar
  disableClick?: boolean
  showPresenceState?: boolean
  sizePresence?: CrewPresenceStateIconSize
}

export const CrewUserAvatar: FC<Props> = memo((props) => {
  const { t } = useTranslation()

  // 対象ユーザーのアバター画像URL文字列を生成
  const imageUrl = useMemo(() => {
    if (props.directChannelId) {
      // ダイレクトチャンネルのIDが指定されている場合は、そのIDから相手ユーザーのアバターを取得する
      return generateImageAvatarUrl(
        EntityType.DirectChannel,
        props.directChannelId
      )
    } else {
      // そうでない場合はユーザーIDからアバターを取得する
      return generateImageAvatarUrl(EntityType.User, props.userId)
    }
  }, [props.directChannelId, props.userId])

  const [userPresenceState, setUserPresenceState] =
    useState<UserPresenceState>()

  // handle presence state change
  const handleUserPresenceStateChange = useCallback(
    (userPresence: UserPresenceState) => {
      setUserPresenceState(userPresence)
    },
    []
  )

  // text presence state
  const textPresenceState = useMemo(() => {
    if (!userPresenceState) return t('label.unknown')

    if (!userPresenceState.presenceStateMessage) return userPresenceState.name

    return `${userPresenceState.name} / ${userPresenceState.presenceStateMessage}`
  }, [t, userPresenceState])

  return (
    <>
      {props.disableClick ? (
        <div className="flex flex-row gap-x-2 items-center shrink-0">
          <CrewAvatar
            displayName={props.displayName}
            size={props.size}
            imageURL={imageUrl}
            className={props.className}
            cacheValue={props.cacheValue}
          />
          {props.showLabel && (
            <span
              className={classNames(
                props.labelColor ?? 'crew-text-default',
                'truncate'
              )}
            >
              {props.displayName}
            </span>
          )}
        </div>
      ) : (
        <CrewLink
          // Go user detail by click avatar
          to={`/${EntityType.User}/${props.userId}/${getDefaultTabValue(
            DirectMessageTabs
          )}`}
          className="flex flex-row gap-x-2 items-center shrink-0"
        >
          <div className="flex relative">
            <CrewAvatar
              displayName={props.displayName}
              size={props.size}
              imageURL={imageUrl}
              className={props.className}
              cacheValue={props.cacheValue}
              showPresenceState={props.showPresenceState}
              textPresenceState={textPresenceState}
            />
            {props.showPresenceState && (
              <div className="absolute bottom-0 right-0">
                <CrewUserPresence
                  userId={props.userId}
                  onChange={handleUserPresenceStateChange}
                />
              </div>
            )}
          </div>
          {props.showLabel && (
            <span
              className={classNames(
                props.labelColor ?? 'crew-text-default',
                'truncate'
              )}
            >
              {props.displayName}
            </span>
          )}
        </CrewLink>
      )}
    </>
  )
})
