import { useChatMessage } from '@crew/states'

import { useAppDispatch, useAppSelector } from 'states/hooks'
import { useMemo } from 'react'
import { RightSideBarDisplayType } from 'enums/app'
import { useCanAccessChatRoomQuery } from '@crew/apis/chat/chatApis'
import { skipToken } from '@reduxjs/toolkit/query'

export const useChatThreadPanel = (displayType: string) => {
  /** 各画面毎のトピックID */
  // チャット系（プロジェクト詳細、タスク詳細など）
  const chatTopicId = useAppSelector(
    (state) => state.message.chat.current.chatThread?.topicId
  )

  // 選択されたメッセージのtopicId
  const currentTopicId = useMemo(() => {
    switch (displayType) {
      // Feed, Attention, Bookmarkの場合にこのコンポーネントを表示することはないので、undefinedを返す
      case RightSideBarDisplayType.Feed:
      case RightSideBarDisplayType.Attention:
      case RightSideBarDisplayType.Bookmark:
        return undefined
      case RightSideBarDisplayType.ProjectDetail:
      case RightSideBarDisplayType.EventDetail:
      case RightSideBarDisplayType.FileDetail:
      case RightSideBarDisplayType.TaskDetail:
      case RightSideBarDisplayType.DirectChannel:
      case RightSideBarDisplayType.CrossSearchChat:
        return chatTopicId
      default:
        return undefined
    }
  }, [chatTopicId, displayType])

  // トピックのメッセージ
  const dispatch = useAppDispatch()
  const { message: topicMessage } = useChatMessage(
    currentTopicId ?? '',
    dispatch,
    useAppSelector
  )

  // チャットルームにアクセスする権限があるかを取得する
  const canAccessChatRoomParams = topicMessage?.chatRoomId
    ? {
        chatRoomId: topicMessage?.chatRoomId,
      }
    : undefined
  const { data: canAccessChatRoomResult } = useCanAccessChatRoomQuery(
    canAccessChatRoomParams ?? skipToken
  )

  // チャットルームへのアクセス権限があるか（チャットを表示するかどうかに使用する）
  const canAccessChatRoom = useMemo(() => {
    const canAccess = canAccessChatRoomResult?.canAccess
    if (canAccess === undefined) {
      return false
    } else {
      return canAccess
    }
  }, [canAccessChatRoomResult?.canAccess])

  return {
    topicMessage,
    canAccessChatRoom,
  }
}
