import classNames from 'classnames'
import { CrewContextMenu } from 'components/devextreme/crewContextMenu'
import { memo } from 'react'
import { useTaskDetailCommentItemActionMenu } from './useTaskDetailCommentItemActionMenu'
import { ContextMenuItems, isContextMenuItem } from 'enums/app'
import { useCallback } from 'react'
import { ComponentCallbackHandler } from '@crew/utils'
import MoreHoriz from '~icons/material-symbols/more-horiz'

export type TaskDetailCommentItemActionMenuProps = {
  onContextMenuOpen: (isOpen: boolean) => void
  onEditComment: () => void
  onDeleteComment: () => void
  isHideContextMenuItemDelete?: boolean
  id: string // element id for context menu
}

export const TaskDetailCommentItemActionMenu = memo(
  (props: TaskDetailCommentItemActionMenuProps) => {
    const { contextMenuDataSource } = useTaskDetailCommentItemActionMenu(
      props.isHideContextMenuItemDelete
    )

    //call when context menu open
    const handleShownItem = useCallback(() => {
      //ホバーメニューを表示
      props.onContextMenuOpen(true)
    }, [props])

    //call when context menu close
    const handleHiddenItem = useCallback(() => {
      //ホバーメニューを非表示
      props.onContextMenuOpen(false)
    }, [props])

    //call when click item of context menu
    const handleContextMenuItemClick = useCallback<
      ComponentCallbackHandler<typeof CrewContextMenu, 'onItemClick'>
    >(
      (event) => {
        const item = event.itemData
        if (!isContextMenuItem(item)) {
          return
        }
        switch (item.action) {
          //edit action click
          case ContextMenuItems.Edit.action:
            props.onEditComment()
            break
          //delete action click
          case ContextMenuItems.Delete.action:
            props.onDeleteComment()
            break
        }
      },
      [props]
    )

    const iconDefaultClass =
      'hover:bg-crew-slate-1-light hover:shadow dark:hover:bg-crew-slate-4-dark cursor-pointer'

    return (
      <>
        {/* 編集・削除 */}
        <MoreHoriz
          className={classNames(iconDefaultClass)}
          id={props.id}
          width={24}
          height={24}
        />
        <CrewContextMenu
          target={`#${props.id}`}
          dataSource={contextMenuDataSource}
          position={{
            offset: { y: 5 },
            at: 'right bottom',
            my: 'right top',
            collision: 'flip',
          }}
          onShown={handleShownItem}
          onHidden={handleHiddenItem}
          displayExpr="label"
          width="130px"
          // ここをdxclickにすると左クリックで表示されるようになる
          showEvent="dxclick"
          onItemClick={handleContextMenuItemClick}
        />
      </>
    )
  }
)
