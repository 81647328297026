import { FC, memo, useCallback, useEffect, useMemo } from 'react'
import { useMemberTable } from './useMemberTable'
import { ProjectSettingMember } from '@crew/apis/project/models/getProjectSettingMembers/response'
import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  Row,
  RowSelectionState,
  TableOptions,
  Updater,
  getCoreRowModel,
} from '@tanstack/react-table'
import {
  CrewTable,
  SelectCheckbox,
} from 'components/elements/crewTable/crewTable'
import { ProjectMemberState } from 'enums/app'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'
import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { camelCase } from 'lodash'
import {
  MemberStateType,
  Region,
  SettingKeyType,
  ContractPlan,
} from '@crew/enums/app'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import BaselineDelete from '~icons/ic/baseline-delete'
import BaselineCreate from '~icons/ic/baseline-create'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { ProjectDetailMemberEntryDialog } from '../../../projectDetailMemberEntryDialog/projectDetailMemberEntryDialog'
import { setSelectedUserIds } from 'features/project/components/projectDetailPage/states/projectDetailSlice'
import { useTranslation } from '@crew/modules/i18n'
import { useModal } from 'components/layouts/modal/useModal'
import { useToast } from 'hooks/useToast'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { EntityType, ProjectSettingMemberActionType } from '@crew/enums/domain'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useProjectAdvancedSettings, useProjectPermissions } from '@crew/hooks'
import { useUserSetting } from '@crew/states'
import { useNavigate, useSearchParams } from 'react-router-dom'
import qs from 'qs'
import _ from 'lodash'
import { GetProjectSettingMembersRequest } from '@crew/apis/project/models/getProjectSettingMembers/request'
import { skipToken } from '@reduxjs/toolkit/query'
import { useGetProjectSettingMembersQuery } from '@crew/apis/project/projectApis'
import { DEFAULT_PAGING_PARAMS } from 'configs/constants'
import { getParamAsString } from 'utils'

export type MemberTableProps = {
  projectId: string
  rowSelection: RowSelectionState
  setRowSelection: (rowSelection: Updater<RowSelectionState>) => void
}
export const MemberTable: FC<MemberTableProps> = memo((props) => {
  const {
    approveProjectMemberPending,
    deleteProjectMemberPending,
    deleteProjectMembers,
    isLoadingDeleteProjectMemberPending,
    isLoadingDeleteProjectMembers,
  } = useMemberTable()

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const params = qs.parse(searchParams.toString())

  const defaultListDisplayNumber = useUserSetting(
    SettingKeyType.ListDisplayNumber,
    DEFAULT_PAGING_PARAMS.pageSize
  )

  const pagination: PaginationState = useMemo(
    () => ({
      pageIndex: Number(
        getParamAsString('pageIndex', params) ?? DEFAULT_PAGING_PARAMS.pageIndex
      ),
      pageSize: Number(
        getParamAsString('pageSize', params) ?? defaultListDisplayNumber
      ),
    }),
    [defaultListDisplayNumber, params]
  )

  const projectSettingMemberEventMessage = useAppSelector(
    (state) => state.app.projectSettingMemberEventMessage
  )

  const projectMemberState = searchParams.get('state')
  const requestParams: GetProjectSettingMembersRequest | undefined =
    props.projectId
      ? {
          projectId: props.projectId,
          keyword: searchParams.get('keyword') || '',
          projectMemberState:
            projectMemberState &&
            projectMemberState !== ProjectMemberState.allStates.value
              ? projectMemberState
              : undefined,
          limit: pagination.pageSize,
          offset: pagination.pageIndex * pagination.pageSize,
        }
      : undefined

  // Call API get list project setting member
  const {
    data: getProjectSettingMembersResult,
    refetch: getProjectSettingMembersRefetch,
  } = useGetProjectSettingMembersQuery(requestParams ?? skipToken)

  const projectSettingMembers = useMemo(
    () => getProjectSettingMembersResult?.projectSettingMembers ?? [],
    [getProjectSettingMembersResult?.projectSettingMembers]
  )

  const totalCount = useMemo(
    () => getProjectSettingMembersResult?.totalCount ?? 0,
    [getProjectSettingMembersResult?.totalCount]
  )

  // refresh project setting member list and clear list selected member
  useEffect(() => {
    getProjectSettingMembersRefetch()
    dispatch(setSelectedUserIds([]))
  }, [
    dispatch,
    getProjectSettingMembersRefetch,
    projectSettingMemberEventMessage,
  ])

  const {
    hasPrjMemberDeletePermission,
    hasPrjMemberAddPermission,
    hasPrjMemberEditPermission,
  } = useProjectPermissions(EntityType.Project, props.projectId)

  const currentPlan = useAppSelector((state) => state.app.currentPlan)

  const { doProjectManagement } = useProjectAdvancedSettings(props.projectId)

  const [columnVisibility, setColumnVisibility] = useState({})
  const [columnPinning] = useState({
    left: ['select'],
    right: ['action'],
  })
  const [currentMember, setCurrentMember] = useState<ProjectSettingMember>()
  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')
  const [action, setAction] = useState<string>(
    ProjectSettingMemberActionType.Delete
  )

  const { success, error } = useToast()

  const [showApiErrors] = useShowApiErrors()
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  const projectSubject = useAppSelector(
    (state) => state.projectDetail.projectSubject
  )

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  // Handle open confirm dialog to delete member
  const handleOpenConfirmDeleteMemberButtonClick = useCallback(
    (member: ProjectSettingMember) => {
      setCurrentMember(member)
      setAction(ProjectSettingMemberActionType.Delete)
      setConfirmMessage(t('message.general.confirmMessage.delete'))
      openConfirmDialog()
    },
    [openConfirmDialog, t]
  )

  // Handle open confirm dialog to delete member
  const handleOpenConfirmRejectMemberButtonClick = useCallback(
    (member: ProjectSettingMember) => {
      setCurrentMember(member)
      setAction(ProjectSettingMemberActionType.Reject)
      setConfirmMessage(t('message.general.confirmMessage.delete'))
      openConfirmDialog()
    },
    [openConfirmDialog, t]
  )

  // Handle open confirm dialog to delete member
  const handleOpenConfirmCancelMemberButtonClick = useCallback(
    (member: ProjectSettingMember) => {
      setCurrentMember(member)
      setConfirmMessage(t('message.general.confirmMessage.cancel'))
      setAction(ProjectSettingMemberActionType.Cancel)
      openConfirmDialog()
    },
    [openConfirmDialog, t]
  )

  // Press submit confirmation button to delete member
  const handleSubmitDeleteSingleMemberButtonClick = useCallback(
    async (member: ProjectSettingMember) => {
      try {
        // check member id exists
        if (!member || !props.projectId) {
          closeConfirmDialog()
          return
        }

        // Execute delete project member process
        await deleteProjectMembers(props.projectId, member.userId)

        // reset row selection
        const selection = Object.keys(props.rowSelection).reduce((obj, key) => {
          if (member.userId !== key) {
            obj[key] = props.rowSelection[key]
          }
          return obj
        }, {} as RowSelectionState)
        props.setRowSelection(selection)

        success(t('message.project.projectMemberDeleted'))
      } catch (err) {
        showApiErrors(err)
      }
      closeConfirmDialog()
    },
    [closeConfirmDialog, props, deleteProjectMembers, success, t, showApiErrors]
  )

  // Press submit confirmation button to reject member
  const handleSubmitRejectMemberButtonClick = useCallback(
    async (member: ProjectSettingMember) => {
      try {
        // Execute delete project member pending process
        await deleteProjectMemberPending(member.projectMemberPendingId)

        // reset row selection
        const selection = Object.keys(props.rowSelection).reduce((obj, key) => {
          if (member.projectMemberPendingId !== key) {
            obj[key] = props.rowSelection[key]
          }
          return obj
        }, {} as RowSelectionState)
        props.setRowSelection(selection)

        success(t('message.projectMemberPending.projectMemberDelete'))
      } catch (err) {
        error(t('message.general.failedToReject'))
      }
      closeConfirmDialog()
    },
    [closeConfirmDialog, deleteProjectMemberPending, props, success, t, error]
  )

  // Press submit confirmation button to cancel member
  const handleSubmitCancelMemberButtonClick = useCallback(
    async (member: ProjectSettingMember) => {
      try {
        // Execute delete project member pending process
        await deleteProjectMemberPending(member.projectMemberPendingId)

        // reset row selection
        const selection = Object.keys(props.rowSelection).reduce((obj, key) => {
          if (member.projectMemberPendingId !== key) {
            obj[key] = props.rowSelection[key]
          }
          return obj
        }, {} as RowSelectionState)
        props.setRowSelection(selection)

        success(t('message.projectMemberPending.projectMemberDelete'))
      } catch (err) {
        error(t('message.general.failedToCancel'))
      }
      closeConfirmDialog()
    },
    [closeConfirmDialog, deleteProjectMemberPending, props, success, t, error]
  )

  // handle click button approve member
  const handleApproveMemberButtonClick = useCallback(
    async (member: ProjectSettingMember) => {
      if (!member) {
        return
      }

      // Execute approve project member pending process
      await approveProjectMemberPending(
        props.projectId,
        member.projectMemberPendingId
      )

      // reset row selection
      const selection = Object.keys(props.rowSelection).reduce((obj, key) => {
        if (member.projectMemberPendingId !== key) {
          obj[key] = props.rowSelection[key]
        }
        return obj
      }, {} as RowSelectionState)
      props.setRowSelection(selection)

      success(t('message.projectMemberPending.projectMemberApproved'))
      try {
      } catch (err) {
        error(t('message.projectMemberPending.failedToApprove'))
      }
    },
    [approveProjectMemberPending, props, success, t, error]
  )

  // navigate to the appropriate function when click button permit
  const handleConfirmDialogPermitButtonClick = useCallback(() => {
    if (currentMember) {
      switch (action) {
        case ProjectSettingMemberActionType.Delete:
          return handleSubmitDeleteSingleMemberButtonClick(currentMember)
        case ProjectSettingMemberActionType.Reject:
          return handleSubmitRejectMemberButtonClick(currentMember)
        case ProjectSettingMemberActionType.Cancel:
          return handleSubmitCancelMemberButtonClick(currentMember)
      }
    }
  }, [
    action,
    handleSubmitDeleteSingleMemberButtonClick,
    handleSubmitCancelMemberButtonClick,
    handleSubmitRejectMemberButtonClick,
    currentMember,
  ])

  const [
    isProjectSettingMemberEntryDialogOpen,
    openProjectSettingMemberEntryDialog,
    closeProjectSettingMemberEntryDialog,
  ] = useModal()

  const handleOpenProjectSettingMemberEntryDialog = useCallback(
    (member: ProjectSettingMember) => {
      setCurrentMember(member)
      openProjectSettingMemberEntryDialog()
    },
    [openProjectSettingMemberEntryDialog]
  )

  // 招待中ユーザーはdisplayNameのみ表示し、それ以外はアバターも含め表示する
  const renderUserColumnByState = useCallback(
    (member: ProjectSettingMember) => {
      switch (member.state) {
        case ProjectMemberState.Inviting.value:
          return member.displayName
        default:
          return (
            <CrewUserAvatar
              userId={member.userId}
              displayName={member.displayName}
              size={CrewAvatarSize.xs}
              showLabel={true}
              cacheValue={member.user.id + member.user.version}
            />
          )
      }
    },
    []
  )
  const renderState = useCallback(
    (member: ProjectSettingMember) => {
      switch (member.state) {
        case MemberStateType.Joined.value:
          return t(`${MemberStateType.Joined.text}`)
        case MemberStateType.Inviting.value:
          return t(`${MemberStateType.Inviting.text}`)
        case MemberStateType.WaitingApproval.value:
          return t(`${MemberStateType.WaitingApproval.text}`)
        default:
          return
      }
    },
    [t]
  )

  // https://github.com/TanStack/table/discussions/3899
  // https://github.com/TanStack/table/discussions/3619
  // https://github.com/infonomic/remix.infonomic.io/blob/d3a7f628d3ad6e1e80cc80d4ac72db74da90e8d6/app/routes/admin%2B/users.tsx#L116
  // Func handle change pagination
  const handlePaginationListChange: OnChangeFn<PaginationState> = useCallback(
    (updaterOrValue) => {
      let values: PaginationState
      if (updaterOrValue instanceof Function) {
        values = updaterOrValue(pagination)
      } else {
        values = updaterOrValue
      }

      const newParams = {
        ...params,
        pageIndex: values.pageIndex,
        pageSize: values.pageSize,
      }

      // paramsが変わっていない場合はnavigateしない
      if (_.isEqual(params, newParams)) return

      const newQueryString = qs.stringify(newParams, {
        arrayFormat: 'repeat',
        skipNulls: true,
      })

      navigate(`?${newQueryString}`)
    },
    [navigate, pagination, params]
  )

  const pageCount = Math.ceil((totalCount ?? 0) / pagination.pageSize)

  const columns = useMemo<ColumnDef<ProjectSettingMember>[]>(
    () => {
      const tableColumns: ColumnDef<ProjectSettingMember>[] = [
        {
          id: 'select',
          accessorKey: 'select',
          header: ({ table }) => (
            <SelectCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          ),
          cell: ({ row }) => (
            <div className="flex flex-1 justify-center">
              <SelectCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          ),
          size: 80,
          minSize: 50,
          enableSorting: false,
          meta: {
            align: 'center',
          },
        },
        {
          id: 'user',
          accessorKey: 'user',
          header: () => t('label.user'),
          cell: ({ row }) => {
            return (
              <div className="truncate">
                {renderUserColumnByState(row.original)}
              </div>
            )
          },
          size: 200,
          minSize: 50,
        },
        {
          id: 'role',
          accessorKey: 'role',
          header: () => t('label.role'),
          cell: ({ row }) => {
            return (
              <div className="truncate">
                {row.original.role.roleCode
                  ? t(`label.roles.${camelCase(row.original.role.roleCode)}`)
                  : row.original.role.name}
              </div>
            )
          },
          size: 160,
          minSize: 50,
        },
        {
          id: 'memberState',
          accessorKey: 'memberState',
          header: () => t('label.memberState'),
          cell: ({ row }) => {
            return (
              <div className="flex gap-x-2.5 items-center">
                {renderState(row.original)}
                {row.original.state === MemberStateType.Inviting.value &&
                hasPrjMemberAddPermission ? (
                  <CrewButton
                    type="normal"
                    stylingMode="outlined"
                    className="h-8"
                    onClick={() =>
                      handleOpenConfirmCancelMemberButtonClick(row.original)
                    }
                    render={() => (
                      <div className={'w-16 text-sm py-1'}>
                        {t('action.cancel')}
                      </div>
                    )}
                  />
                ) : (
                  row.original.state ===
                    MemberStateType.WaitingApproval.value &&
                  hasPrjMemberAddPermission && (
                    <div className="flex gap-x-1">
                      <CrewButton
                        type="normal"
                        stylingMode="outlined"
                        className="h-8"
                        onClick={() => {
                          handleApproveMemberButtonClick(row.original)
                        }}
                        render={() => (
                          <div className={'w-16 text-sm py-1'}>
                            {t('action.approve')}
                          </div>
                        )}
                      />
                      <CrewButton
                        type="normal"
                        stylingMode="outlined"
                        className="h-8"
                        onClick={() =>
                          handleOpenConfirmRejectMemberButtonClick(row.original)
                        }
                        render={() => (
                          <div className={'w-16 text-sm py-1'}>
                            {t('action.reject')}
                          </div>
                        )}
                      />
                    </div>
                  )
                )}
              </div>
            )
          },
          size: 280,
          minSize: 280,
        },
      ]

      // プロジェクト管理権限がある場合、コストを表示する
      if (doProjectManagement && currentPlan === ContractPlan.Professional) {
        tableColumns.push({
          id: 'projectCost',
          accessorKey: 'projectCost',
          header: () => t('label.costPerHour'),
          cell: ({ row }: { row: Row<ProjectSettingMember> }) => {
            return (
              row.original.projectCost && (
                <div className="truncate">
                  {t('format.currency', {
                    value: row.original.projectCost,
                  })}
                  {t('label.yen')}
                </div>
              )
            )
          },
          size: 120,
          minSize: 50,
        })
      }

      tableColumns.push(
        {
          id: 'registeredDate',
          accessorKey: 'registeredDate',
          header: () => t('label.registeredDate'),
          cell: ({ row }) => {
            return (
              <div className="truncate">
                {t('format.datetime', {
                  value: row.original.createdAt,
                  timeZone: defaultUserProfileRegion,
                })}
              </div>
            )
          },
          size: 160,
          minSize: 50,
        },
        {
          id: 'action',
          accessorKey: 'action',
          header: '',
          cell: ({ row }) => (
            <div>
              {row.original.state === MemberStateType.Joined.value && (
                <div className="flex gap-2 pr-2 justify-end items-center">
                  {hasPrjMemberEditPermission && (
                    // 「プロジェクトメンバーの編集」権限を持っている場合　：表示する
                    <span
                      className="cursor-pointer"
                      onClick={() =>
                        handleOpenProjectSettingMemberEntryDialog(row.original)
                      }
                    >
                      <BaselineCreate width={24} height={24} />
                    </span>
                  )}

                  {hasPrjMemberDeletePermission && (
                    // 「プロジェクトメンバーの削除」権限を持っている場合　：表示する
                    <span
                      className="cursor-pointer"
                      onClick={() =>
                        handleOpenConfirmDeleteMemberButtonClick(row.original)
                      }
                    >
                      <BaselineDelete width={24} height={24} />
                    </span>
                  )}
                </div>
              )}
            </div>
          ),
          size: 80,
          minSize: 50,
          enableSorting: false,
        }
      )

      return tableColumns
    },
    // we need add only props that used in columns
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      hasPrjMemberDeletePermission,
      hasPrjMemberEditPermission,
      hasPrjMemberAddPermission,
      defaultUserProfileRegion,
      doProjectManagement,
      currentPlan,
    ]
  )
  const tableOptions: TableOptions<ProjectSettingMember> = {
    data: projectSettingMembers,
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      columnVisibility,
      columnPinning,
      rowSelection: props.rowSelection,
    },
    getRowId: (row) => row.projectMemberPendingId ?? row.userId,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: props.setRowSelection,
    onPaginationChange: handlePaginationListChange,
    manualPagination: true,
    enableRowSelection: true,
    maxMultiSortColCount: 2,
    pageCount,
    meta: {
      headerRowHeight: 40,
      dataRowHeight: 50,
    },
  }

  // set selected rows original data to parent component
  useEffect(() => {
    const selectedMembers = projectSettingMembers.filter((member) => {
      return props.rowSelection[member.projectMemberPendingId ?? member.userId]
    })

    // reset selected project members
    // TODO: 選択中ユーザーの保持はreduxではなくcontextで行う。リファクタリング対象。
    // https://break-tmc.atlassian.net/browse/CREW-10096
    dispatch(
      setSelectedUserIds(
        selectedMembers.map((selectedMember) => {
          // 招待中ユーザーの場合はprojectMemberPendingIdを、それ以外はuserIdを返す
          if (selectedMember.projectMemberPendingId) {
            return selectedMember.projectMemberPendingId
          }

          return selectedMember.userId
        })
      )
    )
    // don't need to add props to deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowSelection])

  return (
    <>
      {/* member list table */}
      <CrewTable tableOptions={tableOptions} showColumnSelector={false} />
      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleConfirmDialogPermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
        permitButtonDisabled={
          isLoadingDeleteProjectMemberPending || isLoadingDeleteProjectMembers
        }
      />
      {currentMember && (
        <ProjectDetailMemberEntryDialog
          isEditMode={true}
          title={t('label.editMember') + ' - ' + projectSubject}
          isOpen={isProjectSettingMemberEntryDialogOpen}
          onClose={closeProjectSettingMemberEntryDialog}
          userId={currentMember.userId}
        />
      )}
    </>
  )
})
