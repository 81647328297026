import { FC, memo, useCallback, useState, useEffect } from 'react'
import {
  BackgroundBlurOptions,
  BackgroundFilterSpec,
  BackgroundReplacementOptions,
  Device,
  isVideoTransformDevice,
  VideoInputDevice,
  BackgroundReplacementVideoFrameProcessor,
  DefaultVideoTransformDevice,
} from 'amazon-chime-sdk-js'

import {
  useLocalVideo,
  useMeetingManager,
  useVideoInputs,
} from 'modules/amazon-chime-sdk-component-library-devextreme'

import { BaseSdkProps } from '../../modules/amazon-chime-sdk-component-library-devextreme/components/sdk/Base'
import {
  BackgroundBlurProvider,
  useBackgroundBlur,
} from '../../modules/amazon-chime-sdk-component-library-devextreme/providers/BackgroundBlurProvider'
import { useLogger } from '../../modules/amazon-chime-sdk-component-library-devextreme/providers/LoggerProvider'
import { createUninitializedContext } from '@crew/utils/context'
import {
  BackgroundReplacementProvider,
  useBackgroundReplacement,
} from '../../modules/amazon-chime-sdk-component-library-devextreme/providers/BackgroundReplacementProvider'
import { base64ToBlob, convertBlobToBase64 } from '@crew/utils/file'
import { LocalStorageKeys } from 'enums/system'
import { useLazyDownloadMeetingBackgroundImageQuery } from '@crew/apis/meeting/meetingApis'

type InnerProps = BaseSdkProps & { children?: React.ReactNode }

export const BackgroundEffect = {
  blur: 'blur',
  replacement: 'image', // 定義値はi18nの表示用に使用するため、"image"を指定
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BackgroundEffect =
  (typeof BackgroundEffect)[keyof typeof BackgroundEffect]

interface BackgroundEffectType {
  isBackgroundEffectSupported: boolean
  enableBackgroundEffect: (enable: boolean) => Promise<void>
  isBackgroundEffectEnabled: boolean
  isBackgroundEffectChanging: boolean
  selectBackgroundEffect: (effect: BackgroundEffect) => Promise<void>
  selectBackgroundImage: (base64String: string) => Promise<void>
  backgroundEffect: BackgroundEffect
  isNowBackgroundEffectEnabled: boolean // 現在のエフェクト有効状態
  loadBackgroundImage: () => Promise<void> // Get the latest background image
}

const {
  context: backgroundEffectContext,
  useInitializedContext: useBackgroundEffect,
} = createUninitializedContext<BackgroundEffectType>()

/**
 * 実装メモ: バックグラウンドエフェクトは、VideoTransformDeviceというデバイスの一種として実装されている。
 * このデバイスを、ChimeSDKの入力とWebカメラなどの入力デバイスの間に挟み込むことでエフェクトを実現する。
 *
 * - エフェクト有効状態
 *   - [InputDevice]-[VideoTransformDevice]-[ChimeSDK]
 * - エフェクト無効状態
 *   - [InputDevice]-[ChimeSDK]
 *
 * なお構成上はVideoTransformDeviceを多段にすることも出来そうだが、現在の実装では想定していない。
 */

/**
 * 背景エフェクトを提供するプロバイダ
 * @param param0
 * @returns
 */
const BackgroundEffectProviderImpl: FC<InnerProps> = ({ children }) => {
  const logger = useLogger()
  const meetingManager = useMeetingManager()
  const { selectedDevice } = useVideoInputs()
  const { isVideoEnabled } = useLocalVideo()

  const [lazyDownloadMeetingBackgroundImageQuery] =
    useLazyDownloadMeetingBackgroundImageQuery()

  const { isBackgroundBlurSupported, createBackgroundBlurDevice } =
    useBackgroundBlur()

  const {
    isBackgroundReplacementSupported,
    createBackgroundReplacementDevice,
  } = useBackgroundReplacement()

  const [selectedEffect, setSelectedEffect] = useState<BackgroundEffect>(
    BackgroundEffect.blur
  )

  const isBackgroundEffectSupported =
    (isBackgroundBlurSupported && isBackgroundReplacementSupported) ?? false

  // FIXME: Web会議開始または参加時の設定中に参加者一覧にユーザーが表示され、カメラがONになった直後にユーザーの背景が見えてしまうため暫定対応で初期値を背景ON（ぼかし）とする
  //        開始または参加時のカメラ表示の挙動に関してはCREW-8167で検討
  //        https://break-tmc.atlassian.net/browse/CREW-8167
  const [isBackgroundEffectEnabled, setBackgroundEffectEnabled] = useState(true)

  const [isBackgroundEffectChanging, setBackgroundEffectChanging] =
    useState(false)

  // 背景エフェクト用デバイスを生成する
  const createBackgroundEffect = useCallback(
    (effect: BackgroundEffect, device: Device) => {
      switch (effect) {
        case BackgroundEffect.blur:
          return createBackgroundBlurDevice(device)
        case BackgroundEffect.replacement:
          return createBackgroundReplacementDevice(device)
        default:
          const errorMessage = `Unknown background effect was selected : ${selectedEffect}`
          logger.error(errorMessage)
          throw new Error(errorMessage)
      }
    },
    [
      createBackgroundBlurDevice,
      createBackgroundReplacementDevice,
      logger,
      selectedEffect,
    ]
  )

  // 背景エフェクトを有効化
  const enableBackgroundEffect = useCallback(
    async (enable: boolean) => {
      if (isBackgroundEffectChanging || !selectedDevice) {
        return
      }

      try {
        setBackgroundEffectChanging(true)
        let newInputDevice: VideoInputDevice

        // 現在選択中の入力デバイスがVideoTransformの場合、エフェクトは有効
        // そうではない=エフェクトは無効
        const isNowBackgroundEffectEnabled =
          isVideoTransformDevice(selectedDevice)

        if (enable === isNowBackgroundEffectEnabled) {
          // 既に要求された有効状態、何もしない
        }

        // type guardを使うため、enableではなくisNowBackgroundEffectEnabledで判定する
        if (!isNowBackgroundEffectEnabled) {
          // 今はエフェクト無効、有効化する

          // カメラデバイスとChimeSDKの入力の間にエフェクトデバイスを生成して挟む
          newInputDevice = await createBackgroundEffect(
            selectedEffect,
            selectedDevice
          )
          logger.info(
            `Video filter turned on - selecting video transform device: ${JSON.stringify(
              newInputDevice
            )}`
          )
        } else {
          // 今はエフェクト有効、無効化する

          // 現在のエフェクトデバイスから元のデバイスを取り出し、ChimeSDKの入力に直結する
          newInputDevice = await selectedDevice.intrinsicDevice()
          logger.info(
            `Video filter was turned off - selecting inner device: ${JSON.stringify(
              newInputDevice
            )}`
          )
        }

        // ローカルビデオが既に有効なら新しいデバイスで再スタートする。そうでないならselectだけでスタートしない
        if (isVideoEnabled) {
          await meetingManager.startVideoInputDevice(newInputDevice)
        } else {
          meetingManager.selectVideoInputDevice(newInputDevice)
        }

        // 処理完了、ステータス更新
        setBackgroundEffectEnabled(!isNowBackgroundEffectEnabled)
      } catch (error) {
        logger.error(`Failed to toggle Background Effect:${error}`)
      } finally {
        setBackgroundEffectChanging(false)
      }
    },
    [
      createBackgroundEffect,
      isBackgroundEffectChanging,
      isVideoEnabled,
      logger,
      meetingManager,
      selectedDevice,
      selectedEffect,
    ]
  )

  // 背景画像を選択
  const selectBackgroundImage = useCallback(
    async (base64String: string) => {
      const imageBlob = base64ToBlob(base64String)

      if (isBackgroundEffectChanging || !selectedDevice || !imageBlob) {
        return
      }

      // NOTE: 画像差し替えの実装についてはBackgroundBlurVideoFrameProcessorを用いた公式ドキュメント参考
      // https://aws.github.io/amazon-chime-sdk-js/modules/backgroundfilter_video_processor.html

      try {
        setBackgroundEffectChanging(true)

        setSelectedEffect(BackgroundEffect.replacement) // エフェクトはimageに固定

        // 現在選択中の入力デバイスがVideoTransformの場合、背景画像エフェクトは有効
        // そうではない=背景画像エフェクトは無効
        const isNowBackgroundEffectEnabled =
          isVideoTransformDevice(selectedDevice)

        if (isNowBackgroundEffectEnabled) {
          const device = await selectedDevice.intrinsicDevice()

          // BackgroundReplacementVideoFrameProcessorがサポートされているかチェック
          if (await BackgroundReplacementVideoFrameProcessor.isSupported()) {
            const options: BackgroundReplacementOptions = { imageBlob }

            let transformDevice: DefaultVideoTransformDevice | undefined

            // 選択した画像を背景にする
            const replacementProcessor =
              await BackgroundReplacementVideoFrameProcessor.create(
                undefined,
                options
              )

            if (replacementProcessor) {
              transformDevice = new DefaultVideoTransformDevice(
                logger,
                device,
                [replacementProcessor]
              )
            }

            if (transformDevice) {
              await meetingManager.startVideoInputDevice(transformDevice)

              // 選択した画像をローカルストレージに保存
              // TODO: 背景画像は後ほどS3に保存となるので、その対応時に取得先をS3に変更する（対応タスクは後ほど起票）
              // https://break-tmc.atlassian.net/browse/CREW-6686?focusedCommentId=13364
              localStorage.setItem(
                LocalStorageKeys.MeetingBackgroundReplacementBase64,
                base64String
              )
            } else {
              // エフェクトデバイス生成失敗
              logger.error(
                'Failed to create BackgroundReplacementVideoFrameProcessor'
              )
            }
          }
        } else {
          // エフェクト未使用、何もしない(選択したエフェクトはsetSelectedEffectされているので、その値をtoggleBackgroundEffectする時に利用する)
        }
      } catch (error) {
        logger.error(`Failed to change Background Effect:${error}`)
      } finally {
        setBackgroundEffectChanging(false)
      }
    },
    [isBackgroundEffectChanging, logger, meetingManager, selectedDevice]
  )

  // Load background image from s3
  const loadBackgroundImage = useCallback(async () => {
    // Download background image from s3
    const backgroundImageBlob =
      await lazyDownloadMeetingBackgroundImageQuery().unwrap()
    // Convert blob image file to base64
    const base64String = await convertBlobToBase64(backgroundImageBlob)
    // 背景エフェクトを選択した画像に差し替える（ドロップダウンの項目が「ぼかし」の場合は「画像」に切り替える）
    selectBackgroundImage(base64String as string)
  }, [lazyDownloadMeetingBackgroundImageQuery, selectBackgroundImage])

  const selectBackgroundEffect = useCallback(
    async (newEffect: BackgroundEffect) => {
      // 背景画像を置き換え中、またはデバイスがない場合、背景効果の置き換え処理をしない
      if (isBackgroundEffectChanging || !selectedDevice) {
        return
      }

      try {
        setBackgroundEffectChanging(true)

        setSelectedEffect(newEffect)

        // 現在選択中の入力デバイスがVideoTransformの場合、エフェクトは有効
        // そうではない=エフェクトは無効
        const isNowBackgroundEffectEnabled =
          isVideoTransformDevice(selectedDevice)

        if (isNowBackgroundEffectEnabled) {
          // エフェクト使用中、エフェクトデバイスを差し替える
          const innerDevice = await selectedDevice.intrinsicDevice()
          const newDevice = await createBackgroundEffect(newEffect, innerDevice)
          logger.info(
            `Video filter turned on - selecting video transform device: ${JSON.stringify(
              newDevice
            )}`
          )

          // 「画像」を選択すると、背景画像をダウンロードし、Web会議の背景画像に設定する
          if (newEffect === BackgroundEffect.replacement) {
            await loadBackgroundImage()
          }

          // ローカルビデオが既に有効なら新しいデバイスで再スタートする。そうでないならselectだけでスタートしない
          if (isVideoEnabled) {
            await meetingManager.startVideoInputDevice(newDevice)
          } else {
            meetingManager.selectVideoInputDevice(newDevice)
          }
        } else {
          // エフェクト未使用、何もしない(選択したエフェクトはsetSelectedEffectされているので、その値をtoggleBackgroundEffectする時に利用する)
        }
      } catch (error) {
        logger.error(`Failed to change Background Effect:${error}`)
      } finally {
        await setBackgroundEffectChanging(false)
      }
    },
    [
      createBackgroundEffect,
      isBackgroundEffectChanging,
      isVideoEnabled,
      loadBackgroundImage,
      logger,
      meetingManager,
      selectedDevice,
    ]
  )

  const value: BackgroundEffectType = {
    isBackgroundEffectSupported,
    enableBackgroundEffect,
    isBackgroundEffectEnabled,
    isBackgroundEffectChanging,
    selectBackgroundEffect,
    selectBackgroundImage,
    backgroundEffect: selectedEffect,
    isNowBackgroundEffectEnabled: isVideoTransformDevice(selectedDevice),
    loadBackgroundImage,
  }

  return (
    <backgroundEffectContext.Provider value={value}>
      {children}
    </backgroundEffectContext.Provider>
  )
}

export type BackgroundEffectProviderOptions = {
  blur?: {
    /**
     * 利用するビデオフィルタのスペック。公式ドキュメント参照
     * [Amazon Chime SDK for JavaScript Background Filter Guide](https://github.com/aws/amazon-chime-sdk-js/blob/main/guides/15_Background_Filter_Video_Processor.md#adding-a-background-filter-to-your-application)
     */
    spec?: BackgroundFilterSpec
    /**
     * Blurビデオフィルタのオプション。公式ドキュメント参照
     * [Amazon Chime SDK for JavaScript Background Filter Guide](https://github.com/aws/amazon-chime-sdk-js/blob/main/guides/15_Background_Filter_Video_Processor.md#adding-a-background-filter-to-your-application)
     */
    options?: BackgroundBlurOptions
  }
  replacement?: {
    /**
     * 利用するビデオフィルタのスペック。公式ドキュメント参照
     * [Amazon Chime SDK for JavaScript Background Filter Guide](https://github.com/aws/amazon-chime-sdk-js/blob/main/guides/15_Background_Filter_Video_Processor.md#adding-a-background-filter-to-your-application)
     */
    spec?: BackgroundFilterSpec

    /**
     * Replacementビデオフィルタのオプション。公式ドキュメント参照
     * [Amazon Chime SDK for JavaScript Background Filter Guide](https://github.com/aws/amazon-chime-sdk-js/blob/main/guides/15_Background_Filter_Video_Processor.md#adding-a-background-filter-to-your-application)
     */
    options?: BackgroundReplacementOptions
  }
}

export type BackgroundEffectProviderProps = BaseSdkProps &
  BackgroundEffectProviderOptions & {
    children?: React.ReactNode
  }

// 背景エフェクト(ぼかし)の設定
// 画像置き換えの設定は動的に生成する必要があるため、ここでは指定しない
const defaultBackgroundBlurOptions: BackgroundEffectProviderOptions = {
  blur: {
    options: {
      blurStrength: 20,
      filterCPUUtilization: 20,
      reportingPeriodMillis: 1000,
    },
  },
} as const

/**
 * BackgroundEffectProviderImplの外側で各EffectのProviderを適用する必要があるため、
 * まとめて適用するためのコンテナを作成し、そちらをBackgroundEffectProviderとしてexportする
 */
const BackgroundEffectProvider: FC<BackgroundEffectProviderProps> = memo(
  ({ children }) => {
    // 背景エフェクトのオプション
    const [backgroundEffectOptions, setBackgroundEffectOptions] =
      useState<BackgroundEffectProviderOptions>(defaultBackgroundBlurOptions)

    // BackgroundEffectProviderのオプションの初期設定
    useEffect(() => {
      // 初回マウント時にローカルストレージから背景画像を取得し、背景エフェクトのオプションに設定する
      // TODO: 背景画像は後ほどS3に保存となるので、その対応時に取得先をS3に変更する（対応タスクは後ほど起票）
      // https://break-tmc.atlassian.net/browse/CREW-6686?focusedCommentId=13364
      const imageBlob = localStorage.getItem(
        LocalStorageKeys.MeetingBackgroundReplacementBase64
      )
        ? base64ToBlob(
            localStorage.getItem(
              LocalStorageKeys.MeetingBackgroundReplacementBase64
            ) as string
          )
        : undefined

      setBackgroundEffectOptions({
        ...defaultBackgroundBlurOptions,
        replacement: {
          options: {
            imageBlob: imageBlob,
          },
        },
      })
    }, [])

    return (
      <BackgroundReplacementProvider {...backgroundEffectOptions.replacement}>
        <BackgroundBlurProvider {...backgroundEffectOptions.blur}>
          <BackgroundEffectProviderImpl>
            {children}
          </BackgroundEffectProviderImpl>
        </BackgroundBlurProvider>
      </BackgroundReplacementProvider>
    )
  }
)

export { BackgroundEffectProvider, useBackgroundEffect }
