import {
  useInsertTaskCategoryMutation,
  useUpdateTaskCategoryMutation,
} from '@crew/apis/task/taskApis'
import { EntityType } from '@crew/enums/domain'
import { useTranslation } from '@crew/modules/i18n'
import { NotifyEventType } from 'enums/app'
import {
  notifyProjectSettingTaskCategoryEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from 'states/hooks'

export type FormValues = {
  name: string
}
const formInitialValues: FormValues = {
  name: '',
}
export const useTaskCategoryEntryForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  // Get functions for update task category
  const [
    insertTaskCategoryMutation,
    { isLoading: isLoadingInsertTaskCategory },
  ] = useInsertTaskCategoryMutation()
  const [
    updateTaskCategoryMutation,
    { isLoading: isLoadingUpdateTaskCategory },
  ] = useUpdateTaskCategoryMutation()

  // react-hook-formの各種データを取得
  const { control, reset, clearErrors, setError, formState, handleSubmit } =
    useForm<FormValues>({
      criteriaMode: 'all',
      defaultValues: formInitialValues,
    })

  // バリデーションルール
  const validateRules = useMemo(
    () => ({
      name: {
        required: t('message.general.required'),
      },
    }),
    [t]
  )

  // Insert task category process
  const insertTaskCategory = useCallback(
    async (projectId: string, name: string) => {
      await insertTaskCategoryMutation({
        taskCategory: {
          entityType: EntityType.Project,
          entityRecordId: projectId,
          name,
          // TODO ～～分類のテーブルに不要なdisplay_colorが含まれているため除去する
          //      https://break-tmc.atlassian.net/browse/CREW-3097
          displayColor: 'dummy-text',
        },
      })

      //trigger event refresh project setting members list
      const objectEventMessage: ObjectEventMessage<void> = {
        eventType: NotifyEventType.Inserted,
        id: '',
        object: undefined,
      }
      dispatch(notifyProjectSettingTaskCategoryEvent(objectEventMessage))
    },
    [dispatch, insertTaskCategoryMutation]
  )

  // Update task category process
  const updateTaskCategory = useCallback(
    async (taskCategoryId: string, name: string, version: number) => {
      await updateTaskCategoryMutation({
        taskCategory: {
          id: taskCategoryId,
          name,
          version,
        },
      })

      //trigger event refresh project setting members list
      const objectEventMessage: ObjectEventMessage<void> = {
        eventType: NotifyEventType.Updated,
        id: taskCategoryId,
        object: undefined,
      }
      dispatch(notifyProjectSettingTaskCategoryEvent(objectEventMessage))
    },
    [dispatch, updateTaskCategoryMutation]
  )

  return {
    control,
    reset,
    clearErrors,
    setError,
    formState,
    handleSubmit,
    validateRules,

    insertTaskCategory,
    updateTaskCategory,
    isLoadingInsertTaskCategory,
    isLoadingUpdateTaskCategory,
  }
}
