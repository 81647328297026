import {
  DestinationType,
  TenantMemberFilter,
  TenantMemberState,
  UserType,
} from '@crew/enums/domain'
import { useTranslation } from '@crew/modules/i18n'
import {
  ColumnDef,
  PaginationState,
  RowSelectionState,
  TableOptions,
  Updater,
  getCoreRowModel,
  OnChangeFn,
} from '@tanstack/react-table'
import {
  CrewTable,
  SelectCheckbox,
} from 'components/elements/crewTable/crewTable'
import { FC, memo, useEffect, useMemo } from 'react'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { useTenantUserTable } from './useTenantUserTable'
import { camelCase } from 'lodash'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'
import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import classNames from 'classnames'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import BaselineCreate from '~icons/ic/baseline-create'
import { EditTenantUserEntryDialog } from '../../../editTenantUserEntryDialog/editTenantUserEntryDialog'
import { useCallback, useState } from 'react'
import { useModal } from 'components/layouts/modal/useModal'
import { useToast } from 'hooks/useToast'
import { useSystemPermissions } from '@crew/hooks'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { RoleRef } from '@crew/models/refs'
import {
  SelectedUserType,
  useTenantUserListContext,
} from '../../../../useUserList'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppSelector } from 'states/hooks'
import { GetTenantSettingMembersRequest } from '@crew/apis/dist/tenantSetting/models/getTenantSettingMembers/request'
import { useGetTenantSettingMembersQuery } from '@crew/apis/dist/tenantSetting/tenantSettingApis'
import { skipToken } from '@reduxjs/toolkit/query'
import qs from 'qs'
import { DEFAULT_PAGING_PARAMS } from 'configs/constants'
import { getParamAsString } from 'utils'
import _ from 'lodash'

// renderとして使うのでmemo不可
const MemberState = (state: TenantMemberState) => {
  const [t] = useTranslation()

  switch (state) {
    case TenantMemberState.Enabled:
      return t(`label.${TenantMemberState.Enabled}`)
    case TenantMemberState.Disabled:
      return t(`label.${TenantMemberState.Disabled}`)
    case TenantMemberState.Inviting:
      return t(`label.${TenantMemberState.Inviting}`)
    case TenantMemberState.WaitingApproval:
      return t(`label.${camelCase(TenantMemberState.WaitingApproval)}`)
    default:
      return
  }
}

// renderとして使うのでmemo不可
const UserByState: FC<{
  state: TenantMemberState
  userId: string
  displayName: string
  version: number
}> = (props) => {
  switch (props.state) {
    case TenantMemberState.Enabled:
      return (
        <CrewUserAvatar
          userId={props.userId}
          displayName={props.displayName}
          size={CrewAvatarSize.xs}
          showLabel={true}
          labelColor="crew-link"
          cacheValue={props.userId + props.version}
        />
      )
    case TenantMemberState.Disabled:
      return (
        <CrewUserAvatar
          userId={props.userId}
          displayName={props.displayName}
          size={CrewAvatarSize.xs}
          showLabel={true}
          labelColor="crew-text-gray-5"
          cacheValue={props.userId + props.version}
        />
      )
    default:
      return null
  }
}

const LoginIdByState: FC<{
  loginId: string | null
  displayName: string
  destinationType: string | null
  state: TenantMemberState
}> = ({ loginId, displayName, destinationType, state }) => {
  return (
    <div
      className={classNames('w-full whitespace-nowrap truncate', {
        'crew-text-gray-5': state === TenantMemberState.Disabled,
      })}
    >
      {state === TenantMemberState.Inviting
        ? destinationType === DestinationType.LoginId
          ? displayName
          : null
        : loginId}
    </div>
  )
}

const EmailByState: FC<{
  email: string | null
  displayName: string
  destinationType: string | null
  state: TenantMemberState
}> = ({ email, displayName, destinationType, state }) => {
  return (
    <div
      className={classNames('w-full whitespace-nowrap truncate', {
        'crew-text-gray-5': state === TenantMemberState.Disabled,
      })}
    >
      {state === TenantMemberState.Inviting
        ? destinationType === DestinationType.Email
          ? displayName
          : email
        : email}
    </div>
  )
}

type TenantSettingMember = {
  tenantMemberPendingId: string | null
  userId: string
  displayName: string
  roleId: string
  state: TenantMemberState
  enabled: boolean
  role: RoleRef
  userType: UserType | null
  expirationDatetime: string | null
  createdAt: string
  destinationType: string | null
  loginId: string | null
  email: string | null
  version: number
}

export type TenantUserTableProps = {
  tenantSettingMembers: TenantSettingMember[]
  rowSelection: RowSelectionState
  setRowSelection: (rowSelection: Updater<RowSelectionState>) => void
}

export const TenantUserTable = memo(() => {
  const { deletePendingUser, isLoadingDeleteUserPending } = useTenantUserTable()

  const {
    hasSysExternalUserInvitePermission,
    hasSysInternalUserInvitePermission,
  } = useSystemPermissions()

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  const { t } = useTranslation()
  const { success, error } = useToast()
  const navigate = useNavigate()

  const { setSelectedUsers } = useTenantUserListContext()

  const [searchParams] = useSearchParams()
  const params = qs.parse(searchParams.toString())

  const defaultListDisplayNumber = useUserSetting(
    SettingKeyType.ListDisplayNumber,
    DEFAULT_PAGING_PARAMS.pageSize
  )

  const pagination: PaginationState = useMemo(
    () => ({
      pageIndex: Number(
        getParamAsString('pageIndex', params) ?? DEFAULT_PAGING_PARAMS.pageIndex
      ),
      pageSize: Number(
        getParamAsString('pageSize', params) ?? defaultListDisplayNumber
      ),
    }),
    [defaultListDisplayNumber, params]
  )

  const stateFilter =
    searchParams.get('filter') || TenantMemberFilter.AllTenantMembers

  const keyword = useAppSelector(
    (state) => state.tenantSettingPage.user.keyword
  )

  const tenantSettingUserEventMessage = useAppSelector(
    (state) => state.app.tenantSettingUserEventMessage
  )

  const getTenantSettingMembersParams: GetTenantSettingMembersRequest = {
    filter: stateFilter as TenantMemberFilter,
    keyword: keyword,
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
  }

  // Call API get list user
  const {
    data: getTenantSettingMembersResult,
    refetch: getTenantSettingMembersRefetch,
  } = useGetTenantSettingMembersQuery(
    getTenantSettingMembersParams ?? skipToken
  )

  const tenantSettingMembers = useMemo(
    () => getTenantSettingMembersResult?.tenantSettingMembers ?? [],
    [getTenantSettingMembersResult?.tenantSettingMembers]
  )

  const totalCount = useMemo(
    () => getTenantSettingMembersResult?.totalCount ?? 0,
    [getTenantSettingMembersResult?.totalCount]
  )

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  // get selected files from row selection
  const selectedMembers = useMemo(() => {
    if (!getTenantSettingMembersResult?.tenantSettingMembers) return []

    return getTenantSettingMembersResult.tenantSettingMembers.filter(
      (member) => {
        return rowSelection[member.tenantMemberPendingId ?? member.userId]
      }
    )
  }, [getTenantSettingMembersResult?.tenantSettingMembers, rowSelection])

  // refresh user list
  useEffect(() => {
    getTenantSettingMembersRefetch()

    return () => {
      // reset selected user list when leave page
      setSelectedUsers([])
      setRowSelection({})
    }
  }, [
    tenantSettingUserEventMessage,
    getTenantSettingMembersRefetch,
    setSelectedUsers,
  ])

  // update select users when change
  useEffect(() => {
    setSelectedUsers(
      selectedMembers.map((item) => {
        const user: SelectedUserType = {
          userId: item.tenantMemberPendingId ?? item.userId,
          userType: item.userType,
          version: item.version,
        }
        return user
      })
    )
  }, [selectedMembers, setSelectedUsers])

  const [selectedMember, setSelectedMember] = useState<TenantSettingMember>()

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')

  const [columnVisibility, setColumnVisibility] = useState({})
  const [columnPinning] = useState({
    left: ['select'],
    right: ['action'],
  })

  // handles the event when the user clicks on a button to open a confirmation dialog for canceling a member.
  const handleCancelMemberButtonClick = useCallback(
    (member: TenantSettingMember) => {
      setSelectedMember(member)
      setConfirmMessage(t('message.general.confirmMessage.cancel'))
      openConfirmDialog()
    },
    [openConfirmDialog, t]
  )

  // handles the submission of canceling an invitation for a user.
  const handleSubmitCancelInvitationButtonClick = useCallback(async () => {
    if (selectedMember?.tenantMemberPendingId) {
      // Cancel tenant user inviting when ${tenantMemberPendingId} exist
      try {
        // Call Api cancel member
        await deletePendingUser(selectedMember.tenantMemberPendingId)

        // reset row selection
        const selection = Object.keys(rowSelection).reduce((obj, key) => {
          if (selectedMember.userId !== key) {
            obj[key] = rowSelection[key]
          }
          return obj
        }, {} as RowSelectionState)
        setRowSelection(selection)

        success(t('message.projectMemberPending.projectMemberDelete'))
      } catch (err) {
        error(t('message.general.failedToCancel'))
      }
    }
    closeConfirmDialog()
  }, [
    closeConfirmDialog,
    deletePendingUser,
    error,
    rowSelection,
    selectedMember?.tenantMemberPendingId,
    selectedMember?.userId,
    success,
    t,
  ])

  const [
    isEditTenantUserEntryDialogOpen,
    openEditTenantUserEntryDialog,
    closeEditTenantUserEntryDialog,
  ] = useModal()

  // Open dialog for edit member.
  const handleEditTenantUserButtonClick = useCallback(
    (member: TenantSettingMember) => {
      setSelectedMember(member)
      openEditTenantUserEntryDialog()
    },
    [openEditTenantUserEntryDialog]
  )

  const columns = useMemo<ColumnDef<TenantSettingMember>[]>(
    () => [
      {
        id: 'select',
        accessorKey: 'select',
        header: ({ table }) => (
          <SelectCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="flex flex-1 justify-center">
            <SelectCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
        size: 80,
        minSize: 50,
        enableSorting: false,
        meta: {
          align: 'center',
        },
      },
      {
        id: 'user',
        header: () => t('label.displayName'),
        cell: ({ row }) => (
          <div className="truncate">
            <UserByState
              state={row.original.state}
              userId={row.original.userId}
              displayName={row.original.displayName}
              version={row.original.version}
            />
          </div>
        ),
        size: 200,
        minSize: 50,
      },
      {
        id: 'loginId',
        header: () => t('label.loginId'),
        cell: ({ row }) => (
          <LoginIdByState
            loginId={row.original.loginId}
            destinationType={row.original.destinationType}
            displayName={row.original.displayName}
            state={row.original.state}
          />
        ),
        size: 150,
        minSize: 50,
      },
      {
        id: 'email',
        header: () => t('label.mailAddress'),
        cell: ({ row }) => (
          <EmailByState
            email={row.original.email}
            destinationType={row.original.destinationType}
            displayName={row.original.displayName}
            state={row.original.state}
          />
        ),
        size: 240,
        minSize: 50,
      },
      {
        id: 'role',
        header: () => t('label.role'),
        cell: ({ row }) => (
          <div
            className={classNames('w-full whitespace-nowrap truncate', {
              'crew-text-gray-5':
                row.original.state === TenantMemberState.Disabled,
            })}
          >
            {row.original.role.roleCode
              ? t(`label.roles.${camelCase(row.original.role.roleCode)}`)
              : row.original.role.name}
          </div>
        ),
        size: 160,
        minSize: 50,
      },
      {
        id: 'memberState',
        header: () => t('label.memberState'),
        cell: ({ row }) => (
          <div className="w-full">
            <div
              className={classNames(
                'flex gap-x-2.5 items-center',
                row.original.state === TenantMemberState.Disabled &&
                  'crew-text-gray-5'
              )}
            >
              {MemberState(row.original.state)}
              {row.original.state === TenantMemberState.Inviting &&
                (hasSysExternalUserInvitePermission ||
                  hasSysInternalUserInvitePermission) && (
                  <CrewButton
                    type="normal"
                    stylingMode="outlined"
                    className="h-8"
                    onClick={() => handleCancelMemberButtonClick(row.original)}
                    render={() => (
                      <div className={'w-16 text-sm py-1'}>
                        {t('action.cancel')}
                      </div>
                    )}
                  />
                )}
            </div>
          </div>
        ),
        size: 240,
        minSize: 240,
      },
      {
        id: 'expirationDate',
        header: () => t('label.expirationDate'),
        cell: ({ row }) => (
          <div
            className={classNames('w-full whitespace-nowrap truncate', {
              'crew-text-gray-5':
                row.original.state === TenantMemberState.Disabled,
            })}
          >
            {row.original.expirationDatetime &&
              t('format.date', {
                value: row.original.expirationDatetime,
              })}
          </div>
        ),
        size: 120,
        minSize: 50,
      },
      {
        id: 'registeredDate',
        header: () => t('label.registeredDate'),
        cell: ({ row }) => (
          <div
            className={classNames('w-full whitespace-nowrap truncate', {
              'crew-text-gray-5':
                row.original.state === TenantMemberState.Disabled,
            })}
          >
            {t('format.timestamp', {
              value: row.original.createdAt,
              timeZone: defaultUserProfileRegion,
            })}
          </div>
        ),
        size: 160,
        minSize: 50,
      },
      {
        id: 'action',
        accessorKey: 'action',
        header: '',
        cell: ({ row }) => (
          <div className="flex flex-1 justify-center">
            {row.original.state !== TenantMemberState.Inviting && (
              <CrewButton
                icon={<BaselineCreate width={20} height={20} />}
                tabIndex={-1}
                stylingMode="text"
                onClick={() => handleEditTenantUserButtonClick(row.original)}
                size="sm"
              />
            )}
          </div>
        ),
        size: 120,
        minSize: 50,
        enableSorting: false,
      },
    ],
    [
      t,
      hasSysExternalUserInvitePermission,
      hasSysInternalUserInvitePermission,
      handleCancelMemberButtonClick,
      defaultUserProfileRegion,
      handleEditTenantUserButtonClick,
    ]
  )

  // https://github.com/TanStack/table/discussions/3899
  // https://github.com/TanStack/table/discussions/3619
  // https://github.com/infonomic/remix.infonomic.io/blob/d3a7f628d3ad6e1e80cc80d4ac72db74da90e8d6/app/routes/admin%2B/users.tsx#L116
  // Func handle change pagination
  const handlePaginationListChange: OnChangeFn<PaginationState> = useCallback(
    (updaterOrValue) => {
      let values: PaginationState
      if (updaterOrValue instanceof Function) {
        values = updaterOrValue(pagination)
      } else {
        values = updaterOrValue
      }

      const newParams = {
        ...params,
        pageIndex: values.pageIndex,
        pageSize: values.pageSize,
      }

      // paramsが変わっていない場合はnavigateしない
      if (_.isEqual(params, newParams)) return

      const newQueryString = qs.stringify(newParams, {
        arrayFormat: 'repeat',
        skipNulls: true,
      })

      navigate(`?${newQueryString}`)
    },
    [navigate, pagination, params]
  )

  const pageCount = Math.ceil((totalCount ?? 0) / pagination.pageSize)

  const tableOptions: TableOptions<TenantSettingMember> = {
    data: tenantSettingMembers,
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      columnVisibility,
      columnPinning,
      rowSelection: rowSelection,
    },
    getRowId: (row) => row.tenantMemberPendingId ?? row.userId,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: handlePaginationListChange,
    enableRowSelection: true,
    pageCount,
    meta: {
      headerRowHeight: 40,
      dataRowHeight: 50,
    },
    manualPagination: true,
  }

  return (
    <>
      {/* Tenant user list table */}
      <CrewTable tableOptions={tableOptions} showColumnSelector={false} />

      {/* Canceling an invitation confirm dialog */}
      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleSubmitCancelInvitationButtonClick}
        onCancelButtonClick={closeConfirmDialog}
        permitButtonDisabled={isLoadingDeleteUserPending}
      />

      {selectedMember && (
        <EditTenantUserEntryDialog
          title={t('label.editUser')}
          isOpen={isEditTenantUserEntryDialogOpen}
          onClose={closeEditTenantUserEntryDialog}
          userId={selectedMember.userId}
          version={selectedMember.version}
          displayName={selectedMember.displayName}
          roleId={selectedMember.roleId}
          enabled={selectedMember.enabled}
          userType={selectedMember.userType}
          expirationDatetime={selectedMember.expirationDatetime}
        />
      )}
    </>
  )
})
