import classNames from 'classnames'
import { CrewAvatar } from 'components/elements/crewAvatar/crewAvatar'
import { BaseSdkProps } from 'modules/amazon-chime-sdk-component-library-devextreme/components/sdk/Base'
import { FC, memo, useMemo } from 'react'
import { CrewLocalVideoTile } from '../crewLocalVideoTile'
import { CrewRemoteVideoTile } from '../crewRemoteVideoTile'
import { AttendeeUser, useCrewAttendees } from './useCrewAttendees'
import { generateImageAvatarUrl } from '@crew/utils/avatar'
import { EntityType, MeetingAttendeeJoinState } from '@crew/enums/domain'
import { UserRef } from '@crew/models/refs'
import { CrewShowMicrophoneMute } from 'components/elements/crewShowMicrophoneMute/crewShowMicrophoneMute'
import { CrewShowNameAttended } from 'components/elements/crewShowNameAttended/crewShowNameAttended'

type Props = BaseSdkProps & {
  id?: string
  attendeeUsers: AttendeeUser[]
  isSharing: boolean
}

// アクティブスピーカーの際に付与するクラス群
const ACTIVE_SPEAKER_CLASS =
  'border-crew-yellow-3-light dark:border-crew-yellow-3-dark border-solid border-4'

// ※使用する際はclassNameにwidthを指定すること
//      使用例：<CrewAttendees className="w-72" />
export const CrewAttendees: FC<Props> = memo(
  ({ className, isSharing, attendeeUsers, ...rest }) => {
    const {
      isVideoEnabled,
      attendeeIdToTileId,
      localAttendeeUser,
      activeSpeakerAttendeeId,
    } = useCrewAttendees()

    // Get remote attendees who are joining the meeting
    const remoteAttendeeVideosTile = useMemo(
      () =>
        attendeeUsers.filter(
          (user) => user.joinState === MeetingAttendeeJoinState.Joining
        ),
      [attendeeUsers]
    )

    const classes = useMemo(
      () =>
        isSharing
          ? {
              //列数を制限しない場合、タイル内部をflexにして良い感じに横に並べる
              tileContainer:
                'justify-center flex max-h-full h-16 w-auto aspect-video',
              tile: 'h-full w-full',
            }
          : {
              //列数を指定する場合、固定幅で並べる
              tileContainer: 'justify-center w-full aspect-video',
              tile: 'h-full w-full m-auto',
            },
      [isSharing]
    )

    return (
      <div className={className}>
        <div className={classes.tileContainer}>
          {/* ローカル */}
          {isVideoEnabled ? (
            // ビデオON
            <CrewLocalVideoTile
              key={localAttendeeUser?.id as string}
              className={classNames(
                classes.tile,
                localAttendeeUser?.chimeAttendeeId ===
                  activeSpeakerAttendeeId && ACTIVE_SPEAKER_CLASS
              )}
              name={localAttendeeUser?.displayName}
              chimeAttendeeId={localAttendeeUser?.chimeAttendeeId}
            />
          ) : (
            // ビデオOFF
            localAttendeeUser && (
              <AttendeeTile
                chimeAttendeeId={localAttendeeUser.chimeAttendeeId}
                key={localAttendeeUser.id as string}
                user={localAttendeeUser}
                className={classNames(
                  classes.tile,
                  localAttendeeUser.chimeAttendeeId ===
                    activeSpeakerAttendeeId && ACTIVE_SPEAKER_CLASS
                )}
              />
            )
          )}
        </div>
        {/* リモート */}
        {remoteAttendeeVideosTile.map((user) => {
          const tileId = attendeeIdToTileId[user.chimeAttendeeId]

          // リモート参加者をループして、アクティブスピーカーのAttendeeIdと比較して一致すればそのユーザがアクティブスピーカーである
          const isActiveSpeaker =
            user.chimeAttendeeId === activeSpeakerAttendeeId

          return (
            <div
              className={classes.tileContainer}
              key={user.id + user.joinState}
            >
              {tileId ? (
                // ビデオON
                <CrewRemoteVideoTile
                  key={user.id}
                  tileId={tileId}
                  name={user.displayName}
                  className={classNames(
                    classes.tile,
                    isActiveSpeaker && ACTIVE_SPEAKER_CLASS
                  )}
                  chimeAttendeeId={user.chimeAttendeeId}
                />
              ) : (
                // ビデオOFF
                <AttendeeTile
                  chimeAttendeeId={user.chimeAttendeeId}
                  key={user.id}
                  user={user}
                  className={classNames(
                    classes.tile,
                    isActiveSpeaker && ACTIVE_SPEAKER_CLASS
                  )}
                />
              )}
            </div>
          )
        })}
      </div>
    )
  }
)

// ビデオOFF時の枠表示
type AttendeeTileProps = {
  chimeAttendeeId: string
  user: UserRef
  className?: string
}
const AttendeeTile: FC<AttendeeTileProps> = memo((props) => {
  return (
    <div
      className={classNames(
        props.className,
        'bg-crew-slate-3-dark text-white',
        'flex justify-center items-center',
        'relative'
      )}
    >
      <CrewAvatar
        displayName={props.user.displayName}
        className="aspect-square"
        imageURL={generateImageAvatarUrl(EntityType.User, props.user.id)}
        cacheValue={props.user.id + props.user.version}
      />
      {/* Show name Attended member */}
      <CrewShowNameAttended name={props.user.displayName} />

      {/* Show mute icon */}
      {props.chimeAttendeeId && (
        <CrewShowMicrophoneMute chimeAttendeeId={props.chimeAttendeeId} />
      )}
    </div>
  )
})
