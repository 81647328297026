import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useUpdateBillingInformationMutation } from '@crew/apis/contract/contractApis'
import { useTranslation } from '@crew/modules/i18n'
import { ValidateRules } from '@crew/utils/form'

export type FormValues = {
  companyName: string
  postalCode: string
  state: string
  city: string
  line1: string
  line2: string
}
const formInitialValues: FormValues = {
  companyName: '',
  postalCode: '',
  state: '',
  city: '',
  line1: '',
  line2: '',
}
export const useContractChangeBillingAddressPage = () => {
  const { control, handleSubmit, setError, reset } = useForm<FormValues>({
    criteriaMode: 'all',
    defaultValues: formInitialValues,
  })

  const { t } = useTranslation()

  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      companyName: {
        required: t('message.general.required'),
      },
      postalCode: {},
      state: {},
      city: {},
      line1: {},
      line2: {},
    }),
    [t]
  )

  const [
    updateBillingInformationMutation,
    { isLoading: isLoadingUpdateBillingInformation },
  ] = useUpdateBillingInformationMutation()

  // 請求先情報の更新
  const updateBillingInformation = useCallback(
    async (data: FormValues, country: string) => {
      await updateBillingInformationMutation({
        name: data.companyName,
        address: {
          country: country,
          postalCode: data.postalCode,
          state: data.state,
          city: data.city,
          line1: data.line1,
          line2: data.line2,
        },
      }).unwrap()
    },
    [updateBillingInformationMutation]
  )

  return {
    control,
    validateRules,
    handleSubmit,
    setError,
    reset,
    updateBillingInformation,
    isLoadingUpdateBillingInformation,
  }
}
