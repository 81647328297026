import { useTranslation } from '@crew/modules/i18n'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { FC, memo, useCallback } from 'react'
import { useMeetingManager } from 'modules/amazon-chime-sdk-component-library-devextreme'
import { getDeviceId } from 'modules/amazon-chime-sdk-component-library-devextreme/utils/device-utils'
import { useCrewSpeakerTestButton } from './useCrewSpeakerTestButton'

//スピーカーのテストボタン
export const CrewSpeakerTestButton: FC = memo(() => {
  const { checkAudioOutput } = useCrewSpeakerTestButton()
  const { t } = useTranslation()

  const meetingManager = useMeetingManager()

  const handleTestButtonClick = useCallback(async () => {
    if (!meetingManager.meetingSession) {
      return
    }

    const deviceId = await getDeviceId(meetingManager.selectedAudioOutputDevice)

    // 5秒間ブザー音を慣らす
    await checkAudioOutput(meetingManager.meetingSession, deviceId)
  }, [
    checkAudioOutput,
    meetingManager.meetingSession,
    meetingManager.selectedAudioOutputDevice,
  ])

  return (
    <CrewButton
      stylingMode="outlined"
      text={t('label.test')}
      onClick={handleTestButtonClick}
    />
  )
})
