import { Menu } from '@headlessui/react'
import classNames from 'classnames'
import { FC, memo, useCallback } from 'react'

export type Item = {
  value: number | string
  label: string
}

type ComboBoxItemProps = {
  item: Item
  onClickItem: (value: number | string | undefined) => void
}
export const CrewComboBoxItem: FC<ComboBoxItemProps> = memo((props) => {
  // handle item click
  const handleClickItem = useCallback(() => {
    props.onClickItem(props.item.value)
  }, [props])

  return (
    <Menu.Item key={props.item.value}>
      {({ active }) => (
        <div
          className={classNames(
            'block px-4 py-2 text-sm',
            active && 'crew-bg-gray-2'
          )}
          onClick={handleClickItem}
        >
          {props.item.label}
        </div>
      )}
    </Menu.Item>
  )
})
