import { memo } from 'react'
import { TaskDetailCommentItem } from './components/taskDetailCommentItem/taskDetailCommentItem'
import { TaskDetailWorkItem } from './components/taskDetailWorkItem/taskDetailWorkItem'
import { TaskDetailCommentInput } from './components/taskDetailCommentInput/taskDetailCommentInput'
import { TaskDetailWorkInputPanel } from './components/taskDetailWorkInputPanel/taskDetailWorkInputPanel'
import { TaskDetailCommentListPageContext } from './useTaskDetailCommentListPanel'
import { CommentButtonGroup, SortCommentButtonGroup } from 'enums/app'
import { useProjectPermissions } from '@crew/hooks'
import { EntityType } from '@crew/enums/domain'
import { useAppSelector } from 'states/hooks'
import {
  useGetTaskCommentsAndHistoriesQuery,
  useGetTaskWorksQuery,
} from '@crew/apis/task/taskApis'
import { skipToken } from '@reduxjs/toolkit/query'
import { GetTaskWorksRequest } from '@crew/apis/task/models/getTaskWorks/request'
import { GetTaskCommentsAndHistoriesRequest } from '@crew/apis/task/models/getTaskCommentsAndHistories/request'
import { useEffect, useState } from 'react'

export const TaskDetailCommentListPanel = memo(() => {
  const [editItemId, setEditItemId] = useState<string | null>(null)

  // コメント入力パネル・タスク作業入力パネル表示判定用のリスト表示形式のインデックス
  const selectedCommentDisplayType = useAppSelector(
    (state) => state.taskDetail.selectedCommentButtonGroupItem
  )

  const selectedSortCommentDisplayType = useAppSelector(
    (state) => state.taskDetail.selectedSortCommentButtonGroupItem
  )

  // タスクコメントの更新を検知して再レンダリングを走らせるためのトリガー
  const taskCommentEventMessage = useAppSelector(
    (state) => state.app.taskCommentEventMessage
  )

  const taskWorkEventMessage = useAppSelector(
    (state) => state.app.taskWorkEventMessage
  )

  const taskEventMessage = useAppSelector((state) => state.app.taskEventMessage)

  const taskId = useAppSelector((state) => state.taskDetail.taskId)

  // タスク作業を取得する
  // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
  const getTaskWorksParam: GetTaskWorksRequest | undefined = taskId
    ? {
        taskId: taskId,
        sort: [`createdAt.${selectedSortCommentDisplayType}`],
      }
    : undefined

  // タスク作業の取得をスキップするかどうかの要約変数（直接指定したらわかりづらかったので一度変数に代入）
  const skipGetTaskWorks =
    getTaskWorksParam &&
    selectedCommentDisplayType === CommentButtonGroup.Time.value

  // タスク作業を取得
  const {
    data: getGetTaskWorksResult,
    isUninitialized: isTaskWorksUninitialized,
    refetch: refetchTaskWorks,
  } = useGetTaskWorksQuery(skipGetTaskWorks ? getTaskWorksParam : skipToken)

  // タスクコメント+タスク履歴を取得する
  // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
  const getTaskCommentsAndHistoriesParam:
    | GetTaskCommentsAndHistoriesRequest
    | undefined = taskId
    ? {
        taskId: taskId,
        filter: selectedCommentDisplayType,
        sort: [`createdAt.${selectedSortCommentDisplayType}`], //set sort by create at
      }
    : undefined

  // タスクコメント+タスク履歴の取得をスキップするかどうかの要約変数（直接指定したらわかりづらかったので一度変数に代入）
  const skipGetTaskCommentsAndHistories =
    getTaskCommentsAndHistoriesParam &&
    selectedCommentDisplayType !== CommentButtonGroup.Time.value

  // タスクコメント+タスク履歴の取得
  const {
    data: getTaskCommentsAndHistoriesResult,
    isUninitialized: isTaskCommentsUninitialized,
    refetch: refetchTaskComments,
  } = useGetTaskCommentsAndHistoriesQuery(
    skipGetTaskCommentsAndHistories
      ? getTaskCommentsAndHistoriesParam
      : skipToken
  )

  // タスクコメント・タスク作業の更新を検知して再レンダリングを走らせる
  useEffect(() => {
    // タスクコメントが初期化済みの場合、再フェッチを実行
    // 初期化状態をチェックしないと初回レンダリング時にもrefetchを実行しようとしてエラーが発生してしまう
    if (!isTaskCommentsUninitialized) {
      refetchTaskComments()
    }
    // タスク作業が初期化済みの場合、再フェッチを実行
    // 初期化状態をチェックしないと初回レンダリング時にもrefetchを実行しようとしてエラーが発生してしまう
    if (!isTaskWorksUninitialized) {
      refetchTaskWorks()
    }
  }, [
    isTaskCommentsUninitialized,
    isTaskWorksUninitialized,
    refetchTaskComments,
    refetchTaskWorks,
    taskCommentEventMessage,
    taskWorkEventMessage,
    taskEventMessage,
  ])

  const { hasPrjFileDeletePermission, hasPrjFileDownloadPermission } =
    useProjectPermissions(EntityType.Task, taskId)

  const latestTaskHistoryId =
    getTaskCommentsAndHistoriesResult?.latestTaskHistoryId

  return (
    <TaskDetailCommentListPageContext.Provider
      value={{
        editItemId,
        setEditItemId,
      }}
    >
      <div
        className={`flex flex-col gap-y-4 pb-3 ${
          selectedSortCommentDisplayType ===
          SortCommentButtonGroup.Descending.value
            ? 'flex-col-reverse'
            : ''
        }`}
      >
        <div>
          {(selectedCommentDisplayType ===
            CommentButtonGroup.CommentAndHistory.value ||
            selectedCommentDisplayType === CommentButtonGroup.Comment.value ||
            selectedCommentDisplayType === CommentButtonGroup.History.value) &&
            getTaskCommentsAndHistoriesResult?.taskCommentsAndHistories?.map(
              (taskComment, index) => {
                return (
                  <TaskDetailCommentItem
                    hasFileDeletePermission={hasPrjFileDeletePermission}
                    hasFileDownloadPermission={hasPrjFileDownloadPermission}
                    key={index}
                    taskComment={taskComment}
                    latestTaskHistoryId={latestTaskHistoryId ?? null}
                  />
                )
              }
            )}

          {selectedCommentDisplayType === CommentButtonGroup.Time.value &&
            getGetTaskWorksResult?.taskWorks?.map((taskWork) => {
              return (
                <TaskDetailWorkItem key={taskWork.id} taskWork={taskWork} />
              )
            })}
        </div>

        {/* 表示形式が「コメントと履歴」の場合はコメント入力パネルを表示（「コメント」「履歴」は入力パネル非表示） */}
        {/* 履歴が1件も取れていない場合（＝参照できないタスク）は入力枠を表示させない */}
        {selectedCommentDisplayType ===
          CommentButtonGroup.CommentAndHistory.value &&
          getTaskCommentsAndHistoriesResult?.taskCommentsAndHistories && (
            <TaskDetailCommentInput />
          )}

        {/* 表示形式が「時間」の場合はタスク作業入力パネルを表示 */}
        {/* 履歴が1件も取れていない場合（＝参照できないタスク）は入力枠を表示させない */}
        {selectedCommentDisplayType === CommentButtonGroup.Time.value &&
          getGetTaskWorksResult?.taskWorks && (
            <TaskDetailWorkInputPanel isEditMode={false} />
          )}
      </div>
    </TaskDetailCommentListPageContext.Provider>
  )
})
