import { FC, memo } from 'react'
import { MemberTable } from './components/memberTable/memberTable'
import { RowSelectionState, Updater } from '@tanstack/react-table'
import { useParams } from 'react-router-dom'

type ProjectDetailMemberListPanelProps = {
  rowSelection: RowSelectionState
  setRowSelection: (rowSelection: Updater<RowSelectionState>) => void
}

export const ProjectDetailMemberListPanel: FC<ProjectDetailMemberListPanelProps> =
  memo((props) => {
    const { projectId } = useParams()

    return (
      <div className="h-full flex flex-col">
        {projectId && (
          <MemberTable
            projectId={projectId}
            rowSelection={props.rowSelection}
            setRowSelection={props.setRowSelection}
          />
        )}
      </div>
    )
  })
