import { EntityType, ProjectType } from '@crew/enums/domain'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from '@crew/modules/i18n'
import { ValidateRules } from '@crew/utils/form'
import { UploadFile } from 'models/domain/uploadFile'
import { File } from '@crew/apis/file/models/getFile/response'
import {
  notifyFileEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { NotifyEventType } from 'enums/app'
import { useAppDispatch } from 'states/hooks'
import { File as FileResponse } from '@crew/models/domain'
import {
  useDeleteFileMutation,
  useUpdateFileMutation,
  useInsertFileMutation,
} from '@crew/apis/file/fileApis'
import { useFileEntityTypeDataSource } from 'hooks/dataSource/useResourceDataSource'
import { useEntityRecordDataSource } from 'hooks/dataSource/useEntityRecordDataSource'
import { useProjectTagDataSource } from 'hooks/dataSource/useProjectTagDataSource'
import { useFolderDataSource } from 'hooks/dataSource/useFolderDataSource'
import { useInsertEventFileMutation } from '@crew/apis/project/projectApis'
import { useInsertTaskFileMutation } from '@crew/apis/task/taskApis'

export type FormValues = {
  entityType: EntityType | null
  entityRecordId: string | null
  tagIds: string[] | null
  description: string
  name: string | null
  fileHistoryDescription: string
  uploadedFileKey: string | null
  needNotification: boolean
  folderId: string | null
}

export const useFileEntryForm = (
  isEditMode: boolean,
  entityType: EntityType | null,
  entityRecordId: string | null
) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  // react-hook-formの各種データを取得
  const {
    formState,
    control,
    reset,
    setValue,
    getValues,
    setError,
    handleSubmit,
  } = useForm<FormValues>({
    criteriaMode: 'all',
  })

  // ファイルの登録・更新・削除の関数を取得
  const [insertTaskFileMutation, { isLoading: isLoadingInsertTaskFile }] =
    useInsertTaskFileMutation()
  const [insertEventFileMutation, { isLoading: isLoadingInsertEventFile }] =
    useInsertEventFileMutation()
  const [insertFileMutation, { isLoading: isLoadingInsertFile }] =
    useInsertFileMutation()
  const [updateFileMutation, { isLoading: isLoadingUpdateFile }] =
    useUpdateFileMutation()
  const [deleteFileMutation, { isLoading: isLoadingDeleteFile }] =
    useDeleteFileMutation()

  // 関連先タイプのDataSource
  const entityTypeDataSource = useFileEntityTypeDataSource()

  // 関連先のDataSource
  const entityRecordIdDataSource = useEntityRecordDataSource(entityType)

  // フォルダのDataSource
  const folderDataSource = useFolderDataSource(entityType, entityRecordId)

  // Get list project ID
  const projectIds = useMemo(
    () =>
      entityType === EntityType.Project && entityRecordId
        ? [entityRecordId]
        : [],
    [entityRecordId, entityType]
  )

  // get file tag record dataSource
  const { insertProjectTagDataSource } = useProjectTagDataSource(
    EntityType.File,
    ProjectType.Project,
    projectIds
  )
  // タグのDataSource
  const tagDataSource = insertProjectTagDataSource

  // ファイル名の入力をチェックする関数
  const validateFileName = useCallback(() => {
    // 編集モード時、ファイル名の必須チェック
    if (isEditMode) {
      if (!getValues('name')) {
        return false
      }
    }
    return true
  }, [getValues, isEditMode])

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      entityType: {
        required: t('message.general.required'),
      },
      uploadedFileKey: {
        required: t('message.general.required'),
      },
      entityRecordId: {
        required: t('message.general.required'),
      },
      // ファイル名
      name: {
        validate: {
          requiredInEditMode: () =>
            validateFileName() || t('message.general.required'),
        },
        maxLength: {
          value: 100,
          message: t('message.general.maxLength', {
            name: t('label.subject'),
            value: 100,
          }),
        },
      },

      // 内容
      description: {
        maxLength: {
          value: 500,
          message: t('message.general.maxLength', {
            name: t('label.description'),
            value: 500,
          }),
        },
      },
      // not validate below
      tagIds: {},
      // 変更内容（ファイル履歴の説明。ファイルアップロード時に表示）
      fileHistoryDescription: {
        maxLength: {
          value: 500,
          message: t('message.general.maxLength', {
            name: t('label.changes'),
            value: 500,
          }),
        },
      },
      needNotification: {},
      folderId: {
        required: t('message.general.required'),
      },
    }),
    [t, validateFileName]
  )

  // ファイル新規登録
  const insertFile = useCallback(
    async (
      data: FormValues,
      uploadedFile: UploadFile | undefined,
      processEntityType: EntityType | undefined, // EventやTaskなど、登録のリクエストに含めるEntityType
      processEntityRecordId: string | undefined
    ) => {
      // 必要なデータがない場合は処理しない
      if (!uploadedFile) {
        return
      }

      // 送信に使用するEntityTypeとEntityRecordId
      // processEntityTypeは、FileEntryFormの呼び出し元から渡される値
      //  例）ProjectやTask、Eventを持つ
      // ファイル一覧画面から登録する場合は、この値が渡されてこないため、画面上で設定された値(dataの値)を使用する
      const entityTypeParam =
        processEntityType ?? (data.entityType as EntityType)
      const entityRecordIdParam =
        processEntityRecordId ?? (data.entityRecordId as string)

      const file = {
        name: uploadedFile.name,
        fileName: uploadedFile.name,
        keyName: uploadedFile.keyName,
        folderId: data.folderId ?? undefined,
        tagIds: data.tagIds ?? undefined,
        description: data.description,
        fileHistoryDescription: data.fileHistoryDescription,
      }

      let result = null

      if (entityTypeParam === EntityType.Event) {
        result = await insertEventFileMutation({
          eventId: entityRecordIdParam,
          needNotification: data.needNotification,
          file,
        }).unwrap()
      } else if (entityTypeParam === EntityType.Task) {
        result = await insertTaskFileMutation({
          taskId: entityRecordIdParam,
          needNotification: data.needNotification,
          file,
        }).unwrap()
      } else {
        result = await insertFileMutation({
          needNotification: data.needNotification,
          file: {
            ...file,
            entityType: entityTypeParam,
            entityRecordId: entityRecordIdParam,
            size: uploadedFile.size,
          },
        }).unwrap()
      }

      // ファイル登録後のObjectEventMessageを発火
      const objectEventMessage: ObjectEventMessage<FileResponse> = {
        eventType: NotifyEventType.Inserted,
        id: result?.file?.id ?? '',
        object: result?.file ?? undefined,
      }
      dispatch(notifyFileEvent(objectEventMessage))

      return result
    },
    [
      dispatch,
      insertEventFileMutation,
      insertFileMutation,
      insertTaskFileMutation,
    ]
  )

  // ファイル更新
  const updateFile = useCallback(
    async (
      data: FormValues,
      file: File | null,
      uploadedFile: UploadFile | undefined
    ) => {
      // 必要なデータがない場合は処理しない
      if (!file) {
        return
      }

      const payload = {
        description: data.description,
        tagIds: data.tagIds ?? undefined,
        fileHistoryDescription: data.fileHistoryDescription,
      }

      //Perform when edit file and file exist
      const result = await updateFileMutation({
        needNotification: data.needNotification,
        file: {
          ...payload,
          id: file.id,
          name: data.name as string,
          fileName: uploadedFile?.name, // ファイル差し替えがない更新の場合はuploadedFileがundefinedとなる
          keyName: uploadedFile?.keyName, // ファイル差し替えがない更新の場合はuploadedFileがundefinedとなる
          revision: file.revision,
          version: file.version,
          folderId: data.folderId ?? undefined,
        },
      }).unwrap()

      // ファイル更新後のObjectEventMessageを発火
      const objectEventMessage: ObjectEventMessage<FileResponse> = {
        eventType: NotifyEventType.Updated,
        id: result.file?.id as string,
        object: result.file ?? undefined,
      }
      dispatch(notifyFileEvent(objectEventMessage))
    },
    [dispatch, updateFileMutation]
  )

  // ファイル削除
  const deleteFile = useCallback(
    async (fileId: string, fileVersion: number) => {
      await deleteFileMutation({
        fileId: fileId,
        version: fileVersion,
      }).unwrap()

      const objectEventMessage: ObjectEventMessage<FileResponse> = {
        eventType: NotifyEventType.Deleted,
        id: fileId,
        object: undefined,
      }

      dispatch(notifyFileEvent(objectEventMessage))
    },
    [deleteFileMutation, dispatch]
  )

  return {
    // react-hook-form関連
    formState,
    control,
    reset,
    setValue,
    setError,
    handleSubmit,
    validateRules,

    // DataSource
    entityTypeDataSource,
    entityRecordIdDataSource,
    tagDataSource,
    folderDataSource,

    // ファイル登録・更新・削除処理
    insertFile,
    updateFile,
    deleteFile,
    isLoadingInsertFile,
    isLoadingUpdateFile,
    isLoadingDeleteFile,
    isLoadingInsertTaskFile,
    isLoadingInsertEventFile,
  }
}
