import {
  ComponentProps,
  forwardRef,
  memo,
  PropsWithChildren,
  FC,
  useMemo,
} from 'react'
import { SelectBox } from 'devextreme-react'
import classNames from 'classnames'
import TextBox from 'devextreme-react/text-box'
import { SEARCH_TIMEOUT_MSEC } from '@crew/configs/constants'

type FieldRenderProps = {
  id?: string
  'data-testid'?: string
  name: string
}

const defaultRenderedItemClassName = 'h-5'

// フィールド表示のレンダリング。renderとして使うのでmemo不可
const ColorSelectBoxField: FC<FieldRenderProps> = (data) => (
  <div
    id={data.id}
    data-testid={data['data-testid']}
    className="custom-item h-8 p-1.5"
  >
    {data && data.name ? (
      <div
        className={classNames(defaultRenderedItemClassName, data.name)}
      ></div>
    ) : null}

    {/* FIXME: itemRenderにはTextBoxが必須だが、ここではカラー表示のみだけなので隠ぺい方法を模索する
                       （TextBoxのclassNameにtailwindのhiddenを設定しても隠れなかったのでhiddenを適用したdivで囲って一時対応）
                       https://break-tmc.atlassian.net/browse/CREW-2977 */}
    <div className="hidden">
      <TextBox />
    </div>
  </div>
)

type ItemRenderProps = {
  name: string
}

// リスト項目のレンダリング。renderとして使うのでmemo不可
const ColorSelectBoxItem: FC<ItemRenderProps> = (data) => (
  <div className="custom-item">
    <div className={classNames(defaultRenderedItemClassName, data.name)}></div>
  </div>
)

type Props = PropsWithChildren<ComponentProps<typeof SelectBox>> & {
  'data-testid'?: string
}

/**
 * SelectBoxのラッパー（react-hook-form非対応版）の色選択用カスタムドロー
 */
export const CrewColorSelectBox = memo(
  forwardRef<SelectBox, Props>(({ children, ...rest }, ref) => {
    const fieldRender = useMemo(() => {
      return (params: { name: string }) => (
        <ColorSelectBoxField
          id={rest.id}
          data-testid={rest['data-testid']}
          name={params.name}
        />
      )
    }, [rest])

    return (
      <SelectBox
        {...rest}
        ref={ref}
        // NOTE: ただのSelectBoxとして使用する際、余計なお世話となる↓
        labelMode={rest.labelMode ?? 'static'}
        displayExpr={rest.displayExpr ?? 'key'}
        valueExpr={rest.valueExpr ?? 'value'}
        searchEnabled={rest.searchEnabled ?? true}
        searchExpr={rest.searchExpr ?? 'key'}
        searchTimeout={rest.searchTimeout ?? SEARCH_TIMEOUT_MSEC}
        // カスタムドロー部分のレンダリング
        fieldRender={fieldRender}
        itemRender={ColorSelectBoxItem}
      >
        {children}
      </SelectBox>
    )
  })
)
