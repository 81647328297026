import classNames from 'classnames'
import { FC, memo, useCallback, useMemo } from 'react'

import { skipToken } from '@reduxjs/toolkit/query'

import { useGetLookupRelatedItemQuery } from '@crew/apis/lookup/lookupApis'
import { EntityType, ProjectType } from '@crew/enums/domain'
import { getDefaultTabValue } from '@crew/utils/enum'

import {
  DirectMessageTabs,
  EventDetailTabs,
  FileDetailListTabs,
  ProjectListTabs,
  TaskDetailListTabs,
} from 'enums/app'

import { CrewLink, LinkColor } from '../crewLink/crewLink'
import { useGetChatRoomByEntityQuery } from '@crew/apis/chat/chatApis'
import { useChatThreadListService, useChatTimelineService } from '@crew/states'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { ChatView } from '@crew/enums/app'

type CrewRelatedItemLinkProps = {
  entityType: EntityType
  id: string
  className?: string
  color?: LinkColor
  targetChatMessageId?: string
  asText?: boolean
}

export const CrewRelatedItemLink: FC<CrewRelatedItemLinkProps> = memo(
  (props) => {
    //TODO: 新着～ブックマークのリンクからだと正しく取得できない。下記タスクで対応する
    //      https://break-tmc.atlassian.net/browse/CREW-10988

    const getLookupRelatedItemParams =
      props.entityType && props.id
        ? {
            entityType: props.entityType,
            id: props.id,
          }
        : undefined

    const { data } = useGetLookupRelatedItemQuery(
      getLookupRelatedItemParams ?? skipToken
    )

    const renderLink = useMemo(() => {
      const itemId = data?.relatedItem?.id
      if (itemId === undefined) {
        return
      }

      const params = props.targetChatMessageId
        ? `?messageId=${props.targetChatMessageId}`
        : ''

      switch (props.entityType) {
        case EntityType.Project:
          if (data?.relatedItem?.type === ProjectType.DirectChannel) {
            return `/direct-channels/${itemId}/${getDefaultTabValue(
              DirectMessageTabs
            )}${params}`
          } else if (data?.relatedItem?.type === ProjectType.Project) {
            return `/${EntityType.Project}/${itemId}/${getDefaultTabValue(
              ProjectListTabs
            )}${params}`
          }

          return ''
        case EntityType.Task:
          return `/${EntityType.Task}/${itemId}/${getDefaultTabValue(
            TaskDetailListTabs
          )}${params}`
        case EntityType.Event:
          return `/${EntityType.Event}/${itemId}/${getDefaultTabValue(
            EventDetailTabs
          )}${params}`
        case EntityType.File:
          return `/${EntityType.File}/${itemId}/${getDefaultTabValue(
            FileDetailListTabs
          )}${params}`
        default:
          return
      }
    }, [
      data?.relatedItem?.id,
      data?.relatedItem?.type,
      props.targetChatMessageId,
      props.entityType,
    ])

    const { data: getChatRoomData } = useGetChatRoomByEntityQuery({
      entityRecordId: props.id,
      entityType: props.entityType,
    })

    const dispatch = useAppDispatch()
    // Sliceの操作を行うためのServiceを取得
    const chatTimelineService = useChatTimelineService(dispatch)
    const chatThreadListService = useChatThreadListService(dispatch)

    // チャットのトピック表示形式を取得する
    const currentDisplayFormat = useAppSelector(
      (state) => state.message.chat.current.displayFormat
    )

    // TODO: https://break-tmc.atlassian.net/browse/CREW-17853 の対応。
    //       URLにmessageIdが含まれる場合、そのメッセージにスクロール位置を合わせるとき、scrollToMessageIdやselectedMessageIdと処理が競合してしまい
    //       スクロール位置を合わせられなくなることから暫定対応として、リンククリック時に競合する値をリセットしてしまうことにする。
    //       下記タスクで恒久対応を行う際は、この処理は削除する。
    //       https://break-tmc.atlassian.net/browse/CREW-17941
    const handleLinkClick = useCallback(() => {
      // URLパラメータにmessageIdが含まれない場合や、対象のチャットルームが取得できない場合は処理を行わない
      if (!props.targetChatMessageId || !getChatRoomData?.chatRoom?.id) {
        return
      }
      if (currentDisplayFormat === ChatView.Timeline) {
        // タイムライン形式の場合
        // スクロール位置をりセット
        chatTimelineService.resetTimelineScrollToMessageId({
          chatRoomId: getChatRoomData.chatRoom.id,
        })
        chatTimelineService.resetTimelineSelectedMessageId({
          chatRoomId: getChatRoomData.chatRoom.id,
        })
      } else if (currentDisplayFormat === ChatView.ThreadList) {
        // スレッドリスト形式の場合
        // スクロール位置をりセット
        chatThreadListService.resetThreadListScrollToMessageId({
          chatRoomId: getChatRoomData.chatRoom.id,
        })
        chatThreadListService.resetThreadListSelectedMessageId({
          chatRoomId: getChatRoomData.chatRoom.id,
        })
      }
    }, [
      chatThreadListService,
      chatTimelineService,
      currentDisplayFormat,
      getChatRoomData?.chatRoom?.id,
      props.targetChatMessageId,
    ])

    return props.asText ? (
      <div>{data?.relatedItem?.name}</div>
    ) : renderLink ? (
      <CrewLink
        color={props.color}
        to={renderLink}
        title={data?.relatedItem?.name}
        className={classNames(props.className)}
        onClick={handleLinkClick}
      >
        {data?.relatedItem?.name}
      </CrewLink>
    ) : null
  }
)
