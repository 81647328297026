/* eslint-disable @typescript-eslint/no-redeclare */
import { AttentionDisplayGroup } from '@crew/enums/app'
import AlternateEmail from '~icons/material-symbols/alternate-email'
import BookmarkOutline from '~icons/material-symbols/bookmark-outline'
import BaselineEvent from '~icons/ic/baseline-event'
import ChatOutline from '~icons/material-symbols/chat-outline'
import KeyboardDoubleArrowDown from '~icons/material-symbols/keyboard-double-arrow-down'
import KeyboardDoubleArrowUp from '~icons/material-symbols/keyboard-double-arrow-up'
import KeyboardArrowDown from '~icons/material-symbols/keyboard-arrow-down'
import KeyboardArrowUp from '~icons/material-symbols/keyboard-arrow-up'
import EventAvailable from '~icons/material-symbols/event-available'
import BaselineQueryBuilder from '~icons/ic/baseline-query-builder'
import OutlineInsertDriveFile from '~icons/ic/outline-insert-drive-file'
import BaselineMinus from '~icons/ic/baseline-minus'
import GroupOutline from '~icons/material-symbols/group-outline'
import Subject from '~icons/material-symbols/subject'
import OutlinePhotoSizeSelectActual from '~icons/ic/outline-photo-size-select-actual'
import Groups from '~icons/material-symbols/groups'
import EmoticonHappyOutline from '~icons/mdi/emoticon-happy-outline'
import BaselineReply from '~icons/ic/baseline-reply'
import AccountClock from '~icons/mdi/account-clock'
import AccountTreeOutline from '~icons/material-symbols/account-tree-outline'
import ArchiveLine from '~icons/ri/archive-line'
import BaseOutlineVideocam from '~icons/ic/outline-videocam'
import North from '~icons/material-symbols/north'
import South from '~icons/material-symbols/south'
import ViewListOutLineSharp from '~icons/material-symbols/view-list-outline-sharp'
import ViewColumnOutlineSharp from '~icons/material-symbols/view-column-outline-sharp'
import BaselineToday from '~icons/ic/baseline-today'
import ViewHeadline from '~icons/material-symbols/view-headline'
import GanttChart24Regular from '~icons/fluent/gantt-chart-24-regular'
import ShowChart from '~icons/material-symbols/show-chart'
import GridViewOutline from '~icons/material-symbols/grid-view-outline'

/**
 *  イベントタイプ
 */
export const NotifyEventType = {
  Inserted: 'inserted',
  Updated: 'updated',
  Deleted: 'deleted',
} as const

export type NotifyEventType =
  (typeof NotifyEventType)[keyof typeof NotifyEventType]

// フィルタ条件（活動）
export const EventFilters = {
  AllEvents: 'allEvents',
  MyEvent: 'myEvent',
} as const

export type EventFilters = (typeof EventFilters)[keyof typeof EventFilters]

// type filter (event)
export const EventKindFilters = {
  AllEventKinds: 'allTypes',
} as const

export type EventKindFilters =
  (typeof EventKindFilters)[keyof typeof EventKindFilters]

// type filter (task)
export const TaskKindFilters = {
  AllTaskKinds: 'allTypes',
} as const

export type TaskKindFilters =
  (typeof TaskKindFilters)[keyof typeof TaskKindFilters]

// フィルタ条件（タスク）
export const TaskFilters = {
  UncompletedTasks: 'uncompletedTasks',
  MyTasks: 'myTasks',
  AllTasks: 'allTasks',
} as const

export type TaskFilters = (typeof TaskFilters)[keyof typeof TaskFilters]

export const ProjectFilters = {
  ParticipatingProjects: 'participatingProjects',
  MyProjects: 'myProjects',
  ActiveProjects: 'activeProjects',
  ArchivedProjects: 'archivedProjects',
} as const

export type ProjectFilters =
  (typeof ProjectFilters)[keyof typeof ProjectFilters]

// project scope
export const ProjectScope = {
  Public: {
    key: 'public',
    name: 'パブリック',
  },
  Private: {
    key: 'private',
    name: 'プライベート',
  },
} as const

export type ProjectScope = (typeof ProjectScope)[keyof typeof ProjectScope]

// フィルタ条件（タスク種別）
export const TimeFlag = {
  Newer: 'newer',
  Default: 'default',
  Older: 'older',
  Independent: 'independent',
} as const

export type TimeFlag = (typeof TimeFlag)[keyof typeof TimeFlag]

/**
 * 時間軸上での向き
 */
export const TimelineDirection = {
  /** より新しい方 */
  Newer: 'newer',
  /** より古い方 */
  Older: 'older',
  /** Both newer and orlder */
  BothNewerAndOlder: 'bothNewerAndOlder',
} as const
export type TimelineDirection =
  (typeof TimelineDirection)[keyof typeof TimelineDirection]

/**
 * Project progress list items
 */
export const ProjectProgressItems = {
  Completed: {
    text: 'completed',
    displayColor: 'bg-crew-blue-3-light dark:bg-crew-blue-3-dark',
  },
  Incomplete: {
    text: 'incomplete',
    displayColor: 'bg-crew-blue-2-light dark:bg-crew-blue-2-dark',
  },
  Overdue: {
    text: 'overdue',
    displayColor: 'bg-crew-red-3-light dark:bg-crew-red-3-dark',
  },
} as const
export type ProjectProgressItems =
  (typeof ProjectProgressItems)[keyof typeof ProjectProgressItems]

export const ProjectListTabs = {
  Chat: {
    text: 'label.chat',
    value: 'chat',
    icon: ChatOutline,
    isDefault: true,
  },
  Meeting: {
    text: 'label.meeting',
    value: 'meeting',
    icon: BaseOutlineVideocam,
    isDefault: false,
  },
  Task: {
    text: 'label.task',
    value: 'task',
    icon: EventAvailable,
    isDefault: false,
  },
  File: {
    text: 'label.file',
    value: 'file',
    icon: OutlineInsertDriveFile,
    isDefault: false,
  },
  Member: {
    text: 'label.member',
    value: 'member',
    icon: GroupOutline,
    isDefault: false,
  },
} as const
export type ProjectListTabs =
  (typeof ProjectListTabs)[keyof typeof ProjectListTabs]

export const DirectMessageTabs = {
  Chat: {
    text: 'label.chat',
    value: 'chat',
    icon: ChatOutline,
    isDefault: true,
  },
  File: {
    text: 'label.file',
    value: 'file',
    icon: OutlineInsertDriveFile,
    isDefault: false,
  },
} as const
export type DirectMessageTabs =
  (typeof DirectMessageTabs)[keyof typeof DirectMessageTabs]

export const AppTheme = {
  Light: 'light',
  Dark: 'dark',
} as const
export type AppTheme = (typeof AppTheme)[keyof typeof AppTheme]

export const AppLanguage = {
  En: 'en',
  Ja: 'ja',
} as const
export type AppLanguage = (typeof AppLanguage)[keyof typeof AppLanguage]

export const EventDetailTabs = {
  Detail: {
    text: 'label.detail',
    value: 'detail',
    icon: Subject,
    isDefault: true,
  },
  MeetingMinutes: {
    text: 'label.meetingMinutes',
    value: 'meetingMinutes',
    icon: ArchiveLine,
    isDefault: true,
  },
  Attendee: {
    text: 'label.attendee',
    value: 'attendee',
    icon: GroupOutline,
    isDefault: false,
  },
  Task: {
    text: 'label.task',
    value: 'task',
    icon: EventAvailable,
    isDefault: false,
  },
  File: {
    text: 'label.file',
    value: 'file',
    icon: OutlineInsertDriveFile,
    isDefault: false,
  },
} as const
export type EventDetailTabs =
  (typeof EventDetailTabs)[keyof typeof EventDetailTabs]

/**
 * Project setting tab list item
 */
export const ProjectSettingTabs = {
  AdvancedSetting: {
    text: 'label.advancedSetting',
    value: 'advancedSetting',
    isDefault: true,
  },
  EventKind: {
    text: 'label.meetingKind',
    value: 'eventKind',
    isDefault: false,
  },
  //   TODO: イベント分類タブは下記タスクで非表示としたため、現状未使用。
  // https://break-tmc.atlassian.net/browse/CREW-15044
  //   EventCategory: {
  //     text: 'label.eventCategory',
  //     value: 'eventCategory',
  //     isDefault: false,
  //   },
  TaskKind: {
    text: 'label.taskKind',
    value: 'taskKind',
    isDefault: false,
  },
  TaskCategory: {
    text: 'label.taskCategory',
    value: 'taskCategory',
    isDefault: false,
  },
  TaskStatus: {
    text: 'label.taskState',
    value: 'taskStatus',
    isDefault: false,
  },
} as const
export type ProjectSettingTabs =
  (typeof ProjectSettingTabs)[keyof typeof ProjectSettingTabs]

// 【テナント設定】タブ一覧
export const TenantSettingTabs = {
  Organization: {
    text: 'label.organization',
    value: 'organization',
    isDefault: true,
  },
  Authority: {
    text: 'label.authority',
    value: 'authority',
    isDefault: false,
  },
  User: {
    text: 'label.user',
    value: 'user',
    isDefault: false,
  },
  EventKind: {
    text: 'label.eventKind',
    value: 'eventKind',
    isDefault: false,
  },
  PresenceStatus: {
    text: 'label.presenceStatus',
    value: 'presenceStatus',
    isDefault: false,
  },
  ProjectGroup: {
    text: 'label.projectGroup',
    value: 'projectGroup',
    isDefault: false,
  },
} as const
export type TenantSettingTabs =
  (typeof TenantSettingTabs)[keyof typeof TenantSettingTabs]

// Personal setting tab list
export const PersonalSettingTabs = {
  Profile: {
    text: 'label.personalProfile',
    value: 'profile',
    isDefault: true,
  },
  Display: {
    text: 'label.personalDisplay',
    value: 'display',
    isDefault: false,
  },
  Notification: {
    text: 'label.notification',
    value: 'notification',
    isDefault: false,
  },
  Security: {
    text: 'label.security',
    value: 'security',
    isDefault: false,
  },
} as const
export type PersonalSettingTabs =
  (typeof PersonalSettingTabs)[keyof typeof PersonalSettingTabs]

export const TaskListOrderItems = {
  Id: {
    value: 'id',
    text: 'label.id',
  },
  Type: {
    value: 'taskKindName',
    text: 'label.classification',
  },
  Title: {
    value: 'subject',
    text: 'label.subject',
  },
  Priority: {
    value: 'taskPriority',
    text: 'label.priority',
  },
  Status: {
    value: 'taskStateDisplayOrder',
    text: 'label.status',
  },
  AssignedUser: {
    value: 'assignToUserName',
    text: 'label.assignedUser',
  },
  DueDate: {
    value: 'dueDate',
    text: 'label.dueDate',
  },
} as const

export type TaskListOrderItems =
  (typeof TaskListOrderItems)[keyof typeof TaskListOrderItems]

export const FileListOrderItems = {
  Name: {
    value: 'name',
    text: 'label.fileName',
  },
  Revision: {
    value: 'revision',
    text: 'label.version',
  },
  Size: {
    value: 'size',
    text: 'label.size',
  },
  UpdatedBy: {
    value: 'updatedBy',
    text: 'label.updatedBy',
  },
  LastUpdatedAttachmentAt: {
    value: 'lastUpdatedAttachmentAt',
    text: 'label.updateDatetime',
  },
} as const

export type FileListOrderItems =
  (typeof FileListOrderItems)[keyof typeof FileListOrderItems]

// プロジェクト一覧 詳細検索の並べ替え項目リスト
export const ProjectListOrderItems = {
  ProjectName: {
    value: 'subject',
    text: 'label.projectName',
  },
  Description: {
    value: 'description',
    text: 'label.description',
  },
  OwnerUser: {
    value: 'ownerUserName',
    text: 'label.owner',
  },
} as const

export type ProjectListOrderItems =
  (typeof ProjectListOrderItems)[keyof typeof ProjectListOrderItems]

// 昇順／降順
export const Sort = {
  Ascending: {
    id: 1,
    value: 'asc',
    text: 'label.ascending',
  },
  Descending: {
    id: 2,
    value: 'desc',
    text: 'label.descending',
  },
} as const

export type Sort = (typeof Sort)[keyof typeof Sort]

// タスク状態のタイプ一覧
// NOTE: chooseableは選択可能判定用。（設定ダイアログでは未着手・完了は選択不可）
export const TaskStateTypes = {
  // 未着手
  NotStarted: {
    value: 'notStarted',
    chooseable: false,
  },
  // 作業中
  InProgress: {
    value: 'inProgress',
    chooseable: true,
  },
  // レビュー中
  InReview: {
    value: 'inReview',
    chooseable: true,
  },
  // 完了
  Completed: {
    value: 'completed',
    chooseable: false,
  },
} as const
export type TaskStateTypes =
  (typeof TaskStateTypes)[keyof typeof TaskStateTypes]

// filter scope
export const FilterScope = {
  Public: {
    key: 'public',
    name: 'label.public',
  },
  Private: {
    key: 'private',
    name: 'label.private',
  },
} as const

export type FilterScope = (typeof FilterScope)[keyof typeof FilterScope]

// タスク優先度
export const TaskPriorities = {
  Highest: {
    value: 5,
    text: 'label.highest',
    textColorClass: 'crew-badge-red',
    iconColorClass: 'crew-badge-icon-red',
    iconClass: KeyboardDoubleArrowUp,
  },
  High: {
    value: 4,
    text: 'label.high',
    textColorClass: 'crew-badge-red',
    iconColorClass: 'crew-badge-icon-red',
    iconClass: KeyboardArrowUp,
  },
  Normal: {
    value: 3,
    text: 'label.normal',
    textColorClass: 'crew-badge-green',
    iconColorClass: 'crew-badge-icon-green',
    iconClass: BaselineMinus,
  },
  Low: {
    value: 2,
    text: 'label.low',
    textColorClass: 'crew-badge-blue',
    iconColorClass: 'crew-badge-icon-blue',
    iconClass: KeyboardArrowDown,
  },
  Lowest: {
    value: 1,
    text: 'label.lowest',
    textColorClass: 'crew-badge-blue',
    iconColorClass: 'crew-badge-icon-blue',
    iconClass: KeyboardDoubleArrowDown,
  },
} as const
export type TaskPriorities =
  (typeof TaskPriorities)[keyof typeof TaskPriorities]

// ホームメニュー
export const HomeMenuItems = {
  // TODO: ダッシュボードが実装される際にコメントアウトを解除
  // ダッシュボード
  // Dashboard: {
  //   path: 'dashboard',
  //   label: 'label.dashboard',
  //   icon: 'dx-icon-mediumiconslayout',
  //   showRightPanel: false,
  // },
  // メンションと返信
  MentionAndReply: {
    path: 'mentionAndReply',
    label: 'label.mentionAndReply',
    icon: AlternateEmail,
    showRightPanel: true,
    value: 'mentionAndReply',
  },
  // 新着
  NewArrival: {
    path: 'newArrival',
    label: 'label.newArrival',
    icon: BaselineQueryBuilder,
    showRightPanel: true,
    value: 'newArrival',
  },
  // ブックマーク
  Bookmark: {
    path: 'bookmark',
    label: 'label.bookmark',
    icon: BookmarkOutline,
    showRightPanel: true,
    value: 'bookmark',
  },
  // スケジュール
  Schedule: {
    path: 'schedule',
    label: 'label.schedule',
    icon: BaselineEvent,
    showRightPanel: false,
    value: 'schedule',
  },
  // タスク
  MyTask: {
    path: 'myTask',
    label: 'label.myTask',
    icon: EventAvailable,
    showRightPanel: false,
    value: 'myTask',
  },
  // 作業時間
  WorkingTime: {
    path: 'workingTime',
    label: 'label.workingTime',
    icon: AccountClock,
    showRightPanel: false,
    value: 'workingTime',
  },
} as const
export type HomeMenuItems = (typeof HomeMenuItems)[keyof typeof HomeMenuItems]

// メンションと返信画面のタブ
export const AttentionTabType = {
  // 全て
  All: {
    text: 'label.all',
    value: AttentionDisplayGroup.All,
    icon: undefined,
    isDefault: true,
  },
  // メンション
  Mention: {
    text: 'label.mention',
    value: AttentionDisplayGroup.Mention,
    icon: AlternateEmail,
    isDefault: false,
  },
  // 返信
  Reply: {
    text: 'label.reply',
    value: AttentionDisplayGroup.Reply,
    icon: BaselineReply,
    isDefault: false,
  },
  // リアクション
  Reaction: {
    text: 'label.reaction',
    value: AttentionDisplayGroup.Reaction,
    icon: EmoticonHappyOutline,
    isDefault: false,
  },
} as const
export type AttentionTabType =
  (typeof AttentionTabType)[keyof typeof AttentionTabType]

export type ContextMenuItem = {
  action: string
  label: string
}

export const isContextMenuItem = (item: any): item is ContextMenuItem => {
  return (
    Boolean(item) &&
    (item as ContextMenuItem).action !== undefined &&
    (item as ContextMenuItem).label !== undefined
  )
}

// コンテキストメニューの編集 or 削除
export const ContextMenuItems = {
  ReadStatus: {
    action: 'readStatus',
    label: 'label.readStatus',
  },
  Edit: {
    action: 'edit',
    label: 'action.edit',
  },
  Delete: {
    action: 'delete',
    label: 'action.delete',
  },
  Copy: {
    action: 'copy',
    label: 'action.copyLink',
  },
} as const satisfies { [key: string]: ContextMenuItem }

export type ContextMenuItems =
  (typeof ContextMenuItems)[keyof typeof ContextMenuItems]

export const TaskDetailListTabs = {
  Comment: {
    text: 'label.comment',
    value: 'comment',
    icon: ChatOutline,
    isDefault: true,
  },
  File: {
    text: 'label.file',
    value: 'file',
    icon: OutlineInsertDriveFile,
    isDefault: false,
  },
  Dependency: {
    text: 'label.dependencies',
    value: 'dependency',
    icon: AccountTreeOutline,
    isDefault: false,
  },
} as const
export type TaskDetailListTabs =
  (typeof TaskDetailListTabs)[keyof typeof TaskDetailListTabs]

export const CommentButtonGroup = {
  CommentAndHistory: {
    value: 'commentsAndHistories',
    text: 'label.commentAndHistory',
    displayUrlText: 'commentAndHistory',
  },
  Comment: {
    value: 'comments',
    text: 'label.comment',
    displayUrlText: 'comment',
  },
  History: {
    value: 'histories',
    text: 'label.history',
    displayUrlText: 'history',
  },
  Time: {
    value: 'time',
    text: 'label.time',
    displayUrlText: 'time',
  },
} as const

export type CommentButtonGroup =
  (typeof CommentButtonGroup)[keyof typeof CommentButtonGroup]

// project scope
export const SortCommentButtonGroup = {
  Ascending: {
    value: 'asc',
    icon: North,
  },
  Descending: {
    value: 'desc',
    icon: South,
  },
} as const

export type SortCommentButtonGroup =
  (typeof SortCommentButtonGroup)[keyof typeof SortCommentButtonGroup]

export const TaskDisplayModeButtonGroup = {
  List: {
    id: 0,
    icon: ViewHeadline,
  },
  Kanban: {
    id: 1,
    icon: ViewColumnOutlineSharp,
  },
  Calendar: {
    id: 2,
    icon: BaselineToday,
  },
  Timeline: {
    id: 3,
    icon: ViewListOutLineSharp,
  },
  Gantt: {
    id: 4,
    icon: GanttChart24Regular,
  },
  Evm: {
    id: 5,
    icon: ShowChart,
  },
} as const

export type TaskDisplayModeButtonGroup =
  (typeof TaskDisplayModeButtonGroup)[keyof typeof TaskDisplayModeButtonGroup]

export const ProjectListPanelDisplayMode = {
  List: {
    id: 0,
    icon: ViewHeadline,
  },
  Card: {
    id: 1,
    icon: GridViewOutline,
  },
} as const

export type ProjectListPanelDisplayMode =
  (typeof ProjectListPanelDisplayMode)[keyof typeof ProjectListPanelDisplayMode]

export const TaskCommentFilter = {
  Comments: 'comments',
  Histories: 'histories',
  CommentsAndHistories: 'commentsAndHistories',
} as const

export type TaskCommentFilter =
  (typeof TaskCommentFilter)[keyof typeof TaskCommentFilter]

// 右パネルのタブ
export const AppRightPanelTabs = {
  Chat: {
    text: 'label.chat',
    value: 'chat',
    icon: ChatOutline,
  },
  // comment out when implement related tab
  // Related: {
  //   text: 'label.related',
  //   value: 'related',
  //   icon: mdiLinkVariant,
  // },
} as const
export type AppRightPanelTabs =
  (typeof AppRightPanelTabs)[keyof typeof AppRightPanelTabs]

export const FileFilters = {
  MyFiles: 'myFiles',
  AllFiles: 'allFiles',
} as const

export type FileFilters = (typeof FileFilters)[keyof typeof FileFilters]
export const FileDetailListTabs = {
  UpdatedHistory: {
    text: 'label.updatedHistory',
    value: 'updatedHistory',
    icon: BaselineQueryBuilder,
    isDefault: true,
  },
  Preview: {
    text: 'label.preview',
    value: 'preview',
    icon: OutlinePhotoSizeSelectActual,
    isDefault: false,
  },
} as const
export type FileDetailListTabs =
  (typeof FileDetailListTabs)[keyof typeof FileDetailListTabs]

export const HTTPStatusText = {
  Conflict: 'Conflict',
} as const

export type HTTPStatusText =
  (typeof HTTPStatusText)[keyof typeof HTTPStatusText]

export const ButtonType = {
  Action: 'action',
  Normal: 'normal',
  More: 'more',
} as const
export type ButtonType = (typeof ButtonType)[keyof typeof ButtonType]

export const EventDetailAttendances = {
  Attendance: {
    text: 'label.attendance',
    value: 'attendance',
    colorClass: 'crew-badge-green',
  },
  Absence: {
    text: 'label.absence',
    value: 'absence',
    colorClass: 'crew-badge-red',
  },
}
export type EventDetailAttendances =
  (typeof EventDetailAttendances)[keyof typeof EventDetailAttendances]

export const ProjectMemberEntryDialogTabs = {
  ExistingUsers: {
    id: 0,
    text: 'label.existingUsers',
  },
  NewUsers: {
    id: 1,
    text: 'label.newUsers',
  },
} as const
export type ProjectMemberEntryDialogTabs =
  (typeof ProjectMemberEntryDialogTabs)[keyof typeof ProjectMemberEntryDialogTabs]

export const AccountSignupPage = {
  SignupExistingAccount: 'signupExistingAccount',
  SignupNewAccount: 'signupNewAccount',
  SignupError: 'signupError',
} as const
export type AccountSignupPage =
  (typeof AccountSignupPage)[keyof typeof AccountSignupPage]

export const ChatMessageMention = {
  Me: 'me',
  All: 'all',
  Here: 'here',
  Other: 'other',
}
export type ChatMessageMention =
  (typeof ChatMessageMention)[keyof typeof ChatMessageMention]

/**
 *  Type tenant setting user state
 */
export const TenantSettingUserStateType = {
  allStates: { value: 'allTenantMembers', text: 'label.allState' },
  Enabled: { value: 'enabledTenantMembers', text: 'label.enabled' },
  Disabled: { value: 'disabledTenantMembers', text: 'label.disabled' },
  Inviting: { value: 'invitingTenantMembers', text: 'label.inviting' },
}

export type TenantSettingUserStateType =
  (typeof TenantSettingUserStateType)[keyof typeof TenantSettingUserStateType]

export const UserFilters = {
  AllUsers: 'allUsers',
  InternalUsers: 'internalUsers',
  ExternalUsers: 'externalUsers',
} as const

export type UserFilters = (typeof UserFilters)[keyof typeof UserFilters]

//user chat setting display format
export const UserChatSettingDisplayFormat = {
  Timeline: {
    value: 'timeline',
    text: 'label.timelineDisplay',
  },
  Thread: {
    value: 'thread',
    text: 'label.threadDisplay',
  },
}
export type UserChatSettingDisplayFormat =
  (typeof UserChatSettingDisplayFormat)[keyof typeof UserChatSettingDisplayFormat]

//user chat setting display range
export const UserChatSettingDisplayRange = {
  DisplayAll: {
    value: 'displayAll',
    text: 'label.displayAll',
  },
  LayerOnly: {
    value: 'layerOnly',
    text: 'label.thisLayerOnly',
  },
}

export const NotificationSettingValues = {
  Banner: {
    text: 'label.banner',
    value: 'banner',
  },
  Email: {
    text: 'label.email',
    value: 'email',
  },
  BannerAndEmail: {
    text: 'label.bannerAndEmail',
    value: 'bannerAndEmail',
  },
  None: {
    text: 'label.none',
    value: 'none',
  },
} as const
export type NotificationSettingValues =
  (typeof NotificationSettingValues)[keyof typeof NotificationSettingValues]

export const AddExternalUserOptions = {
  Allow: {
    text: 'label.allow',
    value: 'allow',
  },
  Disallow: {
    text: 'label.disallow',
    value: 'disallow',
  },
} as const
export type AddExternalUserOptions =
  (typeof AddExternalUserOptions)[keyof typeof AddExternalUserOptions]

export const TwoFactorAuthenticationType = {
  App: {
    text: 'label.app',
    value: 'app',
  },
  Email: {
    text: 'label.email',
    value: 'email',
  },
  Sms: {
    text: 'label.sms',
    value: 'sms',
  },
} as const
export type TwoFactorAuthenticationType =
  (typeof TwoFactorAuthenticationType)[keyof typeof TwoFactorAuthenticationType]

// Organization role
export const OrganizationRole = {
  System: {
    key: 'systemRole',
    name: 'label.systemRole',
  },
  Project: {
    key: 'projectRole',
    name: 'label.projectRole',
  },
} as const

export type OrganizationRole =
  (typeof OrganizationRole)[keyof typeof OrganizationRole]

export const ProjectMemberState = {
  allStates: { value: 'allStates', text: 'label.allState' },
  Joined: { value: 'joined', text: 'label.joined' },
  Inviting: { value: 'inviting', text: 'label.inviting' },
  waitingApproval: { value: 'waitingApproval', text: 'label.waitingApproval' },
}

export type ProjectMemberState =
  (typeof ProjectMemberState)[keyof typeof ProjectMemberState]

// Set region code for the `value` of each item.
// You can get the code of region at https://github.com/ttacon/libphonenumber/blob/master/countrycodetoregionmap.go
// 「github.com/nyaruka/phonenumbers」ライブラリの地域へ参照する値のため、キャメルケースに従う必要はない
export const NationPhoneCode = {
  Japan: {
    text: 'label.japanPhoneCode',
    value: 'JP',
  },
  Vietnam: {
    text: 'label.vietnamPhoneCode',
    value: 'VN',
  },
}
export type NationPhoneCode =
  (typeof NationPhoneCode)[keyof typeof NationPhoneCode]

export const PlanId = {
  Starter: 'starter',
  Standard: 'standard',
}
export type PlanId = (typeof PlanId)[keyof typeof PlanId]

export const AttendeeMenuItems = {
  TurnOffAudio: {
    text: 'action.turnOffAudio',
    value: 'turnOffAudio',
  },
  TurnOffVideo: {
    text: 'action.turnOffVideo',
    value: 'turnOffVideo',
  },
  AppointAsMeetingHost: {
    text: 'action.appointAsMeetingHost',
    value: 'appointAsMeetingHost',
  },
  DismissAsMeetingHost: {
    text: 'action.dismissAsMeetingHost',
    value: 'dismissAsMeetingHost',
  },
  RemoveFromMeeting: {
    text: 'action.removeFromMeeting',
    value: 'removeFromMeeting',
  },
}
export type AttendeeMenuItems =
  (typeof AttendeeMenuItems)[keyof typeof AttendeeMenuItems]

// RightSideBarの表示種類
export const RightSideBarDisplayType = {
  Feed: 'feed',
  Attention: 'attention',
  Bookmark: 'bookmark',
  ProjectDetail: 'projectDetail',
  EventDetail: 'eventDetail',
  TaskDetail: 'taskDetail',
  FileDetail: 'fileDetail',
  DirectChannel: 'directChannel',
  CrossSearchChat: 'crossSearchChat',
} as const
export type RightSideBarDisplayType =
  (typeof RightSideBarDisplayType)[keyof typeof RightSideBarDisplayType]

// タスク一覧項目名（ソート用）
export const CrewTaskListItemName = {
  ReferenceNo: 'referenceNo', // タスクID
  // FIXME: タスク一覧の関連先ソートをどうするか検討
  //        https://break-tmc.atlassian.net/browse/CREW-6824
  RelatedItem: 'entityType', // 関連先
  TaskKind: 'taskKindName', // 種別
  Subject: 'subject', // 件名
  TaskPriority: 'taskPriority', // 優先度
  TaskState: 'taskStateDisplayOrder', // 状態
  TaskCategory: 'taskCategoryName', // 分類
  AssignToUser: 'assignToUserName', // 担当者
  StartDate: 'startDate', // 開始日
  DueDate: 'dueDate', // 期限
} as const
export type CrewTaskListItemName =
  (typeof CrewTaskListItemName)[keyof typeof CrewTaskListItemName]

export const UnsortedFolder = {
  value: 'unsorted',
  text: 'label.unsorted',
}

export type UnsortedFolder =
  (typeof UnsortedFolder)[keyof typeof UnsortedFolder]

export const DisplayAnonymousFile = {
  Display: {
    text: 'label.show',
    value: 'show',
  },
  DoNotDisplay: {
    text: 'label.doNotDisplay',
    value: 'doNotDisplay',
  },
} as const
export type DisplayAnonymousFile =
  (typeof DisplayAnonymousFile)[keyof typeof DisplayAnonymousFile]

// Task search options
export const TaskSearchOptions = {
  Keyword: {
    id: 'keyword',
    name: 'keyword',
    label: 'label.keyword',
    defaultValue: undefined,
    defaultShown: true,
  },
  TaskKindId: {
    id: 'taskKindId',
    name: 'taskKindId',
    label: 'label.taskKind',
    defaultValue: [],
    defaultShown: false,
  },
  AssignToUser: {
    id: 'assignToUser',
    name: 'assignToUser',
    label: 'label.assignedUser',
    defaultValue: undefined,
    defaultShown: false,
  },
  TaskStateId: {
    id: 'taskStateId',
    name: 'taskStateId',
    label: 'label.state',
    defaultValue: [],
    defaultShown: false,
  },
  TaskStateType: {
    id: 'taskStateType',
    name: 'taskStateType',
    label: 'label.statusType',
    defaultValue: [],
    defaultShown: false,
  },
  TaskPriority: {
    id: 'taskPriority',
    name: 'taskPriority',
    label: 'label.priority',
    defaultValue: [],
    defaultShown: false,
  },
  TaskCategoryId: {
    id: 'taskCategoryId',
    name: 'taskCategoryId',
    label: 'label.category',
    defaultValue: [],
    defaultShown: false,
  },
  ProjectId: {
    id: 'projectId',
    name: 'projectId',
    label: 'label.project',
    defaultValue: undefined,
    defaultShown: false,
  },
  ProjectGroupId: {
    id: 'projectGroupId',
    name: 'projectGroupId',
    label: 'label.projectGroup',
    defaultValue: [],
    defaultShown: false,
  },
  StartDate: {
    id: 'startDate',
    name: 'startDate',
    label: 'label.startDate',
    defaultValue: 'thatDay',
    defaultShown: false,
  },
  DueDate: {
    id: 'dueDate',
    name: 'dueDate',
    label: 'label.dueDate',
    defaultValue: 'thatDay',
    defaultShown: false,
  },
  CreatedById: {
    id: 'createdById',
    name: 'createdById',
    label: 'label.createdBy',
    defaultValue: undefined,
    defaultShown: false,
  },
  UpdatedById: {
    id: 'updatedById',
    name: 'updatedById',
    label: 'label.updatedBy',
    defaultValue: undefined,
    defaultShown: false,
  },
  CreatedAt: {
    id: 'createdAt',
    name: 'createdAt',
    label: 'label.registrationDate',
    defaultValue: 'thatDay',
    defaultShown: false,
  },
  UpdatedAt: {
    id: 'updatedAt',
    name: 'updatedAt',
    label: 'label.rolloverDate',
    defaultValue: 'thatDay',
    defaultShown: false,
  },
} as const
export type TaskSearchOptions =
  (typeof TaskSearchOptions)[keyof typeof TaskSearchOptions]

// Project search options
export const ProjectSearchOptions = {
  Keyword: {
    id: 'keyword',
    name: 'keyword',
    label: 'label.keyword',
    defaultValue: undefined,
    defaultShown: true,
  },
  JoiningProjectStatus: {
    id: 'joiningProjectStatus',
    name: 'joiningProjectStatus',
    label: 'label.joiningProjectStatus.joiningStatus',
    defaultValue: [],
    defaultShown: false,
  },
  ArchivedStatus: {
    id: 'archivedStatus',
    name: 'archivedStatus',
    label: 'label.archivedProjectStatus.archivedStatus',
    defaultValue: [],
    defaultShown: false,
  },
  ProjectGroupId: {
    id: 'projectGroupId',
    name: 'projectGroupId',
    label: 'label.projectGroup',
    defaultValue: [],
    defaultShown: false,
  },
  OwnerUser: {
    id: 'ownerUser',
    name: 'ownerUser',
    label: 'label.owner',
    defaultValue: undefined,
    defaultShown: false,
  },
  CreatedAt: {
    id: 'createdAt',
    name: 'createdAt',
    label: 'label.registrationDate',
    defaultValue: 'thatDay',
    defaultShown: false,
  },
  UpdatedAt: {
    id: 'updatedAt',
    name: 'updatedAt',
    label: 'label.rolloverDate',
    defaultValue: 'thatDay',
    defaultShown: false,
  },
} as const
export type ProjectSearchOptions =
  (typeof ProjectSearchOptions)[keyof typeof ProjectSearchOptions]

// Archived status
export const ArchivedStatus = {
  Archived: {
    text: 'label.archivedProjectStatus.archived',
    value: 'archived',
  },
  Unarchive: {
    text: 'label.archivedProjectStatus.unarchive',
    value: 'unarchive',
  },
}
export type ArchivedStatus =
  (typeof ArchivedStatus)[keyof typeof ArchivedStatus]

// Joining project status
export const JoiningProjectStatus = {
  Joined: {
    text: 'label.joiningProjectStatus.joined',
    value: 'joined',
  },
  NotJoin: {
    text: 'label.joiningProjectStatus.notJoin',
    value: 'notJoin',
  },
}
export type JoiningProjectStatus =
  (typeof JoiningProjectStatus)[keyof typeof JoiningProjectStatus]

// Event search options
export const EventSearchOptions = {
  Keyword: {
    id: 'keyword',
    name: 'keyword',
    label: 'label.keyword',
    defaultValue: undefined,
    defaultShown: true,
  },
  AttendeeId: {
    id: 'attendeeId',
    name: 'attendeeId',
    label: 'label.attendee',
    defaultValue: undefined,
    defaultShown: false,
  },
  EventKindId: {
    id: 'eventKindId',
    name: 'eventKindId',
    label: 'label.meetingKind',
    defaultValue: [],
    defaultShown: false,
  },
  // TODO: Web: イベント分類の非表示対応
  // https://break-tmc.atlassian.net/browse/CREW-15049
  // EventCategoryId: {
  //   id: 'eventCategoryId',
  //   name: 'eventCategoryId',
  //   label: 'label.eventCategory',
  //   defaultValue: [],
  //   defaultShown: false,
  // },
  StartDate: {
    id: 'startDate',
    name: 'startDate',
    label: 'label.startDate',
    defaultValue: 'thatDay',
    defaultShown: false,
  },
  EndDate: {
    id: 'endDate',
    name: 'endDate',
    label: 'label.endDate',
    defaultValue: 'thatDay',
    defaultShown: false,
  },
  CreatedById: {
    id: 'createdById',
    name: 'createdById',
    label: 'label.createdBy',
    defaultValue: undefined,
    defaultShown: false,
  },
  UpdatedById: {
    id: 'updatedById',
    name: 'updatedById',
    label: 'label.updatedBy',
    defaultValue: undefined,
    defaultShown: false,
  },
  CreatedAt: {
    id: 'createdAt',
    name: 'createdAt',
    label: 'label.registrationDate',
    defaultValue: 'thatDay',
    defaultShown: false,
  },
  UpdatedAt: {
    id: 'updatedAt',
    name: 'updatedAt',
    label: 'label.rolloverDate',
    defaultValue: 'thatDay',
    defaultShown: false,
  },
} as const
export type EventSearchOptions =
  (typeof EventSearchOptions)[keyof typeof EventSearchOptions]

// File search options
export const FileSearchOptions = {
  Keyword: {
    id: 'keyword',
    name: 'keyword',
    label: 'label.keyword',
    defaultValue: undefined,
    defaultShown: true,
  },
  AnonymousFile: {
    id: 'anonymousFile',
    name: 'anonymousFile',
    label: 'label.anonymousFile',
    defaultValue: DisplayAnonymousFile.DoNotDisplay.value,
    defaultShown: true,
  },
  Tag: {
    id: 'tag',
    name: 'tag',
    label: 'label.tag',
    defaultValue: [],
    defaultShown: false,
  },
  ProjectId: {
    id: 'projectId',
    name: 'projectId',
    label: 'label.project',
    defaultValue: undefined,
    defaultShown: false,
  },
  ProjectGroupId: {
    id: 'projectGroupId',
    name: 'projectGroupId',
    label: 'label.projectGroup',
    defaultValue: undefined,
    defaultShown: false,
  },
  CreatedById: {
    id: 'createdById',
    name: 'createdById',
    label: 'label.createdBy',
    defaultValue: undefined,
    defaultShown: false,
  },
  UpdatedById: {
    id: 'updatedById',
    name: 'updatedById',
    label: 'label.updatedBy',
    defaultValue: undefined,
    defaultShown: false,
  },
  CreatedAt: {
    id: 'createdAt',
    name: 'createdAt',
    label: 'label.registrationDate',
    defaultValue: 'thatDay',
    defaultShown: false,
  },
  UpdatedAt: {
    id: 'updatedAt',
    name: 'updatedAt',
    label: 'label.rolloverDate',
    defaultValue: 'thatDay',
    defaultShown: false,
  },
} as const
export type FileSearchOptions =
  (typeof FileSearchOptions)[keyof typeof FileSearchOptions]

// Detail task search options
export const DetailTaskSearchOptions = {
  Keyword: {
    id: 'keyword',
    name: 'keyword',
    label: 'label.keyword',
    defaultValue: undefined,
    defaultShown: true,
  },
  TaskKindId: {
    id: 'taskKindId',
    name: 'taskKindId',
    label: 'label.taskKind',
    defaultValue: [],
    defaultShown: false,
  },
  AssignToUser: {
    id: 'assignToUser',
    name: 'assignToUser',
    label: 'label.assignedUser',
    defaultValue: undefined,
    defaultShown: false,
  },
  TaskStateId: {
    id: 'taskStateId',
    name: 'taskStateId',
    label: 'label.state',
    defaultValue: [],
    defaultShown: false,
  },
  TaskStateType: {
    id: 'taskStateType',
    name: 'taskStateType',
    label: 'label.statusType',
    defaultValue: [],
    defaultShown: false,
  },
  TaskPriority: {
    id: 'taskPriority',
    name: 'taskPriority',
    label: 'label.priority',
    defaultValue: [],
    defaultShown: false,
  },
  DueDate: {
    id: 'dueDate',
    name: 'dueDate',
    label: 'label.dueDate',
    defaultValue: 'thatDay',
    defaultShown: false,
  },
  TaskCategoryId: {
    id: 'taskCategoryId',
    name: 'taskCategoryId',
    label: 'label.category',
    defaultValue: [],
    defaultShown: false,
  },
  StartDate: {
    id: 'startDate',
    name: 'startDate',
    label: 'label.startDate',
    defaultValue: 'thatDay',
    defaultShown: false,
  },
  CreatedById: {
    id: 'createdById',
    name: 'createdById',
    label: 'label.createdBy',
    defaultValue: undefined,
    defaultShown: false,
  },
  UpdatedById: {
    id: 'updatedById',
    name: 'updatedById',
    label: 'label.updatedBy',
    defaultValue: undefined,
    defaultShown: false,
  },
  CreatedAt: {
    id: 'createdAt',
    name: 'createdAt',
    label: 'label.registrationDate',
    defaultValue: 'thatDay',
    defaultShown: false,
  },
  UpdatedAt: {
    id: 'updatedAt',
    name: 'updatedAt',
    label: 'label.rolloverDate',
    defaultValue: 'thatDay',
    defaultShown: false,
  },
} as const
export type DetailTaskSearchOptions =
  (typeof DetailTaskSearchOptions)[keyof typeof DetailTaskSearchOptions]

// Detail file search options
export const DetailFileSearchOptions = {
  Keyword: {
    id: 'keyword',
    name: 'keyword',
    label: 'label.keyword',
    defaultValue: undefined,
    defaultShown: true,
  },
  Tag: {
    id: 'tag',
    name: 'tag',
    label: 'label.tag',
    defaultValue: [],
    defaultShown: false,
  },
  CreatedById: {
    id: 'createdById',
    name: 'createdById',
    label: 'label.createdBy',
    defaultValue: undefined,
    defaultShown: false,
  },
  UpdatedById: {
    id: 'updatedById',
    name: 'updatedById',
    label: 'label.updatedBy',
    defaultValue: undefined,
    defaultShown: false,
  },
  CreatedAt: {
    id: 'createdAt',
    name: 'createdAt',
    label: 'label.registrationDate',
    defaultValue: 'thatDay',
    defaultShown: false,
  },
  UpdatedAt: {
    id: 'updatedAt',
    name: 'updatedAt',
    label: 'label.rolloverDate',
    defaultValue: 'thatDay',
    defaultShown: false,
  },
  AnonymousFile: {
    id: 'anonymousFile',
    name: 'anonymousFile',
    label: 'label.anonymousFile',
    defaultValue: DisplayAnonymousFile.DoNotDisplay.value,
    defaultShown: true,
  },
  Folder: {
    id: 'folder',
    name: 'folder',
    label: 'label.folder',
    defaultValue: undefined,
    defaultShown: false,
  },
} as const
export type DetailFileSearchOptions =
  (typeof DetailFileSearchOptions)[keyof typeof DetailFileSearchOptions]

export const SearchTabs = {
  Chat: {
    text: 'label.chat',
    value: 'chat',
    icon: ChatOutline,
    isDefault: true,
  },
  Event: {
    text: 'label.meeting',
    value: 'event',
    icon: BaseOutlineVideocam,
    isDefault: false,
  },
  Task: {
    text: 'label.task',
    value: 'task',
    icon: EventAvailable,
    isDefault: false,
  },
  File: {
    text: 'label.file',
    value: 'file',
    icon: OutlineInsertDriveFile,
    isDefault: false,
  },
  Project: {
    text: 'label.project',
    value: 'project',
    icon: Groups,
    isDefault: false,
  },
} as const
export type SearchTabs = (typeof SearchTabs)[keyof typeof SearchTabs]

export const AttendeeViewStyles = {
  displayOnTheBottom: {
    text: 'label.displayOnTheBottom',
    value: 'displayOnTheBottom',
  },
  displayOnTheRight: {
    text: 'label.displayOnTheRight',
    value: 'displayOnTheRight',
  },
  doNotDisplay: {
    text: 'label.doNotDisplay',
    value: 'doNotDisplay',
  },
}
export type AttendeeViewStyles =
  (typeof AttendeeViewStyles)[keyof typeof AttendeeViewStyles]

export const PaymentMethod = {
  creditCard: {
    value: 'creditCard',
    text: 'label.creditCard',
  },
  bankTransfer: {
    value: 'bankTransfer',
    text: 'label.bankTransfer',
  },
}
export type PaymentMethod = (typeof PaymentMethod)[keyof typeof PaymentMethod]

export const FolderMenuContextItems = {
  CreateSubFolder: {
    text: 'action.createSubFolder',
    value: 'createSubFolder',
  },
  EditFolder: {
    text: 'action.editFolder',
    value: 'editFolder',
  },
  DeleteFolder: {
    text: 'action.deleteFolder',
    value: 'deleteFolder',
  },
}

export type FolderMenuContextItems =
  (typeof FolderMenuContextItems)[keyof typeof FolderMenuContextItems]

export const isFolderContextMenuItem = (
  item: any
): item is FolderMenuContextItems => {
  return (
    Boolean(item) &&
    (item as FolderMenuContextItems).value !== undefined &&
    (item as FolderMenuContextItems).text !== undefined
  )
}

export const FileListDisplayMode = {
  List: {
    id: 0,
    icon: ViewHeadline,
  },
  Grid: {
    id: 1,
    icon: GridViewOutline,
  },
} as const

export type FileListDisplayMode =
  (typeof FileListDisplayMode)[keyof typeof FileListDisplayMode]

export const ProjectDetailEventListDisplayMode = {
  Calendar: {
    id: 0,
    icon: BaselineToday,
  },
  List: {
    id: 1,
    icon: ViewHeadline,
  },
} as const

export type ProjectDetailEventListDisplayMode =
  (typeof ProjectDetailEventListDisplayMode)[keyof typeof ProjectDetailEventListDisplayMode]

// 左上の選択ドロップダウンは「月間」と「週間」
export const TimePeriod = {
  Weekly: {
    value: 'weekly',
    text: 'label.weekly',
  },
  Monthly: {
    value: 'monthly',
    text: 'label.monthly',
  },
} as const

export type TimePeriod = (typeof TimePeriod)[keyof typeof TimePeriod]

export const TaskContextMenuItems = {
  Detail: {
    action: 'detail',
    label: 'action.detail',
  },
  Edit: {
    action: 'edit',
    label: 'action.edit',
  },
} as const satisfies { [key: string]: ContextMenuItem }

export const ProjectDetailEventTimePeriod = {
  Weekly: {
    value: 'weekly',
    text: 'label.weekly',
  },
  Monthly: {
    value: 'monthly',
    text: 'label.monthly',
  },
  GroupMonthly: {
    value: 'groupMonthly',
    text: 'label.groupMonthly',
  },
} as const
