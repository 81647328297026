import { useCallback, useMemo } from 'react'

import { ChatMessage } from '@crew/apis/chat/models/getChatRoomMessages/response'
import { AttentionDisplayGroup, TimelineDirection } from '@crew/enums/app'
import { AttentionType } from '@crew/enums/domain'
import {
  Attention,
  useAttentionService,
  useChatMessageService,
} from '@crew/states'

import { useAppDispatch, useAppSelector } from 'states/hooks'

import { useLazyGetAttentionsQuery } from '@crew/apis/attention/attentionApis'
import { AttentionItem } from '@crew/apis/attention/models/getAttentions/response'
import { GetAttentionsRequest } from '@crew/apis/attention/models/getAttentions/request'
import { MESSAGE_AREA_MIN_HEIGHT } from '@crew/configs/constants'
import { useFetchLimit } from '@crew/hooks'
import { getWindowDimensions } from 'utils'

// メッセージアイテムの型
export type DisplayMessageItem = Attention.AttentionInfo & {
  id: string // メッセージIDと表示形式を組み合わせたID
  handleAdditionalLoading: (() => void) | undefined // 追加読込処理
}

// アテンションメッセージアイテム生成用のparameterの型
// XXがメンションしました　というメッセージを表示させるためにactionUserとattentionTypeの情報を持たせる
type ChatMessageAndAttentionInfo = ChatMessage & Attention.AttentionInfo

// AttentionItemをChatMessageAndAttentionInfoに変換する関数
const convertAttentionItemToChatMessageAndAttentionInfo = (
  attentionItem: AttentionItem
): ChatMessageAndAttentionInfo => {
  return {
    ...attentionItem.chatMessage,
    id: attentionItem.entityRecordId,
    chatMessageId: attentionItem.chatMessage.id,
    attentionUserName: attentionItem.actionUser.displayName,
    attentionType: attentionItem.attentionType,
  }
}

/**
 * 複数メッセージ追加用の型
 */
type AddMessageParams = {
  attentionMessages: ChatMessageAndAttentionInfo[]
  criterionEntityId: string | undefined
  direction: TimelineDirection
  isLoadedOfEndOfMessages: boolean
}

/**
 * アテンションメッセージをロードするためのパラメータ
 */
type LoadAttentionsParams = {
  criterionEntityId: string | undefined
  direction: TimelineDirection
}

/**
 * 引数のmessagesから表示用のアイテムリストを生成する関数
 * @param messages ViewModelから取得したTimeline形式のmessages
 * @param loadAttentions アテンションメッセージをロードする関数
 * @param delayedUpdateLastReadMessageId 既読更新を遅延実行させるための関数
 * @returns 表示用アイテムリストと更新後の最終既読メッセージID
 */
export const convertMessagesToDisplayItems = (
  messages: Attention.AttentionMessage[],
  loadAttentions: (params: LoadAttentionsParams) => void
): DisplayMessageItem[] =>
  messages.map((message) => {
    const messageId = message.id

    // 追加読込の方向を決定する。必要無ければundefined
    const additionalLoadingDirection =
      message.hasMorePrev && message.hasMoreNext // 時間軸上で両方向の隣のメッセージが未読込だった場合はboth
        ? TimelineDirection.BothNewerAndOlder
        : message.hasMorePrev // 時間軸上でprev方向の隣のメッセージが未読込だった場合はolder
        ? TimelineDirection.Older
        : message.hasMoreNext // 時間軸上でnext方向の隣のメッセージが未読込だった場合はnewer
        ? TimelineDirection.Newer
        : undefined // 両方向の隣のメッセージが読込済だった場合は追加読込は不要

    // 追加読込処理を行う関数を生成する。必要無ければundefined
    const handleAdditionalLoading = additionalLoadingDirection
      ? () =>
          loadAttentions({
            criterionEntityId: messageId,
            direction: additionalLoadingDirection,
          })
      : undefined

    return {
      id: messageId,
      attentionUserName: message.attentionUserName,
      attentionType: message.attentionType,
      chatMessageId: message.chatMessageId,
      handleAdditionalLoading,
    }
  })

export const useMentionMessageList = () => {
  const dispatch = useAppDispatch()

  // 取得したチャットメッセージをSliceにキャッシュする操作を提供するService
  const chatMessageService = useChatMessageService(dispatch)
  // Attention関連のSliceへの操作を提供するService
  const attentionService = useAttentionService(dispatch)

  // アテンションデータ取得用のクエリ
  const [lazyGetAttentionsQuery] = useLazyGetAttentionsQuery()

  // Reduxに格納されているアテンションのメッセージのDictionaryを取得する
  const attentionMessageDictionary = useAppSelector(
    (state) => state.message.attention.mention.messages.entities
  )
  // Reduxに格納されている対象チャットルームのメッセージのIDリストを取得する
  // Reduxに格納されている順番で取得する必要があるので、entitiesだけでなくidsも取得しておく必要がある
  const attentionMessageIds = useAppSelector(
    (state) => state.message.attention.mention.messages.ids
  )
  // 表示対象のメッセージを返す
  const attentionMessages = useMemo(() => {
    if (!attentionMessageIds || !attentionMessageDictionary) {
      return []
    }
    // id順にメッセージを設定する
    return attentionMessageIds.reduce(
      (result: Attention.AttentionMessage[], id) => {
        const message = attentionMessageDictionary[id]
        if (message) {
          result.push(message)
        }
        return result
      },
      []
    )
  }, [attentionMessageDictionary, attentionMessageIds])

  // 一度にfetchするサイズ
  const fetchLimit = useFetchLimit(MESSAGE_AREA_MIN_HEIGHT, getWindowDimensions)

  /**
   * 複数アイテムをViewModelに追加する
   * @param params
   */
  const addItems = useCallback(
    (params: AddMessageParams) => {
      // ViewModelへデータを追加
      const parameter = {
        targetGroup: AttentionDisplayGroup.Mention,
        messages: params.attentionMessages,
        criterionMessageId: params.criterionEntityId,
        direction: params.direction,
        isLoadedOfEndOfSourceItems: params.isLoadedOfEndOfMessages,
      }
      attentionService.addAttentionMessages(parameter)
    },
    [attentionService]
  )

  /**
   * API経由で取得したチャットメッセージをメッセージのキャッシュとViewModelに追加する
   */
  const addMessages = useCallback(
    (params: AddMessageParams) => {
      // キャッシュへ追加
      chatMessageService.addChatMessagesToCache({
        chatMessages: params.attentionMessages,
      })
      // ViewModelへ追加
      addItems({
        attentionMessages: params.attentionMessages,
        criterionEntityId: params.criterionEntityId,
        direction: params.direction,
        isLoadedOfEndOfMessages: params.isLoadedOfEndOfMessages,
      })
    },
    [addItems, chatMessageService]
  )

  /**
   * アテンションメッセージをロードするメソッド
   * @param params 対象メッセージを絞り込むためのパラメータ
   * @returns
   */
  const loadAttentions = useCallback(
    async (params: LoadAttentionsParams) => {
      // 対象はメンションのみ
      const filter = [AttentionType.Mention]

      const request: GetAttentionsRequest = {
        filter,
        criterionMessageId: params.criterionEntityId,
        direction: params.direction,
        limit: fetchLimit,
      }

      try {
        const data = await lazyGetAttentionsQuery(request).unwrap()

        const isLoadedOfEndOfMessages =
          request.limit > data.attentionItems.length

        const addItemsPayload: AddMessageParams = {
          attentionMessages: data.attentionItems.map((attentionItem) =>
            convertAttentionItemToChatMessageAndAttentionInfo(attentionItem)
          ),
          criterionEntityId: request.criterionMessageId,
          direction: request.direction,
          isLoadedOfEndOfMessages,
        }

        // アテンションメッセージをsliceとtimelineに追加
        // sliceにはチャットメッセージだけ残してキャッシュとして利用する
        addMessages(addItemsPayload)
      } catch (err) {
        // TODO: CREW-13720の対応で、プロジェクトから退出した際にエラートーストが出てしまう問題が発生し、トースト表示をコメントアウトする暫定対応を行った
        // ただ、これにより追加ロード時にエラーがあっても画面上表示されないという状態であるため、以下タスクで恒久対応を行う
        // 現時点ではコンソールにエラーを表示するにとどめる
        // https://break-tmc.atlassian.net/browse/CREW-13724
        // toast.error(t('message.general.failedToRetrieveData'))
        console.error(err)
      }
    },
    [addMessages, fetchLimit, lazyGetAttentionsQuery]
  )

  // アテンション表示用アイテムリスト。
  const displayItems = useMemo(
    () => convertMessagesToDisplayItems(attentionMessages, loadAttentions),
    [attentionMessages, loadAttentions]
  )

  // アイテムが領域内に表示された
  const messageInView = useCallback(
    (entityRecordId: string) => {
      // スクロール位置を戻すため対象のentityRecordIdを保持しておく
      attentionService.setAttentionScrollToEntityRecordId({
        targetGroup: AttentionDisplayGroup.Mention,
        entityRecordId,
      })
    },
    [attentionService]
  )

  // 既読更新を行う
  const reloadMessages = useCallback(() => {
    // データを再取得
    loadAttentions({
      criterionEntityId: undefined, // 最新のものをロード
      direction: TimelineDirection.Older,
    })
  }, [loadAttentions])

  return {
    displayItems,
    reloadMessages,
    messageInView,
  }
}
