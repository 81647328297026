import { FC, memo, useMemo } from 'react'

import { LIMIT_FOR_UNREAD_DISPLAY_COUNT } from 'configs/constants'
import { selectUnreadCountTotal } from '@crew/states'
import { useAppSelector } from 'states/hooks'
import { CrewCountBadge } from 'components/crewCountBadge/crewCountBadge'

export const CrewAttentionUnreadBadge: FC = memo(() => {
  const { attention, mentionToMe, mentionToGroup } = useAppSelector(
    selectUnreadCountTotal()
  )

  // if unread number is greater than 99, display 99+
  const counterText =
    attention > LIMIT_FOR_UNREAD_DISPLAY_COUNT
      ? `${LIMIT_FOR_UNREAD_DISPLAY_COUNT}+`
      : `${attention}`

  const targetBackgroundClassNames = useMemo(
    () =>
      mentionToMe > 0
        ? 'crew-badge-red-inverted' // 赤：ユーザー個人宛のメンションがある場合
        : mentionToGroup > 0
        ? 'crew-badge-orange-inverted' // オレンジ：ユーザが属するグループ宛のメンションがある場合。@allや@hereなど
        : 'crew-badge-blue-inverted', // 青：上記以外
    [mentionToMe, mentionToGroup]
  )

  if (attention <= 0) {
    // 未読0なら何も表示しない
    return null
  }

  return (
    <CrewCountBadge displayColor={targetBackgroundClassNames}>
      {counterText}
    </CrewCountBadge>
  )
})
