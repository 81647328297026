/* eslint-disable @typescript-eslint/no-redeclare */

/**
 *  CrewBadgeの色
 */
export const CrewBadgeColorClass = {
  Red: 'crew-badge-red',
  Orange: 'crew-badge-orange',
  Yellow: 'crew-badge-yellow',
  Green: 'crew-badge-green',
  Blue: 'crew-badge-blue',
  Cyan: 'crew-badge-cyan',
  Purple: 'crew-badge-purple',
  Gray: 'crew-badge-gray',
} as const

export type CrewBadgeColorClass =
  (typeof CrewBadgeColorClass)[keyof typeof CrewBadgeColorClass]

/**
 *  CrewBadgeの反転色
 */
export const CrewBadgeInvertedColorClass = {
  Red: 'crew-badge-red-inverted',
  Orange: 'crew-badge-orange-inverted',
  Yellow: 'crew-badge-yellow-inverted',
  Green: 'crew-badge-green-inverted',
  Blue: 'crew-badge-blue-inverted',
  Cyan: 'crew-badge-cyan-inverted',
  Purple: 'crew-badge-purple-inverted',
  Gray: 'crew-badge-gray-inverted',
} as const

export type CrewBadgeInvertedColorClass =
  (typeof CrewBadgeInvertedColorClass)[keyof typeof CrewBadgeInvertedColorClass]

/**
 * Devextreme timeline scheduler appointment color
 */
export const CrewBadgeInvertedColorToHex = {
  [CrewBadgeInvertedColorClass.Red]: '#fee2e2',
  [CrewBadgeInvertedColorClass.Orange]: '#ffe6d5',
  [CrewBadgeInvertedColorClass.Yellow]: '#fef0c3',
  [CrewBadgeInvertedColorClass.Green]: '#dcfce8',
  [CrewBadgeInvertedColorClass.Blue]: '#dbe8fe',
  [CrewBadgeInvertedColorClass.Cyan]: '#cff7fe',
  [CrewBadgeInvertedColorClass.Purple]: '#f4e8ff',
  [CrewBadgeInvertedColorClass.Gray]: '#f5f5f5',
} as const

export type CrewBadgeInvertedColorToHex =
  (typeof CrewBadgeInvertedColorToHex)[keyof typeof CrewBadgeInvertedColorToHex]

/**
 * Devextreme timeline scheduler appointment color
 */
export const CrewBadgeColorToHex = {
  [CrewBadgeColorClass.Red]: '#dc2626',
  [CrewBadgeColorClass.Orange]: '#ea670c',
  [CrewBadgeColorClass.Yellow]: '#ca9a04',
  [CrewBadgeColorClass.Green]: '#16a34a',
  [CrewBadgeColorClass.Blue]: '#2570eb',
  [CrewBadgeColorClass.Cyan]: '#0899b2',
  [CrewBadgeColorClass.Purple]: '#9133ea',
  [CrewBadgeColorClass.Gray]: '#525252',
} as const

export type CrewBadgeColorToHex =
  (typeof CrewBadgeColorToHex)[keyof typeof CrewBadgeColorToHex]
