import { CrewDateFilterBox } from 'components/devextreme/crewDateFilterBox'
import { CrewSelectBox } from 'components/devextreme/crewSelectBox'
import { CrewTagBox } from 'components/devextreme/crewTagBox'
import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { FC, memo } from 'react'
import { assertNever } from '@crew/utils'
import _ from 'lodash'
import { useCallback } from 'react'
import {
  ParamValue,
  SearchFilter,
  dateFilterValueToString,
  stringToDateFilterValue,
} from 'utils/filter'
import { ValueChangedEvent as TextBoxValueChangedEvent } from 'devextreme/ui/text_box'
import { ValueChangedEvent as SelectBoxValueChangedEvent } from 'devextreme/ui/select_box'
import { ValueChangedEvent as TagBoxValueChangedEvent } from 'devextreme/ui/tag_box'
import { CrewDateFilterBoxValueChangedEvent } from 'components/devextreme/crewDateFilterBox'
import {
  useTaskPriorityDataSource,
  useTaskStateTypeDataSource,
} from 'hooks/dataSource/useResourceDataSource'
import { useTaskKindDataSource } from 'hooks/dataSource/useTaskKindDataSource'
import { useTaskStateDataSource } from 'hooks/dataSource/useTaskStateDataSource'
import { EntityType } from '@crew/enums/domain'
import { useTaskCategoryDataSource } from 'hooks/dataSource/useTaskCategoryDataSource'
import { DetailTaskSearchOptions } from 'enums/app'
import { useUserDataSource } from 'hooks/dataSource/useUserDataSource'
import { t } from '@crew/modules/i18n'
import { useMemberDataSource } from 'hooks/dataSource/useMemberDataSource'
type DetailTaskSearchInputProps = {
  filter: SearchFilter
  updateSearchValue: (filter: SearchFilter, value: ParamValue) => void
  projectId: string | undefined
}

export const CrewDetailTaskSearchInput: FC<DetailTaskSearchInputProps> = memo(
  ({ filter, updateSearchValue, projectId }) => {
    // タスク優先度のカスタムデータソース
    const taskPriorityDataSource = useTaskPriorityDataSource()
    // 実際にタスク種別のリストに表示されるデータ
    const taskKindDataSource = useTaskKindDataSource(
      EntityType.Project,
      projectId,
      false, // hasBaseFilter
      false, // isGrouped
      true // multiSelect
    )
    // custom data source for assign to user
    const assignToUserDataSource = useUserDataSource(true)

    // custom data source for task state
    const taskStateDataSource = useTaskStateDataSource(
      EntityType.Project,
      projectId
    )
    // custom data source for task category
    const taskCategoryDataSource = useTaskCategoryDataSource(
      EntityType.Project,
      projectId
    )
    // custom data source for created by user
    const createdByUserDataSource = useMemberDataSource(
      EntityType.Project,
      projectId,
      undefined,
      true
    )
    // custom data source for updated by user
    const updatedByUserDataSource = useMemberDataSource(
      EntityType.Project,
      projectId,
      undefined,
      true
    )

    // タスク状態タイプのカスタムデータソース
    const taskStateTypeDataSource = useTaskStateTypeDataSource()

    // キーワードの変更
    const handleKeywordChanged = useCallback(
      (e: TextBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        if (e.value === '') {
          updateSearchValue(filter, undefined)
          return
        }
        updateSearchValue(filter, e.value)
      },
      [filter, updateSearchValue]
    )

    // タスク種別の変更
    const handleTypeChanged = useCallback(
      (e: TagBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        updateSearchValue(filter, e.value)
      },
      [filter, updateSearchValue]
    )

    // 優先度の変更
    const handlePriorityChanged = useCallback(
      (e: TagBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        updateSearchValue(filter, e.value)
      },
      [filter, updateSearchValue]
    )

    // 担当者の変更
    const handleAssignToChanged = useCallback(
      (e: SelectBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        updateSearchValue(filter, e.value)
      },
      [filter, updateSearchValue]
    )

    // ステータスの変更
    const handleStatusChanged = useCallback(
      (e: TagBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        updateSearchValue(filter, e.value)
      },
      [filter, updateSearchValue]
    )

    // 開始日の変更
    const handleStartDateChanged = useCallback(
      (e: CrewDateFilterBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        if (e.value) {
          updateSearchValue(filter, dateFilterValueToString(e.value))
        }
      },
      [filter, updateSearchValue]
    )

    // 期限日の変更
    const handleDueDateChanged = useCallback(
      (e: CrewDateFilterBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        if (e.value) {
          updateSearchValue(filter, dateFilterValueToString(e.value))
        }
      },
      [filter, updateSearchValue]
    )

    // カテゴリの変更
    const handleCategoryChanged = useCallback(
      (e: TagBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        updateSearchValue(filter, e.value)
      },
      [filter, updateSearchValue]
    )

    // 作成者の変更
    const handleCreatedByChanged = useCallback(
      (e: SelectBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        updateSearchValue(filter, e.value)
      },
      [filter, updateSearchValue]
    )

    // 更新者の変更
    const handleUpdatedByChanged = useCallback(
      (e: SelectBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        updateSearchValue(filter, e.value)
      },
      [filter, updateSearchValue]
    )

    // 作成日の変更
    const handleCreatedAtChanged = useCallback(
      (e: CrewDateFilterBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        if (e.value) {
          updateSearchValue(filter, dateFilterValueToString(e.value))
        }
      },
      [filter, updateSearchValue]
    )

    // 更新日の変更
    const handleUpdatedAtChanged = useCallback(
      (e: CrewDateFilterBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        if (e.value) {
          updateSearchValue(filter, dateFilterValueToString(e.value))
        }
      },
      [filter, updateSearchValue]
    )

    // タスク状態タイプの変更
    const handleTaskStateTypeChanged = useCallback(
      (e: TagBoxValueChangedEvent) => {
        if (_.isEqual(e.value, e.previousValue)) return
        updateSearchValue(filter, e.value)
      },
      [filter, updateSearchValue]
    )

    switch (filter.field) {
      case DetailTaskSearchOptions.Keyword.id:
        return (
          <CrewTextBox
            id={DetailTaskSearchOptions.Keyword.id}
            name={DetailTaskSearchOptions.Keyword.name}
            value={filter.value as string}
            showClearButton={true}
            onValueChanged={handleKeywordChanged}
            mode="search"
          />
        )
      case DetailTaskSearchOptions.TaskKindId.id:
        return (
          <CrewTagBox
            id={DetailTaskSearchOptions.TaskKindId.id}
            name={DetailTaskSearchOptions.TaskKindId.name}
            displayExpr="name"
            valueExpr="id"
            dataSource={taskKindDataSource}
            searchEnabled={true}
            popupSearchEnabled={true}
            searchMode="contains"
            searchExpr="name"
            minSearchLength={0}
            maxDisplayedTags={2}
            value={filter.value as string[]}
            onValueChanged={handleTypeChanged}
          />
        )
      case DetailTaskSearchOptions.AssignToUser.id:
        return (
          <CrewSelectBox
            id={DetailTaskSearchOptions.AssignToUser.id}
            name={DetailTaskSearchOptions.AssignToUser.name}
            dataSource={assignToUserDataSource}
            displayExpr="displayName"
            valueExpr="id"
            minSearchLength={0}
            showClearButton={true}
            value={filter.value}
            onValueChanged={handleAssignToChanged}
            searchEnabled={true}
            searchExpr="displayName"
          />
        )
      case DetailTaskSearchOptions.TaskStateId.id:
        return (
          <CrewTagBox
            id={DetailTaskSearchOptions.TaskStateId.id}
            name={DetailTaskSearchOptions.TaskStateId.name}
            displayExpr="name"
            valueExpr="id"
            dataSource={taskStateDataSource}
            searchEnabled={true}
            popupSearchEnabled={true}
            searchMode="contains"
            searchExpr="name"
            minSearchLength={0}
            maxDisplayedTags={2}
            value={filter.value as string[]}
            onValueChanged={handleStatusChanged}
          />
        )

      case DetailTaskSearchOptions.TaskStateType.id:
        return (
          <CrewTagBox
            id={DetailTaskSearchOptions.TaskStateType.id}
            name={DetailTaskSearchOptions.TaskStateType.name}
            displayExpr="name"
            valueExpr="id"
            dataSource={taskStateTypeDataSource}
            searchEnabled={true}
            popupSearchEnabled={true}
            searchMode="contains"
            searchExpr="name"
            minSearchLength={0}
            maxDisplayedTags={2}
            value={filter.value as string[]}
            onValueChanged={handleTaskStateTypeChanged}
          />
        )

      case DetailTaskSearchOptions.TaskPriority.id:
        return (
          <CrewTagBox
            id={DetailTaskSearchOptions.TaskPriority.id}
            name={DetailTaskSearchOptions.TaskPriority.name}
            displayExpr="name"
            valueExpr={(item) => item && String(item.id)} // we need to cast priority to string
            dataSource={taskPriorityDataSource}
            searchEnabled={true}
            popupSearchEnabled={true}
            searchMode="contains"
            searchExpr="name"
            minSearchLength={0}
            maxDisplayedTags={2}
            value={filter.value as string[]}
            onValueChanged={handlePriorityChanged}
          />
        )
      case DetailTaskSearchOptions.TaskCategoryId.id:
        return (
          <CrewTagBox
            id={DetailTaskSearchOptions.TaskCategoryId.id}
            name={DetailTaskSearchOptions.TaskCategoryId.name}
            displayExpr="name"
            valueExpr="id"
            dataSource={taskCategoryDataSource}
            searchEnabled={true}
            popupSearchEnabled={true}
            searchMode="contains"
            searchExpr="name"
            minSearchLength={0}
            maxDisplayedTags={2}
            value={filter.value as string[]}
            onValueChanged={handleCategoryChanged}
            noDataText={
              projectId
                ? t('label.noDataText')
                : t('message.general.selectProject')
            }
          />
        )
      case DetailTaskSearchOptions.StartDate.id:
        return (
          <CrewDateFilterBox
            value={stringToDateFilterValue(filter.value as string)}
            onValueChange={handleStartDateChanged}
          />
        )
      case DetailTaskSearchOptions.DueDate.id:
        return (
          <CrewDateFilterBox
            value={stringToDateFilterValue(filter.value as string)}
            onValueChange={handleDueDateChanged}
            showOverdueOption
          />
        )
      case DetailTaskSearchOptions.CreatedById.id:
        return (
          <CrewSelectBox
            id={DetailTaskSearchOptions.CreatedById.id}
            name={DetailTaskSearchOptions.CreatedById.name}
            displayExpr="displayName"
            valueExpr="id"
            dataSource={createdByUserDataSource}
            minSearchLength={0}
            showClearButton={true}
            value={filter.value}
            onValueChanged={handleCreatedByChanged}
            searchEnabled={true}
            searchExpr="displayName"
          />
        )
      case DetailTaskSearchOptions.UpdatedById.id:
        return (
          <CrewSelectBox
            id={DetailTaskSearchOptions.UpdatedById.id}
            name={DetailTaskSearchOptions.UpdatedById.name}
            displayExpr="displayName"
            valueExpr="id"
            dataSource={updatedByUserDataSource}
            minSearchLength={0}
            showClearButton={true}
            value={filter.value}
            onValueChanged={handleUpdatedByChanged}
            searchEnabled={true}
            searchExpr="displayName"
          />
        )
      case DetailTaskSearchOptions.CreatedAt.id:
        return (
          <CrewDateFilterBox
            value={stringToDateFilterValue(filter.value as string)}
            onValueChange={handleCreatedAtChanged}
          />
        )
      case DetailTaskSearchOptions.UpdatedAt.id:
        return (
          <CrewDateFilterBox
            value={stringToDateFilterValue(filter.value as string)}
            onValueChange={handleUpdatedAtChanged}
          />
        )
      default:
        // assertNever has value type never, so we need to cast filter.field to never
        assertNever(filter.field as never, 'Invalid filter field')
    }
  }
)
