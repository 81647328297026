import {
  ComponentProps,
  forwardRef,
  memo,
  PropsWithChildren,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { List, Popup, TagBox } from 'devextreme-react'
import { Position, ToolbarItem } from 'devextreme-react/popup'
import { dxButtonOptions } from 'devextreme/ui/button'
import { Button as TagBoxButton } from 'devextreme-react/tag-box'
import _ from 'lodash'
import { useTranslation } from '@crew/modules/i18n'
import DataSource from 'devextreme/data/data_source'

export interface ICrewTagBox {
  focus(): void
  component(): TagBox | null
}

type Props = PropsWithChildren<ComponentProps<typeof TagBox>> & {
  popupSearchEnabled?: boolean
  tagBoxId?: string
}

export const CrewTagBox = memo(
  forwardRef<ICrewTagBox, Props>(
    (
      {
        children,
        dataSource,
        searchEnabled,
        searchMode = 'contains',
        searchExpr,
        popupSearchEnabled = true,
        id,
        valueExpr,
        displayExpr,
        noDataText,
        tagBoxId,
        grouped,
        groupComponent,
        ...rest
      },
      ref
    ) => {
      const { t } = useTranslation()
      const tagboxRef = useRef<TagBox | null>(null)
      const listRef = useRef<List>(null)
      const [isOpen, setIsOpen] = useState<boolean>(false)

      // Expose focus and component methods via useImperativeHandle
      useImperativeHandle(ref, () => {
        return {
          focus: () => {
            return tagboxRef?.current?.instance.focus()
          },
          component: () => {
            return tagboxRef?.current
          },
        }
      })

      // Function to hide the Popup
      const hideInfo = () => {
        setIsOpen(false)
      }

      // Callback to focus the List when Popup is shown
      const handlePopupShown = useCallback(() => {
        listRef.current?.instance.focus()
      }, [])

      // Callback to hide the Popup
      const handlePopupHiding = useCallback(() => {
        hideInfo()
      }, [])

      // Deep clone of dataSource
      const popupDataSource = useMemo(() => {
        const newObj = _.cloneDeep(dataSource)
        return newObj
      }, [dataSource])

      // Options for the TagBoxButton
      const tagboxButtonOptions: dxButtonOptions = useMemo(() => {
        return {
          icon: 'search',
          type: 'normal',
          stylingMode: 'text',
          focusStateEnabled: false,
          onClick: () => {
            tagboxRef.current?.instance.close()
            if (popupDataSource instanceof DataSource) {
              popupDataSource.searchValue('')
              popupDataSource.reload()
            }

            setIsOpen(true)
          },
        }
      }, [popupDataSource])

      // Options for the "Apply" button
      const applyButtonOptions: dxButtonOptions = useMemo(() => {
        return {
          text: t('action.apply'),
          onClick: () => {
            const selectedItems =
              listRef.current?.instance.option('selectedItems')

            if (valueExpr) {
              const selectedItemKeys =
                listRef.current?.instance.option('selectedItemKeys')
              tagboxRef.current?.instance.option('value', selectedItemKeys)
            } else {
              tagboxRef.current?.instance.option('value', selectedItems)
            }

            tagboxRef.current?.instance.option('selectedItems', selectedItems)
            hideInfo()
          },
        }
      }, [t, valueExpr])

      // Options for the "Close" button
      const closeButtonOptions: dxButtonOptions = useMemo(() => {
        return {
          text: t('action.close'),
          onClick: () => {
            hideInfo()
          },
        }
      }, [t])

      // Default selected item keys
      const defaultSelectedItemKeys =
        tagboxRef.current?.instance.option('value') || []

      // Default selected items
      const defaultSelectedItems =
        tagboxRef?.current?.instance.option('selectedItems') || []

      // ポップアップ表示対象のID。tagBoxIdが指定されていればそれを使用し、指定されていなければidを使用する
      const popupPositionTargetId = useMemo(() => {
        return tagBoxId || id
      }, [tagBoxId, id])

      // Options for the dropdown
      const dropDownOptions = useMemo(() => {
        return {
          minWidth: '200px',
        }
      }, [])

      return (
        <>
          <TagBox
            ref={tagboxRef}
            dataSource={dataSource}
            searchExpr={searchExpr}
            searchEnabled={searchEnabled}
            searchMode={searchMode}
            id={popupPositionTargetId}
            valueExpr={valueExpr}
            displayExpr={displayExpr}
            noDataText={noDataText ?? t('label.noDataText')}
            groupComponent={groupComponent}
            grouped={grouped}
            dropDownOptions={dropDownOptions}
            selectedItems={defaultSelectedItems}
            {...rest}
          >
            {popupSearchEnabled && (
              <TagBoxButton
                name="searchButton"
                location="after"
                options={tagboxButtonOptions}
              />
            )}
            {children}
          </TagBox>

          {popupSearchEnabled && isOpen && (
            <Popup
              visible={isOpen}
              dragEnabled={false}
              showCloseButton={false}
              hideOnOutsideClick={true}
              showTitle={false}
              width={400}
              height={400}
              animation={undefined}
              shading={false}
              onShown={handlePopupShown}
              onHiding={handlePopupHiding}
            >
              <Position
                at="left bottom"
                my="left top"
                of={`#${popupPositionTargetId}`}
                collision="flipfit"
              />
              <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="before"
                options={applyButtonOptions}
              />
              <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={closeButtonOptions}
              />
              <List
                ref={listRef}
                dataSource={popupDataSource}
                keyExpr={valueExpr}
                displayExpr={displayExpr}
                showSelectionControls={true}
                selectionMode="all"
                defaultSelectedItemKeys={defaultSelectedItemKeys}
                searchExpr={searchExpr}
                searchEnabled={searchEnabled}
                searchMode={searchMode}
                noDataText={noDataText ?? t('label.noDataText')}
                selectAllMode="page"
                grouped={grouped}
                groupComponent={groupComponent}
              />
            </Popup>
          )}
        </>
      )
    }
  )
)
