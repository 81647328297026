import { apis } from '../apiBase/apiBase';
export const userStateApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        updateUserPresenceState: builder.mutation({
            query: (params) => ({
                url: 'user-presence-states/update',
                method: 'POST',
                body: params,
            }),
        }),
    }),
    overrideExisting: false,
});
export const { useUpdateUserPresenceStateMutation } = userStateApis;
