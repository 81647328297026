import {
  useLazyGetEventKindsQuery,
  useLazyGetProjectEventsForPaginationQuery,
  useUpdateEventDurationMutation,
} from '@crew/apis/project/projectApis'
import {
  EntityType,
  EventType,
  GroupScheduleFilterType,
} from '@crew/enums/domain'
import {
  CrewBadgeInvertedColorClass,
  CrewBadgeInvertedColorToHex,
} from 'enums/color'
import { useDataSource } from 'hooks/dataSource/useDataSource'
import { RefObject, useMemo } from 'react'
import { Scheduler } from 'devextreme-react'
import { useLazyGetLookupProjectMembersQuery } from '@crew/apis/lookup/lookupApis'
import { ProjectDetailEventTimePeriod } from 'enums/app'
import { useTranslation } from '@crew/modules/i18n'
import { useScheduleGroupMonthDataSource } from 'hooks/dataSource/useScheduleGroupMonthDataSource'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { JsonDateFormat } from '@crew/enums/system'
import dayjs from '@crew/modules'

export const useProjectDetailEventCalendar = (
  projectId: string | undefined,
  schedulerRef: RefObject<Scheduler>,
  targetDate: Date
) => {
  const { t } = useTranslation()

  const [lazyGetEventKindsQuery] = useLazyGetEventKindsQuery()
  const [lazyGetProjectEventsForPaginationQuery] =
    useLazyGetProjectEventsForPaginationQuery()
  const [lazyGetLookupProjectMembersQuery] =
    useLazyGetLookupProjectMembersQuery()

  const [updateEventDurationMutation] = useUpdateEventDurationMutation()

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  const eventScheduleGroupMonthDataSource = useScheduleGroupMonthDataSource(
    targetDate,
    GroupScheduleFilterType.GroupScheduleProjectFilter,
    projectId
  )

  const eventDataSource = useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        if (!projectId || !schedulerRef.current) return []

        // Get the first day in calendar month page
        const calendarStartDate =
          schedulerRef.current.instance.getStartViewDate()
        // Get the last day in calendar month page
        const calendarEndDate = schedulerRef.current.instance.getEndViewDate()

        let startDate =
          'afterAt:' + dayjs(calendarStartDate).format(JsonDateFormat.YYYYMMDD)
        let endDate =
          'beforeAt:' + dayjs(calendarEndDate).format(JsonDateFormat.YYYYMMDD)

        const result = await lazyGetProjectEventsForPaginationQuery({
          projectId,
          keyword: undefined,
          eventKindIds: undefined,
          // TODO: Web: イベント分類の非表示対応
          // https://break-tmc.atlassian.net/browse/CREW-15049
          eventCategoryIds: undefined,
          startDate,
          endDate,
          createdAt: undefined,
          updatedAt: undefined,
          createdById: undefined,
          updatedById: undefined,
          limit: undefined,
          offset: undefined,
          attendeeId: undefined,
        }).unwrap()

        return result.events
      },
      update: async (key, values) => {
        // スケジューラは日時をUTC時間に自動的に変換するが、
        // バックエンド側はクライアントのタイムゾーンに沿って処理するため、ここでUTC時間をクライアントのタイムゾーンに変換する。
        const startDatetime = dayjs(values.startDatetime)
          .tz(String(defaultUserProfileRegion))
          .format(JsonDateFormat.YYYYMMDDHHmmss)

        const endDatetime = dayjs(values.endDatetime)
          .tz(String(defaultUserProfileRegion))
          .format(JsonDateFormat.YYYYMMDDHHmmss)

        await updateEventDurationMutation({
          event: {
            id: key,
            startDatetime: startDatetime,
            endDatetime: endDatetime,
            version: values.version,
          },
        })
      },
    }),
    [
      defaultUserProfileRegion,
      lazyGetProjectEventsForPaginationQuery,
      projectId,
      schedulerRef,
      updateEventDurationMutation,
    ]
  )

  //Get event kinds
  const eventKindDataSource = useDataSource(
    () => ({
      loadMode: 'raw',
      key: 'id',
      load: async (loadOptions) => {
        const response = await lazyGetEventKindsQuery({
          eventType: EventType.Project,
          entityType: EntityType.Project,
          entityRecordId: projectId,
        }).unwrap()

        return response.eventKinds.map((item) => {
          return {
            ...item,
            displayColor:
              CrewBadgeInvertedColorToHex[
                item.displayColor as CrewBadgeInvertedColorClass
              ],
          }
        })
      },
    }),
    [lazyGetEventKindsQuery, projectId]
  )

  //Get list project members
  const projectMembersDataSource = useDataSource(
    () => ({
      loadMode: 'raw',
      key: 'id',
      load: async (loadOptions) => {
        if (!projectId) return []

        // Get project members by projectId
        const response = await lazyGetLookupProjectMembersQuery({
          projectId,
          userId: undefined,
          keyword: undefined,
          excludeUserId: undefined,
          // Show only enabled user
          isEnabledUser: true,
        }).unwrap()

        return response.projectMembers.map((member) => {
          return {
            id: member.id,
            displayName: member.displayName,
            version: member.version,
          }
        })
      },
    }),
    [lazyGetLookupProjectMembersQuery, projectId]
  )

  const timePeriodDataSource = useMemo(() => {
    return Object.values(ProjectDetailEventTimePeriod).map((time) => {
      return {
        id: time.value,
        name: t(time.text),
      }
    })
  }, [t])

  return {
    eventKindDataSource,
    eventDataSource,
    projectMembersDataSource,
    timePeriodDataSource,
    eventScheduleGroupMonthDataSource,
  }
}
