import { useCallback, useState } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { useToast } from 'hooks/useToast'
import { UploadFileResponse } from '@crew/apis/file/models/uploadFile/response'
import { apiBaseUrl } from '@crew/configs/constants'
import axios from 'axios'
import { axiosInstance } from 'apis/axiosInstance'
import { useShowApiErrors } from 'hooks/useShowApiErrors'

type AbortControllers = {
  [fileName: string]: AbortController
}

// ファイルアップロード処理のカスタムフック
export const useCrewFileUpload = () => {
  const { t } = useTranslation()
  const toast = useToast()
  const [showApiErrors] = useShowApiErrors()

  const [abortControllers, setAbortControllers] = useState<AbortControllers>({}) // Store AbortControllers for each file

  // ファイルをアップロード
  const uploadFile = useCallback(
    async (
      file: File,
      onUploaded?: (res: UploadFileResponse) => void,
      onUploadProgress?: (progress: number) => void,
      // any型でエラーを受け取る
      onUploadError?: (err: any) => void
    ) => {
      try {
        // ファイルタイプがない場合はエラー
        if (!file.type) {
          toast.error(t('message.file.unsupportedFileFormat'))
          return
        }

        if (
          axiosInstance.defaults.maxContentLength &&
          file.size > axiosInstance.defaults.maxContentLength
        ) {
          toast.error(t('message.file.invalidMaxFileSize'))
          return
        }

        const formData = new FormData()
        formData.append('file', file)

        const controller = new AbortController() // Create a new AbortController for each file
        setAbortControllers((prev) => ({ ...prev, [file.name]: controller }))

        // TODO: 呼び出し側でaxiosInstanceを直接使用しないように、以下タスクでリファクタ予定
        // https://break-tmc.atlassian.net/browse/CREW-16919
        const response = await axiosInstance.post(
          `${apiBaseUrl()}api/v1/files/upload`,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              if (progressEvent.total) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                )

                // プログレスコールバックが渡されていれば実行
                onUploadProgress && onUploadProgress(percentCompleted)
              }
            },
            signal: controller.signal, // Attach the AbortController signal to Axios request
          }
        )

        // アップロード後の後続処理が渡されていれば実行
        onUploaded && onUploaded(response.data)
      } catch (err: any) {
        // エラーコールバックが渡されていれば実行
        onUploadError && onUploadError(err)

        // is a cancellation
        if (axios.isCancel(err)) {
          // is a cancellation
          console.error('Operation canceled')
        } else {
          showApiErrors(err.response)
        }
      }
    },
    [showApiErrors, t, toast]
  )

  return {
    uploadFile,
    abortControllers,
  }
}
