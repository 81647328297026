import { UpdateGanttTaskRequest_Task } from '@crew/apis/task/models/updateGanttTask/request'
import { GetGanttTasksRequest } from '@crew/apis/task/models/getGanttTasks/request'
import {
  useDeleteTaskMutation,
  useLazyGetTaskQuery,
  useUpdateGanttTaskMutation,
  useLazyGetGanttTasksQuery,
} from '@crew/apis/task/taskApis'
import { useDataSource } from 'hooks/dataSource/useDataSource'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import dayjs from '@crew/modules'
import { JsonDateFormat } from '@crew/enums/system'
import { GanttTask } from '@crew/apis/task/models/getGanttTasks/response'
import { cloneDeep } from 'lodash'

// check task has child task
const hasChildTask = (task: GanttTask, tasks: GanttTask[]): boolean => {
  return tasks.some((t) => t.parentTaskId === task.id)
}

// calculate changed estimated work times between before and after
const calculateChangedEstimatedWorkTimes = (
  tasks: GanttTask[],
  targetTask: GanttTask
): number | null => {
  const oldTask = tasks.find((task) => task.id === targetTask.id)

  if (!oldTask) return targetTask.estimatedWorkTimes

  if (
    typeof oldTask.estimatedWorkTimes === 'number' &&
    typeof targetTask.estimatedWorkTimes === 'number'
  ) {
    return targetTask.estimatedWorkTimes - oldTask.estimatedWorkTimes
  } else if (typeof targetTask.estimatedWorkTimes === 'number') {
    return targetTask.estimatedWorkTimes
  } else if (typeof oldTask.estimatedWorkTimes === 'number') {
    return oldTask.estimatedWorkTimes > 0 ? -oldTask.estimatedWorkTimes : null
  } else {
    return null
  }
}

// Calculate the actual progress of a task based on its child tasks
const calculateActualProgress = (childTasks: GanttTask[]): number => {
  // Calculate the total estimated work times of all child tasks
  // If 'estimatedWorkTimes' is null or undefined, treat it as 0
  const totalEstimatedWorkTimes = childTasks.reduce(
    (total, { estimatedWorkTimes }) => total + (estimatedWorkTimes ?? 0),
    0
  )

  // If there is no estimated work time, return 0 as the progress
  if (totalEstimatedWorkTimes === 0) return 0

  // Calculate the weighted actual progress of all child tasks
  const actualProgress = childTasks.reduce(
    (total, { estimatedWorkTimes, actualProgress = 0 }) => {
      // Only calculate progress for tasks with a valid, positive estimated work time
      if (estimatedWorkTimes && estimatedWorkTimes > 0) {
        return (
          total +
          (estimatedWorkTimes / totalEstimatedWorkTimes) * (actualProgress ?? 0)
        )
      }
      return total
    },
    0
  )

  // Round the final progress value to the nearest whole number and return it
  return Math.round(actualProgress)
}

// Get the minimum start date from a list of tasks, optionally comparing it to a target start date.
const getMinStartDate = (
  tasks: GanttTask[],
  targetStartDate: string | null
) => {
  // Reduce the tasks array to find the earliest start date
  const date = tasks.reduce((minStartDate: Date | null, task) => {
    if (!task.startDate) return null // Skip tasks with no start date

    const startDate = dayjs(task.startDate).toDate() // Convert task start date to Date object
    if (!minStartDate) return startDate // If minStartDate is not set, use the current startDate

    // Return the earlier of the two dates
    return startDate < minStartDate ? startDate : minStartDate
  }, null)

  // If no target start date is provided, return the calculated minimum start date
  if (!targetStartDate) return date

  // Compare the calculated minimum start date with the target start date
  if (date && dayjs(date).isBefore(dayjs(targetStartDate))) {
    return date // Return the earlier date
  } else {
    return dayjs(targetStartDate).toDate() // Return the target start date if it is earlier
  }
}

// Get the maximum due date from a list of tasks, optionally comparing it to a target end date.
const getMaxDueDate = (tasks: GanttTask[], targetEndDate: string | null) => {
  // Reduce the tasks array to find the latest due date
  const date = tasks.reduce((maxDueDate: Date | null, task) => {
    if (!task.dueDate) return null // Skip tasks with no due date

    const dueDate = dayjs(task.dueDate).toDate() // Convert task due date to Date object

    if (!maxDueDate) return dueDate // If maxDueDate is not set, use the current dueDate

    // Return the later of the two dates
    return dueDate > maxDueDate ? dueDate : maxDueDate
  }, null)

  // If no target end date is provided, return the calculated maximum due date
  if (!targetEndDate) return date

  // Compare the calculated maximum due date with the target end date
  if (date && dayjs(date).isAfter(dayjs(targetEndDate))) {
    return date // Return the later date
  } else {
    return targetEndDate // Return the target end date if it is later
  }
}

const findRelatedTasks = (taskId: string, tasks: GanttTask[]): GanttTask[] => {
  // Helper function to find a task by its ID
  const getTaskById = (id: string): GanttTask | undefined => {
    return tasks.find((task) => task.id === id)
  }

  // Traverse upward to find all parent tasks and their children (siblings)
  const findParentsAndSiblings = (
    task: GanttTask | undefined,
    relatedTasks: GanttTask[]
  ): void => {
    while (task && task.parentTaskId !== null) {
      const parent = getTaskById(task.parentTaskId)
      if (parent) {
        relatedTasks.unshift(parent) // Insert parent at the beginning
        // Add all siblings (including the current task) to relatedTasks
        const siblings = tasks.filter((t) => t.parentTaskId === parent.id)
        siblings.forEach((sibling) => {
          if (!relatedTasks.some((rt) => rt.id === sibling.id)) {
            relatedTasks.push(sibling)
          }
        })
        task = parent
      } else {
        break
      }
    }
  }

  // Traverse downward to find all child tasks
  const findChildren = (id: string, relatedTasks: GanttTask[]): void => {
    const children = tasks.filter((task) => task.parentTaskId === id)
    for (const child of children) {
      relatedTasks.push(child) // Add child to the list
      findChildren(child.id, relatedTasks) // Recursively find the child's children
    }
  }

  const relatedTasks: GanttTask[] = []
  const task = getTaskById(taskId)

  if (task) {
    relatedTasks.push(task)
    findParentsAndSiblings(task, relatedTasks)
    findChildren(taskId, relatedTasks)
  }

  return relatedTasks
}

/**
 * Update related tasks based on the target task and action type.
 *
 * @param tasks - Array of Task objects.
 * @param targetTask - The task that is being updated, deleted, or inserted.
 * @param actionType - The type of action being performed: 'update', 'delete', or 'insert'.
 * @returns An array of updated Task objects.
 */
const updateRelatedTasksByTargetTask = (
  tasks: GanttTask[],
  targetTask: GanttTask,
  actionType: 'update' | 'delete' | 'insert'
): GanttTask[] => {
  const updatedTasks: GanttTask[] = []

  /**
   * Update the parent task based on its child tasks.
   *
   * @param parentTaskId - The ID of the parent task to update.
   * @returns The updated parent task, or undefined if not found.
   */
  const updateParentTask = (parentTaskId: string): GanttTask | undefined => {
    const parentTask = tasks.find((task) => task.id === parentTaskId)
    if (!parentTask) return undefined

    // Filter out the child tasks of the parent task, excluding the target task
    const childTasks = tasks.filter(
      (task) => task.parentTaskId === parentTaskId && task.id !== targetTask.id
    )

    // Determine the target start and end dates based on the action type
    const targetStartDate =
      actionType === 'delete' ? null : targetTask.startDate
    const targetEndDate = actionType === 'delete' ? null : targetTask.dueDate

    // Update the parent task's start date to the earliest start date of its child tasks
    const minStartDate = getMinStartDate(childTasks, targetStartDate)
    parentTask.startDate = minStartDate
      ? dayjs(minStartDate).format(JsonDateFormat.YYYYMMDD)
      : null

    // Update the parent task's due date to the latest due date of its child tasks
    const maxDueDate = getMaxDueDate(childTasks, targetEndDate)
    parentTask.dueDate = maxDueDate
      ? dayjs(maxDueDate).format(JsonDateFormat.YYYYMMDD)
      : null

    // Update the parent task's actual progress based on its child tasks
    parentTask.actualProgress = calculateActualProgress(childTasks)

    // Update the parent task's estimated work times based on its child tasks
    if (actionType === 'delete') {
      // Subtract the estimated work times of the deleted task
      if (typeof targetTask.estimatedWorkTimes === 'number') {
        const parentWorkTimes = parentTask.estimatedWorkTimes ?? 0

        if (parentWorkTimes === targetTask.estimatedWorkTimes) {
          parentTask.estimatedWorkTimes = null
        } else {
          parentTask.estimatedWorkTimes =
            parentWorkTimes - targetTask.estimatedWorkTimes
        }
      }
    } else if (actionType === 'insert') {
      // Add the estimated work times of the inserted task
      if (typeof targetTask.estimatedWorkTimes === 'number') {
        const parentWorkTimes = parentTask.estimatedWorkTimes ?? 0
        parentTask.estimatedWorkTimes =
          parentWorkTimes + targetTask.estimatedWorkTimes
      }
    } else {
      const changedEstimatedWorkTimes = calculateChangedEstimatedWorkTimes(
        tasks,
        targetTask
      )

      if (typeof changedEstimatedWorkTimes === 'number') {
        const parentWorkTimes = parentTask.estimatedWorkTimes ?? 0
        const newWorkTimes = parentWorkTimes + changedEstimatedWorkTimes

        // Update estimated work times, ensuring it does not go below zero
        parentTask.estimatedWorkTimes = newWorkTimes >= 0 ? newWorkTimes : null
      } else {
        const targetWorkTimes = targetTask.estimatedWorkTimes ?? 0
        const parentWorkTimes = parentTask.estimatedWorkTimes ?? 0

        // If parent's work times equals target's work times, set it to null
        if (parentWorkTimes === targetWorkTimes) {
          parentTask.estimatedWorkTimes = null
        } else {
          // Otherwise, subtract target's work times from parent's work times
          parentTask.estimatedWorkTimes = parentWorkTimes - targetWorkTimes
        }
      }
    }

    return parentTask
  }

  /**
   * Traverse and update all parent tasks recursively.
   *
   * @param parentTaskId - The ID of the parent task to start updating from.
   */
  const traverseAndUpdateParents = (parentTaskId: string): void => {
    let currentTask = updateParentTask(parentTaskId)

    while (currentTask) {
      updatedTasks.push(currentTask)
      if (!currentTask.parentTaskId) break
      currentTask = updateParentTask(currentTask.parentTaskId)
    }
  }

  // If the target task has a parent task, update all parent tasks
  if (targetTask.parentTaskId) {
    traverseAndUpdateParents(targetTask.parentTaskId)
  }

  // Add the target task to the list of updated tasks
  updatedTasks.push(targetTask)

  // If the action type is 'delete', add all child tasks of the target task to the list of updated tasks
  if (actionType === 'delete') {
    const childTasks = tasks.filter(
      (task) => task.parentTaskId === targetTask.id
    )
    childTasks.forEach((task) => {
      updatedTasks.push(task)
    })
  }

  return updatedTasks
}

export const useProjectDetailTaskListGantt = () => {
  const { projectId } = useParams()

  const [lazyGetGanttTasksQuery] = useLazyGetGanttTasksQuery()
  const [lazyGetTaskQuery] = useLazyGetTaskQuery()

  const [updateGanttTaskMutation] = useUpdateGanttTaskMutation()
  const [deleteTaskMutation] = useDeleteTaskMutation()

  const taskDataSource = useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        if (!projectId) return []
        const params: GetGanttTasksRequest = {
          projectId,
        }

        const response = await lazyGetGanttTasksQuery(params).unwrap()

        const tasks = cloneDeep(response.tasks)
        const newTasks = tasks.map((task) => {
          return {
            ...task,
            startDate: task.startDate
              ? dayjs(task.startDate).startOf('day').toDate()
              : undefined, // convert startDate to begin of day
            dueDate: task.dueDate
              ? dayjs(task.dueDate).endOf('day').toDate()
              : undefined, // convert endDate to end of day
            actualStartDate: task.actualStartDate
              ? dayjs(task.actualStartDate).toDate()
              : undefined,
            actualEndDate: task.actualEndDate
              ? dayjs(task.actualEndDate).toDate()
              : undefined,
            actualProgress: task.actualProgress ?? 0,
          }
        })

        return newTasks
      },
      byKey: async (taskId) => {
        const response = await lazyGetTaskQuery({ taskId }).unwrap()
        return response.task
      },
    }),
    [lazyGetGanttTasksQuery, lazyGetTaskQuery, projectId]
  )

  // Function to update a task in the Gantt chart
  const updateGanttTask = useCallback(
    async (task: UpdateGanttTaskRequest_Task) => {
      await updateGanttTaskMutation({
        task,
      }).unwrap()
    },
    [updateGanttTaskMutation]
  )

  // Function to delete a task
  const deleteTask = useCallback(
    async (taskId: string, version: number) => {
      await deleteTaskMutation({
        taskId,
        version,
      }).unwrap()
    },
    [deleteTaskMutation]
  )

  return {
    taskDataSource,

    updateGanttTask,
    deleteTask,
    findRelatedTasks,
    updateRelatedTasksByTargetTask,
    hasChildTask,
  }
}
