import { CrewScrollView } from 'components/devextreme/crewScrollView'
import { CrewSortable } from 'components/devextreme/crewSortable'
import { ProjectDetailTaskListKanbanCard } from 'features/project/components/projectDetailPage/components/projectDetailTaskList/components/projectDetailTaskListPanel/components/projectDetailTaskListKanban/components/ProjectDetailTaskListKanbanCard/ProjectDetailTaskListKanbanCard'
import { useProjectDetailTaskListKanban } from 'features/project/components/projectDetailPage/components/projectDetailTaskList/components/projectDetailTaskListPanel/components/projectDetailTaskListKanban/useProjectDetailTaskListKanban'
import { memo, useMemo, useRef } from 'react'
import { GetProjectTasksRequest } from '@crew/apis/project/models/getProjectTasks/request'
import { useLazyGetLookupKanbanBucketItemsQuery } from '@crew/apis/lookup/lookupApis'
import { useGetProjectTasksQuery } from '@crew/apis/project/projectApis'
import { skipToken } from '@reduxjs/toolkit/query'
import type { AddEvent, ReorderEvent } from 'devextreme/ui/sortable'
import { useCallback, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import qs from 'qs'
import { getParamAsArray, getParamAsDate, getParamAsString } from 'utils'
import { DetailTaskSearchOptions, TaskPriorities } from 'enums/app'
import { useShowApiErrors } from 'hooks/useShowApiErrors'

import {
  EntityType,
  KanbanBucketType,
  KanbanUpdatableColumn,
} from '@crew/enums/domain'
import { useProjectPermissions, useValueChangeEffect } from '@crew/hooks'
import { useTranslation } from '@crew/modules/i18n'
import { TaskEntryDialog } from 'features/task/components/taskEntryDialog/taskEntryDialog'
import { useModal } from 'components/layouts/modal/useModal'
import { useAppSelector } from 'states/hooks'

type AssignToUser = {
  id: string
  displayName: string
  version: number
}

type TaskKind = {
  id: string
  name: string
  displayColor: string
}

type TaskState = {
  id: string
  name: string
  displayColor: string
}

type TaskCategory = {
  id: string
  name: string
}

type ProjectTask = {
  id: string
  subject: string
  description: string | null
  startDate: string | null
  dueDate: string | null
  assignToUser: AssignToUser | null
  taskKind: TaskKind
  taskCategory: TaskCategory | null
  taskPriority: number
  taskState: TaskState
  estimatedWorkTimes: number | null
  actualWorkTimes: number | null
  version: number
}

type BucketItem = {
  id: string
  name: string
  displayColor: string | null
  version: number
}

const NotSetBucketItem: BucketItem = {
  id: 'NOT_SET',
  name: 'label.notSet',
  version: 0,
  displayColor: null,
}

// Function to check if the bucket type is a valid Kanban bucket type.
const isValidBucketType = (
  bucketType: string
): bucketType is KanbanBucketType =>
  Object.values(KanbanBucketType).includes(bucketType as KanbanBucketType)

// Function to remove an item from the tasks array at the specified index.
const removeItem = (tasks: ProjectTask[], removeIdx: number) => {
  // Return a new array excluding the item at removeIdx.
  return tasks.filter((_, idx) => idx !== removeIdx)
}

// Function to insert an item into the tasks array at the specified index.
const insertItem = (
  tasks: ProjectTask[],
  task: ProjectTask,
  insertIdx: number
) => {
  // Create a copy of the tasks array to avoid mutating the original array.
  const newArray = [...tasks]
  // Insert the new task at the specified index.
  newArray.splice(insertIdx, 0, task)

  return newArray
}

// Function to determine the group key based on the selected bucket type
const getGroupKey = (
  task: ProjectTask,
  selectedBucketType: string
): string | number => {
  switch (selectedBucketType) {
    case KanbanBucketType.AssignToUser:
      return task.assignToUser?.id ?? NotSetBucketItem.id
    case KanbanBucketType.TaskKind:
      return task.taskKind.id
    case KanbanBucketType.TaskCategory:
      return task.taskCategory?.id ?? NotSetBucketItem.id
    case KanbanBucketType.TaskState:
      return task.taskState.id
    default:
      return task.taskPriority
  }
}

// Function to get task lists by task state
const getTaskLists = (
  bucketItems: BucketItem[],
  taskArray: ProjectTask[],
  selectedBucketType: string
) => {
  // Reduce the taskArray to create a mapping of task bucket items to tasks.
  const tasksMap = taskArray.reduce(
    (result: { [key: string]: ProjectTask[] }, task: ProjectTask) => {
      const groupKey = getGroupKey(task, selectedBucketType)

      // Check if the task bucket item ID already exists in the result map.
      if (result[groupKey]) {
        // If it exists, push the task to the existing array.
        result[groupKey].push(task)
      } else {
        // If it doesn't exist, create a new array with the task.
        result[groupKey] = [task]
      }

      return result
    },
    {}
  )

  // Map the bucket items array to an array of task lists.
  return bucketItems.map(
    (bucketItem: BucketItem) => tasksMap[bucketItem.id] || []
  )
}

export const ProjectDetailTaskListKanban = memo(() => {
  const { t } = useTranslation()

  const [showApiError] = useShowApiErrors()

  const { updateKanbanTask } = useProjectDetailTaskListKanban()

  const [searchParams] = useSearchParams()
  const params = qs.parse(searchParams.toString())
  const { projectId } = useParams()

  const { hasPrjTaskEditPermission } = useProjectPermissions(
    EntityType.Project,
    projectId
  )

  const selectedTaskKanbanBucketType = useAppSelector(
    (state) => state.projectDetail.selectedTaskKanbanBucketType
  )

  const kanbanUpdatableColumn = useMemo(() => {
    switch (selectedTaskKanbanBucketType) {
      case KanbanBucketType.TaskKind:
        return KanbanUpdatableColumn.TaskKindId
      case KanbanBucketType.AssignToUser:
        return KanbanUpdatableColumn.AssignToUserId
      case KanbanBucketType.TaskCategory:
        return KanbanUpdatableColumn.TaskCategoryId
      case KanbanBucketType.TaskState:
        return KanbanUpdatableColumn.TaskStateId
      default:
        return KanbanUpdatableColumn.TaskPriority
    }
  }, [selectedTaskKanbanBucketType])

  const [isTaskEntryDialogOpen, openTaskEntryDialog, closeTaskEntryDialog] =
    useModal()

  const [lazyGetLookupKanbanBucketItemsQuery] =
    useLazyGetLookupKanbanBucketItemsQuery()

  // プロジェクトタスク一覧取得
  // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
  const getProjectTasksParams: GetProjectTasksRequest | undefined = projectId
    ? {
        projectId,
        keyword: getParamAsString(DetailTaskSearchOptions.Keyword.id, params),
        taskKindIds: getParamAsArray(
          DetailTaskSearchOptions.TaskKindId.id,
          params
        ),
        assignToUser: getParamAsString(
          DetailTaskSearchOptions.AssignToUser.id,
          params
        ),
        taskStateIds: getParamAsArray(
          DetailTaskSearchOptions.TaskStateId.id,
          params
        ),
        taskStateTypes: getParamAsArray(
          DetailTaskSearchOptions.TaskStateType.id,
          params
        ),
        taskPriorities: getParamAsArray(
          DetailTaskSearchOptions.TaskPriority.id,
          params
        )?.map((taskPriority) => Number(taskPriority)), // string[] -> number[]
        taskCategoryIds: getParamAsArray(
          DetailTaskSearchOptions.TaskCategoryId.id,
          params
        ),
        startDate: getParamAsDate(DetailTaskSearchOptions.StartDate.id, params),
        dueDate: getParamAsDate(DetailTaskSearchOptions.DueDate.id, params),
        createdById: getParamAsString(
          DetailTaskSearchOptions.CreatedById.id,
          params
        ),
        updatedById: getParamAsString(
          DetailTaskSearchOptions.UpdatedById.id,
          params
        ),
        createdAt: getParamAsDate(DetailTaskSearchOptions.CreatedAt.id, params),
        updatedAt: getParamAsDate(DetailTaskSearchOptions.UpdatedAt.id, params),
        targetDateFrom: undefined,
        targetDateTo: undefined,
      }
    : undefined

  const {
    data: getProjectTasksResult,
    refetch: getProjectTasksRefetch,
    isFetching: isFetchingProjectTasks,
  } = useGetProjectTasksQuery(getProjectTasksParams ?? skipToken)

  // プロジェクトタスク一覧格納用ステータス
  // NOTE: ボード間のドラッグによるカンバン移動後に一覧を再構成し、それを格納するためuseStateが必要
  const [taskList, setTaskList] = useState<Array<ProjectTask[]>>([])

  const priorityItems = useMemo(() => {
    return Object.values(TaskPriorities).map((item) => {
      return {
        id: String(item.value),
        name: t(item.text),
        displayColor: null,
        version: 0,
      }
    })
  }, [t])

  const selectedTaskKanbanBucketTypeRef = useRef(selectedTaskKanbanBucketType)

  const [kanbanBucketItems, setKanbanBucketItems] = useState<BucketItem[]>([])

  // Get bucket items of kanban bucket
  const getBucketItems = useCallback(
    async (bucketType: KanbanBucketType) => {
      if (!projectId) return

      const result = await lazyGetLookupKanbanBucketItemsQuery({
        projectId,
        bucketType,
      }).unwrap()

      // If the selected bucket type has changed while the API call is in progress, do not update the state.
      if (selectedTaskKanbanBucketTypeRef.current !== bucketType) return

      const newBucketItems = [...result.bucketItems]

      // Add "Not Set" item to the top of the list for AssignToUser and TaskCategory
      if (
        bucketType === KanbanBucketType.AssignToUser ||
        bucketType === KanbanBucketType.TaskCategory
      ) {
        newBucketItems.unshift(NotSetBucketItem)
      }

      setKanbanBucketItems(newBucketItems)
    },
    [lazyGetLookupKanbanBucketItemsQuery, projectId]
  )

  useValueChangeEffect(
    () => {
      // set selectedTaskKanbanBucketType to ref
      selectedTaskKanbanBucketTypeRef.current = selectedTaskKanbanBucketType

      // selectedTaskKanbanBucketType is kanban bucket type
      if (isValidBucketType(selectedTaskKanbanBucketType)) {
        // call API get bucket items of kanban bucket
        getBucketItems(selectedTaskKanbanBucketType)
      } else {
        // selectedTaskKanbanBucketType is priority
        setKanbanBucketItems(priorityItems)
      }

      // refetch tasks data
      getProjectTasksRefetch()
    },
    [
      getBucketItems,
      getProjectTasksRefetch,
      lazyGetLookupKanbanBucketItemsQuery,
      priorityItems,
      projectId,
      selectedTaskKanbanBucketType,
    ],
    selectedTaskKanbanBucketType
  )

  // setTaskListでタスク一覧を格納するためuseEffectが必要
  useEffect(() => {
    // タスク状態（ボード）別にタスク一覧を配列化
    if (getProjectTasksResult?.tasks && kanbanBucketItems) {
      setTaskList(
        getTaskLists(
          kanbanBucketItems,
          getProjectTasksResult.tasks,
          selectedTaskKanbanBucketType
        )
      )
    }
  }, [
    getProjectTasksResult?.tasks,
    // refetchProjectTasks will be called when error occurs
    isFetchingProjectTasks,
    kanbanBucketItems,
    selectedTaskKanbanBucketType,
  ])

  // refetch tasks data
  const refetchProjectTasks = useCallback(() => {
    getProjectTasksRefetch()
  }, [getProjectTasksRefetch])

  // Handle event task item is dropped
  const handleTaskItemDrop = useCallback(
    async (event: ReorderEvent | AddEvent) => {
      if (kanbanBucketItems.length === 0) return

      const updatedLists = [...taskList]

      // get item from the source list
      const item = updatedLists[event.fromData][event.fromIndex]

      // remove item from the source list
      updatedLists[event.fromData] = removeItem(
        updatedLists[event.fromData],
        event.fromIndex
      )

      // Function to create the updated task item
      const createUpdatedTaskItem = (item: ProjectTask, toData: number) => ({
        ...item,
        taskState:
          kanbanUpdatableColumn === KanbanUpdatableColumn.TaskStateId
            ? {
                ...kanbanBucketItems[toData],
                // taskState always has displayColor, but it is not recognized by typescript
                displayColor: kanbanBucketItems[toData].displayColor as string,
              }
            : item.taskState,
        taskCategory:
          kanbanUpdatableColumn === KanbanUpdatableColumn.TaskCategoryId
            ? kanbanBucketItems[toData]
            : item.taskCategory,
        taskPriority:
          kanbanUpdatableColumn === KanbanUpdatableColumn.TaskPriority
            ? Number(kanbanBucketItems[toData].id)
            : item.taskPriority,
        assignToUser:
          kanbanUpdatableColumn === KanbanUpdatableColumn.AssignToUserId
            ? kanbanBucketItems[toData].id === NotSetBucketItem.id
              ? null
              : {
                  ...kanbanBucketItems[toData],
                  // map bucket item name to assignToUser displayName
                  displayName: kanbanBucketItems[toData].name,
                }
            : item.assignToUser,
        taskKind:
          kanbanUpdatableColumn === KanbanUpdatableColumn.TaskKindId
            ? {
                ...kanbanBucketItems[toData],
                // taskKind always has displayColor, but it is not recognized by typescript
                displayColor: kanbanBucketItems[toData].displayColor as string,
              }
            : item.taskKind,
        version:
          event.fromData === event.toData ? item.version : item.version + 1,
      })

      // Create the updated task item
      const insertTaskItem = createUpdatedTaskItem(item, event.toData)

      // insert item to the destination list
      updatedLists[event.toData] = insertItem(
        updatedLists[event.toData],
        insertTaskItem,
        event.toIndex
      )

      setTaskList(updatedLists)

      // Do nothing if the task is dropped in the same list
      if (event.fromData === event.toData) return

      // Execute update task state process
      try {
        await updateKanbanTask(
          item.id,
          kanbanUpdatableColumn,
          kanbanBucketItems[event.toData].id === NotSetBucketItem.id
            ? ''
            : kanbanBucketItems[event.toData].id,
          item.version
        )
      } catch (error) {
        // refetch tasks data when error
        refetchProjectTasks()

        showApiError(error)
        return
      }
    },
    [
      kanbanBucketItems,
      kanbanUpdatableColumn,
      refetchProjectTasks,
      showApiError,
      taskList,
      updateKanbanTask,
    ]
  )

  const editTaskId = useRef<string>()
  const handleEditTask = useCallback(
    (taskId: string) => {
      editTaskId.current = taskId
      openTaskEntryDialog()
    },
    [openTaskEntryDialog]
  )

  // タスク編集ダイアログの編集完了後
  const handleTaskUpdated = useCallback(() => {
    closeTaskEntryDialog()

    // refetch tasks data
    refetchProjectTasks()
  }, [closeTaskEntryDialog, refetchProjectTasks])

  // タスク編集ダイアログの削除完了後
  const handleTaskDeleted = useCallback(() => {
    closeTaskEntryDialog()

    // refetch tasks data
    refetchProjectTasks()
  }, [closeTaskEntryDialog, refetchProjectTasks])

  return (
    <>
      <CrewScrollView showScrollbar="always" direction="both">
        <CrewSortable itemOrientation="horizontal" handle=".list-title">
          <div className="flex gap-x-3.5 p-2">
            {taskList.map((tasks, index) => {
              const bucketItem = kanbanBucketItems[index]
              return (
                <div
                  key={bucketItem?.id}
                  className="crew-task-list-kanban w-80 bg-crew-gray-2-light dark:bg-crew-gray-4-dark rounded-xl"
                >
                  <div className="my-2.5 text-center">
                    {t(bucketItem?.name)}
                  </div>
                  <CrewScrollView
                    className="py-2.5"
                    direction="vertical"
                    showScrollbar="never"
                  >
                    {/* 列の最小幅を制限し、タスク一覧が正しく表示されるようにする */}
                    <CrewSortable
                      className="min-h-[50vh] flex flex-col gap-y-1.5 mx-2.5"
                      group="cardsGroup"
                      data={index}
                      onReorder={handleTaskItemDrop}
                      onAdd={handleTaskItemDrop}
                    >
                      {tasks.map((task) => (
                        <ProjectDetailTaskListKanbanCard
                          key={task.id}
                          item={task}
                          hasPrjTaskEditPermission={hasPrjTaskEditPermission}
                          onEditTask={handleEditTask}
                        />
                      ))}
                    </CrewSortable>
                  </CrewScrollView>
                </div>
              )
            })}
          </div>
        </CrewSortable>
      </CrewScrollView>

      <TaskEntryDialog
        isEditMode={true}
        title={t('label.editTaskTitle')}
        onSubmit={handleTaskUpdated}
        onDeleted={handleTaskDeleted}
        isOpen={isTaskEntryDialogOpen}
        onClose={closeTaskEntryDialog}
        taskId={editTaskId.current}
        projectId={projectId}
      />
    </>
  )
})
