import { SEARCH_TIMEOUT_MSEC } from '@crew/configs/constants'
import { useProjectPermissions, useValueChangeEffect } from '@crew/hooks'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewLoadingSpinner } from 'components/devextreme/crewLoadingSpinner'
import { CrewScrollView } from 'components/devextreme/crewScrollView'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { CrewErrorDialog } from 'components/elements/crewErrorDialog/crewErrorDialog'
import { CrewFieldLabel } from 'components/elements/crewFieldLabel'
import { CrewLink } from 'components/elements/crewLink/crewLink'
import { CrewTaskPrioritySelectBoxFieldRender } from 'components/elements/crewTaskPrioritySelectBoxFieldRender/crewTaskPrioritySelectBoxFieldRender'
import { CrewTaskPrioritySelectBoxItemRender } from 'components/elements/crewTaskPrioritySelectBoxItemRender/crewTaskPrioritySelectBoxItemRender'
import { CrewBadgeSelectBoxField } from 'components/forms/crewBadgeSelectBoxField'
import { CrewDatePickerField } from 'components/forms/crewDatePickerField'
import { CrewErrorSummary } from 'components/forms/crewErrorSummary'
import { CrewHtmlEditorField } from 'components/forms/crewHtmlEditorField'
import { CrewTagBoxField } from 'components/forms/crewTagBoxField'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { DatePickerDateFormat } from 'enums/system'
import { useTaskEntryForm } from 'features/task/components/taskEntryDialog/components/taskEntryForm/useTaskEntryForm'
import { useFocusInput } from 'hooks/useFocusInput'
import { FC, memo } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { useAppSelector } from 'states/hooks'
import { useGetTaskQuery } from '@crew/apis/task/taskApis'
import dayjs from '@crew/modules/dayjs'
import { useToast } from 'hooks/useToast'
import { TaskPriorities } from 'enums/app'
import { EntityType } from '@crew/enums/domain'
import { useModal } from 'components/layouts/modal/useModal'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { UploadFile } from 'models/domain/uploadFile'
import { GetTaskRequest } from '@crew/apis/task/models/getTask/request'
import { skipToken } from '@reduxjs/toolkit/query'
import { TaskKindRef, TaskCategoryRef } from '@crew/models/refs'
import { ComponentCallbackHandler, convertMinutesToHHMM } from '@crew/utils'
import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import { FormValues } from './useTaskEntryForm'
import { CrewCheckBoxField } from 'components/forms/crewCheckBoxField'

type GroupComponentType<T> = {
  data: {
    items: T[]
    key: string
  }
}

// Render task type group name
const renderTaskKindGroupName = ({ data }: GroupComponentType<TaskKindRef>) => {
  // プロジェクトに紐づかないデータは仕様上ありえないが、型制約の関係上チェック処理を追加
  const item = data.items.find((item) => item.projectId)

  if (!item) {
    return null
  }

  return <span>{item.projectName}</span>
}

// Render task category group name
const renderTaskCategoryGroupName = ({
  data,
}: GroupComponentType<TaskCategoryRef>) => {
  // プロジェクトに紐づかないデータは仕様上ありえないが、型制約の関係上チェック処理を追加
  const item = data.items.find((item) => item.projectId)

  if (!item) {
    return null
  }

  return <span>{item.projectName}</span>
}

export type TaskFormProps = {
  isEditMode: boolean
  taskId?: string
  projectId?: string
  parentTaskId?: string
  onSubmit: (taskId: string) => void
  onCancel: () => void
  onDeleted?: (taskId: string) => void
  defaultDueDate?: Date
  defaultAssignToUserId?: string
}

export const TaskEntryForm: FC<TaskFormProps> = memo((props) => {
  const {
    targetEntityRecordId,

    handleSubmit,
    formState,
    reset,
    control,
    setError,
    setValue,
    getValues,

    validateRules,

    entityType,
    setEntityType,
    entityRecordId,
    setEntityRecordId,

    notificationRecipientDataSource,
    taskKindDataSource,
    taskPriorityDataSource,
    taskCategoryDataSource,
    userDataSource,
    entityTypeDataSource,
    entityRecordDataSource,

    insertTask,
    updateTask,
    deleteTask,
    isLoadingInsertTask,
    isLoadingUpdateTask,
    isLoadingDeleteTask,
  } = useTaskEntryForm(props)

  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

  const { t } = useTranslation()

  // 添付ファイル（アップロードファイル）情報格納用の配列
  const [uploadedFileList, setUploadedFileList] = useState<UploadFile[]>([])
  const [taskVersion, setTaskVersion] = useState(0)
  const [parentTaskId, setParentTaskId] = useState('')

  const [needNotification, setNeedNotification] = useState(false)

  const [isLoading] = useState(false)

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')

  const [isErrorDialogOpen, closeErrorDialog] = useModal()

  // エラーダイアログメッセージ
  const [errorMessage] = useState('')

  const newTaskDialogClose = useCallback(() => {
    props.onCancel()
  }, [props])

  const editTaskDialogClose = useCallback(() => {
    props.onCancel()
  }, [props])

  const toast = useToast()

  // TODO:「進捗管理から除外する」の表示は、下記タスクで仕様確定するまでは一時的に非表示とする。
  //       https://break-tmc.atlassian.net/browse/CREW-16286
  // const { doProjectManagement } =
  //   useProjectAdvancedSettings(targetEntityRecordId)

  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  // 削除確認ダイアログ OKボタン
  const handleDeletePermitButtonClick = useCallback(async () => {
    if (props.taskId) {
      try {
        await deleteTask(props.taskId, taskVersion)
        toast.success(t('message.task.taskDeleted'))

        editTaskDialogClose()

        // 削除後は呼び出し元でページ遷移などを行う
        if (props.onDeleted) {
          props.onDeleted(props.taskId)
          return
        }
      } catch (err) {
        showApiErrors(err)
      }
    }
    closeConfirmDialog()
  }, [
    closeConfirmDialog,
    deleteTask,
    editTaskDialogClose,
    props,
    showApiErrors,
    t,
    taskVersion,
    toast,
  ])

  // Get initial event kind id
  const getInitialTaskKindId = useCallback(async () => {
    //Need get taskKinds from eventKindDataSource
    const taskKinds = (await taskKindDataSource.store().load()) as TaskKindRef[]

    if (taskKinds.length === 0) return null

    // get initial task type
    const initialTaskKind = taskKinds.find((taskKind) => taskKind.initialValue)

    let initTaskKindId = null
    // if initial task type is found
    if (initialTaskKind) {
      //set initial task type
      initTaskKindId = initialTaskKind.id
    } else {
      // 初期値の設定されている項目が無ければそのテナントの最初の項目
      initTaskKindId = taskKinds[0].id
    }

    return initTaskKindId
  }, [taskKindDataSource])

  //when entityType changed value reset value dropdown entityRecord and value dropdown assign to user
  const entityTypeOptionChanged = useCallback<
    ComponentCallbackHandler<typeof CrewSelectBoxField, 'onValueChanged'>
  >(
    (event) => {
      if (entityType !== event.value) {
        setEntityType(event.value)
        setValue('entityRecordId', null)
        setValue('assignToUserId', null)
      }
    },
    [entityType, setEntityType, setValue]
  )

  //when entityRecordId changed value reset value dropdown assign to user
  const entityRecordIdOptionChanged = useCallback<
    ComponentCallbackHandler<typeof CrewSelectBoxField, 'onValueChanged'>
  >(
    (event) => {
      if (entityRecordId !== event.value) {
        setEntityRecordId(event.value)
      }
    },
    [entityRecordId, setEntityRecordId]
  )

  // プロジェクトIDが変更された場合、初期値を設定する
  useValueChangeEffect(
    () => {
      if (!props.isEditMode) {
        const initializeData = async (entityRecordId: string) => {
          const eventKindId = await getInitialTaskKindId()
          setValue('taskKindId', eventKindId)

          setValue('entityRecordId', entityRecordId)

          setValue('assignToUserId', props.defaultAssignToUserId ?? null)
        }

        if (entityRecordId) {
          initializeData(entityRecordId)
        }
      }
    },
    [
      entityRecordId,
      getInitialTaskKindId,
      props.isEditMode,
      setValue,
      props.defaultAssignToUserId,
    ],
    entityRecordId
  )

  // タスク詳細を取得する
  // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
  const getTaskParam: GetTaskRequest | undefined = props.taskId
    ? {
        taskId: props.taskId,
      }
    : undefined
  const { data: getTaskResult } = useGetTaskQuery(getTaskParam ?? skipToken)

  // handle submit form and call api register event
  const handleSubmitButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        if (props.isEditMode && props.taskId) {
          // Execute update task process
          const result = await updateTask(
            props.taskId,
            taskVersion,
            data,
            // Because there is no item on the screen to specify the ParentTask, we will keep the ParentTaskId that was obtained from the database.
            parentTaskId ?? undefined
          )

          // close dialog
          editTaskDialogClose()

          // Submit後の処理は呼び出し元で実行
          props.onSubmit(result.task?.id as string)

          toast.success(t('message.task.taskUpdated'))
        } else {
          // タスク登録APIリクエスト
          const result = await insertTask(
            data,
            uploadedFileList,
            parentTaskId ?? undefined
          )

          newTaskDialogClose()

          // Submit後の処理は呼び出し元で実行
          props.onSubmit(result.task?.id as string)

          toast.success(t('message.task.taskRegistered'))
        }
      } catch (err) {
        showApiErrors(err)
      }
    }
    handleSubmit(onSubmit)()
  }, [
    handleSubmit,
    props,
    updateTask,
    taskVersion,
    parentTaskId,
    editTaskDialogClose,
    toast,
    t,
    insertTask,
    uploadedFileList,
    newTaskDialogClose,
    showApiErrors,
  ])
  const handleDeleteButtonClick = useCallback(() => {
    if (getTaskResult?.task?.hasChildTasks) {
      setConfirmMessage(t('message.task.confirmDeleteLinkedSubtasks'))
    } else {
      setConfirmMessage(t('message.general.confirmMessage.delete'))
    }

    // 確認ダイアログの表示（処理は確認ダイアログのOKボタン押下時に行う）
    openConfirmDialog()
  }, [getTaskResult?.task?.hasChildTasks, openConfirmDialog, t])

  // ファイルアップロード中かどうか
  const isFileUploading = useMemo(() => {
    return uploadedFileList.some((file) => file.progress)
  }, [uploadedFileList])

  // 添付ファイルアップロード完了後
  const handleUploaded = useCallback((file: UploadFile) => {
    // ファイル一覧にアップロードしたファイルを追加
    setUploadedFileList((baseData) => {
      //replace same file name
      const index = baseData.findIndex((item) => item.name === file.name)
      if (index === -1) {
        return [...baseData, file]
      } else {
        // replace the file with the same name
        baseData[index] = file
        return [...baseData]
      }
    })
  }, [])

  // 添付ファイル削除ボタン押下時
  const handleDeleteFile = useCallback((file: UploadFile) => {
    // uploadedFileListに格納している該当ファイル情報を削除する
    setUploadedFileList((baseData) =>
      baseData.filter((item) => item.name !== file.name)
    )
  }, [])

  // フォーム初期化処理関数
  const initializeForm = useCallback(async () => {
    if (props.taskId) {
      if (getTaskResult?.task) {
        setEntityType(getTaskResult.task.entityType)
        setEntityRecordId(getTaskResult.task.entityRecordId)
        setTaskVersion(getTaskResult.task.version)
        setParentTaskId(getTaskResult.task.parentTaskId ?? '')
        reset({
          entityType: getTaskResult.task.entityType,
          entityRecordId: getTaskResult.task.entityRecordId,
          taskKindId: getTaskResult.task.taskKind.id,
          subject: getTaskResult.task.subject,
          startDate: getTaskResult.task.startDate
            ? dayjs(getTaskResult.task.startDate).toDate()
            : null,
          dueDate: getTaskResult.task.dueDate
            ? dayjs(getTaskResult.task.dueDate).toDate()
            : null,
          taskPriority: getTaskResult.task.taskPriority,
          taskCategoryId: getTaskResult.task.taskCategory?.id ?? null,
          assignToUserId: getTaskResult.task.assignToUser?.id ?? null,
          estimatedWorkTimes:
            typeof getTaskResult.task.estimatedWorkTimes === 'number'
              ? convertMinutesToHHMM(getTaskResult.task.estimatedWorkTimes)
              : '',
          description: getTaskResult.task.description ?? '',
          isProgressManagementDisabled:
            getTaskResult.task.isProgressManagementDisabled,
        })
      }
    } else {
      setParentTaskId(props.parentTaskId ?? '')
      reset({
        entityType: EntityType.Project,
        entityRecordId: props.projectId,
        taskPriority: TaskPriorities.Normal.value, //set default task priority to normal
        // プロジェクト管理がONの場合は、チェックボックスを表示し、初期値はOFF
        // プロジェクト管理がOFFの場合は、チェックボックスを非表示で、データ上は常にON
        // TODO:「進捗管理から除外する」の表示は、下記タスクで仕様確定するまでは一時的に非表示とする。
        //       https://break-tmc.atlassian.net/browse/CREW-16286
        // isProgressManagementDisabled: !doProjectManagement,
        dueDate: props.defaultDueDate ?? null,
      })
    }
  }, [
    getTaskResult?.task,
    props.parentTaskId,
    props.projectId,
    props.taskId,
    reset,
    setEntityRecordId,
    setEntityType,
    props.defaultDueDate,
  ])

  // 初期化処理をuseEffect化
  // TODO: 本当はuseEffectを外したいのだが、レンダリングが大量に走ってしまうためこのようにしている。以下タスクで調査・対応予定
  // https://break-tmc.atlassian.net/browse/CREW-6028
  useEffect(() => {
    // フォーム初期化を実行
    initializeForm()
  }, [initializeForm])

  //set 担当者 to current user
  const handleAssignTextLinkClick = useCallback(() => {
    if (loggedInUser) {
      setValue('assignToUserId', loggedInUser.id)
    }
  }, [loggedInUser, setValue])

  const canSend = useMemo(
    // fromState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
    // check permission create task
    () => Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
    // formStateはproxyなのでformState自体をlistenする必要がある
    // https://react-hook-form.com/api/useform/formstate
    [formState]
  )

  useFocusInput('subject')

  const {
    hasPrjTaskDeletePermission,
    hasPrjFileCreatePermission,
    hasPrjTaskCreatePermission,
    hasPrjTaskEditPermission,
  } = useProjectPermissions(
    entityType as EntityType,
    targetEntityRecordId ?? undefined
  )

  // Determine whether you have permission to create tasks or update tasks
  const hasUpsertTaskPermission = useMemo(() => {
    return (
      (!props.isEditMode && hasPrjTaskCreatePermission) ||
      (props.isEditMode && hasPrjTaskEditPermission)
    )
  }, [hasPrjTaskCreatePermission, hasPrjTaskEditPermission, props.isEditMode])

  // チェックボックスの値が変更されたときの処理
  const handleNeedNotificationChange = useCallback(() => {
    setNeedNotification(getValues('needNotification') ?? false)
  }, [getValues])

  // 子タスクが紐づいているタスクの場合は、「予定開始日」、「予定終了日」、「予定作業時間」の編集は不可とする。
  const canEditPlanStartAndEndDate = useMemo(
    () => !getTaskResult?.task?.hasChildTasks || !props.isEditMode,
    [getTaskResult?.task?.hasChildTasks, props.isEditMode]
  )

  return (
    <>
      <form className="flex flex-col h-full">
        <CrewScrollView>
          {/* モーダルの最小幅を制限し、開始日、終了日、所有者など画面の各入力項目が正しく表示されるようにする */}
          <div className="overflow-x-auto">
            <div className="flex flex-col gap-y-5 min-w-[640px]">
              <div className="grid grid-cols-4 gap-x-2.5">
                {/* 関連先タイプ */}
                <div>
                  <CrewSelectBoxField
                    id="entityType"
                    name="entityType"
                    control={control}
                    dataSource={entityTypeDataSource}
                    labelMode="hidden"
                    valueExpr="id"
                    displayExpr="name"
                    searchEnabled={false}
                    // NOTE: searchEnabled=falseだとデフォルト値の"1"が適用となりloadされないので"0"を設定
                    minSearchLength={0}
                    rules={validateRules.entityType}
                    label={t('label.entityType')}
                    required={true}
                    onValueChanged={entityTypeOptionChanged}
                    readOnly={
                      props.projectId || props.isEditMode ? true : false
                    }
                    showClearButton={false}
                  />
                </div>
                {/* 関連先 */}
                <div className="col-span-3">
                  <CrewSelectBoxField
                    id="entityRecordId"
                    name="entityRecordId"
                    control={control}
                    dataSource={entityRecordDataSource}
                    labelMode="hidden"
                    valueExpr="id"
                    displayExpr="name"
                    searchExpr="name"
                    searchEnabled={true}
                    // NOTE: searchEnabled=falseだとデフォルト値の"1"が適用となりloadされないので"0"を設定
                    minSearchLength={0}
                    rules={validateRules.entityType}
                    label={t('label.entityRecord')}
                    required={true}
                    onValueChanged={entityRecordIdOptionChanged}
                    readOnly={
                      props.projectId || props.isEditMode ? true : false
                    }
                    showClearButton={false}
                  />
                </div>
              </div>

              {/* 件名 */}
              <div className="col-span-3">
                <CrewTextBoxField
                  id="subject"
                  name="subject"
                  control={control}
                  labelMode="hidden"
                  rules={validateRules.subject}
                  label={t('label.subject')}
                  required={true}
                />
              </div>

              <div className="grid grid-cols-4 gap-x-2.5">
                {/* 種別 */}
                <div>
                  <CrewBadgeSelectBoxField
                    id="taskKindId"
                    name="taskKindId"
                    control={control}
                    dataSource={taskKindDataSource}
                    labelMode="hidden"
                    searchEnabled={false}
                    // NOTE: searchEnabled=falseだとデフォルト値の"1"が適用となりloadされないので"0"を設定
                    minSearchLength={0}
                    rules={validateRules.taskKindId}
                    label={t('label.classification')}
                    required={true}
                    deferRendering={false}
                    showClearButton={false}
                    groupComponent={renderTaskKindGroupName}
                    // プロジェクトIDが存在する場合にのみグループ化を行う（タスク編集の場合又はプロジェクト詳細画面でタスクを作成する場合）
                    grouped={targetEntityRecordId ? false : true}
                  />
                </div>
                {/* 優先度 */}
                <div>
                  <CrewSelectBoxField
                    id="taskPriority"
                    name="taskPriority"
                    control={control}
                    dataSource={taskPriorityDataSource}
                    fieldRender={CrewTaskPrioritySelectBoxFieldRender}
                    itemRender={CrewTaskPrioritySelectBoxItemRender}
                    labelMode="hidden"
                    valueExpr="id"
                    displayExpr="name"
                    searchEnabled={false}
                    minSearchLength={0}
                    rules={validateRules.taskPriority}
                    label={t('label.priority')}
                    required={true}
                    showClearButton={false}
                  />
                </div>

                {/* 分類 */}
                <div>
                  <CrewSelectBoxField
                    id="taskCategoryId"
                    name="taskCategoryId"
                    control={control}
                    dataSource={taskCategoryDataSource}
                    labelMode="hidden"
                    valueExpr="id"
                    displayExpr="name"
                    searchEnabled={false}
                    minSearchLength={0}
                    label={t('label.category')}
                    groupComponent={renderTaskCategoryGroupName}
                    // プロジェクトIDが存在する場合にのみグループ化を行う（タスク編集の場合又はプロジェクト詳細画面でタスクを作成する場合）
                    grouped={targetEntityRecordId ? false : true}
                  />
                </div>

                {/* 担当者 */}
                <div className="flex flex-col gap-y-1">
                  <div className="flex items-center justify-between">
                    <CrewFieldLabel text={t('label.assignedUser')} />

                    {/* 私が担当 */}
                    <CrewLink
                      className="text-sm underline"
                      onClick={handleAssignTextLinkClick}
                    >
                      {t('label.assignToMe')}
                    </CrewLink>
                  </div>
                  <CrewSelectBoxField
                    id="assignToUserId"
                    name="assignToUserId"
                    control={control}
                    dataSource={userDataSource}
                    labelMode="hidden"
                    valueExpr="id"
                    displayExpr="displayName"
                    searchExpr="displayName"
                    searchEnabled={true}
                    minSearchLength={0}
                  />
                </div>
              </div>

              <div className="grid grid-cols-4 gap-x-2.5">
                {/* 開始日 */}
                <div>
                  <CrewDatePickerField
                    id="startDate"
                    name="startDate"
                    control={control}
                    labelMode="hidden"
                    displayFormat={DatePickerDateFormat.YYYYMMDD}
                    // TODO: 日付のplaceholderの有無について確認
                    // https://break-tmc.atlassian.net/browse/CREW-1481
                    showClearButton={true}
                    rules={validateRules.startDate}
                    label={t('label.startDate')}
                    disabled={!canEditPlanStartAndEndDate}
                  />
                </div>

                {/* 期限 */}
                <div>
                  <CrewDatePickerField
                    id="dueDate"
                    name="dueDate"
                    control={control}
                    labelMode="hidden"
                    displayFormat={DatePickerDateFormat.YYYYMMDD}
                    showClearButton={true}
                    rules={validateRules.dueDate}
                    label={t('label.dueDate')}
                    disabled={!canEditPlanStartAndEndDate}
                  />
                </div>

                {/* 予定時間 */}
                <div>
                  <CrewTextBoxField
                    id="estimatedWorkTimes"
                    name="estimatedWorkTimes"
                    control={control}
                    labelMode="hidden"
                    rules={validateRules.estimatedWorkTimes}
                    label={t('label.scheduledTime')}
                    placeholder="hh:mm"
                    className="align-right-input"
                    disabled={!canEditPlanStartAndEndDate}
                  />
                </div>

                {/* TODO:「進捗管理から除外する」の表示は、下記タスクで仕様確定するまでは一時的に非表示とする。
                          https://break-tmc.atlassian.net/browse/CREW-16286 */}
                {/* 進捗管理から除外 */}
                {/* {doProjectManagement && (
                  <div className="mt-6">
                    <CrewCheckBoxField
                      id="isProgressManagementDisabled"
                      name="isProgressManagementDisabled"
                      showLabel
                      control={control}
                      rules={validateRules.isProgressManagementDisabled}
                      label={t('label.excludedFromProgressManagement')}
                    />
                  </div>
                )} */}
              </div>

              {/* FIXME: CrewHtmlEditor自体にスタイルクラスを組み込むようにするべきか検討する
                          https://break-tmc.atlassian.net/browse/CREW-4450 */}
              <div className="crew-slim-toolbar-item">
                {/* 内容 */}
                <CrewHtmlEditorField
                  id="description"
                  name="description"
                  minHeight="7rem"
                  control={control}
                  rules={validateRules.description}
                  label={t('label.content')}
                  fileUploaderDisabled={
                    // 編集時 または ファイル作成権限がない場合はファイルアップロード無効
                    props.isEditMode || !hasPrjFileCreatePermission
                  }
                  uploadedFileList={uploadedFileList}
                  onUploaded={handleUploaded}
                  onDeleteUploadedFile={handleDeleteFile}
                  disabledMention={true}
                />
              </div>

              {/* チャットに投稿する */}
              <CrewCheckBoxField
                id="needNotification"
                name="needNotification"
                control={control}
                label={t('label.needNotification')}
                rules={validateRules.needNotification}
                defaultValue={false}
                onValueChanged={handleNeedNotificationChange}
              />

              {needNotification && (
                <div>
                  {/* 通知先 */}
                  <CrewTagBoxField
                    id="notificationUserIds"
                    name="notificationUserIds"
                    control={control}
                    displayExpr="displayName"
                    valueExpr="id"
                    dataSource={notificationRecipientDataSource}
                    searchEnabled={true}
                    searchMode="contains"
                    searchExpr="displayName"
                    searchTimeout={SEARCH_TIMEOUT_MSEC}
                    minSearchLength={0}
                    labelMode="hidden"
                    label={t('label.notificationRecipient')}
                  />
                </div>
              )}

              <CrewErrorSummary formState={formState} />
            </div>
          </div>
        </CrewScrollView>
        <div className="flex justify-between items-center">
          {props.isEditMode && hasPrjTaskDeletePermission && (
            <CrewButton
              text={t('action.delete')}
              type="danger"
              onClick={handleDeleteButtonClick}
              disabled={isLoadingDeleteTask}
            />
          )}

          <div className="ml-auto flex gap-x-5">
            <CrewButton
              text={t('action.register')}
              type="primary"
              onClick={handleSubmitButtonClick}
              disabled={
                !canSend ||
                !hasUpsertTaskPermission ||
                isFileUploading ||
                isLoadingInsertTask ||
                isLoadingUpdateTask
              }
            />
            <CrewButton
              text={t('action.cancel')}
              type="normal"
              stylingMode="outlined"
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
      {/* 削除確認ダイアログ */}
      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleDeletePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
        permitButtonDisabled={isLoadingDeleteTask}
      />
      <CrewLoadingSpinner visible={isLoading} />
      {/* エラーダイアログ */}
      <CrewErrorDialog
        isOpen={isErrorDialogOpen}
        message={errorMessage}
        onCloseButtonClick={closeErrorDialog}
      />
    </>
  )
})
