import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { memo } from 'react'
import { useUserListToolbar } from './useUserListToolbar'
import { CrewSelectBox } from 'components/devextreme/crewSelectBox'
import { TenantUserEntryDialog } from '../../../tenantUserEntryDialog/tenantUserEntryDialog'
import { EditTenantUserEntryDialog } from '../editTenantUserEntryDialog/editTenantUserEntryDialog'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { TextBox } from 'devextreme-react'
import { debounce } from 'lodash'
import qs from 'qs'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { SEARCH_TIMEOUT_MSEC } from '@crew/configs/constants'
import { useTranslation } from '@crew/modules/i18n'
import { ComponentCallbackHandler, isEqualParams } from '@crew/utils'
import { useModal } from 'components/layouts/modal/useModal'
import { TenantSettingUserStateType } from 'enums/app'
import { tenantSettingUserStateKeywordUpdated } from 'features/tenant/components/tenantSettingPage/state/tenantSettingPageSlice'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useAppDispatch } from 'states/hooks'
import { useToast } from 'hooks/useToast'
import { useTenantUserListContext } from '../../useUserList'
import { TenantUserBulkInviteDialog } from '../../../tenantUserBulkInviteDialog/tenantUserBulkInviteDialog'
import { useSystemPermissions } from '@crew/hooks'
import { TenantUserBulkAddDialog } from '../../../tenantUserBulkAddDialog/tenantUserBulkAddDialog'

export const UserListToolbar = memo(() => {
  const {
    stateFilterDataSource,
    isLoadingDeleteUserPending,
    deletePendingUsers,
  } = useUserListToolbar()

  const { t } = useTranslation()

  const { navigate } = useCrewNavigate()

  const location = useLocation()

  const [searchParams] = useSearchParams()

  const dispatch = useAppDispatch()

  const { success, error } = useToast()

  const {
    hasSysExternalUserInvitePermission,
    hasSysInternalUserInvitePermission,
  } = useSystemPermissions()

  const [
    isTenantUserEntryDialogOpen,
    openTenantUserEntryDialog,
    closeTenantUserEntryDialog,
  ] = useModal()

  const [
    isTenantUserBulkInviteDialogOpen,
    openTenantUserBulkInviteDialog,
    closeTenantUserBulkInviteDialog,
  ] = useModal()

  const [
    isTenantUserBulkAddDialogOpen,
    openTenantUserBulkAddDialog,
    closeTenantUserBulkAddDialog,
  ] = useModal()

  const { selectedUsers } = useTenantUserListContext()

  const [keyword, setKeyword] = useState<string>(
    searchParams.get('keyword') ?? ''
  )

  const stateFilter =
    searchParams.get('filter') || TenantSettingUserStateType.allStates.value

  // キーワード検索
  const keywordTextBoxRef = useRef<TextBox>(null)

  //delay search when keyup keyword search after 500ms
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        dispatch(tenantSettingUserStateKeywordUpdated(value))
        const currentParams: any = qs.parse(searchParams.toString())

        const newParams = {
          ...currentParams,
          keyword: value || undefined, // nullや空文字はundefinedに置き換える
        }

        // Don't perform search when the url param is the same
        if (!isEqualParams(currentParams, newParams)) {
          navigate(location.pathname, newParams, true)
        }
      }, SEARCH_TIMEOUT_MSEC),
    [dispatch, location.pathname, navigate, searchParams]
  )

  //call function when keyup keyword box search
  const handleKeywordTextBoxInput = useCallback(() => {
    // onInputイベントでは入力値をvalueで取得できないので、instanceから直接取得する
    const input = keywordTextBoxRef.current?.instance.option('text')

    if (input !== undefined && input !== keyword) {
      setKeyword(input)
      debouncedSearch(input)
    }
  }, [debouncedSearch, keyword])

  // event when state filter changed
  const handleStateFilterChanged = useCallback<
    ComponentCallbackHandler<typeof CrewSelectBox, 'onValueChanged'>
  >(
    (event) => {
      const currentParams: any = qs.parse(searchParams.toString())
      // nullや空文字はundefinedに置き換える
      const newParams = {
        ...currentParams,
        filter: event.value || undefined,
      }

      if (!isEqualParams(currentParams, newParams)) {
        navigate(location.pathname, newParams, true)
      }
    },
    [location.pathname, navigate, searchParams]
  )

  // クエリーパラメータが変わったら、検索条件用のLocalStateに反映する
  useEffect(() => {
    const currentParams: any = qs.parse(searchParams.toString())
    dispatch(tenantSettingUserStateKeywordUpdated(currentParams.keyword))

    //clear state when leave tenant setting page
    return () => {
      dispatch(tenantSettingUserStateKeywordUpdated(''))
    }
  }, [dispatch, searchParams])

  const [
    isEditTenantUserEntryDialogOpen,
    openEditTenantUserEntryDialog,
    closeEditTenantUserEntryDialog,
  ] = useModal()

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  // handles the event when the user clicks on a button to open a confirmation dialog for canceling selected users.
  const handleCancelInvitationButtonClick = useCallback(() => {
    setConfirmMessage(t('message.general.confirmMessage.cancel'))
    openConfirmDialog()
  }, [openConfirmDialog, t])

  // handles the submission of canceling an invitation for selected users.
  const handleSubmitCancelInvitationButtonClick = useCallback(async () => {
    if (selectedUsers.length > 0) {
      // Cancel tenant users selected inviting
      try {
        // Execute delete pending users process
        await deletePendingUsers(selectedUsers)

        success(t('message.tenant.cancelInvitationSuccess'))
      } catch (err) {
        error(t('message.general.failedToCancel'))
      }
    }
    closeConfirmDialog()
  }, [closeConfirmDialog, deletePendingUsers, error, selectedUsers, success, t])

  // check can open bulk edit user dialog
  const canOpenBulkEditUser = useMemo(
    () =>
      //  list selected user > 0 and can't find user have user type different other user (all user have same user type)
      selectedUsers.length > 0 &&
      selectedUsers.findIndex((item) => {
        return item.userType !== selectedUsers[0].userType
      }) === -1,
    [selectedUsers]
  )

  // handle bulk edit user button event click
  const handleBulkEditUserButtonClick = useCallback(() => {
    if (!canOpenBulkEditUser) {
      error(t('message.tenant.canNotEditUserTogether'))
      return
    }
    openEditTenantUserEntryDialog()
  }, [openEditTenantUserEntryDialog, error, t, canOpenBulkEditUser])

  return (
    <div className="flex justify-between items-start p-2 gap-x-2.5">
      <div className="flex gap-x-2.5">
        {/* キーワード */}
        <CrewTextBox
          className="w-40"
          value={keyword}
          valueChangeEvent="input change"
          onInput={handleKeywordTextBoxInput}
          showClearButton={true}
          ref={keywordTextBoxRef}
          mode="search"
        />
        {/* 状態フィルタ */}
        <CrewSelectBox
          className="w-36"
          value={stateFilter}
          dataSource={stateFilterDataSource}
          displayExpr="name"
          valueExpr="id"
          searchEnabled={false}
          minSearchLength={0}
          showClearButton={false}
          onValueChanged={handleStateFilterChanged}
        />
      </div>

      <div className="flex gap-x-2.5 overflow-x-scroll">
        {/* 招待をキャンセルボタン */}
        {(hasSysInternalUserInvitePermission ||
          hasSysExternalUserInvitePermission) && (
          <CrewButton
            disabled={selectedUsers.length === 0}
            text={t('action.cancelInvitation')}
            onClick={handleCancelInvitationButtonClick}
            type="normal"
            stylingMode="outlined"
          />
        )}

        {/* ユーザーを編集ボタン */}
        <CrewButton
          disabled={selectedUsers.length === 0}
          text={t('label.editUser')}
          onClick={handleBulkEditUserButtonClick}
          type="normal"
          stylingMode="outlined"
        />

        {(hasSysInternalUserInvitePermission ||
          hasSysExternalUserInvitePermission) && (
          <>
            {/* ユーザーを招待ボタン */}
            <CrewButton
              type="primary"
              text={t('action.inviteUser')}
              onClick={openTenantUserEntryDialog}
            />

            {/* 一括招待 */}
            <CrewButton
              type="primary"
              text={t('action.bulkInvitation')}
              onClick={openTenantUserBulkInviteDialog}
            />

            {/* 一括追加 */}
            <CrewButton
              type="primary"
              text={t('action.bulkAdd')}
              onClick={openTenantUserBulkAddDialog}
            />
          </>
        )}
      </div>

      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleSubmitCancelInvitationButtonClick}
        onCancelButtonClick={closeConfirmDialog}
        permitButtonDisabled={isLoadingDeleteUserPending}
      />

      {/* ユーザーを招待 */}
      <TenantUserEntryDialog
        title={t('label.inviteUser')}
        isOpen={isTenantUserEntryDialogOpen}
        onClose={closeTenantUserEntryDialog}
      />

      {/* ユーザーを一括招待 */}
      <TenantUserBulkInviteDialog
        title={t('label.bulkInviteUsers')}
        isOpen={isTenantUserBulkInviteDialogOpen}
        onClose={closeTenantUserBulkInviteDialog}
      />

      {/* ユーザーを一括追加 */}
      <TenantUserBulkAddDialog
        title={t('label.bulkAddUsers')}
        isOpen={isTenantUserBulkAddDialogOpen}
        onClose={closeTenantUserBulkAddDialog}
      />

      <EditTenantUserEntryDialog
        title={t('label.bulkEditUsers')}
        isOpen={isEditTenantUserEntryDialogOpen}
        onClose={closeEditTenantUserEntryDialog}
        userType={null}
        expirationDatetime={null}
      />
    </div>
  )
})
