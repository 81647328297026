import { FC, memo, useCallback, useEffect, useMemo } from 'react'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useTranslation } from '@crew/modules/i18n'
import { useToast } from 'hooks/useToast'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import {
  FormValues,
  useProjectDetailMemberUnitPriceEntryForm,
} from './useProjectDetailMemberUnitPriceEntryForm'
import { CrewDatePickerField } from 'components/forms/crewDatePickerField'
import { useParams } from 'react-router-dom'
import dayjs from '@crew/modules'
import { CrewNumberBoxField } from 'components/forms/crewNumberBoxField'
import { useGetProjectMemberCostQuery } from '@crew/apis/project/projectApis'
import { GetProjectMemberCostRequest } from '@crew/apis/project/models/getProjectMemberCost/request'
import { skipToken } from '@reduxjs/toolkit/query'

export type Props = {
  isEditMode: boolean
  onSubmit: () => void
  onCancel: () => void
  userId: string | null
  projectMemberCostId: string | null
}

export const ProjectDetailMemberUnitPriceEntryForm: FC<Props> = memo(
  (props) => {
    const { t } = useTranslation()
    const toast = useToast()
    const { projectId } = useParams()

    const getProjectMemberCostParams: GetProjectMemberCostRequest | undefined =
      props.projectMemberCostId
        ? {
            projectMemberCostId: props.projectMemberCostId,
          }
        : undefined
    const { data: getProjectMemberCost } = useGetProjectMemberCostQuery(
      getProjectMemberCostParams ?? skipToken
    )

    const {
      control,
      formState,
      validateRules,
      setError,
      reset,
      handleSubmit,
      insertProjectMemberCost,
      updateProjectMemberCost,
      isLoadingInsertProjectMemberCost,
      isLoadingUpdateProjectMemberCost,
    } = useProjectDetailMemberUnitPriceEntryForm()

    const [showApiErrors] = useShowApiErrorsWithForm(setError)

    const canSend = useMemo(
      () =>
        formState.isDirty &&
        Object.keys(formState.errors).length === 0 &&
        !formState.isSubmitting &&
        !isLoadingInsertProjectMemberCost &&
        !isLoadingUpdateProjectMemberCost,
      [
        formState,
        isLoadingInsertProjectMemberCost,
        isLoadingUpdateProjectMemberCost,
      ]
    )

    // Event handle when the Submit button to Add/Update project member unit price is clicked
    const handleSubmitButtonClick = useCallback(() => {
      // react-hook-formのhandleSubmitに渡すコールバック関数を定義する
      const onSubmit = async (data: FormValues) => {
        try {
          if (!projectId || !props.userId) return

          if (props.isEditMode) {
            if (!getProjectMemberCost?.projectMemberCost) return

            await updateProjectMemberCost(
              data,
              projectId,
              props.userId,
              getProjectMemberCost.projectMemberCost.id,
              getProjectMemberCost.projectMemberCost.version
            )

            toast.success(
              t('message.project.projectMemberUnitPriceUpdatedSuccessfully')
            )
          } else {
            await insertProjectMemberCost(data, projectId, props.userId)

            toast.success(
              t('message.project.projectMemberUnitPriceRegisteredSuccessfully')
            )
          }

          props.onSubmit && props.onSubmit()
        } catch (err) {
          showApiErrors(err)
        }
      }
      handleSubmit(onSubmit)()
    }, [
      getProjectMemberCost?.projectMemberCost,
      handleSubmit,
      insertProjectMemberCost,
      projectId,
      props,
      showApiErrors,
      t,
      toast,
      updateProjectMemberCost,
    ])

    // handle close project setting member entry dialog
    const handleCancelButtonClick = useCallback(() => {
      props.onCancel()
    }, [props])

    useEffect(() => {
      if (getProjectMemberCost?.projectMemberCost) {
        reset({
          effectiveStartDate: dayjs(
            getProjectMemberCost.projectMemberCost.effectiveStartDate
          ).toDate(),
          unitPrice: getProjectMemberCost.projectMemberCost.unitPrice,
        })
      }
    }, [getProjectMemberCost?.projectMemberCost, reset])

    return (
      <form className="flex flex-col gap-y-5 h-full">
        <div className="flex flex-row gap-x-2.5">
          <CrewDatePickerField
            id="effectiveStartDate"
            name="effectiveStartDate"
            control={control}
            label={t('label.effectiveStartDate')}
            rules={validateRules.effectiveStartDate}
            required
          />

          <CrewNumberBoxField
            id="unitPrice"
            name="unitPrice"
            control={control}
            label={t('label.pricePerHour')}
            rules={validateRules.unitPrice}
            required
            format="#,##0.00"
          />
        </div>

        <div className="flex justify-end gap-x-2.5">
          <CrewButton
            text={t('action.register')}
            type="primary"
            onClick={handleSubmitButtonClick}
            disabled={!canSend}
          />

          <CrewButton
            text={t('action.cancel')}
            type="normal"
            stylingMode="outlined"
            onClick={handleCancelButtonClick}
          />
        </div>
      </form>
    )
  }
)
