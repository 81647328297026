import { apis } from '../apiBase/apiBase';
const lookupApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        getLookupTaskKinds: builder.query({
            query: (params) => ({
                url: 'lookup/task-kinds',
                method: 'GET',
                params: params,
            }),
        }),
        getLookupTaskCategories: builder.query({
            query: (params) => ({
                url: 'lookup/task-categories',
                method: 'GET',
                params: params,
            }),
        }),
        getLookupUsers: builder.query({
            query: (params) => ({
                url: 'lookup/users',
                method: 'GET',
                params: params,
            }),
        }),
        getLookupUser: builder.query({
            query: (params) => ({
                url: `lookup/users/${params.userId}`,
            }),
        }),
        getLookupProjectGroups: builder.query({
            query: (params) => ({
                url: 'lookup/project-groups',
                params: params,
            }),
        }),
        getLookupProjectGroup: builder.query({
            query: (params) => ({
                url: `lookup/project-groups/${params.projectGroupId}`,
            }),
        }),
        getLookupEventKind: builder.query({
            query: (params) => ({
                url: `lookup/event-kinds/${params.eventKindId}`,
            }),
        }),
        getLookupEventCategory: builder.query({
            query: (params) => ({
                url: `lookup/event-categories/${params.eventCategoryId}`,
            }),
        }),
        getLookupTaskCategory: builder.query({
            query: (params) => ({
                url: `lookup/task-categories/${params.taskCategoryId}`,
            }),
        }),
        getLookupTaskKind: builder.query({
            query: (params) => ({
                url: `lookup/task-kinds/${params.taskKindId}`,
            }),
        }),
        getLookupTaskState: builder.query({
            query: (params) => ({
                url: `lookup/task-states/${params.taskStateId}`,
            }),
        }),
        getLookupEventCategories: builder.query({
            query: (params) => ({
                url: `lookup/event-categories`,
                params: params,
                method: 'GET',
            }),
        }),
        getLookupEventKinds: builder.query({
            query: (params) => ({
                url: `lookup/event-kinds`,
                method: 'GET',
                params: params,
            }),
        }),
        getLookupChatRoom: builder.query({
            query: (params) => ({
                url: `lookup/chat-room`,
                method: 'GET',
                params: params,
            }),
        }),
        getLookupProjectMembers: builder.query({
            query: (params) => ({
                url: `lookup/project-members`,
                params: params,
                method: 'GET',
            }),
        }),
        getLookupProjectMember: builder.query({
            query: (params) => ({
                url: `lookup/project-members/${params.userId}`,
                params: params,
                method: 'GET',
            }),
        }),
        getLookupTaskStates: builder.query({
            query: (params) => ({
                url: 'lookup/task-states',
                method: 'GET',
                params: params,
            }),
        }),
        getLookupRoles: builder.query({
            query: (params) => ({
                url: 'lookup/roles',
                method: 'GET',
                params: params,
            }),
        }),
        getLookupRelatedItems: builder.query({
            query: (params) => ({
                url: `lookup/related-items`,
                params: params,
                method: 'GET',
            }),
        }),
        getLookupRelatedItem: builder.query({
            query: (params) => ({
                url: `lookup/related-items/${params.entityType}`,
                method: 'GET',
                params: params,
            }),
        }),
        getLookupRole: builder.query({
            query: (params) => ({
                url: `lookup/roles/${params.roleId}`,
                method: 'GET',
            }),
        }),
        getMembers: builder.query({
            query: (params) => ({
                url: `lookup/members`,
                method: 'GET',
                params: params,
            }),
        }),
        getMember: builder.query({
            query: (params) => ({
                url: `lookup/members/${params.userId}`,
                method: 'GET',
                params: params,
            }),
        }),
        getTags: builder.query({
            query: (params) => ({
                url: 'lookup/tags',
                method: 'GET',
                params: params,
            }),
        }),
        getTag: builder.query({
            query: (params) => ({
                url: `lookup/tags/${params.tagId}`,
                method: 'GET',
            }),
        }),
        getLookupFile: builder.query({
            query: (params) => ({
                url: `lookup/files/${params.fileId}`,
                method: 'GET',
            }),
        }),
        getLookupFilter: builder.query({
            query: (params) => ({
                url: `lookup/filters/${params.filterId}`,
                method: 'GET',
            }),
        }),
        getLookupFilters: builder.query({
            query: (params) => ({
                url: `lookup/filters`,
                params: params,
                method: 'GET',
            }),
        }),
        getLookupFolders: builder.query({
            query: (params) => ({
                url: `lookup/folders`,
                params: params,
                method: 'GET',
            }),
        }),
        getLookupFolder: builder.query({
            query: (params) => ({
                url: `lookup/folders/${params.folderId}`,
                method: 'GET',
            }),
        }),
        getLookupKanbanBucketItems: builder.query({
            query: (params) => ({
                url: `lookup/kanban-bucket-items`,
                method: 'GET',
                params: params,
            }),
        }),
    }),
    overrideExisting: false,
});
export const { useGetLookupTaskKindsQuery, useGetLookupTaskCategoriesQuery, useGetLookupUsersQuery, useLazyGetLookupUsersQuery, useLazyGetLookupUserQuery, useGetLookupUserQuery, useGetLookupEventKindQuery, useLazyGetLookupEventKindQuery, useGetLookupProjectGroupsQuery, useLazyGetLookupProjectGroupsQuery, useGetLookupProjectGroupQuery, useLazyGetLookupProjectGroupQuery, useGetLookupEventCategoryQuery, useLazyGetLookupEventCategoryQuery, useLazyGetLookupTaskCategoriesQuery, useLazyGetLookupTaskCategoryQuery, useGetLookupTaskCategoryQuery, useLazyGetLookupTaskKindQuery, useLazyGetLookupTaskKindsQuery, useLazyGetLookupTaskStateQuery, useGetLookupEventCategoriesQuery, useLazyGetLookupEventCategoriesQuery, useGetLookupEventKindsQuery, useLazyGetLookupEventKindsQuery, useGetLookupChatRoomQuery, useLazyGetLookupChatRoomQuery, useGetLookupProjectMembersQuery, useLazyGetLookupProjectMembersQuery, useGetLookupProjectMemberQuery, useLazyGetLookupProjectMemberQuery, useGetLookupTaskStatesQuery, useLazyGetLookupTaskStatesQuery, useGetLookupRolesQuery, useLazyGetLookupRolesQuery, useGetLookupRelatedItemsQuery, useLazyGetLookupRelatedItemsQuery, useLazyGetLookupRoleQuery, useGetLookupRelatedItemQuery, useLazyGetLookupRelatedItemQuery, useLazyGetMembersQuery, useGetMembersQuery, useLazyGetMemberQuery, useGetMemberQuery, useGetTagsQuery, useLazyGetTagsQuery, useLazyGetTagQuery, useGetLookupFileQuery, useLazyGetLookupFileQuery, useLazyGetLookupFilterQuery, useGetLookupFiltersQuery, useLazyGetLookupFiltersQuery, useLazyGetLookupFoldersQuery, useGetLookupFoldersQuery, useLazyGetLookupFolderQuery, useGetLookupFolderQuery, useGetLookupKanbanBucketItemsQuery, useLazyGetLookupKanbanBucketItemsQuery, } = lookupApis;
