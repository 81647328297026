import React, { useState, memo, Fragment, useCallback, useRef } from 'react'
import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { useValueChangeEffect } from '@crew/hooks'
import { NumberBox, TextBox } from 'devextreme-react'
import { CrewNumberBox } from 'components/devextreme/crewNumberBox'
import ArrowDropDown from '~icons/material-symbols/arrow-drop-down'
import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { DataType } from '@crew/enums/app'
import {
  CrewComboBoxItem,
  Item,
} from './components/crewComboBoxItem/crewComboBoxItem'

type ComboBoxProps = {
  items: Item[]
  value?: number | string
  unit?: string
  onValueChange?: (value?: number | string) => void
  disabled?: boolean
  dataType?: DataType
  isInvalid?: boolean
  name?: string
  inputAttr?: Record<string, string>
}

export const CrewComboBox: React.FC<ComboBoxProps> = memo((props) => {
  const [isFocused, setIsFocused] = useState(false)
  const numberBoxRef = useRef<NumberBox>(null)
  const textBoxRef = useRef<TextBox>(null)

  const [inputValue, setInputValue] = useState<number | string | undefined>(
    props.value
  )

  // Update input value when props value change
  useValueChangeEffect(
    () => {
      setInputValue(props.value)
    },
    [props.value],
    props.value
  )

  // handle number box change
  const handleNumberBoxChange = useCallback((e: number) => {
    setInputValue(e)
  }, [])

  // handle text box change
  const handleTextBoxChange = useCallback((e: string) => {
    setInputValue(e)
  }, [])

  // handle item click
  const handleClickItem = useCallback(
    (value: number | string | undefined) => {
      props.onValueChange?.(value)
      setInputValue(value)
    },
    [props]
  )

  // handle click value to focus
  const handleClickValue = useCallback(() => {
    if (props.disabled) return
    setIsFocused(true)
  }, [props.disabled])

  // handle focus out
  const handleFocusOut = useCallback(() => {
    props.onValueChange?.(inputValue)
    setIsFocused(false)
  }, [inputValue, props])

  // handle enter key
  const handleEnterKey = useCallback(() => {
    handleFocusOut()
  }, [handleFocusOut])

  // focus input when focused
  useValueChangeEffect(
    () => {
      if (isFocused) {
        if (props.dataType === DataType.Number) {
          numberBoxRef.current?.instance.focus()
        } else {
          textBoxRef.current?.instance.focus()
        }
      }
    },
    [isFocused, props.dataType],
    isFocused
  )

  // render number box or text box
  const renderNumberBoxORTextBox = useCallback(() => {
    if (props.dataType === DataType.Number) {
      return (
        <CrewNumberBox
          inputAttr={props.inputAttr ?? {}}
          name={props.name}
          ref={numberBoxRef}
          value={inputValue as number}
          onValueChange={handleNumberBoxChange}
          disabled={props.disabled}
          className="!border-0"
          onFocusOut={handleFocusOut}
          onEnterKey={handleEnterKey}
        />
      )
    } else {
      return (
        <CrewTextBox
          ref={textBoxRef}
          inputAttr={props.inputAttr ?? {}}
          name={props.name}
          value={inputValue as string}
          onValueChange={handleTextBoxChange}
          disabled={props.disabled}
          onFocusOut={handleFocusOut}
          onEnterKey={handleEnterKey}
          className="!border-0"
        />
      )
    }
  }, [
    props.dataType,
    props.inputAttr,
    props.name,
    props.disabled,
    inputValue,
    handleNumberBoxChange,
    handleFocusOut,
    handleEnterKey,
    handleTextBoxChange,
  ])

  // render vew input value
  const renderViewInputValue = useCallback(() => {
    return (
      <div className="flex-1">
        {isFocused ? (
          renderNumberBoxORTextBox()
        ) : (
          <div
            className="dx-widget dx-texteditor-input"
            aria-disabled={props.disabled}
            onClick={handleClickValue}
          >
            <span
              className={classNames(props.disabled && '!text-crew-gray-400')}
            >
              {(inputValue || typeof inputValue === DataType.Number) &&
                `${inputValue}${props.unit ? props.unit : ''}`}
            </span>
          </div>
        )}
      </div>
    )
  }, [
    isFocused,
    renderNumberBoxORTextBox,
    props.disabled,
    props.unit,
    handleClickValue,
    inputValue,
  ])

  // render menu items
  const renderMenuItems = useCallback(() => {
    return (
      <div className="py-1">
        {props.items.map((item) => (
          <CrewComboBoxItem
            key={item.value}
            item={item}
            onClickItem={handleClickItem}
          />
        ))}
      </div>
    )
  }, [props.items, handleClickItem])

  return (
    <div
      className={classNames(
        'relative flex flex-row w-full items-center border dx-texteditor dx-editor-outlined',
        props.disabled && '!bg-gray-100',
        isFocused && 'dx-state-focused',
        !props.disabled &&
          !isFocused &&
          !props.isInvalid &&
          'hover:!border-blue-300 dark:hover:!border-blue-900',
        props.isInvalid && '!border-crew-red-500'
      )}
    >
      {renderViewInputValue()}
      <Menu as="div">
        <Menu.Button
          as="button"
          className={classNames(
            'dark:hover:bg-neutral-950 crew-action-base py-1.5 px-2 text-base mr-1 crew-action-normal-text',
            props.disabled && '!bg-gray-100'
          )}
          disabled={props.disabled}
        >
          <ArrowDropDown />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="dx-texteditor dx-editor-outlined dx-popup-wrapper dx-overlay-content absolute z-10 max-h-56 overflow-auto right-0 w-auto min-w-full py-1 mt-1.5 origin-bottom-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black/5 focus:outline-none">
            {renderMenuItems()}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
})
