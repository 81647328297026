import { apis } from '../apiBase/apiBase';
export const userApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: (params) => {
                return {
                    url: `/users`,
                    params: params,
                    method: `GET`,
                };
            },
        }),
        getUser: builder.query({
            query: (params) => {
                return {
                    url: `/users/${params.userId}`,
                    method: `GET`,
                };
            },
        }),
        updateUserRole: builder.mutation({
            query: (params) => ({
                url: `users/update-role`,
                method: `POST`,
                body: params,
            }),
        }),
        getMyAccount: builder.query({
            query: (params) => {
                return {
                    url: '/users/my-account',
                    method: `GET`,
                };
            },
        }),
        updateUsersRole: builder.mutation({
            query: (params) => ({
                url: `users/update-users-role`,
                method: `POST`,
                body: params,
            }),
        }),
        getProjectPermissions: builder.query({
            query: (params) => {
                return {
                    url: `/users/permissions/project`,
                    method: `GET`,
                    params: params,
                };
            },
        }),
        getSystemPermissions: builder.query({
            query: (params) => {
                return {
                    url: `/users/permissions/system`,
                    method: `GET`,
                };
            },
        }),
        getInviteableExternalUsers: builder.query({
            query: (params) => {
                return {
                    url: '/users/inviteable-external-users-count',
                    method: 'GET',
                };
            },
        }),
        getExternalUsersCount: builder.query({
            query: (params) => {
                return {
                    url: '/users/external-users-count',
                    method: 'GET',
                };
            },
        }),
        getInviteableUsersForFreePlan: builder.query({
            query: (params) => {
                return {
                    url: '/users/inviteable-users-for-free-plan',
                    method: 'GET',
                };
            },
        }),
        verifyBulkAddUsers: builder.mutation({
            query: (params) => {
                // リクエストのファイルをformDataに格納して送信
                const formData = new FormData();
                formData.append('file', params.file);
                return {
                    url: '/users/bulk-insert/verify',
                    method: 'POST',
                    body: formData,
                };
            },
        }),
        bulkInsertUsers: builder.mutation({
            query: (params) => ({
                url: `users/bulk-insert`,
                method: `POST`,
                body: params,
            }),
        }),
        getUserState: builder.query({
            query: (params) => {
                return {
                    url: `/users/${params.userId}/state`,
                    method: `GET`,
                };
            },
        }),
    }),
    overrideExisting: false,
});
export const { useGetUsersQuery, useLazyGetUsersQuery, useGetUserQuery, useLazyGetUserQuery, useUpdateUserRoleMutation, useGetMyAccountQuery, useUpdateUsersRoleMutation, useGetProjectPermissionsQuery, useLazyGetProjectPermissionsQuery, useGetSystemPermissionsQuery, useLazyGetSystemPermissionsQuery, useGetInviteableExternalUsersQuery, useGetExternalUsersCountQuery, useLazyGetExternalUsersCountQuery, useGetInviteableUsersForFreePlanQuery, useLazyGetInviteableUsersForFreePlanQuery, useVerifyBulkAddUsersMutation, useBulkInsertUsersMutation, useGetUserStateQuery, useLazyGetUserStateQuery, } = userApis;
