import { FC, memo } from 'react'
import { useTaskStateEntryForm } from './useTaskStateEntryForm'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { CrewColorSelectBoxField } from 'components/forms/crewColorSelectBoxField'
import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewBadge } from 'components/elements/crewBadge/crewBadge'
import { CrewErrorSummary } from 'components/forms/crewErrorSummary'
import { useTranslation } from '@crew/modules/i18n'
import { useMemo, useCallback, useState, useEffect } from 'react'
import { TaskStateTypes } from 'enums/app'
import { useGetTaskStateQuery } from '@crew/apis/task/taskApis'
import { useToast } from 'hooks/useToast'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { useBadgeColorClassDataSource } from 'hooks/dataSource/useResourceDataSource'
import { useTaskStateTypeDataSource } from 'hooks/dataSource/useTaskStateTypeDataSource'
import { skipToken } from '@reduxjs/toolkit/query/react'
import { GetTaskStateRequest } from '@crew/apis/task/models/getTaskState/request'
import { ComponentCallbackHandler } from '@crew/utils'
import { FormValues } from './useTaskStateEntryForm'

export type TaskStateEntryFormProps = {
  isEditMode: boolean
  taskStateId?: string
  onSubmit: () => void
  onClose: () => void
}

export const TaskStateEntryForm: FC<TaskStateEntryFormProps> = memo((props) => {
  const {
    control,
    reset,
    formState,
    handleSubmit,
    clearErrors,
    setError,

    validateRules,

    insertTaskState,
    updateTaskState,
    isLoadingInsertTaskState,
    isLoadingUpdateTaskState,
  } = useTaskStateEntryForm()

  const { t } = useTranslation()
  const { success } = useToast()

  // バッジプレビュー表示用項目
  const [previewBadgeName, setPreviewBadgeName] = useState<string>('')
  const [previewBadgeDisplayColor, setPreviewBadgeDisplayColor] = useState<
    string | null
  >(null)

  // タイプドロップダウンの選択可能項目出力判定（true：全項目、false：選択項目のみ）
  // NOTE: 新規登録時は選択項目のみとするので初期値はtrueとする
  const [onlyChooseable, setOnlyChooseable] = useState(true)

  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  // タスク状態取得
  // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
  const getTaskStateParam: GetTaskStateRequest | undefined = props.taskStateId
    ? {
        taskStateId: props.taskStateId,
      }
    : undefined

  const { data: getTaskStateQueryResult } = useGetTaskStateQuery(
    getTaskStateParam ?? skipToken
  )

  // フォーム初期化処理関数
  const initializeForm = useCallback(() => {
    if (props.isEditMode && props.taskStateId) {
      if (getTaskStateQueryResult?.taskState) {
        reset({
          id: getTaskStateQueryResult.taskState?.id,
          name: getTaskStateQueryResult.taskState?.name,
          displayColor: getTaskStateQueryResult.taskState?.displayColor,
          taskStateType: getTaskStateQueryResult.taskState?.taskStateType,
          version: getTaskStateQueryResult.taskState?.version,
        })

        // 登録しているステータスのTaskStateTypes定数を取得（chooseableを特定するため）
        const taskStateType = Object.values(TaskStateTypes).find(
          (key) =>
            key.value === getTaskStateQueryResult.taskState?.taskStateType
        )

        if (taskStateType) {
          // When creating a new one, only 作業中 and レビュー中 are allowed to be selected.
          setOnlyChooseable(taskStateType.chooseable)
        }
      }
    }
  }, [
    getTaskStateQueryResult?.taskState,
    props.isEditMode,
    props.taskStateId,
    reset,
  ])

  // 初期化処理をuseEffect化
  // TODO: 本当はuseEffectを外したいのだが、レンダリングが大量に走ってしまうためこのようにしている。以下タスクで調査・対応予定
  // https://break-tmc.atlassian.net/browse/CREW-6028
  useEffect(() => {
    // フォーム初期化を実行
    initializeForm()
  }, [initializeForm])

  // バッジの色スタイルクラスのリスト
  const colorClassListDataSource = useBadgeColorClassDataSource()

  // タスク状態のタイプリスト
  const taskStateTypeListDataSource = useTaskStateTypeDataSource(onlyChooseable)

  // 名称テキスト変更時
  const handleNameChanged = useCallback<
    ComponentCallbackHandler<typeof CrewTextBoxField, 'onValueChanged'>
  >((event) => {
    setPreviewBadgeName(event.value)
  }, [])

  // バッジカラー変更時
  const handleDisplayColorChanged = useCallback<
    ComponentCallbackHandler<typeof CrewColorSelectBoxField, 'onValueChanged'>
  >((event) => {
    setPreviewBadgeDisplayColor(event.value)
  }, [])

  // 登録ボタン押下
  const handleSubmitButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        if (props.isEditMode) {
          await updateTaskState(data)
          success(t('message.taskState.taskStateUpdated'))
        } else {
          await insertTaskState(data)

          success(t('message.taskState.taskStateRegistered'))
        }
        reset()
        clearErrors()
        props.onSubmit && props.onSubmit()
      } catch (err) {
        showApiErrors(err)
      }
    }
    handleSubmit(onSubmit)()
  }, [
    clearErrors,
    handleSubmit,
    insertTaskState,
    props,
    reset,
    showApiErrors,
    success,
    t,
    updateTaskState,
  ])

  // タスク状態ダイアログのキャンセルボタン押下
  const handleCancelButtonClick = useCallback(() => {
    props.onClose()
  }, [props])

  const canSend = useMemo(
    // fromState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
    () => Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
    // formStateはproxyなのでformState自体をlistenする必要がある
    // https://react-hook-form.com/api/useform/formstate
    [formState]
  )

  return (
    <form>
      <div>
        <div className="h-20">
          <CrewTextBoxField
            id="name"
            name="name"
            control={control}
            labelMode="hidden"
            label={t('label.name')}
            required={true}
            valueChangeEvent="keyup"
            onValueChanged={handleNameChanged}
            rules={validateRules.name}
          />
        </div>
        <div className="flex gap-4 h-20">
          <div>
            <CrewColorSelectBoxField
              id="displayColor"
              name="displayColor"
              control={control}
              labelMode="hidden"
              label={t('label.color')}
              required={true}
              dataSource={colorClassListDataSource}
              displayExpr="name"
              valueExpr="id"
              onValueChanged={handleDisplayColorChanged}
              width="200px"
              rules={validateRules.displayColor}
            />
          </div>
          <div className="inline-flex items-center">
            {previewBadgeName && previewBadgeDisplayColor ? (
              <CrewBadge displayColor={previewBadgeDisplayColor}>
                {previewBadgeName}
              </CrewBadge>
            ) : null}
          </div>
        </div>
        <div className="h-20">
          <CrewSelectBoxField
            id="taskStateType"
            name="taskStateType"
            control={control}
            labelMode="hidden"
            label={t('label.type')}
            required={true}
            showClearButton={false}
            searchEnabled={false}
            minSearchLength={0}
            dataSource={taskStateTypeListDataSource}
            displayExpr="name"
            valueExpr="id"
            width="200px"
            rules={validateRules.taskStateType}
            // When editing, do not change the Type
            disabled={props.isEditMode}
          />
        </div>
        <CrewErrorSummary formState={formState} />
      </div>
      <div className="flex justify-between items-center mt-3">
        <div className="ml-auto flex gap-x-5">
          <CrewButton
            text={t('action.register')}
            type="primary"
            disabled={
              !canSend || isLoadingInsertTaskState || isLoadingUpdateTaskState
            }
            onClick={handleSubmitButtonClick}
          />
          <CrewButton
            text={t('action.cancel')}
            type="normal"
            stylingMode="outlined"
            onClick={handleCancelButtonClick}
          />
        </div>
      </div>
    </form>
  )
})
