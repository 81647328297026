import { useTranslation } from '@crew/modules/i18n'
import { CrewFieldLabel } from 'components/elements/crewFieldLabel'
import { memo } from 'react'

export const WorkingTimeEntryFormHeader = memo(() => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-row py-1 border-b border-crew-gray-400 text-sm">
      {/* タスク */}
      <div className="flex-1 px-1">
        <CrewFieldLabel
          text={t('general.entityType.tasks')}
          className="crew-text-default"
        />
      </div>
      {/* 開始時刻 */}
      <div className="w-24 px-1">
        <CrewFieldLabel
          text={t('label.startTime')}
          className="crew-text-default"
        />
      </div>
      {/* Space between time */}
      <div className="w-6 px-1"></div>
      {/* 終了時刻 */}
      <div className="w-24 px-1">
        <CrewFieldLabel
          text={t('label.endTime')}
          className="crew-text-default"
        />
      </div>
      {/* 作業時間 */}
      <div className="w-28 px-1">
        <CrewFieldLabel
          text={t('label.workingTime')}
          required
          className="crew-text-default"
        />
      </div>

      {/* actual progress */}
      <div className="w-28 px-1">
        <CrewFieldLabel
          text={t('label.progressRate')}
          className="crew-text-default"
        />
      </div>
      {/* Action header */}
      <div className="w-8 px-1"></div>
    </div>
  )
})
