import { memo, useMemo } from 'react'
import { HTMLEDITOR_VIEW_STYLE } from 'configs/constants'
import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { CrewErrorDialog } from 'components/elements/crewErrorDialog/crewErrorDialog'
import { TaskDetailWorkInputForm } from 'features/task/components/taskDetailPage/components/taskDetailCommentList/components/taskDetailCommentListPanel/components/taskDetailWorkInputPanel/components/taskDetailWorkInputForm/taskDetailWorkInputForm'
import { TaskDetailCommentItemActionMenu } from '../taskDetailCommentItem/components/taskDetailCommentItemActionMenu/taskDetailCommentItemActionMenu'
import { useTaskDetailWorkItem } from './useTaskDetailWorkItem'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { useModal } from 'components/layouts/modal/useModal'
import { useToast } from 'hooks/useToast'
import { TaskDetailCommentListPageContext } from '../../useTaskDetailCommentListPanel'
import { CrewHtmlContent } from 'components/elements/crewHtmlContent/crewHtmlContent'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { convertMinutesToHHMM } from '@crew/utils'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { UserRef } from '@crew/models/refs'

type TaskWork = {
  id: string
  workDate: string
  startTime: string | null
  endTime: string | null
  description: string | null
  actualWorkTimes: number
  createdByUser: UserRef
  createdAt: string
  version: number
}

type TaskDetailWorkItemProps = {
  taskWork: TaskWork
}

export const TaskDetailWorkItem = memo((props: TaskDetailWorkItemProps) => {
  const { deleteTaskWork, isLoadingDeleteTaskWork } = useTaskDetailWorkItem()

  const { t } = useTranslation()
  const toast = useToast()
  const [showApiErrors] = useShowApiErrors()
  const { editItemId, setEditItemId } = useContext(
    TaskDetailCommentListPageContext
  )

  const [visibleActionMenu, setVisibleActionMenu] = useState<boolean>(false)
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false)
  const [isEditWork, setIsEditWork] = useState<boolean>(false)

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')

  const [isErrorDialogOpen, closeErrorDialog] = useModal()

  // エラーダイアログメッセージ
  const [errorMessage] = useState('')

  // コメントブロックにマウスカーソルを乗せたら３点リーダ表示
  const handleMouseEnter = useCallback(() => {
    setVisibleActionMenu(true)
  }, [])

  // コメントブロックからマウスカーソルを外したらそのコメントブロック内の３点リーダ非表示
  const handleMouseLeave = useCallback(() => {
    setVisibleActionMenu(false)
  }, [])

  // タスク作業編集フォームのキャンセルボタン押下
  const handleCloseWorkInput = useCallback(() => {
    setIsEditWork(false)
  }, [])

  // コンテキストメニューメニューの編集ボタン押下
  const handleEditActionMenuButtonClick = useCallback(() => {
    setIsEditWork(true)
    setEditItemId(props.taskWork.id)
  }, [setEditItemId, props.taskWork.id])

  //Click action menu delete item
  const handleDeleteActionMenuButtonClick = useCallback(async () => {
    // 確認ダイアログの表示（処理は確認ダイアログのOKボタン押下時に行う）
    setConfirmMessage(t('message.general.confirmMessage.delete'))
    openConfirmDialog()
  }, [openConfirmDialog, t])

  // 削除確認ダイアログ OKボタン
  const handleDeletePermitButtonClick = useCallback(async () => {
    if (props.taskWork.id) {
      try {
        await deleteTaskWork(props.taskWork.id, props.taskWork.version)
        toast.success(t('message.task.taskCommentDeleted'))
      } catch (err) {
        showApiErrors(err)
      }
    }

    closeConfirmDialog()
  }, [
    closeConfirmDialog,
    deleteTaskWork,
    props.taskWork.id,
    props.taskWork.version,
    showApiErrors,
    t,
    toast,
  ])

  //close edit work input when other work input open or open create work input
  useEffect(() => {
    if (!editItemId || editItemId !== props.taskWork.id) {
      setIsEditWork(false)
    }
  }, [editItemId, props.taskWork.id])

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  // Task work times
  const taskWorkTimes = useMemo(() => {
    if (!props.taskWork.startTime && !props.taskWork.endTime) {
      // タスク作業の開始時刻、終了時刻にnullが設定されている場合：作業時間を表示せず、日付のみ表示
      return t('format.date', {
        value: props.taskWork.workDate,
        timeZone: defaultUserProfileRegion,
      })
    }

    // タスク作業の開始時刻、終了時刻が入力されている場合：作業時間を表示する
    return (
      <>
        {
          // 作業日
          t('format.date', {
            value: props.taskWork.workDate,
            timeZone: defaultUserProfileRegion,
          })
        }
        <span> </span>
        {
          // 開始時刻
          t('format.shorttime', {
            value: props.taskWork.startTime,
            timeZone: defaultUserProfileRegion,
          })
        }
        {
          // separator character between 開始時刻 and 終了時刻
          props.taskWork.startTime && props.taskWork.endTime && (
            <span className="mx-0.5">-</span>
          )
        }
        {
          // 終了時刻
          t('format.shorttime', {
            value: props.taskWork.endTime,
            timeZone: defaultUserProfileRegion,
          })
        }
      </>
    )
  }, [
    defaultUserProfileRegion,
    props.taskWork.endTime,
    props.taskWork.startTime,
    props.taskWork.workDate,
    t,
  ])
  return (
    <>
      {isEditWork ? (
        <>
          <TaskDetailWorkInputForm
            isEditMode={true}
            taskWorkId={props.taskWork.id}
            version={props.taskWork.version}
            onClose={handleCloseWorkInput}
          />
        </>
      ) : (
        <div
          className="flex flex-col gap-y-2 border-b border-crew-gray-2-light dark:border-crew-gray-3-dark py-2 relative"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="flex gap-x-2 items-center">
            <div className="min-w-0">
              <CrewUserAvatar
                userId={props.taskWork.createdByUser.id}
                displayName={props.taskWork.createdByUser.displayName}
                size={CrewAvatarSize.xs}
                showLabel={true}
                cacheValue={
                  props.taskWork.createdByUser.id +
                  props.taskWork.createdByUser.version
                }
              />
            </div>

            <span>
              {t('format.datetime', {
                value: props.taskWork.createdAt,
                timeZone: defaultUserProfileRegion,
              })}
            </span>
          </div>

          {/* 説明が登録されていれば表示 */}
          {props.taskWork.description && (
            <div className={HTMLEDITOR_VIEW_STYLE}>
              <CrewHtmlContent html={props.taskWork.description} />
            </div>
          )}

          {/* 実績時間と開始日時 */}
          <div>
            <ul className="list-disc pl-6">
              <li className="font-bold">
                <span>
                  {convertMinutesToHHMM(props.taskWork.actualWorkTimes)} (
                  {taskWorkTimes})
                </span>
              </li>
            </ul>
          </div>

          {/* ３点リーダ */}
          {(visibleActionMenu || showContextMenu) && (
            <div
              // タスク作業枠の右上に３点リーダーを表示するためabsoluteを使用
              className="absolute top-0 right-0"
            >
              <TaskDetailCommentItemActionMenu
                onContextMenuOpen={setShowContextMenu}
                onEditComment={handleEditActionMenuButtonClick}
                onDeleteComment={handleDeleteActionMenuButtonClick}
                id={`taskDetailWorkItemActionMenu-${props.taskWork.id}`}
              />
            </div>
          )}
        </div>
      )}

      {/* 削除確認ダイアログ */}
      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleDeletePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
        permitButtonDisabled={isLoadingDeleteTaskWork}
      />

      {/* エラーダイアログ */}
      <CrewErrorDialog
        isOpen={isErrorDialogOpen}
        message={errorMessage}
        onCloseButtonClick={closeErrorDialog}
      />
    </>
  )
})
