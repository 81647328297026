import { ReactNode, memo, useCallback, useMemo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import BaselineDelete from '~icons/ic/baseline-delete'
import BaselineCreate from '~icons/ic/baseline-create'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { PresenceState } from '@crew/models/domain'
import { CrewPresenceStateIcon } from 'components/elements/crewPresenceStateIcon/crewPresenceStateIcon'

type PresenceStatusListItemProps = {
  presenceStatus: PresenceState
  onEditButtonClick: (
    presenceStatusId: string,
    presenceStatusVersion: number
  ) => void
  onDeleteButtonClick: (
    presenceStatusId: string,
    presenceStatusVersion: number
  ) => void
  dragHandle: ReactNode
}

const DEFINITION_TYPE_BUILT_IN = 'built-in'

export const PresenceStatusListItem = memo(
  (props: PresenceStatusListItemProps) => {
    const { t } = useTranslation()

    // default user profile region
    const defaultUserProfileRegion = useUserSetting(
      SettingKeyType.UserProfileRegion,
      Region.Japan.value
    )

    // render name cell
    const renderNameCell = useMemo(() => {
      return (
        <div className="flex flex-row items-center gap-2">
          <CrewPresenceStateIcon
            presenceStateType={props.presenceStatus.presenceStateType}
          />
          <span>{props.presenceStatus.name}</span>
        </div>
      )
    }, [props.presenceStatus.name, props.presenceStatus.presenceStateType])

    // render registered date
    const renderRegisteredDateCell = useMemo(
      () =>
        t('format.timestamp', {
          value: props.presenceStatus.createdAt,
          timeZone: defaultUserProfileRegion,
        }),
      [t, props.presenceStatus.createdAt, defaultUserProfileRegion]
    )

    // handle delete button click
    const handleDeleteButtonClick = useCallback(() => {
      props.onDeleteButtonClick(
        props.presenceStatus.id,
        props.presenceStatus.version
      )
    }, [props])

    // handle delete button click
    const handleEditButtonClick = useCallback(() => {
      props.onEditButtonClick(
        props.presenceStatus.id,
        props.presenceStatus.version
      )
    }, [props])

    return (
      <>
        {/* drag handle item */}
        <td>{props.dragHandle}</td>

        {/* name */}
        <td className="w-3/12 max-w-0">{renderNameCell}</td>

        {/* registered date */}
        <td className="w-6/12">{renderRegisteredDateCell}</td>

        {/* Action button */}
        <td className="w-3/12">
          <div className="flex gap-2 pr-2 justify-end items-center">
            <span className="cursor-pointer" onClick={handleEditButtonClick}>
              <BaselineCreate width={24} height={24} />
            </span>
            {props.presenceStatus.definitionType !==
              DEFINITION_TYPE_BUILT_IN && (
              <span
                className="cursor-pointer"
                onClick={handleDeleteButtonClick}
              >
                <BaselineDelete width={24} height={24} />
              </span>
            )}
          </div>
        </td>
      </>
    )
  }
)
