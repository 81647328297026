import { useTranslation } from '@crew/modules/i18n'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { BaseSdkProps } from 'modules/amazon-chime-sdk-component-library-devextreme/components/sdk/Base'
import {
  useContentShareControls,
  useContentShareState,
  useIsContentShareSupported,
} from 'modules/amazon-chime-sdk-component-library-devextreme/providers/ContentShareProvider'
import { FC, memo } from 'react'
import DesktopWindowsOutline from '~icons/material-symbols/desktop-windows-outline'

type Props = BaseSdkProps & { id?: string; disabled?: boolean }

export const CrewContentShareButton: FC<Props> = memo(
  ({ className, ...rest }) => {
    const { t } = useTranslation()

    const { isLocalUserSharing } = useContentShareState()
    const { toggleContentShare } = useContentShareControls()
    const isContentShareSupported = useIsContentShareSupported()

    return (
      <CrewButton
        type={isLocalUserSharing ? 'success' : 'normal'}
        stylingMode={isLocalUserSharing ? 'contained' : 'outlined'}
        icon={<DesktopWindowsOutline width={24} height={24} />}
        text={t('label.shareScreens')}
        className={className}
        onClick={() => {
          toggleContentShare()
        }}
        disabled={rest.disabled || !isContentShareSupported}
      />
    )
  }
)
