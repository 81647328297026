import { useMemo } from 'react'
import { UserFilters } from 'enums/app'
import { useTranslation } from '@crew/modules/i18n'

export const useUserListSearchPanel = () => {
  const { t } = useTranslation()
  // Users filter list
  const filterDataSource = useMemo(() => {
    return Object.values(UserFilters).map((item) => {
      return { id: item, name: t('label.' + item) }
    })
  }, [t])

  return {
    filterDataSource,
  }
}
