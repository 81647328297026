import { RefObject, memo } from 'react'
import { AttendeeUser } from 'components/chime/crewAttendees/useCrewAttendees'
import { EventDetailWebMeetingLocalAttendee } from './components/eventDetailWebMeetingLocalAttendee/eventDetailWebMeetingLocalAttendee'
import { EventDetailWebMeetingRemoteAttendee } from './components/eventDetailWebMeetingRemoteAttendee/eventDetailWebMeetingRemoteAttendee'
import { MeetingAttendeeJoinState } from '@crew/enums/domain'
import { EventDetailWebMeetingWaitingAttendeeItem } from './components/eventDetailWebMeetingWaitingAttendeeItem/eventDetailWebMeetingWaitingAttendeeItem'

type EventDetailWebMeetingAttendeeListProps = {
  isFullScreen: boolean
  fullScreenTargetRef: RefObject<HTMLDivElement>
  attendeeUsers: AttendeeUser[]
  localAttendeeUser: AttendeeUser | undefined
}

export const EventDetailWebMeetingAttendeeList = memo(
  (props: EventDetailWebMeetingAttendeeListProps) => {
    return (
      <div className="flex flex-col w-72 h-full p-3 gap-y-1 overflow-y-auto">
        {/* show attendee local */}
        {props.localAttendeeUser && (
          <EventDetailWebMeetingLocalAttendee
            attendee={{
              ...props.localAttendeeUser,
              attendeeUserId: props.localAttendeeUser.id,
            }}
            isFullScreen={props.isFullScreen}
            fullScreenTargetRef={props.fullScreenTargetRef}
            // また、ログインユーザーが主催者であっても、参加者＝ログインユーザーのメニューボタンについては非表示とすること
            showAttendeeActionMenu={false}
          />
        )}
        {/* show list attendee invite meeting */}
        {props.attendeeUsers?.map((user) => {
          if (
            user.joinState === MeetingAttendeeJoinState.Waiting &&
            !props.localAttendeeUser?.isHost
          ) {
            // if the logged in user is not the Host, do not show waiting attendees
            return null
          }

          return user.joinState === MeetingAttendeeJoinState.Waiting ? (
            // 待機中のユーザー
            <EventDetailWebMeetingWaitingAttendeeItem
              key={user.id}
              chimeAttendeeId={user.chimeAttendeeId}
              attendeeUserId={user.id}
              displayName={user.displayName}
            />
          ) : (
            // 参加済みのユーザー
            <EventDetailWebMeetingRemoteAttendee
              key={user.id}
              attendee={{
                ...user,
                attendeeUserId: user.id,
              }}
              isFullScreen={props.isFullScreen}
              fullScreenTargetRef={props.fullScreenTargetRef}
              // ログインユーザーが主催者ではない場合は、メニューボタン（…）は非表示とする
              showAttendeeActionMenu={props.localAttendeeUser?.isHost ?? false}
            />
          )
        })}
      </div>
    )
  }
)
