import { Modal } from 'components/layouts/modal/modal'

import { FC, memo, useCallback } from 'react'
import { PresenceStatusEntryForm } from './components/presenceStatusEntryForm'

export type PresenceStatusDialogProps = {
  isEditMode: boolean
  title: string
  isOpen: boolean
  onClose: () => void
  presenceStatusId?: string
}

export const PresenceStatusEntryDialog: FC<PresenceStatusDialogProps> = memo(
  (props) => {
    // 登録ボタン押下
    const handleSubmitButtonClick = useCallback(() => {
      props.onClose()
    }, [props])

    return (
      <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
        <PresenceStatusEntryForm
          isEditMode={props.isEditMode}
          presenceStatusId={props.presenceStatusId}
          onSubmit={handleSubmitButtonClick}
          onClose={props.onClose}
        />
      </Modal>
    )
  }
)
