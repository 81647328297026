import { FC, memo } from 'react'
import MicOff from '~icons/material-symbols/mic-off'
import useAttendeeAudioStatus from 'modules/amazon-chime-sdk-component-library-devextreme/hooks/sdk/useAttendeeAudioStatus'

type CrewMicrophoneMuteProps = {
  chimeAttendeeId: string
}

export const CrewShowMicrophoneMute: FC<CrewMicrophoneMuteProps> = memo(
  (props) => {
    const { muted } = useAttendeeAudioStatus(props.chimeAttendeeId)

    return (
      <div className="absolute bottom-1 right-1">
        {/* show icon mute when props muted is true */}
        {muted && (
          <div className="crew-badge-icon-red">
            <MicOff width={24} height={24} />
          </div>
        )}
      </div>
    )
  }
)
