import { ComponentProps, forwardRef, memo, PropsWithChildren } from 'react'
import Scheduler from 'devextreme-react/scheduler'

type Props = PropsWithChildren<ComponentProps<typeof Scheduler>>

export const CrewScheduler = memo(
  forwardRef<Scheduler, Props>(({ children, ...rest }, ref) => (
    <Scheduler ref={ref} {...rest}>
      {children}
    </Scheduler>
  ))
)
