import { FC, memo } from 'react'

import { ChatSearchHeader } from './components/chatSearchHeader/chatSearchHeader'
import { ChatSearchMessageList } from './components/chatSearchMessageList/chatSearchMessageList'
import { ChatView } from '@crew/enums/app'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { useChatSearchService } from '@crew/states'

export const ChatSearch: FC = memo(() => {
  // 処理対象のチャットルームをViewModelから取得
  const currentChatRoom = useAppSelector(
    (state) => state.message.chat.current.chatRoom
  )
  // この処理が流れる際、ViewModelには必ずチャットルームが設定されているはずなので、未設定の場合はエラーとする
  if (!currentChatRoom) {
    throw new Error('currentChatRoom is undefined')
  }
  const currentChatRoomId = currentChatRoom.id

  // Current表示形式を取得する
  const currentdisplayFormat = useAppSelector(
    (state) => state.message.chat.current.displayFormat
  )
  // タイムライン形式の該当チャットルームの検索範囲を取得
  const currentTimelineSearchRange = useAppSelector(
    (state) =>
      state.message.chat.timeline.entities[currentChatRoomId]?.searchRange
  )
  // スレッド形式の該当チャットルームの検索範囲を取得
  const currentThreadListSearchRange = useAppSelector(
    (state) =>
      state.message.chat.threadList.entities[currentChatRoomId]?.searchRange
  )

  const dispatch = useAppDispatch()
  const chatSearchService = useChatSearchService(dispatch)

  // 呼び出し元で選択されている検索範囲を取得して、ChatSearchに設定する
  if (currentdisplayFormat === ChatView.Timeline) {
    // タイムライン形式の場合
    if (currentTimelineSearchRange) {
      // 検索範囲を設定
      chatSearchService.setSearchRange({
        searchRange: currentTimelineSearchRange,
        chatRoomId: currentChatRoomId,
      })
    }
  } else if (currentdisplayFormat === ChatView.ThreadList) {
    // スレッドリスト形式の場合
    if (currentThreadListSearchRange) {
      // 検索範囲を設定
      chatSearchService.setSearchRange({
        searchRange: currentThreadListSearchRange,
        chatRoomId: currentChatRoomId,
      })
    }
  } else {
    // それ以外の形式の場合はエラー
    console.error(
      `[ChatSearch] Current display format is invalid. format:${currentdisplayFormat}`
    )
    return null
  }

  return (
    <div className="flex flex-col w-full h-full gap-1 overflow-hidden">
      {/* 検索バー */}
      <ChatSearchHeader />

      {/* 検索結果 */}
      <ChatSearchMessageList />
    </div>
  )
})
