import { NotifyEventType } from 'enums/app'
import { useCallback, useState } from 'react'
import {
  useInsertProjectMemberPendingsMutation,
  useInsertProjectMembersMutation,
} from '@crew/apis/project/projectApis'
import { useAppDispatch } from 'states/hooks'
import dayjs from '@crew/modules'
import { JsonDateFormat } from '@crew/enums/system'
import { Project, ProjectMember } from '@crew/models/domain'
import {
  ObjectEventMessage,
  notifyProjectEvent,
  notifyProjectSettingMemberEvent,
} from 'features/app/states/appSlice'
import { createUninitializedContext } from '@crew/utils/context'
import { User } from '@crew/apis/project/models/getNonProjectMembers/response'

export type RoleSelectType = {
  id: string
  name: string
  roleCode: string | null
}

export type ExistingUserType = User & {
  role: RoleSelectType
}

export type NewUserType = {
  emailOrLoginID: string
  projectRole: RoleSelectType
  tenantRoleType: string
  expirationDatetime: string | null
}

/** Context用の型 */
type ProjectMemberEntryDialogContextType = {
  selectedExistingUsers: ExistingUserType[]
  setSelectedExistingUsers: (users: ExistingUserType[]) => void

  selectedNewUsers: NewUserType[]
  setSelectedNewUsers: (newUsers: NewUserType[]) => void
}

/** Context */
const {
  context: projectMemberEntryDialogContext,
  useInitializedContext: useProjectMemberEntryContext,
} = createUninitializedContext<ProjectMemberEntryDialogContextType>()

export { useProjectMemberEntryContext }
export const useProjectMemberEntryDialog = () => {
  const dispatch = useAppDispatch()

  const [selectedNewUsers, setSelectedNewUsers] = useState<NewUserType[]>([])
  const [selectedExistingUsers, setSelectedExistingUsers] = useState<
    ExistingUserType[]
  >([])

  // Get functions for create project member
  const [
    insertProjectMembersMutation,
    { isLoading: isLoadingInsertProjectMembers },
  ] = useInsertProjectMembersMutation()
  const [
    insertProjectMemberPendingsMutation,
    { isLoading: isLoadingInsertProjectMemberPending },
  ] = useInsertProjectMemberPendingsMutation()

  // Insert project members process
  const insertProjectMembers = useCallback(
    async (projectId: string) => {
      const payload = {
        projectId: projectId,
        users: selectedExistingUsers.map((user) => ({
          userId: user.id,
          roleId: user.role.id,
        })),
      }
      //add member to project
      await insertProjectMembersMutation({
        projectMembers: payload,
      }).unwrap()

      const objectEventMessageProjectSetting: ObjectEventMessage<ProjectMember> =
        {
          eventType: NotifyEventType.Inserted,
          id: selectedExistingUsers[0].id,
          object: {
            userId: selectedExistingUsers[0].id,
            roleId: selectedExistingUsers[0].role.id,
          },
        }
      dispatch(
        notifyProjectSettingMemberEvent(objectEventMessageProjectSetting)
      )

      // プロジェクト詳細のメンバー再取得のためEventMessageをdispatch
      const objectEventMessageProject: ObjectEventMessage<Project> = {
        eventType: NotifyEventType.Updated,
        id: projectId,
        object: {
          id: projectId,
        },
      }
      dispatch(notifyProjectEvent(objectEventMessageProject))
    },
    [dispatch, insertProjectMembersMutation, selectedExistingUsers]
  )

  // Insert project member pending process
  const insertProjectMemberPendings = useCallback(
    async (projectId: string) => {
      const payload = {
        projectId: projectId as string,
        projectMembers: selectedNewUsers.map((user) => ({
          destination: user.emailOrLoginID,
          projectRoleId: user.projectRole.id,
          tenantRoleType: user.tenantRoleType,
          expirationDatetime: user.expirationDatetime
            ? dayjs(user.expirationDatetime).format(JsonDateFormat.YYYYMMDD)
            : null,
        })),
      }

      //invite users to project
      const result = await insertProjectMemberPendingsMutation({
        projectMembersPending: payload,
      }).unwrap()

      // refresh project setting members
      const objectEventMessageProjectSetting: ObjectEventMessage<ProjectMember> =
        {
          eventType: NotifyEventType.Inserted,
          id: result.projectMembersPending[0].id,
          object: result.projectMembersPending[0],
        }
      dispatch(
        notifyProjectSettingMemberEvent(objectEventMessageProjectSetting)
      )
    },
    [dispatch, insertProjectMemberPendingsMutation, selectedNewUsers]
  )

  return {
    selectedNewUsers,
    setSelectedNewUsers,
    selectedExistingUsers,
    setSelectedExistingUsers,
    insertProjectMembers,
    insertProjectMemberPendings,

    projectMemberEntryDialogContext,
    isLoadingInsertProjectMembers,
    isLoadingInsertProjectMemberPending,
  }
}
