export type DateFilterValue = {
  conditionTypeId: string
  value: ParamValue
}

export type ParamType = 'nothing' | 'date' | 'number' | 'dateRange'
export type ParamValue = string | string[] | undefined

export type SearchFilter = {
  field: string
  value: ParamValue
}

export type FilterDefinition = {
  filters: { [key: string]: SearchFilter }
  orders: string[]
}

export type SearchOption = {
  id: string
  name: string
  label: string
  defaultValue: ParamValue
  defaultShown: boolean
}

export const SelfKey = 'me' // 自分を表すキー
export const SelfName = 'label.myself' // 自分の名前(要i18n対応)

export function stringToDateFilterValue(param: string): DateFilterValue {
  const params = param.trim().split(':')
  if (params.length < 1) throw new Error('invalid param')
  const conditionTypeId = params[0]
  const value = params[1] ?? undefined
  const values = value ? value?.split('_') : undefined

  switch (conditionTypeId) {
    case 'yesterday':
    case 'today':
    case 'tomorrow':
    case 'lastMonth':
    case 'thisMonth':
    case 'nextMonth':
      return { conditionTypeId: conditionTypeId, value: value }
    case 'thatDay':
    case 'beforeAt':
    case 'afterAt':
      return { conditionTypeId: conditionTypeId, value: value }
    case 'pastDays':
      return { conditionTypeId: conditionTypeId, value: value }
    case 'between':
      return { conditionTypeId: conditionTypeId, value: values }
    case 'nextDays':
      return { conditionTypeId: conditionTypeId, value: values }
    default:
      throw new Error('unknown conditionTypeId')
  }
}

export function dateFilterValueToString(value: DateFilterValue): string {
  switch (value.conditionTypeId) {
    case 'yesterday':
    case 'today':
    case 'tomorrow':
    case 'lastMonth':
    case 'thisMonth':
    case 'nextMonth':
      return `${value.conditionTypeId}`
    case 'thatDay':
    case 'beforeAt':
    case 'afterAt':
      return `${value.conditionTypeId}:${value.value}`
    case 'pastDays':
      return `${value.conditionTypeId}:${value.value}`
    case 'between':
      if (Array.isArray(value.value)) {
        return `${value.conditionTypeId}:${value.value.join('_')}`
      } else {
        throw new Error('paramValue must be an array')
      }
    case 'nextDays':
      if (Array.isArray(value.value)) {
        return `${value.conditionTypeId}:${value.value.join('_')}`
      } else {
        throw new Error('paramValue must be an array')
      }
    default:
      throw new Error('unknown conditionTypeId')
  }
}
