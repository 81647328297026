import { ReactNode, memo, useMemo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import BaselineDelete from '~icons/ic/baseline-delete'
import BaselineCreate from '~icons/ic/baseline-create'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'

type TaskCategory = {
  name: string
  createdAt: string
}

type TaskCategoryListItemProps = {
  taskCategory: TaskCategory
  onEditButtonClick: () => void
  onDeleteButtonClick: () => void
  dragHandle: ReactNode
}

export const TaskCategoryListItem = memo((props: TaskCategoryListItemProps) => {
  const { t } = useTranslation()

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  // 登録日時
  const renderRegisteredDateCell = useMemo(
    () =>
      t('format.timestamp', {
        value: props.taskCategory.createdAt,
        timeZone: defaultUserProfileRegion,
      }),
    [t, props.taskCategory.createdAt, defaultUserProfileRegion]
  )

  return (
    <>
      {/* drag handle item */}
      <td>{props.dragHandle}</td>

      {/* name */}
      <td className="w-4/12 max-w-0">
        <div className="truncate">{props.taskCategory.name}</div>
      </td>

      {/* registered date */}
      <td className="w-5/12">{renderRegisteredDateCell}</td>

      {/* Action button */}
      <td className="w-3/12">
        <div className="flex gap-2 pr-2 justify-end items-center">
          <span className="cursor-pointer" onClick={props.onEditButtonClick}>
            <BaselineCreate width={24} height={24} />
          </span>
          <span className="cursor-pointer" onClick={props.onDeleteButtonClick}>
            <BaselineDelete width={24} height={24} />
          </span>
        </div>
      </td>
    </>
  )
})
