import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { memo, FC } from 'react'
import { DirectMessagePanel } from './components/directMessagePanel/directMessagePanel'
import { EntityType } from '@crew/enums/domain'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from 'states/hooks'
import { useChatCurrentService } from '@crew/states'
import { skipToken } from '@reduxjs/toolkit/query'
import { useGetChatRoomByEntityQuery } from '@crew/apis/chat/chatApis'
import { useGetProjectForDirectChannelQuery } from '@crew/apis/project/projectApis'
import { GetProjectForDirectChannelRequest } from '@crew/apis/project/models/getProjectForDirectChannel/request'
import { GetChatRoomByEntityRequest } from '@crew/apis/chat/models/getChatRoomByEntity/request'
import { useValueChangeEffect } from '@crew/hooks'

export const DirectMessagePage: FC = memo(() => {
  const { projectId } = useParams()

  const dispatch = useAppDispatch()

  // Sliceの操作を行うためのServiceを取得
  const chatCurrentService = useChatCurrentService(dispatch)

  // direct channelを取得する
  // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
  const getDirectChannelParam: GetProjectForDirectChannelRequest | undefined =
    projectId
      ? {
          projectId,
        }
      : undefined
  const { data: getDirectChannelResult } = useGetProjectForDirectChannelQuery(
    getDirectChannelParam ?? skipToken
  )

  const isDirectChannelRegistered =
    getDirectChannelResult?.directChannel !== null

  // チャットルーム取得にはダイレクトチャンネルが存在している必要があるので、取得できない場合はチャットルーム取得処理を実行しない
  const getChatRoomParams: GetChatRoomByEntityRequest | undefined =
    getDirectChannelResult?.directChannel?.id
      ? {
          entityType: EntityType.Project,
          entityRecordId: getDirectChannelResult?.directChannel?.id,
        }
      : undefined

  // ダイレクトチャンネルが存在する場合、チャットルームを取得する
  const { data: getChatRoomResponse } = useGetChatRoomByEntityQuery(
    getChatRoomParams ?? skipToken
  )

  // 取得したチャットルームIDに紐づくチャットメッセージを取得する
  useValueChangeEffect(
    () => {
      if (
        getChatRoomResponse?.chatRoom &&
        getDirectChannelResult?.directChannel?.id
      ) {
        // 表示するチャットルームを設定する
        chatCurrentService.setCurrentChatRoomAndRestoreChatThread({
          id: getChatRoomResponse.chatRoom.id,
          rootEntityType: EntityType.Project,
          rootEntityRecordId: getDirectChannelResult.directChannel.id,
          entityType: EntityType.Project,
          entityRecordId: getDirectChannelResult.directChannel.id,
        })
      }
    },
    // チャットルーム変更時とダイレクトメッセージが追加されたときのみ処理する
    [
      chatCurrentService,
      getChatRoomResponse?.chatRoom,
      getChatRoomResponse?.chatRoom?.id,
      getDirectChannelResult?.directChannel?.id,
    ],
    [getChatRoomResponse?.chatRoom?.id]
  )

  // 以前表示していたダイレクトチャンネルが表示されないよう、チャットルームの表示をリセットする
  useValueChangeEffect(
    () => {
      if (!isDirectChannelRegistered) {
        chatCurrentService.resetCurrentChatRoom()
      }
      // isDirectChannelRegisteredの変更だけ検知すれば良いので、その他の依存は不要
    },
    [chatCurrentService, isDirectChannelRegistered],
    [isDirectChannelRegistered]
  )

  // Do not show content if the direct channel does not existed
  if (!getDirectChannelResult?.directChannel) {
    return null
  }

  return (
    <CrewErrorBoundary>
      {
        // ダイレクトチャンネルが既に登録済みの場合はダイレクトメッセージパネルを表示する
        <DirectMessagePanel />
      }
    </CrewErrorBoundary>
  )
})
