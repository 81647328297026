var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice, } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetPresenceStatesQuery } from '@crew/apis/presenceState/presenceStateApis';
const initialSliceState = { presenceStates: {} };
//**************************************************** slice ***********************************************************/
export const presenceStatesSlice = createSlice({
    name: 'presenceStates',
    initialState: initialSliceState,
    reducers: {
        setPresenceStates: (state, action) => {
            const presenceMap = {};
            action.payload.forEach((state) => {
                presenceMap[state.id] = state;
            });
            state.presenceStates = presenceMap;
        },
    },
});
const { setPresenceStates } = presenceStatesSlice.actions;
//**************************************************** custom hooks ***********************************************************/
// Use throughout your app instead of plain `useDispatch` and `useSelector`
const useAppDispatch = () => useDispatch();
const useAppSelector = useSelector;
/**
 * A hook that returns the presence states from the API
 * @returns 読み込みトリガ関数
 */
export const useLoadPresenceStates = () => {
    const [lazyGetPresenceStatesQuery] = useLazyGetPresenceStatesQuery();
    const dispatch = useAppDispatch();
    const loadPresenceStates = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const getPresenceStates = yield lazyGetPresenceStatesQuery().unwrap();
            if (getPresenceStates) {
                const presenceStates = getPresenceStates.presenceStates.map((presenceState) => {
                    return {
                        id: presenceState.id,
                        name: presenceState.name,
                        presenceStateType: presenceState.presenceStateType,
                    };
                });
                dispatch(setPresenceStates(presenceStates));
            }
        }
        catch (err) {
            console.error('Failed to load presence states:', err);
        }
    }), [dispatch, lazyGetPresenceStatesQuery]);
    return [loadPresenceStates];
};
/**
 * Hook to get the presence state by id
 * @param id
 * @returns
 */
export const usePresenceState = (id) => {
    const presenceState = useAppSelector((state) => state.presenceStates.presenceStates[id]);
    return presenceState;
};
