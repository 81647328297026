import { FC, memo } from 'react'
import { useProjectGroupListGrid } from './useProjectGroupListGrid'
import { ProjectGroupEntryDialog } from 'features/tenant/components/tenantSettingPage/components/projectGroupList/components/projectGroupEntryDialog/projectGroupEntryDialog'
import { CrewErrorDialog } from 'components/elements/crewErrorDialog/crewErrorDialog'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { ProjectGroupTable } from './components/projectGroupTable/projectGroupTable'
import { useTranslation } from '@crew/modules/i18n'
import { useCallback, useEffect, useState } from 'react'
import { useGetProjectGroupsQuery } from '@crew/apis/project/projectApis'
import { useAppSelector } from 'states/hooks'
import { useToast } from 'hooks/useToast'
import { useModal } from 'components/layouts/modal/useModal'
import { GetProjectGroupsRequest } from '@crew/apis/project/models/getProjectGroups/request'
import { skipToken } from '@reduxjs/toolkit/query'
import { useSearchParams } from 'react-router-dom'
import { SelectedProjectGroup } from './useProjectGroupListGrid'
export type ProjectGroupListGridProps = {}

export const ProjectGroupListGrid: FC<ProjectGroupListGridProps> = memo(() => {
  const {
    isLoadingCheckReferenceProjectGroup,
    isLoadingDeleteProject,
    deleteProjectGroup,
    checkReferenceProjectGroup,
  } = useProjectGroupListGrid()

  const { t } = useTranslation()
  const { success } = useToast()

  const [
    isProjectGroupEntryDialogOpen,
    openProjectGroupEntryDialog,
    closeProjectGroupEntryDialog,
  ] = useModal()

  // const projectGroupsDataGridRef = useRef<DataGrid>(null)
  const projectGroupEventMessage = useAppSelector(
    (state) => state.app.projectGroupEventMessage
  )

  const [selectedProjectGroup, setSelectedProjectGroup] = useState<
    SelectedProjectGroup | undefined
  >()

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()
  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')
  // 確認ダイアログ キャンセルボタン押下
  const handleConfirmCancelButtonClick = useCallback(() => {
    closeConfirmDialog()
  }, [closeConfirmDialog])

  const [
    isDeleteReferenceGroupConfirmDialogOpen,
    setIsDeleteReferenceGroupConfirmDialogOpen,
  ] = useState(false)

  const [isErrorDialogOpen, openErrorDialog, closeErrorDialog] = useModal()

  // エラーダイアログメッセージ
  const [errorMessage, setErrorMessage] = useState('')

  const [searchParams] = useSearchParams()
  const getProjectGroupsParams: GetProjectGroupsRequest | undefined = {
    sort: searchParams.getAll('sort') ?? undefined,
  }
  const { data: getProjectGroupsResult, refetch: getProjectGroupsRefetch } =
    useGetProjectGroupsQuery(getProjectGroupsParams ?? skipToken)

  // refresh project group list
  useEffect(() => {
    getProjectGroupsRefetch()
  }, [getProjectGroupsRefetch, projectGroupEventMessage])

  // Gridの編集アイコン押下
  const handleProjectGroupGridEditButtonClick = useCallback(
    (projectGorupId: string, projectGorupVersion: number) => {
      // id値はイベントオブジェクトから取得可能
      setSelectedProjectGroup({
        projectGroupId: projectGorupId,
        version: projectGorupVersion,
      })

      openProjectGroupEntryDialog()
    },
    [openProjectGroupEntryDialog]
  )

  // Grid内の削除アイコン押下
  const handleProjectGroupGridDeleteButtonClick = useCallback(
    (projectGorupId: string, projectGorupVersion: number) => {
      setConfirmMessage(t('message.general.confirmMessage.delete'))
      openConfirmDialog()

      setSelectedProjectGroup({
        projectGroupId: projectGorupId,
        version: projectGorupVersion,
      })
    },
    [openConfirmDialog, t]
  )

  // handle open delete reference group confirm dialog
  const handleOpenDeleteReferenceGroupConfirmDialog = useCallback(() => {
    setIsDeleteReferenceGroupConfirmDialogOpen(true)
  }, [])

  // 削除確認ダイアログ OKボタン
  const handleDeletePermitButtonClick = useCallback(async () => {
    if (selectedProjectGroup) {
      try {
        const checkReferenceProjectGroupResult =
          await checkReferenceProjectGroup(selectedProjectGroup.projectGroupId)

        // If there is a reference project for this group, show dialog confirm before performing delete project group process
        if (checkReferenceProjectGroupResult.isReference) {
          closeConfirmDialog()
          handleOpenDeleteReferenceGroupConfirmDialog()
          return
        }

        await deleteProjectGroup(
          selectedProjectGroup.projectGroupId,
          selectedProjectGroup.version
        )

        success(t('message.projectGroup.projectGroupDeleted'))
      } catch (error) {
        setErrorMessage(t('message.general.errorMessage.delete'))
        openErrorDialog()
      }
    }
    closeConfirmDialog()
  }, [
    checkReferenceProjectGroup,
    closeConfirmDialog,
    deleteProjectGroup,
    handleOpenDeleteReferenceGroupConfirmDialog,
    openErrorDialog,
    selectedProjectGroup,
    success,
    t,
  ])

  // ダイアログのキャンセルボタン押下
  const handleProjectGroupEntryDialogClose = useCallback(() => {
    // ここで選択されたIDを初期化しないと次回のload時に編集前の値が残るのでresetする
    setSelectedProjectGroup({
      projectGroupId: '',
      version: 0,
    })

    closeProjectGroupEntryDialog()
  }, [closeProjectGroupEntryDialog])

  // Event handle when Cancel button on Delete reference group confirmation dialog is clicked
  const handleCloseButtonOnDeleteReferenceGroupConfirmDialogClick =
    useCallback(() => {
      setIsDeleteReferenceGroupConfirmDialogOpen(false)
    }, [])

  // Event handle when OK button on Delete reference group confirmation dialog is clicked
  const handlePermitButtonOnDeleteReferenceGroupConfirmDialogClick =
    useCallback(async () => {
      try {
        if (selectedProjectGroup) {
          await deleteProjectGroup(
            selectedProjectGroup.projectGroupId,
            selectedProjectGroup.version
          )
        }

        //close dialog
        success(t('message.projectGroup.projectGroupDeleted'))
      } catch (error) {
        setErrorMessage(t('message.general.errorMessage.delete'))
        openErrorDialog()
      }
      handleCloseButtonOnDeleteReferenceGroupConfirmDialogClick()
    }, [
      deleteProjectGroup,
      handleCloseButtonOnDeleteReferenceGroupConfirmDialogClick,
      openErrorDialog,
      selectedProjectGroup,
      success,
      t,
    ])

  return (
    <>
      <div className="h-full flex flex-col">
        {getProjectGroupsResult?.projectGroups && (
          <ProjectGroupTable
            projectGroups={getProjectGroupsResult?.projectGroups}
            onProjectGroupGridDeleteButtonClick={
              handleProjectGroupGridDeleteButtonClick
            }
            onProjectGroupGridEditButtonClick={
              handleProjectGroupGridEditButtonClick
            }
          />
        )}
        <CrewConfirmDialog
          isOpen={isConfirmDialogOpen}
          message={confirmMessage}
          onPermitButtonClick={handleDeletePermitButtonClick}
          onCancelButtonClick={handleConfirmCancelButtonClick}
          permitButtonDisabled={
            isLoadingDeleteProject || isLoadingCheckReferenceProjectGroup
          }
        />

        <CrewErrorDialog
          isOpen={isErrorDialogOpen}
          message={errorMessage}
          onCloseButtonClick={closeErrorDialog}
        />

        {/* Delete reference group confirmation dialog */}
        <CrewConfirmDialog
          isOpen={isDeleteReferenceGroupConfirmDialogOpen}
          message={t('message.project.confirmDeleteProjectGroup')}
          onPermitButtonClick={
            handlePermitButtonOnDeleteReferenceGroupConfirmDialogClick
          }
          onCancelButtonClick={
            handleCloseButtonOnDeleteReferenceGroupConfirmDialogClick
          }
          permitButtonDisabled={isLoadingDeleteProject}
        />
      </div>

      <ProjectGroupEntryDialog
        isEditMode={true}
        title={t('label.projectGroupEdit')}
        isOpen={isProjectGroupEntryDialogOpen}
        onClose={handleProjectGroupEntryDialogClose}
        projectGroupId={selectedProjectGroup?.projectGroupId}
      />
    </>
  )
})
