import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useUpdateExternalUserCountMutation } from '@crew/apis/contract/contractApis'

export type FormValues = {
  numberOfInvitees: number
}

const formInitialValues: FormValues = {
  numberOfInvitees: 0,
}

export const useContractPurchaseExternalUserPage = () => {
  const { control, handleSubmit, setValue } = useForm<FormValues>({
    criteriaMode: 'all',
    defaultValues: formInitialValues,
  })

  const [
    updateExternalUserCountMutation,
    { isLoading: isLoadingUpdateExternalUserCount },
  ] = useUpdateExternalUserCountMutation()

  // 招待可能人数を更新する処理
  const updateInvitableExternalUserCount = useCallback(
    async (addExternalUserCount: number) => {
      await updateExternalUserCountMutation({
        ExternalUserCount: addExternalUserCount,
      }).unwrap()
    },
    [updateExternalUserCountMutation]
  )

  return {
    control,
    handleSubmit,
    setValue,
    updateInvitableExternalUserCount,
    isLoadingUpdateExternalUserCount,
  }
}
