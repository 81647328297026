import { memo } from 'react'
import {
  CrewAvatarSize,
  CrewAvatar,
} from 'components/elements/crewAvatar/crewAvatar'
import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { generateImageAvatarUrl } from '@crew/utils/avatar'
import { EntityType } from '@crew/enums/domain'
import { skipToken } from '@reduxjs/toolkit/query'
import { GetUserRequest } from '@crew/apis/lookup/models/getUser/request'
import { useGetLookupUserQuery } from '@crew/apis/lookup/lookupApis'

type OwnerFieldRenderProps = {
  ownerId?: string
  ownerName?: string
}

export const OwnerFieldRender = memo((props: OwnerFieldRenderProps) => {
  // ユーザー情報取得（アバターのキャッシュ用にバージョンが必要なため）
  const getUserParam: GetUserRequest | undefined = props.ownerId
    ? {
        userId: props.ownerId,
      }
    : undefined
  const { data: getUserResult } = useGetLookupUserQuery(
    getUserParam ?? skipToken
  )

  return (
    <div className="flex items-center gap-x-0.5 w-full">
      {props.ownerName && props.ownerId && (
        <CrewAvatar
          displayName={props.ownerName}
          imageURL={generateImageAvatarUrl(EntityType.User, props.ownerId)}
          className="flex-none ml-2.5"
          size={CrewAvatarSize.xs}
          cacheValue={
            // 型制約上nullチェックが必要なためチェックを行うが、基本はnullになることはない
            getUserResult?.user
              ? getUserResult.user.id + getUserResult.user.version
              : ''
          }
        />
      )}
      <CrewTextBox defaultValue={props.ownerName} readOnly={false} />
    </div>
  )
})
