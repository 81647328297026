import { UnreadInfoTarget } from '@crew/enums/domain'
import { EntityType, ProjectType } from '@crew/enums/domain'
import { useValueChangeEffect } from '@crew/hooks'
import { ActiveChatRoomInfo } from '@crew/states'
import { generateImageAvatarUrl } from '@crew/utils/avatar'
import { getDefaultTabValue } from '@crew/utils/enum'
import classNames from 'classnames'
import {
  CrewAvatar,
  CrewAvatarShapeType,
  CrewAvatarSize,
} from 'components/elements/crewAvatar/crewAvatar'
import { CrewChatRoomUnreadBadge } from 'components/elements/crewChatRoomUnreadBadge/crewChatRoomUnreadBadge'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'
import { DirectMessageTabs, ProjectListTabs, ProjectScope } from 'enums/app'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { LeftSideBarContextMenu } from '../../../../../leftSideBarContextMenu/leftSideBarContextMenu'
import {
  CrewUserPresence,
  UserPresenceState,
} from 'components/elements/crewUserPresence/crewUserPresence'
import { useTranslation } from '@crew/modules/i18n'
import { GetProjectForDirectChannelRequest } from '@crew/apis/project/models/getProjectForDirectChannel/request'
import { useGetProjectForDirectChannelQuery } from '@crew/apis/project/projectApis'

export type UnreadProjectItemProps = {
  chatRoom: ActiveChatRoomInfo
}

// メニューの基本スタイル
const baseClassNames =
  'flex flex-row h-full w-full items-center p-2 gap-2 hover:font-bold [&:has(.has-unread)]:font-bold group-hover:font-bold'

// 選択メニューのアクティブ状態スタイル（ハイライト）
const activeClassNames = 'font-bold crew-text-gray-1 crew-bg-gray-2'

export const UnreadProjectItem: FC<UnreadProjectItemProps> = memo((props) => {
  const location = useLocation()
  const { t } = useTranslation()

  const [isActive, setIsActive] = useState(false)
  const [hover, setHover] = useState(false)

  // ホバーメニューを表示
  const handleMouseEnter = useCallback(() => {
    setHover(true)
  }, [])

  // ホバーメニューを非表示
  const handleMouseLeave = useCallback(() => {
    setHover(false)
  }, [])

  // URLの変更に応じてハイライトさせるかどうかをハンドリングするためisActiveを変更する
  useValueChangeEffect(
    () => {
      // eg: location.pathname = /projects/{projectId}/xxx
      const locationSegments = location.pathname.split('/')
      if (
        (locationSegments[1] === 'direct-channels' ||
          locationSegments[1] === EntityType.Project) &&
        locationSegments[2] === props.chatRoom.projectId
      ) {
        setIsActive(true)
      } else {
        setIsActive(false)
      }
    },
    [location, props.chatRoom.projectId],
    location
  )
  // クリック時のナビゲーション先
  const navLink = useMemo(() => {
    if (props.chatRoom.projectType === ProjectType.Project) {
      return `/${EntityType.Project}/${
        props.chatRoom.projectId
      }/${getDefaultTabValue(ProjectListTabs)}`
    } else if (props.chatRoom.projectType === ProjectType.DirectChannel) {
      return `/direct-channels/${props.chatRoom.projectId}/${getDefaultTabValue(
        DirectMessageTabs
      )}`
    }

    // projectTypeが適切でないことは通常あり得ないが、NavLinkのtoがundefinedを取れないため空文字を返すようにしておく
    return ''
  }, [props.chatRoom.projectType, props.chatRoom.projectId])

  // Get direct channel
  const getProjectForDirectChannelRequest: GetProjectForDirectChannelRequest = {
    projectId: props.chatRoom.projectId,
  }
  const { data: getDirectChannelResult } = useGetProjectForDirectChannelQuery(
    getProjectForDirectChannelRequest
  )

  // Get the partner of the logged in user in the current channel
  const partner = getDirectChannelResult?.directChannel?.user2

  const [userPresenceState, setUserPresenceState] =
    useState<UserPresenceState>()

  // handle presence state change
  const handleUserPresenceStateChange = useCallback(
    (userPresence: UserPresenceState) => {
      setUserPresenceState(userPresence)
    },
    []
  )

  // text presence state
  const textPresenceState = useMemo(() => {
    if (!userPresenceState) return t('label.unknown')

    if (!userPresenceState.presenceStateMessage) return userPresenceState.name

    return `${userPresenceState.name} / ${userPresenceState.presenceStateMessage}`
  }, [t, userPresenceState])

  return (
    <li
      key={props.chatRoom.projectId}
      className="flex flex-row group relative justify-between"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <NavLink
        to={navLink}
        className={classNames(baseClassNames, isActive && activeClassNames)}
      >
        <div className="flex">
          <div className="flex w-full items-center">
            {/* アバターを描画 */}
            {props.chatRoom.projectType === ProjectType.Project && (
              <CrewAvatar
                displayName={props.chatRoom.name}
                size={CrewAvatarSize.xs}
                shape={CrewAvatarShapeType.Square}
                imageURL={generateImageAvatarUrl(
                  EntityType.Project,
                  props.chatRoom.projectId
                )}
                isPrivate={props.chatRoom.scope === ProjectScope.Private.key}
                cacheValue={
                  props.chatRoom.projectId + props.chatRoom.projectVersion
                }
              />
            )}
            {props.chatRoom.projectType === ProjectType.DirectChannel && (
              <div className="flex items-center space-x-1 relative">
                <CrewUserAvatar
                  userId={props.chatRoom.projectId}
                  directChannelId={props.chatRoom.projectId}
                  displayName={props.chatRoom.name}
                  size={CrewAvatarSize.xs}
                  cacheValue={
                    props.chatRoom.projectId + props.chatRoom.projectVersion
                  }
                  showPresenceState={true}
                  textPresenceState={textPresenceState}
                />
                {partner && (
                  <div className="absolute bottom-0 right-0">
                    <CrewUserPresence
                      userId={partner.id}
                      onChange={handleUserPresenceStateChange}
                    />
                  </div>
                )}
              </div>
            )}

            {/* 選択中 または 未読ありの場合、太字で表示 */}
            <span className="line-clamp-2 ml-2 mr-1 break-all">
              {props.chatRoom.name}
            </span>
            <CrewChatRoomUnreadBadge chatRoomId={props.chatRoom.chatRoomId} />
          </div>

          <div className="flex w-4 items-center ml-1">
            <LeftSideBarContextMenu
              target={UnreadInfoTarget.ChatRoom}
              chatRoomId={props.chatRoom.chatRoomId}
              // add remove favorite menu item
              projectId={props.chatRoom.projectId}
              hover={hover}
              isFavorite={props.chatRoom.isFavorite}
              enableMarkAllRead={true} // 「未読」では「すべて既読にする」は表示する
            />
          </div>
        </div>
      </NavLink>
    </li>
  )
})
