import { useUploadFileMutation } from '@crew/apis/file/fileApis'
import { AppLanguage } from 'enums/app'
import { useCallback, useMemo } from 'react'
import { useUpdateUserSettingsMutation } from '@crew/apis/setting/settingApis'
import { ValidateRules } from '@crew/utils/form'
import { useForm } from 'react-hook-form'
import { useTranslation } from '@crew/modules/i18n'
import { UpdateUserSettingsRequest } from '@crew/apis/setting/models/updateUserSettings/request'
import { Region, SettingKeyType } from '@crew/enums/app'

export type FormValues = {
  displayName: string
  alias: string
  searchKey: string
  department: string
  language: string | null
  region: string | null
  avatarKey: string | null
}

export const formInitialValues: FormValues = {
  displayName: '',
  alias: '',
  searchKey: '',
  department: '',
  language: AppLanguage.Ja, //default language value is Japanese
  region: Region.Japan.value, //default region value is Japan
  avatarKey: null,
}

export const usePersonalSettingProfileForm = () => {
  const { t } = useTranslation()

  // react-hook-formの各種データを取得
  const { control, setValue, reset, handleSubmit, setError } =
    useForm<FormValues>({
      criteriaMode: 'all',
      defaultValues: formInitialValues,
    })

  // Get function for upload file and update user settings
  const [uploadFileMutation] = useUploadFileMutation()
  const [
    updateUserSettingsMutation,
    { isLoading: isLoadingUpdateUserSetting },
  ] = useUpdateUserSettingsMutation()

  // Upload file process
  const uploadFile = useCallback(
    async (file: File) => {
      const result = await uploadFileMutation({ file }).unwrap()

      return result
    },
    [uploadFileMutation]
  )

  // Update user settings process
  const updateUserSettings = useCallback(
    async (data: FormValues, version: number) => {
      // user setting params
      const userSettingData: UpdateUserSettingsRequest = {
        userSettings: {
          displayName: data.displayName,
          alias: data.alias ?? '',
          searchKey: data.searchKey ?? '',
          department: data.department ?? '',
          avatarKey: data.avatarKey ?? '',
          version: version, //getUser.user.version,
          settings: [
            {
              key: SettingKeyType.UserProfileLanguage,
              value: data.language ?? '',
            },
            {
              key: SettingKeyType.UserProfileRegion,
              value: data.region ?? '',
            },
          ],
        },
      }

      // call api save setting user
      await updateUserSettingsMutation(userSettingData).unwrap()
    },
    [updateUserSettingsMutation]
  )

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      displayName: {
        required: t('message.general.required'),
      },
      // not validate below
      alias: {},
      searchKey: {},
      department: {},
      language: {
        required: t('message.general.required'),
      },
      region: {
        required: t('message.general.required'),
      },
      avatarKey: {},
    }),
    [t]
  )

  return {
    control,
    setValue,
    reset,
    handleSubmit,
    setError,
    validateRules,
    uploadFile,
    updateUserSettings,
    isLoadingUpdateUserSetting,
  }
}
