import { useTranslation } from '@crew/modules/i18n'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { Modal } from 'components/layouts/modal/modal'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { ChatReadStatusUserList } from './components/chatReadStatusUserList/chatReadStatusUserList'
import { UserRef } from '@crew/models/refs'
import { useGetChatMessageReadStatusQuery } from '@crew/apis/chat/chatApis'
import { ChatReadStatus } from '@crew/enums/app'
import { CrewTabs } from 'components/elements/crewTabs/crewTabs'
import {
  BadgeColor,
  CrewTabProps,
} from 'components/elements/crewTabs/components/crewTab'

type User = UserRef & {
  isRead: boolean
}

// ユーザを既読、未読、全ての状態でグループ化
const createUsersMapGroupByReadStatus = (users: User[]) => {
  const usersMap = users.reduce((acc, user) => {
    const key = user.isRead
      ? ChatReadStatus.Read.value
      : ChatReadStatus.Unread.value
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(user)
    return acc
  }, {} as { [key: string]: User[] })

  // add all users
  usersMap[ChatReadStatus.All.value] = users

  return usersMap
}

type ChatReadStatusDialogProps = {
  isOpen: boolean
  title: string
  onClose: () => void
  messageId: string
}

export const ChatReadStatusDialog: FC<ChatReadStatusDialogProps> = memo(
  (props) => {
    const { t } = useTranslation()

    const [selectedIndex, setSelectedIndex] = useState<number>(0) // default first tab

    const selectedStatus = useMemo(
      () => Object.values(ChatReadStatus)[selectedIndex].value,
      [selectedIndex]
    )

    // タブ選択時、選択状態(LocalState)を更新
    const handleTabsSelectionChanged = useCallback((selectedIndex: number) => {
      setSelectedIndex(selectedIndex)
    }, [])

    const { data: getChatMessageReadStatusResult } =
      useGetChatMessageReadStatusQuery({
        messageId: props.messageId,
      })

    // チャットメッセージの既読状態をユーザごとにグループ化
    const chatMessageUserReadMap = useMemo(() => {
      const users = getChatMessageReadStatusResult?.chatMessageReadStatus?.map(
        (readStatus) => {
          return {
            ...readStatus.user,
            isRead: readStatus.isRead,
          }
        }
      )

      return createUsersMapGroupByReadStatus(users || [])
    }, [getChatMessageReadStatusResult?.chatMessageReadStatus])

    // タブアイテム
    const displayTabItems: CrewTabProps[] = useMemo(() => {
      const items = Object.values(ChatReadStatus).map((item, index) => {
        return {
          onClick: () => {
            handleTabsSelectionChanged(index)
          },
          selected: index === selectedIndex,
          text: t(item.text),
          icon: undefined,
          badgeCount: chatMessageUserReadMap[item.value]?.length || 0,
          badgeColor: 'Blue' as BadgeColor,
        }
      })

      return items
    }, [chatMessageUserReadMap, handleTabsSelectionChanged, selectedIndex, t])

    return (
      <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
        <div className="h-[500px] min-w-[480px] flex flex-col overflow-hidden">
          <CrewTabs tabItems={displayTabItems} />

          {/* set width and height of the list to ensure that each item field on the screen is displayed correctly. */}
          <div className="flex-1 overflow-auto">
            <ChatReadStatusUserList
              users={chatMessageUserReadMap[selectedStatus] ?? []}
            />
          </div>

          <div className="text-right mt-2.5">
            <CrewButton
              text={t('action.close')}
              type="normal"
              stylingMode="outlined"
              onClick={props.onClose}
            />
          </div>
        </div>
      </Modal>
    )
  }
)
