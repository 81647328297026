import { useLazyGetMeetingAttendeesQuery } from '@crew/apis/meeting/meetingApis'
import { MeetingAttendeeJoinState } from '@crew/enums/domain'
import { UserRef } from '@crew/models/refs'
import { useTranslation } from '@crew/modules/i18n'
import { DataMessage } from 'amazon-chime-sdk-js'
import {
  AWS_CHIME_BOT_PREFIX,
  TOPIC_MEETING_HOST_CHANGED,
  TOPIC_MEETING_USER_JOIN_STATE_CHANGED,
} from 'configs/constants'
import { useToast } from 'hooks/useToast'
import _ from 'lodash'
import { useMeetingManager } from 'modules/amazon-chime-sdk-component-library-devextreme'
import { useAudioVideo } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/AudioVideoProvider'
import { useFeaturedTileState } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/FeaturedVideoTileProvider'
import { useLocalVideo } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/LocalVideoProvider'
import { useRemoteVideoTileState } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/RemoteVideoTileProvider'
import { useRosterState } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/RosterProvider'
import { RosterAttendeeType } from 'modules/amazon-chime-sdk-component-library-devextreme/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppSelector } from 'states/hooks'

export type AttendeeUser = UserRef &
  RosterAttendeeType & {
    isHost: boolean
    joinState: MeetingAttendeeJoinState
  }

export const useCrewAttendees = () => {
  const toast = useToast()
  const { t } = useTranslation()
  // ------------------
  // ローカル表示関連
  // ------------------
  const { isVideoEnabled } = useLocalVideo()
  const audioVideo = useAudioVideo()
  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)
  const [localAttendeeUser, setLocalAttendeeUser] = useState<
    AttendeeUser | undefined
  >(undefined)

  // ------------------
  // リモート参加者表示関連
  // ------------------
  const { attendeeIdToTileId } = useRemoteVideoTileState()
  const { roster } = useRosterState()
  const { meetingId } = useMeetingManager()
  const [attendeeUsers, setAttendeeUsers] = useState<AttendeeUser[]>([])
  const [lazyGetMeetingAttendeesQuery] = useLazyGetMeetingAttendeesQuery()

  // アクティブスピーカーの参加者IDを取得（表示切替に使用する）
  const { activeSpeakerAttendeeId } = useFeaturedTileState()

  // ローカルユーザーを除いた参加者一覧を取得
  const attendees = useMemo(
    () =>
      Object.values(roster).filter(
        (attendee) => !attendee.externalUserId?.includes(AWS_CHIME_BOT_PREFIX) //Remove AWS Chime Bot
      ),
    [roster]
  )
  // 参加者のユーザー情報を取得
  const getUsers = useCallback(
    async (
      attendees: RosterAttendeeType[],
      meetingId: string,
      loggedInUserId: string
    ) => {
      // 参加者なしの場合、処理中断
      if (attendees.length === 0) {
        return
      }

      // 参加者一覧からユーザーIDのみ抽出
      const attendeeIds = _.map(attendees, 'externalUserId')

      // 参加者のユーザー情報を取得
      const response = await lazyGetMeetingAttendeesQuery({
        meetingId,
        userIds: attendeeIds as string[],
      }).unwrap()

      // [参加者のユーザー情報]と[ChimeのAttendee]をマージしたリストを生成
      let attendeeUsers: AttendeeUser[] = []
      _.each(response.attendees, (user) => {
        // ユーザーIDが一致するものを検索
        const attendee = _.find(attendees, (attendee) => {
          return attendee.externalUserId === user.user.id
        })

        // [参加者のユーザー情報]と[ChimeのAttendee]をマージ
        const attendeeUser = _.merge(attendee, user.user, {
          isHost: user.isHost,
          joinState: user.joinState,
        })

        if (user.user.id !== loggedInUserId) {
          // ユーザーIDがログインユーザーIDではない場合
          attendeeUsers.push(attendeeUser)
        } else {
          // ユーザーIDがログインユーザーIDの場合
          setLocalAttendeeUser(attendeeUser)
        }
      })

      setAttendeeUsers(attendeeUsers)
    },
    [lazyGetMeetingAttendeesQuery]
  )

  useEffect(() => {
    if (!audioVideo || !meetingId || !loggedInUser) {
      console.warn('AudioVideo object is not available')
      return
    }

    // =======================================================
    // ===== Subscribe processing when meeting host changed =====
    // =======================================================
    const onMeetingHostChanged = (dataMessage: DataMessage) => {
      const message = dataMessage.text()
      toast.info(message)

      // get new users list
      getUsers(attendees, meetingId, loggedInUser.id)
    }

    audioVideo.realtimeSubscribeToReceiveDataMessage(
      TOPIC_MEETING_HOST_CHANGED,
      onMeetingHostChanged
    )

    // Subscribe process when meeting attendees change
    const onMeetingAttendeesChanged = (dataMessage: DataMessage) => {
      // 待機中ユーザーが含まれていた場合かつ、参加者一覧が非表示になっている場合、一覧を表示状態に変更する
      // ログインユーザーが主催者の場合のみ
      if (localAttendeeUser?.isHost) {
        const message = dataMessage.text()
        // Dont show toast message if message is empty
        if (message) {
          toast.info(message)
        }
      }

      // get new users list
      getUsers(attendees, meetingId, loggedInUser.id)
    }

    audioVideo.realtimeSubscribeToReceiveDataMessage(
      TOPIC_MEETING_USER_JOIN_STATE_CHANGED,
      onMeetingAttendeesChanged
    )

    return () => {
      if (audioVideo) {
        // Unsubscribe process related to meeting host change
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage(
          TOPIC_MEETING_HOST_CHANGED
        )

        // Unsubscribe process related to meeting attendees change
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage(
          TOPIC_MEETING_USER_JOIN_STATE_CHANGED
        )
      }
    }
  }, [
    attendees,
    audioVideo,
    getUsers,
    localAttendeeUser?.isHost,
    loggedInUser,
    meetingId,
    t,
    toast,
  ])

  // 参加者一覧を設定する(ログインユーザーは除く)
  useEffect(() => {
    // ログイン情報がない場合、何もしない
    if (!loggedInUser || !meetingId) {
      return
    }

    getUsers(attendees, meetingId, loggedInUser.id)
  }, [attendees, getUsers, loggedInUser, meetingId])

  return {
    isVideoEnabled,
    attendeeUsers,
    attendeeIdToTileId,
    localAttendeeUser,
    activeSpeakerAttendeeId,
  }
}
