import { useLazyGetTaskStatesQuery } from '@crew/apis/task/taskApis'
import { EntityType } from '@crew/enums/domain'
import { TaskState } from '@crew/models/domain'
import { useTranslation } from '@crew/modules/i18n'
import { useEffectOnce } from '@dx-system/react-use'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { CrewErrorDialog } from 'components/elements/crewErrorDialog/crewErrorDialog'
import {
  CrewSortableList,
  arrayMove,
} from 'components/elements/crewSortableList/crewSortableList'
import { useModal } from 'components/layouts/modal/useModal'
import { TaskStateTypes } from 'enums/app'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { memo, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'states/hooks'
import { TaskStateEntryDialog } from '../taskStateEntryDialog/taskStateEntryDialog'
import { TaskStateListItem } from './components/taskStateListItem/taskStateListItem'
import { SelectedTaskState, useTaskStateListGrid } from './useTaskStateListGrid'
import { TaskStateTableHeader } from './components/taskStateTableHeader/taskStateTableHeader'

export const TaskStateListGrid = memo(() => {
  const { deleteTaskState, reorderTaskState, isLoadingDeleteTaskState } =
    useTaskStateListGrid()
  const { projectId } = useParams()
  const { t } = useTranslation()
  const { success } = useToast()
  const [showApiErrors] = useShowApiErrors()

  const [
    isTaskStateEntryDialogOpen,
    openTaskStateEntryDialog,
    closeTaskStateEntryDialog,
  ] = useModal()

  const taskStateEventMessage = useAppSelector(
    (state) => state.app.taskStateEventMessage
  )

  const [taskStates, setTaskStates] = useState<TaskState[]>()

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  // 確認ダイアログメッセージ
  const [confirmDialogMessage, setConfirmDialogMessage] = useState('')

  const [isErrorDialogOpen, openErrorDialog, closeErrorDialog] = useModal()

  // エラーダイアログメッセージ
  const [errorMessage, setErrorMessage] = useState('')

  // lazyGetで読み込みを行う。
  // 通常のgetを使うと、ソート失敗後の再読み込みでBackendが返すdataに変化がないため、useEffectが発火せずtaskStatesをリセットできない。
  // lazyGetの結果を常にsetTaskStateすることで回避する。
  const [lazyGetTaskStatesQuery] = useLazyGetTaskStatesQuery()
  const fetchTaskStates = useCallback(async () => {
    if (!projectId) {
      return
    }

    const result = await lazyGetTaskStatesQuery({
      entityType: EntityType.Project,
      entityRecordId: projectId,
    })
    setTaskStates(result.data?.taskStates)
  }, [projectId, lazyGetTaskStatesQuery])

  // 初期読み込み
  useEffectOnce(() => {
    fetchTaskStates()
  })

  // refresh task state list
  useEffect(() => {
    if (taskStateEventMessage) {
      fetchTaskStates()
    }
  }, [fetchTaskStates, taskStateEventMessage])

  // 並び替え処理
  const handleReorder = useCallback(
    async (fromId: string, toId: string) => {
      // ちらつき防止のため、APIを呼ぶ前にUI上での並び替えを先行して行う
      setTaskStates((items) => {
        if (!items) {
          return items
        }

        const from = items.findIndex((item) => item.id === fromId)
        const to = items.findIndex((item) => item.id === toId)
        return arrayMove(items, from, to)
      })

      try {
        await reorderTaskState(fromId, toId)
      } catch (err) {
        setErrorMessage(t('message.general.errorMessage.reorder'))
        openErrorDialog()
      }

      // APIの実行結果にかかわらず再読み込みを行い、Backendに同期させる
      fetchTaskStates()
    },
    [reorderTaskState, t, openErrorDialog, fetchTaskStates]
  )

  const [selectedTaskState, setSelectedTaskState] = useState<
    SelectedTaskState | undefined
  >(undefined)

  // 内の編集アイコン押下
  const handleTaskStateEditButtonClick = useCallback(
    (taskStateId: string, taskStateVersion: number) => {
      // id値はイベントオブジェクトから取得可能
      setSelectedTaskState({
        taskStateId: taskStateId,
        version: taskStateVersion,
      })

      openTaskStateEntryDialog()
    },
    [openTaskStateEntryDialog]
  )

  // 内の削除アイコン押下
  const handleTaskStateDeleteButtonClick = useCallback(
    (taskStateId: string, taskStateVersion: number) => {
      setConfirmDialogMessage(t('message.general.confirmMessage.delete'))
      openConfirmDialog()

      setSelectedTaskState({
        taskStateId: taskStateId,
        version: taskStateVersion,
      })
    },
    [openConfirmDialog, t]
  )

  // 削除確認ダイアログ OKボタン
  const handleDeletePermitButtonClick = useCallback(async () => {
    if (selectedTaskState) {
      try {
        await deleteTaskState(
          selectedTaskState.taskStateId,
          selectedTaskState.version
        )

        success(t('message.taskState.taskStateDeleted'))
      } catch (error) {
        showApiErrors(error)
      }
    }
    closeConfirmDialog()
  }, [
    closeConfirmDialog,
    selectedTaskState,
    deleteTaskState,
    success,
    t,
    showApiErrors,
  ])

  // タスク種別ダイアログClose
  const handleDialogClose = useCallback(() => {
    closeTaskStateEntryDialog()

    // ここで選択されたIDを初期化しないと次回のload時に編集前の値が残るのでresetする
    setSelectedTaskState(undefined)
  }, [closeTaskStateEntryDialog])

  // 削除ボタンの表示判定
  const isVisibleDeleteButton = useCallback((taskStateType: string) => {
    const taskStateTypeExist = Object.values(TaskStateTypes).find(
      (key) => key.value === taskStateType
    )

    // 選択可能判定にて削除ボタン表示/非表示を選定する（enumsに無い値の場合は念のため削除不可とする）
    return taskStateTypeExist ? taskStateTypeExist.chooseable : false
  }, [])

  return (
    <div className="h-full flex flex-col">
      {taskStates && (
        <CrewSortableList
          items={taskStates}
          onReorder={handleReorder}
          header={<TaskStateTableHeader />}
          renderItem={(item) => (
            <CrewSortableList.Item id={item.id}>
              <TaskStateListItem
                key={item.id}
                taskState={item}
                isVisibleDeleteButton={isVisibleDeleteButton(
                  item.taskStateType
                )}
                onEditButtonClick={() =>
                  handleTaskStateEditButtonClick(item.id, item.version)
                }
                onDeleteButtonClick={() =>
                  handleTaskStateDeleteButtonClick(item.id, item.version)
                }
                dragHandle={<CrewSortableList.DragHandle />}
              />
            </CrewSortableList.Item>
          )}
        />
      )}

      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmDialogMessage}
        onPermitButtonClick={handleDeletePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
        permitButtonDisabled={isLoadingDeleteTaskState}
      />

      <CrewErrorDialog
        isOpen={isErrorDialogOpen}
        message={errorMessage}
        onCloseButtonClick={closeErrorDialog}
      />

      <TaskStateEntryDialog
        isEditMode={true}
        title={t('label.taskStateEdit')}
        isOpen={isTaskStateEntryDialogOpen}
        onClose={handleDialogClose}
        taskStateId={selectedTaskState?.taskStateId}
      />
    </div>
  )
})
