import { FileUploader } from 'devextreme-react'
import {
  ComponentProps,
  forwardRef,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  DropZoneEnterEvent,
  DropZoneLeaveEvent,
  UploadedEvent,
  UploadStartedEvent,
} from 'devextreme/ui/file_uploader'
import { CrewFileUploadDropZone } from 'components/devextreme/crewFileUploader/components/crewFileUploadDropZone'
import { UploadFile } from 'models/domain/uploadFile'
import { useTranslation } from '@crew/modules/i18n'
import { uniqueString } from '@crew/utils'
import { useCrewFileUpload } from './hooks/useCrewFileUpload'
import { useValueChangeEffect } from '@crew/hooks'
export type CrewUploadedEvent = UploadedEvent & { key: string }

export type CrewFileUploaderProps = Pick<
  PropsWithChildren<ComponentProps<typeof FileUploader>>,
  'children' | 'multiple' | 'uploadFile' | 'disabled' // これらのプロパティのみ参照元から指定可能に設定
> & {
  isShowDropZoneAlways?: boolean // ドロップ領域を常時表示するか
  uploadedFileList?: UploadFile[] // ドロップ領域に表示させるファイルリスト
  onUploaded?: (file: UploadFile) => void // onUploadedの引数拡張版
  onDropZoneEnter?: (e: any) => void // onDropZoneEnterの移譲用
  onDropZoneLeave?: (e: any) => void // onDropZoneLeaveの移譲用
  onUploadStarted?: (e: any) => void // onUploadStartedの移譲用
  maxFileSize?: number | undefined // Specifies the maximum file size (in bytes) allowed for uploading. Applies only if uploadMode is "instantly" or "useButtons".
  isCancelUpload?: boolean // Specifies whether the file upload process can be canceled.
  onUploadError?: (file: UploadFile) => void // onUploadErrorの移譲用
}

// TODO: ファイルアップロードおよびアップロード済みファイル一覧表示機能がCrewHtmlEditorFieldと共通化できていないので以下で対応予定
// https://break-tmc.atlassian.net/browse/CREW-6737
export const CrewFileUploader = memo(
  forwardRef<FileUploader, CrewFileUploaderProps>((props, ref) => {
    const {
      children,
      onUploaded: propsOnUploaded,
      onDropZoneEnter: propsOnDropZoneEnter,
      onDropZoneLeave: propsOnDropZoneLeave,
      onUploadStarted: propsOnUploadStarted,
      onUploadError: propsOnUploadError,
      maxFileSize,
      ...rest
    } = props

    const { t } = useTranslation()

    // NOTE: IDの値が数字から始まるとselectorのエラーとなるので頭に固定文字列を付ける
    const [dropZoneId] = useState(() => 'dropZone-' + uniqueString())

    // isShowDropZoneAlways=true（ドロップ領域が常時表示）の場合はドロップ領域アクティブ切り替えに用いる
    // isShowDropZoneAlways=false（ファイルドラッグ時にドロップ領域表示）の場合にドロップ領域のアクティブ切り替えフラグとして用いる
    const [isShowDropZone, setIsShowDropZone] = useState(false)

    // ファイルアップロード用カスタムフック
    const { uploadFile, abortControllers } = useCrewFileUpload()

    // ファイルドラッグ時、アップロード実行する
    const handleFileUpload = useCallback(
      (file: File) => {
        // アップロード処理自体はカスタムフック側で行い、後続処理でファイルキーを格納
        return uploadFile(
          file,
          // upload success
          (res) => {
            props.onUploaded?.({
              name: file.name,
              size: file.size,
              keyName: res.key,
              progress: undefined,
            })
          },
          // upload progress
          (progress) => {
            props.onUploaded?.({
              name: file.name,
              size: file.size,
              keyName: '',
              progress,
            })
          },
          // upload error
          (err) => {
            props.onUploadError?.({
              name: file.name,
              size: file.size,
              keyName: '',
            })
          }
        )
      },
      [props, uploadFile]
    )

    // ファイルをドラッグした状態でドロップ領域に入ったとき
    const handleDropZoneEnter = useCallback(
      (event: DropZoneEnterEvent) => {
        // ドロップ領域を表示
        setIsShowDropZone(true)

        props.onDropZoneEnter?.(event)
      },
      [props]
    )

    // ファイルドラッグ状態のマウスカーソルがドロップ領域から離れたとき
    const handleDropZoneLeave = useCallback(
      (event: DropZoneLeaveEvent) => {
        setIsShowDropZone(false)

        props.onDropZoneLeave?.(event)
      },
      [props]
    )

    // アップロード開始時
    const handleUploadStarted = useCallback(
      (event: UploadStartedEvent) => {
        // ドロップ領域を非表示
        setIsShowDropZone(false)

        props.onUploadStarted?.(event)
      },
      [props]
    )

    // Finish dropping files into dropzone
    const handleDropFile = useCallback(() => {
      // ドロップ領域を非表示
      setIsShowDropZone(false)
    }, [])

    // Event handle when cancel upload file
    const handleCancelUpload = useCallback(() => {
      Object.values(abortControllers).forEach((controller) => {
        controller.abort()
      })
    }, [abortControllers])

    // アップロードキャンセルフラグが変更された場合、アップロード処理をキャンセル
    useValueChangeEffect(
      () => {
        if (props.isCancelUpload) {
          handleCancelUpload()
        }
      },
      [abortControllers, handleCancelUpload, props.isCancelUpload],
      props.isCancelUpload
    )

    // アンマウント時にアップロード処理をキャンセル
    useEffect(() => {
      return () => {
        handleCancelUpload()
      }
    }, [handleCancelUpload])

    return (
      <div className="flex-1 max-h-full" onDrop={handleDropFile}>
        {/* ファイルドロップ領域 */}
        <CrewFileUploadDropZone
          children={children}
          dropZoneId={dropZoneId}
          isShowDropZone={isShowDropZone}
          isShowDropZoneAlways={rest.isShowDropZoneAlways}
          uploadedFileList={rest.uploadedFileList}
        />

        {/* ファイルアップローダー */}
        <FileUploader
          dropZone={`#${dropZoneId}`}
          visible={false} //カスタムドロップ領域のみ表示するため、FileUploader本体は表示しない
          ref={ref}
          uploadMode="instantly"
          uploadFile={handleFileUpload}
          // 「ドロップ領域を常時表示」の場合はドロップ領域クリックによるファイル選択ダイアログを表示
          dialogTrigger={
            rest.isShowDropZoneAlways ? `#${dropZoneId}` : undefined
          }
          onDropZoneEnter={handleDropZoneEnter}
          onDropZoneLeave={handleDropZoneLeave}
          onUploadStarted={handleUploadStarted}
          // FIXME: 現時点で不要なプロパティの扱いをどうするか改めて確認
          //        https://break-tmc.atlassian.net/browse/CREW-5026
          // 以下プロパティは、FileUploader非表示のため不要なプロパティだが、表示する場合は必要になるため念の為設定しておく
          selectButtonText={t('label.selectFile')}
          labelText={t('label.dragDropOrClickFile')}
          uploadedMessage={t('label.uploaded')}
          uploadFailedMessage={t('label.uploadFailed')}
          uploadAbortedMessage={t('label.uploadAborted')}
          invalidMinFileSizeMessage={t('message.invalidMinFileSize')}
          invalidMaxFileSizeMessage={t('message.invalidMaxFileSize')}
          invalidFileExtensionMessage={t('message.invalidFileExtension')}
          maxFileSize={maxFileSize}
          // ---------ここまで↑
          {...rest}
        />
      </div>
    )
  })
)
