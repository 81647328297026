import { useGetTasksQuery } from '@crew/apis/task/taskApis'
import { useTranslation } from '@crew/modules/i18n'
import { skipToken } from '@reduxjs/toolkit/query'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  FormValues,
  useTaskDetailDependencyChildTask,
} from './useTaskDetailDependencyChildTask'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { useProjectPermissions } from '@crew/hooks'
import { EntityType } from '@crew/enums/domain'
import { useAppSelector } from 'states/hooks'
import { GetTasksRequest } from '@crew/apis/task/models/getTasks/request'
import { CrewTagBoxField } from 'components/forms/crewTagBoxField'
import AddCircleOutline from '~icons/material-symbols/add-circle-outline'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { useModal } from 'components/layouts/modal/useModal'
import {
  DependencyTask,
  TaskDetailChildParentItem,
} from '../taskDetailChildParentItem/taskDetailChildParentItem'

type TaskDetailDependencyChildTaskProps = {
  taskId: string | undefined
}

export const TaskDetailDependencyChildTask: FC<TaskDetailDependencyChildTaskProps> =
  memo((props) => {
    const {
      control,
      formState,
      validateRules,
      reset,
      setError,
      handleSubmit,
      updateTask,
      childTaskDataSource,
      removeChildTask,
      isLoadingUpdateTaskParent,
    } = useTaskDetailDependencyChildTask(props.taskId)

    const { t } = useTranslation()
    const { taskId } = useParams()
    const taskEventMessage = useAppSelector(
      (state) => state.app.taskEventMessage
    )
    const [showApiErrors] = useShowApiErrorsWithForm(setError)
    const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
      useModal()
    const [showAddChildTask, setShowAddChildTask] = useState(false)

    const getTasksParams: GetTasksRequest | undefined = taskId
      ? {
          assignToUser: undefined,
          createdAt: undefined,
          createdById: undefined,
          dueDate: undefined,
          keyword: undefined,
          keywordFilterCondition: undefined,
          parentTaskId: taskId,
          projectId: undefined,
          projectGroupIds: undefined,
          startDate: undefined,
          taskStateTypes: undefined,
          taskCategoryIds: undefined,
          taskPriorities: undefined,
          taskStateIds: undefined,
          taskKindIds: undefined,
          updatedById: undefined,
          updatedAt: undefined,
        }
      : undefined
    const { data: getTasksResult, refetch: getTasksRefetch } = useGetTasksQuery(
      getTasksParams ?? skipToken
    )

    const canSend = useMemo(
      // fromState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
      // check permission create task
      () =>
        Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
      // formStateはproxyなのでformState自体をlistenする必要がある
      // https://react-hook-form.com/api/useform/formstate
      [formState]
    )

    useEffect(() => {
      getTasksRefetch()
    }, [taskEventMessage, getTasksRefetch])

    // Click the show add child task form button
    const handleShowAddChildTaskFormButtonClick = useCallback(() => {
      setShowAddChildTask(true)
    }, [])

    // キャンセルボタンクリック
    const handleCancelButtonClick = useCallback(() => {
      // reset form
      reset()

      setShowAddChildTask(false)
    }, [reset])

    // Click the add child task button
    const handleAddChildTaskButtonClick = useCallback(async () => {
      const onSubmit = async (data: FormValues) => {
        try {
          if (!taskId) return

          await updateTask(data.childTasks, taskId)

          // reset form
          reset()
          setShowAddChildTask(false)
        } catch (err) {
          showApiErrors(err)
        }
      }
      handleSubmit(onSubmit)()
    }, [handleSubmit, reset, showApiErrors, taskId, updateTask])

    const [task, setTask] = useState<DependencyTask>()

    // Click the delete button
    const handleDeleteButtonClick = useCallback(
      (task: DependencyTask) => {
        setTask(task)
        openConfirmDialog()
      },
      [openConfirmDialog]
    )

    // 削除確認ダイアログ OKボタン
    const handleDeletePermitButtonClick = useCallback(async () => {
      if (task) {
        try {
          await removeChildTask(task)
        } catch (err) {
          showApiErrors(err)
        }
      }

      closeConfirmDialog()
    }, [closeConfirmDialog, removeChildTask, showApiErrors, task])

    const { hasPrjTaskEditPermission } = useProjectPermissions(
      EntityType.Task,
      taskId
    )

    return (
      <>
        <p className="flex items-center">{t('label.childTask')}</p>

        <div className="flex items-center">
          {hasPrjTaskEditPermission && (
            <CrewButton
              stylingMode="text"
              onClick={handleShowAddChildTaskFormButtonClick}
              icon={<AddCircleOutline width={20} height={20} />}
              size="sm"
            />
          )}
        </div>

        <div className="col-span-2 flex flex-col gap-2.5">
          {showAddChildTask && (
            <form>
              <div className="flex flex-row items-center gap-2.5">
                {/* Search */}
                <div className="flex-1">
                  <CrewTagBoxField
                    control={control}
                    id="childTasks"
                    name="childTasks"
                    dataSource={childTaskDataSource}
                    displayExpr="subject"
                    searchEnabled={true}
                    showLabel={false}
                    placeholder=""
                    rules={validateRules.childTasks}
                    maxDisplayedTags={3}
                    popupSearchEnabled={true}
                    showMaskMode="always"
                  />
                </div>

                {/* 追加 */}
                <CrewButton
                  text={t('label.add')}
                  type="primary"
                  disabled={!canSend || isLoadingUpdateTaskParent}
                  onClick={handleAddChildTaskButtonClick}
                />
                {/* キャンセル */}
                <CrewButton
                  text={t('action.cancel')}
                  type="normal"
                  stylingMode="outlined"
                  onClick={handleCancelButtonClick}
                />
              </div>
            </form>
          )}

          {/* child tasks */}
          {getTasksResult?.tasks &&
            getTasksResult.tasks.map((task) => (
              <TaskDetailChildParentItem
                key={task.id}
                task={task}
                onDelete={handleDeleteButtonClick}
              />
            ))}

          {/* 削除確認ダイアログ */}
          <CrewConfirmDialog
            isOpen={isConfirmDialogOpen}
            message={t('message.general.confirmMessage.delete')}
            onPermitButtonClick={handleDeletePermitButtonClick}
            onCancelButtonClick={closeConfirmDialog}
            permitButtonDisabled={isLoadingUpdateTaskParent}
          />
        </div>
      </>
    )
  })
