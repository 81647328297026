import { useTranslation } from '@crew/modules/i18n'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useInsertOrReplaceSettingMutation } from '@crew/apis/setting/settingApis'
import { NotificationSettingValues } from 'enums/app'

import { InsertOrReplaceSettingRequest } from '@crew/apis/setting/models/insertOrReplaceSetting/request'
import { ValidateRules } from '@crew/utils/form'
import { SettingKeyType } from '@crew/enums/app'

export type FormValues = {
  notificationMention: string | null
  notificationReply: string | null
  notificationMessage: string | null
  notificationReaction: string | null
  notificationMeOnlyWhenOffline: boolean
}
export const formInitialValues: FormValues = {
  notificationMention: NotificationSettingValues.BannerAndEmail.value,
  notificationReply: NotificationSettingValues.BannerAndEmail.value,
  notificationMessage: NotificationSettingValues.BannerAndEmail.value,
  notificationReaction: NotificationSettingValues.None.value,
  notificationMeOnlyWhenOffline: false,
}

export const usePersonalSettingNotificationForm = () => {
  const { t } = useTranslation()

  // react-hook-formの各種データを取得
  const { control, handleSubmit, reset, setError } = useForm<FormValues>({
    criteriaMode: 'all',
    defaultValues: formInitialValues,
  })

  // Get update user setting funciton
  const [
    insertOrReplaceSettingMutation,
    { isLoading: isLoadingInsertOrReplaceSetting },
  ] = useInsertOrReplaceSettingMutation()

  // Update user setting process
  const updateUserSettings = useCallback(
    async (data: FormValues) => {
      // user setting params
      const userSettingData: InsertOrReplaceSettingRequest = {
        userSettings: [
          {
            key: SettingKeyType.NotificationMention,
            value: data.notificationMention ?? '',
          },
          {
            key: SettingKeyType.NotificationReply,
            value: data.notificationReply ?? '',
          },
          {
            key: SettingKeyType.NotificationMessage,
            value: data.notificationMessage ?? '',
          },
          {
            key: SettingKeyType.NotificationReaction,
            value: data.notificationReaction ?? '',
          },
          {
            key: SettingKeyType.NotificationMeOnlyWhenOffline,
            value: String(data.notificationMeOnlyWhenOffline),
          },
        ],
      }

      // call api save setting user
      await insertOrReplaceSettingMutation(userSettingData).unwrap()
    },
    [insertOrReplaceSettingMutation]
  )

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      newsNewArrival: {
        required: t('message.general.required'),
      },
      newsMentionAndReply: {
        required: t('message.general.required'),
      },
      notificationMention: {
        required: t('message.general.required'),
      },
      notificationReply: {
        required: t('message.general.required'),
      },
      notificationMessage: {
        required: t('message.general.required'),
      },
      notificationReaction: {
        required: t('message.general.required'),
      },
      notificationMeOnlyWhenOffline: {},
    }),
    [t]
  )

  return {
    control,
    handleSubmit,
    reset,
    setError,
    validateRules,

    updateUserSettings,
    isLoadingInsertOrReplaceSetting,
  }
}
