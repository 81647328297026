import { useEndMeetingMutation } from '@crew/apis/meeting/meetingApis'
import { useCallback } from 'react'

export const useWebMeetingJoinDialog = () => {
  const [endMeetingMutation] = useEndMeetingMutation()

  // end meeting
  const endMeeting = useCallback(
    async (meetingId: string) => {
      // 会議終了API内で開催者の場合に終了、開催者以外の場合は会議から退出の処理を行う
      await endMeetingMutation({
        meetingId,
      })
    },
    [endMeetingMutation]
  )

  return {
    endMeeting,
  }
}
