import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from '@crew/modules/i18n'
import { useAppDispatch } from 'states/hooks'
import {
  useInsertTaskMutation,
  useUpdateTaskMutation,
  useDeleteTaskMutation,
} from '@crew/apis/task/taskApis'

import dayjs from '@crew/modules/dayjs'
import {
  notifyTaskEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { NotifyEventType } from 'enums/app'
import { Task } from '@crew/models/domain'
import { EntityType } from '@crew/enums/domain'
import { JsonDateFormat } from '@crew/enums/system'
import { ValidateRules } from '@crew/utils/form'
import { useParams } from 'react-router-dom'
import { TaskFormProps } from 'features/task/components/taskEntryDialog/components/taskEntryForm/taskEntryForm'
import { UploadFile } from 'models/domain/uploadFile'
import {
  useTaskEntityTypeDataSource,
  useTaskPriorityDataSource,
} from 'hooks/dataSource/useResourceDataSource'
import { useEntityRecordDataSource } from 'hooks/dataSource/useEntityRecordDataSource'
import { useMemberDataSource } from 'hooks/dataSource/useMemberDataSource'
import { useTaskKindDataSource } from 'hooks/dataSource/useTaskKindDataSource'
import { useTaskCategoryDataSource } from 'hooks/dataSource/useTaskCategoryDataSource'
import { useNotificationRecipientDataSource } from 'hooks/dataSource/useNotificationRecipientDataSource'
import {
  convertScheduledTimeToMinutes,
  isRegexFormatScheduledTime,
} from '@crew/utils'

export type FormValues = {
  entityType: string | null
  entityRecordId: string | null
  taskKindId: string | null
  subject: string
  startDate: Date | null
  dueDate: Date | null
  taskPriority: number | null
  taskCategoryId: string | null
  assignToUserId: string | null
  estimatedWorkTimes: string | null
  description: string
  notificationUserIds: string[]
  needNotification: boolean
  isProgressManagementDisabled: boolean
}

export const useTaskEntryForm = (props: TaskFormProps) => {
  const { eventId } = useParams()

  // Get functions for update task infomation
  const [insertTaskMutation, { isLoading: isLoadingInsertTask }] =
    useInsertTaskMutation()
  const [updateTaskMutation, { isLoading: isLoadingUpdateTask }] =
    useUpdateTaskMutation()
  const [deleteTaskMutation, { isLoading: isLoadingDeleteTask }] =
    useDeleteTaskMutation()

  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const [entityType, setEntityType] = useState<EntityType>(EntityType.Project)
  const [entityRecordId, setEntityRecordId] = useState<string | null>(null)

  // react-hook-formの各種データを取得
  const {
    handleSubmit,
    clearErrors,
    formState,
    reset,
    control,
    setError,
    setValue,
    getValues,
  } = useForm<FormValues>({
    criteriaMode: 'all',
  })

  // Insert task process
  const insertTask = useCallback(
    async (
      data: FormValues,
      uploadedFileList: UploadFile[],
      parentTaskId: string | undefined
    ) => {
      const payload = {
        // NOTE: レスポンスに合わせid項目を含める
        taskKindId: data.taskKindId as string,
        subject: data.subject as string,
        startDate: data.startDate
          ? dayjs(data.startDate).format(JsonDateFormat.YYYYMMDD)
          : undefined,
        dueDate: data.dueDate
          ? dayjs(data.dueDate).format(JsonDateFormat.YYYYMMDD)
          : undefined,
        taskPriority: data.taskPriority as number,
        taskCategoryId: data.taskCategoryId ?? undefined,
        assignToUserId: data.assignToUserId ?? undefined,
        // TODO: HtmlEditorを使う場面にsanitizerを仕込む
        // https://break-tmc.atlassian.net/browse/CREW-1505
        description: data.description ?? undefined,
        estimatedWorkTimes: convertScheduledTimeToMinutes(
          data.estimatedWorkTimes
        ),
        notificationUserIds: data.notificationUserIds ?? undefined,
        needNotification: data.needNotification,
        parentTaskId,
        // TODO:「進捗管理から除外する」の表示は、下記タスクで仕様確定するまでは一時的に非表示とする。
        //       https://break-tmc.atlassian.net/browse/CREW-16286
        isProgressManagementDisabled: false,
      }

      // タスク登録APIリクエスト
      const result = await insertTaskMutation({
        task: {
          entityRecordId: data.entityRecordId as string,
          entityType: data.entityType as EntityType,
          ...payload,
          files: uploadedFileList.length > 0 ? uploadedFileList : undefined,
          eventId,
        },
      }).unwrap()

      const objectEventMessage: ObjectEventMessage<Task> = {
        eventType: NotifyEventType.Inserted,
        id: result.task?.id as string,
        object: result.task ?? undefined,
      }

      dispatch(notifyTaskEvent(objectEventMessage))

      return result
    },
    [dispatch, insertTaskMutation, eventId]
  )

  // Update task process
  const updateTask = useCallback(
    async (
      taskId: string,
      version: number,
      data: FormValues,
      parentTaskId: string | undefined
    ) => {
      const payload = {
        // NOTE: レスポンスに合わせid項目を含める
        taskKindId: data.taskKindId as string,
        subject: data.subject as string,
        startDate: data.startDate
          ? dayjs(data.startDate).format(JsonDateFormat.YYYYMMDD)
          : undefined,
        dueDate: data.dueDate
          ? dayjs(data.dueDate).format(JsonDateFormat.YYYYMMDD)
          : undefined,
        taskPriority: data.taskPriority as number,
        taskCategoryId: data.taskCategoryId ?? undefined,
        assignToUserId: data.assignToUserId ?? undefined,
        // TODO: HtmlEditorを使う場面にsanitizerを仕込む
        // https://break-tmc.atlassian.net/browse/CREW-1505
        description: data.description ?? undefined,
        estimatedWorkTimes: convertScheduledTimeToMinutes(
          data.estimatedWorkTimes
        ),
        notificationUserIds: data.notificationUserIds ?? undefined,
        needNotification: data.needNotification,
        parentTaskId,
        // TODO:「進捗管理から除外する」の表示は、下記タスクで仕様確定するまでは一時的に非表示とする。
        //       https://break-tmc.atlassian.net/browse/CREW-16286
        isProgressManagementDisabled: false,
        actualProgress: undefined,
      }

      const result = await updateTaskMutation({
        task: {
          id: taskId,
          ...payload,
          version,
        },
      }).unwrap()

      const objectEventMessage: ObjectEventMessage<Task> = {
        eventType: NotifyEventType.Updated,
        id: result.task?.id as string,
        object: result.task ?? undefined,
      }

      dispatch(notifyTaskEvent(objectEventMessage))

      return result
    },
    [dispatch, updateTaskMutation]
  )

  // Delete task process
  const deleteTask = useCallback(
    async (taskId: string, version: number) => {
      await deleteTaskMutation({
        taskId,
        version,
      }).unwrap()

      const objectEventMessage: ObjectEventMessage<Task> = {
        eventType: NotifyEventType.Deleted,
        id: taskId as string,
        object: undefined,
      }

      dispatch(notifyTaskEvent(objectEventMessage))
    },
    [deleteTaskMutation, dispatch]
  )

  // get users by entity type and entity record id
  const notificationRecipientDataSource = useNotificationRecipientDataSource(
    entityType,
    entityRecordId,
    true
  )

  //custom validate estimed work times string
  const validateEstimatedWorkTimes = useCallback(() => {
    const estimatedWorkTimes = getValues('estimatedWorkTimes')

    return isRegexFormatScheduledTime(estimatedWorkTimes)
  }, [getValues])

  //custom validate start date and due date
  const validateDate = useCallback(() => {
    const startDate = getValues('startDate')
    const dueDate = getValues('dueDate')

    if (
      (startDate && dueDate && startDate <= dueDate) ||
      !startDate ||
      !dueDate
    ) {
      clearErrors('startDate')
      clearErrors('dueDate')
      return true
    }

    return false
  }, [clearErrors, getValues])

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      entityType: { required: t('message.general.required') },
      entityRecordId: {
        required: t('message.general.required'),
      },
      // 種別
      taskKindId: {
        required: t('message.general.required'),
      },
      // 件名
      // TODO: ER図に記載された桁数に変更する
      // https://break-tmc.atlassian.net/browse/CREW-2774
      subject: {
        required: t('message.general.required'),
        maxLength: {
          value: 100,
          message: t('message.general.maxLength', {
            name: t('label.subject'),
            value: 100,
          }),
        },
      },
      // 開始日
      startDate: {
        // FIXME: 日付形式バリデーションについてCrewDatePickerField（dxDataBox）のonFocusOutでは
        //        useContollerのonBlurに入力値が渡せないためパターンチェックが機能しない。
        //        dxValidatorのonValidatedなど駆使すれば何かしら対応が可能かもしれないが調査が必要。
        // https://break-tmc.atlassian.net/browse/CREW-1460
        //
        // pattern: {
        //   value: DateRegExp,
        //   message: t('dateFormat'),
        // },
        validate: {
          always: () =>
            validateDate() || t('message.task.invalidTaskStartDate'),
        },
      },
      // 期日
      dueDate: {
        // TODO: 開始日と同様
        // pattern: {
        //   value: DateRegExp,
        //   message: t('dateFormat'),
        // },
        validate: {
          always: () => validateDate() || t('message.task.invalidTaskDueDate'),
        },
      },
      // 優先度
      taskPriority: {
        required: t('message.general.required'),
      },
      // 予定時間
      estimatedWorkTimes: {
        // min: {
        //   value: 0,
        //   message: t('message.general.minHour', {
        //     name: t('label.scheduledTime'),
        //     value: 0,
        //   }),
        // },
        // TODO: 時間制限をER図に記載された仕様に変更する
        // https://break-tmc.atlassian.net/browse/CREW-2774
        // max: {
        //   value: 1000,
        //   message: t('message.general.maxHour', {
        //     name: t('label.scheduledTime'),
        //     value: 1000,
        //   }),
        // },
        validate: {
          always: () =>
            validateEstimatedWorkTimes() || t('message.task.invalidFormatTime'),
        },
      },
      // 内容
      description: {
        // 「説明」にはHTMLコードが含まれるため、フロントエンドで上限値をチェックせずに、バックエンド側で実行します。
        // 「説明」の上限値は4000文字です。
      },
      // not validate below
      assignToUserId: {},
      taskCategoryId: {},
      notificationUserIds: {},
      needNotification: {},
      // 実績開始日
      isProgressManagementDisabled: {},
    }),
    [t, validateDate, validateEstimatedWorkTimes]
  )

  // タスク優先度のカスタムデータソース
  const taskPriorityDataSource = useTaskPriorityDataSource()

  const targetEntityRecordId = props.projectId ?? entityRecordId ?? undefined

  // タスク種別DataSource構築
  const taskKindDataSource = useTaskKindDataSource(
    EntityType.Project,
    targetEntityRecordId,
    false, // hasBaseFilter
    // プロジェクトIDが存在する場合にのみグループ化を行う（タスク編集の場合又はプロジェクト詳細画面でタスクを作成する場合）
    targetEntityRecordId ? false : true, // isGrouped
    false // multiSelect
  )

  // タスク分類DataSource構築
  const taskCategoryDataSource = useTaskCategoryDataSource(
    EntityType.Project,
    targetEntityRecordId,
    //  プロジェクトIDが存在する場合にのみグループ化を行う（タスク編集の場合又はプロジェクト詳細画面でタスクを作成する場合）
    targetEntityRecordId ? false : true
  )

  // 担当者DataSource構築（インクリメンタルサーチ）
  // get user by entity type and entity record id
  const userDataSource = useMemberDataSource(
    entityType,
    targetEntityRecordId,
    true
  )

  //set data source for entity type
  const entityTypeDataSource = useTaskEntityTypeDataSource()

  //get entity record datasource
  const entityRecordDataSource = useEntityRecordDataSource(entityType)

  return {
    targetEntityRecordId,

    handleSubmit,
    clearErrors,
    formState,
    reset,
    control,
    setError,
    setValue,
    getValues,

    validateRules,

    entityType,
    setEntityType,
    entityRecordId,
    setEntityRecordId,

    notificationRecipientDataSource,
    taskKindDataSource,
    taskPriorityDataSource,
    taskCategoryDataSource,
    userDataSource,
    entityTypeDataSource,
    entityRecordDataSource,

    insertTask,
    updateTask,
    deleteTask,
    isLoadingInsertTask,
    isLoadingUpdateTask,
    isLoadingDeleteTask,
  }
}
