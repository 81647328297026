import {
  memo,
  useState,
  useRef,
  useCallback,
  FC,
  useId,
  useMemo,
  useEffect,
  MouseEventHandler,
} from 'react'
import { Popup, Position } from 'devextreme-react/popup'
import Calendar from 'devextreme-react/calendar'
import ArrayStore from 'devextreme/data/array_store'
import { CrewSelectBox } from './crewSelectBox'
import { NumberBox } from 'devextreme-react'
import { ValueChangedEvent as SelectBoxValueChangedEvent } from 'devextreme/ui/select_box'
import { ValueChangedEvent as CalendarValueChangedEvent } from 'devextreme/ui/calendar'
import { ValueChangedEvent as NumberBoxValueChangedEvent } from 'devextreme/ui/number_box'
import dayjs from '@crew/modules'
import { DateFilterValue, ParamType } from 'utils/filter'
import { DateRange, DateRangeBox } from './crewDateRangeBox'
import { useTranslation } from '@crew/modules/i18n'
import { isPositiveInteger } from '@crew/utils/number'
import { CrewButton } from '../elements/crewButton/crewButton'
import { CrewCheckBox } from './crewCheckBox'
import { ValueChangedEvent } from 'devextreme/ui/check_box'
import classNames from 'classnames'

const OverdueOption = {
  IncludeTaskOverdue: 'includeTaskOverdue',
  DoNotIncludeTaskOverdue: 'doNotIncludeTaskOverdue',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
type OverdueOption = (typeof OverdueOption)[keyof typeof OverdueOption]

type NumberBoxWithOverdueOptionValueType = {
  nextDays: string | number | undefined
  overdueOption: string | undefined
}

type NumberBoxWithOverdueOptionProps = {
  value: NumberBoxWithOverdueOptionValueType
  onValueChange: (value: NumberBoxWithOverdueOptionValueType) => void
  onError: (error: string | undefined) => void
  errorMessage?: string
  showOverdueOption?: boolean
}

const NumberBoxWithOverdueOption: FC<NumberBoxWithOverdueOptionProps> = (
  props
) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(props.value.nextDays)
  const [includeOverdue, setIncludeOverdue] = useState(
    props.value.overdueOption
      ? props.value.overdueOption === OverdueOption.IncludeTaskOverdue
      : true // default is true
  )

  // Callback for handling changes to the number value
  const handleNumberChanged = useCallback(
    (e: NumberBoxValueChangedEvent) => {
      setValue(e.value)

      props.onValueChange({
        nextDays: e.value,
        overdueOption: props.showOverdueOption
          ? includeOverdue
            ? OverdueOption.IncludeTaskOverdue
            : OverdueOption.DoNotIncludeTaskOverdue
          : undefined,
      })

      if (isPositiveInteger(e.value)) {
        props.onError(undefined)
      } else {
        props.onError(t('message.general.invalidPositiveInteger'))
      }
    },
    [includeOverdue, props, t]
  )

  // Callback for handling changes to the include overdue checkbox value
  const handleIncludeOverdueChanged = useCallback(
    (e: ValueChangedEvent) => {
      setIncludeOverdue(e.value)

      props.onValueChange({
        nextDays: value,
        overdueOption: e.value
          ? OverdueOption.IncludeTaskOverdue
          : OverdueOption.DoNotIncludeTaskOverdue,
      })
    },
    [props, value]
  )

  return (
    <>
      <div
        className={classNames(
          'flex items-center gap-2',
          props.showOverdueOption && 'w-72'
        )}
      >
        <NumberBox
          value={value ? Number(value) : undefined}
          onValueChanged={handleNumberChanged}
          valueChangeEvent="input"
          isValid={!props.errorMessage}
        />
        <span>{t('label.days')}</span>
      </div>

      {/* および、期限を超過したタスクを含める */}
      {props.showOverdueOption && (
        <CrewCheckBox
          text={t('label.afterDaysAndIncludeOverdue')}
          onValueChanged={handleIncludeOverdueChanged}
          value={includeOverdue}
        />
      )}
    </>
  )
}

export type ConditionType = {
  id: string
  name: string
  paramType: ParamType
}

const conditionTypes: Array<ConditionType> = [
  { id: 'yesterday', name: 'label.yesterday', paramType: 'nothing' }, // ?dueDate=yesterday
  { id: 'today', name: 'label.today', paramType: 'nothing' }, // ?dueDate=today
  { id: 'tomorrow', name: 'label.tomorrow', paramType: 'nothing' }, // ?dueDate=tomorrow
  { id: 'lastMonth', name: 'label.lastMonth', paramType: 'nothing' }, // ?dueDate=lastMonth
  { id: 'thisMonth', name: 'label.thisMonth', paramType: 'nothing' }, // ?dueDate=thisMonth
  { id: 'nextMonth', name: 'label.nextMonth', paramType: 'nothing' }, // ?dueDate=nextMonth
  { id: 'thatDay', name: 'label.thatDay', paramType: 'date' }, // ?dueDate=2021-10-01
  { id: 'beforeAt', name: 'label.beforeAt', paramType: 'date' }, // ?dueDate=beforeAt:2021-10-01
  { id: 'afterAt', name: 'label.afterAt', paramType: 'date' }, // ?dueDate=afterAt:2021-10-01
  { id: 'pastDays', name: 'label.pastDays', paramType: 'number' }, // ?dueDate=pastDays:3
  { id: 'nextDays', name: 'label.nextDays', paramType: 'number' }, // ?dueDate=nextDays:3_includeOverdue
  { id: 'between', name: 'label.between', paramType: 'dateRange' }, // ?dueDate=between:2021-10-01_2021-10-31
]

export type CrewDateFilterBoxValueChangedEvent = {
  value: DateFilterValue
  previousValue: DateFilterValue
}

export type CrewDateFilterBoxProps = {
  value: DateFilterValue
  onValueChange?: (e: CrewDateFilterBoxValueChangedEvent) => void
  showOverdueOption?: boolean
}

export const CrewDateFilterBox: FC<CrewDateFilterBoxProps> = memo(
  ({ value, onValueChange, showOverdueOption }) => {
    const { t } = useTranslation()
    const labelRef = useRef<HTMLDivElement>(null)

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const [errorMessage, setErrorMessage] = useState<string>()

    // conditionType is a memoized value that finds the condition type based on the conditionTypeId from the value prop.
    const conditionType = useMemo(
      () => conditionTypes.find((f) => f.id === value.conditionTypeId),
      [value.conditionTypeId]
    )

    const [prevValue, setPrevValue] = useState<DateFilterValue>(value)
    const [draftValue, setDraftValue] = useState<DateFilterValue>(value)

    // draftConditionType is a memoized value that finds the condition type based on the conditionTypeId from the draftValue state.
    const draftConditionType = useMemo(
      () => conditionTypes.find((f) => f.id === draftValue.conditionTypeId),
      [draftValue.conditionTypeId]
    )

    // ドラフト値を初期化
    useEffect(() => {
      setPrevValue(value)
      setDraftValue(value)
    }, [value])

    // Function to close the filter box.
    const hideInfo = useCallback(() => {
      setIsOpen(false)
    }, [])

    // conditionTypeDataSource is a memoized value that creates a new ArrayStore with conditionTypes as data.
    const conditionTypeDataSource: ArrayStore = useMemo(
      () =>
        new ArrayStore({
          key: 'id',
          data: conditionTypes,
        }),
      []
    )

    // Function to build the display text based on the condition type and value.
    const buildDisplayText = useCallback(() => {
      if (!conditionType) {
        return t('label.unspecified')
      }

      if (conditionType?.paramType === 'nothing') {
        return t(conditionType.name)
      }

      if (conditionType?.paramType === 'date') {
        return t(conditionType.name) + (value.value ? ':' + value.value : '')
      }

      if (conditionType?.paramType === 'dateRange') {
        if (Array.isArray(value.value)) {
          return (
            t(conditionType.name) +
            ':' +
            value.value[0] +
            ' - ' +
            value.value[1]
          )
        } else {
          return t(conditionType.name)
        }
      }

      if (conditionType?.paramType === 'number') {
        if (Array.isArray(value.value)) {
          return (
            t(conditionType.name) + (value.value ? ':' + value.value[0] : '')
          )
        } else {
          return t(conditionType.name) + (value.value ? ':' + value.value : '')
        }
      }

      return t('label.unknownCondition')
    }, [conditionType, t, value])

    // Function to handle the apply button click. It calls the onValueChange prop function and closes the filter box.
    const handleApplyButtonClick = useCallback(() => {
      onValueChange?.({ value: draftValue, previousValue: prevValue })
      hideInfo()
    }, [draftValue, hideInfo, onValueChange, prevValue])

    // Function to handle the close button click. It closes the filter box.
    const handleCloseButtonClick = useCallback(() => {
      hideInfo()
    }, [hideInfo])

    // Function to handle the change of condition type. It sets the draftValue state with the new condition type.
    const handleConditionTypeChanged = useCallback(
      (e: SelectBoxValueChangedEvent) => {
        const conditionType = conditionTypes.find((f) => f.id === e.value)
        if (!conditionType) {
          throw new Error('unknown conditionType')
        }

        setDraftValue({
          conditionTypeId: conditionType.id,
          value: undefined,
        })

        setErrorMessage(undefined)
      },
      []
    )

    // Function to handle the change of date value. It sets the draftValue state with the new date value.
    const handleDateValueChanged = useCallback(
      (e: CalendarValueChangedEvent) => {
        setDraftValue((prev) => ({
          conditionTypeId: prev.conditionTypeId,
          value: dayjs(e.value).format('YYYY-MM-DD'),
        }))
      },
      []
    )

    // Function to handle the change of date range value. It sets the draftValue state with the new date range value.
    const handleDateRangeValueChanged = useCallback((value: DateRange) => {
      const startDate = dayjs(value.startDate)
      const endDate = dayjs(value.endDate)
      setDraftValue((prev) => ({
        conditionTypeId: prev.conditionTypeId,
        value: [
          startDate.isValid() ? startDate.format('YYYY-MM-DD') : '',
          endDate.isValid() ? endDate.format('YYYY-MM-DD') : '',
        ],
      }))
    }, [])

    // Function to handle the change of number value. It sets the draftValue state with the new number value.
    const handleNumberChanged = useCallback(
      (e: NumberBoxValueChangedEvent) => {
        setDraftValue((prev) => ({
          conditionTypeId: prev.conditionTypeId,
          value: e.value,
        }))

        if (isPositiveInteger(e.value)) {
          setErrorMessage(undefined)
        } else {
          setErrorMessage(t('message.general.invalidPositiveInteger'))
        }
      },
      [t]
    )

    // Function to handle the click event on the label. It sets the isOpen state to true, opening the filter box.
    const handleLabelClick: MouseEventHandler<HTMLDivElement> =
      useCallback(() => {
        setIsOpen(true)
      }, [])

    const id = useId().replace(/:/g, '_')

    // disabled apply button when the value is empty and the condition type is date, date range or number.
    const isApplyButtonDisabled = useMemo(() => {
      return (
        (!draftValue.value &&
          (draftConditionType?.paramType === 'date' ||
            draftConditionType?.paramType === 'dateRange' ||
            draftConditionType?.paramType === 'number')) ||
        (draftConditionType?.paramType === 'number' && !!errorMessage)
      )
    }, [draftConditionType?.paramType, draftValue.value, errorMessage])

    // Function to handle the change of number value with overdue option. It sets the draftValue state with the new number value and overdue option.
    const handleNumberBoxWithOverdueOptionChanged = useCallback(
      (value: NumberBoxWithOverdueOptionValueType) => {
        setDraftValue((prev) => ({
          conditionTypeId: prev.conditionTypeId,
          value: [
            value.nextDays ? String(value.nextDays) : '',
            value.overdueOption ?? '',
          ],
        }))
      },
      []
    )

    return (
      <div className="flex h-9 px-3 items-center rounded border crew-bg-default">
        <div id={id + '-label'} ref={labelRef} onClick={handleLabelClick}>
          {buildDisplayText()}
        </div>

        {isOpen && (
          <Popup
            visible={isOpen}
            onHiding={hideInfo}
            dragEnabled={false}
            showCloseButton={false}
            showTitle={false}
            hideOnOutsideClick={true}
            animation={undefined}
            shading={false}
            width="auto"
            height="auto"
          >
            <Position
              at="left bottom"
              my="left top"
              of={'#' + id + '-label'}
              collision="flipfit"
            />

            <div className="flex flex-col gap-2">
              <CrewSelectBox
                id="conditionType"
                name="conditionType"
                dataSource={conditionTypeDataSource}
                valueExpr="id"
                displayExpr={(item) => t(item.name)}
                minSearchLength={0}
                showClearButton={false}
                value={draftValue?.conditionTypeId}
                onValueChanged={handleConditionTypeChanged}
                searchEnabled={false}
              />

              {draftConditionType?.paramType === 'date' && (
                <Calendar
                  value={draftValue.value as string}
                  onValueChanged={handleDateValueChanged}
                />
              )}

              {draftConditionType?.paramType === 'dateRange' && (
                <DateRangeBox
                  value={{
                    startDate: draftValue.value?.[0],
                    endDate: draftValue.value?.[1],
                  }}
                  onValueChange={handleDateRangeValueChanged}
                />
              )}

              {draftConditionType?.paramType === 'number' && (
                <>
                  {draftConditionType.id === 'nextDays' ? (
                    <NumberBoxWithOverdueOption
                      value={{
                        nextDays:
                          typeof draftValue.value?.[0] === 'string'
                            ? Number(draftValue.value?.[0])
                            : undefined,
                        overdueOption: draftValue.value?.[1],
                      }}
                      errorMessage={errorMessage}
                      onError={setErrorMessage}
                      onValueChange={handleNumberBoxWithOverdueOptionChanged}
                      showOverdueOption={showOverdueOption}
                    />
                  ) : (
                    <div className="flex items-center gap-2">
                      <NumberBox
                        value={
                          typeof draftValue.value === 'string' ||
                          typeof draftValue.value === 'number'
                            ? Number(draftValue.value)
                            : undefined
                        }
                        onValueChanged={handleNumberChanged}
                        valueChangeEvent="input"
                        isValid={!errorMessage}
                      />
                      <span>{t('label.days')}</span>
                    </div>
                  )}
                </>
              )}

              <div className="flex justify-center gap-2">
                <CrewButton
                  stylingMode="outlined"
                  text={t('action.apply')}
                  onClick={handleApplyButtonClick}
                  disabled={isApplyButtonDisabled}
                />

                <CrewButton
                  stylingMode="outlined"
                  text={t('action.close')}
                  onClick={handleCloseButtonClick}
                />
              </div>

              {/* Show error message when number invalid */}
              {errorMessage && (
                <p className="m-0 text-sm crew-error-text">{errorMessage}</p>
              )}
            </div>
          </Popup>
        )}
      </div>
    )
  }
)
