import { useMemo } from 'react'
import {
  useLazyGetLookupEventKindQuery,
  useLazyGetLookupEventKindsQuery,
} from '@crew/apis/lookup/lookupApis'
import { useTranslation } from '@crew/modules/i18n'
import { useDataSource } from './useDataSource'
import { EventKindFilters } from 'enums/app'
import {
  isValidLoadOptionsFilter,
  pickupIdsFromLoadOptionsFilter,
} from 'utils/filterExpr'
import { EntityType } from '@crew/enums/domain'

export const useEventKindDataSource = (
  entityType: EntityType | undefined,
  entityRecordId: string | undefined,
  multiSelect: boolean,
  isGrouped: boolean
) => {
  const { t } = useTranslation()
  const [lazyGetEventKindsQuery] = useLazyGetLookupEventKindsQuery()
  const [lazyGetEventKindQuery] = useLazyGetLookupEventKindQuery()

  // ""全ての種別""をenumにするとpush,unshiftで型エラーになるため定数で作成する
  // Data default condition Types
  const eventTypeFilterBaseData = useMemo(() => {
    return {
      id: String(EventKindFilters.AllEventKinds),
      name: t('label.' + EventKindFilters.AllEventKinds),
    }
  }, [t])

  return useDataSource(
    () => ({
      key: 'id',
      // Using 'raw' load mode to avoid grouping issue
      // https://supportcenter.devexpress.com/ticket/details/t1017195/datasource-grouping-not-rendering-correctly-using-custom-datasource
      loadMode: isGrouped ? 'raw' : undefined,
      load: async (loadOptions) => {
        if (loadOptions.filter && multiSelect) {
          // TODO: 3つ目以降のタグを追加するとfilter付のloadが複数発生する
          // https://break-tmc.atlassian.net/browse/CREW-2207

          // ['id', =, 'some_id'] か [['id', =, 'some_id'], 'or',  ...] 形式のみ許可
          if (!isValidLoadOptionsFilter(loadOptions.filter)) {
            return []
          }

          // Filtering selected event type IDs
          const filteringEventKindIds = pickupIdsFromLoadOptionsFilter(
            loadOptions.filter
          )

          const response = await lazyGetEventKindsQuery({
            entityType: entityType,
            entityRecordId: entityRecordId,
            name: undefined,
            eventKindIds: filteringEventKindIds,
          }).unwrap()

          return response.eventKinds
        } else {
          // インクリメンタルサーチ
          const response = await lazyGetEventKindsQuery({
            entityType: entityType,
            entityRecordId: entityRecordId,
            name: loadOptions.searchValue,
            eventKindIds: undefined,
          }).unwrap()

          return response.eventKinds
        }
      },
      byKey: async (eventKindId: string) => {
        if (!eventKindId) {
          return null
        }
        // 初期ロード時にセットされているのがすべての種別同値であれば""すべての種別""のBaseDataをセットする
        if (eventKindId === EventKindFilters.AllEventKinds) {
          return eventTypeFilterBaseData
        }

        const response = await lazyGetEventKindQuery({
          eventKindId,
        }).unwrap()

        return response.eventKind
      },
      group: isGrouped ? 'entityRecordId' : null,
    }),
    [
      isGrouped,
      multiSelect,
      lazyGetEventKindsQuery,
      entityType,
      entityRecordId,
      lazyGetEventKindQuery,
      eventTypeFilterBaseData,
    ]
  )
}
