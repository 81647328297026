import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import { CrewTagBoxField } from 'components/forms/crewTagBoxField'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useInviteNewUsersToolbar } from './useInviteNewUsersToolbar'
import { CrewDatePickerField } from 'components/forms/crewDatePickerField'
import { DatePickerDateFormat } from 'enums/system'
import { useFocusInput } from 'hooks/useFocusInput'
import { FormValues, UserTagBoxType } from './useInviteNewUsersToolbar'
import { ComponentCallbackHandler, uniqueString } from '@crew/utils'
import { RoleRef } from '@crew/models/refs'
import {
  InvitationRoleType,
  Roles,
  SettingKeyType,
  ContractPlan,
} from '@crew/enums/app'
import {
  RoleSelectType,
  useProjectMemberEntryContext,
} from 'features/project/components/projectMemberEntryDialog/useProjectMemberEntryDialog'
import dayjs from '@crew/modules'
import { useTranslation } from '@crew/modules/i18n'
import { GetTenantSettingsRequest } from '@crew/apis/setting/models/getTenantSettings/request'
import { useGetTenantSettingsQuery } from '@crew/apis/setting/settingApis'
import HelpOutline from '~icons/material-symbols/help-outline'
import { CrewPopover } from 'components/devextreme/crewPopover'
import { CrewSelectBox } from 'components/devextreme/crewSelectBox'
import { useRoleDataSource } from 'hooks/dataSource/useRoleDataSource'
import { RoleType } from '@crew/enums/domain'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { useModal } from 'components/layouts/modal/useModal'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { useAppSelector } from 'states/hooks'

// Horizontal initialization value of popover
const MAX_WITH_POPOVER = 300

type HelpIconProps = {
  target: string
  content: string
}

const HelpIcon: FC<HelpIconProps> = memo((props) => {
  // ユニークキー生成
  const popoverKey = useMemo(() => uniqueString(), [])
  return (
    <>
      <span id={`${props.target}-${popoverKey}`}>
        <HelpOutline width={20} height={20} />
      </span>
      <CrewPopover
        target={`#${props.target}-${popoverKey}`}
        showEvent="mouseenter"
        hideEvent="mouseleave"
        position="top"
        maxWidth={MAX_WITH_POPOVER}
      >
        <span className="text-sm">{props.content}</span>
      </CrewPopover>
    </>
  )
})

//return type for reduce function
type ReduceReturnType = {
  [key in SettingKeyType]?: string | null
}
export const InviteNewUsersToolbar = memo(() => {
  const {
    control,
    handleSubmit,
    reset,
    formState,
    setValue,
    getValues,
    clearErrors,
    setError,

    tenantRoleDataSource,
    newUserDataSource,

    validateRules,
    isDisableDates,

    addUsers,
    verifyProjectMembersPending,
    isLoadingVerifyProjectMembersPending,
  } = useInviteNewUsersToolbar()

  const { t } = useTranslation()

  const currentPlan = useAppSelector((state) => state.app.currentPlan)

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  const { setSelectedNewUsers, selectedNewUsers } =
    useProjectMemberEntryContext()

  const [selectedTenantRoleType, setSelectedTenantRoleType] = useState<string>(
    InvitationRoleType.Internal.key
  )

  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  // list role code need exclude in role dropdown
  const excludeRoles = useMemo(() => {
    if (selectedTenantRoleType === InvitationRoleType.External.key) {
      // If you are an external user, you can only have the `prj_guest` role in the project.
      // So ignore the `prj_admin` and `prj_member` here.
      return [Roles.PrjAdmin, Roles.PrjMember]
    }

    return []
  }, [selectedTenantRoleType])

  //set data source for project role dropdown
  const projectRoleDataSource = useRoleDataSource(
    RoleType.Project,
    excludeRoles,
    // If you are an external user, you can only have the `prj_guest` role in the project.
    // So if you are an external user, don't get user-defined roles here.
    !(selectedTenantRoleType === InvitationRoleType.External.key)
  )

  const defaultProjectRole = useMemo(
    () =>
      selectedTenantRoleType === InvitationRoleType.Internal.key
        ? Roles.PrjMember.value
        : Roles.PrjGuest.value,
    [selectedTenantRoleType]
  )

  // 組織設定の「外部ユーザーの有効期限(初期値)」を取得
  const getTenantSettingsRequestParams: GetTenantSettingsRequest = {
    keys: [SettingKeyType.OrganizationExternalUserExpirationDate],
  }
  const { data: getTenantSettings } = useGetTenantSettingsQuery(
    getTenantSettingsRequestParams
  )

  // 有効期限の初期値
  const defaultExpiredDate = useMemo(() => {
    //convert tenant settings to object
    const tenantSettings =
      getTenantSettings?.tenantSettings.reduce<ReduceReturnType>(
        (tenantSetting, currentValue) => ({
          ...tenantSetting,
          [currentValue.key]: currentValue.value,
        }),
        {}
      )

    const expirationDate =
      tenantSettings?.[SettingKeyType.OrganizationExternalUserExpirationDate]

    // 有効期限には「当日」+「組織設定の外部ユーザーの有効期限(初期値)」を初期表示する
    return dayjs(Date.now())
      .add(expirationDate ? Number(expirationDate) : 0, 'day')
      .toDate()
  }, [getTenantSettings?.tenantSettings])

  // フォーム初期化処理関数
  const initializeForm = useCallback(async () => {
    //Need get roleItem from roleDataSource by name ('メンバー')
    const roleItems = await projectRoleDataSource.store().load()
    if (Array.isArray(roleItems)) {
      const roleItem = roleItems.find(
        (role) => role.roleCode === defaultProjectRole
      ) as RoleRef

      setValue('projectRoleId', roleItem.id)
    } else {
      console.error('Unexpected type of roleItems', roleItems)
    }
  }, [defaultProjectRole, projectRoleDataSource, setValue])

  useEffect(() => {
    // フォーム初期化を実行
    initializeForm()
  }, [initializeForm])

  // すでに存在するタグを入力しEnterを押した場合もこのイベントが発生する
  const handleAddCustomUserValue = useCallback<
    ComponentCallbackHandler<typeof CrewTagBoxField, 'onCustomItemCreating'>
  >(
    (args) => {
      if (!args.text) {
        return
      }

      let newUser: UserTagBoxType = {
        value: args.text,
      }

      // カスタム値の登録（{ id: tagId | undefined, name: 入力値 }）
      // カスタムデータソースのinsert内でAPIによるtagIdの取得を試みる
      args.customItem = args.component
        .getDataSource()
        .store()
        .insert(newUser)
        .then((savedUser: UserTagBoxType) => {
          newUser = savedUser //レスポンスで差し替え
          return newUserDataSource.load()
        })
        .then(() => newUser)
    },
    [newUserDataSource]
  )

  // get role info from role data source
  const getRole = useCallback(
    async (roleId: string) => {
      //get role info from role data source
      const roleItems = await projectRoleDataSource.load()
      const role = roleItems.find(
        (role: RoleSelectType) => role.id === roleId
      ) as RoleSelectType

      return role
    },
    [projectRoleDataSource]
  )

  //action when add button is clicked
  const handleAddButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        // Verify member list
        const result = await verifyProjectMembersPending(
          data.tenantRoleType,
          data.newUsers
        )

        if (result.hasExternalUser) {
          openConfirmDialog()
          return
        }

        if (!data.projectRoleId) return

        //get role info from role data source
        const role = await getRole(data.projectRoleId)

        const users = await addUsers(data, role, selectedNewUsers)

        setSelectedNewUsers(users)

        reset()
      } catch (err) {
        showApiErrors(err)
      }
    }
    handleSubmit(onSubmit)()
  }, [
    handleSubmit,
    verifyProjectMembersPending,
    getRole,
    addUsers,
    selectedNewUsers,
    setSelectedNewUsers,
    reset,
    openConfirmDialog,
    showApiErrors,
  ])

  // Event handle when confirm dialog OK button is clicked
  const handlePermitButtonClick = useCallback(async () => {
    // get form data
    const data = getValues()

    if (data.projectRoleId) {
      //get role info from role data source
      const role = await getRole(data.projectRoleId)

      const users = await addUsers(data, role, selectedNewUsers)

      setSelectedNewUsers(users)

      reset()
    }
    closeConfirmDialog()
  }, [
    addUsers,
    closeConfirmDialog,
    getRole,
    getValues,
    reset,
    selectedNewUsers,
    setSelectedNewUsers,
  ])

  const canSend = useMemo(
    () =>
      Object.keys(formState.errors).length === 0 &&
      !formState.isSubmitting &&
      !isLoadingVerifyProjectMembersPending,
    [formState, isLoadingVerifyProjectMembersPending]
  )

  useFocusInput('newUsers')

  // Event handle when the tenant role type is changed
  const handleTenantRoleTypeChange = useCallback<
    ComponentCallbackHandler<typeof CrewSelectBox, 'onValueChanged'>
  >(
    (event) => {
      setSelectedTenantRoleType(event.value)
      if (event.value === InvitationRoleType.External.key) {
        setValue(
          'expirationDatetime',
          getValues('expirationDatetime') ?? defaultExpiredDate
        )
      }
      clearErrors()
    },
    [clearErrors, defaultExpiredDate, getValues, setValue]
  )

  return (
    <div>
      <p className="text-md my-3">{t('label.inviteNewUsersDescription')}</p>
      <form className="flex flex-col gap-y-2.5">
        {/* tenant role */}
        <div className="flex flex-col gap-y-1">
          <div className="flex flex-row items-center">
            <p>{t('label.organizationRoleLabel')}</p>

            {currentPlan !== ContractPlan.Free && (
              <HelpIcon
                target="tenant-role"
                content={t('label.organizationRoleHelpText')}
              />
            )}
          </div>
          <CrewSelectBoxField
            id="tenantRoleType"
            control={control}
            name="tenantRoleType"
            dataSource={tenantRoleDataSource}
            valueExpr="id"
            displayExpr="name"
            searchEnabled={false}
            searchExpr="name"
            minSearchLength={0}
            required={true}
            rules={validateRules.tenantRoleType}
            className="w-1/4"
            showClearButton={false}
            onValueChanged={handleTenantRoleTypeChange}
          />
        </div>

        {/* project role */}
        <div className="flex flex-col gap-y-1">
          <div className="flex flex-row items-center">
            <p>{t('label.projectRoleLabel')}</p>

            {currentPlan !== ContractPlan.Free && (
              <HelpIcon
                target="project-role"
                content={t('label.projectRoleHelpText')}
              />
            )}
          </div>
          <CrewSelectBoxField
            id="projectRoleId"
            control={control}
            name="projectRoleId"
            dataSource={projectRoleDataSource}
            valueExpr="id"
            displayExpr="name"
            searchEnabled={false}
            searchExpr="name"
            minSearchLength={0}
            required={true}
            rules={validateRules.projectRoleId}
            className="w-1/4"
            showClearButton={false}
          />
        </div>

        {/* expiration date */}
        <div className="flex flex-col gap-y-1">
          <div className="flex flex-row items-center">
            <p>{t('label.expirationDateLabel')}</p>
            <HelpIcon
              target="expiration-datetime"
              content={t('label.expirationDateHelpText')}
            />
          </div>
          <CrewDatePickerField
            id="expirationDatetime"
            name="expirationDatetime"
            control={control}
            labelMode="hidden"
            pickerType="calendar"
            showLabel={false}
            displayFormat={DatePickerDateFormat.YYYYMMDD}
            rules={validateRules.expirationDatetime}
            disabledDates={isDisableDates}
            className="w-1/4"
            // Expired date is required when the tenant role type is external
            showClearButton={
              selectedTenantRoleType !== InvitationRoleType.External.key
            }
            // If tenant role type is external we cant using blackspace to clear date
            acceptCustomValue={
              selectedTenantRoleType !== InvitationRoleType.External.key
            }
          />
        </div>

        <div className="flex flex-col gap-y-1">
          <p>{t('label.newUsersLabel')}</p>
          <div className="flex flex-row items-start gap-x-2.5">
            <div className="grow">
              {/* new users */}
              <CrewTagBoxField
                id="newUsers"
                name="newUsers"
                displayExpr="value"
                valueExpr="value"
                dataSource={newUserDataSource}
                control={control}
                showLabel={false}
                searchEnabled={false}
                rules={validateRules.newUsers}
                acceptCustomValue={true}
                onCustomItemCreating={handleAddCustomUserValue}
                openOnFieldClick={false}
                deferRendering={false}
                popupSearchEnabled={false}
                className="grow"
              />
            </div>

            {/* add button */}
            <CrewButton
              type="primary"
              text={t('label.add')}
              onClick={handleAddButtonClick}
              disabled={!canSend}
            />
          </div>
        </div>
      </form>

      {/* 削除確認ダイアログ */}
      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={t('message.tenant.inviteUserConfirmMessage')}
        onPermitButtonClick={handlePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
      />
    </div>
  )
})
