import { Member } from '@crew/apis/lookup/models/getMember/response'
import { getImageData, tag as emojiTag } from '@crew/emoji-data'
import { useTranslation } from '@crew/modules/i18n'
import { uniqueString } from '@crew/utils'
import classNames from 'classnames'
import { CrewPopover } from 'components/devextreme/crewPopover'
import { useCrewReactionBadge } from 'components/elements/crewReactionBadge/useCrewReactionBadge'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { FC, MouseEventHandler, memo, useCallback, useMemo } from 'react'

export type CrewReactionBadgeProps = {
  shortName: string
  reactionCount: number
  reacted: boolean
  chatMessageId: string
  members: Member[]
  showOnly: boolean
}
// Horizontal initialization value of popover
const MAX_WITH_POPOVER = 300
// limit user react initialization value of popover
const LIMIT_USER_SHOW_POPOVER = 5

export const CrewReactionBadge: FC<CrewReactionBadgeProps> = memo((props) => {
  const { deleteReaction, insertReaction } = useCrewReactionBadge()

  const [showApiErrors] = useShowApiErrors()

  const validEmoji = props.shortName && Boolean(getImageData(props.shortName))

  const emojiClassName = validEmoji ? emojiTag.className : undefined

  const { t } = useTranslation()

  //未定義絵文字だった場合、代替文字を設定する
  const altText = validEmoji ? '' : '〓'

  // 絵文字のshort_nameをcustom data attributeとして埋め込む
  const customData = {
    [`data-${emojiTag.shortNameCustomDataAttributeName}`]: props.shortName,
  }

  // ユニークキー生成
  const popoverKey = useMemo(() => uniqueString(), [])

  // render message the number of people who reacted
  const messageReacted = useMemo(() => {
    let currentMessage = ''

    if (props.members.length <= LIMIT_USER_SHOW_POPOVER) {
      // If the number of members is less than the popover display limit
      currentMessage = props.members
        .map((member) => member.displayName)
        .join(', ')
    } else {
      // Otherwise
      currentMessage = t('label.reactOtherMembersMessage', {
        otherMembers: props.members.length - LIMIT_USER_SHOW_POPOVER,
      })
      currentMessage =
        props.members
          .slice(0, LIMIT_USER_SHOW_POPOVER)
          .map((member) => member.displayName)
          .join(', ') + currentMessage
    }
    return currentMessage
  }, [props.members, t])

  // リアクションバッジ押下
  const handleReactionClick: MouseEventHandler<HTMLDivElement> = useCallback(
    async (event) => {
      // 表示のみの場合は、処理しない
      if (props.showOnly) return

      // クリックイベントはここで処理が完了するので、親要素に伝播させない
      event.stopPropagation()

      if (props.reacted) {
        // リアクション済は解除
        try {
          await deleteReaction(props.chatMessageId, props.shortName)
        } catch (err) {
          showApiErrors(err)
        }
      } else {
        // 未リアクションは付与
        try {
          await insertReaction(props.chatMessageId, props.shortName)
        } catch (err) {
          showApiErrors(err)
        }
      }
    },
    [
      props.showOnly,
      props.reacted,
      props.chatMessageId,
      props.shortName,
      deleteReaction,
      showApiErrors,
      insertReaction,
    ]
  )

  return (
    <div
      id={`reacted-${popoverKey}`}
      className={classNames(
        'flex flex-row items-center crew-reaction',
        // ログインユーザーがリアクションしている場合に背景色を変える
        { 'bg-crew-gray-200 dark:bg-crew-gray-700': props.reacted },
        // 表示のみの場合はカーソルをポインターにしない
        { 'cursor-pointer': !props.showOnly }
      )}
      onClick={handleReactionClick}
    >
      <span className={emojiClassName} {...customData}>
        {altText}
      </span>
      <span className="text-sm ml-1">{props.reactionCount}</span>
      <CrewPopover
        target={`#reacted-${popoverKey}`}
        showEvent="mouseenter"
        hideEvent="mouseleave"
        position="top"
        maxWidth={MAX_WITH_POPOVER}
      >
        <span className="text-sm">{messageReacted}</span>
      </CrewPopover>
    </div>
  )
})
