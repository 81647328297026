import { useCallback } from 'react'
import { useUpdateBillingCycleMutation } from '@crew/apis/contract/contractApis'
import { BillingCycle } from '@crew/enums/app'

export const useContractChangeCycleBillingPage = () => {
  // Get update billing cycle function
  const [
    updateBillingCycleMutation,
    { isLoading: isLoadingUpdateBillingCycle },
  ] = useUpdateBillingCycleMutation()

  // Update billing cycle process
  const updateBillingCycle = useCallback(
    async (billingCycle: BillingCycle) => {
      await updateBillingCycleMutation({
        billingCycle: billingCycle,
      }).unwrap()
    },
    [updateBillingCycleMutation]
  )

  return {
    updateBillingCycle,
    isLoadingUpdateBillingCycle,
  }
}
