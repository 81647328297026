import { useCallback } from 'react'
import { useDeleteEventAttendeesMutation } from '@crew/apis/project/projectApis'
import { NotifyEventType } from 'enums/app'
import {
  ObjectEventMessage,
  notifyEventAttendeeEvent,
} from 'features/app/states/appSlice'
import { EventAttendee } from '@crew/models/domain'
import { useAppDispatch } from 'states/hooks'

export const useEventDetailAttendeeListToolbar = () => {
  const dispatch = useAppDispatch()

  // Get function for delete event attendees
  const [
    deleteEventAttendeesMutation,
    { isLoading: isLoadingDeleteEventAttendees },
  ] = useDeleteEventAttendeesMutation()

  // Delete event attendees
  const deleteEventAttendees = useCallback(
    async (eventId: string, userIds: string[], needNotification: boolean) => {
      await deleteEventAttendeesMutation({
        eventId,
        userIds: userIds,
        needNotification,
      }).unwrap()

      //trigger update event attendee list
      const objectEventMessage: ObjectEventMessage<EventAttendee> = {
        eventType: NotifyEventType.Deleted,
        id: eventId,
        object: undefined,
      }
      dispatch(notifyEventAttendeeEvent(objectEventMessage))
    },
    [deleteEventAttendeesMutation, dispatch]
  )

  return {
    deleteEventAttendees,
    isLoadingDeleteEventAttendees,
  }
}
