import { apis } from '../apiBase/apiBase';
export const presenceStateApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        getPresenceStates: builder.query({
            query: () => ({
                url: `presence-states`,
                method: 'GET',
            }),
        }),
        insertPresenceState: builder.mutation({
            query: (params) => ({
                url: 'presence-states/insert',
                method: 'POST',
                body: params,
            }),
        }),
        deletePresenceState: builder.mutation({
            query: (params) => ({
                url: 'presence-states/delete',
                method: 'POST',
                body: params,
            }),
        }),
        updatePresenceState: builder.mutation({
            query: (params) => ({
                url: 'presence-states/update',
                method: 'POST',
                body: params,
            }),
        }),
        reorderPresenceState: builder.mutation({
            query: (params) => ({
                url: 'presence-states/reorder',
                method: 'POST',
                body: params,
            }),
        }),
    }),
    overrideExisting: false,
});
export const { useGetPresenceStatesQuery, useLazyGetPresenceStatesQuery, useInsertPresenceStateMutation, useDeletePresenceStateMutation, useUpdatePresenceStateMutation, useReorderPresenceStateMutation, } = presenceStateApis;
