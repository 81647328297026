import { FC, memo, useMemo } from 'react'
import classNames from 'classnames'

type Props = {
  displayColor: string
  className?: string
  children?: React.ReactNode
}

export const CrewCountBadge: FC<Props> = memo((props) => {
  const badgeBaseClassNames = useMemo(() => {
    // 最低幅として、1文字分確保できるようにする
    return 'crew-badge text-sm truncate text-center min-w-[1.5rem] h-5 leading-3 !font-normal has-unread'
  }, [])

  return (
    <span
      className={classNames(
        badgeBaseClassNames,
        props.className,
        props.displayColor
      )}
    >
      {props.children}
    </span>
  )
})
