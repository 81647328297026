import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useInsertFileTagsMutation } from '@crew/apis/file/fileApis'
import { FileListTagsEntryFormProps } from './fileListTagsEntryForm'
import { useProjectTagDataSource } from 'hooks/dataSource/useProjectTagDataSource'
import {
  ObjectEventMessage,
  notifyFileEvent,
} from 'features/app/states/appSlice'
import { NotifyEventType } from 'enums/app'
import { useAppDispatch } from 'states/hooks'
import { EntityType, ProjectType } from '@crew/enums/domain'

export type FormValues = {
  tagIds: string[] | null
}

export type Tag = {
  id: number
  name: string
}

export const useFileListTagsEntryForm = (props: FileListTagsEntryFormProps) => {
  const dispatch = useAppDispatch()

  // Get function for insert file tags
  const [insertFileTagsMutation, { isLoading: isLoadingInsertFileTag }] =
    useInsertFileTagsMutation()

  // react-hook-formの各種データを取得
  const { formState, control, handleSubmit, setError } = useForm<FormValues>({
    criteriaMode: 'all',
  })

  // Get data source for the dropdown to display tags list
  const { insertProjectTagDataSource } = useProjectTagDataSource(
    EntityType.File,
    ProjectType.Project,
    props.projectIds
  )
  const tagDataSource = insertProjectTagDataSource

  // Insert file tags process
  const insertFileTags = useCallback(
    async (fileIds: string[], tagIds: string[]) => {
      await insertFileTagsMutation({
        fileIds,
        tagIds,
      })

      // mutation操作の後は、レスポンスを通知する
      const objectEventMessage: ObjectEventMessage<File> = {
        eventType: NotifyEventType.Updated,
        id: props.fileIds[0],
        object: undefined,
      }
      dispatch(notifyFileEvent(objectEventMessage))
    },
    [dispatch, insertFileTagsMutation, props.fileIds]
  )

  return {
    formState,
    control,
    handleSubmit,
    setError,

    tagDataSource,
    insertFileTags,
    isLoadingInsertFileTag,
  }
}
