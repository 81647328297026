import {
  useJoinProjectMutation,
  useLeaveProjectMutation,
} from '@crew/apis/project/projectApis'
import { useCallback, useState } from 'react'
import { useAppDispatch } from 'states/hooks'
import { ProjectMember, Project } from '@crew/models/domain'
import { NotifyEventType } from 'enums/app'
import {
  ObjectEventMessage,
  notifyProjectSettingMemberEvent,
  notifyProjectEvent,
} from 'features/app/states/appSlice'

export const useProjectDetailHeadPanel = () => {
  const dispatch = useAppDispatch()

  const [isApprovalPending, setIsApprovalPending] = useState<boolean>(false)

  // Get function for join project
  const [joinProjectMutation] = useJoinProjectMutation()
  const [leaveProjectMutation, { isLoading: isLoadingLeaveProject }] =
    useLeaveProjectMutation()

  // Join project process
  const joinProject = useCallback(
    async (projectId: string, approvalToJoin: boolean) => {
      // ボタン押下時、プロジェクト参加APIを実行する
      await joinProjectMutation({
        projectId,
      }).unwrap()

      if (approvalToJoin) {
        // 参加には承認が必要なプロジェクトの場合（「要承認」がtrue）
        // 「プロジェクトに参加」ボタンを「承認待ち」ボタンにラベル変更し、非活性(disabled)にする
        setIsApprovalPending(true)
      } else {
        // 参加には承認がいらないプロジェクトの場合（「要承認」がfalse）
        // 非表示になっていた領域を表示するため、プロジェクト詳細取得APIを実行する
        const objectEventMessageProject: ObjectEventMessage<Project> = {
          eventType: NotifyEventType.Updated,
          id: projectId,
          object: {
            id: projectId,
          },
        }
        dispatch(notifyProjectEvent(objectEventMessageProject))
      }
    },
    [dispatch, joinProjectMutation]
  )

  // Leave project process
  const leaveProject = useCallback(
    async (projectId: string, userId: string) => {
      // Call api to leave project
      await leaveProjectMutation({
        projectId: projectId,
      }).unwrap()

      //trigger event refresh project setting members list
      const objectEventMessageProjectMember: ObjectEventMessage<ProjectMember> =
        {
          eventType: NotifyEventType.Deleted,
          id: userId,
          object: {
            projectId,
            userId,
          },
        }
      dispatch(notifyProjectSettingMemberEvent(objectEventMessageProjectMember))

      //trigger project detail refresh members list
      const objectEventMessageProject: ObjectEventMessage<Project> = {
        eventType: NotifyEventType.Updated,
        id: projectId,
        object: {
          id: projectId,
        },
      }
      dispatch(notifyProjectEvent(objectEventMessageProject))
    },
    [leaveProjectMutation, dispatch]
  )

  return {
    joinProject,
    leaveProject,

    isApprovalPending,
    setIsApprovalPending,
    isLoadingLeaveProject,
  }
}
