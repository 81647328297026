import { memo, useCallback, useState } from 'react'
import { FileListActionMenu } from 'features/file/components/fileListPage/components/fileListActionMenu/fileListActionMenu'
import { CrewLink } from '../crewLink/crewLink'
import { getDefaultTabValue } from '@crew/utils/enum'
import { FileDetailListTabs } from 'enums/app'
import { EntityType } from '@crew/enums/domain'
import { useProjectPermissions } from '@crew/hooks'
import { CrewFileIcon } from '../crewFileIcon/crewFileIcon'

export type CrewFileNameProps = {
  fileId: string
  fileName: string
  entityType: EntityType
  entityRecordId?: string
  showDownload?: boolean
  className?: string
}

export const CrewFileName = memo((props: CrewFileNameProps) => {
  const { hasPrjFileDownloadPermission } = useProjectPermissions(
    props.entityType,
    props.entityRecordId ?? props.fileId
  )

  const [visibleActionMenu, setVisibleActionMenu] = useState(false)

  //ホバーメニューを表示
  const handleMouseEnter = useCallback(() => {
    setVisibleActionMenu(true)
  }, [])

  //ホバーメニューを非表示
  const handleMouseLeave = useCallback(() => {
    setVisibleActionMenu(false)
  }, [])

  return (
    <div
      className="flex items-center gap-x-3 group"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="flex flex-row items-center gap-1">
        {/* file icon */}
        <div className="shrink-0">
          <CrewFileIcon fileName={props.fileName} />
        </div>

        {/* file name */}
        <CrewLink
          to={`/files/${props.fileId}/${getDefaultTabValue(
            FileDetailListTabs
          )}`}
          title={props.fileName}
          className={props.className ?? ''}
          draggable={false}
        >
          {props.fileName}
        </CrewLink>
      </div>

      {visibleActionMenu && (
        <FileListActionMenu
          fileId={props.fileId}
          fileName={props.fileName}
          showDownload={hasPrjFileDownloadPermission}
        />
      )}
    </div>
  )
})
