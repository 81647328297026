import { useCallback } from 'react'
import {
  useInsertFavoriteMutation,
  useDeleteFavoriteMutation,
} from '@crew/apis/favorite/favoriteApis'
import {
  ObjectEventMessage,
  notifyFavoriteEvent,
} from 'features/app/states/appSlice'
import { useAppDispatch } from 'states/hooks'
import { Favorite } from '@crew/models/domain'
import { NotifyEventType } from 'enums/app'
import { EntityType } from '@crew/enums/domain'

export const useCrewFavorite = () => {
  const [insertFavoriteMutation] = useInsertFavoriteMutation()
  const [deleteFavoriteMutation] = useDeleteFavoriteMutation()

  const dispatch = useAppDispatch()

  // subscribe to favorite
  const subscribeFavorite = useCallback(
    async (entityType: EntityType, entityRecordId: string) => {
      const response = await insertFavoriteMutation({
        entityType,
        entityRecordId,
      }).unwrap()

      if (!response.favorite) return

      const objectEventMessage: ObjectEventMessage<Favorite> = {
        eventType: NotifyEventType.Updated,
        id: response.favorite.id,
        object: undefined,
      }

      dispatch(notifyFavoriteEvent(objectEventMessage))

      return response.favorite
    },
    [dispatch, insertFavoriteMutation]
  )

  // unsubscribe from favorite
  const unsubscribeFavorite = useCallback(
    async (entityType: EntityType, entityRecordId: string) => {
      await deleteFavoriteMutation({
        entityType,
        entityRecordId,
      }).unwrap()

      const objectEventMessage: ObjectEventMessage<Favorite> = {
        eventType: NotifyEventType.Deleted,
        id: entityRecordId,
        object: undefined,
      }

      dispatch(notifyFavoriteEvent(objectEventMessage))
    },
    [deleteFavoriteMutation, dispatch]
  )

  return {
    subscribeFavorite,
    unsubscribeFavorite,
  }
}
