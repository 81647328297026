import { useRequestAccountRegistrationMutation } from '@crew/apis/app/appApis'
import { BillingCycle, ContractPlan } from '@crew/enums/app'
import { useTranslation } from '@crew/modules/i18n'
import { isValidEmailAddress } from '@crew/utils'
import { ValidateRules } from '@crew/utils/form'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'

export type FormValues = {
  email: string
  agree: boolean
}
const formInitialValues: FormValues = {
  email: '',
  agree: false,
}
export const useAppAccountRegistration = () => {
  const { t } = useTranslation()

  // react-hook-formの各種データを取得
  const { control, handleSubmit, formState, getValues, watch } =
    useForm<FormValues>({
      defaultValues: formInitialValues,
      criteriaMode: 'all',
    })

  // Get account registration function
  const [
    requestAccountRegistrationMutation,
    { isLoading: isRequestAccountRegistrationLoading },
  ] = useRequestAccountRegistrationMutation()

  // Register account process
  const registerAccount = useCallback(
    async (
      data: FormValues,
      plan: ContractPlan,
      billingCycle: BillingCycle
    ) => {
      await requestAccountRegistrationMutation({
        mailAddress: data.email,
        plan: plan,
        billingCycle: billingCycle,
      }).unwrap()
    },
    [requestAccountRegistrationMutation]
  )

  // Check valid email
  const checkValidEmailAddress = useCallback(() => {
    return isValidEmailAddress(getValues('email'))
  }, [getValues])

  // 以下、入力コンポーネントのルールとレンダリングを定義
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      email: {
        required: t('message.general.isRequired', {
          name: t('label.mailAddress'),
        }),
        validate: {
          checkValidEmailAddress: () =>
            checkValidEmailAddress() || t('message.general.invalidEmail'),
        },
      },
      agree: {},
    }),
    [checkValidEmailAddress, t]
  )

  return {
    control,
    handleSubmit,
    formState,
    validateRules,
    registerAccount,
    watch,
    isRequestAccountRegistrationLoading,
  }
}
