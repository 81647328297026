import { EntityType } from '@crew/enums/domain'
import { useProjectPermissions } from '@crew/hooks'
import { FileHistory } from '@crew/models/domain'
import { isImageFile } from '@crew/utils/chat'
import classNames from 'classnames'
import { CrewAttachments } from 'components/elements/crewAttachments/crewAttachments'
import { CrewAttachmentThumbnails } from 'components/elements/crewAttachmentThumbnails/crewAttachmentThumbnails'
import { CrewHtmlContent } from 'components/elements/crewHtmlContent/crewHtmlContent'
import { HTMLEDITOR_VIEW_STYLE } from 'configs/constants'
import { memo, FC, useMemo } from 'react'

export type EventDescriptionProps = {
  eventId: string | undefined
  description?: string | null
  fileHistories: FileHistory[] | undefined
}
export const EventDescriptionPanel: FC<EventDescriptionProps> = memo(
  (props) => {
    const { hasPrjFileDeletePermission, hasPrjFileDownloadPermission } =
      useProjectPermissions(EntityType.Event, props.eventId)

    // List image file
    const imageFiles = useMemo(
      () => props.fileHistories?.filter((item) => isImageFile(item.name)),
      [props.fileHistories]
    )

    // List normal file
    const files = useMemo(
      () => props.fileHistories?.filter((item) => !isImageFile(item.name)),
      [props.fileHistories]
    )

    return (
      <>
        <div className="p-2">
          {/* 説明 */}
          {props?.description && (
            <div className={classNames(HTMLEDITOR_VIEW_STYLE)}>
              <CrewHtmlContent html={props?.description} />
            </div>
          )}
        </div>
        <div className="p-2">
          {/* 添付画像ファイル */}
          {imageFiles && (
            <CrewAttachmentThumbnails
              attachmentThumbnails={imageFiles}
              showDeleteButton={hasPrjFileDeletePermission}
              canDownload={hasPrjFileDownloadPermission}
            />
          )}

          {/* 添付ファイル */}
          {files && (
            <CrewAttachments
              attachments={files}
              showDeleteButton={hasPrjFileDeletePermission}
              canDownload={hasPrjFileDownloadPermission}
            />
          )}
        </div>
      </>
    )
  }
)
