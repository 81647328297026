import { FC, memo, useCallback, useMemo, useState } from 'react'
import { Modal } from 'components/layouts/modal/modal'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useTranslation } from '@crew/modules/i18n'
import { CrewRadioGroup } from 'components/devextreme/crewRadioGroup'
import { ChangeEventScopeType } from '@crew/enums/domain'
import { ValueChangedEvent } from 'devextreme/ui/radio_group'

export const EventAction = {
  Edit: 'edit',
  Delete: 'delete',
} as const

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type EventAction = (typeof EventAction)[keyof typeof EventAction]

// render content
// renderとして使うのでmemo不可
const RadioItem: FC<{
  id: string
  name: string
}> = (props) => (
  <div className="py-2 pointer-events-none">
    <p className="crew-text-default">{props.name}</p>
  </div>
)

type EventChangeScopeDialogProps = {
  title: string
  isOpen: boolean
  onClose: () => void
  eventAction: EventAction
  onConfirm: (changeEventScope: ChangeEventScopeType) => void
}

export const EventChangeScopeDialog: FC<EventChangeScopeDialogProps> = memo(
  (props) => {
    const { t } = useTranslation()

    const [changeEventScope, setChangeEventScope] =
      useState<ChangeEventScopeType>(ChangeEventScopeType.ThisEvent)

    const changeEventScopes = useMemo(() => {
      return Object.keys(ChangeEventScopeType).map((key) => {
        const value =
          ChangeEventScopeType[key as keyof typeof ChangeEventScopeType]
        return {
          id: value,
          name: t(`label.changeEventScopeType.${value}`),
        }
      })
    }, [t])

    // ラジオボタン変更
    const handleRadioGroupValueChanged = useCallback(
      (event: ValueChangedEvent) => {
        const value = event.value as ChangeEventScopeType
        setChangeEventScope(value)
      },
      []
    )

    // 確認ボタンクリック
    const handleConfirmButtonClick = useCallback(() => {
      props.onConfirm(changeEventScope)
    }, [changeEventScope, props])

    return (
      <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
        <div className="flex flex-col gap-y-5 h-full w-80">
          <p className="crew-text-gray-4">
            {props.eventAction === EventAction.Edit
              ? t('label.changeEventScope')
              : t('label.deleteEventScope')}
          </p>

          {/* change event scope radio group */}
          <CrewRadioGroup
            layout="vertical"
            items={changeEventScopes}
            valueExpr="id"
            displayExpr="name"
            itemRender={RadioItem}
            onValueChanged={handleRadioGroupValueChanged}
            value={changeEventScope}
          />

          {changeEventScope !== ChangeEventScopeType.ThisEvent && (
            <p className="text-crew-red-500">
              {props.eventAction === EventAction.Edit
                ? t('label.changeEventScopeDescription')
                : t('label.changeEventScopeDescription2')}
            </p>
          )}

          <div className="flex justify-end gap-2.5">
            {/* OKボタン */}
            <CrewButton
              text={t('action.confirmPermit')}
              type="primary"
              onClick={handleConfirmButtonClick}
            />

            {/* キャンセルボタン */}
            <CrewButton
              text={t('action.confirmCancel')}
              type="normal"
              onClick={props.onClose}
            />
          </div>
        </div>
      </Modal>
    )
  }
)
