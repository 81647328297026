import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useUpdateUserPasswordMutation } from '@crew/apis/setting/settingApis'
import { useTranslation } from '@crew/modules/i18n'
import { ValidateRules } from '@crew/utils/form'

export type FormValues = {
  currentPassword: string
  newPassword: string
  newPasswordConfirmation: string
}
const formInitialValues: FormValues = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirmation: '',
}

export const useChangePasswordForm = () => {
  const { t } = useTranslation()

  // react-hook-formの各種データを取得
  const { control, handleSubmit, getValues, setError, reset, formState } =
    useForm<FormValues>({
      criteriaMode: 'all',
      defaultValues: formInitialValues,
    })

  // Get function for update password
  const [
    updateUserPasswordMutation,
    { isLoading: isLoadingUpdateUserPassword },
  ] = useUpdateUserPasswordMutation()

  // Update password process
  const updatePassword = useCallback(
    async (data: FormValues) => {
      const payload = {
        password: data.currentPassword,
        newPassword: data.newPassword,
      }
      await updateUserPasswordMutation({
        userSetting: payload,
      }).unwrap()
    },
    [updateUserPasswordMutation]
  )

  // validate confirm password equal password
  const checkPasswordConfirm = useCallback(() => {
    return getValues('newPassword') === getValues('newPasswordConfirmation')
  }, [getValues])

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      currentPassword: {
        required: t('message.general.isRequired', {
          name: t('label.password'),
        }),
      },
      newPassword: {
        required: t('message.general.isRequired', {
          name: t('label.password'),
        }),
      },
      newPasswordConfirmation: {
        required: t('message.general.isRequired', {
          name: t('label.confirmPassword'),
        }),
        validate: {
          checkPasswordConfirm: () =>
            checkPasswordConfirm() ||
            t('message.signup.register.invalidFormatConfirmPassword'),
        },
      },
    }),
    [checkPasswordConfirm, t]
  )

  return {
    control,
    handleSubmit,
    setError,
    reset,
    formState,
    validateRules,
    updatePassword,
    isLoadingUpdateUserPassword,
  }
}
