import { useGetSystemPermissionsQuery, useGetProjectPermissionsQuery, } from '@crew/apis/user/userApis';
import { Permissions } from '@crew/enums/app';
import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
/**
 * 指定した権限を持っているか
 * @param permissionsResult
 * @param permissionCode
 * @returns
 */
const hasPermission = (permissionsResult, permissionCode) => {
    var _a, _b;
    return ((_b = (_a = permissionsResult === null || permissionsResult === void 0 ? void 0 : permissionsResult.permissions.find((permission) => permission.code === permissionCode)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : false);
};
/**
 * システム権限を取得するためのcustom hook
 * @returns
 */
export const useSystemPermissions = () => {
    const { data: getSystemPermissionsResult } = useGetSystemPermissionsQuery();
    /**
     * 契約情報の表示/変更
     */
    const hasSysContractViewEditPermission = hasPermission(getSystemPermissionsResult, Permissions.SysContractViewEdit.value);
    /**
     * 組織設定の表示/変更
     */
    const hasSysTenantSettingViewEditPermission = hasPermission(getSystemPermissionsResult, Permissions.SysTenantSettingViewEdit.value);
    /**
     * プロジェクトの作成/削除
     */
    const hasSysProjectCreateDeletePermission = hasPermission(getSystemPermissionsResult, Permissions.SysProjectCreateDelete.value);
    /**
     * パブリックプロジェクトの表示 /参加
     */
    const hasSysPublicProjectViewJoinPermission = hasPermission(getSystemPermissionsResult, Permissions.SysPublicProjectViewJoin.value);
    /**
     * 組織内ユーザーの招待
     */
    const hasSysInternalUserInvitePermission = hasPermission(getSystemPermissionsResult, Permissions.SysInternalUserInvite.value);
    /**
     * 組織外ユーザーの招待
     */
    const hasSysExternalUserInvitePermission = hasPermission(getSystemPermissionsResult, Permissions.SysExternalUserInvite.value);
    /**
     * ユーザーリストの表示
     */
    const hasSysUserListViewPermission = hasPermission(getSystemPermissionsResult, Permissions.SysUserListView.value);
    return useMemo(() => ({
        hasSysContractViewEditPermission,
        hasSysTenantSettingViewEditPermission,
        hasSysProjectCreateDeletePermission,
        hasSysPublicProjectViewJoinPermission,
        hasSysInternalUserInvitePermission,
        hasSysExternalUserInvitePermission,
        hasSysUserListViewPermission,
    }), [
        hasSysContractViewEditPermission,
        hasSysExternalUserInvitePermission,
        hasSysInternalUserInvitePermission,
        hasSysProjectCreateDeletePermission,
        hasSysPublicProjectViewJoinPermission,
        hasSysTenantSettingViewEditPermission,
        hasSysUserListViewPermission,
    ]);
};
/**
 * プロジェクト権限を取得するためのcustom hook
 * @param permissionType
 * @param entityType
 * @param entityRecordId
 * @returns
 */
export const useProjectPermissions = (entityType, entityRecordId) => {
    // プロジェクト権限を取得する際に対象エンティティを特定する情報が不足している場合は権限取得をスキップするための変数
    // 　→　チャットルーム内の権限取得時、sliceからentityTypeとentityRecordIdを取る構造のため、処理順によって取得できない場合があるのでスキップする
    // https://redux-toolkit.js.org/rtk-query/usage/conditional-fetching
    const isSkipRequired = !entityType || !entityRecordId;
    const getUserPermissionsRequest = !isSkipRequired
        ? {
            entityType,
            entityRecordId,
        }
        : undefined;
    const { data: getProjectPermissionsResult } = useGetProjectPermissionsQuery(getUserPermissionsRequest !== null && getUserPermissionsRequest !== void 0 ? getUserPermissionsRequest : skipToken);
    /**
     * プロジェクトの編集
     */
    const hasPrjProjectEditPermission = hasPermission(getProjectPermissionsResult, Permissions.PrjProjectEdit.value);
    /**
     * プロジェクト設定の変更
     */
    const hasPrjProjectSettingEditPermission = hasPermission(getProjectPermissionsResult, Permissions.PrjProjectSettingEdit.value);
    /**
     * プロジェクトメンバーの追加
     */
    const hasPrjMemberAddPermission = hasPermission(getProjectPermissionsResult, Permissions.PrjMemberAdd.value);
    /**
     * プロジェクトメンバーの編集
     */
    const hasPrjMemberEditPermission = hasPermission(getProjectPermissionsResult, Permissions.PrjMemberEdit.value);
    /**
     * プロジェクトメンバーの削除
     */
    const hasPrjMemberDeletePermission = hasPermission(getProjectPermissionsResult, Permissions.PrjMemberDelete.value);
    /**
     * イベントの作成
     */
    const hasPrjEventCreatePermission = hasPermission(getProjectPermissionsResult, Permissions.PrjEventCreate.value);
    /**
     * イベントの編集
     */
    const hasPrjEventEditPermission = hasPermission(getProjectPermissionsResult, Permissions.PrjEventEdit.value);
    /**
     * イベントの削除
     */
    const hasPrjEventDeletePermission = hasPermission(getProjectPermissionsResult, Permissions.PrjEventDelete.value);
    /**
     * 会議の管理
     */
    const hasPrjEventMeetingManagePermission = hasPermission(getProjectPermissionsResult, Permissions.PrjEventMeetingManage.value);
    /**
     * タスクの作成
     */
    const hasPrjTaskCreatePermission = hasPermission(getProjectPermissionsResult, Permissions.PrjTaskCreate.value);
    /**
     * タスクの編集
     */
    const hasPrjTaskEditPermission = hasPermission(getProjectPermissionsResult, Permissions.PrjTaskEdit.value);
    /**
     * タスクの削除
     */
    const hasPrjTaskDeletePermission = hasPermission(getProjectPermissionsResult, Permissions.PrjTaskDelete.value);
    /**
     * ファイルの作成
     */
    const hasPrjFileCreatePermission = hasPermission(getProjectPermissionsResult, Permissions.PrjFileCreate.value);
    /**
     * ファイルの編集
     */
    const hasPrjFileEditPermission = hasPermission(getProjectPermissionsResult, Permissions.PrjFileEdit.value);
    /**
     * ファイルのダウンロード
     */
    const hasPrjFileDownloadPermission = hasPermission(getProjectPermissionsResult, Permissions.PrjFileDownload.value);
    /**
     * ファイルの削除
     */
    const hasPrjFileDeletePermission = hasPermission(getProjectPermissionsResult, Permissions.PrjFileDelete.value);
    /**
     * フォルダの作成
     */
    const hasPrjFolderCreatePermission = hasPermission(getProjectPermissionsResult, Permissions.PrjFolderCreate.value);
    /**
     * フォルダの編集
     */
    const hasPrjFolderEditPermission = hasPermission(getProjectPermissionsResult, Permissions.PrjFolderEdit.value);
    /**
     * フォルダの削除
     */
    const hasPrjFolderDeletePermission = hasPermission(getProjectPermissionsResult, Permissions.PrjFolderDelete.value);
    /**
     * プロジェクトメンバーの役割変更
     */
    const hasPrjMemberRoleEditPermission = hasPermission(getProjectPermissionsResult, Permissions.PrjMemberRoleEdit.value);
    return useMemo(() => ({
        hasPrjProjectEditPermission,
        hasPrjProjectSettingEditPermission,
        hasPrjMemberEditPermission,
        hasPrjMemberAddPermission,
        hasPrjMemberDeletePermission,
        hasPrjEventCreatePermission,
        hasPrjEventEditPermission,
        hasPrjEventDeletePermission,
        hasPrjEventMeetingManagePermission,
        hasPrjTaskCreatePermission,
        hasPrjTaskEditPermission,
        hasPrjTaskDeletePermission,
        hasPrjFileCreatePermission,
        hasPrjFileEditPermission,
        hasPrjFileDownloadPermission,
        hasPrjFileDeletePermission,
        hasPrjFolderCreatePermission,
        hasPrjFolderEditPermission,
        hasPrjFolderDeletePermission,
        hasPrjMemberRoleEditPermission,
    }), [
        hasPrjEventCreatePermission,
        hasPrjEventDeletePermission,
        hasPrjEventMeetingManagePermission,
        hasPrjEventEditPermission,
        hasPrjMemberAddPermission,
        hasPrjFileCreatePermission,
        hasPrjFileDeletePermission,
        hasPrjFileDownloadPermission,
        hasPrjFileEditPermission,
        hasPrjMemberDeletePermission,
        hasPrjMemberEditPermission,
        hasPrjProjectEditPermission,
        hasPrjProjectSettingEditPermission,
        hasPrjTaskCreatePermission,
        hasPrjTaskDeletePermission,
        hasPrjTaskEditPermission,
        hasPrjFolderCreatePermission,
        hasPrjFolderEditPermission,
        hasPrjFolderDeletePermission,
        hasPrjMemberRoleEditPermission,
    ]);
};
