import { useLazyGetTaskKindsQuery } from '@crew/apis/task/taskApis'
import { EntityType } from '@crew/enums/domain'
import { TaskKind } from '@crew/models/domain'
import { useTranslation } from '@crew/modules/i18n'
import { useEffectOnce } from '@dx-system/react-use'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { CrewErrorDialog } from 'components/elements/crewErrorDialog/crewErrorDialog'
import {
  CrewSortableList,
  arrayMove,
} from 'components/elements/crewSortableList/crewSortableList'
import { useModal } from 'components/layouts/modal/useModal'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { memo, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'states/hooks'
import { TaskKindEntryDialog } from '../taskKindEntryDialog/taskKindEntryDialog'
import { TaskKindListItem } from './components/taskKindListItem/taskKindListItem'
import { SelectedTaskKind, useTaskKindListGrid } from './useTaskKindListGrid'
import { TaskKindTableHeader } from './components/taskKindTableHeader/taskKindTableHeader'

export const TaskKindListGrid = memo(() => {
  const { deleteTaskKind, reorderTaskKind, isLoadingDeleteTaskKind } =
    useTaskKindListGrid()
  const { projectId } = useParams()
  const { t } = useTranslation()
  const { success } = useToast()
  const [showApiErrors] = useShowApiErrors()

  const [
    isTaskKindEntryDialogOpen,
    openTaskKindEntryDialog,
    closeTaskKindEntryDialog,
  ] = useModal()

  const taskKindEventMessage = useAppSelector(
    (state) => state.app.taskKindEventMessage
  )

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  const [isErrorDialogOpen, openErrorDialog, closeErrorDialog] = useModal()

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')

  // エラーダイアログメッセージ
  const [errorMessage, setErrorMessage] = useState('')

  const [taskKinds, setTaskKinds] = useState<TaskKind[]>()

  // lazyGetで読み込みを行う。
  // 通常のgetを使うと、ソート失敗後の再読み込みでBackendが返すdataに変化がないため、useEffectが発火せずTaskKindsをリセットできない。
  // lazyGetの結果を常にsetTaskStateすることで回避する。
  const [lazyGetTaskKindsQuery] = useLazyGetTaskKindsQuery()
  const fetchTaskKinds = useCallback(async () => {
    if (!projectId) {
      return
    }

    const result = await lazyGetTaskKindsQuery({
      entityType: EntityType.Project,
      entityRecordId: projectId,
    })
    setTaskKinds(result.data?.taskKinds)
  }, [projectId, lazyGetTaskKindsQuery])

  // 初期読み込み
  useEffectOnce(() => {
    fetchTaskKinds()
  })

  // refresh task type list
  useEffect(() => {
    if (taskKindEventMessage) {
      fetchTaskKinds()
    }
  }, [fetchTaskKinds, taskKindEventMessage])

  const [selectedTaskKind, setSelectedTaskKind] = useState<
    SelectedTaskKind | undefined
  >(undefined)

  // 並び替え処理
  const handleReorder = useCallback(
    async (fromId: string, toId: string) => {
      // ちらつき防止のため、APIを呼ぶ前にUI上での並び替えを先行して行う
      setTaskKinds((items) => {
        if (!items) {
          return items
        }

        const from = items.findIndex((item) => item.id === fromId)
        const to = items.findIndex((item) => item.id === toId)
        return arrayMove(items, from, to)
      })

      try {
        await reorderTaskKind(fromId, toId)
      } catch (err) {
        setErrorMessage(t('message.general.errorMessage.reorder'))
        openErrorDialog()
      }

      // APIの実行結果にかかわらず再読み込みを行い、Backendに同期させる
      fetchTaskKinds()
    },
    [fetchTaskKinds, openErrorDialog, reorderTaskKind, t]
  )

  // 内の編集アイコン押下
  const handleTaskKindEditButtonClick = useCallback(
    (taskKindId: string, taskKindVersion: number) => {
      // id値はイベントオブジェクトから取得可能
      setSelectedTaskKind({
        taskKindId: taskKindId,
        version: taskKindVersion,
      })

      openTaskKindEntryDialog()
    },
    [openTaskKindEntryDialog]
  )

  // 内の削除アイコン押下
  const handleTaskKindDeleteButtonClick = useCallback(
    (taskKindId: string, taskKindVersion: number) => {
      setConfirmMessage(t('message.general.confirmMessage.delete'))
      openConfirmDialog()

      setSelectedTaskKind({
        taskKindId: taskKindId,
        version: taskKindVersion,
      })
    },
    [openConfirmDialog, t]
  )

  // 削除確認ダイアログ OKボタン
  const handleDeletePermitButtonClick = useCallback(async () => {
    if (selectedTaskKind) {
      try {
        await deleteTaskKind(
          selectedTaskKind.taskKindId,
          selectedTaskKind.version
        )

        success(t('message.taskKind.taskKindDeleted'))
      } catch (error) {
        showApiErrors(error)
      }
    }
    closeConfirmDialog()
  }, [
    closeConfirmDialog,
    deleteTaskKind,
    selectedTaskKind,
    showApiErrors,
    success,
    t,
  ])

  // タスク種別ダイアログClose
  const handleDialogClose = useCallback(() => {
    closeTaskKindEntryDialog()
    // ここで選択されたIDを初期化しないと次回のload時に編集前の値が残るのでresetする
    setSelectedTaskKind({ taskKindId: '', version: 0 })
  }, [closeTaskKindEntryDialog])

  return (
    <div className="h-full flex flex-col">
      {taskKinds && (
        <CrewSortableList
          items={taskKinds}
          onReorder={handleReorder}
          header={<TaskKindTableHeader />}
          renderItem={(item) => (
            <CrewSortableList.Item id={item.id}>
              <TaskKindListItem
                key={item.id}
                taskKind={item}
                onEditButtonClick={() =>
                  handleTaskKindEditButtonClick(item.id, item.version)
                }
                onDeleteButtonClick={() =>
                  handleTaskKindDeleteButtonClick(item.id, item.version)
                }
                dragHandle={<CrewSortableList.DragHandle />}
              />
            </CrewSortableList.Item>
          )}
        />
      )}

      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleDeletePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
        permitButtonDisabled={isLoadingDeleteTaskKind}
      />

      <CrewErrorDialog
        isOpen={isErrorDialogOpen}
        message={errorMessage}
        onCloseButtonClick={closeErrorDialog}
      />

      <TaskKindEntryDialog
        isEditMode={true}
        title={t('label.taskKindEdit')}
        isOpen={isTaskKindEntryDialogOpen}
        onClose={handleDialogClose}
        taskKindId={selectedTaskKind?.taskKindId}
      />
    </div>
  )
})
