import { FC, memo, useMemo } from 'react'
import classNames from 'classnames'
import { HTMLEDITOR_VIEW_STYLE } from 'configs/constants'
import BaselineVideocam from '~icons/ic/baseline-videocam'
import { CrewLink } from 'components/elements/crewLink/crewLink'
import { CrewBadge } from 'components/elements/crewBadge/crewBadge'
import { CrewPopover } from 'components/devextreme/crewPopover'
import { getDefaultTabValue } from '@crew/utils/enum'
import { EventDetailTabs, ProjectListTabs } from 'enums/app'
import { useTranslation } from '@crew/modules/i18n'
import { CrewHtmlContent } from 'components/elements/crewHtmlContent/crewHtmlContent'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import dayjs from '@crew/modules'
import { JsonDateFormat } from '@crew/enums/system'
import { EventKindRef, ProjectRef } from '@crew/models/refs'
import { ProjectType } from '@crew/enums/domain'

type Event = {
  id: string
  subject: string
  description: string | null
  startDatetime: string
  endDatetime: string
  entityRecordId: string | null
  eventKind: EventKindRef
  isMeeting: boolean
  project?: ProjectRef | null
}

type CrewEventItemProps = {
  event: Event
  displayRelatedName: boolean
}

export const CrewEventItem: FC<CrewEventItemProps> = memo((props) => {
  const { t } = useTranslation()

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  // check startDatetime and endDatetime is same day
  const isSameDay = useMemo(
    () =>
      dayjs(props.event.startDatetime).format(JsonDateFormat.YYYYMMDD) ===
      dayjs(props.event.endDatetime).format(JsonDateFormat.YYYYMMDD),
    [props.event.endDatetime, props.event.startDatetime]
  )

  // Project name
  const projectNameElement = useMemo(() => {
    if (!props.displayRelatedName || !props.event.project) {
      return null
    }

    if (props.event.project.projectType === ProjectType.InstantChannel) {
      // 会議の関連先(添付赤枠)が個人スケジュールの場合、リンクではなく単純な文字列として表示する（プロジェクト詳細に遷移する必要がないため）
      return (
        <span className="crew-text-gray-5">{props.event.project.subject}</span>
      )
    }

    return (
      <CrewLink
        to={`/projects/${props.event.project.id}/${getDefaultTabValue(
          ProjectListTabs
        )}`}
        title={props.event.project.subject}
        color="gray"
        className="line-clamp-2"
      >
        {props.event.project.subject}
      </CrewLink>
    )
  }, [props.displayRelatedName, props.event.project])

  return (
    <div className="flex flex-col gap-1.5 w-full">
      {/* 開始日時と終了日時 */}
      {isSameDay ? (
        // 時刻指定あり：開始と終了で日付が同じ場合は時刻のみ表示
        <div className="flex flex-row gap-x-2">
          <time>
            {t('format.datetime', {
              value: props.event.startDatetime,
              timeZone: defaultUserProfileRegion,
            })}
          </time>
          <time>-</time>
          <time>
            {t('format.shorttime', {
              value: props.event.endDatetime,
              timeZone: defaultUserProfileRegion,
            })}
          </time>
        </div>
      ) : (
        // 時刻指定あり：開始と終了で日付が異なる場合は日付と時刻を表示
        <div className="flex flex-row gap-x-2">
          <div className="flex flex-row gap-x-1.5">
            <time>
              {t('format.datetime', {
                value: props.event.startDatetime,
                timeZone: defaultUserProfileRegion,
              })}
            </time>
          </div>
          <time>-</time>
          <div className="flex flex-row gap-x-1.5">
            <time>
              {t('format.datetime', {
                value: props.event.endDatetime,
                timeZone: defaultUserProfileRegion,
              })}
            </time>
          </div>
        </div>
      )}

      <div className="flex flex-row gap-2 items-center">
        <div className="flex flex-row items-center gap-2.5">
          {/* event subject */}
          <CrewLink
            to={`/events/${props.event.id}/${getDefaultTabValue(
              EventDetailTabs
            )}`}
            className="font-bold line-clamp-2"
            title={props.event.subject}
          >
            {props.event.subject}
          </CrewLink>

          {/* 活動種別バッジ */}
          <CrewBadge
            displayColor={props.event.eventKind.displayColor}
            className="shrink-0"
          >
            {props.event.eventKind.name}
          </CrewBadge>

          {/* project subject */}
          {projectNameElement}
        </div>

        {/* Show icon meeting  */}
        {props.event.isMeeting && (
          <div id={`isMeeting_${props.event.id}`}>
            <BaselineVideocam
              width={24}
              height={24}
              className="crew-badge-icon-red"
            />
            {/* Popover shows meeting status */}
            <CrewPopover
              target={`#isMeeting_${props.event.id}`}
              showEvent="mouseenter"
              hideEvent="mouseleave"
              position="top"
            >
              {t('message.meeting.webMeetingInProgress')}
            </CrewPopover>
          </div>
        )}
      </div>

      <div>
        {props.event.description && (
          <div className={classNames('line-clamp-2', HTMLEDITOR_VIEW_STYLE)}>
            <CrewHtmlContent html={props.event.description} />
          </div>
        )}
      </div>
    </div>
  )
})
