import { FC, Fragment, memo, useCallback, useState } from 'react'

import { ChatView } from '@crew/enums/app'

import { getChatMessageListItemDomId } from 'utils/chat'
import { CrewUnreadLine } from 'components/elements/crewUnreadLine/crewUnreadLine'

import { ChatThreadListMessageListItem } from './components/chatThreadListMessageListItem/chatThreadListMessageListItem'
import { useChatThreadListMessageList } from './useChatThreadListMessageList'
import { useValueChangeEffect } from '@crew/hooks'
import { ChatReadStatusDialog } from 'features/chat/components/chatReadStatusDialog/chatReadStatusDialog'
import { useTranslation } from '@crew/modules/i18n'
import { useModal } from 'components/layouts/modal/useModal'

type ChatThreadListMessageListProps = {
  selectedItemId?: string | undefined // 選択中のメッセージ。ハイライト用に使用する
}

export const ChatThreadListMessageList: FC<ChatThreadListMessageListProps> =
  memo((props) => {
    const {
      displayItems,
      itemsScrollableDivRef,
      messageInView,
      messageOutOfView,
    } = useChatThreadListMessageList()

    const { t } = useTranslation()
    const [
      isOpenedReadStatusDialog,
      openReadStatusDialog,
      closeReadStatusDialog,
    ] = useModal()

    const [selectedReadStatusMessageId, setSelectedReadStatusMessageId] =
      useState<string>('')

    // メッセージ表示イベントハンドラ
    const handleMessageInView = useCallback(
      (inView: boolean, messageId: string) => {
        if (inView) {
          messageInView(messageId)
        } else {
          messageOutOfView(messageId)
        }
      },
      [messageInView, messageOutOfView]
    )

    const [selectedMessageId, setSelectedMessageId] = useState<
      string | undefined
    >(undefined)

    useValueChangeEffect(
      () => {
        setSelectedMessageId(props.selectedItemId)
      },
      [props.selectedItemId],
      props.selectedItemId
    )

    // Open ReadStatusDialog
    const handleClickChatReadStatus = useCallback(
      (chatMessageId: string) => {
        setSelectedReadStatusMessageId(chatMessageId)
        openReadStatusDialog()
      },
      [openReadStatusDialog]
    )

    return (
      // ThreadListMessageList
      <div
        className="grow flex overflow-y-scroll flex-col-reverse"
        ref={itemsScrollableDivRef}
      >
        <div className="grow flex flex-col">
          {displayItems.map((item) => (
            <Fragment key={item.id}>
              <CrewUnreadLine show={item.showUnreadLine} />
              <ChatThreadListMessageListItem
                key={item.id}
                // スクロール位置制御のためChatViewを含めたidとして指定する
                id={getChatMessageListItemDomId(
                  item.messageId,
                  ChatView.ThreadList
                )}
                replyCount={item.threadReplyCount}
                chatMessageId={item.messageId}
                container={itemsScrollableDivRef}
                onMessageInView={handleMessageInView}
                onAdditionalLoading={item.handleAdditionalLoading}
                onFirstUnreadMessageViewed={item.handleFirstUnreadMessageViewed}
                isFirstUnreadMessage={item.isFirstUnreadMessage}
                selectedItemId={selectedMessageId}
                setSelectedItemId={setSelectedMessageId}
                onClickChatReadStatus={handleClickChatReadStatus}
              />
            </Fragment>
          ))}
        </div>

        {/* 既読状況 */}
        {selectedReadStatusMessageId && (
          <ChatReadStatusDialog
            isOpen={isOpenedReadStatusDialog}
            title={t('label.readStatus')}
            messageId={selectedReadStatusMessageId}
            onClose={closeReadStatusDialog}
          />
        )}
      </div>
    )
  })
