import { useTranslation } from '@crew/modules/i18n'
import { useForm } from 'react-hook-form'
import { useUpdateEventAttendeeMutation } from '@crew/apis/project/projectApis'
import { EventDetailAttendances } from 'enums/app'
import { useCallback, useMemo } from 'react'
import { EventAttendee } from '@crew/apis/project/models/updateEventAttendee/request'

export type FormValues = {
  attendance: string | null
  comment: string | null
}
const formInitialValues: FormValues = {
  attendance: EventDetailAttendances.Attendance.value,
  comment: '',
}

export const useEventDetailAttendanceAndCommentForm = () => {
  const { t } = useTranslation()

  // react-hook-formの各種データを取得
  const { control, handleSubmit, setError, formState, reset } =
    useForm<FormValues>({
      criteriaMode: 'all',
      defaultValues: formInitialValues,
    })

  // Get function for update event attendee
  const [
    updateEventAttendeeMutation,
    { isLoading: isLoadingUpdateEventAttendee },
  ] = useUpdateEventAttendeeMutation()

  // Update event attendee
  const updateEventAttendee = useCallback(
    async (attendee: EventAttendee) => {
      const result = await updateEventAttendeeMutation({
        eventAttendee: attendee,
      }).unwrap()

      return result
    },
    [updateEventAttendeeMutation]
  )

  // Attendee data source
  const attendanceDataSource = useMemo(() => {
    return Object.values(EventDetailAttendances).map((item) => {
      return {
        id: item.value,
        text: t(item.text),
      }
    })
  }, [t])

  return {
    control,
    handleSubmit,
    setError,
    formState,
    reset,
    attendanceDataSource,
    updateEventAttendee,
    isLoadingUpdateEventAttendee,
  }
}
