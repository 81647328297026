import { CrewButton } from 'components/elements/crewButton/crewButton'
import { memo } from 'react'
import { useModal } from 'components/layouts/modal/useModal'
import { useTranslation } from '@crew/modules/i18n'
import { useProjectSettingPageContext } from 'features/project/components/projectSettingPage/useProjectSettingPage'
import { TaskCategoryEntryDialog } from '../taskCategoryEntryDialog/taskCategoryEntryDialog'

export const TaskCategoryListToolbar = memo(() => {
  const { t } = useTranslation()

  const { projectSubject } = useProjectSettingPageContext()

  const [
    isTaskCategoryEntryDialogOpen,
    openTaskCategoryEntryDialog,
    closeTaskCategoryEntryDialog,
  ] = useModal()

  return (
    <div className="text-right">
      <CrewButton
        type="primary"
        text={t('action.newTaskCategory')}
        onClick={openTaskCategoryEntryDialog}
      />

      <TaskCategoryEntryDialog
        isEditMode={false}
        title={t('label.addTaskCategory') + ' - ' + projectSubject}
        isOpen={isTaskCategoryEntryDialogOpen}
        onClose={closeTaskCategoryEntryDialog}
        taskCategoryId={null}
        version={null}
      />
    </div>
  )
})
