import { Menu } from '@headlessui/react'
import classNames from 'classnames'
import { useMemo } from 'react'
import { genericMemo } from 'utils'
import {
  DropDownButtonClassNamesMap,
  DropDownButtonSize,
  DropDownButtonSizeClassNamesMap,
  DropDownButtonType,
  StylingMode,
} from '../../crewDropDownButton'

type DropDownItemProps<TItem> = {
  itemData: TItem
  displayExpr: keyof TItem
  onItemClick?: (itemData: TItem) => void
  itemRender?: (itemData: TItem) => React.ReactElement
  isSelected?: boolean
  type: DropDownButtonType
  stylingMode: StylingMode
  size: DropDownButtonSize
  highlightSelected: boolean
}

export const DropDownItem = genericMemo(
  <TItem,>({
    itemData,
    displayExpr,
    onItemClick,
    itemRender,
    isSelected,
    type,
    stylingMode,
    size,
    highlightSelected,
  }: DropDownItemProps<TItem>) => {
    // Handle item click
    const handleMenuItemClick = () => {
      onItemClick?.(itemData)
    }

    const isDisabled = useMemo(() => {
      // Check if itemData has a disabled property
      if (typeof itemData === 'object' && itemData && 'disabled' in itemData) {
        return itemData.disabled
      }

      return false
    }, [itemData])

    const icon = useMemo(() => {
      // Check if itemData has an icon property
      if (typeof itemData === 'object' && itemData && 'icon' in itemData) {
        return itemData.icon
      }

      return null
    }, [itemData])

    return (
      <Menu.Item>
        <div
          className={classNames(
            'w-full flex items-center rounded gap-2',
            DropDownButtonSizeClassNamesMap[size],
            DropDownButtonClassNamesMap[type][stylingMode],
            highlightSelected &&
              isSelected &&
              DropDownButtonSelectedItemClassNamesMap[type],
            isDisabled ? 'pointer-events-none opacity-70' : 'cursor-pointer'
          )}
          onClick={handleMenuItemClick}
        >
          {itemRender?.(itemData) || (
            <>
              {/* render icon */}
              {icon}

              {/* render text */}
              <span className="whitespace-nowrap">
                {itemData[displayExpr] as string}
              </span>
            </>
          )}
        </div>
      </Menu.Item>
    )
  }
)

// Define a mapping object for selected item button types
const DropDownButtonSelectedItemClassNamesMap = {
  primary: '!bg-crew-blue-600 dark:!bg-crew-blue-600',
  success: '!bg-crew-green-600 dark:!bg-crew-green-600',
  danger: '!bg-crew-red-600 dark:!bg-crew-red-600',
  normal: '!bg-crew-gray-300 dark:!bg-crew-gray-600',
} as const satisfies {
  [key in DropDownButtonType]: string
}
