import { ContextMenuItems, NotifyEventType } from 'enums/app'
import { useCallback, useMemo } from 'react'
import {
  useDeleteBookmarkMutation,
  useInsertBookmarkMutation,
  useUpdateBookmarkArchiveMutation,
} from '@crew/apis/bookmark/bookmarkApis'
import { useDeleteChatMessageMutation } from '@crew/apis/chat/chatApis'
import { useAppDispatch } from 'states/hooks'
import {
  ObjectEventMessage,
  notifyBookmarkEvent,
} from 'features/app/states/appSlice'
import { Bookmark } from '@crew/models/domain'
import { useTranslation } from '@crew/modules/i18n'

export const useCrewChatMessageActionMenu = (
  disabledContextMenu: boolean,
  disabledChatReadStatus: boolean
) => {
  const { t } = useTranslation()

  const [insertBookmarkMutation] = useInsertBookmarkMutation()
  const [deleteBookmarkMutation] = useDeleteBookmarkMutation()
  const [updateBookmarkArchiveMutation] = useUpdateBookmarkArchiveMutation()
  const [deleteChatMessageMutation, { isLoading: isLoadingDeleteChatMessage }] =
    useDeleteChatMessageMutation()

  const dispatch = useAppDispatch()

  const contextMenuData = useMemo(() => {
    return Object.values(ContextMenuItems)
      .map((item) => {
        return {
          id: item.action,
          name: t(item.label),
        }
      })
      .filter(
        (item) =>
          !disabledContextMenu ||
          item.id === ContextMenuItems.Copy.action || // コピーは常に表示
          (!disabledChatReadStatus &&
            item.id === ContextMenuItems.ReadStatus.action) // 読了状態は表示
      )
  }, [disabledContextMenu, disabledChatReadStatus, t])

  // delete bookmark
  const deleteBookmark = useCallback(
    async (chatMessageId: string) => {
      // ここではブックマークされているかの状態はpropsでしか持っていないためバックエンドのブックマークAPI側でwebSocket経由でブックマーク更新のメッセージを投げる
      // 別端末・同じユーザーでログインしていた場合にブックマークの状態を反映させるため
      await deleteBookmarkMutation({
        chatMessageId,
      }).unwrap()
    },
    [deleteBookmarkMutation]
  )

  // insert bookmark
  const insertBookmark = useCallback(
    async (chatMessageId: string) => {
      // ここではブックマークされているかの状態はpropsでしか持っていないためバックエンドのブックマークAPI側でwebSocket経由でブックマーク更新のメッセージを投げる
      // 別端末・同じユーザーでログインしていた場合にブックマークの状態を反映させるため
      await insertBookmarkMutation({
        chatMessageId,
      }).unwrap()
    },
    [insertBookmarkMutation]
  )

  // 削除確認ダイアログ OKボタン
  const deleteChatMessage = useCallback(
    async (chatMessageId: string, version: number) => {
      await deleteChatMessageMutation({
        chatMessageId,
        version,
      }).unwrap()
    },
    [deleteChatMessageMutation]
  )

  //Bookmark archive checkbox changed
  const bookmarkArchiveSelectionChange = useCallback(
    async (chatMessageId: string, isArchived: boolean, version: number) => {
      // update bookmark message archive
      const paramBookmarkArchiveRequest = {
        chatMessageId,
        archived: !isArchived,
        version,
      }

      await updateBookmarkArchiveMutation({
        bookmark: paramBookmarkArchiveRequest,
      }).unwrap()

      const objectEventMessage: ObjectEventMessage<Bookmark> = {
        eventType: NotifyEventType.Updated,
        id: chatMessageId,
        object: undefined,
      }

      dispatch(notifyBookmarkEvent(objectEventMessage))
    },
    [dispatch, updateBookmarkArchiveMutation]
  )

  return {
    contextMenuData,
    deleteChatMessage,
    deleteBookmark,
    insertBookmark,
    bookmarkArchiveSelectionChange,
    isLoadingDeleteChatMessage,
  }
}
