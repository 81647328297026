import { EntityType } from '@crew/enums/domain'
import { generateImageAvatarUrl } from '@crew/utils/avatar'
import Groups from '~icons/material-symbols/groups'
import {
  CrewAvatar,
  CrewAvatarShapeType,
} from 'components/elements/crewAvatar/crewAvatar'
import { CrewFavorite } from 'components/elements/crewFavorite/crewFavorite'
import { CrewGroupAvatar } from 'components/elements/crewGroupAvatar/crewGroupAvatar'
import { CrewLink } from 'components/elements/crewLink/crewLink'
import { ProjectScope } from 'enums/app'
import { memo } from 'react'
import { ProjectMemberEntryDialog } from 'features/project/components/projectMemberEntryDialog/projectMemberEntryDialog'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useTranslation } from '@crew/modules/i18n'
import { getDefaultTabValue } from '@crew/utils/enum'
import { useModal } from 'components/layouts/modal/useModal'
import { ProjectListTabs } from 'enums/app'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { ProjectGroupRef, UserRef } from '@crew/models/refs'
import { Favorite } from '@crew/models/domain'
import { CrewNotificationProject } from 'components/elements/crewNotificationProject/crewNotificationProject'

type Project = {
  id: string
  subject: string
  scope: string
  projectGroup: ProjectGroupRef
  projectMembers: UserRef[]
  projectFavorite: Favorite | null
  version: number
}

type ProjectSettingHeadPanelProps = {
  project: Project | null | undefined
  isJoinedProject: boolean
}

export const ProjectSettingHeadPanel = memo(
  (props: ProjectSettingHeadPanelProps) => {
    const { t } = useTranslation()

    const { projectId } = useParams()

    // プロジェクトメンバー追加ダイアログ
    const [
      isProjectMemberEntryDialogOpen,
      openProjectMemberEntryDialog,
      closeProjectMemberEntryDialog,
    ] = useModal()

    const { navigate } = useCrewNavigate()

    // go to project detail from project setting
    const handleReturnProjectButtonClick = useCallback(() => {
      navigate(`/projects/${projectId}/${getDefaultTabValue(ProjectListTabs)}`)
    }, [navigate, projectId])

    return props.project ? (
      <div className="flex flex-col gap-2.5">
        <div className="flex flex-col gap-2">
          <div className="w-full flex gap-x-2.5 justify-between items-center">
            {/* ページタイトル */}
            <div className="flex gap-2 items-center h-8 text-crew-gray-3-light dark:text-crew-gray-2-dark">
              <Groups width={24} height={24} />
              <p className="font-bold">{t('label.project')}</p>
            </div>
            <CrewButton
              type="normal"
              stylingMode="outlined"
              text={t('label.returnToProject')}
              onClick={handleReturnProjectButtonClick}
            />
          </div>

          <div className="flex flex-row justify-between items-start gap-2">
            <div className="flex flex-row items-center gap-2">
              {/* プロジェクトアバター */}
              <CrewAvatar
                cacheValue={props.project.id + props.project.version}
                displayName={props.project.subject}
                shape={CrewAvatarShapeType.Square}
                isPrivate={props.project.scope === ProjectScope.Private.key}
                imageURL={generateImageAvatarUrl(
                  EntityType.Project,
                  props.project.id
                )}
              />
              <div className="flex flex-col">
                {/* 件名 */}
                <div
                  className="font-bold text-2xl"
                  title={props.project.subject}
                >
                  {props.project.subject}
                </div>

                {/* プロジェクトグループ
                 * FIXME: 遷移先が決まり次第toプロパティを設定する
                 * https://break-tmc.atlassian.net/browse/CREW-6918
                 */}
                <CrewLink title={props.project.projectGroup?.name}>
                  {props.project.projectGroup?.name}
                </CrewLink>
              </div>
              <div className="flex gap-2 items-center">
                {props.isJoinedProject && (
                  <>
                    {/* 通知アイコン */}
                    <CrewNotificationProject projectId={props.project.id} />

                    {/* お気に入りアイコン */}
                    <CrewFavorite
                      key={props.project.id}
                      entityType={EntityType.Project}
                      entityRecordId={props.project.id}
                      isFavorite={props.project.projectFavorite ? true : false}
                    />
                  </>
                )}
                {/* disable click add member when use has not joined the project */}
                <div className="flex">
                  {/* プロジェクトメンバー */}
                  <CrewGroupAvatar
                    groupAvatar={props.project.projectMembers}
                    onAddAvatarClick={openProjectMemberEntryDialog}
                    //  hidden icon add member when has not joined the project
                    showAddAvatar={props.isJoinedProject ?? false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* プロジェクトメンバー追加ダイアログ */}
        <ProjectMemberEntryDialog
          title={t('label.addOrInviteMembers')}
          isOpen={isProjectMemberEntryDialogOpen}
          onClose={closeProjectMemberEntryDialog}
        />
      </div>
    ) : null
  }
)
