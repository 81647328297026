import { SettingKeyType } from '@crew/enums/app'
import { useTranslation } from '@crew/modules/i18n'
import { useLoadUserSettings, useUserSetting } from '@crew/states'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewRequireDot } from 'components/elements/crewRequireDot'
import { CrewCheckBoxField } from 'components/forms/crewCheckBoxField'
import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import { NotificationSettingValues } from 'enums/app'
import { useNotificationPermission } from 'hooks/pushNotification/useNotificationPermission'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { memo, useCallback, useEffect, useMemo } from 'react'
import {
  formInitialValues,
  FormValues,
  usePersonalSettingNotificationForm,
} from './usePersonalSettingNotificationForm'

/**
 * Personal Setting Notification Form
 * @date 8/3/2023 - 12:49:19 PM
 *
 * @type {*}
 */
export const PersonalSettingNotificationForm = memo(() => {
  const {
    control,
    handleSubmit,
    reset,
    setError,
    validateRules,
    updateUserSettings,
    isLoadingInsertOrReplaceSetting,
  } = usePersonalSettingNotificationForm()

  const { t } = useTranslation()
  const { success } = useToast()
  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  const [loadUserSettings] = useLoadUserSettings()

  const [permission, requestPermissionAsync] = useNotificationPermission()

  // Get the notify setting for logged in user
  const defaultNotificationMention = useUserSetting(
    SettingKeyType.NotificationMention,
    NotificationSettingValues.BannerAndEmail.value
  )
  const defaultNotificationReply = useUserSetting(
    SettingKeyType.NotificationReply,
    NotificationSettingValues.BannerAndEmail.value
  )
  const defaultNotificationMessage = useUserSetting(
    SettingKeyType.NotificationMessage,
    NotificationSettingValues.BannerAndEmail.value
  )
  const defaultNotificationReaction = useUserSetting(
    SettingKeyType.NotificationReaction,
    NotificationSettingValues.None.value
  )
  const defaultNotificationMeOnlyWhenOffline = useUserSetting(
    SettingKeyType.NotificationMeOnlyWhenOffline,
    'false'
  )

  // [Notification] section
  // Get the list of new arrival
  const notificationSettingValues = useMemo(() => {
    return Object.values(NotificationSettingValues).map((item) => {
      return {
        value: item.value,
        text: t(item.text),
      }
    })
  }, [t])

  // Display saved settings on the screen
  useEffect(() => {
    formInitialValues.notificationMention = String(defaultNotificationMention)
    formInitialValues.notificationReply = String(defaultNotificationReply)
    formInitialValues.notificationMessage = String(defaultNotificationMessage)
    formInitialValues.notificationReaction = String(defaultNotificationReaction)
    formInitialValues.notificationMeOnlyWhenOffline =
      defaultNotificationMeOnlyWhenOffline === 'true' ? true : false

    reset({
      ...formInitialValues,
    })
  }, [
    defaultNotificationMeOnlyWhenOffline,
    defaultNotificationMention,
    defaultNotificationReply,
    defaultNotificationMessage,
    defaultNotificationReaction,
    reset,
  ])

  // Event handle when the Save button is clicked
  const handleSaveSettingButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        // Execute update user setting process
        await updateUserSettings(data)

        // Display a toast indicating that the setting update was successful
        success(t('message.personalSetting.saveUserSettingSuccess'))

        // Reload the settings
        loadUserSettings()
      } catch (err) {
        // show error api
        showApiErrors(err)
      }
    }
    handleSubmit(onSubmit)()
  }, [
    handleSubmit,
    updateUserSettings,
    success,
    t,
    loadUserSettings,
    showApiErrors,
  ])

  const handlePermissionRequestButtonClick = useCallback(() => {
    requestPermissionAsync()
  }, [requestPermissionAsync])

  return (
    <form className="flex flex-col gap-y-5">
      {/* Notification  */}
      <div className="flex flex-col gap-y-2.5 py-2.5 border-b crew-border-gray">
        <p className="font-bold leading-5">{t('label.notificationMethod')}</p>

        {/* メンション */}
        <div className="flex items-center justify-between">
          <span className="crew-text-gray-4">
            {t('label.mention')}
            <CrewRequireDot />
          </span>
          <CrewSelectBoxField
            control={control}
            id="notificationMention"
            name="notificationMention"
            className="w-80"
            items={notificationSettingValues}
            valueExpr="value"
            displayExpr="text"
            minSearchLength={0}
            showClearButton={false}
            rules={validateRules.notificationMention}
          />
        </div>

        {/* 返信 */}
        <div className="flex items-center justify-between">
          <span className="crew-text-gray-4">
            {t('label.reply')}
            <CrewRequireDot />
          </span>
          <CrewSelectBoxField
            control={control}
            id="notificationReply"
            name="notificationReply"
            className="w-80"
            items={notificationSettingValues}
            valueExpr="value"
            displayExpr="text"
            minSearchLength={0}
            showClearButton={false}
            rules={validateRules.notificationReply}
          />
        </div>

        {/* メッセージ */}
        <div className="flex items-center justify-between">
          <span className="crew-text-gray-4">
            {t('label.message')}
            <CrewRequireDot />
          </span>
          <CrewSelectBoxField
            control={control}
            id="notificationMessage"
            name="notificationMessage"
            className="w-80"
            items={notificationSettingValues}
            valueExpr="value"
            displayExpr="text"
            minSearchLength={0}
            showClearButton={false}
            rules={validateRules.notificationMessage}
          />
        </div>

        {/* リアクション */}
        <div className="flex items-center justify-between">
          <span className="crew-text-gray-4">
            {t('label.reaction')}
            <CrewRequireDot />
          </span>
          <CrewSelectBoxField
            control={control}
            id="notificationReaction"
            name="notificationReaction"
            className="w-80"
            items={notificationSettingValues}
            valueExpr="value"
            displayExpr="text"
            minSearchLength={0}
            showClearButton={false}
            rules={validateRules.notificationReaction}
          />
        </div>

        {/* オフライン時のみ通知する */}
        <div className="w-80 ml-auto flex gap-x-2.5 items-center">
          <CrewCheckBoxField
            id="notificationMeOnlyWhenOffline"
            control={control}
            name="notificationMeOnlyWhenOffline"
            label={t('label.notificationMeOnlyWhenOffline')}
            className="crew-text-default"
          />
        </div>

        {/* バナーの許可 */}
        <div className="flex items-center justify-between">
          <span className="crew-text-gray-4">
            {t('label.allowBannerNotification')}
          </span>

          <div className="w-80 ml-auto flex gap-x-2.5 items-center">
            {!permission || permission === 'default' ? ( // permissionが未確認の場合のみdialogを表示することができる
              <>
                <span className="crew-text-default grow">
                  {t(`label.pushPermissions.default`)}
                </span>
                <CrewButton
                  type="normal"
                  stylingMode="outlined"
                  text={t('action.configureNotification')}
                  onClick={handlePermissionRequestButtonClick}
                />
              </>
            ) : (
              <div className="crew-text-default grow">
                <p>{t(`label.pushPermissions.${permission}`)}</p>
                <p>{t(`label.canChangeInBrowserSetting`)}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Action Save*/}
      <CrewButton
        onClick={handleSaveSettingButtonClick}
        text={t('action.toSave')}
        type="primary"
        className="mr-auto"
        disabled={isLoadingInsertOrReplaceSetting}
      />
    </form>
  )
})
