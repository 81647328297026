import { memo, useCallback, useState } from 'react'

import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { CrewErrorDialog } from 'components/elements/crewErrorDialog/crewErrorDialog'
import { useTranslation } from '@crew/modules/i18n'
import { useToast } from 'hooks/useToast'
import { useModal } from 'components/layouts/modal/useModal'
import { useProjectSettingPageContext } from 'features/project/components/projectSettingPage/useProjectSettingPage'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import {
  arrayMove,
  CrewSortableList,
} from 'components/elements/crewSortableList/crewSortableList'
import { useLazyGetTaskCategoriesQuery } from '@crew/apis/task/taskApis'
import { useParams } from 'react-router-dom'
import { EntityType } from '@crew/enums/domain'
import { TaskCategory } from '@crew/apis/task/models/getTaskCategories/response'
import { useEffectOnce } from '@dx-system/react-use'
import { useAppSelector } from 'states/hooks'
import { useValueChangeEffect } from '@crew/hooks'
import {
  SelectedTaskCategory,
  useTaskCategoryListGrid,
} from './useTaskCategoryListGrid'
import { TaskCategoryTableHeader } from './components/taskCategoryTableHeader/taskCategoryTableHeader'
import { TaskCategoryListItem } from './components/taskCategoryListItem/taskCategoryListItem'
import { TaskCategoryEntryDialog } from '../taskCategoryEntryDialog/taskCategoryEntryDialog'

export const TaskCategoryListGrid = memo(() => {
  const { projectId } = useParams()
  const {
    deleteTaskCategory,
    reorderTaskCategory,
    isLoadingDeleteTaskCategory,
  } = useTaskCategoryListGrid()

  const { t } = useTranslation()
  const toast = useToast()
  const { projectSubject } = useProjectSettingPageContext()
  const [showApiErrors] = useShowApiErrors()

  const [
    isProjectSettingTaskCategoryEntryDialogOpen,
    openProjectSettingTaskCategoryEntryDialog,
    closeProjectSettingTaskCategoryEntryDialog,
  ] = useModal()

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')

  const [isErrorDialogOpen, openErrorDialog, closeErrorDialog] = useModal()

  // エラーダイアログメッセージ
  const [errorMessage, setErrorMessage] = useState('')

  // Task category selected for deletion
  const [taskCategorySelected, setTaskCategorySelected] =
    useState<SelectedTaskCategory>()

  const [lazyGetTaskCategoriesQuery] = useLazyGetTaskCategoriesQuery()

  const [projectTaskCategories, setProjectTaskCategories] =
    useState<TaskCategory[]>()

  const projectSettingTaskCategoryEventMessage = useAppSelector(
    (state) => state.app.projectSettingTaskCategoryEventMessage
  )

  const fetchProjectTaskCategories = useCallback(async () => {
    if (!projectId) {
      return
    }

    const result = await lazyGetTaskCategoriesQuery({
      entityType: EntityType.Project,
      entityRecordId: projectId,
    })
    setProjectTaskCategories(result.data?.taskCategories)
  }, [lazyGetTaskCategoriesQuery, projectId])

  // 初期読み込み
  useEffectOnce(() => {
    fetchProjectTaskCategories()
  })

  useValueChangeEffect(
    () => {
      if (projectSettingTaskCategoryEventMessage) {
        fetchProjectTaskCategories()
      }
    },
    [fetchProjectTaskCategories, projectSettingTaskCategoryEventMessage],
    projectSettingTaskCategoryEventMessage
  )

  // 並び替え処理
  const handleReorder = useCallback(
    async (fromId: string, toId: string) => {
      // ちらつき防止のため、APIを呼ぶ前にUI上での並び替えを先行して行う
      setProjectTaskCategories((items) => {
        if (!items) {
          return items
        }

        const from = items.findIndex((item) => item.id === fromId)
        const to = items.findIndex((item) => item.id === toId)
        return arrayMove(items, from, to)
      })

      try {
        // Execute reorder event type process
        await reorderTaskCategory(fromId, toId)
      } catch (err) {
        setErrorMessage(t('message.general.errorMessage.reorder'))
        openErrorDialog()
      }

      // APIの実行結果にかかわらず再読み込みを行い、Backendに同期させる
      fetchProjectTaskCategories()
    },
    [fetchProjectTaskCategories, reorderTaskCategory, t, openErrorDialog]
  )

  //handle click task category on grid row set select task category id to state
  const handleTaskCategoryGridEditButtonClick = useCallback(
    (id: string, version: number) => {
      setTaskCategorySelected({
        taskCategoryId: id,
        version: version,
      })

      //open task category entry dialog
      openProjectSettingTaskCategoryEntryDialog()
    },
    [openProjectSettingTaskCategoryEntryDialog]
  )

  // Press Delete icon in grid
  const handleTaskCategoryGridDeleteButtonClick = useCallback(
    (id: string, version: number) => {
      setConfirmMessage(t('message.general.confirmMessage.delete'))
      openConfirmDialog()
      setTaskCategorySelected({
        taskCategoryId: id,
        version: version,
      })
    },
    [openConfirmDialog, t]
  )

  // 削除確認ダイアログ OKボタン
  const handleDeletePermitButtonClick = useCallback(async () => {
    try {
      if (taskCategorySelected) {
        // Execute delete task category process
        await deleteTaskCategory(taskCategorySelected)

        toast.success(t('message.project.taskCategoryDeleted'))
      }
    } catch (err) {
      showApiErrors(err)
    }
    closeConfirmDialog()
  }, [
    closeConfirmDialog,
    taskCategorySelected,
    deleteTaskCategory,
    toast,
    t,
    showApiErrors,
  ])

  return (
    <div className="h-full flex flex-col">
      {projectTaskCategories && (
        <CrewSortableList
          items={projectTaskCategories}
          onReorder={handleReorder}
          header={<TaskCategoryTableHeader />}
          renderItem={(item) => (
            <CrewSortableList.Item id={item.id}>
              <TaskCategoryListItem
                key={item.id}
                taskCategory={item}
                onEditButtonClick={() =>
                  handleTaskCategoryGridEditButtonClick(item.id, item.version)
                }
                onDeleteButtonClick={() =>
                  handleTaskCategoryGridDeleteButtonClick(item.id, item.version)
                }
                dragHandle={<CrewSortableList.DragHandle />}
              />
            </CrewSortableList.Item>
          )}
        />
      )}

      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleDeletePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
        permitButtonDisabled={isLoadingDeleteTaskCategory}
      />
      <CrewErrorDialog
        isOpen={isErrorDialogOpen}
        message={errorMessage}
        onCloseButtonClick={closeErrorDialog}
      />

      <TaskCategoryEntryDialog
        isEditMode={true}
        title={t('label.editTaskCategory') + ' - ' + projectSubject}
        isOpen={isProjectSettingTaskCategoryEntryDialogOpen}
        onClose={closeProjectSettingTaskCategoryEntryDialog}
        taskCategoryId={
          taskCategorySelected ? taskCategorySelected.taskCategoryId : null
        }
        version={taskCategorySelected ? taskCategorySelected.version : null}
      />
    </div>
  )
})
