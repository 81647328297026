import { memo } from 'react'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { TaskCategoryListToolbar } from './components/taskCategoryListToolbar/taskCategoryListToolbar'
import { TaskCategoryListGrid } from './components/taskCategoryListGrid/taskCategoryListGrid'

export const ProjectSettingTaskCategoryList = memo(() => {
  return (
    <div className="flex flex-col gap-2.5">
      <CrewErrorBoundary>
        <TaskCategoryListToolbar />
      </CrewErrorBoundary>

      <CrewErrorBoundary>
        <TaskCategoryListGrid />
      </CrewErrorBoundary>
    </div>
  )
})
