import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewErrorSummary } from 'components/forms/crewErrorSummary'
import { CrewTagBoxField } from 'components/forms/crewTagBoxField'
import { SEARCH_TIMEOUT_MSEC } from '@crew/configs/constants'
import { memo } from 'react'
import { useFileListTagsEntryForm } from './useFileListTagsEntryForm'
import { useCallback, useMemo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { ComponentCallbackHandler } from '@crew/utils'
import { FormValues, Tag } from './useFileListTagsEntryForm'
export type FileListTagsEntryFormProps = {
  fileIds: string[]
  projectIds: string[]
  onCancel: () => void
}

export const FileListTagsEntryForm = memo(
  (props: FileListTagsEntryFormProps) => {
    const {
      formState,
      control,
      handleSubmit,
      setError,

      tagDataSource,
      insertFileTags,
      isLoadingInsertFileTag,
    } = useFileListTagsEntryForm(props)

    const { t } = useTranslation()
    const toast = useToast()

    const canSend = useMemo(
      // fromState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
      () =>
        Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
      // formStateはproxyなのでformState自体をlistenする必要がある
      // https://react-hook-form.com/api/useform/formstate
      [formState]
    )

    const handleAddCustomTagValue = useCallback<
      ComponentCallbackHandler<typeof CrewTagBoxField, 'onCustomItemCreating'>
    >(
      (args) => {
        if (!args.text) {
          return
        }

        let newTag: Tag = {
          id: 0, //バックエンドで採番
          name: args.text,
        }

        // カスタム値の登録（{ id: tagId | undefined, name: 入力値 }）
        // カスタムデータソースのinsert内でAPIによるtagIdの取得を試みる
        args.customItem = args.component
          .getDataSource()
          .store()
          .insert(newTag)
          .then((savedTag: Tag) => {
            newTag = savedTag //レスポンスで差し替え
            return tagDataSource.load()
          })
          .then(() => newTag)
      },
      [tagDataSource]
    )

    const [showApiErrors] = useShowApiErrorsWithForm(setError)

    // handle submit form and call api register event
    const handleSubmitButtonClick = useCallback(() => {
      const onSubmit = async (data: FormValues) => {
        try {
          await insertFileTags(props.fileIds, data.tagIds ?? [])

          toast.success(t('message.file.tagUpdated'))
          props.onCancel()
        } catch (err) {
          showApiErrors(err)
        }
      }
      handleSubmit(onSubmit)()
    }, [handleSubmit, insertFileTags, props, showApiErrors, t, toast])

    return (
      <form className="flex flex-col gap-5 h-full">
        {/* モーダルの最小幅を制限し、画面の各入力項目が正しく表示されるようにする */}
        <div className="min-w-[500px]">
          <CrewTagBoxField
            id="tagIds"
            name="tagIds"
            control={control}
            displayExpr="name"
            valueExpr="id"
            dataSource={tagDataSource}
            searchEnabled={true}
            searchMode="contains"
            searchExpr="name"
            searchTimeout={SEARCH_TIMEOUT_MSEC}
            minSearchLength={0}
            labelMode="hidden"
            acceptCustomValue={true}
            onCustomItemCreating={handleAddCustomTagValue}
          />
        </div>
        <CrewErrorSummary formState={formState} />
        <div className="flex justify-between items-center">
          <div className="ml-auto flex gap-x-5">
            <CrewButton
              text={t('action.register')}
              type="primary"
              disabled={!canSend || isLoadingInsertFileTag}
              onClick={handleSubmitButtonClick}
            />
            <CrewButton
              text={t('action.cancel')}
              type="normal"
              stylingMode="outlined"
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    )
  }
)
