import { UpdateKanbanTaskRequest } from '@crew/apis/task/models/updateKanbanTask/request'
import { useUpdateKanbanTaskMutation } from '@crew/apis/task/taskApis'
import { KanbanUpdatableColumn } from '@crew/enums/domain'
import { Task } from '@crew/models/domain'
import { NotifyEventType } from 'enums/app'
import {
  notifyProjectTaskEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { useCallback } from 'react'
import { useAppDispatch } from 'states/hooks'

export const useProjectDetailTaskListKanban = () => {
  const dispatch = useAppDispatch()

  const [updateKanbanTaskMutation] = useUpdateKanbanTaskMutation()

  // Function to update the kanban task
  const updateKanbanTask = useCallback(
    async (
      taskId: string,
      columnName: KanbanUpdatableColumn,
      value: string,
      version: number
    ) => {
      const payload: UpdateKanbanTaskRequest = {
        task: {
          id: taskId,
          columnName,
          value,
          version,
        },
      }

      // Call API to update kanban task
      await updateKanbanTaskMutation(payload).unwrap()

      const objectEventMessage: ObjectEventMessage<Task> = {
        eventType: NotifyEventType.Updated,
        id: payload.task.id,
        object: payload.task ?? undefined,
      }

      dispatch(notifyProjectTaskEvent(objectEventMessage))
    },
    [dispatch, updateKanbanTaskMutation]
  )

  return {
    updateKanbanTask,
  }
}
