import { FC, memo } from 'react'
import classNames from 'classnames'
import { useContentShareState } from 'modules/amazon-chime-sdk-component-library-devextreme'

type CrewShowNameAttendedProps = {
  name: string
}

export const CrewShowNameAttended: FC<CrewShowNameAttendedProps> = memo(
  (props) => {
    // 画面共有状態に応じて表示切替を行うため画面共有しているAttendeeIdを取得する
    const { sharingAttendeeId } = useContentShareState()

    return (
      // To display at the top left of the participant monitor within the web conference panel using absolute.
      <div
        className={classNames(
          ' text-crew-gray-200 rounded-md absolute top-1 left-1 truncate',
          sharingAttendeeId !== null
            ? 'px-1 py-0.5 text-xs bg-crew-gray-900/40'
            : 'backdrop-blur-md px-2 py-1 bg-crew-gray-900/60'
        )}
      >
        {props.name}
      </div>
    )
  }
)
