import { ContextMenuItems } from 'enums/app'
import { useDataSource } from 'hooks/dataSource/useDataSource'
import { useMemo } from 'react'
import { useTranslation } from '@crew/modules/i18n'

export const useTaskDetailCommentItemActionMenu = (
  isHideContextMenuItemDelete: boolean | undefined
) => {
  const { t } = useTranslation()

  // ContextMenuに格納するuseDataSource用のデータ
  const contextMenuData = useMemo(() => {
    return Object.values(ContextMenuItems)

      .filter((item) => {
        if (
          // Hide delete menu item if isHideContextMenuItemDelete is true
          (isHideContextMenuItemDelete &&
            item.action === ContextMenuItems.Delete.action) ||
          // Hide copy menu item
          (item.action !== ContextMenuItems.Delete.action &&
            item.action !== ContextMenuItems.Edit.action)
        ) {
          return false
        }

        return true
      })
      .map((item) => {
        return { action: item.action, label: t(item.label) }
      })
  }, [isHideContextMenuItemDelete, t])

  // 実際にContextMenuに格納するDataSource定義
  const contextMenuDataSource = useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        return contextMenuData
      },
    }),
    [contextMenuData]
  )

  return {
    contextMenuDataSource,
  }
}
