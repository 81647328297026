var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback, useState } from 'react';
import { connectWebsocket, } from '@crew/apis/websocket/websocketApis';
import { WEBSOCKET_UNSUBSCRIBE_DELAY_MSEC, WEBSOCKET_CONNECTED_POLLING_DELAY_MSEC, } from '@crew/configs/constants';
import { IncomingControlMessageType, PubSubEventDataType, PubSubEventTargetType, } from '@crew/enums/domain';
import { createUninitializedContext } from '@crew/utils/context';
import { isIncomingAsyncMessage, isIncomingControlMessage, } from './models/messages/incoming';
import _ from 'lodash';
const { context: websocketContext, useInitializedContext: useWebsocketContext, } = createUninitializedContext();
export { useWebsocketContext };
/**
 * subscribeするリソースの情報から接続先を識別する値を生成する
 * @param targetType subscribeするリソースの種類
 * @param channel subscribeするチャンネル(リソースID)。ログイン中userの場合(type='user')はundefined
 * @param dataType subscribeするチャンネルのデータタイプ
 * @returns
 */
const toTarget = (targetType, targetId, dataType) => {
    const actualTargetId = targetType === PubSubEventTargetType.User &&
        dataType !== PubSubEventDataType.Presence
        ? '' // userかつpresenceでない時はtargetIdは空白固定
        : targetId;
    if (actualTargetId === undefined) {
        throw new Error(`[toTarget] channel is empty. type:${targetType} targetId:${targetId} dataType:${dataType}`);
    }
    return `${targetType}_${actualTargetId}_${dataType}`;
};
/**
 * subscribeターゲット毎の受信イベントハンドラの情報
 */
const targetHandlers = {};
/**
 * 唯一無二の非同期通信インスタンス
 */
let conn = undefined;
/**
 * WebSocketの接続状態
 */
export const WebsocketStatus = {
    running: 'running',
    paused: 'paused',
};
/**
 * WebSocketの接続状態。初期値はrunning。エラー時や明示的に停止した場合はpausedになる
 */
let status = WebsocketStatus.running;
/**
 * サーバにメッセージを送信する
 * @param message 送信メッセージ
 */
const sendMessage = (message) => {
    conn === null || conn === void 0 ? void 0 : conn.send(JSON.stringify(message));
};
/**
 * 以前の接続先を再Subscribeする必要があるかどうか判定する
 * @returns
 */
const needsReSubscribe = () => {
    return conn === undefined && Object.keys(targetHandlers).length > 0;
};
/**
 * 以前の接続先を再Subscribeする
 */
const reSubscribeIfNeeded = () => {
    //以前subscribeしていたハンドラ分のsbusribeメッセージをバックエンドに送信する
    Object.entries(targetHandlers).forEach(([target, targetReceiver]) => {
        console.info(`[useWebsocketProvider] Re-subscribe. target:${target}`);
        // subscribeメッセージを送信する
        const subscribeMessage = {
            type: 'subscribe',
            payload: {
                targetType: targetReceiver.targetType,
                targetId: targetReceiver.targetId,
                dataType: targetReceiver.dataType,
            },
        };
        sendMessage(subscribeMessage);
    });
};
/**
 * 非同期通信を提供するプロバイダの内部ロジック
 * @returns
 */
const useWebsocketProvider = () => {
    /**
     * subscribe中かどうかを保持するフラグのマップ。更新を検出するためstateで管理する
     */
    const [isSubscribingMap, setSubscribingMap] = useState({});
    /**
     * イベントハンドラがsubscribe状態か確認する関数の実体
     */
    const isSubscribing = useCallback((messageType) => {
        var _a;
        const [target] = (_a = Object.entries(targetHandlers).find(([_, targetHandler]) => 
        // TODO: CREW-8120 any型を使わない方法を検討する
        // https://break-tmc.atlassian.net/browse/CREW-8120
        Object.keys(targetHandler.handlers).includes(messageType))) !== null && _a !== void 0 ? _a : [];
        return target && isSubscribingMap[target] ? true : false;
    }, [isSubscribingMap]);
    /**
     * サーバからメッセージが来た場合に最初に呼び出すイベントハンドラ。subscribeで指定したイベントハンドラにメッセージを配信する
     * @param target 受信
     * @param message
     * @returns
     */
    // setSubscribingMapを利用するためカスタムhookの中で定義する
    const handleRecvMessage = useCallback((message) => {
        var _a, _b;
        if (isIncomingAsyncMessage(message)) {
            // データ系メッセージ。subscribeしたハンドラに配信する
            const target = toTarget(message.targetType, message.targetId, message.dataType);
            // メッセージの情報を基にsubscribeしているハンドラの情報
            const targetHandler = targetHandlers[target];
            if (targetHandler === undefined) {
                console.warn(`[useWebsocketProvider] recv message from unknown target:${target}.`);
                return;
            }
            // 各ハンドラにメッセージを配信する
            // TODO: CREW-8120 any型を使わない方法を検討する
            // https://break-tmc.atlassian.net/browse/CREW-8120
            (_a = Object.keys(targetHandler === null || targetHandler === void 0 ? void 0 : targetHandler.handlers)) === null || _a === void 0 ? void 0 : _a.forEach((messageType) => {
                if (targetHandler === null || targetHandler === void 0 ? void 0 : targetHandler.handlers[messageType]) {
                    targetHandler.handlers[messageType].handle(message.type, message.payload);
                }
            });
        }
        else if (isIncomingControlMessage(message)) {
            // 制御系メッセージ。Provider内部の処理に利用する
            if (message.type === IncomingControlMessageType.Success &&
                message.targetType) {
                // subscribe成功、subscribingフラグを立てる
                const target = toTarget(message.targetType, message.targetId, message.dataType);
                setSubscribingMap((v) => (Object.assign(Object.assign({}, v), { [target]: true })));
                // 各チャンネルのサブスクライブ状態をstateへ反映するため、呼び出し元のイベントハンドラを呼び出す
                (_b = targetHandlers[target]) === null || _b === void 0 ? void 0 : _b.controlMessageHandler(message.type);
            }
        }
    }, []);
    /**
     * connが未接続なら接続を行い、完了まで待ち、必要ならsubscribeをやり直す。接続済なら何もしない
     */
    const ensureConnection = useCallback(() => new Promise((resolve) => {
        if (conn) {
            // connectWebsocket済、isConnectedなら即resolveし、そうでなかったら接続完了までpollingで待つ。
            if (conn.isConnected()) {
                // 接続済み
                console.info(`[useWebsocketProvider] Connection already completed.`);
                // 次の処理へ
                resolve();
            }
            else {
                const pollingTimer = setInterval(() => {
                    if (conn === null || conn === void 0 ? void 0 : conn.isConnected()) {
                        clearInterval(pollingTimer);
                        // 接続完了
                        console.info(`[useWebsocketProvider] Connection complete. triggered by other.`);
                        // 次の処理へ
                        resolve();
                    }
                }, WEBSOCKET_CONNECTED_POLLING_DELAY_MSEC);
            }
        }
        else {
            // connがない=未接続なので接続開始し、接続完了まで待つ。既にsubscribe済のハンドラがあれば再subscribeする
            console.info(`[useWebsocketProvider] Start connection...}`);
            conn = connectWebsocket({
                // 接続完了イベント
                onConnected: () => {
                    console.info(`[useWebsocketProvider] Connection complete. triggered by self.`);
                    reSubscribeIfNeeded();
                    // TODO: CREW-8010 リトライ系の表示をしていたらそれを除去する
                    // https://break-tmc.atlassian.net/browse/CREW-8010
                    // 次の処理へ
                    resolve();
                },
                // 自動再接続開始イベント
                onReconnecting: (tryCount, intervalMsec) => {
                    console.warn(`[useWebsocketProvider] Connection retrying... tryCount:${tryCount}, intervalMsec:${intervalMsec}`);
                    // TODO: CREW-8010 今からリトライする旨の表示を行う
                    // https://break-tmc.atlassian.net/browse/CREW-8010
                },
                // メッセージ受信イベント
                onRecvMessage: handleRecvMessage,
                // 接続終了イベント
                onDisconnected: (isByError) => {
                    console.info(`[useWebsocketProvider] Disconnected. isByError:${isByError}`);
                    conn = undefined;
                    if (isByError) {
                        // エラーによる接続終了の場合は未接続状態にする
                        status = WebsocketStatus.paused;
                        // TODO: CREW-8010 リトライエラーの表示を行う
                        // https://break-tmc.atlassian.net/browse/CREW-8010
                    }
                },
            });
        }
    }), [handleRecvMessage]);
    /**
     * 指定したリソースに紐付くメッセージをsubscribeする関数の実体
     */
    const subscribe = useCallback((subscriptionId, targetType, targetId, dataType, messageType, receiver, controlMessageReceiver, addSubscriptionHandler, updateSubscribeStateSubscribing) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        if (subscriptionId.description === undefined) {
            // symbolに文字列を指定していない場合はエラー扱いとする
            return;
        }
        if (targetType !== PubSubEventTargetType.User && targetId == null) {
            // typeがuserでないのにentityIdがnullなのは実装ミスなので例外を投げる
            const message = `[useWebsocketProvider] listen target is not user, but entityId is null`;
            console.error(message);
            throw new Error(message);
        }
        const target = toTarget(targetType, targetId, dataType);
        console.info(`[useWebsocketProvider] Start subscribe. target:${target} messageType:${messageType}`);
        // 対象チャンネルのサブスクライブ状態を「処理中」に更新する
        // もしも既に「サブスクライブ済み」となっていてここの処理が実行されても、reducer側で「処理中」には戻らないようになっている
        updateSubscribeStateSubscribing({
            targetType,
            targetId,
            dataType,
        });
        // 状態がrunningの場合、接続処理を行う
        if (status === WebsocketStatus.running) {
            yield ensureConnection();
        }
        // 指定したsubscribe対象へのイベントハンドラを登録する
        if (!targetHandlers[target]) {
            // targetHandlerが無い場合は新たに作成し、subscribeする
            console.info(`[useWebsocketProvider] Newer connection initialize. target:${target}`);
            // targetがuserかつdataTypeがpresenceでない場合はtargetIdはundefined固定。そうでない場合はtargetIdをそのまま使う
            const actualTargetId = targetType === PubSubEventTargetType.User &&
                dataType !== PubSubEventDataType.Presence
                ? undefined
                : targetId;
            targetHandlers[target] = {
                targetType: targetType,
                targetId: actualTargetId,
                dataType: dataType,
                handlers: {},
                controlMessageHandler: controlMessageReceiver,
                unsubscribeDelayTimer: undefined,
            };
            // 状態がrunningの場合、即座にsubscribeメッセージを送信する。pausedの場合は接続後のreSubscribeIfNeededでメッセージが送信させる
            if (status === WebsocketStatus.running) {
                const subscribeMessage = {
                    type: 'subscribe',
                    payload: {
                        targetType: targetType,
                        targetId: actualTargetId,
                        dataType: dataType,
                    },
                };
                sendMessage(subscribeMessage);
            }
        }
        else {
            // 既にsubscribe済だがtargetのunsubscribe timerが起動されていたらキャンセルする
            if (targetHandlers[target].unsubscribeDelayTimer) {
                console.info(`[useWebsocketProvider] Cancel unsubscribe. Configure new handler. target:${target}`);
                clearTimeout(targetHandlers[target].unsubscribeDelayTimer);
                targetHandlers[target].unsubscribeDelayTimer = undefined;
            }
        }
        // イベントハンドラに紐づいているコンポーネントのsymbol一覧
        const orgSubscriptionIds = (_b = (_a = targetHandlers[target].handlers[messageType]) === null || _a === void 0 ? void 0 : _a.subscriptionIds) !== null && _b !== void 0 ? _b : [];
        // イベントハンドラを登録する
        targetHandlers[target].handlers[messageType] = {
            handle: receiver,
            subscriptionIds: [...orgSubscriptionIds, subscriptionId],
        };
        // stateへハンドラを登録する
        addSubscriptionHandler({
            targetType,
            dataType,
            messageType,
            subscriptionId: subscriptionId.description,
            id: targetId,
        });
    }), [ensureConnection]);
    /**
     * unsubscribeする関数の実体
     */
    const unsubscribe = useCallback((messageType, subscriptionId, deleteSubscriptionHandler, updateSubscribeStateNotSubscribed) => __awaiter(void 0, void 0, void 0, function* () {
        // 指定したmessageTypeで識別されるイベントハンドラを削除する
        Object.entries(targetHandlers).forEach(([target, targetHandler]) => {
            var _a;
            if (!_.includes((_a = targetHandler.handlers[messageType]) === null || _a === void 0 ? void 0 : _a.subscriptionIds, subscriptionId)) {
                // 指定したsymbolで識別されるコンポーネントが存在しないため、何もしない
                return;
            }
            console.info(`[useWebsocketProvider] Start unsubscribe. targetType:${targetHandler.targetType} targetId:${targetHandler.targetId} dataType:${targetHandler.dataType}} messageType:${messageType}`);
            // コンポーネントsymbolを配列から削除
            _.pull(targetHandler.handlers[messageType].subscriptionIds, subscriptionId);
            // 呼び出し元のコンポーネントが残っていない場合は、イベントハンドラ自体も削除する
            if (targetHandler.handlers[messageType].subscriptionIds.length === 0) {
                // イベントハンドラを削除
                delete targetHandler.handlers[messageType];
                // stateからハンドラを削除
                deleteSubscriptionHandler({
                    targetType: targetHandler.targetType,
                    dataType: targetHandler.dataType,
                    messageType,
                    id: targetHandler.targetId,
                });
            }
            const restHandlers = Object.keys(targetHandler.handlers).length;
            // 削除したのが最後のイベントハンドラだった場合、unsubscribeしてtargetHandler自体を削除する
            // TODO: CREW-8120 any型を使わない方法を検討する
            // https://break-tmc.atlassian.net/browse/CREW-8120
            if (restHandlers === 0) {
                console.info(`[useWebsocketProvider] Last handler was removed. unsubscribe from server ready...`);
                // 連続で同じtargetにunsubscribe・subscribeされた場合に連続してリクエストが発行されるのを避けるため、実際のunsubscribeを遅延させる。
                // 再度subscribeされた場合はunsubscribeをキャンセルする。
                targetHandlers[target].unsubscribeDelayTimer = setTimeout(() => {
                    // unsubscribeメッセージを送信する
                    const subscribeMessage = {
                        type: 'unsubscribe',
                        payload: {
                            targetType: targetHandler.targetType,
                            targetId: targetHandler.targetId,
                            dataType: targetHandler.dataType,
                        },
                    };
                    sendMessage(subscribeMessage);
                    delete targetHandlers[target];
                    // チャンネルのサブスクライブ状態を「未サブスクライブ」に更新する
                    updateSubscribeStateNotSubscribed({
                        targetType: targetHandler.targetType,
                        targetId: targetHandler.targetId,
                        dataType: targetHandler.dataType,
                    });
                    console.info(`[useWebsocketProvider] unsubscribed. target:${target}`);
                    // targetHandlersが空になったらconnを切断する
                    if (Object.keys(targetHandlers).length === 0) {
                        console.info(`[useWebsocketProvider] All handlers were removed. disconnect from server.`);
                        conn === null || conn === void 0 ? void 0 : conn.close();
                        conn = undefined;
                    }
                }, WEBSOCKET_UNSUBSCRIBE_DELAY_MSEC);
            }
            else {
                console.info(`[useWebsocketProvider] rest handlers. do not unsubscribe from server.`);
            }
        });
    }), []);
    /**
     * サーバとの接続を強制的に切断する。未接続なら何もしない。
     */
    const forceDisconnect = useCallback(() => {
        if (conn !== undefined) {
            // connが存在する=接続中なので強制切断する
            console.warn(`[useWebsocketProvider] Force disconnect.`);
            conn.close();
            conn = undefined;
        }
    }, []);
    /**
     * websocket経由での受信を一時停止する。
     * 一次停止中は、バックエンドから送られるメッセージは全て破棄される代わりに、ネットワークが切断されてもエラーにならない。
     * アプリがバックグラウンドになった場合などに利用する。
     */
    const pause = useCallback(() => {
        // 状態をpausedに変更
        status = WebsocketStatus.paused;
        // 接続それ自体は切断する
        if (conn) {
            conn.close();
            conn = undefined;
        }
    }, []);
    /**
     * websocket経由での受信を再開する
     */
    const resume = useCallback(() => {
        // 状態をrunningに変更
        status = WebsocketStatus.running;
        // 以前にsubscribeしていたハンドラがある場合、必要なら再接続と再subscribeを行う。
        // 再subscribeが不要な場合はなにもしない。次のsubscribe時に接続も行われる。
        if (needsReSubscribe()) {
            ensureConnection();
        }
    }, [ensureConnection]);
    return {
        subscribe,
        unsubscribe,
        isSubscribing,
        forceDisconnect,
        pause,
        resume,
    };
};
/**
 * 非同期接続を提供するプロバイダ
 */
export const WebsocketProvider = memo((props) => {
    const { subscribe, unsubscribe, isSubscribing, forceDisconnect, pause, resume, } = useWebsocketProvider();
    return (_jsx(websocketContext.Provider, { value: {
            subscribe,
            unsubscribe,
            isSubscribing,
            forceDisconnect,
            pause,
            resume,
        }, children: props.children }));
});
