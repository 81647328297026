import { memo, FC, useCallback } from 'react'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'
import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { useTranslation } from '@crew/modules/i18n'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useMeetingManager } from 'modules/amazon-chime-sdk-component-library-devextreme'
import { useAudioVideo } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/AudioVideoProvider'
import {
  LIFE_TIME_MS,
  TOPIC_MEETING_PERMIT_USER,
  TOPIC_MEETING_REJECT_USER,
} from 'configs/constants'

type EventDetailWebMeetingWaitingAttendeesProps = {
  chimeAttendeeId: string
  displayName: string
  attendeeUserId: string
}

export const EventDetailWebMeetingWaitingAttendeeItem: FC<EventDetailWebMeetingWaitingAttendeesProps> =
  memo((props) => {
    const { t } = useTranslation()
    const { meetingId } = useMeetingManager()
    const audioVideo = useAudioVideo()

    // 参加許可ボタンクリック時の処理
    const handlePermitButtonClick = useCallback(() => {
      if (!audioVideo || !meetingId) return

      // Send realtime message that host permit user to join meeting
      audioVideo.realtimeSendDataMessage(
        TOPIC_MEETING_PERMIT_USER,
        JSON.stringify({ meetingId, userId: props.chimeAttendeeId }),
        LIFE_TIME_MS
      )
    }, [audioVideo, meetingId, props.chimeAttendeeId])

    // 参加拒否ボタンクリック時の処理
    const handleRejectButtonClick = useCallback(() => {
      if (!audioVideo || !meetingId) return

      // Send realtime message that host reject user to join meeting
      audioVideo.realtimeSendDataMessage(
        TOPIC_MEETING_REJECT_USER,
        JSON.stringify({ meetingId, userId: props.chimeAttendeeId }),
        LIFE_TIME_MS
      )
    }, [audioVideo, meetingId, props.chimeAttendeeId])

    return (
      <>
        <div className="flex justify-between items-center gap-2.5">
          <div className="min-w-0 flex flex-row items-center gap-0.5">
            <div className="min-w-0 grow flex flex-row items-center gap-2.5">
              <CrewUserAvatar
                userId={props.attendeeUserId}
                displayName={props.displayName}
                size={CrewAvatarSize.xs}
                showLabel={false}
                cacheValue={props.attendeeUserId}
                disableClick
              />
              <div className="flex flex-col flex-1">
                <p className="line-clamp-1">{props.displayName}</p>
                <p className="crew-text-gray-4 text-xs line-clamp-1">
                  {t('label.askToJoinMeeting')}
                </p>
              </div>
            </div>
          </div>

          <div className="flex gap-x-1 items-center">
            <CrewButton
              type="normal"
              text={t('label.permit')}
              stylingMode="outlined"
              size="sm"
              onClick={handlePermitButtonClick}
            />
            <CrewButton
              type="normal"
              text={t('label.reject')}
              stylingMode="outlined"
              size="sm"
              onClick={handleRejectButtonClick}
            />
          </div>
        </div>
      </>
    )
  })
