/* eslint-disable @typescript-eslint/no-redeclare */

/**
 * localStorageで使うキー一覧
 */
export const LocalStorageKeys = {
  LoggedInUser: 'loggedInUser',
  Theme: 'theme',
  LocaleName: 'localeName',
  // FIXME: CREW-6686の議論にてS3にアップロードで対応となったが、仕様が決まるまでBase64データをローカルストレージに一時的に保存
  //        https://break-tmc.atlassian.net/browse/CREW-6686?focusedCommentId=13364
  MeetingBackgroundReplacementBase64: 'meetingBackgroundReplacementBase64',
} as const
export type LocalStorageKeys =
  (typeof LocalStorageKeys)[keyof typeof LocalStorageKeys]

/**
 * 日付フォーマット(CrewDatePicker用)
 */
export const DatePickerDateFormat = {
  YYYYMMDD: 'yyyy/MM/dd',
  YYYYMMDDHHmm: 'yyyy/MM/dd HH:mm',
}
