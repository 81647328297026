import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { useCrewAttachmentThumbnailItem } from './useCrewAttachmentThumbnailItem'
import {
  CrewRetryImage,
  CrewRetryImageRetryType,
} from '../../../crewRetryImage/crewRetryImage'
import classNames from 'classnames'
import { useTranslation } from '@crew/modules/i18n'
import { useToast } from 'hooks/useToast'
import { useModal } from 'components/layouts/modal/useModal'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { File, FileHistory } from '@crew/models/domain'
import { isFileHistory } from '@crew/utils'
import { useFileSignedUrl } from 'hooks/useFileSignedUrl'
import { isVideoFile } from '@crew/utils/chat'
import CloseCircle from '~icons/mdi/close-circle'
import PlayCircleOutline from '~icons/material-symbols/play-circle-outline'

export type CrewAttachmentThumbnailItemProps = {
  showDeleteButton: boolean
  onDeleteAttachmentItem: (fileId: string) => void // CrewAttachments側のファイル表記削除イベント
  onOpenImagePreview: () => void
  attachment: File | FileHistory
}

export const CrewAttachmentThumbnailItem = memo(
  (props: CrewAttachmentThumbnailItemProps) => {
    const { deleteAttachment, isLoadingDeleteFileHistory } =
      useCrewAttachmentThumbnailItem(props.attachment)
    const [generalShowApiErrors] = useShowApiErrors()
    const { t } = useTranslation()
    const toast = useToast()
    const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
      useModal()
    const {
      getFileSignedUrlForThumbnail,
      getFileHistorySignedUrlForThumbnail,
    } = useFileSignedUrl()

    // 確認ダイアログメッセージ
    const [confirmMessage, setConfirmMessage] = useState('')

    // Action when delete button is clicked
    const handleDeleteButtonClick = useCallback(() => {
      // 確認ダイアログの表示（処理は確認ダイアログのOKボタン押下時に行う）
      setConfirmMessage(t('message.general.confirmMessage.delete'))
      openConfirmDialog()
    }, [openConfirmDialog, t])

    // 削除確認ダイアログの許可ボタンクリック時のイベントハンドラ
    const handleDeletePermitButtonClick = useCallback(async () => {
      try {
        // ファイル削除
        await deleteAttachment()

        // ファイル削除に成功した旨のトーストを表示する
        toast.success(t('message.file.deleteSuccess'))

        // 削除したファイルの表記を消す
        props.onDeleteAttachmentItem(props.attachment.id)
      } catch (err) {
        generalShowApiErrors(err)
      }
      // 確認ダイアログを閉じる
      closeConfirmDialog()
    }, [
      closeConfirmDialog,
      deleteAttachment,
      generalShowApiErrors,
      props,
      t,
      toast,
    ])

    const [fileDownloadUrl, setFileDownloadUrl] = useState('')

    // サムネイル取得URLの生成
    const generateThumbnailUrl = useCallback(async () => {
      let url = ''
      if (isFileHistory(props.attachment)) {
        // FileHistoryの場合
        url = await getFileHistorySignedUrlForThumbnail(props.attachment.id)
      } else {
        // Fileの場合
        url = await getFileSignedUrlForThumbnail(props.attachment.id)
      }
      return url
    }, [
      getFileHistorySignedUrlForThumbnail,
      getFileSignedUrlForThumbnail,
      props.attachment,
    ])

    // ファイルのサムネイル取得用URLを生成し、子コンポーネントに渡すstateにセットする
    useEffect(() => {
      const setThumbnailUrl = async () => {
        const url = await generateThumbnailUrl()
        setFileDownloadUrl(url)
      }

      setThumbnailUrl()
    }, [generateThumbnailUrl])

    const isVideoThumbnail = useMemo(() => {
      return isVideoFile(props.attachment.fileName)
    }, [props.attachment.fileName])

    return (
      <>
        {/*aspect は4:3で固定。*/}
        {/*表示領域のサイズは横幅を基準に計算させる。*/}
        {/*アスペクト比を指定した場合、横幅 / aspect = 高さ、で高さが決定するので、高さを明示的に指定しても計算によって上書きされてしまう。*/}
        {/*そのため、仕様では高さが指定されているが、実装上は指示高さとaspectから計算した横幅を使用する。*/}
        {/*またコンテンツのサイズを指定しつつその外側にmarginを空けるため、横幅の最大値を指定する外側のdivと、サイズ指定しつつmarginを付ける内側のdivとに適用するclassを分ける*/}
        <div
          // 最大幅を指定するためのdiv
          className={classNames(
            // 横は最小3列で表示するため、1列あたりの最大幅を33%とする。
            'max-w-[33%]',
            // 他の画像との間に隙間を空ける
            'p-1'
          )}
        >
          <div
            // 外側にmarginをつけつつ、コンテンツを仕様に沿って表示するためのdiv
            className={classNames(
              // 外側のdivで計算される最大横幅を引き継ぐ
              'max-w-full',
              // 高さの最大(240px≒15rem)*(4/3) = 320px≒15remが横幅。最大幅が↑で相対値で指定されているため、表示領域に応じて縮小される。
              'w-[20rem]',
              // 高さの最小(80px≒5rem)*(4/3) = 106.667px≒6.667remが横の最小幅。これ以上小さくなることはない
              'min-w-[6.667rem]',
              // aspectを指定することで、横幅に応じた縦幅を計算させる
              'aspect-[4/3]',
              // 枠線の指定。この分だけ↑で計算されたサイズより大きくなる(つまりコンテンツ部分は指定サイズどおりになる)
              'border crew-border-gray',
              // 削除ボタンをabsoluteで表示するため、relativeで座標基準をこのdivとする
              'relative',
              // 角丸その他の設定
              'rounded-md cursor-pointer'
            )}
          >
            <div
              className="w-full h-full overflow-hidden"
              onClick={props.onOpenImagePreview}
            >
              <CrewRetryImage
                src={fileDownloadUrl}
                alt={props.attachment.name}
                retryType={CrewRetryImageRetryType.GenerateNewUrl}
                onGenerateNewUrl={generateThumbnailUrl}
              />
            </div>

            {/* 削除アイコン（ボタン） */}
            {props.showDeleteButton && (
              <div
                // サムネイル内の右上にアイコンを位置させるためabsoluteで位置を指定する
                className="absolute right-1 top-1 w-6 h-6 rounded-full bg-crew-white text-crew-gray-900"
              >
                <CloseCircle
                  width="100%"
                  height="100%"
                  onClick={handleDeleteButtonClick}
                />
              </div>
            )}

            {/** Show Play icon for Video thumbail */}
            {isVideoThumbnail && (
              <div
                className="absolute crew-text-gray-4 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                onClick={props.onOpenImagePreview}
              >
                <PlayCircleOutline width={48} height={48} />
              </div>
            )}
          </div>
        </div>

        {/* 削除確認ダイアログ */}
        <CrewConfirmDialog
          isOpen={isConfirmDialogOpen}
          message={confirmMessage}
          onPermitButtonClick={handleDeletePermitButtonClick}
          onCancelButtonClick={closeConfirmDialog}
          permitButtonDisabled={isLoadingDeleteFileHistory}
        />
      </>
    )
  }
)
