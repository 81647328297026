import { BulkInviteUserRequest } from '@crew/apis/tenantSetting/models/bulkInviteUser/request'
import { TenantSettingMember } from '@crew/apis/tenantSetting/models/getTenantSettingMembers/response'
import {
  useBulkInviteUserMutation,
  useVerifyBulkInvitationMutation,
} from '@crew/apis/tenantSetting/tenantSettingApis'
import { NotifyEventType } from 'enums/app'
import {
  ObjectEventMessage,
  notifyTenantSettingUserEventMessageEvent,
} from 'features/app/states/appSlice'
import { useCallback } from 'react'
import { useAppDispatch } from 'states/hooks'

export const useTenantUserBulkInviteDialog = () => {
  const dispatch = useAppDispatch()

  const [verifyBulkInvitationMutation] = useVerifyBulkInvitationMutation()
  const [bulkInviteUserMutation, { isLoading: isLoadingBulkInviteUser }] =
    useBulkInviteUserMutation()

  // Verify bulk invitation
  const verifyBulkInvitation = useCallback(
    async (file: File) => {
      const result = await verifyBulkInvitationMutation({ file: file }).unwrap()

      return result
    },
    [verifyBulkInvitationMutation]
  )

  // Bulk invite user
  const bulkInviteUser = useCallback(
    async (data: BulkInviteUserRequest) => {
      await bulkInviteUserMutation(data).unwrap()
      // mutation操作の後は、レスポンスを通知する
      const objectEventMessage: ObjectEventMessage<TenantSettingMember> = {
        eventType: NotifyEventType.Inserted,
        id: '',
        object: undefined,
      }
      //trigger event refresh user list
      dispatch(notifyTenantSettingUserEventMessageEvent(objectEventMessage))
    },
    [bulkInviteUserMutation, dispatch]
  )

  return { verifyBulkInvitation, bulkInviteUser, isLoadingBulkInviteUser }
}
