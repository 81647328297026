import { FC, memo, useCallback } from 'react'
import { Menu } from '@headlessui/react'
import classNames from 'classnames'
import { CrewPresenceStateIcon } from 'components/elements/crewPresenceStateIcon/crewPresenceStateIcon'
import { PresenceState } from '@crew/states'

type PresenceStateItemProps = {
  presenceState: PresenceState
  onPresenceStateChange: (presenceState: PresenceState) => void
}

export const PresenceStateItem: FC<PresenceStateItemProps> = memo((props) => {
  // 在席状態を変更する
  const handlePresenceStateChange = useCallback(() => {
    props.onPresenceStateChange(props.presenceState)
  }, [props])

  return (
    <Menu.Item key={props.presenceState.id}>
      {({ active }) => (
        <div
          className={classNames(
            { 'crew-bg-gray-2': active },
            'px-4 py-2 text-sm flex flex-row items-center gap-1.5',
            'cursor-pointer'
          )}
          onClick={handlePresenceStateChange}
        >
          <CrewPresenceStateIcon
            presenceStateType={props.presenceState.presenceStateType}
          />
          <span>{props.presenceState.name}</span>
        </div>
      )}
    </Menu.Item>
  )
})
