import { UserRef } from '@crew/models/refs'
import { useTranslation } from '@crew/modules/i18n'
import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'
import { FC, memo } from 'react'

type User = UserRef & {
  isRead: boolean
}

type ChatReadStatusUserListItemProps = {
  user: User
}

export const ChatReadStatusUserListItem: FC<ChatReadStatusUserListItemProps> =
  memo((props) => {
    const { t } = useTranslation()

    return (
      <div className="py-3 flex flex-row justify-between items-center">
        <CrewUserAvatar
          userId={props.user.id}
          displayName={props.user.displayName}
          cacheValue={props.user.id + props.user.version}
          disableClick
          showLabel
          size={CrewAvatarSize.xs}
        />

        {props.user.isRead && <div className="px-2.5">{t('label.read')}</div>}
      </div>
    )
  })
