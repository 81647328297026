import { BaseSdkProps } from 'modules/amazon-chime-sdk-component-library-devextreme/components/sdk/Base'
import { useAudioVideo } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/AudioVideoProvider'
import { useContentShareState } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/ContentShareProvider'
import { FC, memo, useEffect, useRef } from 'react'
import { VideoTile } from './components/videoTile'

type Props = BaseSdkProps & { id?: string }

export const CrewContentShare: FC<Props> = memo((props) => {
  const videoElementRef = useRef<HTMLVideoElement>(null)
  const audioVideo = useAudioVideo()
  const { tileId } = useContentShareState()

  useEffect(() => {
    if (!audioVideo || !videoElementRef.current || !tileId) {
      return
    }

    audioVideo.bindVideoElement(tileId, videoElementRef.current)

    return () => {
      const tile = audioVideo.getVideoTile(tileId)
      if (tile) {
        audioVideo.unbindVideoElement(tileId)
      }
    }
  }, [audioVideo, tileId])

  return <VideoTile ref={videoElementRef} {...props} />
})
