import { FC, memo, useMemo } from 'react'
import classNames from 'classnames'

import { HTMLEDITOR_VIEW_STYLE } from 'configs/constants'

import { useAppSelector } from 'states/hooks'
import {
  addCodeStyleByCodeTarget,
  addMentionStyleByMentionTarget,
} from 'utils/chat'
import { CrewHtmlContent } from 'components/elements/crewHtmlContent/crewHtmlContent'
import { sanitizeHtml } from 'utils/html'
import { escapeRegExp } from '@crew/utils/chat'

export type CrewNormalChatMessageProps = {
  messageText: string
  messagePlainText: string
  isTopicSummary: boolean
  highlightKeyword?: string
}

export const CrewNormalChatMessage: FC<CrewNormalChatMessageProps> = memo(
  (props) => {
    const loggedInUser = useAppSelector((state) => state.app.loggedInUser)
    // Replace to mark keyword with background color when searching
    const htmlMessageResult = useMemo(() => {
      // topic repost message show plain text
      if (props.isTopicSummary) {
        return props.messagePlainText
      }
      let messsageResult = props.messageText
      if (props.highlightKeyword && props.highlightKeyword.trim().length > 0) {
        // Escape the highlight keyword
        const escapedKeyword = escapeRegExp(props.highlightKeyword)

        // Regex replace text outside html tags
        const kewords = escapedKeyword.split(' ')
        const regex = new RegExp(`(${kewords.join('|')})(?![^<]*>)`, 'gi')

        // $1: regex matched string
        messsageResult = props.messageText.replace(
          regex,
          '<strong class="bg-crew-yellow-2-light dark:bg-crew-yellow-2-dark">$1</strong>'
        )
      }

      messsageResult = addCodeStyleByCodeTarget(messsageResult) // チャット本文

      return addMentionStyleByMentionTarget(
        sanitizeHtml(messsageResult),
        loggedInUser?.id ?? ''
      ) // チャット本文
    }, [
      props.isTopicSummary,
      props.messageText,
      props.highlightKeyword,
      props.messagePlainText,
      loggedInUser?.id,
    ])

    return (
      <div
        className={classNames(
          HTMLEDITOR_VIEW_STYLE,
          props.isTopicSummary && 'truncate' // スレッドの見出しの場合は1行表示とし、はみ出た分は省略表示する
        )}
      >
        <CrewHtmlContent html={htmlMessageResult} />
      </div>
    )
  }
)
