import { useTranslation } from '@crew/modules/i18n'
import { memo } from 'react'

export const EventDetailWebMeetingWaitingRoom = memo(() => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div>{t('label.waitingForApproval')}</div>
    </div>
  )
})
