import { CrewRadioGroup } from 'components/devextreme/crewRadioGroup'
import { PaymentMethod } from 'enums/app'
import { usePaymentMethodDataSource } from 'hooks/dataSource/useResourceDataSource'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { ContractRegisterCreditCardForm } from './components/contractRegisterCreditCardForm/contractRegisterCreditCardForm'
import { ContractRegisterBankTransferForm } from './components/contractRegisterBankTransferForm/contractRegisterBankTransferForm'
import { Elements } from '@stripe/react-stripe-js'
import {
  useCreateSetupIntentQuery,
  useGetBillingCycleQuery,
  useGetPlansQuery,
} from '@crew/apis/contract/contractApis'
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import { NativeEventInfo } from 'devextreme/events'
import dxRadioGroup from 'devextreme/ui/radio_group'
import { ValueChangedInfo } from 'devextreme/ui/editor/editor'
import { useAppSelector } from 'states/hooks'
import { CrewFieldLabel } from 'components/elements/crewFieldLabel'
import { useTranslation } from '@crew/modules/i18n'
import {
  BillingCycle,
  StripeSubscriptionStatus,
  ContractPlan,
} from '@crew/enums/app'
import { ComponentCallbackHandler } from '@crew/utils'
import { useValueChangeEffect } from '@crew/hooks'

// プラン情報のラジオボタンの選択値の型
type PlanRadioGroupItem = {
  value: ContractPlan
  name: string
  description: string
}

// プラン情報のラジオボタンの選択値の型
type BillingCycleRadioGroupItem = {
  value: BillingCycle
  name: string
  description: string
  price: string
}

// render content
// renderとして使うのでmemo不可
const PlanItem: FC<PlanRadioGroupItem> = (props) => (
  <div
    id={`plan-radioId-${props.value}`}
    className="w-full h-10 justify-start items-center inline-flex"
  >
    {/* プラン名 */}
    <div className="w-40 justify-start items-center gap-2.5 flex">
      <div className="text-md">{props.name}</div>
    </div>
    {/* 説明 */}
    <div className="gap-2.5 flex">
      <div className="crew-text-gray-4 text-md">{props.description}</div>
    </div>
  </div>
)

// render content
// renderとして使うのでmemo不可
const BillingCycleItem: FC<BillingCycleRadioGroupItem> = (props) => (
  <div
    id={`cycle-radioId-${props.value}`}
    className="w-full h-10 justify-start items-center inline-flex"
  >
    {/* 契約サイクル */}
    <span className="leading-5 w-20">{props.name}</span>

    {/* 説明 */}
    <div className="w-48 crew-text-gray-5 leading-tight">
      {props.description}
    </div>

    {/* 金額 */}
    <div className="grow shrink basis-0 crew-text-gray-5 text-md leading-tight">
      {props.price}
    </div>
  </div>
)

export const ContractRegisterPaymentMethodPage: FC = memo(() => {
  const { t } = useTranslation()
  const paymentMethodDataSource = usePaymentMethodDataSource()
  const themeMode = useAppSelector((state) => state.app.currentTheme)

  // 請求サイクルを取得する
  const { data: billingCycleData } = useGetBillingCycleQuery()

  // プラン情報一覧を取得
  const { data: plansResult, isFetching } = useGetPlansQuery()

  // 契約プランのラジオボタンに値を渡すための変数
  const [selectedPlan, setSelectedPlan] = useState<ContractPlan | undefined>(
    undefined
  )

  // 請求サイクルのラジオボタンに値を渡すための変数
  const [selectedBillingCycle, setSelectedBillingCycle] =
    useState<BillingCycle>(BillingCycle.Month)

  // プラン一覧
  const planItems = useMemo(() => {
    const items: PlanRadioGroupItem[] = []
    plansResult?.plans.map((item) =>
      items.push({
        value: item.plan,
        name: t(`label.planType.${item.plan}`),
        description: t(`label.planDescriptionForUpdate.${item.plan}`),
      })
    )

    return items
  }, [plansResult?.plans, t])

  // 契約サイクル一覧
  const billingItems = useMemo(() => {
    // 選択中のプランの契約サイクルの情報を取得
    const plan = plansResult?.plans.find((item) => item.plan === selectedPlan)
    if (!plan) {
      return []
    }

    return Object.values(BillingCycle).map((cycle) => {
      // プラン情報から各請求サイクルの情報を取得
      const billingCycle = plan.billingCycles.find(
        (item) => item.billingCycle === cycle
      )
      if (!billingCycle) {
        // undefinedになることはないが、型制約のためチェック処理を追加
        return []
      }

      const item: BillingCycleRadioGroupItem = {
        value: cycle,
        name: t(`label.cycleBilling.${cycle}.name`),
        description: t(`label.cycleBilling.${cycle}.description`),
        price: t(`label.cycleBilling.${cycle}.price`, {
          price: billingCycle.unitAmount.toLocaleString(),
        }),
      }

      return item
    })
  }, [plansResult, selectedPlan, t])

  // プラン一覧取得時、現在契約中のプランをラジオボタンの選択値に反映する
  useValueChangeEffect(
    () => {
      // プラン一覧から現在契約中のプラン情報を検索
      const currentPlan = plansResult?.plans.find((item) =>
        item.billingCycles.some((billingCycle) => billingCycle.isCurrentCycle)
      )
      // 現在契約中のプラン情報があれば選択状態にする
      // ただし、契約中のプランがフリープランの場合は、アップグレードの処理となるためスタンダードを選択状態にする
      if (currentPlan && currentPlan.plan !== ContractPlan.Free) {
        setSelectedPlan(currentPlan.plan)
      } else {
        setSelectedPlan(ContractPlan.Standard)
      }
    },
    [plansResult],
    plansResult
  )

  // 請求サイクルを取得できたらラジオボタンの選択値に反映する
  useValueChangeEffect(
    () => {
      if (billingCycleData) {
        billingCycleData.billingCycle.forEach((item) => {
          if (item.isCurrentCycle) {
            setSelectedBillingCycle(item.billingCycle)
          }
        })
      }
    },
    [billingCycleData],
    billingCycleData
  )

  // 契約プランのラジオボタンの選択値が変わったときのイベントハンドラ
  const handlePlanRadioGroupValueChanged = useCallback<
    ComponentCallbackHandler<typeof CrewRadioGroup, 'onValueChanged'>
  >((event) => {
    setSelectedPlan(event.value)
  }, [])

  // 契約サイクルのラジオボタンの選択値が変わったときのイベントハンドラ
  const handleBillingCycleRadioGroupValueChanged = useCallback<
    ComponentCallbackHandler<typeof CrewRadioGroup, 'onValueChanged'>
  >((event) => {
    setSelectedBillingCycle(event.value)
  }, [])

  // 支払い方法
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(
    PaymentMethod.creditCard.value
  )

  const {
    data: createSetupIntentResult,
    isFetching: isCreateSetupIntentFetching,
  } = useCreateSetupIntentQuery()

  // 支払方法変更時のイベントハンドラ
  const handlePaymentMethodChanged = useCallback(
    (e: NativeEventInfo<dxRadioGroup, Event> & ValueChangedInfo) => {
      setSelectedPaymentMethod(e.value)
    },
    []
  )

  // Stripe要素のオプション
  const stripeElementOptions = useMemo<StripeElementsOptions>(() => {
    return {
      appearance: {
        theme: themeMode === 'light' ? 'flat' : 'night',
      },
      clientSecret: createSetupIntentResult?.clientSecret,
    }
  }, [createSetupIntentResult?.clientSecret, themeMode])

  // トライアル中の場合は契約サイクルを表示するため、サブスクリプションステータスを取得する
  const isTrialing =
    createSetupIntentResult?.subscriptionStatus ===
    StripeSubscriptionStatus.Trialing

  const isFree = createSetupIntentResult?.currentPlan === ContractPlan.Free

  const isPaid = !isTrialing && !isFree

  // 請求方法のラジオボタンのrender
  const renderPaymentRadioGroupItem = useCallback(
    (item: { id: string; name: string }) => {
      return (
        <div
          id={`plan-radioId-${item.id}`}
          className="w-full h-10 justify-start items-center inline-flex"
        >
          {/* 支払い方法 */}
          <div className="w-28 justify-start items-center gap-2.5 flex">
            <div className="text-md">{t(item.name)}</div>
          </div>
        </div>
      )
    },
    [t]
  )

  return (
    <div className="flex justify-center">
      <div className="py-2.5 flex flex-col gap-2.5 w-full max-w-2xl">
        {/* 見出し（支払方法の変更/ご契約内容） */}
        <div className="py-2.5">
          <span className="text-md font-bold">
            {isPaid
              ? t('label.changePaymentMethod')
              : t('label.contractDetails')}
          </span>
        </div>

        {/* サブスクリプションステータスがトライアル中またはフリープランを利用中の場合は契約サイクルの選択を表示する */}
        {!isPaid && (
          <>
            {/* 契約プラン変更用のラジオボタン */}
            <div>
              <CrewFieldLabel text={t('label.contractPlan')} />
              <CrewRadioGroup
                layout="vertical"
                disabled={isFetching} // フェッチ中は選択不可
                items={planItems}
                valueExpr="value"
                displayExpr="name"
                value={selectedPlan}
                itemRender={PlanItem}
                onValueChanged={handlePlanRadioGroupValueChanged}
              />
            </div>

            {/* 請求サイクル変更用のラジオボタン */}
            <div>
              <CrewFieldLabel text={t('label.contractCycle')} />

              <CrewRadioGroup
                layout="vertical"
                disabled={isFetching} // フェッチ中は選択不可
                items={billingItems}
                valueExpr="value"
                displayExpr="name"
                value={selectedBillingCycle}
                itemRender={BillingCycleItem}
                onValueChanged={handleBillingCycleRadioGroupValueChanged}
              />
            </div>
          </>
        )}

        {/* 支払い方法変更用のラジオボタン */}
        <div>
          <CrewFieldLabel text={t('label.paymentMethod')} />
          <CrewRadioGroup
            layout="vertical"
            dataSource={paymentMethodDataSource}
            valueExpr="id"
            displayExpr="name"
            value={selectedPaymentMethod}
            itemRender={renderPaymentRadioGroupItem}
            onValueChanged={handlePaymentMethodChanged}
            disabled={isCreateSetupIntentFetching || isFetching}
          />
        </div>

        {/* 登録フォーム */}
        {!isCreateSetupIntentFetching && (
          <div>
            {/* クレジットカード */}
            {selectedPaymentMethod === PaymentMethod.creditCard.value && (
              <Elements
                stripe={loadStripe(process.env.REACT_APP_STRIPE_KEY || '')}
                options={stripeElementOptions}
              >
                <ContractRegisterCreditCardForm
                  billingCycle={!isPaid ? selectedBillingCycle : undefined}
                  plan={!isPaid ? selectedPlan : undefined}
                  showContactInformation={!isPaid}
                />
              </Elements>
            )}
            {/* 銀行振込 */}
            {selectedPaymentMethod === PaymentMethod.bankTransfer.value && (
              <ContractRegisterBankTransferForm
                billingCycle={!isPaid ? selectedBillingCycle : undefined}
                plan={!isPaid ? selectedPlan : undefined}
                showContactInformation={!isPaid}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
})
