import {
  useLazyGetEventKindsQuery,
  useLazyGetMyScheduleQuery,
  useUpdateEventDurationMutation,
} from '@crew/apis/project/projectApis'
import { JsonDateFormat } from '@crew/enums/system'
import { EventType } from '@crew/enums/domain'
import dayjs from '@crew/modules'
import {
  CrewBadgeInvertedColorClass,
  CrewBadgeInvertedColorToHex,
} from 'enums/color'
import { useDataSource } from 'hooks/dataSource/useDataSource'
import { TimePeriod } from 'enums/app'
import { useMemo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'

export const useScheduleWeekly = (targetDate: Date) => {
  const { t } = useTranslation()

  const [lazyGetEventKindsQuery] = useLazyGetEventKindsQuery()
  const [lazyGetMyScheduleQuery] = useLazyGetMyScheduleQuery()

  const [updateEventDurationMutation] = useUpdateEventDurationMutation()

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  const eventDataSource = useDataSource(
    () => ({
      key: 'id',
      load: async (loadOptions) => {
        let startDate = dayjs(targetDate)
          .startOf('week')
          .format(JsonDateFormat.YYYYMMDD)
        let endDate = dayjs(targetDate)
          .endOf('week')
          .format(JsonDateFormat.YYYYMMDD)

        const result = await lazyGetMyScheduleQuery({
          startDate,
          endDate,
        }).unwrap()

        return result.events
      },
      update: async (key, values) => {
        // スケジューラは日時をUTC時間に自動的に変換するが、
        // バックエンド側はクライアントのタイムゾーンに沿って処理するため、ここでUTC時間をクライアントのタイムゾーンに変換する。
        const startDatetime = dayjs(values.startDatetime)
          .tz(String(defaultUserProfileRegion))
          .format(JsonDateFormat.YYYYMMDDHHmmss)

        const endDatetime = dayjs(values.endDatetime)
          .tz(String(defaultUserProfileRegion))
          .format(JsonDateFormat.YYYYMMDDHHmmss)

        await updateEventDurationMutation({
          event: {
            id: key,
            startDatetime: startDatetime,
            endDatetime: endDatetime,
            version: values.version,
          },
        })
      },
    }),
    [
      defaultUserProfileRegion,
      lazyGetMyScheduleQuery,
      targetDate,
      updateEventDurationMutation,
    ]
  )

  //Get event kinds
  const eventKindDataSource = useDataSource(
    () => ({
      loadMode: 'raw',
      key: 'id',
      load: async (loadOptions) => {
        const response = await lazyGetEventKindsQuery({
          eventType: EventType.Personal,
          entityType: undefined,
          entityRecordId: undefined,
        }).unwrap()

        return response.eventKinds.map((item) => {
          return {
            ...item,
            displayColor:
              CrewBadgeInvertedColorToHex[
                item.displayColor as CrewBadgeInvertedColorClass
              ],
          }
        })
      },
    }),
    [lazyGetEventKindsQuery]
  )

  const timePeriodDataSource = useMemo(() => {
    return Object.values(TimePeriod).map((time) => {
      return {
        id: time.value,
        name: t(time.text),
      }
    })
  }, [t])

  return {
    eventKindDataSource,
    eventDataSource,
    timePeriodDataSource,
  }
}
