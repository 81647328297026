import { useCallback, useMemo, useRef, useState } from 'react'

import { useEffectOnce } from '@dx-system/react-use'

import { useLazyGetChatRoomMessagesQuery } from '@crew/apis/chat/chatApis'
import { GetChatRoomMessagesRequest } from '@crew/apis/chat/models/getChatRoomMessages/request'
import { ChatMessage } from '@crew/apis/chat/models/getChatRoomMessages/response'
import { MESSAGE_AREA_MIN_HEIGHT } from '@crew/configs/constants'
import { ChatView, SearchRange, TimelineDirection } from '@crew/enums/app'
import {
  useChatThreadLastReadMessage,
  useFetchLimit,
  useProcessInLaterRenderingWithParams,
  useValueChangeEffect,
} from '@crew/hooks'
import { useSubscribeChat } from '@crew/providers/websocket'
import { Chat, useChatMessageService, useChatThreadService } from '@crew/states'
import { compareUlid } from '@crew/utils/ulid'

import { useAppDispatch, useAppSelector } from 'states/hooks'
import { getWindowDimensions } from 'utils'
import { getChatMessageListItemDomId } from 'utils/chat'

/** メッセージアイテムの型 */
export type DisplayMessageItem = {
  id: string // メッセージIDと表示形式を組み合わせたID
  messageId: string // メッセージIDs
  isFirstUnreadMessage: boolean // 最古の未読メッセージかどうか
  handleAdditionalLoading: (() => void) | undefined // 追加読込処理
  handleFirstUnreadMessageViewed: (() => void) | undefined // 最初の未読アイテムが表示されたときの処理
}

/** messagesから表示用アイテムへ変換する関数の戻り値の型 */
type ConvertMessagesToDisplayItemsResult = {
  items: DisplayMessageItem[]
  unreadLineMessageId: string | undefined
}

/** 複数メッセージ追加時のパラメータ */
type AddMessagesParams = {
  chatMessages: ChatMessage[]
  criterionMessageId: string | undefined
  direction: TimelineDirection
  isLoadedOfEndOfMessages: boolean
}

/** チャットメッセージをロードするためのパラメータ */
type LoadChatMessagesParams = {
  criterionMessageId: string | undefined
  direction: TimelineDirection
}

/**
 * 引数のmessagesから表示用のアイテムリストを生成する関数
 * @param messages ViewModelから取得したTimeline形式のmessages
 * @param isSuccessLastReadFetch 最終既読メッセージIDの取得に成功しているかどうか
 * @param lastReadMessageId 最終既読メッセージID
 * @param loadChatMessages チャットメッセージをロードする関数
 * @param delayedUpdateLastReadMessageId 既読更新を遅延実行させるための関数
 * @param currentDisplayFormat チャットのトピック表示形式
 * @param loggedInUserId ログイン中のユーザーID
 * @returns 表示用アイテムリストと更新後の最終既読メッセージID
 */
export const convertMessagesToDisplayItems = (
  messages: Chat.ThreadMessage[],
  isSuccessLastReadFetch: boolean,
  lastReadMessageId: string | undefined,
  loadChatMessages: (params: LoadChatMessagesParams) => void,
  delayedUpdateLastReadMessageId: (
    unreadLineMessageId: string | undefined
  ) => void,
  currentDisplayFormat: ChatView | undefined,
  loggedInUserId: string | undefined
): ConvertMessagesToDisplayItemsResult => {
  const items: DisplayMessageItem[] = []

  // 子孫を含めない現在のチャットスレッドで最古の未読メッセージのID
  let firstUnreadMessageId: string | undefined

  messages.forEach((message, index) => {
    const prevMessage = index > 0 ? messages[index - 1] : undefined
    const messageId = message.id

    // このメッセージが最古の未読メッセージかどうか
    // trueなら既読更新処理が挟み込まれる
    let isFirstUnreadMessage = false

    // 最古の未読メッセージ(既読処理のトリガ且つ未読ライン位置)である条件は、
    // ■1. 既読情報の取得が完了している
    // ■2. まだ最古の未読メッセージが見つかっていない
    // ■3. このメッセージが未読である
    // ■4. このメッセージが時間軸で最古であるか、1つ前のメッセージが既読であるか、1つ前のメッセージが自分の投稿である
    // なお、古いメッセージからループ処理していることが前提

    // スレッドリスト表示のみスレッド内の未読管理と既読処理が行われるため、
    // 表示モードがスレッドリスト表示である場合のみ「最古の未読メッセージ」の判定を行う
    if (currentDisplayFormat === ChatView.ThreadList) {
      // メッセージが自分の投稿でない場合のみ「最古の未読メッセージ」の判定処理を行う
      // 自分の投稿の場合は未読として扱わず、既読処理も行わない
      if (
        message.createdById !== undefined &&
        message.createdById !== loggedInUserId
      ) {
        // 最古の未読メッセージが未出現かつバックエンドから未読情報の取得が完了していて、既読位置が未設定だったら最古の未読メッセージか判定を行う
        if (
          // ■1. 既読情報の取得が完了している
          isSuccessLastReadFetch &&
          // ■2. まだ最古の未読メッセージが見つかっていない
          firstUnreadMessageId === undefined
        ) {
          // このメッセージが未読かどうか
          // 1. このメッセージの属するチャットスレッドに既読位置が設定されている
          // 2. 且つ、既読位置より新しいメッセージである
          const isUnreadMessage =
            lastReadMessageId && compareUlid(lastReadMessageId, '<', message.id)

          // このメッセージが時間軸で最古のメッセージかどうか
          // 1. このメッセージの前にメッセージがない
          // 2. 且つ、古い方向に追加読込する必要がない
          const isOldestMessage =
            message.prevId === undefined && !message.hasMorePrev

          // 1つ前のメッセージが既読かどうか
          // 1. 1つ前のメッセージが存在している
          // 2. 且つ、1つ前のメッセージの属するチャットスレッドに既読位置が設定されていない(チャットスレッド内すべて既読)か、既読位置以前のメッセージである
          const isReadPrevMessage =
            prevMessage &&
            (lastReadMessageId === undefined ||
              compareUlid(lastReadMessageId, '>=', prevMessage.id))

          // 1つ前のメッセージが自分の投稿かどうか
          //   自分の投稿は既読処理が走らず、最終既読メッセージIDとの比較上は未読扱いされてしまうため、
          //   isReadPrevMessageの判定で拾うことができないので、1つ前のメッセージが自分の投稿かどうかも判定に使用する必要がある
          const isPrevMyMessage =
            prevMessage && prevMessage.createdById === loggedInUserId

          if (
            // ■3. このメッセージが未読である
            isUnreadMessage &&
            // ■4. このメッセージが時間軸で最古であるか、1つ前のメッセージが既読であるか、1つ前のメッセージが自分の投稿である
            (isOldestMessage || isReadPrevMessage || isPrevMyMessage)
          ) {
            firstUnreadMessageId = messageId
            isFirstUnreadMessage = true
          }
        }
      }
    }

    // 追加読込の方向を決定する。必要無ければundefined
    const additionalLoadingDirection =
      message.hasMorePrev && message.hasMoreNext // 時間軸上で両方向の隣のメッセージが未読込だった場合はboth
        ? TimelineDirection.BothNewerAndOlder
        : message.hasMorePrev // 時間軸上でprev方向の隣のメッセージが未読込だった場合はolder
        ? TimelineDirection.Older
        : message.hasMoreNext // 時間軸上でnext方向の隣のメッセージが未読込だった場合はnewer
        ? TimelineDirection.Newer
        : undefined // 両方向の隣のメッセージが読込済だった場合は追加読込は不要

    // 追加読込処理を行う関数を生成する。必要無ければundefined
    const handleAdditionalLoading = additionalLoadingDirection
      ? () =>
          loadChatMessages({
            criterionMessageId: messageId,
            direction: additionalLoadingDirection,
          })
      : undefined

    // 最初の未読アイテムが表示されたときの処理を行う関数を生成する。必要無ければundefined
    const handleFirstUnreadMessageViewed = isFirstUnreadMessage
      ? () => {
          delayedUpdateLastReadMessageId(firstUnreadMessageId)
        }
      : undefined

    items.push({
      id: messageId,
      messageId: messageId,
      isFirstUnreadMessage,
      handleAdditionalLoading,
      handleFirstUnreadMessageViewed,
    })
  })

  return { items, unreadLineMessageId: firstUnreadMessageId }
}

export const useChatThreadMessageList = () => {
  // 処理対象のチャットスレッドをViewModelから取得
  const currentChatThread = useAppSelector(
    (state) => state.message.chat.current.chatThread
  )
  // この処理が流れる際、ViewModelには必ずチャットルームが設定されているはずなので、未設定の場合はエラーとする
  if (!currentChatThread) {
    throw new Error('currentChatThread is undefined')
  }

  const dispatch = useAppDispatch()

  // メッセージ表示領域のref
  const itemsScrollableDivRef = useRef<HTMLDivElement>(null)

  // 取得したチャットメッセージをSliceにキャッシュする関数を取得
  const chatMessageService = useChatMessageService(dispatch)

  // Sliceの操作を行うためのServiceを取得
  const chatThreadService = useChatThreadService(dispatch)

  // チャットメッセージ取得API
  const [lazyGetChatRoomMessagesQuery] = useLazyGetChatRoomMessagesQuery()

  // 画面遷移前のスクロール位置
  const scrollToMessageId = useAppSelector(
    (state) =>
      state.message.chat.thread.entities[currentChatThread.topicId]
        ?.scrollToMessageId
  )

  // 検索機能やアテンションなどで選択されたメッセージID
  const selectedMessageId = useAppSelector(
    (state) =>
      state.message.chat.thread.entities[currentChatThread.topicId]
        ?.selectedMessageId
  )

  const {
    lastReadMessageId,
    forceUpdateLastReadMessageId,
    updateLastReadMessageId,
    isSuccessLastReadFetch,
    setDisplayingItems,
    updateNewestViewedMessageId,
    reset: resetLastReadMessageHook,
  } = useChatThreadLastReadMessage(
    dispatch,
    useAppSelector,
    currentChatThread.topicId
  )

  // Reduxに格納されている対象トピック配下のメッセージのDictionaryを取得する
  const chatThreadMessageDictionary = useAppSelector(
    (state) =>
      state.message.chat.thread.entities[currentChatThread.topicId]?.messages
        .entities
  )
  // Reduxに格納されている対象トピック配下のメッセージのIDリストを取得する
  // Reduxに格納されている順番で取得する必要があるので、entitiesだけでなくidsも取得しておく必要がある
  const chatThreadMessageIds = useAppSelector(
    (state) =>
      state.message.chat.thread.entities[currentChatThread.topicId]?.messages
        .ids
  )
  // 表示対象のメッセージを返す
  const chatThreadMessages = useMemo(() => {
    if (!chatThreadMessageIds || !chatThreadMessageDictionary) {
      return []
    }
    // id順にメッセージを設定する
    return chatThreadMessageIds.reduce((result: Chat.ThreadMessage[], id) => {
      const message = chatThreadMessageDictionary[id]
      if (message) {
        result.push(message)
      }
      return result
    }, [])
  }, [chatThreadMessageDictionary, chatThreadMessageIds])

  /**
   * 複数アイテムをViewModelに追加する
   * @param params
   */
  const addItems = useCallback(
    (params: AddMessagesParams) => {
      // ViewModelへデータを追加
      const parameter = {
        topicId: currentChatThread.topicId,
        messages: params.chatMessages,
        criterionMessageId: params.criterionMessageId,
        direction: params.direction,
        isLoadedOfEndOfSourceItems: params.isLoadedOfEndOfMessages,
      }
      chatThreadService.addChatThreadMessages(parameter)
    },
    [chatThreadService, currentChatThread.topicId]
  )

  /**
   * API経由で取得したチャットメッセージをメッセージのキャッシュとViewModelに追加する
   */
  const addMessages = useCallback(
    (params: AddMessagesParams) => {
      // キャッシュへ追加
      chatMessageService.addChatMessagesToCache({
        chatMessages: params.chatMessages,
      })
      // ViewModelへ追加
      addItems({
        chatMessages: params.chatMessages,
        criterionMessageId: params.criterionMessageId,
        direction: params.direction,
        isLoadedOfEndOfMessages: params.isLoadedOfEndOfMessages,
      })
    },
    [addItems, chatMessageService]
  )

  // 一度にfetchするサイズ
  const fetchLimit = useFetchLimit(MESSAGE_AREA_MIN_HEIGHT, getWindowDimensions)

  /**
   * チャットメッセージをロードするメソッド
   * @param params 対象メッセージを絞り込むためのパラメータ
   * @returns
   */
  const loadChatMessages = useCallback(
    async (params: LoadChatMessagesParams) => {
      const request: GetChatRoomMessagesRequest = {
        filter: SearchRange.AllMessage, // スレッド表示では検索範囲を絞る必要がないのですべてのメッセージを取得する
        keyword: '',
        chatRoomId: currentChatThread.chatRoomId,
        threadRootOnly: false, // Thread形式のためスレッドルートだけでなくすべて取得する
        threadRootMessageId: currentChatThread.topicId, // Thread形式のため処理対象のスレッドに紐づくデータを取得する
        normalMessageOnly: undefined, // message_typeでの絞り込みは行わないのでundefinedを指定する
        criterionMessageId: params.criterionMessageId,
        direction: params.direction,
        limit: fetchLimit,
      }

      try {
        const data = await lazyGetChatRoomMessagesQuery(request).unwrap()
        const isLoadedOfEndOfMessages = request.limit > data.chatMessages.length

        const messagesAddedPayload: AddMessagesParams = {
          chatMessages: data.chatMessages,
          ...request, // 型が違うがduck typeによって代入可能
          isLoadedOfEndOfMessages,
        }

        if (request.chatRoomId === currentChatThread.chatRoomId) {
          addMessages(messagesAddedPayload)
        } else {
          console.info(
            `[useChatTimeline] Chat room id mismatch. current:${currentChatThread.chatRoomId} recv:${request.chatRoomId}`
          )
        }
      } catch (err) {
        // TODO: CREW-13720の対応で、プロジェクトから退出した際にエラートーストが出てしまう問題が発生し、トースト表示をコメントアウトする暫定対応を行った
        // ただ、これにより追加ロード時にエラーがあっても画面上表示されないという状態であるため、以下タスクで恒久対応を行う
        // 現時点ではコンソールにエラーを表示するにとどめる
        // https://break-tmc.atlassian.net/browse/CREW-13724
        // toast.error(t('message.general.failedToRetrieveData'))
        console.error(err)
      }
    },
    [
      currentChatThread.chatRoomId,
      currentChatThread.topicId,
      fetchLimit,
      lazyGetChatRoomMessagesQuery,
      addMessages,
    ]
  )

  // 既読更新を遅延実行させるためcustom hookでwrapする
  const delayedUpdateLastReadMessageId = useProcessInLaterRenderingWithParams(
    (unreadLineMessageId: string | undefined) =>
      updateLastReadMessageId(unreadLineMessageId),
    [updateLastReadMessageId],
    1
  )

  // チャットのトピック表示形式を取得する
  const currentDisplayFormat = useAppSelector(
    (state) => state.message.chat.current.displayFormat
  )

  // ログイン中のユーザ情報
  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

  // チャット表示用アイテムリスト
  const { items: displayItems } = useMemo(
    () =>
      convertMessagesToDisplayItems(
        chatThreadMessages,
        isSuccessLastReadFetch,
        lastReadMessageId,
        loadChatMessages,
        delayedUpdateLastReadMessageId,
        currentDisplayFormat,
        loggedInUser?.id
      ),
    [
      chatThreadMessages,
      currentDisplayFormat,
      delayedUpdateLastReadMessageId,
      isSuccessLastReadFetch,
      lastReadMessageId,
      loadChatMessages,
      loggedInUser?.id,
    ]
  )

  // すべてのアイテムが既読状態かどうか
  const isAllItemsRead = useMemo(() => {
    if (chatThreadMessages.length === 0) {
      // 表示するアイテムがない=未読アイテムもない=すべて既読
      return true
    }

    if (lastReadMessageId === undefined) {
      // 既読アイテムIDが未設定=すべて既読
      return true
    }

    // chat系のメッセージは昇順なので、最新のアイテムは配列の最後にある
    const newestItem = chatThreadMessages[chatThreadMessages.length - 1]

    if (newestItem.hasMoreNext) {
      // 表示対象アイテムの最新のものに次がある=表示されていない未読アイテムがまだある=すべて既読ではない
      return false
    }

    if (compareUlid(lastReadMessageId, '<', newestItem.id)) {
      // 最終既読アイテムIDが最新のアイテムよりも前=少なくとも最新アイテムは未読=すべて既読ではない
      return false
    }

    // 表示するアイテムが存在し、既読アイテムがあり、最新のアイテムに次がなく、かつ最終既読アイテムIDが最新のアイテム以降=すべて既読
    return true
  }, [chatThreadMessages, lastReadMessageId])

  // WebSocketでメッセージを受信したときに呼ばれるイベントハンドラ
  const handleRecvMessageByWebsocket = useCallback(
    (message: ChatMessage) => {
      console.info(`[Chat] Recv message via websocket. ${message.id}`)

      // 受け取ったメッセージが表示しているスレッドのメッセージか判定
      if (
        currentChatThread.topicId !== message.id &&
        currentChatThread.topicId !== message.parentChatMessageId
      ) {
        return
      }

      // すべてのメッセージが既読状態で、且つスクロール位置が最新側の端だった場合には、受信したメッセージを即座に既読とする。
      // このハンドラはレンダリング前のタイミングで呼ばれるため、未読ラインの表示前に既読扱いされるので未読ラインは表示されない。
      //
      // スクロール位置が最新側の端でない場合、古いメッセージを表示している状態であり、受信メッセージは画面上に表示されないため、既読にしない。
      // この場合は通常通り未読ラインが表示され、スクロールをトリガーとして既読更新される。

      // 表示欄のスクロール位置を取得するためrefを使うが、最初のDOMができるまでnullであるため最初にnullチェックを行う
      const container = itemsScrollableDivRef.current
      if (container === null) {
        return
      }

      if (isAllItemsRead && container.scrollTop === 0) {
        console.info(
          `[Chat] updateLastReadMessageId: ${message.id} trigger by websocket`
        )
        forceUpdateLastReadMessageId(message.id)
      }
    },
    [currentChatThread.topicId, forceUpdateLastReadMessageId, isAllItemsRead]
  )

  // websocket経由でメッセージを受信したときのイベントハンドラ

  // チャット関連のメッセージをwebsocket経由でsubscribeする
  useSubscribeChat(
    currentChatThread.chatRoomId,
    handleRecvMessageByWebsocket,
    dispatch,
    useAppSelector
  )

  // アイテムが領域内に表示された
  const messageInView = useCallback(
    (id: string, messageId: string) => {
      // 今現在画面に表示しているアイテムのリストに追加する。
      // 既読更新時に、未読ラインを越えてより古いメッセージを見ているか(≒既読更新すべきか)の判定対象になる
      setDisplayingItems((prev) => [
        ...prev.filter((m) => m.id !== id),
        { id, messageId },
      ])

      // 既読更新時の最終既読IDとして使うため、画面に表示した事のあるアイテムの中で一番大きいIDを記録しておく
      updateNewestViewedMessageId(messageId)

      // スクロール位置を戻すための対象messageIdを保持しておく
      chatThreadService.setThreadScrollToMessageId({
        topicId: currentChatThread.topicId,
        chatMessageId: messageId,
      })
    },
    [
      chatThreadService,
      currentChatThread.topicId,
      setDisplayingItems,
      updateNewestViewedMessageId,
    ]
  )

  // アイテムが領域外に移動して非表示になった
  const messageOutOfView = useCallback(
    (id: string) => {
      // 消えたアイテムをリストから除去する
      setDisplayingItems((prev) => prev.filter((elem) => elem.id !== id))
    },
    [setDisplayingItems]
  )

  // 指定したメッセージIDのメッセージを表示領域内にスクロールする
  const scrollToMessage = useCallback(
    (messageId: string | undefined) => {
      // messageIdがなければ処理しない
      if (messageId === undefined) {
        // スクロールする必要がないため、スクロール成功として扱う
        return true
      }

      // スクロール対象のDOM要素
      const displayTargetElement = itemsScrollableDivRef.current?.querySelector(
        `#${getChatMessageListItemDomId(messageId, ChatView.Thread)}`
      )
      // スクロール対象のメッセージを表示アイテムの中から取得する
      const item = displayItems.find((item) => item.messageId === messageId)
      // スクロール対象のメッセージが表示アイテムの中にあり、スクロール対象のDOM要素がある場合のみ処理を行う
      if (item && displayTargetElement) {
        // 表示対象のDOM要素を表示領域内にスクロールする
        displayTargetElement.scrollIntoView({
          behavior: 'auto',
          block: 'center',
        })
      } else {
        // スクロール失敗
        return false
      }

      return true
    },
    [displayItems]
  )

  useEffectOnce(() => {
    // 他画面への遷移から戻った際に、スクロール位置を元の位置に戻すための処理
    scrollToMessage(scrollToMessageId)
    // 追加読み込みが走るように、最新のメッセージのhasMoreNextを強制的にtrueにする
    chatThreadService.forceUpdateThreadMessageHasMoreNext({
      topicId: currentChatThread.topicId,
    })
  })

  // 追加読み込み後、特定メッセージへのスクロールが必要か
  const [
    requiredScrollAfterAdditionalLoad,
    setRequiredScrollAfterAdditionalLoad,
  ] = useState<boolean>(false)

  // 検索機能やアテンションなどでメッセージが選択されたら、選択されたメッセージにスクロール位置を移動する処理
  useValueChangeEffect(
    () => {
      // メッセージを表示領域内にスクロールする
      const scrollSucceeded = scrollToMessage(selectedMessageId)

      if (scrollSucceeded) {
        // スクロール成功時：メッセージIDをリセットする
        chatThreadService.resetChatThreadSelectedMessageId({
          topicId: currentChatThread.topicId,
        })
      } else {
        // スクロール失敗時：スクロール対象のメッセージが表示されていないため、スクロール失敗している。
        //                 そのため追加読み込みを行い、再度スクロールを行う。

        // メッセージ選択によるスクロールを有効にする
        setRequiredScrollAfterAdditionalLoad(true)

        // スクロール対象メッセージ付近を表示するために追加読み込みを行う
        loadChatMessages({
          criterionMessageId: selectedMessageId,
          direction: TimelineDirection.BothNewerAndOlder,
        })
      }
    },
    [
      chatThreadService,
      currentChatThread.topicId,
      loadChatMessages,
      scrollToMessage,
      selectedMessageId,
    ],
    selectedMessageId
  )

  // 検索機能やアテンションで選択されたメッセージが表示対象に含まれているか
  const isLoadedScrollTarget = useMemo(
    () =>
      selectedMessageId !== undefined
        ? displayItems.some((item) => item.messageId === selectedMessageId)
        : undefined,
    [displayItems, selectedMessageId]
  )

  // 検索機能やアテンションなどでメッセージが選択されたら、選択されたメッセージにスクロール位置を移動する処理
  //   選択したメッセージがdisplayItemsに含まれていない場合、追加読み込み後に再度スクロールを行う必要があるため、
  //   ここではその追加読み込み後の再スクロール処理を行っている
  useValueChangeEffect(
    () => {
      // 追加読み込み後にスクロール処理が必要 かつ スクロール対象メッセージが表示対象に含まれている場合、スクロールを行う
      if (requiredScrollAfterAdditionalLoad && isLoadedScrollTarget) {
        const scrollSucceeded = scrollToMessage(selectedMessageId)

        if (scrollSucceeded) {
          // メッセージ選択によるスクロールを無効にする
          setRequiredScrollAfterAdditionalLoad(false)

          // メッセージIDをリセットする
          chatThreadService.resetChatThreadSelectedMessageId({
            topicId: currentChatThread.topicId,
          })
        }
      }
    },
    [
      chatThreadService,
      currentChatThread.topicId,
      requiredScrollAfterAdditionalLoad,
      isLoadedScrollTarget,
      scrollToMessage,
      selectedMessageId,
    ],
    isLoadedScrollTarget
  )

  // 表示対象のスレッドが変わったら初期ロードを行う
  useValueChangeEffect(
    () => {
      // 初期化処理が実行されていない場合のみ処理する

      resetLastReadMessageHook()
      loadChatMessages({
        // 最新のメッセージからOlder方向に取得する
        criterionMessageId: undefined,
        direction: TimelineDirection.Older,
      })
    },
    [loadChatMessages, resetLastReadMessageHook, scrollToMessageId],
    currentChatThread.topicId
  )

  return {
    displayItems,
    itemsScrollableDivRef,
    messageInView,
    messageOutOfView,
  }
}
