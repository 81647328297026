import { memo } from 'react'
import { UserListToolbar } from './components/userListToolbar/userListToolbar'
import { UserListPanel } from './components/userListPanel/userListPanel'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { useSystemPermissions } from '@crew/hooks'

export const UserListPage = memo(() => {
  // Get system permission for logged in user
  const { hasSysUserListViewPermission } = useSystemPermissions()

  // If user does not have permission to view user list, return empty fragment
  if (!hasSysUserListViewPermission) {
    return null
  }

  return (
    <div className="min-h-0 flex-1 flex flex-col grow w-full">
      <div className="min-h-0 flex flex-col gap-4 p-2">
        <CrewErrorBoundary>
          <UserListToolbar />
        </CrewErrorBoundary>
        <CrewErrorBoundary>
          <UserListPanel />
        </CrewErrorBoundary>
      </div>
    </div>
  )
})
