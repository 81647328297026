import { FC, Fragment, memo, useCallback, useRef, useState } from 'react'
import { BookmarkMessageListItem } from './components/bookmarkMessageListItem/bookmarkMessageListItem'
import { useBookmarkMessageList } from './useBookmarkMessageList'
import { useValueChangeEffect } from '@crew/hooks'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { BookmarkFilters } from '@crew/enums/app'
import { useEffectOnce } from '@dx-system/react-use'
import { useSubscribeBookmark } from '@crew/providers/websocket'
import { setRightSideBarSelectedMessageId } from 'features/app/states/appSlice'

export const BookmarkMessageList: FC = memo(() => {
  const dispatch = useAppDispatch()
  const {
    displayItems,
    messageInView,
    searchBookmarkMessages,
    deleteMessage,
    keyword,
    archiveFilter,
  } = useBookmarkMessageList()

  // 返信ボタンで選択されたメッセージのID
  const [selectedMessageId, setSelectedMessageId] = useState<
    string | undefined
  >(undefined)

  // ログインユーザー
  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

  // ブックマーク更新時のイベントメッセージ
  const bookmarkEventMessage = useAppSelector(
    (state) => state.app.bookmarkEventMessage
  )

  // 画面遷移前のスクロール位置
  const scrollToMessageId = useAppSelector(
    (state) => state.message.bookmark.scrollToMessageId
  )

  // メッセージ表示領域のref
  const itemsScrollableDivRef = useRef<HTMLDivElement>(null)

  useValueChangeEffect(
    () => {
      // When the selected message ID changes, set it to the chat panel
      dispatch(setRightSideBarSelectedMessageId(selectedMessageId))

      return () => {
        // When unmounting, reset the selected message ID
        dispatch(setRightSideBarSelectedMessageId(undefined))
      }
    },
    [dispatch, selectedMessageId],
    selectedMessageId
  )

  // 他画面への遷移から戻った際に、スクロール位置を元の位置に戻すための処理
  useEffectOnce(() => {
    // scrollToMessageIdがなければ処理しない
    if (scrollToMessageId === undefined) {
      return
    }

    // スクロール対象のDOM要素
    const displayTargetElement = itemsScrollableDivRef.current?.querySelector(
      `#bookmark_${scrollToMessageId}`
    )
    // スクロール対象のメッセージを表示中アイテムの中から取得する
    const item = displayItems.find((item) => item.id === scrollToMessageId)
    // スクロール対象のメッセージが表示アイテムの中にあり、スクロール対象のDOM要素がある場合のみ処理を行う
    if (item && displayTargetElement) {
      // 表示対象のDOM要素を表示領域内にスクロールする
      displayTargetElement.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      })
    }
  })

  // ログインユーザーが変わったら初期ロードを行う
  useValueChangeEffect(
    () => {
      searchBookmarkMessages()
    },
    [loggedInUser, searchBookmarkMessages],
    loggedInUser
  )

  // archiveFilterが変更されたらブックマークデータを再取得する
  useValueChangeEffect(
    () => {
      searchBookmarkMessages()
    },
    [searchBookmarkMessages],
    archiveFilter,
    true // 初回ロード時には実行しない（スキップする）
  )

  // keywordが変更されたらブックマークデータを再取得する
  useValueChangeEffect(
    () => {
      searchBookmarkMessages()
    },
    [searchBookmarkMessages],
    keyword,
    true // 初回ロード時には実行しない（スキップする）
  )

  // フィルタが「未処理のみ」または「処理済み」を選択中にアーカイブ状態を変更した場合、ViewModelからメッセージを削除する
  useValueChangeEffect(
    () => {
      // ブックマーク変更イベントが発生していないため、何もしない
      if (!bookmarkEventMessage) {
        return
      }

      // フィルタが「未処理のみ」または「処理済み」の場合、viewModelからメッセージ削除
      if (
        archiveFilter === BookmarkFilters.Archived.value ||
        archiveFilter === BookmarkFilters.Unarchive.value
      ) {
        deleteMessage(bookmarkEventMessage.id)
      }

      // アーカイブ状態を変更した場合のみ（=bookmarkEventMessageに値がある）実行したいので、それ以外の依存は設定しない
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [archiveFilter, bookmarkEventMessage, deleteMessage],
    bookmarkEventMessage,
    true // 初回ロード時には実行しない（スキップする）
  )

  // ブックマーク関連のメッセージをwebsocket経由でsubscribeする
  useSubscribeBookmark(dispatch, useAppSelector)

  // メッセージ表示イベントハンドラ
  const handleMessageInView = useCallback(
    (inView: boolean, messageId: string) => {
      if (inView) {
        messageInView(messageId)
      }
    },
    [messageInView]
  )

  // データが存在しない場合
  if (displayItems.length === 0) {
    return null
  }

  return (
    // 「読み込み中」のメッセージは、画面のちらつきの原因になるため、現時点では表示しない
    <div
      className="flex-1 flex-col overflow-y-scroll"
      ref={itemsScrollableDivRef}
    >
      {displayItems.map((item) => (
        <Fragment key={item.id}>
          <BookmarkMessageListItem
            // スクロール位置設定時、数字から始まるidはエラーになるため、idの先頭に文字列を付与する
            id={`bookmark_${item.id}`}
            key={item.id}
            chatMessageId={item.id}
            container={itemsScrollableDivRef}
            onMessageInView={handleMessageInView}
            onAdditionalLoading={item.handleAdditionalLoading}
            selectedItemId={selectedMessageId}
            setSelectedItemId={setSelectedMessageId}
          />
        </Fragment>
      ))}
    </div>
  )
})
