import { FC, memo } from 'react'
import { useProjectGroupEntryForm } from './useProjectGroupEntryForm'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewErrorSummary } from 'components/forms/crewErrorSummary'
import { useTranslation } from '@crew/modules/i18n'
import { useMemo, useCallback, useEffect } from 'react'
import { useGetProjectGroupQuery } from '@crew/apis/project/projectApis'
import { useToast } from 'hooks/useToast'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { skipToken } from '@reduxjs/toolkit/query/react'
import { GetProjectGroupRequest } from '@crew/apis/project/models/getProjectGroup/request'
import { FormValues } from './useProjectGroupEntryForm'

export type ProjectGroupEntryFormProps = {
  isEditMode: boolean
  projectGroupId?: string
  onSubmit: () => void
  onClose: () => void
}

export const ProjectGroupEntryForm: FC<ProjectGroupEntryFormProps> = memo(
  (props) => {
    const {
      control,
      reset,
      formState,
      handleSubmit,
      clearErrors,
      setError,

      validateRules,

      insertProjectGroup,
      updateProjectGroup,
      isLoadingInsertProjectGroup,
      isLoadingUpdateProjectGroup,
    } = useProjectGroupEntryForm()

    const { t } = useTranslation()
    const { success } = useToast()

    const [showApiErrors] = useShowApiErrorsWithForm(setError)

    // プロジェクトグループ取得
    // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
    const getProjectGroupParam: GetProjectGroupRequest | undefined =
      props.projectGroupId
        ? {
            projectGroupId: props.projectGroupId,
          }
        : undefined

    const { data: getProjectGroupQueryResult } = useGetProjectGroupQuery(
      getProjectGroupParam ?? skipToken
    )

    // フォーム初期化処理関数
    const initializeForm = useCallback(() => {
      if (props.isEditMode && props.projectGroupId) {
        if (getProjectGroupQueryResult?.projectGroup) {
          reset({
            id: getProjectGroupQueryResult.projectGroup?.id,
            name: getProjectGroupQueryResult.projectGroup?.name,
            version: getProjectGroupQueryResult.projectGroup?.version,
          })
        }
      }
    }, [
      getProjectGroupQueryResult?.projectGroup,
      props.isEditMode,
      props.projectGroupId,
      reset,
    ])

    // 初期化処理をuseEffect化
    // TODO: 本当はuseEffectを外したいのだが、レンダリングが大量に走ってしまうためこのようにしている。以下タスクで調査・対応予定
    // https://break-tmc.atlassian.net/browse/CREW-6028
    useEffect(() => {
      // フォーム初期化を実行
      initializeForm()
    }, [initializeForm])

    // 登録ボタン押下
    const handleSubmitButtonClick = useCallback(() => {
      const onSubmit = async (data: FormValues) => {
        try {
          if (props.isEditMode) {
            const result = await updateProjectGroup(data)
            success(
              t('message.projectGroup.projectGroupUpdated', {
                projectGroup: result.projectGroup,
              })
            )
          } else {
            const result = await insertProjectGroup(data)

            success(
              t('message.projectGroup.projectGroupRegistered', {
                projectGroup: result.projectGroup,
              })
            )
          }
          reset()
          clearErrors()
          props.onSubmit && props.onSubmit()
        } catch (err) {
          showApiErrors(err)
        }
      }
      handleSubmit(onSubmit)()
    }, [
      clearErrors,
      handleSubmit,
      insertProjectGroup,
      props,
      reset,
      showApiErrors,
      success,
      t,
      updateProjectGroup,
    ])

    // プロジェクトグループダイアログのキャンセルボタン押下
    const handleCancelButtonClick = useCallback(() => {
      props.onClose()
    }, [props])

    const canSend = useMemo(
      // fromState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
      () =>
        Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
      // formStateはproxyなのでformState自体をlistenする必要がある
      // https://react-hook-form.com/api/useform/formstate
      [formState]
    )

    return (
      <form>
        <div>
          <div className="h-20">
            <CrewTextBoxField
              id="name"
              name="name"
              control={control}
              labelMode="hidden"
              label={t('label.name')}
              required={true}
              valueChangeEvent="keyup"
              rules={validateRules.name}
            />
          </div>
          <CrewErrorSummary formState={formState} />
        </div>
        <div className="flex justify-between items-center mt-3">
          <div className="ml-auto flex gap-x-5">
            <CrewButton
              text={t('action.register')}
              type="primary"
              disabled={
                !canSend ||
                isLoadingInsertProjectGroup ||
                isLoadingUpdateProjectGroup
              }
              onClick={handleSubmitButtonClick}
            />
            <CrewButton
              text={t('action.cancel')}
              type="normal"
              stylingMode="outlined"
              onClick={handleCancelButtonClick}
            />
          </div>
        </div>
      </form>
    )
  }
)
