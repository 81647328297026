import { memo } from 'react'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { ProjectEntryDialog } from 'features/project/components/projectEntryDialog/projectEntryDialog'
import Groups from '~icons/material-symbols/groups'
import { useSystemPermissions } from '@crew/hooks'
import { ProjectSearch } from './components/projectSearch/projectSearch'
import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { displayModeUpdated } from 'features/project/components/projectListPage/states/projectListPageSlice'
import { useTranslation } from '@crew/modules/i18n'
import { ProjectListPanelDisplayMode, ProjectListTabs } from 'enums/app'
import { useModal } from 'components/layouts/modal/useModal'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { getDefaultTabValue } from '@crew/utils/enum'
import { CrewButtonGroup } from 'components/elements/crewButtonGroup/crewButtonGroup'

type ButtonGroupInfo = {
  index: number
  text: string | undefined
  icon: JSX.Element | undefined
}

export const ProjectListToolbar = memo(() => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { navigate } = useCrewNavigate()

  const [
    isProjectEntryDialogOpen,
    openProjectEntryDialog,
    closeProjectEntryDialog,
  ] = useModal()

  // 表示形式（リスト・カード）の選択状態
  const displayMode = useAppSelector(
    (state) => state.projectListPage.displayMode
  )

  // Get display mode button group
  const projectListDisplayModeButtonGroup: ButtonGroupInfo[] = useMemo(() => {
    const items = Object.values(ProjectListPanelDisplayMode).map((item) => {
      const SvgIcon = item.icon
      return {
        index: item.id,
        text: undefined,
        icon: <SvgIcon width={24} height={24} />,
      }
    })

    return items
  }, [])

  // Handle click display mode button to show project list
  const handleProjectListDisplayModeItemClick = useCallback(
    (itemData: ButtonGroupInfo) => {
      dispatch(displayModeUpdated(itemData.index))
    },
    [dispatch]
  )
  // Register project finish
  const handleProjectRegistered = useCallback(
    (projectId: string) => {
      // プロジェクト詳細画面に遷移
      navigate(`/projects/${projectId}/${getDefaultTabValue(ProjectListTabs)}`)
      // Close entry dialog
      closeProjectEntryDialog()
    },
    [closeProjectEntryDialog, navigate]
  )

  // Get permission for create and delete project
  const { hasSysProjectCreateDeletePermission } = useSystemPermissions()

  return (
    <>
      <div className="w-full flex gap-x-2.5 justify-between items-center">
        {/* ページタイトル */}
        <div className="flex gap-2 items-center h-8 text-crew-gray-3-light dark:text-crew-gray-2-dark">
          <Groups width={24} height={24} />
          <p className="font-bold">{t('label.project') + t('label.list')}</p>
        </div>
        <div className="flex gap-x-1 ml-auto">
          {/* 表示切り替えボタン */}
          <CrewButtonGroup
            items={projectListDisplayModeButtonGroup}
            keyExpr="index"
            textExpr="text"
            iconExpr="icon"
            stylingMode="text"
            selectedItemKey={displayMode}
            onItemClick={handleProjectListDisplayModeItemClick}
          />

          {/* 新規プロジェクトボタン */}
          <div className="flex flex-1">
            {hasSysProjectCreateDeletePermission && (
              <CrewButton
                type="primary"
                className="ml-auto"
                onClick={openProjectEntryDialog}
                text={t('action.projectNew')}
              />
            )}
          </div>
        </div>
      </div>
      <ProjectEntryDialog
        isEditMode={false}
        title={t('label.projectAddTitle')}
        isOpen={isProjectEntryDialogOpen}
        onClose={closeProjectEntryDialog}
        onSubmit={handleProjectRegistered}
      />

      <div className="flex-1 flex gap-x-2.5 items-center">
        <ProjectSearch />
      </div>
    </>
  )
})
