import { memo, useCallback, useMemo } from 'react'
import { useContractPortalPage } from './useContractPortalPage'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import {
  BillingCycle,
  PaymentMethod,
  StripeSubscriptionStatus,
  ContractPlan,
} from '@crew/enums/app'
import { formatByteSize, formatContractStorageSize } from '@crew/utils/number'
import { useSystemPermissions } from '@crew/hooks'
import { useTranslation } from '@crew/modules/i18n'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useGetContractQuery } from '@crew/apis/contract/contractApis'
import { useShowApiErrors } from 'hooks/useShowApiErrors'

/**
 * ContractPortalPage
 * @date 8/16/2023 - 1:28:59 PM
 *
 * @type {*}
 */
export const ContractPortalPage = memo(() => {
  const { createPortalSession, isLoadingCreatePortalSession } =
    useContractPortalPage()

  const { t } = useTranslation()
  const { navigate } = useCrewNavigate()
  const [showApiErrors] = useShowApiErrors()

  // Stripe のポータルサイトを開く
  const openBillingPortal = useCallback(async () => {
    const response = await createPortalSession()
    response.url && window.open(response.url, '_blank', 'noreferrer')
  }, [createPortalSession])

  // go to upgrade plan page
  const handleChangePlanButtonClick = useCallback(() => {
    navigate('/contract/upgrade-plan')
  }, [navigate])

  // go change storage page
  const handleChangeStorageButtonClick = useCallback(() => {
    navigate('/contract/change-storage')
  }, [navigate])

  // 「招待可能数の拡大」ボタンクリック時の処理
  const handleChangeExternlUserCountClick = useCallback(() => {
    navigate('/contract/purchase-external-user')
  }, [navigate])

  // go  change billing cycle page
  const handleChangeBillingCycleButtonClick = useCallback(() => {
    navigate('/contract/change-cycle')
  }, [navigate])

  //「連絡先情報の変更」ボタンクリック時の処理
  const handleChangeContactInformationButtonClick = useCallback(() => {
    navigate('/contract/change-contact-information')
  }, [navigate])

  // 「支払方法の登録」「支払方法の変更」ボタンクリック時の処理
  const handleRegisterPaymentMethodButtonClick = useCallback(() => {
    // 支払方法登録画面へ遷移
    navigate('/contract/register-payment-method')
  }, [navigate])

  // 「請求先情報の変更」ボタンクリック時の処理
  const handleChangeBillingAddressButtonClick = useCallback(() => {
    // 請求先情報変更画面へ遷移
    navigate('/contract/change-billing-address')
  }, [navigate])

  // 「請求ポータル」ボタンクリック時の処理
  const handleBillingPortalButtonClick = useCallback(async () => {
    try {
      // Stripeポータルサイトを開く
      await openBillingPortal()
    } catch (error) {
      showApiErrors(error)
    }
  }, [openBillingPortal, showApiErrors])

  // get contract data information
  const { data: getContractResult, isLoading } = useGetContractQuery()

  // トライアル中の場合は表示コンポーネントを制御する必要があるため、トライアル中かどうかを保持しておく
  const isTrialing =
    getContractResult?.contract.currentPlan.subscriptionStatus ===
    StripeSubscriptionStatus.Trialing

  const isFree =
    getContractResult?.contract.currentPlan.plan === ContractPlan.Free

  const isPaid = !isTrialing && !isFree

  //get current plan label
  const getCurrentPlan = useCallback(() => {
    switch (getContractResult?.contract.currentPlan.plan) {
      case ContractPlan.Professional: {
        return t('label.planType.professional')
      }
      case ContractPlan.Standard: {
        return t('label.planType.standard')
      }
      case ContractPlan.Free: {
        return t('label.planType.free')
      }
      default: {
        return '-'
      }
    }
  }, [t, getContractResult?.contract.currentPlan.plan])

  // 支払方法名
  const paymentMethodName = useMemo(() => {
    switch (getContractResult?.contract.paymentMethod) {
      case PaymentMethod.CreditCard: {
        return t('label.creditCard')
      }
      case PaymentMethod.BankTransfer: {
        return t('label.bankTransfer')
      }
      case PaymentMethod.NotSet: {
        return t('label.notSet')
      }
      default: {
        return '-'
      }
    }
  }, [getContractResult?.contract.paymentMethod, t])

  // 請求先情報の未設定チェック
  const hasBillingAddress = useMemo(() => {
    return (
      getContractResult?.contract.billing.country ||
      getContractResult?.contract.billing.postalCode ||
      getContractResult?.contract.billing.state ||
      getContractResult?.contract.billing.city ||
      getContractResult?.contract.billing.line1 ||
      getContractResult?.contract.billing.line2
    )
  }, [
    getContractResult?.contract.billing.city,
    getContractResult?.contract.billing.country,
    getContractResult?.contract.billing.line1,
    getContractResult?.contract.billing.line2,
    getContractResult?.contract.billing.postalCode,
    getContractResult?.contract.billing.state,
  ])

  const { hasSysContractViewEditPermission } = useSystemPermissions()

  return (
    hasSysContractViewEditPermission &&
    !isLoading && (
      <div className="flex-col justify-start items-center inline-flex">
        <div className="flex-col justify-start items-start flex w-full max-w-2xl">
          {/*ご契約中のプラン */}
          <div className="flex-col w-full gap-2.5 pb-2.5 border-b crew-border-gray ">
            <div className="flex-col justify-start items-start py-2.5 flex">
              <span className="font-bold">{t('label.currentPlan')}</span>
            </div>
            {/* 基本プラン */}
            <div className="flex-row w-full justify-start items-center gap-2.5 py-1 flex">
              <div className="w-1/3">{t('label.basicPlan')}</div>
              <div className="flex-col w-1/3 items-start inline-flex">
                <span>{`${getCurrentPlan()}`}</span>
                {/* 「トライアル中」の表示 */}
                {isTrialing && (
                  <span className="text-sm text-crew-red-3-light dark:text-crew-red-3-dark">
                    {t('label.isTrialing', {
                      dueDate: getContractResult?.contract.currentPlan.trialEnd,
                    })}
                  </span>
                )}
              </div>
              <div className="flex-col w-1/3 items-end inline-flex overflow-hidden">
                {isFree ? (
                  /* アップグレードボタン */
                  <CrewButton
                    text={t('label.upgradeContractPlan')}
                    type="normal"
                    stylingMode="outlined"
                    className="min-w-full"
                    onClick={handleRegisterPaymentMethodButtonClick}
                  />
                ) : isTrialing ? (
                  /* 支払方法登録ボタン */
                  <CrewButton
                    text={t('label.registerPaymentMethod')}
                    type="normal"
                    stylingMode="outlined"
                    className="min-w-full"
                    onClick={handleRegisterPaymentMethodButtonClick}
                  />
                ) : (
                  /* 契約プラン変更ボタン */
                  <CrewButton
                    text={t('label.changeContractPlan')}
                    type="normal"
                    stylingMode="outlined"
                    className="min-w-full"
                    onClick={handleChangePlanButtonClick}
                  />
                )}
              </div>
            </div>
            {/* ストレージ容量 */}
            <div className="flex-row w-full justify-start items-center gap-2.5 py-1 flex">
              <div className="w-1/3">{t('label.storageCapacity')}</div>
              <div className="flex-col items-start w-1/3 inline-flex">
                {formatContractStorageSize(
                  getContractResult?.contract.currentPlan.storageCapacity ?? 0
                ) ?? '-'}
              </div>
              {/*容量の変更（トライアル中は表示しない）*/}
              <div className="flex-col w-1/3 items-end inline-flex overflow-hidden">
                {isPaid && (
                  <CrewButton
                    text={t('label.changeCapacity')}
                    type="normal"
                    stylingMode="outlined"
                    className="min-w-full"
                    onClick={handleChangeStorageButtonClick}
                  />
                )}
              </div>
            </div>
            {/* 外部ユーザー上限数 */}
            <div className="flex-row w-full justify-start items-center gap-2.5 py-1 flex">
              <div className="w-1/3">{t('label.externUserLimitTitle')}</div>
              <div className="flex-col items-start w-1/3 inline-flex">
                {t('label.externUserLimitValue', {
                  baseExternalUsers:
                    getContractResult?.contract.currentPlan.maxExternalUsers
                      .baseExternalUsers ?? '-',
                  addingExternalUsers:
                    getContractResult?.contract.currentPlan.maxExternalUsers
                      .additionalContractedExternalUsers ?? '-',
                })}
              </div>
              {/*招待可能数の拡大（トライアル中は表示しない）*/}
              <div className="flex-col w-1/3 items-end inline-flex overflow-hidden">
                {isPaid && (
                  <CrewButton
                    text={t('label.changeExternlUserCount')}
                    type="normal"
                    stylingMode="outlined"
                    className="min-w-full"
                    onClick={handleChangeExternlUserCountClick}
                  />
                )}
              </div>
            </div>
            {/* 契約サイクル */}
            <div className="flex-row w-full justify-start items-center gap-2.5 py-1 flex">
              <div className="w-1/3">{t('label.contractCycle')}</div>
              <div className="flex-col items-start w-1/3 inline-flex">
                <span>
                  {getContractResult?.contract.currentPlan.billingCycle ===
                  BillingCycle.Year
                    ? t('label.year')
                    : t('label.month')}
                </span>
              </div>
              {/*請求サイクルの変更（トライアル中は表示しない） */}
              <div className="flex-col w-1/3 items-end inline-flex overflow-hidden">
                {isPaid && (
                  <CrewButton
                    text={t('label.changeBillingCycle')}
                    type="normal"
                    stylingMode="outlined"
                    className="min-w-full"
                    onClick={handleChangeBillingCycleButtonClick}
                  />
                )}
              </div>
            </div>
          </div>
          {/* 連絡先情報 */}
          <div className="flex-col w-full gap-2.5 pb-2.5 border-b crew-border-gray ">
            <div className="flex-col justify-start items-start py-2.5 flex">
              <span className="font-bold">{t('label.contactInformation')}</span>
            </div>
            {/* 会社名 */}
            <div className="flex-row w-full justify-start items-center gap-2.5 py-2.5 flex">
              <div className="w-1/3">{t('label.companyName')}</div>
              <div className="flex-col items-start w-1/3 inline-flex">
                {getContractResult?.contract.contact.companyName ?? '-'}
              </div>
              {/* 連絡先情報の変更 */}
              <div className="flex-col w-1/3 items-end inline-flex overflow-hidden">
                <CrewButton
                  text={t('label.changeContactInformation')}
                  type="normal"
                  stylingMode="outlined"
                  className="min-w-full"
                  onClick={handleChangeContactInformationButtonClick}
                />
              </div>
            </div>
            {/* 担当者名 */}
            <div className="flex-row w-full justify-start items-center gap-2.5 py-2.5 flex">
              <div className="w-1/3">{t('label.contactName')}</div>
              <div className="flex-col items-start w-1/3 inline-flex">
                {getContractResult?.contract.contact.contactName ?? '-'}
              </div>
              {/* 中央列の左位置を揃えるために、空のdivを配置する */}
              <div className="w-1/3"></div>
            </div>
            {/* 電話番号 */}
            <div className="flex-row w-full justify-start items-center gap-2.5 py-2.5 flex">
              <div className="w-1/3">{t('label.tel')}</div>
              <div className="flex-col items-start w-1/3 inline-flex">
                {getContractResult?.contract.contact.tel ?? '-'}
              </div>
              {/* 中央列の左位置を揃えるために、空のdivを配置する */}
              <div className="w-1/3"></div>
            </div>
          </div>

          {/*請求情報 */}
          {isPaid && (
            <div className="flex-col w-full gap-2.5 pb-2.5 border-b crew-border-gray ">
              <div className="flex-col justify-start items-start py-2.5 flex">
                <span className="font-bold">
                  {t('label.requestInformation')}
                </span>
              </div>
              {/* 支払方法 */}
              <div className="flex-row w-full justify-start items-center gap-2.5 py-2.5 flex">
                <div className="w-1/3">{t('label.paymentMethod')}</div>
                <div className="flex-col items-start w-1/3 inline-flex">
                  {paymentMethodName}
                </div>
                {/* 支払方法の変更 */}
                <div className="flex-col w-1/3 items-end inline-flex overflow-hidden">
                  <CrewButton
                    text={t('label.changePaymentMethod')}
                    type="normal"
                    stylingMode="outlined"
                    className="min-w-full"
                    onClick={handleRegisterPaymentMethodButtonClick}
                  />
                </div>
              </div>
              {/* 請求先情報 */}
              <div className="flex-row w-full justify-start items-center gap-2.5 py-1 flex">
                <div className="w-1/3">{t('label.billingAddress')}</div>
                <div className="flex-col items-start w-1/3 inline-flex">
                  {hasBillingAddress ? (
                    <>
                      <div>
                        {getContractResult?.contract.billing.postalCode}
                      </div>
                      <div>{getContractResult?.contract.billing.state}</div>
                      <div>{getContractResult?.contract.billing.city}</div>
                      <div>{getContractResult?.contract.billing.line1}</div>
                      <div>{getContractResult?.contract.billing.line2}</div>
                      {/* TODO: 国コードが翻訳されていない。下記タスクで対応されるまではアルファベット2文字の国コードで表示される
                          https://break-tmc.atlassian.net/browse/CREW-13270 */}
                      <div>{getContractResult?.contract.billing.country}</div>
                    </>
                  ) : (
                    <div>-</div>
                  )}
                </div>
                {/* 請求先情報の変更（トライアル中は表示しない） */}
                <div className="flex-col w-1/3 items-end inline-flex overflow-hidden">
                  {!isTrialing && (
                    <CrewButton
                      text={t('label.changeBillingAddress')}
                      type="normal"
                      stylingMode="outlined"
                      className="min-w-full"
                      onClick={handleChangeBillingAddressButtonClick}
                    />
                  )}
                </div>
              </div>
              {/* 請求先ポータル */}
              <div className="flex-row w-full justify-start items-center gap-2.5 py-1 flex">
                <div className="w-2/3">{t('label.requestDescription')}</div>
                <div className="flex-col w-1/3 items-end inline-flex overflow-hidden">
                  {/*請求ポータル */}
                  <CrewButton
                    text={t('label.billingPortal')}
                    type="normal"
                    stylingMode="outlined"
                    className="min-w-full"
                    onClick={handleBillingPortalButtonClick}
                    disabled={isLoadingCreatePortalSession}
                  />
                </div>
              </div>
            </div>
          )}
          {/*使用状況 */}
          <div className="flex-col w-full gap-2.5 pb-2.5 border-b crew-border-gray ">
            <div className="flex-col justify-start items-start py-2.5 flex">
              <span className="font-bold">{t('label.usageStatus')}</span>
            </div>
            {/*ユーザー数 */}
            <div className="flex-row w-full justify-start items-center gap-2.5 py-2.5 flex">
              <div className="w-1/3">{t('label.numberOfUser')}</div>
              <div className="flex-col items-start w-2/3 inline-flex">
                {`${getContractResult?.contract.usage.users ?? '-'}${t(
                  'label.user'
                )}`}
              </div>
            </div>
            {/*外部ユーザー数 */}
            <div className="flex-row w-full justify-start items-center gap-2.5 py-2.5 flex">
              <div className="w-1/3">{t('label.numberOfExternalUser')}</div>
              <div className="flex-col items-start w-2/3 inline-flex">
                {`${getContractResult?.contract.usage.externalUsers ?? '-'}${t(
                  'label.user'
                )}`}
              </div>
            </div>
            {/*ストレージ */}
            <div className="flex-row w-full justify-start items-center gap-2.5 py-2.5 flex">
              <div className="w-1/3">{t('label.storage')}</div>
              <div className="flex-col items-start w-2/3 inline-flex">
                {formatByteSize(
                  getContractResult?.contract.usage.storage ?? 0
                ) ?? '-'}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
})
