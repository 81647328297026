import { UpdateUserPresenceStateRequest } from '@crew/apis/userState/models/updateUserPresenceState/request'
import { useUpdateUserPresenceStateMutation } from '@crew/apis/userState/userStateApis'
import { useTranslation } from '@crew/modules/i18n'
import { ValidateRules } from '@crew/utils/form'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'

export type FormValues = {
  presenceStateId: string
  presenceStateMessage: string | null
}

export const usePresenceSettingForm = () => {
  const { t } = useTranslation()
  // react-hook-formの各種データを取得
  const { control, reset, handleSubmit } = useForm<FormValues>({
    criteriaMode: 'all',
  })

  const [
    updateUserPresenceStateMutation,
    { isLoading: isLoadingUpdateUserPresenceState },
  ] = useUpdateUserPresenceStateMutation()

  // ユーザ在籍状態を更新
  const updateUserPresenceState = useCallback(
    async (data: FormValues) => {
      const payload: UpdateUserPresenceStateRequest = {
        userPresenceState: {
          presenceStateId: data.presenceStateId,
          presenceStateMessage: data.presenceStateMessage ?? undefined,
        },
      }

      await updateUserPresenceStateMutation(payload).unwrap()
    },
    [updateUserPresenceStateMutation]
  )

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      presenceStateMessage: {
        maxLength: {
          value: 20,
          message: t('message.general.maxLength', {
            name: t('label.message'),
            value: 20,
          }),
        },
      },

      presenceStateId: {},
    }),
    [t]
  )

  return {
    control,
    reset,
    handleSubmit,
    updateUserPresenceState,
    isLoadingUpdateUserPresenceState,
    validateRules,
  }
}
