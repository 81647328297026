import { memo, useRef, useState } from 'react'
import { useSearchChatResultList } from './useSearchChatResultList'
import { SearchChatResultListItem } from './components/searchChatResultListItem/searchChatResultListItem'
import { useValueChangeEffect } from '@crew/hooks'
import { useAppDispatch } from 'states/hooks'
import { setRightSideBarSelectedMessageId } from 'features/app/states/appSlice'

export const SearchChatResultList = memo(() => {
  const dispatch = useAppDispatch()
  const { keyword, displayItems } = useSearchChatResultList()

  // メッセージ表示領域のref
  const itemsScrollableDivRef = useRef<HTMLDivElement>(null)

  // 返信ボタンで選択されたメッセージのID
  const [selectedMessageId, setSelectedMessageId] = useState<
    string | undefined
  >(undefined)

  useValueChangeEffect(
    () => {
      // When the selected message ID changes, set it to the chat panel
      dispatch(setRightSideBarSelectedMessageId(selectedMessageId))

      return () => {
        // When unmounting, reset the selected message ID
        dispatch(setRightSideBarSelectedMessageId(undefined))
      }
    },
    [dispatch, selectedMessageId],
    selectedMessageId
  )

  return (
    // 検索結果のメッセージ一覧(上から新しいメッセージ→古いメッセージで表示)
    <div ref={itemsScrollableDivRef} className="grow flex overflow-y-scroll">
      <div className="grow flex flex-col">
        {displayItems.map((item) => (
          <SearchChatResultListItem
            key={item.id}
            id={item.id}
            chatMessageId={item.messageId}
            highlightKeyword={keyword ?? ''}
            onAdditionalLoading={item.handleAdditionalLoading}
            container={itemsScrollableDivRef}
            selectedItemId={selectedMessageId}
            setSelectedItemId={setSelectedMessageId}
          />
        ))}
      </div>
    </div>
  )
})
