import { EntityType } from '@crew/enums/domain'
import { useTranslation } from '@crew/modules/i18n'
import { formatByteSize } from '@crew/utils/number'
import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  Row,
  RowSelectionState,
  SortingState,
  TableOptions,
  getCoreRowModel,
} from '@tanstack/react-table'
import { CrewFileAction } from 'components/elements/crewFileAction/crewFileAction'
import { CrewFileChangerItem } from 'components/elements/crewFileChangerItem/crewFileChangerItem'
import { CrewFileName } from 'components/elements/crewFileName/crewFileName'
import {
  CrewTable,
  SelectCheckbox,
} from 'components/elements/crewTable/crewTable'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { File } from '@crew/apis/project/models/getProjectFiles/response'
import { CrewFileTag } from 'components/elements/crewFileTag/crewFileTag'
import {
  ObjectEventMessage,
  notifyFileEvent,
} from 'features/app/states/appSlice'
import {
  useBulkDeleteFileMutation,
  useDeleteFileMutation,
  useLazyDownloadFilesQuery,
  useUpdateFolderMutation,
} from '@crew/apis/file/fileApis'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { useToast } from 'hooks/useToast'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import qs from 'qs'
import { useModal } from 'components/layouts/modal/useModal'
import { getParamAsArray, getParamAsDate, getParamAsString } from 'utils'
import {
  DetailFileSearchOptions,
  DisplayAnonymousFile,
  FileListDisplayMode,
  NotifyEventType,
} from 'enums/app'
import _ from 'lodash'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { FileListTagsEntryDialog } from 'features/file/components/fileListPage/components/fileListTagsEntryDialog/fileListTagsEntryDialog'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { DEFAULT_PAGING_PARAMS } from 'configs/constants'
import { skipToken } from '@reduxjs/toolkit/query'
import { CrewRelatedItemLink } from 'components/elements/crewRelatedItemLink/crewRelatedItemLink'
import { GetFolderFilesRequest } from '@crew/apis/folder/models/getFolderFiles/request'
import { useGetFolderFilesQuery } from '@crew/apis/folder/folderApis'
import { CrewSortable } from 'components/devextreme/crewSortable'
import { GetProjectFilesRequest } from '@crew/apis/project/models/getProjectFiles/request'
import { useGetProjectFilesQuery } from '@crew/apis/project/projectApis'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { DirectMessageFileMoveDialog } from '../directMessageFileMoveDialog/directMessageFileMoveDialog'
import { CrewPagination } from 'components/elements/crewPagination/crewPagination'
import { CrewGridFileItem } from 'components/elements/crewGridFileItem/crewGridFileItem'
import { useValueChangeEffect } from '@crew/hooks'
import FolderTransferLine from '~icons/ri/folder-transfer-line'
import BaselineSaveAlt from '~icons/ic/baseline-save-alt'
import RoundLocalOffer from '~icons/ic/round-local-offer'
import BaselineDelete from '~icons/ic/baseline-delete'
import { downloadFileFromUrl } from 'utils'

// renderとして使うのでmemo不可
const SizeText: FC<{ size: number }> = (props) => (
  <>{formatByteSize(props.size)}</>
)

// renderとして使うのでmemo不可
const UpdateAt: FC<{ date: string }> = (props) => {
  const [t] = useTranslation()

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  return (
    <>
      {props &&
        t('format.timestamp', {
          value: props.date,
          timeZone: defaultUserProfileRegion,
        })}
    </>
  )
}

type SelectedFile = {
  id: string
  entityRecordId: string
  version: number
}

type FileTableProps = {
  folderId: string
  isShowSearchCondition: boolean
}

export const FileTable: FC<FileTableProps> = memo((props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { success, error, warn } = useToast()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { projectId } = useParams()

  const [showApiErrors] = useShowApiErrors()

  const params = qs.parse(searchParams.toString())

  const fileEventMessage = useAppSelector((state) => state.app.fileEventMessage)
  // 選択中の表示形式
  const selectedFileListDisplayMode = useAppSelector(
    (state) => state.projectDetail.selectedFileListDisplayMode
  )

  const defaultListDisplayNumber = useUserSetting(
    SettingKeyType.ListDisplayNumber,
    DEFAULT_PAGING_PARAMS.pageSize
  )

  const [isOpenTagsEntryDialog, openTagsEntryDialog, closeTagsEntryDialog] =
    useModal()
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()
  const [
    isOpenDirectMessageFileMoveDialog,
    openDirectMessageFileMoveDialog,
    closeDirectMessageFileMoveDialog,
  ] = useModal()

  const [
    isSingleDeleteFileConfirmDialogOpen,
    openSingleDeleteFileConfirmDialog,
    closeSingleDeleteFileConfirmDialog,
  ] = useModal()

  const [fileRemove, setFileRemove] = useState<File>()

  const [deleteFileMutation, { isLoading: isLoadingDeleteFile }] =
    useDeleteFileMutation()
  const [bulkDeleteFileMutation, { isLoading: isLoadingBulkDeleteFile }] =
    useBulkDeleteFileMutation()
  const [updateFolderMutation] = useUpdateFolderMutation()
  const [lazyDownloadFiles] = useLazyDownloadFilesQuery()

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  // Get list project ID
  const projectIds = useMemo(() => (projectId ? [projectId] : []), [projectId])

  const pagination: PaginationState = useMemo(
    () => ({
      pageIndex: Number(
        getParamAsString('pageIndex', params) ?? DEFAULT_PAGING_PARAMS.pageIndex
      ),
      pageSize: Number(
        getParamAsString('pageSize', params) ?? defaultListDisplayNumber
      ),
    }),
    [defaultListDisplayNumber, params]
  )

  const sorting: SortingState = useMemo(() => {
    const sortArray = getParamAsArray('sort', params) || []
    return sortArray.map((sort) => {
      const [id, direction] = sort.split('.')
      return {
        id,
        desc: direction === 'desc',
      }
    })
  }, [params])

  const [columnVisibility, setColumnVisibility] = useState({})
  const [columnPinning] = useState({
    left: ['select'],
    right: ['action'],
  })

  // 無名ファイル
  const isIncludesAnonymousFiles = useMemo(() => {
    if (!getParamAsString(DetailFileSearchOptions.AnonymousFile.id, params)) {
      return undefined
    }
    return (
      getParamAsString(DetailFileSearchOptions.AnonymousFile.id, params) ===
      DisplayAnonymousFile.Display.value
    )
  }, [params])

  const requestSearchParams: GetProjectFilesRequest | undefined =
    projectId && props.isShowSearchCondition
      ? {
          projectId,
          keyword: getParamAsString(DetailFileSearchOptions.Keyword.id, params),
          tagIds: getParamAsArray(DetailFileSearchOptions.Tag.id, params),
          createdById: getParamAsString(
            DetailFileSearchOptions.CreatedById.id,
            params
          ),
          updatedById: getParamAsString(
            DetailFileSearchOptions.UpdatedById.id,
            params
          ),
          createdAt: getParamAsDate(
            DetailFileSearchOptions.CreatedAt.id,
            params
          ),
          updatedAt: getParamAsDate(
            DetailFileSearchOptions.UpdatedAt.id,
            params
          ),
          limit: pagination.pageSize,
          offset: pagination.pageIndex * pagination.pageSize,
          sort: searchParams.getAll('sort') || undefined,
          isIncludesAnonymousFiles,
          folderId: getParamAsString(DetailFileSearchOptions.Folder.id, params),
        }
      : undefined

  const { data: getProjectFilesResult, refetch: getProjectFileRefetch } =
    useGetProjectFilesQuery(requestSearchParams ?? skipToken)

  const requestParams: GetFolderFilesRequest | undefined =
    projectId && !props.isShowSearchCondition
      ? {
          entityType: EntityType.Project,
          entityRecordId: projectId,
          folderId: props.folderId,
          limit: pagination.pageSize,
          offset: pagination.pageIndex * pagination.pageSize,
          sort: searchParams.getAll('sort') || undefined,
        }
      : undefined

  const { data: getFilesResult, refetch: getFilesRefetch } =
    useGetFolderFilesQuery(requestParams ?? skipToken)

  // refresh file list
  useEffect(() => {
    if (props.isShowSearchCondition) {
      getProjectFileRefetch()
    } else {
      getFilesRefetch()
    }
  }, [
    getFilesRefetch,
    fileEventMessage,
    props.isShowSearchCondition,
    getProjectFileRefetch,
    selectedFileListDisplayMode,
  ])

  // reset row selection when change display mode
  useValueChangeEffect(
    () => {
      // reset row selection
      setRowSelection({})
    },
    [],
    selectedFileListDisplayMode
  )

  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([])
  // get selected files from row selection
  useValueChangeEffect(
    () => {
      if (props.isShowSearchCondition) {
        if (!getProjectFilesResult?.projectFiles) {
          setSelectedFiles([])
        } else {
          setSelectedFiles(
            getProjectFilesResult.projectFiles.filter((file) => {
              return rowSelection[file.id]
            })
          )
        }
      } else {
        if (!getFilesResult?.files) {
          setSelectedFiles([])
        } else {
          setSelectedFiles(
            getFilesResult.files.filter((file) => {
              return rowSelection[file.id]
            })
          )
        }
      }
    },
    [
      getFilesResult?.files,
      getProjectFilesResult?.projectFiles,
      props.isShowSearchCondition,
      rowSelection,
    ],
    rowSelection
  )

  // get selected file ids from selected files
  const selectedFileIds = useMemo(() => {
    return selectedFiles.map((row) => row.id)
  }, [selectedFiles])

  const pageCount = Math.ceil(
    ((props.isShowSearchCondition
      ? getProjectFilesResult?.totalCount
      : getFilesResult?.totalCount) ?? 0) / pagination.pageSize
  )

  // Handle open confirm dialog to bulk delete file
  const handleOpenConfirmBulkDeleteFileButtonClick = useCallback(() => {
    setConfirmMessage(t('message.file.bulkDeleteConfirm'))
    openConfirmDialog()
  }, [openConfirmDialog, t])

  // Press submit confirmation button to delete file
  const handleSubmitPermitDeleteFileButtonClick = useCallback(async () => {
    try {
      // Request to bulk delete file
      await bulkDeleteFileMutation({
        files: selectedFiles.map((item: SelectedFile) => {
          return {
            fileId: item.id,
            version: item.version,
          }
        }),
      }).unwrap()
      const objectEventMessage: ObjectEventMessage<File> = {
        eventType: NotifyEventType.Deleted,
        id: selectedFiles[0].id,
        object: undefined,
      }
      dispatch(notifyFileEvent(objectEventMessage))

      // reset row selection
      setRowSelection({})

      success(t('message.file.deleteSuccess'))
    } catch (err) {
      error(t('message.general.failedToDelete'))
    }
    closeConfirmDialog()
  }, [
    bulkDeleteFileMutation,
    closeConfirmDialog,
    dispatch,
    error,
    selectedFiles,
    success,
    t,
  ])

  // Click button download request to link download bulk file
  const handleBulkFileDownloadButtonClick = useCallback(async () => {
    // Get signed url
    const response = await lazyDownloadFiles({
      fileIds: selectedFileIds,
    })

    if (!response.data) {
      error(t('message.general.failedToDownload'))
      return
    }

    // download file from signed url
    downloadFileFromUrl(response.data.url, response.data.fileName)
  }, [error, lazyDownloadFiles, selectedFileIds, t])

  // https://github.com/TanStack/table/discussions/3899
  // https://github.com/TanStack/table/discussions/3619
  // https://github.com/infonomic/remix.infonomic.io/blob/d3a7f628d3ad6e1e80cc80d4ac72db74da90e8d6/app/routes/admin%2B/users.tsx#L116
  // Func handle change pagination
  const handlePaginationChange: OnChangeFn<PaginationState> = useCallback(
    (updaterOrValue) => {
      let values: PaginationState
      if (updaterOrValue instanceof Function) {
        values = updaterOrValue(pagination)
      } else {
        values = updaterOrValue
      }

      const newParams = {
        ...params,
        pageIndex: values.pageIndex,
        pageSize: values.pageSize,
      }

      // paramsが変わっていない場合はnavigateしない
      if (_.isEqual(params, newParams)) return

      const newQueryString = qs.stringify(newParams, {
        arrayFormat: 'repeat',
        skipNulls: true,
      })
      navigate(`?${newQueryString}`)
    },
    [navigate, pagination, params]
  )

  // Func handle change sorting
  const handleSortingChange: OnChangeFn<SortingState> = useCallback(
    (updaterOrValue) => {
      let values: SortingState
      if (updaterOrValue instanceof Function) {
        values = updaterOrValue(sorting)
      } else {
        values = updaterOrValue
      }

      const sortList = values.map((sort) => {
        return `${sort.id}.${sort.desc ? 'desc' : 'asc'}`
      })

      const newParams = {
        ...params,
        sort: sortList,
      }

      // paramsが変わっていない場合はnavigateしない
      if (_.isEqual(params, newParams)) return

      const newQueryString = qs.stringify(newParams, {
        arrayFormat: 'repeat',
        skipNulls: true,
      })
      navigate(`?${newQueryString}`)
    },
    [navigate, params, sorting]
  )

  // Handle open confirm dialog to delete file
  const handleSingleDeleteFileButtonClick = useCallback(
    (row: Row<File>) => {
      setFileRemove(row.original)
      openSingleDeleteFileConfirmDialog()
    },
    [openSingleDeleteFileConfirmDialog]
  )

  // Press submit confirmation button to delete file
  const handleSubmitSingleDeleteFileButtonClick = useCallback(async () => {
    // if fileRemove is undefined, return
    if (fileRemove) {
      try {
        // Request to delete file
        await deleteFileMutation({
          fileId: fileRemove.id,
          version: fileRemove.version,
        }).unwrap()

        // mutation操作の後は、レスポンスを通知する
        const objectEventMessage: ObjectEventMessage<File> = {
          eventType: NotifyEventType.Deleted,
          id: fileRemove.id,
          object: undefined,
        }
        dispatch(notifyFileEvent(objectEventMessage))

        // reset row selection
        const selection = Object.keys(rowSelection).reduce((obj, key) => {
          if (fileRemove.id !== key) {
            obj[key] = rowSelection[key]
          }
          return obj
        }, {} as RowSelectionState)
        setRowSelection(selection)

        success(t('message.file.deleteSuccess'))
      } catch (err) {
        error(t('message.general.failedToDelete'))
      }
    }

    closeSingleDeleteFileConfirmDialog()
  }, [
    closeSingleDeleteFileConfirmDialog,
    fileRemove,
    deleteFileMutation,
    dispatch,
    rowSelection,
    success,
    t,
    error,
  ])

  const columns = useMemo<ColumnDef<File>[]>(
    () => [
      {
        id: 'select',
        accessorKey: 'select',
        header: ({ table }) => (
          <SelectCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="flex flex-1 justify-center">
            <SelectCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
        size: 80,
        minSize: 50,
        enableSorting: false,
        meta: {
          align: 'center',
        },
      },
      {
        id: 'fileName',
        accessorKey: 'fileName',
        header: () => t('label.fileName'),
        cell: ({ row }) => (
          <CrewSortable
            group="shared"
            data={row.original}
            allowDropInsideItem={false}
            allowReordering={false}
          >
            <CrewFileName
              fileId={row.original.id}
              fileName={row.original.name}
              entityType={EntityType.Project}
              entityRecordId={projectId}
              className="line-clamp-2 break-all"
            />
          </CrewSortable>
        ),
        size: 500,
        minSize: 50,
      },
      {
        id: 'tags',
        header: () => t('label.tag'),
        cell: ({ row }) => (
          // we show only 2 lines of tags so need using max-h-[60px]
          <div className="w-full flex flex-wrap gap-1 text-sm overflow-hidden max-h-[52px]">
            {row.original.tags.map((tag) => (
              <CrewFileTag key={tag.id} tag={tag} />
            ))}
          </div>
        ),
        size: 240,
        minSize: 50,
      },
      {
        id: 'revision',
        accessorKey: 'revision',
        header: () => t('label.version'),
        cell: (ctx) => (
          <div className="text-right w-full truncate">
            {ctx.getValue() as string}
          </div>
        ),
        size: 120,
        minSize: 50,
      },
      {
        id: 'size',
        accessorKey: 'size',
        header: () => t('label.size'),
        cell: ({ row }) => (
          <div className="text-right w-full truncate">
            <SizeText size={row.original.size} />
          </div>
        ),
        size: 80,
        minSize: 50,
      },
      {
        id: 'updatedBy',
        accessorKey: 'updatedBy',
        header: () => t('label.updatedBy'),
        cell: ({ row }) => (
          <div className="text-right w-full truncate">
            <CrewFileChangerItem
              displayName={row.original.lastUpdatedAttachmentBy.displayName}
              userId={row.original.lastUpdatedAttachmentBy.id}
              version={row.original.lastUpdatedAttachmentBy.version}
            />
          </div>
        ),
        size: 160,
        minSize: 50,
      },
      {
        id: 'relatedEntity',
        accessorKey: 'relatedEntity',
        header: () => t('label.relatedItem'),
        cell: ({ row }) =>
          row.original.relatedEntity && (
            <CrewRelatedItemLink
              entityType={row.original.relatedEntity.entityType}
              id={row.original.relatedEntity.entityRecordId}
              className="line-clamp-2 break-all"
            />
          ),
        size: 200,
        minSize: 50,
      },
      {
        id: 'lastUpdatedAttachmentAt',
        accessorKey: 'lastUpdatedAttachmentAt',
        header: () => t('label.updateDatetime'),
        cell: ({ row }) => (
          <div className="truncate">
            <UpdateAt date={row.original.lastUpdatedAttachmentAt} />
          </div>
        ),
        size: 160,
        minSize: 50,
      },
      {
        id: 'action',
        accessorKey: 'action',
        header: '',
        cell: ({ row }) => (
          <CrewFileAction
            fileId={row.original.id}
            entityType={EntityType.Project}
            entityRecordId={projectId}
            onDelete={() => handleSingleDeleteFileButtonClick(row)}
          />
        ),
        size: 50,
        minSize: 50,
        enableSorting: false,
      },
    ],
    [t, projectId, handleSingleDeleteFileButtonClick]
  )

  const files = useMemo(() => {
    if (props.isShowSearchCondition) {
      return getProjectFilesResult?.projectFiles ?? []
    } else {
      return getFilesResult?.files ?? []
    }
  }, [
    getFilesResult?.files,
    getProjectFilesResult?.projectFiles,
    props.isShowSearchCondition,
  ])

  const tableOptions: TableOptions<File> = {
    data: files,
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    pageCount,
    state: {
      pagination,
      sorting,
      columnVisibility,
      columnPinning,
      rowSelection,
    },
    getRowId: (row) => row.id,
    onPaginationChange: handlePaginationChange,
    onSortingChange: handleSortingChange,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    manualPagination: true,
    manualSorting: true,
    enableMultiSort: true,
    enableRowSelection: true,
    maxMultiSortColCount: 2,
    meta: {
      headerRowHeight: 40,
      dataRowHeight: 50,
    },
  }

  // Event handler for move files button click
  const handleMoveFilesButtonClick = useCallback(() => {
    openDirectMessageFileMoveDialog()
  }, [openDirectMessageFileMoveDialog])

  // Event handler for move files
  const handleMoveFiles = useCallback(
    async (folderId: string) => {
      try {
        const result = await updateFolderMutation({
          folderId,
          files: selectedFiles.map((item: SelectedFile) => {
            return {
              fileId: item.id,
              version: item.version,
            }
          }),
        }).unwrap()

        if (result.duplicateFileNames && result.duplicateFileNames.length > 0) {
          warn(
            t('message.file.moveDuplicatedFiles', {
              duplicateFileNames: result.duplicateFileNames.join('\n'),
            })
          )
        } else {
          success(t('message.file.moveFilesSuccess'))
        }

        const objectEventMessage: ObjectEventMessage<File> = {
          eventType: NotifyEventType.Updated,
          id: selectedFiles[0].id,
          object: undefined,
        }
        dispatch(notifyFileEvent(objectEventMessage))

        // reset row selection
        setRowSelection({})
      } catch (err) {
        showApiErrors(err)
      }
    },
    [
      dispatch,
      selectedFiles,
      showApiErrors,
      success,
      t,
      updateFolderMutation,
      warn,
    ]
  )

  // handle change pagination grid mode
  const handlePaginationGridChange = useCallback(
    (pageIndex: number, pageSize: number) => {
      const newParams = {
        ...params,
        pageIndex,
        pageSize,
      }

      // paramsが変わっていない場合はnavigateしない
      if (_.isEqual(params, newParams)) return

      const newQueryString = qs.stringify(newParams, {
        arrayFormat: 'repeat',
        skipNulls: true,
      })

      navigate(`?${newQueryString}`)
    },
    [navigate, params]
  )

  const totalCount = useMemo(
    () =>
      (props.isShowSearchCondition
        ? getProjectFilesResult?.totalCount
        : getFilesResult?.totalCount) ?? 0,
    [
      getFilesResult?.totalCount,
      getProjectFilesResult?.totalCount,
      props.isShowSearchCondition,
    ]
  )

  // Event when check item in grid mode
  const handleSelectItem = useCallback(
    (fileId: string, version: number, entityRecordId: string) => {
      // get selected fileIds
      setSelectedFiles((prev) => {
        // If fileId exists in selectedFileIds, remove it
        if (prev.some((item) => item.id === fileId)) {
          return prev.filter((item) => item.id !== fileId)
        }
        // If fileId does not exist in selectedFileIds, add it
        return [...prev, { id: fileId, version, entityRecordId }]
      })
    },
    []
  )

  return (
    <div className="flex flex-col relative @container">
      <div className="flex gap-x-2.5 absolute right-0 -top-2">
        {/* 複数ファイルをまとめてフォルダ移動させるボタンの配置 */}
        <CrewButton
          icon={<FolderTransferLine width={20} height={20} />}
          disabled={selectedFileIds.length === 0}
          onClick={handleMoveFilesButtonClick}
          stylingMode="outlined"
        />

        {/* 一括ダウンロードボタン */}
        <CrewButton
          icon={<BaselineSaveAlt width={20} height={20} />}
          disabled={selectedFileIds.length === 0}
          onClick={handleBulkFileDownloadButtonClick}
          stylingMode="outlined"
        />

        {/* 一括タグ追加ボタン */}
        <CrewButton
          icon={<RoundLocalOffer width={20} height={20} />}
          disabled={selectedFileIds.length === 0}
          onClick={openTagsEntryDialog}
          stylingMode="outlined"
        />

        {/* 一括削除ボタン */}
        <CrewButton
          icon={<BaselineDelete width={20} height={20} />}
          disabled={selectedFileIds.length === 0}
          onClick={handleOpenConfirmBulkDeleteFileButtonClick}
          stylingMode="outlined"
        />

        {/* 一括削除時の確認ダイアログ */}
        <CrewConfirmDialog
          isOpen={isConfirmDialogOpen}
          message={confirmMessage}
          onPermitButtonClick={handleSubmitPermitDeleteFileButtonClick}
          onCancelButtonClick={closeConfirmDialog}
          permitButtonDisabled={isLoadingBulkDeleteFile}
        />
      </div>

      {selectedFileListDisplayMode === FileListDisplayMode.List.id ? (
        // list mode
        <div className="flex-1 overflow-y-hidden">
          {/* file list table */}
          <CrewTable tableOptions={tableOptions} />
        </div>
      ) : (
        // grid mode
        <>
          <CrewPagination
            pageSize={pagination.pageSize}
            pageIndex={pagination.pageIndex}
            pageCount={Math.ceil(totalCount / pagination.pageSize)}
            onPaginationChange={handlePaginationGridChange}
          />
          <div className="grid @7xl:grid-cols-7 @3xl:grid-cols-5 grid-cols-3 gap-2.5">
            {files.map((file) => (
              <CrewSortable
                group="shared"
                data={file}
                allowDropInsideItem={false}
                allowReordering={false}
                className="flex"
                key={file.id}
                dropFeedbackMode="indicate"
              >
                <CrewGridFileItem
                  key={file.id}
                  file={file}
                  onSelect={handleSelectItem}
                />
              </CrewSortable>
            ))}
          </div>
          <CrewPagination
            pageSize={pagination.pageSize}
            pageIndex={pagination.pageIndex}
            pageCount={Math.ceil(totalCount / pagination.pageSize)}
            onPaginationChange={handlePaginationGridChange}
          />
        </>
      )}

      {/* 一括タグ追加ダイアログ */}
      <FileListTagsEntryDialog
        isOpen={isOpenTagsEntryDialog}
        title={t('label.saveTags')}
        fileIds={selectedFileIds}
        projectIds={projectIds}
        onClose={closeTagsEntryDialog}
      />

      {/* Single file delete confirm dialog */}
      <CrewConfirmDialog
        isOpen={isSingleDeleteFileConfirmDialogOpen}
        message={t('message.general.confirmMessage.delete')}
        onPermitButtonClick={handleSubmitSingleDeleteFileButtonClick}
        onCancelButtonClick={closeSingleDeleteFileConfirmDialog}
        permitButtonDisabled={isLoadingDeleteFile}
      />

      {/* ファイルの移動 */}
      <DirectMessageFileMoveDialog
        isOpen={isOpenDirectMessageFileMoveDialog}
        onClose={closeDirectMessageFileMoveDialog}
        onMove={handleMoveFiles}
      />
    </div>
  )
})
