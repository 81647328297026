import { PresenceStateType } from '@crew/enums/domain'
import classNames from 'classnames'
import { FC, memo } from 'react'

// プレゼンスアイコンのサイズ
export const CrewPresenceStateIconSize = {
  base: 'base',
  sm: 'sm',
} as const

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CrewPresenceStateIconSize =
  (typeof CrewPresenceStateIconSize)[keyof typeof CrewPresenceStateIconSize]

// define class names for each size
const iconSizeMap = {
  [CrewPresenceStateIconSize.base]: 'w-3 h-3',
  [CrewPresenceStateIconSize.sm]: 'w-2 h-2',
} as const

// presenceTypeに対応する背景色を定義
const bgColorMap = {
  [PresenceStateType.Present]:
    'bg-crew-green-3-light dark:bg-crew-green-3-dark',
  [PresenceStateType.Busy]: 'bg-crew-red-3-light dark:bg-crew-red-3-dark',
  [PresenceStateType.Away]: 'bg-crew-yellow-3-light dark:bg-crew-yellow-3-dark',
  [PresenceStateType.Absent]: 'bg-crew-gray-3-light dark:bg-crew-gray-3-dark',
  [PresenceStateType.Unknown]: 'bg-crew-gray-3-light dark:bg-crew-gray-3-dark',
} as const

type CrewPresenceStateIconProps = {
  presenceStateType: PresenceStateType
  size?: CrewPresenceStateIconSize
}

export const CrewPresenceStateIcon: FC<CrewPresenceStateIconProps> = memo(
  (props) => {
    return (
      <span
        className={classNames(
          'rounded-full block',
          iconSizeMap[props.size || CrewPresenceStateIconSize.base],
          bgColorMap[props.presenceStateType] || ''
        )}
      />
    )
  }
)
