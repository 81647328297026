import { memo, useCallback } from 'react'
import {
  CrewPresenceStateIcon,
  CrewPresenceStateIconSize,
} from '../crewPresenceStateIcon/crewPresenceStateIcon'
import { useGetUserStateQuery } from '@crew/apis/user/userApis'
import { GetUserStateRequest } from '@crew/apis/user/models/getUserState/request'
import { PresenceState, usePresenceState } from '@crew/states'
import { useValueChangeEffect } from '@crew/hooks'
import { PresenceStateType } from '@crew/enums/domain'
import { useSubscribeUserPresenceState } from '@crew/providers/websocket'
import { UpdatedUserPresence } from '@crew/apis/websocket/models/updatedUserPresence/updatedUserPresence'
import { useTranslation } from '@crew/modules/i18n'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { updateUserPresences } from 'features/app/states/appSlice'

export type UserPresenceState = PresenceState & {
  presenceStateMessage: string | null
}

type CrewUserPresenceProps = {
  userId: string
  size?: CrewPresenceStateIconSize
  onChange?: (userPresenceState: UserPresenceState) => void
}
export const CrewUserPresence = memo((props: CrewUserPresenceProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  // Get user presence state from Redux store
  const userPresence = useAppSelector(
    (state) => state.app.userPresences[props.userId]
  )

  // 在籍状態IDから在籍状態を取得
  const presenceState = usePresenceState(userPresence?.id)

  // get user state
  const paramsUserState: GetUserStateRequest = {
    userId: props.userId,
  }
  const { data: dataUserState } = useGetUserStateQuery(paramsUserState)

  // Update Redux store when initial data loads
  useValueChangeEffect(
    () => {
      if (dataUserState) {
        const presence: UserPresenceState = {
          id: dataUserState.userState.presenceStateId,
          name: presenceState?.name ?? t('label.unknown'),
          presenceStateType:
            presenceState?.presenceStateType ?? PresenceStateType.Unknown,
          presenceStateMessage: dataUserState.userState.presenceStateMessage,
        }
        dispatch(
          updateUserPresences({
            userId: props.userId,
            presence,
          })
        )
      }
    },
    [
      dataUserState,
      dispatch,
      presenceState?.name,
      presenceState?.presenceStateType,
      props.userId,
      t,
    ],
    dataUserState
  )

  // Handle WebSocket updates
  const handleUserPresenceStateUpdated = useCallback(
    (updatePresenceState: UpdatedUserPresence) => {
      dispatch(
        updateUserPresences({
          userId: props.userId,
          presence: {
            id: updatePresenceState.presenceStateId,
            name: presenceState?.name ?? t('label.unknown'),
            presenceStateType:
              presenceState?.presenceStateType ?? PresenceStateType.Unknown,
            presenceStateMessage: updatePresenceState.presenceStateMessage,
          },
        })
      )
    },
    [
      dispatch,
      presenceState?.name,
      presenceState?.presenceStateType,
      props.userId,
      t,
    ]
  )

  //  在籍状態・在籍状態メッセージが変更された場合、変更後の在籍状態を親コンポーネントに通知
  useValueChangeEffect(
    () => {
      if (userPresence && props.onChange) {
        props.onChange(userPresence)
      }
    },
    [dataUserState, presenceState, props, props.onChange, t, userPresence],
    userPresence
  )

  // ユーザー状態の更新をsubscribeする
  useSubscribeUserPresenceState(props.userId, handleUserPresenceStateUpdated)

  return (
    <CrewPresenceStateIcon
      size={props.size ?? CrewPresenceStateIconSize.sm}
      presenceStateType={
        presenceState?.presenceStateType ?? PresenceStateType.Unknown
      }
    />
  )
})
