import { FC, memo } from 'react'

import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewRelatedItemLink } from 'components/elements/crewRelatedItemLink/crewRelatedItemLink'
import { useAppSelector } from 'states/hooks'
import classNames from 'classnames'
import ChevronBackward from '~icons/material-symbols/chevron-backward'

export type ChatThreadHeaderProps = {
  showBackButton: boolean // 戻るボタンを表示するか

  onBackButtonClick: () => void
}

/**
 * Thread形式のヘッダ
 */
export const ChatThreadHeader: FC<ChatThreadHeaderProps> = memo((props) => {
  // 処理対象のチャットスレッドをViewModelから取得
  const currentChatThread = useAppSelector(
    (state) => state.message.chat.current.chatThread
  )

  // 表示に必要なデータが指定されていない場合、表示することができないので、エラーを表示する
  if (!currentChatThread) {
    return null
  }

  return (
    <div
      className={classNames(
        'flex items-center shrink-0 h-10 px-2',
        props.showBackButton && `gap-2`
      )}
    >
      {/* 戻るボタン */}
      {props.showBackButton && (
        <CrewButton
          icon={<ChevronBackward width={20} height={20} />}
          stylingMode="text"
          onClick={props.onBackButtonClick}
          type="normal"
          size="sm"
        />
      )}
      {/* 関連先リンク 
          - 親の関連先がある場合は、親の関連先名も合わせて表示する */}
      <span className="grow line-clamp-1 break-all">
        {currentChatThread.entityRecordId !==
          currentChatThread.rootEntityRecordId && (
          <>
            <CrewRelatedItemLink
              entityType={currentChatThread.rootEntityType}
              id={currentChatThread.rootEntityRecordId}
            />
            &nbsp;/&nbsp;
          </>
        )}
        <CrewRelatedItemLink
          entityType={currentChatThread.entityType}
          id={currentChatThread.entityRecordId}
        />
      </span>
    </div>
  )
})
