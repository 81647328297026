import { t } from '@crew/modules/i18n'
import { FC, memo, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useGetProjectProgressQuery } from '@crew/apis/project/projectApis'
import { ProjectProgressItems } from 'enums/app'

type Progress = {
  [key: string]: number
}

export type ProgressItem = {
  percentage: number | boolean
  value: number
  text: string | undefined
  displayColor: string | undefined
}

export type CrewProgressBarItemProps = {
  projectId: string
}

export const CrewProgressBarItem: FC<CrewProgressBarItemProps> = memo(
  (props) => {
    const itemBaseClassNames = useMemo(() => {
      return 'text-crew-gray-1-light dark:text-crew-gray-1-dark text-sm text-center p-0.5'
    }, [])

    const { data: getProjectProgressResult } = useGetProjectProgressQuery({
      projectId: props.projectId,
    })

    const [totalValue, setTotalValue] = useState(0)

    const progressItems = useMemo(() => {
      if (getProjectProgressResult?.projectProgress) {
        const progress = getProjectProgressResult?.projectProgress as Progress

        const total = Object.values(progress).reduce((accumulator, value) => {
          return accumulator + value
        }, 0)

        setTotalValue(total)

        // ProjectProgressItemsの項目順に進捗率を計算
        const items: ProgressItem[] = Object.keys(ProjectProgressItems).map(
          (_, index) => {
            const item = Object.values(ProjectProgressItems)[index]

            const percentage = !total
              ? 0
              : Object.hasOwn(progress, item.text) &&
                (progress[item.text] / total) * 100
            return {
              ...item,
              percentage,
              value: progress[item.text],
            }
          }
        )

        return items
      } else {
        return []
      }
    }, [getProjectProgressResult?.projectProgress])

    return (
      <div className="flex rounded-md overflow-hidden">
        {/* hiden progress bar when total value equals 0 */}
        {totalValue === 0 || !progressItems
          ? null
          : // show progress bar when value other than 0
            progressItems.map((item: ProgressItem) => {
              // hide progress item when value equals 0
              if (item.value === 0) {
                return <div key={item.text} />
              } else {
                // show progress item when value other than 0
                return (
                  <div
                    title={t('label.' + item.text)}
                    className={classNames(
                      itemBaseClassNames,
                      item.displayColor
                    )}
                    key={item.text}
                    style={{
                      width: item.percentage + '%',
                    }}
                  >
                    {item.value}
                  </div>
                )
              }
            })}
      </div>
    )
  }
)
