import { EntityType, ProjectType } from '@crew/enums/domain'
import { useChatMessage } from '@crew/states'
import { useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { useGetLookupRelatedItemQuery } from '@crew/apis/lookup/lookupApis'
import { skipToken } from '@reduxjs/toolkit/query'

export const useMessageUrl = (messageId: string) => {
  const dispatch = useAppDispatch()

  // We need to get the current URL to generate the message URL
  const url: URL = new URL(window.location.href)

  const { message } = useChatMessage(messageId, dispatch, useAppSelector)

  // Get project information
  const getLookupRelatedItemParams =
    message?.chatRoom.entityType && message?.chatRoom.entityRecordId
      ? {
          entityType: message?.chatRoom.entityType,
          id: message?.chatRoom.entityRecordId,
        }
      : undefined

  const { data } = useGetLookupRelatedItemQuery(
    getLookupRelatedItemParams ?? skipToken
  )

  // The URL of message like this format: https://tmc.crewworks.net/projects/01H56GYEF1A7P84PV2TYW0C4PE/chat?messageId=XXXXX
  const messageUrl = useMemo(() => {
    switch (message?.chatRoom.entityType) {
      case EntityType.Project:
        if (data?.relatedItem?.type === ProjectType.DirectChannel) {
          return `${url.href}?messageId=${messageId}`
        } else if (data?.relatedItem?.type === ProjectType.Project) {
          return `${url.origin}/projects/${message?.chatRoom.entityRecordId}/chat?messageId=${messageId}`
        }

        return ''
      case EntityType.Event:
        return `${url.origin}/events/${message.chatRoom.entityRecordId}/detail?messageId=${messageId}`
      case EntityType.Task:
        return `${url.origin}/tasks/${message.chatRoom.entityRecordId}/comment?messageId=${messageId}`
      case EntityType.File:
        return `${url.origin}/files/${message.chatRoom.entityRecordId}/updatedHistory?messageId=${messageId}`
      default:
        return ''
    }
  }, [
    message?.chatRoom.entityRecordId,
    message?.chatRoom.entityType,
    messageId,
    data?.relatedItem?.type,
    url.href,
    url.origin,
  ])

  return messageUrl
}
