import { useCallback } from 'react'
import {
  useAppointAsMeetingHostMutation,
  useDeleteMeetingAttendeeMutation,
  useDismissAsMeetingHostMutation,
} from '@crew/apis/meeting/meetingApis'

export const useEventDetailWebMeetingAttendeeItem = () => {
  const [dismissAsMeetingHostMutation] = useDismissAsMeetingHostMutation()
  const [appointAsMeetingHostMutation] = useAppointAsMeetingHostMutation()
  const [
    deleteMeetingAttendeeMutation,
    { isLoading: isLoadingDeleteMeetingAttendee },
  ] = useDeleteMeetingAttendeeMutation()

  // Assgin another attendee to host
  const appointAsMeetingHost = useCallback(
    async (meetingId: string, chimeAttendeeId: string) => {
      await appointAsMeetingHostMutation({
        meetingId,
        attendeeId: chimeAttendeeId,
      }).unwrap()
    },
    [appointAsMeetingHostMutation]
  )

  // Remove user from host
  const dismissAsMeetingHost = useCallback(
    async (meetingId: string, chimeAttendeeId: string) => {
      await dismissAsMeetingHostMutation({
        meetingId,
        attendeeId: chimeAttendeeId,
      }).unwrap()
    },
    [dismissAsMeetingHostMutation]
  )

  // remove attendee from meeting
  const removeAttendee = useCallback(
    async (meetingId: string, chimeAttendeeId: string) => {
      await deleteMeetingAttendeeMutation({
        meetingId,
        attendeeId: chimeAttendeeId,
      }).unwrap()
    },
    [deleteMeetingAttendeeMutation]
  )

  return {
    appointAsMeetingHost,
    dismissAsMeetingHost,
    removeAttendee,
    isLoadingDeleteMeetingAttendee,
  }
}
