import { FC, Fragment, memo, useCallback, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { useReplyMessageList } from './useReplyMessageList'
import { useEffectOnce } from '@dx-system/react-use'
import { useValueChangeEffect } from '@crew/hooks'
import { useSubscribeAttention } from '@crew/providers/websocket'
import { ReplyMessageListItem } from './components/replyMessageListItem/replyMessageListItem'
import { setRightSideBarSelectedMessageId } from 'features/app/states/appSlice'

export const ReplyMessageList: FC = memo(() => {
  const dispatch = useAppDispatch()
  const { displayItems, reloadMessages, messageInView } = useReplyMessageList()

  // 画面遷移前のスクロール位置
  const scrollToEntityRecordId = useAppSelector(
    (state) => state.message.attention.reply.scrollToEntityRecordId
  )

  // 返信ボタンで選択されたメッセージのID
  const [selectedMessageId, setSelectedMessageId] = useState<
    string | undefined
  >(undefined)

  // メッセージ表示領域のref
  const itemsScrollableDivRef = useRef<HTMLDivElement>(null)

  useValueChangeEffect(
    () => {
      // When the selected message ID changes, set it to the chat panel
      dispatch(setRightSideBarSelectedMessageId(selectedMessageId))

      return () => {
        // When unmounting, reset the selected message ID
        dispatch(setRightSideBarSelectedMessageId(undefined))
      }
    },
    [dispatch, selectedMessageId],
    selectedMessageId
  )

  // 他画面への遷移から戻った際に、スクロール位置を元の位置に戻すための処理
  useEffectOnce(() => {
    // scrollToEntityRecordIdがなければ処理しない
    if (scrollToEntityRecordId === undefined) {
      return
    }

    // スクロール対象のDOM要素
    const displayTargetElement = itemsScrollableDivRef.current?.querySelector(
      `#attention_${scrollToEntityRecordId}`
    )

    // スクロール対象のメッセージを表示中アイテムの中から取得する
    const item = displayItems.find((item) => item.id === scrollToEntityRecordId)
    // スクロール対象のメッセージが表示アイテムの中にあり、スクロール対象のDOM要素がある場合のみ処理を行う
    if (item && displayTargetElement) {
      // 表示対象のDOM要素を表示領域内にスクロールする
      displayTargetElement.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      })
    }
  })

  // ログインユーザー
  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

  // ログインユーザーが変わったら再ロードを行う
  useValueChangeEffect(
    () => {
      reloadMessages()
    },
    [loggedInUser, reloadMessages],
    loggedInUser
  )

  // アテンション関連のメッセージをwebsocket経由でsubscribeする
  useSubscribeAttention(undefined, dispatch, useAppSelector)

  // メッセージ表示イベントハンドラ
  const handleMessageInView = useCallback(
    (inView: boolean, entityRecordId: string) => {
      if (inView) {
        messageInView(entityRecordId)
      }
    },
    [messageInView]
  )

  // データが存在しない場合
  if (displayItems.length === 0) {
    return null
  }

  return (
    // 「読み込み中」のメッセージは、画面のちらつきの原因になるため、現時点では表示しない
    <div
      className="flex-1 flex-col overflow-y-scroll"
      ref={itemsScrollableDivRef}
    >
      {displayItems.map((item) => (
        <Fragment key={item.id}>
          <ReplyMessageListItem
            // スクロール位置設定時、数字から始まるidはエラーになるため、idの先頭に文字列を付与する
            id={`attention_${item.id}`}
            key={item.id}
            chatMessageId={item.chatMessageId}
            entityRecordId={item.id}
            container={itemsScrollableDivRef}
            attentionUserName={item.attentionUserName}
            onAdditionalLoading={item.handleAdditionalLoading}
            onMessageInView={handleMessageInView}
            selectedItemId={selectedMessageId}
            setSelectedItemId={setSelectedMessageId}
          />
        </Fragment>
      ))}
    </div>
  )
})
