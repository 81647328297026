import { InsertEventKindRequest } from '@crew/apis/project/models/insertEventKind/request'
import { UpdateEventKindRequest } from '@crew/apis/project/models/updateEventKind/request'
import {
  useInsertEventKindMutation,
  useUpdateEventKindMutation,
} from '@crew/apis/project/projectApis'
import { EventKind } from '@crew/models/domain'
import { useTranslation } from '@crew/modules/i18n'
import { ValidateRules } from '@crew/utils/form'
import { NotifyEventType } from 'enums/app'
import { CrewBadgeInvertedColorClass } from 'enums/color'
import {
  notifyEventKindEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from 'states/hooks'
import { EventType } from '@crew/enums/domain'

export type FormValues = {
  id: string | null
  name: string
  displayColor: string | null
  initialValue: boolean
  version: number | null
}

// ダイアログ初期値
export const formInitialValues: FormValues = {
  id: null,
  name: '',
  displayColor: CrewBadgeInvertedColorClass.Red, //add color default is red
  initialValue: false,
  version: null,
}

export const useEventKindEntryForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  // Get functions for update event type information
  const [insertEventKindMutation, { isLoading: isLoadingInsertEventKind }] =
    useInsertEventKindMutation()
  const [updateEventKindMutation, { isLoading: isLoadingUpdateEventKind }] =
    useUpdateEventKindMutation()

  // react-hook-formの各種データを取得
  const { control, reset, formState, handleSubmit, clearErrors, setError } =
    useForm<FormValues>({
      criteriaMode: 'all',
      defaultValues: formInitialValues,
    })

  // Insert event type process
  const insertEventKind = useCallback(
    async (data: FormValues) => {
      const request: InsertEventKindRequest = {
        eventKind: {
          eventType: EventType.Personal,
          entityType: undefined,
          entityRecordId: undefined,
          name: data.name,
          displayColor: data.displayColor ?? '',
          initialValue: data.initialValue,
        },
      }

      const result = await insertEventKindMutation(request).unwrap()

      // mutation操作の後は、レスポンスを通知する
      const objectEventMessage: ObjectEventMessage<EventKind> = {
        eventType: NotifyEventType.Inserted,
        id: result.eventKind?.id ?? '',
        object: result.eventKind ?? undefined,
      }

      dispatch(notifyEventKindEvent(objectEventMessage))

      return result
    },
    [dispatch, insertEventKindMutation]
  )

  // Update event type process
  const updateEventKind = useCallback(
    async (data: FormValues) => {
      const request: UpdateEventKindRequest = {
        eventKind: {
          id: data.id as string,
          name: data.name,
          displayColor: data.displayColor ?? '',
          initialValue: data.initialValue,
          version: data.version as number,
        },
      }

      const result = await updateEventKindMutation(request).unwrap()

      // mutation操作の後は、レスポンスを通知する
      const objectEventMessage: ObjectEventMessage<EventKind> = {
        eventType: NotifyEventType.Updated,
        id: result.eventKind?.id ?? '',
        object: result.eventKind ?? undefined,
      }

      dispatch(notifyEventKindEvent(objectEventMessage))

      return result
    },
    [dispatch, updateEventKindMutation]
  )

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      name: {
        required: t('message.general.isRequired', {
          name: t('label.name'),
        }),
      },
      displayColor: {
        required: t('message.general.isRequired', {
          name: t('label.displayColor'),
        }),
      },
      // not validate below
      id: {},
      version: {},
      initialValue: {},
    }),
    [t]
  )

  return {
    control,
    reset,
    formState,
    handleSubmit,
    clearErrors,
    setError,

    validateRules,

    insertEventKind,
    updateEventKind,
    isLoadingInsertEventKind,
    isLoadingUpdateEventKind,
  }
}
