import { useDeleteFilterMutation } from '@crew/apis/filter/filterApis'
import {
  ObjectEventMessage,
  notifyFilterEvent,
} from 'features/app/states/appSlice'
import { useCallback } from 'react'
import { useAppDispatch } from 'states/hooks'
import { Filter } from '@crew/models/domain'
import { NotifyEventType } from 'enums/app'

export const useFilterTable = () => {
  const dispatch = useAppDispatch()

  const [deleteFilterMutation, { isLoading: isLoadingDeleteFilter }] =
    useDeleteFilterMutation()

  const deleteFilter = useCallback(
    async (filterId: string, version: number) => {
      await deleteFilterMutation({ filterId, version }).unwrap()

      const objectEventMessage: ObjectEventMessage<Filter> = {
        eventType: NotifyEventType.Updated,
        id: filterId,
        object: {
          id: filterId,
          version: version,
        },
      }
      dispatch(notifyFilterEvent(objectEventMessage))
    },
    [deleteFilterMutation, dispatch]
  )

  return { deleteFilter, isLoadingDeleteFilter }
}
