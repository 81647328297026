import { useLazyGetEventKindsQuery } from '@crew/apis/project/projectApis'
import { EventType } from '@crew/enums/domain'
import { EventKind } from '@crew/models/domain'
import { useTranslation } from '@crew/modules/i18n'
import { useEffectOnce } from '@dx-system/react-use'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { CrewErrorDialog } from 'components/elements/crewErrorDialog/crewErrorDialog'
import {
  CrewSortableList,
  arrayMove,
} from 'components/elements/crewSortableList/crewSortableList'
import { useModal } from 'components/layouts/modal/useModal'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { memo, useCallback, useEffect, useState } from 'react'
import { useAppSelector } from 'states/hooks'
import { EventKindEntryDialog } from '../eventKindEntryDialog/eventKindEntryDialog'
import { EventKindListItem } from './components/eventKindListGridItem/eventKindListItem'
import { SelectedEventKind, useEventKindListGrid } from './useEventKindListGrid'
import { EventKindTableHeader } from './components/eventKindTableHeader/eventKindTableHeader'

export const EventKindListGrid = memo((props) => {
  const { deleteEventKind, reorderEventKind, isLoadingDeleteEventKind } =
    useEventKindListGrid()

  const { t } = useTranslation()
  const { success } = useToast()
  const [showApiErrors] = useShowApiErrors()

  const [
    isEventKindEntryDialogOpen,
    openEventKindEntryDialog,
    closeEventKindEntryDialog,
  ] = useModal()

  const eventKindEventMessage = useAppSelector(
    (state) => state.app.eventKindEventMessage
  )

  const [selectedEventKind, setSelectedEventKind] = useState<
    SelectedEventKind | undefined
  >()

  // 確認ダイアログ表示フラグ
  const [isConfirmVisible, setIsConfirmVisible] = useState(false)
  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')
  // 確認ダイアログ キャンセルボタン押下
  const handleConfirmCancelButtonClick = useCallback(() => {
    setIsConfirmVisible(false)
  }, [])

  const [isErrorDialogOpen, openErrorDialog, closeErrorDialog] = useModal()

  // エラーダイアログメッセージ
  const [errorMessage, setErrorMessage] = useState('')

  const [eventKinds, setEventKinds] = useState<EventKind[]>()

  // lazyGetで読み込みを行う。
  // 通常のgetを使うと、ソート失敗後の再読み込みでBackendが返すdataに変化がないため、useEffectが発火せずEventKindsをリセットできない。
  // lazyGetの結果を常にsetTaskStateすることで回避する。
  const [lazyGetEventKindsQuery] = useLazyGetEventKindsQuery()
  const fetchEventKinds = useCallback(async () => {
    const result = await lazyGetEventKindsQuery({
      eventType: EventType.Personal,
      entityType: undefined,
      entityRecordId: undefined,
    })
    setEventKinds(result.data?.eventKinds)
  }, [lazyGetEventKindsQuery])

  // 初期読み込み
  useEffectOnce(() => {
    fetchEventKinds()
  })

  // refresh event type list
  useEffect(() => {
    if (eventKindEventMessage) {
      fetchEventKinds()
    }
  }, [fetchEventKinds, eventKindEventMessage])

  // 並び替え処理
  const handleReorder = useCallback(
    async (fromId: string, toId: string) => {
      // ちらつき防止のため、APIを呼ぶ前にUI上での並び替えを先行して行う
      setEventKinds((items) => {
        if (!items) {
          return items
        }

        const from = items.findIndex((item) => item.id === fromId)
        const to = items.findIndex((item) => item.id === toId)
        return arrayMove(items, from, to)
      })

      try {
        // Execute reorder event type process
        await reorderEventKind(fromId, toId)
      } catch (err) {
        setErrorMessage(t('message.general.errorMessage.reorder'))
        openErrorDialog()
      }

      // APIの実行結果にかかわらず再読み込みを行い、Backendに同期させる
      fetchEventKinds()
    },
    [fetchEventKinds, reorderEventKind, t, openErrorDialog]
  )

  // の編集アイコン押下
  const handleEventKindEditButtonClick = useCallback(
    (eventKindId: string, eventKindVersion: number) => {
      // id値はイベントオブジェクトから取得可能
      setSelectedEventKind({
        eventKindId: eventKindId,
        version: eventKindVersion,
      })

      openEventKindEntryDialog()
    },
    [openEventKindEntryDialog]
  )

  // 内の削除アイコン押下
  const handleEventKindDeleteButtonClick = useCallback(
    (eventKindId: string, eventKindVersion: number) => {
      setConfirmMessage(t('message.general.confirmMessage.delete'))
      setIsConfirmVisible(true)

      setSelectedEventKind({
        eventKindId: eventKindId,
        version: eventKindVersion,
      })
    },
    [t]
  )

  // 削除確認ダイアログ OKボタン
  const handleDeletePermitButtonClick = useCallback(async () => {
    if (selectedEventKind) {
      try {
        await deleteEventKind(
          selectedEventKind.eventKindId,
          selectedEventKind.version
        )

        success(t('message.eventKind.eventKindDeleted'))
      } catch (error) {
        showApiErrors(error)
      }
    }
    setIsConfirmVisible(false)
  }, [deleteEventKind, selectedEventKind, showApiErrors, success, t])

  // ダイアログのキャンセルボタン押下
  const handleEventKindEntryDialogClose = useCallback(() => {
    // ここで選択されたIDを初期化しないと次回のload時に編集前の値が残るのでresetする
    setSelectedEventKind({
      eventKindId: '',
      version: 0,
    })

    closeEventKindEntryDialog()
  }, [closeEventKindEntryDialog])

  return (
    <>
      <div className="h-full flex flex-col">
        {eventKinds && (
          <CrewSortableList
            items={eventKinds}
            onReorder={handleReorder}
            header={<EventKindTableHeader />}
            renderItem={(item) => (
              <CrewSortableList.Item id={item.id}>
                <EventKindListItem
                  key={item.id}
                  eventKind={item}
                  onEditButtonClick={() =>
                    handleEventKindEditButtonClick(item.id, item.version)
                  }
                  onDeleteButtonClick={() =>
                    handleEventKindDeleteButtonClick(item.id, item.version)
                  }
                  dragHandle={<CrewSortableList.DragHandle />}
                />
              </CrewSortableList.Item>
            )}
          />
        )}

        <CrewConfirmDialog
          isOpen={isConfirmVisible}
          message={confirmMessage}
          onPermitButtonClick={handleDeletePermitButtonClick}
          onCancelButtonClick={handleConfirmCancelButtonClick}
          permitButtonDisabled={isLoadingDeleteEventKind}
        />

        <CrewErrorDialog
          isOpen={isErrorDialogOpen}
          message={errorMessage}
          onCloseButtonClick={closeErrorDialog}
        />
      </div>

      <EventKindEntryDialog
        isEditMode={true}
        title={t('label.eventKindEdit')}
        isOpen={isEventKindEntryDialogOpen}
        onClose={handleEventKindEntryDialogClose}
        eventKindId={selectedEventKind?.eventKindId}
      />
    </>
  )
})
