import { FC, memo, useCallback, useRef } from 'react'
import { Modal } from 'components/layouts/modal/modal'
import { UserRef } from '@crew/models/refs'
import List from 'devextreme-react/list'
import { useTranslation } from '@crew/modules/i18n'
import { useUserDataSource } from 'hooks/dataSource/useUserDataSource'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import dxList from 'devextreme/ui/list'
import { EventInfo } from 'devextreme/events'
import { SelectionChangedInfo } from 'devextreme/ui/collection/ui.collection_widget.base'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'
import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'

// Customized render user item component for List
const renderUserItem = (item: UserRef) => (
  <CrewUserAvatar
    userId={item.id}
    displayName={item.displayName}
    size={CrewAvatarSize.xs}
    cacheValue={item.id + item.version}
    disableClick
    showLabel
  />
)

type ScheduleAddUserDialogProps = {
  title: string
  isOpen: boolean
  onClose: () => void
  onAdd: (users: UserRef[]) => void
  isDisablePrimary: boolean
}

export const ScheduleAddUserDialog: FC<ScheduleAddUserDialogProps> = memo(
  (props) => {
    const { t } = useTranslation()

    const listRef = useRef<List>(null)

    const userDataSource = useUserDataSource()

    const selectedItems = useRef<UserRef[]>([])

    // Handle option changed event
    const handleSelectionChanged = useCallback(
      (event: EventInfo<dxList> & SelectionChangedInfo<UserRef>) => {
        // Add selected items to selectedItems
        if (event.addedItems.length > 0) {
          selectedItems.current.push(...event.addedItems)
        }

        // Remove selected items from selectedItems
        if (event.removedItems.length > 0) {
          selectedItems.current = selectedItems.current.filter(
            (item) => !event.removedItems.includes(item)
          )
        }
      },
      []
    )

    // Handle add button click event
    const handleAddButtonClick = useCallback(() => {
      props.onAdd(selectedItems.current)
    }, [props])

    return (
      <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
        <div className="flex flex-col gap-y-5">
          <List
            ref={listRef}
            dataSource={userDataSource}
            keyExpr="id"
            displayExpr="displayName"
            showSelectionControls={true}
            selectionMode="multiple"
            searchEnabled={true}
            onSelectionChanged={handleSelectionChanged}
            noDataText={t('label.noDataText')}
            itemRender={renderUserItem}
            // set width and height of the list to ensure that each item field on the screen is displayed correctly.
            className="h-[500px] w-[400px]"
          />

          <div className="flex items-center justify-between">
            {/* 追加 */}
            <CrewButton
              text={t('action.add')}
              type="primary"
              onClick={handleAddButtonClick}
              disabled={props.isDisablePrimary}
            />

            {/* 閉じる */}
            <CrewButton
              text={t('action.close')}
              type="normal"
              stylingMode="outlined"
              onClick={props.onClose}
            />
          </div>
        </div>
      </Modal>
    )
  }
)
