import { useTranslation } from '@crew/modules/i18n'
import { ColumnDef, TableOptions, getCoreRowModel } from '@tanstack/react-table'
import { CrewTable } from 'components/elements/crewTable/crewTable'
import { FC, memo, useMemo } from 'react'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { NewUserType } from 'features/project/components/projectMemberEntryDialog/useProjectMemberEntryDialog'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useCallback, useState } from 'react'
import { useModal } from 'components/layouts/modal/useModal'
import { useProjectMemberEntryContext } from 'features/project/components/projectMemberEntryDialog/useProjectMemberEntryDialog'
import { InvitationRoleType } from '@crew/enums/app'
import BaselineDelete from '~icons/ic/baseline-delete'

export type InviteNewUserTableProps = {
  selectedNewUsers: NewUserType[]
}

// renderとして使うのでmemo不可
const UserType: FC<{
  tenantRoleType: string
}> = (props) => {
  const [t] = useTranslation()

  const tenantRole = Object.values(InvitationRoleType).find(
    (roleType) => roleType.key === props.tenantRoleType
  )

  return <>{tenantRole && t(tenantRole.label)}</>
}

export const InviteNewUserTable = memo((props: InviteNewUserTableProps) => {
  const { t } = useTranslation()

  const { setSelectedNewUsers } = useProjectMemberEntryContext()

  const [columnVisibility, setColumnVisibility] = useState({})
  const [columnPinning] = useState({
    right: ['action'],
  })

  const [removeEmailOrLoginID, setRemoveEmailOrLoginID] = useState('')
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')

  //Remove button click
  const handleRemoveButtonClick = useCallback(
    (emailOrLoginID: string) => {
      setRemoveEmailOrLoginID(emailOrLoginID)
      setConfirmMessage(t('message.general.confirmMessage.delete'))
      openConfirmDialog()
    },
    [openConfirmDialog, t]
  )

  // 削除確認ダイアログ OKボタン
  const handleDeletePermitButtonClick = useCallback(async () => {
    //remove selected user from selectedNewUsers
    const filterSelectedNewUsers = props.selectedNewUsers.filter(
      (user) => user.emailOrLoginID !== removeEmailOrLoginID
    )
    //update selectedNewUsers
    setSelectedNewUsers(filterSelectedNewUsers)

    closeConfirmDialog()
  }, [
    closeConfirmDialog,
    props.selectedNewUsers,
    removeEmailOrLoginID,
    setSelectedNewUsers,
  ])

  const columns = useMemo<ColumnDef<NewUserType>[]>(
    () => [
      {
        id: 'userIdOrEmailAddress',
        header: () => t('label.userIdOrEmailAddress'),
        cell: ({ row }) => (
          <div className="truncate">{row.original.emailOrLoginID}</div>
        ),
        size: 330,
        minSize: 50,
      },
      {
        id: 'tenantRoleType',
        header: () => t('label.roleInTheOrganization'),
        cell: ({ row }) => (
          <div className="truncate">
            <UserType tenantRoleType={row.original.tenantRoleType} />
          </div>
        ),
        size: 120,
        minSize: 50,
      },
      {
        id: 'projectRole',
        header: () => t('label.roleInTheProject'),
        cell: ({ row }) => (
          <div className="truncate">{row.original.projectRole.name}</div>
        ),
        size: 120,
        minSize: 50,
      },
      {
        id: 'expirationDate',
        header: () => t('label.expirationDate'),
        cell: ({ row }) => (
          <div className="truncate">{row.original.expirationDatetime}</div>
        ),
        size: 120,
        minSize: 50,
      },
      {
        id: 'action',
        accessorKey: 'action',
        header: '',
        cell: ({ row }) => (
          <CrewButton
            stylingMode="text"
            icon={<BaselineDelete width={20} height={20} />}
            onClick={() => handleRemoveButtonClick(row.original.emailOrLoginID)}
          />
        ),
        size: 80,
        minSize: 50,
        enableSorting: false,
      },
    ],
    [handleRemoveButtonClick, t]
  )

  const tableOptions: TableOptions<NewUserType> = {
    data: props.selectedNewUsers,
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility,
      columnPinning,
    },
    onColumnVisibilityChange: setColumnVisibility,
    meta: {
      headerRowHeight: 40,
      dataRowHeight: 50,
    },
  }

  return (
    <>
      {/* invite new user list table */}
      <CrewTable
        tableOptions={tableOptions}
        showPager={false}
        showColumnSelector={false}
      />

      {/* 削除確認ダイアログ */}
      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleDeletePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
      />
    </>
  )
})
