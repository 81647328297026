import { useTranslation } from '@crew/modules/i18n'
import { FC, memo } from 'react'

import { EntityType } from '@crew/enums/domain'
import { CrewLink, LinkColor } from 'components/elements/crewLink/crewLink'
import { CrewLoadingText } from 'components/elements/crewLoading/crewLoadingText/crewLoadingText'
import { CrewRelatedItemLink } from 'components/elements/crewRelatedItemLink/crewRelatedItemLink'

import { Region, SettingKeyType } from '@crew/enums/app'
import { ChatMessage } from '@crew/models/domain'
import { useUserSetting } from '@crew/states'
import { getDefaultTabValue } from '@crew/utils/enum'

import { DirectMessageTabs } from 'enums/app'

export type CrewChatMessageItemHeaderProps = {
  message: ChatMessage
  isShowChatRoomName: boolean
}

export const CrewChatMessageItemHeader: FC<CrewChatMessageItemHeaderProps> =
  memo((props) => {
    const { t } = useTranslation()

    // ユーザー設定からデフォルトのユーザープロファイル地域を取得
    const defaultUserProfileRegion = useUserSetting(
      SettingKeyType.UserProfileRegion,
      Region.Japan.value
    )

    return (
      <div className="flex flex-row gap-2 truncate">
        <CrewLink
          // Go user detail by click avatar
          to={`/${EntityType.User}/${
            props.message.createdBy.id
          }/${getDefaultTabValue(DirectMessageTabs)}`}
          className="text-sm font-bold crew-text-gray-4 hover:text-crew-gray-500 hover:dark:text-crew-gray-400"
        >
          {props.message.createdBy.displayName ?? (
            <CrewLoadingText className="h-full inline-block" />
          )}
        </CrewLink>
        <span className="text-sm crew-text-gray-5">
          {t('format.timestamp', {
            value: props.message.updatedAt,
            timeZone: defaultUserProfileRegion,
          })}
        </span>
        {/* Show related link when isShowChatRoomName is equal to true */}
        {props.isShowChatRoomName && (
          <span className="text-sm crew-text-gray-5 truncate">
            {props.message.chatRoom.entityRecordId !==
              props.message.chatRoom.rootEntityRecordId && (
              <>
                <CrewRelatedItemLink
                  entityType={props.message.chatRoom.rootEntityType}
                  id={props.message.chatRoom.rootEntityRecordId}
                  color={LinkColor.Gray}
                  targetChatMessageId={props.message.id}
                />
                &nbsp;/&nbsp;
              </>
            )}
            <CrewRelatedItemLink
              entityType={props.message.chatRoom.entityType}
              id={props.message.chatRoom.entityRecordId}
              color={LinkColor.Gray}
              targetChatMessageId={props.message.id}
            />
          </span>
        )}
      </div>
    )
  })
