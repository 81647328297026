import Mic from '~icons/material-symbols/mic'
import MicOff from '~icons/material-symbols/mic-off'
import BaselineVideocam from '~icons/ic/baseline-videocam'
import BaselineVideocamOff from '~icons/ic/baseline-videocam-off'
import Verified from '~icons/material-symbols/verified'
import { memo, FC, RefObject, useMemo, useCallback, useState } from 'react'
import { useEventDetailWebMeetingAttendeeItem } from './useEventDetailWebMeetingAttendee'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'
import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { useModal } from 'components/layouts/modal/useModal'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useMeetingManager } from 'modules/amazon-chime-sdk-component-library-devextreme'
import { AttendeeMenuItems } from 'enums/app'
import { useTranslation } from '@crew/modules/i18n'
import { useAudioVideo } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/AudioVideoProvider'
import {
  LIFE_TIME_MS,
  TOPIC_MEETING_HOST_CHANGED,
  TOPIC_MEETING_OFF_AUDIO_VIDEO,
} from 'configs/constants'
import { useValueChangeEffect } from '@crew/hooks'
import { CrewDropDownButton } from 'components/elements/crewDropDownButton/crewDropDownButton'
import MoreHoriz from '~icons/material-symbols/more-horiz'

// Dropdown item render
const renderDropDownItem = (itemData: { value: string }) => (
  <span className="px-1.5 text-base whitespace-nowrap">{itemData.value}</span>
)

type EventDetailWebMeetingAttendeesProps = {
  isMicrophoneEnable: boolean
  isVideoEnable: boolean
  displayName: string
  attendeeUserId: string
  version: number
  isHost: boolean
  isFullScreen: boolean
  chimeAttendeeId: string
  fullScreenTargetRef: RefObject<HTMLDivElement>
  showAttendeeActionMenu: boolean
}

export const EventDetailWebMeetingAttendeeItem: FC<EventDetailWebMeetingAttendeesProps> =
  memo((props) => {
    const {
      appointAsMeetingHost,
      dismissAsMeetingHost,
      removeAttendee,
      isLoadingDeleteMeetingAttendee,
    } = useEventDetailWebMeetingAttendeeItem()
    const { t } = useTranslation()
    const [showApiErrors] = useShowApiErrors()
    const { meetingId } = useMeetingManager()
    const audioVideo = useAudioVideo()

    const [attendeeIsHost, setAttendeeIsHost] = useState(props.isHost)

    // Update the attendeeIsHost state when the isHost prop changes
    useValueChangeEffect(
      () => {
        setAttendeeIsHost(props.isHost)
      },
      [props.isHost],
      props.isHost
    )

    // 確認ダイアログメッセージ
    const [confirmMessage, setConfirmMessage] = useState('')

    const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
      useModal()

    // Get attendee menu items from enum
    const attendeeMenuItems = useMemo(() => {
      return Object.values(AttendeeMenuItems)
        .filter((item) => {
          // If the attendee is the host, the "Appoint as meeting host" menu item is not displayed.
          if (item.value === AttendeeMenuItems.AppointAsMeetingHost.value) {
            return !attendeeIsHost
          } else if (
            item.value === AttendeeMenuItems.DismissAsMeetingHost.value
          ) {
            // If the attendee is not the host, the "Dismiss as meeting host" menu item is not displayed.
            return attendeeIsHost
          } else {
            return true
          }
        })
        .map((item) => {
          return {
            value: t(item.text),
            key: item.value,
            //「音声をオフにする」、「ビデオをオフにする」
            // 既に対象の参加者のデバイスがオフになっている場合は、グレー表示で選択不可にする
            disabled:
              (item.value === AttendeeMenuItems.TurnOffAudio.value &&
                !props.isMicrophoneEnable) ||
              (item.value === AttendeeMenuItems.TurnOffVideo.value &&
                !props.isVideoEnable),
          }
        })
    }, [attendeeIsHost, props.isMicrophoneEnable, props.isVideoEnable, t])

    // Event handler for the attendee menu item 主催者に任命する
    const handleAppointAsMeetingHostItemClick = useCallback(async () => {
      // No processing occurs without `meetingId`
      if (!meetingId || !audioVideo) return

      try {
        await appointAsMeetingHost(meetingId, props.chimeAttendeeId)

        // Send realtime message that the meeting host has changed
        audioVideo.realtimeSendDataMessage(
          TOPIC_MEETING_HOST_CHANGED,
          t('message.meeting.meetingHostChanged'),
          LIFE_TIME_MS
        )

        // Update the attendeeIsHost state
        setAttendeeIsHost(true)
      } catch (err) {
        // refresh attendee host status
        setAttendeeIsHost(props.isHost)

        // Show API error
        showApiErrors(err)
      }
    }, [
      appointAsMeetingHost,
      audioVideo,
      meetingId,
      props.chimeAttendeeId,
      props.isHost,
      showApiErrors,
      t,
    ])

    // Event handler for the attendee menu item 主催者を解任する
    const handleDismissAsMeetingHostItemClick = useCallback(async () => {
      // No processing occurs without `meetingId`
      if (!meetingId || !audioVideo) return
      try {
        await dismissAsMeetingHost(meetingId, props.chimeAttendeeId)

        // Send realtime message that the meeting host has changed
        audioVideo.realtimeSendDataMessage(
          TOPIC_MEETING_HOST_CHANGED,
          t('message.meeting.meetingHostChanged'),
          LIFE_TIME_MS
        )

        // Update the attendeeIsHost state
        setAttendeeIsHost(false)
      } catch (err) {
        // refresh attendee host status
        setAttendeeIsHost(props.isHost)

        // Show API error
        showApiErrors(err)
      }
    }, [
      audioVideo,
      dismissAsMeetingHost,
      meetingId,
      props.chimeAttendeeId,
      props.isHost,
      showApiErrors,
      t,
    ])

    // Event handler for the attendee menu item 音声をオフにする
    const handleTurnOffAudioItemClick = useCallback(() => {
      // No processing occurs without `meetingId`
      if (!meetingId || !audioVideo) return

      // Send realtime message that host turned off attendee's audio
      audioVideo.realtimeSendDataMessage(
        TOPIC_MEETING_OFF_AUDIO_VIDEO,
        JSON.stringify({ userId: props.attendeeUserId, target: 'audio' }),
        LIFE_TIME_MS
      )
    }, [audioVideo, meetingId, props.attendeeUserId])

    // Event handler for the attendee menu item ビデオをオフにする
    const handleTurnOffVideoItemClick = useCallback(() => {
      // No processing occurs without `meetingId`
      if (!meetingId || !audioVideo) return

      // Send realtime message that host turned off attendee's video
      audioVideo.realtimeSendDataMessage(
        TOPIC_MEETING_OFF_AUDIO_VIDEO,
        JSON.stringify({ userId: props.attendeeUserId, target: 'video' }),
        LIFE_TIME_MS
      )
    }, [audioVideo, meetingId, props.attendeeUserId])

    // Event handler for the attendee menu button
    const handleWebMeetingSettingButtonClick = useCallback(
      async (itemData: { key: string; value: string }) => {
        // No processing occurs without `meetingId`
        if (!meetingId) return

        switch (itemData.key) {
          case AttendeeMenuItems.AppointAsMeetingHost.value:
            handleAppointAsMeetingHostItemClick()
            break
          case AttendeeMenuItems.DismissAsMeetingHost.value:
            handleDismissAsMeetingHostItemClick()
            break
          case AttendeeMenuItems.RemoveFromMeeting.value:
            // 確認ダイアログの表示（処理は確認ダイアログのOKボタン押下時に行う）
            setConfirmMessage(t('message.general.confirmMessage.leave'))
            openConfirmDialog()
            break
          case AttendeeMenuItems.TurnOffAudio.value:
            handleTurnOffAudioItemClick()
            break
          case AttendeeMenuItems.TurnOffVideo.value:
            handleTurnOffVideoItemClick()
            break
        }
      },
      [
        handleAppointAsMeetingHostItemClick,
        handleDismissAsMeetingHostItemClick,
        handleTurnOffAudioItemClick,
        handleTurnOffVideoItemClick,
        meetingId,
        openConfirmDialog,
        t,
      ]
    )

    // Confirm remove attendee button press event handler
    const handleRemoveAttendeePermitButtonClick = useCallback(async () => {
      // meetingId is not null, make attendee to leave
      if (meetingId) {
        // Make attendee to leave
        try {
          await removeAttendee(meetingId, props.chimeAttendeeId)
        } catch (err) {
          showApiErrors(err)
        }
      }
      closeConfirmDialog()
    }, [
      closeConfirmDialog,
      meetingId,
      props.chimeAttendeeId,
      removeAttendee,
      showApiErrors,
    ])

    return (
      <>
        <div className="flex justify-between items-center gap-2.5">
          <div className="min-w-0 flex flex-row items-center gap-0.5">
            <div className="min-w-0 grow">
              <CrewUserAvatar
                userId={props.attendeeUserId}
                displayName={props.displayName}
                size={CrewAvatarSize.xs}
                showLabel={true}
                cacheValue={props.attendeeUserId + props.version}
                disableClick
              />
            </div>

            {/* When attendee user is host, so display badge */}
            {attendeeIsHost && (
              <Verified
                width={24}
                height={24}
                className="text-crew-yellow-500 shrink-0"
              />
            )}
          </div>

          {/* Show name Attended member and icon mute */}
          <div className="flex gap-x-1 items-center">
            {/*show icon mute */}
            <div className="crew-badge-icon-gray">
              {props.isMicrophoneEnable ? (
                // Microphone ON
                <Mic width={24} height={24} className="crew-badge-icon-green" />
              ) : (
                // Microphone OFF
                <MicOff
                  width={24}
                  height={24}
                  className="crew-badge-icon-red"
                />
              )}
            </div>
            {/*show icon camera */}
            <div className="crew-badge-icon-gray">
              {props.isVideoEnable ? (
                // Video ON
                <BaselineVideocam
                  width={24}
                  height={24}
                  className="crew-badge-icon-green"
                />
              ) : (
                // Video OFF
                <BaselineVideocamOff
                  width={24}
                  height={24}
                  className="crew-badge-icon-red"
                />
              )}
            </div>

            {/*show icon ellipsis */}
            <CrewDropDownButton
              icon={<MoreHoriz width={24} height={24} />}
              items={attendeeMenuItems}
              keyExpr="key"
              displayExpr="value"
              onItemClick={handleWebMeetingSettingButtonClick}
              showArrowIcon={false}
              showDivider={false}
              highlightSelected={false}
              stylingMode="text"
              buttonSize="sm"
              itemRender={renderDropDownItem}
              container={
                props.isFullScreen ? props.fullScreenTargetRef : undefined
              }
            />
          </div>
        </div>
        {/* Remove attendee confirm dialog */}
        <CrewConfirmDialog
          isOpen={isConfirmDialogOpen}
          message={confirmMessage}
          onPermitButtonClick={handleRemoveAttendeePermitButtonClick}
          onCancelButtonClick={closeConfirmDialog}
          permitButtonDisabled={isLoadingDeleteMeetingAttendee}
        />
      </>
    )
  })
